import React, {useEffect, useId, useState} from "react";
import LNCNumberMobile from "./LNCNumberMobile";
import { Table, Stack, Row } from "react-bootstrap";
import MortalitiesOverview from "./MortalitiesOverview";
import TreatmentOverview from "./TreatmentOverview";
import FeedRemainingOverview from "./FeedRemainingOverview";
import AvgFeedOverview from "./AvgFeedOverview";
import AvgWaterOverview from "./AvgWaterOverview";
import { useNavigate } from "react-router-dom";
import ConsignmentInfoTableMobile from "./ConsignmentInfoTableMobile";

const ConsignmentInfoMobile = ({voyage, isStarted, onViewConsignmentClicked, eovReport, onLoadingAndDepartureViewed, onDischargeViewed, onEOVViewed}) => {
    const componentId = useId();
    const [consignmentInfos, setConsignmentInfos] = useState([]);

    useEffect(() => {
        if (!voyage || voyage.detailedConsignments?.length === 0) return;
        populateConsignmentInfoModels();
    }, [voyage, isStarted, eovReport])

    const populateConsignmentInfoModels = async () => {
        let consignmentsInfoModels = voyage.detailedConsignments.map((dc, idx) => getConsignmentInfo(dc, idx));
        setConsignmentInfos(consignmentsInfoModels);
    }

    const getConsignmentInfo = (dc, idx) => {
        let loadreports = [];
        if (dc?.DeparturePorts?.length > 0 && isStarted) {
            
            dc.DeparturePorts.forEach(dp => {
                if (!dp.LoadingAnimals || dp.LoadingAnimals.length === 0) return;
                let entry = {
                    consignment: dc.Consignment,
                    departurePortID: dp.ID,
                    isReviewed: dp.IsLoadingAndDepartureReviewed
                }
                if (loadreports.find(existing => existing.consignment.id === entry.consignment.id && existing.departurePortID === entry.departurePortID)) return;
                loadreports.push(entry);
            });
        }

        let disports = [];
        if (dc?.DestinationPorts?.length > 0 && isStarted) {
            
            dc.DestinationPorts.forEach(dp => {
                if (!dp.DischargeCompletedDateUTC) return;
                let dischargeDate = new Date(dp.DischargeCompletedDateUTC);
                if (!(dischargeDate instanceof Date) || isNaN(dischargeDate)) return;
                let entry = {
                    consignment: dc.Consignment,
                    destinationPortID: dp.ID,
                    isReviewed: dp.IsDischargeReviewed
                }
                if (disports.find(existing => existing.consignment.id === entry.consignment.id && existing.destinationPortID === entry.destinationPortID)) return;
                disports.push(entry);
            });
        }

        
        return ({id: idx, isExpanded: false, consignment: dc, loadingAndDepartureReports: loadreports, dischargeReports: disports, endOfVoyageReport: eovReport});
    }

    const toggleInfoView = (targetID) => {
        let tempConsignmentInfos = [...consignmentInfos];
        let indexToUpdate = tempConsignmentInfos.indexOf(tempConsignmentInfos.find(tci => tci.id === targetID));
        tempConsignmentInfos[indexToUpdate].isExpanded = !tempConsignmentInfos[indexToUpdate].isExpanded;
        setConsignmentInfos(tempConsignmentInfos);
    }

    return (
        <Row className="mx-0 my-0">
            {
                consignmentInfos.map((ci, idx) => {

                    return (
                        <div 
                            key={`cons_${componentId}_${idx}`} 
                            className="m-0 p-0" 
                            onClick={() => {
                                toggleInfoView(ci.id);
                            }}
                        >
                            <LNCNumberMobile 
                                key={`lnc_number_${componentId}_${idx}`} 
                                consignment={ci?.consignment?.Consignment} 
                                onViewConsignmentClicked={() => onViewConsignmentClicked(ci.consignment.Consignment.id)}
                                loadingReports={ci.loadingAndDepartureReports}
                                dischargeReports={ci.dischargeReports}
                                eovReport={ci.endOfVoyageReport}
                                onLoadingAndDepartureViewed={onLoadingAndDepartureViewed}
                                onDischargeViewed={onDischargeViewed}
                                onEOVViewed={onEOVViewed}
                            />
                            {
                                ci.isExpanded ? (
                                    <ConsignmentInfoTableMobile consignment={ci.consignment.Consignment} idx={idx} componentId={componentId} isStarted={isStarted} />
                                    // <>
                                    //     <Table key={`lnc_info_table_${componentId}_${idx}`} className="my-0" style={{borderLeft: "none", borderRight: "none"}}>
                                    //         <thead>
                                    //             <tr className="consignment-info-table-header-mobile">
                                    //                 <th className="text-center consignment-info-table-cell-border-right w-50">Treatments</th>
                                    //                 <th className="text-center">Mortalities</th>
                                    //             </tr>
                                    //         </thead>
                                    //         <tbody>
                                    //             <tr>
                                    //                 <td className="consignment-info-table-cell-mobile consignment-info-table-cell-border-right w-50">
                                    //                     <Stack direction="horizontal">
                                    //                         <TreatmentOverview consignment={ci.consignment.Consignment} index={idx} isStarted={isStarted}/>
                                    //                     </Stack>
                                    //                 </td>
                                    //                 <td className="consignment-info-table-cell-mobile">
                                    //                     <Stack direction="horizontal">
                                    //                         <MortalitiesOverview consignment={ci.consignment.Consignment} index={idx} isStarted={isStarted} />
                                    //                     </Stack>
                                    //                 </td>
                                    //             </tr>
                                    //         </tbody>
                                    //     </Table>
                                    //     <Table className="my-0" style={{borderLeft: "none", borderRight: "none"}}>
                                    //         <thead>
                                    //             <tr className="consignment-info-table-header-mobile">
                                    //                 <th className="text-center">Feed Remaining</th>
                                    //             </tr>
                                    //         </thead>
                                    //         <tbody>
                                    //             <tr>
                                    //                 <td className="consignment-info-table-cell-mobile">
                                    //                     <Stack direction="horizontal">
                                    //                         <div className="w-100">
                                    //                             <Stack direction="vertical" className="w-100">
                                    //                                 <FeedRemainingOverview consignment={ci.consignment.Consignment} index={idx} isStarted={isStarted}/>
                                    //                             </Stack>
                                    //                         </div>
                                    //                     </Stack>
                                    //                 </td>
                                    //             </tr>
                                    //         </tbody>
                                    //     </Table>
                                    //     <Table className="my-0" style={{borderLeft: "none", borderRight: "none"}}>
                                    //         <thead>
                                    //             <tr className="consignment-info-table-header-mobile">
                                    //                 <th className="text-center consignment-info-table-cell-border-right w-50">Avg feed</th>
                                    //                 <th className="text-center">Avg water</th>
                                    //             </tr>
                                    //         </thead>
                                    //         <tbody>
                                    //             <tr>
                                    //                 <td className="consignment-info-table-cell-mobile consignment-info-table-cell-border-right w-50">
                                    //                     <AvgFeedOverview consignment={ci.consignment.Consignment} index={idx} isStarted={isStarted} />
                                    //                 </td>
                                    //                 <td className="consignment-info-table-cell-mobile">
                                    //                     <AvgWaterOverview consignment={ci.consignment.Consignment} index={idx} isStarted={isStarted} />
                                    //                 </td>                        
                                    //             </tr>
                                    //         </tbody>
                                    //     </Table>
                                    // </>
                                ) : null
                            }
                        </div>
                    )
                })
            }
        </Row>
    )
}

export default ConsignmentInfoMobile;