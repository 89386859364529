export class Vessel {
    ID;
    VesselName;
    IMONumber;
    EmailAddress;
    IsActive;

    constructor({id, vesselName, imoNumber, emailAddress, isActive}) {
        this.ID = id;
        this.VesselName = vesselName;
        this.IMONumber = imoNumber;
        this.EmailAddress = emailAddress;
        this.IsActive = isActive;
    }
}