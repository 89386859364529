import '../css/dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Stack, ButtonGroup, Spinner } from 'react-bootstrap';
import LoggedInContainer from '../layout/LoggedInContainer';
import ConsignmentOverview from '../components/ConsignmentOverview/ConsignmentOverview';
import {
    getActiveConsignmentsForExporterWithObsLocations,
    getDeparturePorts,
    getDestinationPorts
} from '../api/apiAccess';
import { DetailedConsignment } from '../classes/DetailedConsignment';
import { UserContext } from '..';
import { clearSessionConsignmentBeingCreated } from '../helpers/SessionHelper';
import { getMillisecondsDifference, getCurrentDayAndEndDayForVoyage } from '../utils/common';
import ReadWriteButton from '../components/ReadWriteElements/ReadWriteButton';
import { MediaQueryContext } from '..';
import {isReportEditable} from "../utils/common";

const Dashboard = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const mediaQueryCtx = useContext(MediaQueryContext);
    const [allDeparturePorts, setAllDeparturePorts] = useState([]);
    const [allDestinationPorts, setAllDestinationPorts] = useState([]);
    const [activeConsignments, setActiveConsignments] = useState([]);
    const [recentlyCompletedConsignments, setRecentlyCompletedConsignments] = useState([]);
    const [unstartedConsignments, setUnstartedConsignments] = useState([]);
    const [currentActiveConsignments, setCurrentActiveConsignments] = useState(null);

    const [isLoadingCurrentActiveConsignments, setIsLoadingCurrentActiveConsignments] = useState(true);
    const [isLoadingCurrentUnstartedConsignments, setIsLoadingCurrentUnstartedConsignments] = useState(true);
    const [isLoadingRecentlyCompletedConsignments, setIsLoadingRecentlyCompletedConsignments] = useState(true);

    const onViewPastConsignments = () => {
        navigate("/historicalconsignments");
    }

    const onCreateNewConsignment = () => {
        clearSessionConsignmentBeingCreated();
        navigate("/createconsignment");
    }

    useEffect(() => {
        clearSessionConsignmentBeingCreated();
        let fetchDeparturePorts = async () => {
            let results = await getDeparturePorts();
            if (results.status === 200) {
                setAllDeparturePorts(results.data);
            }
        }

        let fetchDestinationPorts = async () => {
            let results = await getDestinationPorts();
            if (results.status === 200) {
                setAllDestinationPorts(results.data);
            }
        }
        let fetchAll = async () => {
            await fetchDeparturePorts();
            await fetchDestinationPorts();
        }

        fetchAll()
    }, []);

    useEffect(() => {

        let fetchCurrentActiveConsignments = async () => {
            let associatedExporter = userCtx.associatedExporter;
            if (associatedExporter?.exporterID !== undefined) {
                let results = await getActiveConsignmentsForExporterWithObsLocations(associatedExporter.exporterID);
                if (results?.status === 200) {
                    let detailedConsignments = [];
                    results.data.forEach(d => {
                        detailedConsignments.push(new DetailedConsignment(d));
                    })
                    setCurrentActiveConsignments(detailedConsignments);
                }
            }
        }

        let fetchAll = async () => {
            await fetchCurrentActiveConsignments();
        }

        fetchAll();

    }, [userCtx.associatedExporter])

    useEffect(() => {
        if (currentActiveConsignments === null) return;
        if (currentActiveConsignments.length > 0 && allDeparturePorts.length > 0 && allDestinationPorts.length > 0) {
            buildGroupedActiveConsignments().then(result => {
                setActiveConsignments(result);
                setIsLoadingCurrentActiveConsignments(false);
            });
            buildGroupedUnstartedConsignments().then(result => {
                setUnstartedConsignments(result);
                setIsLoadingCurrentUnstartedConsignments(false);
            });
            buildGroupedCompletedConsignments().then(result => {
                setRecentlyCompletedConsignments(result);
                setIsLoadingRecentlyCompletedConsignments(false);
            });
        } else {
            setIsLoadingCurrentActiveConsignments(false);
            setIsLoadingCurrentUnstartedConsignments(false);
            setIsLoadingRecentlyCompletedConsignments(false);
        }
    }, [currentActiveConsignments, allDeparturePorts, allDestinationPorts])


    const consignmentInSameTimeFrame = (c1, c2) => {
        var c1StartDate = new Date(c1.estimatedDepartureDateUTC);
        var c1EndDate = new Date(c1.estimatedArrivalDateUTC);
        var c2StartDate = new Date(c2.estimatedDepartureDateUTC);
        var c2EndDate = new Date(c2.estimatedArrivalDateUTC);

        // Get the UTC timestamp for each date
        var c1StartTimestamp = c1StartDate.getTime();
        var c2StartTimeStamp = c2StartDate.getTime();
        var c1EndTimestamp = c1EndDate.getTime();
        var c2EndTimeStamp = c2EndDate.getTime();

        // If the first consignment starts after the second consignment ends
        if (c1StartTimestamp >= c2EndTimeStamp) {
            return false;
        }

        // If the second consignment starts after the first consignment ends
        if (c2StartTimeStamp >= c1EndTimestamp) {
            return false;
        }

        return true;
    }
    const buildGroupedActiveConsignments = async () => {
        let voyages = [];

        currentActiveConsignments.forEach((consignmentDetails, index) => {
            if (consignmentDetails.IsCompleted()) return;
            // Filter out unstarted consignments
            if (consignmentDetails.Consignment.assignedAAVID === undefined || consignmentDetails.Consignment.assignedAAVID === null) return;

            // Find shared voygage
            let sharedVoyage = voyages.find(v => v.consignments.find(c => c.vesselID === consignmentDetails.Consignment.vesselID && consignmentInSameTimeFrame(c, consignmentDetails.Consignment)) !== undefined);
            let associatedDeparturePorts = allDeparturePorts.filter(adp => consignmentDetails.DeparturePorts.map(cdp => cdp.PortID).includes(adp.id));
            let associatedDestinationPorts = allDestinationPorts.filter(adp => consignmentDetails.DestinationPorts.map(cdp => cdp.PortID).includes(adp.id));
            let mostRecentConsignmentDay = getMostRecentConsignmentDay(consignmentDetails.ConsignmentDays);

            let unreviewedLoadingAndDepartures = consignmentDetails.DeparturePorts?.filter(p => p.LoadingAnimals.length > 0 && !p.IsLoadingAndDepartureReviewed) ?? [];
            let hasUnreviewedLoadingAndDepartureReports = unreviewedLoadingAndDepartures?.length > 0 ?? false;

            let unreviewedDischarges = consignmentDetails.DestinationPorts?.filter(p => p.DischargeAnimals.length > 0 && !p.IsDischargeReviewed);
            let hasUnreviewedDischargeReports = unreviewedDischarges?.length > 0 ?? false;

            if (sharedVoyage !== undefined) {
                let sharedVoyageIndex = voyages.indexOf(sharedVoyage);
                sharedVoyage.detailedConsignments.push(consignmentDetails);
                sharedVoyage.consignments.push(consignmentDetails.Consignment);
                sharedVoyage.departurePorts.push(...associatedDeparturePorts);
                sharedVoyage.destinationPorts.push(...associatedDestinationPorts);
                sharedVoyage.loadingAndDepartureReports.pendingReview = hasUnreviewedLoadingAndDepartureReports ? hasUnreviewedLoadingAndDepartureReports : sharedVoyage.loadingAndDepartureReports.pendingReview;
                sharedVoyage.loadingAndDepartureReports.reports.push(...unreviewedLoadingAndDepartures);
                sharedVoyage.dischargeReports.pendingReview = hasUnreviewedDischargeReports ? hasUnreviewedDischargeReports : sharedVoyage.dischargeReports.pendingReview;
                sharedVoyage.dischargeReports.reports.push(...unreviewedDischarges);
                voyages[sharedVoyageIndex] = sharedVoyage;
            } else {
                voyages.push({
                    isMultiConsignmentVoyage: consignmentDetails.Consignment.isMultiConsignmentVoyage,
                    vessel: consignmentDetails.Vessel.VesselName,
                    days: { currentDayNum: null, totalDayNum: null, etd: null },
                    departurePorts: associatedDeparturePorts,
                    destinationPorts: associatedDestinationPorts,
                    reviewStatus: {
                        requiresReporting: userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission ?? false,
                        received: false,
                        reviewed: false
                    },
                    submissionStatus: { submitted: false },
                    consignments: [consignmentDetails.Consignment],
                    detailedConsignments: [consignmentDetails],
                    loadingAndDepartureReports: {
                        reports: unreviewedLoadingAndDepartures,
                        pendingReview: hasUnreviewedLoadingAndDepartureReports && userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission
                    },
                    dischargeReports: {
                        reports: unreviewedDischarges,
                        pendingReview: hasUnreviewedDischargeReports && userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission
                    }
                })
            }
        });

        voyages.forEach(v => {
            v.loadingAndDepartureReports.pendingReview = v.loadingAndDepartureReports.reports.length > 0;
            v.dischargeReports.pendingReview = v.dischargeReports.reports.length > 0;
        })

        voyages = await updateDayStatus(voyages);
        voyages = await updateDailyReportingStatus(voyages);

        return voyages;
    }

    const updateDailyReportingStatus = async (voyages) => {
        const updateStatus = async () => {
            let lUpdated = [...voyages];
            
            for (let voyage of lUpdated) {
                let lHasReceviedAllDailyReportsForVoyage = false;
                let lHasReviewedAllDailyReportsForVoyage = false;
                let lHasSubmittedAllDailyReportsForVoyage = false;

                let lMostRecentDaysForEachConsignmentOnVoyage = [];

                if (voyage.detailedConsignments) {
                    voyage.detailedConsignments.forEach(con => {
                        // can be null
                        let lMostRecentConsignmentDay = getMostRecentConsignmentDay(con.ConsignmentDays);
                        
                        if (lMostRecentConsignmentDay) {
                            lMostRecentDaysForEachConsignmentOnVoyage.push(lMostRecentConsignmentDay);
                        }
                    })

                    if (lMostRecentDaysForEachConsignmentOnVoyage.length === voyage.detailedConsignments.length) {
                        lHasReceviedAllDailyReportsForVoyage = true;
                        lHasReviewedAllDailyReportsForVoyage = lMostRecentDaysForEachConsignmentOnVoyage.every(day => day.isViewed === true);
                        lHasSubmittedAllDailyReportsForVoyage = lMostRecentDaysForEachConsignmentOnVoyage.every(day => day.isSubmitted === true);
                    }
                }
              
                voyage.submissionStatus.submitted = lHasSubmittedAllDailyReportsForVoyage;
                voyage.reviewStatus.received = lHasReceviedAllDailyReportsForVoyage;
                voyage.reviewStatus.reviewed = lHasReviewedAllDailyReportsForVoyage;

            }
            return lUpdated;
        }
        let lUpdated = updateStatus(voyages);

        return lUpdated;
    }

    const getMostRecentConsignmentDay = (consignmentDays) => {
        let mostRecentConsignmentDay = consignmentDays.reduce((prev, current) => {
            return prev?.dayNumber > current.dayNumber ? prev : current
        }, null);
        return mostRecentConsignmentDay;
    }

    const isConsignmentReceiptLate = (consignmentDay) => {
        if (!consignmentDay) return true;
        let isLate = getMillisecondsDifference(new Date(consignmentDay.endDateUTC), new Date(Date.now())) / 3600000 > 24;
        return isLate;
    }

    const buildGroupedUnstartedConsignments = async () => {
        let voyages = [];

        currentActiveConsignments.forEach((consignmentDetails, index) => {
            if (consignmentDetails.Consignment.assignedAAVID !== undefined && consignmentDetails.Consignment.assignedAAVID !== null) return;

            let sharedVoyage = voyages.find(v => v.consignments.find(c => {
                return c.vesselID === consignmentDetails.Consignment.vesselID && consignmentInSameTimeFrame(c, consignmentDetails.Consignment)
            }) !== undefined);

            let associatedDeparturePorts = allDeparturePorts.filter(adp => consignmentDetails.DeparturePorts.map(cdp => cdp.PortID).includes(adp.id));
            let associatedDestinationPorts = allDestinationPorts.filter(adp => consignmentDetails.DestinationPorts.map(cdp => cdp.PortID).includes(adp.id));

            let unreviewedLoadingAndDepartures = consignmentDetails.DeparturePorts?.filter(p => p.LoadingAnimals.length > 0 && !p.IsLoadingAndDepartureReviewed) ?? [];
            let hasUnreviewedLoadingAndDepartureReports = unreviewedLoadingAndDepartures?.length > 0 ?? false;

            let unreviewedDischarges = consignmentDetails.DestinationPorts?.filter(p => p.DischargeAnimals.length > 0 && !p.IsDischargeReviewed);
            let hasUnreviewedDischargeReports = unreviewedDischarges?.length > 0 ?? false;

            if (sharedVoyage !== undefined) {
                let sharedVoyageIndex = voyages.indexOf(sharedVoyage);
                sharedVoyage.detailedConsignments.push(consignmentDetails);
                sharedVoyage.consignments.push(consignmentDetails.Consignment);
                sharedVoyage.departurePorts.push(...associatedDeparturePorts);
                sharedVoyage.destinationPorts.push(...associatedDestinationPorts);
                sharedVoyage.loadingAndDepartureReports.reports.push({ reports: unreviewedLoadingAndDepartures, hasUnreviewed: hasUnreviewedLoadingAndDepartureReports });
                sharedVoyage.dischargeReports.reports.push({ reports: unreviewedDischarges, hasUnreviewed: hasUnreviewedDischargeReports });
                voyages[sharedVoyageIndex] = sharedVoyage;
            } else {

                voyages.push({
                    isMultiConsignmentVoyage: consignmentDetails.Consignment.isMultiConsignmentVoyage,
                    vessel: consignmentDetails.Vessel.VesselName,
                    days: {},
                    departurePorts: associatedDeparturePorts,
                    destinationPorts: associatedDestinationPorts,
                    reviewStatus: {
                        isReviewRequiredPriorToDeptSubmission: userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission ?? false,
                        received: false,
                        reviewed: false
                    },
                    submissionStatus: { submitted: false },
                    consignments: [consignmentDetails.Consignment],
                    detailedConsignments: [consignmentDetails],
                    loadingAndDepartureReports: {
                        reports: unreviewedLoadingAndDepartures,
                        hasUnreviewed: hasUnreviewedLoadingAndDepartureReports
                    },
                    dischargeReports: {
                        reports: unreviewedDischarges,
                        hasUnreviewed: hasUnreviewedDischargeReports
                    }
                })
            }
        });

        voyages = await updateDayStatus(voyages);

        return voyages;
    }

    const buildGroupedCompletedConsignments = async () => {
        let voyages = [];

        currentActiveConsignments.forEach((consignmentDetails, index) => {
            if (!consignmentDetails.IsCompleted() || !isReportEditable(userCtx,consignmentDetails.IsEditable())) return;
            // Filter out unstarted consignments
            if (consignmentDetails.Consignment.assignedAAVID === undefined || consignmentDetails.Consignment.assignedAAVID === null) return;

            // Find shared voygage
            let sharedVoyage = voyages.find(v => v.consignments.find(c => c.vesselID === consignmentDetails.Consignment.vesselID && consignmentInSameTimeFrame(c, consignmentDetails.Consignment)) !== undefined);
            let associatedDeparturePorts = allDeparturePorts.filter(adp => consignmentDetails.DeparturePorts.map(cdp => cdp.PortID).includes(adp.id));
            let associatedDestinationPorts = allDestinationPorts.filter(adp => consignmentDetails.DestinationPorts.map(cdp => cdp.PortID).includes(adp.id));
            let mostRecentConsignmentDay = getMostRecentConsignmentDay(consignmentDetails.ConsignmentDays);

            let unreviewedLoadingAndDepartures = consignmentDetails.DeparturePorts?.filter(p => p.LoadingAnimals.length > 0 && !p.IsLoadingAndDepartureReviewed) ?? [];
            let hasUnreviewedLoadingAndDepartureReports = unreviewedLoadingAndDepartures?.length > 0 ?? false;

            let unreviewedDischarges = consignmentDetails.DestinationPorts?.filter(p => p.DischargeAnimals.length > 0 && !p.IsDischargeReviewed);
            let hasUnreviewedDischargeReports = unreviewedDischarges?.length > 0 ?? false;

            if (sharedVoyage !== undefined) {
                let sharedVoyageIndex = voyages.indexOf(sharedVoyage);
                sharedVoyage.detailedConsignments.push(consignmentDetails);
                sharedVoyage.consignments.push(consignmentDetails.Consignment);
                sharedVoyage.departurePorts.push(...associatedDeparturePorts);
                sharedVoyage.destinationPorts.push(...associatedDestinationPorts);
                sharedVoyage.loadingAndDepartureReports.pendingReview = hasUnreviewedLoadingAndDepartureReports ? hasUnreviewedLoadingAndDepartureReports : sharedVoyage.loadingAndDepartureReports.pendingReview;
                sharedVoyage.loadingAndDepartureReports.reports.push(...unreviewedLoadingAndDepartures);
                sharedVoyage.dischargeReports.pendingReview = hasUnreviewedDischargeReports ? hasUnreviewedDischargeReports : sharedVoyage.dischargeReports.pendingReview;
                sharedVoyage.dischargeReports.reports.push(...unreviewedDischarges);
                voyages[sharedVoyageIndex] = sharedVoyage;
            } else {
                voyages.push({
                    isMultiConsignmentVoyage: consignmentDetails.Consignment.isMultiConsignmentVoyage,
                    vessel: consignmentDetails.Vessel.VesselName,
                    days: { currentDayNum: null, totalDayNum: null, etd: null },
                    departurePorts: associatedDeparturePorts,
                    destinationPorts: associatedDestinationPorts,
                    reviewStatus: {
                        requiresReporting: userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission ?? false,
                        received: !isConsignmentReceiptLate(mostRecentConsignmentDay),
                        reviewed: mostRecentConsignmentDay?.isViewed ?? false
                    },
                    submissionStatus: { submitted: mostRecentConsignmentDay?.isSubmitted },
                    consignments: [consignmentDetails.Consignment],
                    detailedConsignments: [consignmentDetails],
                    loadingAndDepartureReports: {
                        reports: unreviewedLoadingAndDepartures,
                        pendingReview: hasUnreviewedLoadingAndDepartureReports && userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission
                    },
                    dischargeReports: {
                        reports: unreviewedDischarges,
                        pendingReview: hasUnreviewedDischargeReports && userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission
                    }
                })
            }
        });

        voyages.forEach(v => {
            v.loadingAndDepartureReports.pendingReview = v.loadingAndDepartureReports.reports.length > 0;
            v.dischargeReports.pendingReview = v.dischargeReports.reports.length > 0;
        })

        voyages = await updateDayStatus(voyages);

        // Any voyage with a consignment that was completed in the last 14 days should be considered relevant for this list
        // Note: If you change the number of days for filtering this list, you should also change the IsEditable number of days
        // in the DetailedConsignment class to the same number
        let filteredVoyages = voyages.filter(v => v.detailedConsignments.filter(dc => dc.IsCompletedInLastNumDays(14)).length > 0);
        return filteredVoyages;
    }

    const updateDayStatus = async (voyages) => {
        const setDays = async () => {
            let lUpdated = [...voyages];

            for (let voyage of lUpdated) {
                let lDayNumbers = await getCurrentDayAndEndDayForVoyage(userCtx.user?.userTypeID, voyage);
                if (lDayNumbers) {
                    voyage.days.totalDayNum = lDayNumbers.ExpectedNumberOfDays;
                    voyage.days.currentDayNum = lDayNumbers.CurrentDayNumber;
                    voyage.days.etd = calculateETD(voyage.consignments);
                }
            }
            return lUpdated;
        }
        let lVoyagesWithCurrentAdnEndDayNumbers = setDays(voyages);

        return lVoyagesWithCurrentAdnEndDayNumbers;
    }

    const calculateETD = (consignments) => {
        if (!consignments || consignments.length === 0) return;
        let earliestDepartureConsignment = consignments.map(c => c.estimatedDepartureDateUTC).reduce((a, b) => {
            if (new Date(b) < new Date(a)) {
                return b;
            }
            return a;
        });
        let resultDate = new Date(earliestDepartureConsignment);
        return resultDate.toDateString();
    }
    const getDifferenceInDays = (dateTime2, dateTime1) => {
        let differenceInMilliseconds = (dateTime2.getTime() - dateTime1.getTime());
        let differenceInHours = differenceInMilliseconds / 1000 / (60 * 60) / 24;
        return Math.round(differenceInHours);
    }

    const goToREDashboard = () => {
        navigate('/re/dashboard');
    }

    return (
        <LoggedInContainer>
            <Container fluid>
                {
                    mediaQueryCtx.mobile === true ? 
                    (
                        <Row className="dashboard-consignment-group-header" style={{paddingLeft: 0, paddingRight: 0}}>
                            <Col xs={12}>
                                <Stack direction="vertical">
                                    <div className="heading-text text-white text-center" style={{ lineHeight: "50px" }}>Active Consignments</div>
                                    {
                                    userCtx.associatedExporter?.exporter?.organisationTypeID === 3 ?
                                    (
                                        <div className="mx-auto mt-2">
                                            <ButtonGroup>
                                                <Button
                                                    style={{ height: "50px", minWidth: "160px", fontSize: "13px", fontWeight: "400" }}
                                                >
                                                    Voyages
                                                </Button>
                                                <Button
                                                    onClick={goToREDashboard}
                                                    className="btn-secondary text-white"
                                                    style={{ height: "50px", minWidth: "160px", fontSize: "13px", fontWeight: "400" }}
                                                >
                                                    RE Consignments
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    ) : null
                                    }
                                </Stack>
                            </Col>
                        </Row>
                    )
                    :(
                    <Row className="dashboard-consignment-group-header">
                        <Col xs={7}>
                            <Stack direction="horizontal">
                                <div className="heading-text text-white" style={{ lineHeight: "50px" }}>Active Consignments</div>
                                {userCtx.associatedExporter?.exporter?.organisationTypeID === 3 ?
                                    (
                                        <div className="ms-3">
                                            <ButtonGroup>
                                                <Button
                                                    style={{ height: "50px", width: "247px", fontSize: "13px", fontWeight: "400" }}
                                                >
                                                    Voyages
                                                </Button>
                                                <Button
                                                    onClick={goToREDashboard}
                                                    className="btn-secondary text-white"
                                                    style={{ height: "50px", width: "247px", fontSize: "13px", fontWeight: "400" }}
                                                >
                                                    RE Consignments
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    ) : null
                                }
                            </Stack>
                        </Col>
                        <Col xs={5}>
                            <Stack direction='horizontal' gap={2}>
                                <a className="ms-auto text-white pe-3" href="https://app.powerbi.com/Redirect?action=OpenApp&appId=21054d7f-a442-452c-ba64-8bd53e0890ba&ctid=e370a382-77a8-468c-ac48-fcb5a74c9074" target="_blank" style={{ fontSize: "13px", fontWeight: "400" }}>LiveCorp Dashboards</a>

                                <Button
                                    onClick={onViewPastConsignments}
                                    className="btn-secondary text-white"
                                    style={{ height: "50px", width: "247px", fontSize: "13px", fontWeight: "400" }}
                                >
                                    View past consignments
                                </Button>

                                <ReadWriteButton
                                    writeonly={true}
                                    onClick={onCreateNewConsignment}
                                    className="btn-primary"
                                    style={{ height: "50px", width: "247px", fontSize: "13px", fontWeight: "400" }}
                                >
                                    Create new consignment
                                </ReadWriteButton>
                            </Stack>
                        </Col>
                    </Row>
                    )
                }
                <Row className={`mb-5 ${mediaQueryCtx.mobile === true ? "" : "px-5"} pt-5`}>
                    {
                        isLoadingCurrentActiveConsignments === true && (
                            <div className="text-center pt-5">
                                <Spinner className="mx-auto primary-spinner" />
                                <div className="mx-auto">Loading active consignments...</div>
                            </div>
                        )
                    }
                    {
                        activeConsignments.length > 0 && activeConsignments.map((v, index) => {
                            return (
                                <ConsignmentOverview isMobile={mediaQueryCtx?.mobile === true} key={`activeconsignments_voyage_${index}`} voyage={v} isStarted={true} />
                            )
                        })
                    }
                    {
                        isLoadingCurrentActiveConsignments !== true && activeConsignments.length === 0 ? (
                            <div className='text-center'><em>You do not currently have any active consignments.</em></div>
                        )
                            : null

                    }
                </Row>


                <Row className="dashboard-consignment-group-header" style={{ padding: "12px 54px" }}>
                    <Col xs={mediaQueryCtx.mobile === true ? 12 : 7}>
                        <div className={`heading-text text-white ${mediaQueryCtx.mobile === true ? "text-center" : ""}`} style={{ lineHeight: "50px", fontSize: "22px" }}>Not yet started</div>
                    </Col>
                </Row>

                <Row className={`mb-5 ${mediaQueryCtx.mobile === true ? "" : "px-5"} pt-5`}>
                    {
                        isLoadingCurrentUnstartedConsignments === true && (
                            <div className="text-center pt-5">
                                <Spinner className="mx-auto primary-spinner" />
                                <div className="mx-auto">Loading unstarted consignments...</div>
                            </div>
                        )
                    }
                    {
                        unstartedConsignments.length > 0 && unstartedConsignments.map((v, index) => {
                            return (
                                <ConsignmentOverview isMobile={mediaQueryCtx.mobile === true} key={`unstartedconsignments_voyage_${index}`} voyage={v} isStarted={false} />
                            )
                        })
                    }
                    {
                        isLoadingCurrentUnstartedConsignments === false && unstartedConsignments.length === 0 ? (
                            <div className='text-center'><em>You do not currently have any unstarted consignments.</em></div>
                        )
                            : null

                    }
                </Row>

                <Row className="dashboard-consignment-group-header" style={{ padding: "12px 54px" }}>
                    <Col xs={mediaQueryCtx.mobile === true ? 12 : 7}>
                        <div className={`heading-text text-white ${mediaQueryCtx.mobile === true ? "text-center" : ""}`} style={{ lineHeight: "50px", fontSize: "22px" }}>Recently completed</div>
                    </Col>
                </Row>
                <Row className={`mb-5 ${mediaQueryCtx.mobile === true ? "" : "px-5"} pt-5`}>
                    {
                        isLoadingRecentlyCompletedConsignments === true && (
                            <div className="text-center pt-5">
                                <Spinner className="mx-auto primary-spinner" />
                                <div className="mx-auto">Loading recently completed consignments...</div>
                            </div>
                        )
                    }
                    {
                        recentlyCompletedConsignments.length > 0 && recentlyCompletedConsignments.map((v, index) => {
                            return (
                                <ConsignmentOverview isMobile={mediaQueryCtx.mobile === true} key={`recentlycompletedconsignments_voyage_${index}`} voyage={v} isStarted={true} />
                            )
                        })
                    }
                    {
                        isLoadingRecentlyCompletedConsignments === false && recentlyCompletedConsignments.length === 0 ? (
                            <div className='text-center'><em>You do not currently have any recently completed consignments.</em></div>
                        )
                            : null

                    }
                </Row>
            </Container>
        </LoggedInContainer>
    );
};

export default Dashboard;