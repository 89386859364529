import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { UserContext } from "../..";
import { updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject } from "./ReadWriteProperties";

/*
    Can have reagular element properties, or any custom properties that are part of ReadWriteProperties
*/
const ReadWriteButton = (props) => {
    const userCtx = useContext(UserContext);
    const [writeAccess, setWriteAccess] = useState(false);
    const [modifiedProps, setModifiedProps] = useState({});
    const [writeOnly, setWriteOnly] = useState(false);

    useEffect(() => {
        let lWriteAccess = userCtx.user.writeAccess;
        setWriteAccess(lWriteAccess);
    }, [userCtx.user]);

    useEffect(() => {
        setWriteOnly(props.writeonly === true);
        let lModifiedProps = updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject(props, writeAccess);
        setModifiedProps(lModifiedProps);
        
    }, [props, writeAccess]);

    return (
        (writeOnly === true && writeAccess === true) || writeOnly === false ?
            <Button {...modifiedProps} />
            :
            null
    );
};

export default ReadWriteButton;
