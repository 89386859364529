import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/consignmentcreation.css';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import LoggedInContainer from '../../layout/LoggedInContainer';
import { Container, Row, Col, Form, InputGroup, Stack, Button } from "react-bootstrap";
import ConsignmentProgression from "../../components/ConsignmentCreation/ConsignmentProgression";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faXmark, faSquare } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { getDeparturePorts, getDestinationPorts, getVessels } from '../../api/apiAccess';
import DateTimePicker from '../../components/DateTimePicker';
import { addToOrUpdateSessionConsignmentBeingCreated, clearSessionConsignmentBeingCreated, getSessionConsignmentBeingCreated, setSessionConsignmentBeingCreated } from '../../helpers/SessionHelper';
import { UserContext } from '../..';
import { FeedNumberValidationRules, parseNumericInput } from "../../utils/InputValidator";
import { formatUpToSpecifiedPrecision } from '../../utils/common';
import { UserType } from "../../api/enums/EnumUserType";

const Consignment_VoyageDetails = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const inputFormContainer = useRef();
    const [lncNumber, setLNCNumber] = useState("");
    const [departurePorts, setDeparturePorts] = useState([]);
    const [destinationPorts, setDestinationPorts] = useState([]);
    const [selectedDeparturePort, setSelectedDeparturePort] = useState({});
    const [selectedDestinationPort, setSelectedDestinationPort] = useState({});
    const [selectedDestinationPortStops, setSelectedDestinationPortsStops] = useState([{}]);
    const [consignmentPorts, setConsignmentPorts] = useState([]);
    const [vessels, setVessels] = useState([]);
    const [vesselID, setVesselID] = useState(1);
    const [robPellets, setROBPellets] = useState(0);
    const [robGrain, setROBGrain] = useState(0);
    const [robRoughage, setROBRoughage] = useState(0);
    const [departureLoadingPellets, setDepartureLoadingPellets] = useState(0);
    const [departureLoadingGrain, setDepartureLoadingGrain] = useState(0);
    const [departureLoadingRoughage, setDepartureLoadingRoughage] = useState(0);
    const [totalPellets, setTotalPellets] = useState(0);
    const [totalGrain, setTotalGrain] = useState(0);
    const [totalRoughage, setTotalRoughage] = useState(0);
    const [totalFeed, setTotalFeed] = useState(0);
    const [estimatedDepartureDateTime, setEstimatedDepartureDateTime] = useState(null);
    const [estimatedArrivalDateTime, setEstimatedArrivalDateTime] = useState(null);
    const [consignmentBeingCreated, setConsignmentBeingCreated] = useState({});
    const [canAddDischargePort, setCanAddDischargePort] = useState(true);
    const [isLNCNumberValid, setIsLNCNumberValid] = useState(true);
    const [isEstimatedDepartureDateTimeValid, setIsEstimatedDepartureDateTimeValid] = useState(true);
    const [isEstimatedArrivalDateTimeValid, setIsEstimatedArrivalDateTimeValid] = useState(true);
    const [estimatedArrivalErrorMessages, setEstimatedArrivalErrorMessages] = useState([]);
    const [estimatedDepartureErrorMessages, setEstimatedDepartureErrorMessages] = useState([]);
    const [isUseAlternatePenSpacing, setIsUseAlternatePenSpacing] = useState(false);

    // Feed validity
    const [robPelletsValid, setROBPelletsValid] = useState(true);
    const [robGrainValid, setROBGrainValid] = useState(true);
    const [robRoughageValid, setROBRoughageValid] = useState(true);

    const [loadedPelletsValid, setLoadedPelletsValid] = useState(true);
    const [loadedGrainValid, setLoadedGrainValid] = useState(true);
    const [loadedRoughageValid, setLoadedRoughageValid] = useState(true);

    //#region Utility variables
    const progressStages = [
        { title: "Voyage Details", completed: false },
        { title: "Personnel & Reporting", completed: false },
        { title: "Livestock", completed: false },
        { title: "Approved Management Plans", completed: false }
    ];
    //#endregion

    //#region Initial page setup functions
    useEffect(() => {
        let fetchDeparturePorts = async () => {
            let results = await getDeparturePorts();
            if (results?.status === 200) {
                setDeparturePorts(results.data);
            }
        }

        let fetchDestinationPorts = async () => {
            let results = await getDestinationPorts();
            if (results?.status === 200) {
                setDestinationPorts(results.data);
            }
        }

        let fetchVessels = async () => {
            let results = await getVessels();
            if (results?.status === 200) {
                setVessels(results.data)
            }
        }

        let fetchAll = async () => {
            await fetchDeparturePorts();
            await fetchDestinationPorts();
            await fetchVessels();
        }

        fetchAll();

        initializeConsignmentPortsList();
        let sessionConsignmentBeingCreated = getSessionConsignmentBeingCreated();

        if (sessionConsignmentBeingCreated) {
            setIsUseAlternatePenSpacing(sessionConsignmentBeingCreated.isUseAlternatePenSpacing);

            if (sessionConsignmentBeingCreated.consignmentPorts) {
                setConsignmentBeingCreated(sessionConsignmentBeingCreated);
                setLNCNumber(sessionConsignmentBeingCreated.lncNumber > 0 ? sessionConsignmentBeingCreated.lncNumber.toString() : "")
                setEstimatedDepartureDateTime(sessionConsignmentBeingCreated.estimatedDepartureDateTime ? new Date(sessionConsignmentBeingCreated.estimatedDepartureDateTime) : null);
                setEstimatedArrivalDateTime(sessionConsignmentBeingCreated.estimatedArrivalDateTime ? new Date(sessionConsignmentBeingCreated.estimatedArrivalDateTime) : null);
                setSelectedDeparturePort(sessionConsignmentBeingCreated.consignmentPorts[0] ? sessionConsignmentBeingCreated?.consignmentPorts[0] : departurePorts[0])
                setSelectedDestinationPort(sessionConsignmentBeingCreated.consignmentPorts[sessionConsignmentBeingCreated.consignmentPorts.length - 1] ? sessionConsignmentBeingCreated.consignmentPorts[sessionConsignmentBeingCreated.consignmentPorts.length - 1] : destinationPorts[0])
                setConsignmentPorts(sessionConsignmentBeingCreated.consignmentPorts.length > 2 ? sessionConsignmentBeingCreated.consignmentPorts : []);
                setSelectedDestinationPortsStops(sessionConsignmentBeingCreated.consignmentPorts.filter((p, i, arr) => i > 0 && i < arr.length - 1));
                setVesselID(sessionConsignmentBeingCreated.vesselID > 0 ? sessionConsignmentBeingCreated.vesselID : 1);
                setROBPellets(sessionConsignmentBeingCreated.robPellets > -1 ? sessionConsignmentBeingCreated.robPellets : 0);
                setROBGrain(sessionConsignmentBeingCreated.robGrain > -1 ? sessionConsignmentBeingCreated.robGrain : 0);
                setROBRoughage(sessionConsignmentBeingCreated.robRoughage > -1 ? sessionConsignmentBeingCreated.robRoughage : 0);
                setDepartureLoadingPellets(sessionConsignmentBeingCreated.departureLoadingPellets > -1 ? sessionConsignmentBeingCreated.departureLoadingPellets : 0);
                setDepartureLoadingGrain(sessionConsignmentBeingCreated.departureLoadingGrain > -1 ? sessionConsignmentBeingCreated.departureLoadingGrain : 0);
                setDepartureLoadingRoughage(sessionConsignmentBeingCreated.departureLoadingRoughage > -1 ? sessionConsignmentBeingCreated.departureLoadingRoughage : 0);
            }
        }
    }, []);

    useEffect(() => {
        setVessels(vessels);
    }, [vessels])

    useEffect(() => {
        let sessionConsignmentBeingCreated = getSessionConsignmentBeingCreated();
        if (sessionConsignmentBeingCreated?.consignmentPorts) return;
        if (destinationPorts?.length > 0 && departurePorts?.length > 0) {
            if (Object.keys(selectedDeparturePort)?.length === 0) {
                setSelectedDeparturePort(departurePorts[0]);
            }
            if (Object.keys(selectedDestinationPort)?.length === 0) {
                setSelectedDestinationPort(destinationPorts[0]);
            }
        }
    }, [destinationPorts, departurePorts])

    useEffect(() => {
        let lROBPellets = parseFloat(Number(robPellets));
        if (isNaN(lROBPellets)) {
            lROBPellets = 0;
        }
        let lROBGrain = parseFloat(Number(robGrain));
        if (isNaN(lROBGrain)) {
            lROBGrain = 0;
        }
        let lROBRoughage = parseFloat(Number(robRoughage));
        if (isNaN(lROBRoughage)) {
            lROBRoughage = 0;
        }

        let lDepPellets = parseFloat(Number(departureLoadingPellets));
        if (isNaN(lDepPellets)) {
            lDepPellets = 0;
        }
        let lDepGrain = parseFloat(Number(departureLoadingGrain));
        if (isNaN(lDepGrain)) {
            lDepGrain = 0;
        }
        let lDepRoughage = parseFloat(Number(departureLoadingRoughage));
        if (isNaN(lDepRoughage)) {
            lDepRoughage = 0;
        }

        setTotalPellets(lROBPellets + lDepPellets);
        setTotalGrain(lROBGrain + lDepGrain);
        setTotalRoughage(lROBRoughage + lDepRoughage);
    }, [robPellets, robGrain, robRoughage, departureLoadingPellets, departureLoadingGrain, departureLoadingRoughage]);

    useEffect(() => {
        setTotalFeed(Number(totalPellets) + Number(totalGrain) + Number(totalRoughage));
    }, [totalPellets, totalGrain, totalRoughage]);

    useEffect(() => {
        setCanAddDischargePort(consignmentPorts.length < 8)
    }, [consignmentPorts]);

    const initializeConsignmentPortsList = () => {
        if (consignmentBeingCreated?.consignmentPorts?.length > 0) {
            setConsignmentPorts(consignmentBeingCreated.consignmentPorts);
        } else {
            let tempConsignmentPorts = [...consignmentPorts];
            tempConsignmentPorts = [
                departurePorts.length > 0 ? departurePorts[0] : { id: 0, countryName: "", portName: "" },
                destinationPorts.length > 0 ? destinationPorts[0] : { id: 0, countryName: "", portName: "" }
            ];
            setConsignmentPorts(tempConsignmentPorts);
        }
    }

    const goToPersonnel = async () => {
        if (validateInputData()) {
            updateConsignmentBeingCreated();
            navigate("/createconsignmentpersonnel");
        } else {
            window.scrollTo(0, inputFormContainer.current.offsetTop);
        }
    }

    const goToDashboard = () => {
        clearSessionConsignmentBeingCreated();
        navigate("/dashboard");
    }

    const addStopConsignmentPort = () => {
        let tempConsignmentPorts = [...consignmentPorts];
        tempConsignmentPorts.splice(tempConsignmentPorts.length - 1, 0, destinationPorts[0]);
        setSelectedDestinationPortsStops(tempConsignmentPorts.filter((e, i, arr) => i > 0 && i < arr.length - 1));
        setConsignmentPorts(tempConsignmentPorts);
    }

    const updateStopConsignmentPort = (selectedPort, stopIndex) => {
        let tempConsignmentPorts = [...consignmentPorts];
        tempConsignmentPorts[stopIndex] = selectedPort;
        setSelectedDestinationPortsStops(tempConsignmentPorts.filter((e, i, arr) => i > 0 && i < arr.length - 1));
        setConsignmentPorts(tempConsignmentPorts);
    }

    const removeStopConsignmentPort = (stopIndex) => {
        let tempConsignmentPorts = [...consignmentPorts];
        tempConsignmentPorts = tempConsignmentPorts.filter((cp, i) => i !== stopIndex);
        setSelectedDestinationPortsStops(tempConsignmentPorts.filter((e, i, arr) => i > 0 && i < arr.length - 1));
        setConsignmentPorts(tempConsignmentPorts);
    }

    const updateSelectedDeparturePort = (port) => {
        setSelectedDeparturePort(port);
        updateStopConsignmentPort(port, 0);
    }

    const updateSelectedDestinationPort = (port) => {
        setSelectedDestinationPort(port);
        updateStopConsignmentPort(port, consignmentPorts.length - 1);
    }

    //#endregion

    //#region Validation
    const validateInputData = () => {
        let isAllValid = true;
        clearCurrentErrors();

        if (!lncNumber || lncNumber.length !== 6) {
            isAllValid = false;
            setIsLNCNumberValid(false);
        }

        if (!estimatedDepartureDateTime) {
            isAllValid = false;
            let tempErrors = [...estimatedDepartureErrorMessages];
            tempErrors.push("The estimated departure date and time must be provided.");
            setEstimatedDepartureErrorMessages(tempErrors);
            setIsEstimatedDepartureDateTimeValid(false);
        }

        if (!estimatedArrivalDateTime) {
            isAllValid = false;
            let tempErrors = [...estimatedArrivalErrorMessages];
            tempErrors.push("The estimated arrival date and time must be provided.");
            setEstimatedArrivalErrorMessages(tempErrors);
            setIsEstimatedArrivalDateTimeValid(false);
        }

        if (estimatedArrivalDateTime && estimatedDepartureDateTime && estimatedArrivalDateTime <= estimatedDepartureDateTime) {
            isAllValid = false;
            let tempDepartureErrors = [...estimatedDepartureErrorMessages];
            tempDepartureErrors.push("The departure date and time must be earlier than the arrival date and time.");
            setEstimatedDepartureErrorMessages(tempDepartureErrors);
            let tempArrivalErrors = [...estimatedArrivalErrorMessages];
            tempArrivalErrors.push("The arrival date and time must be later than the departure date and time.");
            setEstimatedArrivalErrorMessages(tempArrivalErrors);
            setIsEstimatedArrivalDateTimeValid(false);
            setIsEstimatedDepartureDateTimeValid(false);
        }

        // Validate feed
        let lROBPelletsValid = true;
        let lROBGrainValid = true;
        let lROBRoughageValid = true;
        let lLoadedPelletsValid = true;
        let lLoadedGrainValid = true;
        let lLoadedRoughageValid = true;

        if (robPellets && isNaN(robPellets) === true) {
            isAllValid = false;
            lROBPelletsValid = false;
        }
        if (robGrain && isNaN(robGrain) === true) {
            isAllValid = false;
            lROBGrainValid = false;
        }
        if (robRoughage && isNaN(robRoughage) === true) {
            isAllValid = false;
            lROBRoughageValid = false;
        }

        if (departureLoadingPellets && isNaN(departureLoadingPellets) === true) {
            isAllValid = false;
            lLoadedPelletsValid = false;
        }
        if (departureLoadingGrain && isNaN(departureLoadingGrain) === true) {
            isAllValid = false;
            lLoadedGrainValid = false;
        }
        if (departureLoadingRoughage && isNaN(departureLoadingRoughage) === true) {
            isAllValid = false;
            lLoadedRoughageValid = false;
        }

        setROBPelletsValid(lROBPelletsValid);
        setROBGrainValid(lROBGrainValid);
        setROBRoughageValid(lROBRoughageValid);
        setLoadedPelletsValid(lLoadedPelletsValid);
        setLoadedGrainValid(lLoadedGrainValid);
        setLoadedRoughageValid(lLoadedRoughageValid);

        return isAllValid;
    }

    const clearCurrentErrors = () => {
        setIsEstimatedArrivalDateTimeValid(true);
        setIsEstimatedDepartureDateTimeValid(true);
        setIsLNCNumberValid(true);
        setEstimatedArrivalErrorMessages([]);
        setEstimatedDepartureErrorMessages([]);
    }
    //#endregion

    //#region Consignment Model
    const updateConsignmentBeingCreated = () => {
        let exporterID = userCtx.associatedExporter?.exporter?.id;
        if (exporterID === undefined) {
            // Fetch the logged in user's exporter id and update the associated exporter
            // Or log the user out?
        }
        addToOrUpdateSessionConsignmentBeingCreated({
            exporterID: exporterID,
            exporter: userCtx.associatedExporter.exporter,
            lncNumber: lncNumber,
            consignmentPorts: buildConsignmentPortsArray(),
            vesselID: vesselID,
            estimatedDepartureDateTime: estimatedDepartureDateTime,
            estimatedArrivalDateTime: estimatedArrivalDateTime,
            robPellets: robPellets,
            robGrain: robGrain,
            robRoughage: robRoughage,
            departureLoadingPellets: departureLoadingPellets,
            departureLoadingGrain: departureLoadingGrain,
            departureLoadingRoughage: departureLoadingRoughage,
            isUseAlternatePenSpacing: isUseAlternatePenSpacing
        })
    }

    const buildConsignmentPortsArray = () => {
        let selectedConsignmentPorts = [];
        selectedConsignmentPorts.push(selectedDeparturePort);
        let portStops = selectedDestinationPortStops.map(stop => stop).filter(stop => stop?.id && stop?.countryID);
        if (portStops.length > 0) {
            selectedConsignmentPorts.push(...portStops);
        }
        selectedConsignmentPorts.push(selectedDestinationPort);
        return selectedConsignmentPorts;
    }

    //#endregion

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>Create new consignment</div>
                                <ConsignmentProgression stages={progressStages} currentStage={progressStages[0]} key="consignment_progress" />
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container ref={inputFormContainer} className="py-4 px-5 form-container">
                        <Row style={{ marginBottom: "40px" }}>
                            <Col xs={12}>
                                <Stack direction='horizontal' gap={2}>
                                    <FontAwesomeIcon icon={faSquare} style={{ fontSize: "24px" }} className='voluntary-data-text' />
                                    <span className="darkblue field-label">Denotes voluntary data</span>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="heading-text darkblue">Voyage details</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                LNC Number
                            </Col>
                            <Col xs={12}>
                                <Form.Group id="lnc-number-form-group">
                                    <InputGroup className="pb-3">
                                        <InputGroup.Text id="lnc-number">LNC-</InputGroup.Text>
                                        <Form.Control
                                            autoComplete='off'
                                            isInvalid={!isLNCNumberValid}
                                            id="lnc-number-input"
                                            aria-label="LNCNumber"
                                            aria-describedby="lnc-number"
                                            onChange={(e) => {
                                                setLNCNumber(e.target.value);
                                            }}
                                            value={lncNumber}
                                        />
                                        {!isLNCNumberValid ?
                                            (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip
                                                        anchorSelect="#lnc-number-form-group"
                                                        place="top"
                                                        content="LNC number must be numeric and six digits long."
                                                    />
                                                </div>
                                            ) : null

                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Departure Port
                            </Col>
                            <Col xs={12}>
                                <Form.Select
                                    aria-label="Departure Port"
                                    onChange={(e) => {
                                        let selectedPort = departurePorts.find(p => Number(p.id) === Number(e.target.value));
                                        updateSelectedDeparturePort(selectedPort);
                                    }}
                                    value={selectedDeparturePort?.id}
                                >
                                    {departurePorts && departurePorts.length > 0 && departurePorts.map((depPort, i) => {
                                        return (
                                            <option key={`departure_port_${i}_${depPort.name}`} value={depPort.id}>{`Australia - ${depPort.portName}`}</option>
                                        )
                                    })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        {consignmentPorts && consignmentPorts.length > 2 && consignmentPorts.map((dp, stopIndex) => {

                            // Skip the first an last port as they are the departure port and final destination port
                            if (stopIndex === 0 || stopIndex === consignmentPorts.length - 1) return;

                            // A port is invalid if it already exists in the list of consignment ports
                            let stopIsInvalid = consignmentPorts.filter(cp => cp.id === dp.id).length > 1;
                            return (
                                <Row key={`dp_${stopIndex}`} className="mb-3">
                                    <Col xs={12}>
                                        <Row >
                                            <Col xs={12} className="darkblue field-label mb-1">
                                                Stop {stopIndex}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <Form.Group>
                                                    <InputGroup >
                                                        <Form.Select
                                                            isInvalid={stopIsInvalid}
                                                            aria-label="PortStop"
                                                            aria-describedby={`port-stop-${stopIndex}`}
                                                            id={`port-stop-${stopIndex}`}
                                                            onChange={(e) => {
                                                                let selectedPort = destinationPorts.find(p => Number(p.id) === Number(e.target.value));
                                                                updateStopConsignmentPort(selectedPort, stopIndex);
                                                            }}
                                                            value={dp?.id}
                                                        >
                                                            {destinationPorts && destinationPorts.map((destPort, i) => {
                                                                return (
                                                                    <option key={`stop_port_${stopIndex}_${i}_${destPort.portName}`} value={destPort?.id}>{`${destPort.countryName} - ${destPort.portName}`}</option>
                                                                )
                                                            })
                                                            }
                                                        </Form.Select>
                                                        <Button onClick={(e) => { e.preventDefault(); removeStopConsignmentPort(stopIndex); }}><FontAwesomeIcon icon={faXmark} /></Button>
                                                    </InputGroup>
                                                </Form.Group>
                                                {stopIsInvalid ?
                                                    (
                                                        <div className="validation-error-tooltip">
                                                            <Tooltip
                                                                anchorSelect={`#port-stop-${stopIndex}`}
                                                                variant="error"
                                                                content='The same port can not be added twice.'
                                                            />
                                                        </div>
                                                    ) : null
                                                }

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })}
                        <Row>
                            <Col xs={12} className="darkblue field-label mb-1">
                                Final Destination Port
                            </Col>
                            <Col xs={12}>
                                <Form.Select
                                    aria-label="Final Destination Port"
                                    onChange={(e) => {
                                        let selectedPort = destinationPorts.find(p => Number(p.id) === Number(e.target.value));
                                        updateSelectedDestinationPort(selectedPort);
                                    }}
                                    value={selectedDestinationPort?.id}
                                >
                                    {destinationPorts && destinationPorts.map((destPort, i) => {
                                        return (
                                            <option key={`destination_port__noStops_${i}_${destPort.countryName}_${destPort.portName}`} value={destPort?.id}>{`${destPort.countryName} - ${destPort.portName}`}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                        </Row>
                        {canAddDischargePort ?
                            (
                                <Col xs={12}>
                                    <Button onClick={(e) => { e.preventDefault(); addStopConsignmentPort(); }} className="mt-3 border-0 btn-secondary cc-add-port-button">
                                        <FontAwesomeIcon icon={faCirclePlus} className="primary-green" /> Add Discharge port
                                    </Button>

                                </Col>
                            ) : null
                        }
                        <Row className="mt-4">
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} className="darkblue field-label mb-1">
                                        Estimated departure
                                    </Col>
                                </Row>
                                <Row>
                                    <DateTimePicker
                                        port={selectedDeparturePort}
                                        datetime={estimatedDepartureDateTime}
                                        isValid={isEstimatedDepartureDateTimeValid}
                                        errorMessage={estimatedDepartureErrorMessages.length > 0 ? estimatedDepartureErrorMessages.join("\n") : ""}
                                        onChange={(date) => {
                                            setEstimatedDepartureDateTime(date);
                                        }}
                                        showHeadLabel={true}
                                        endLabel="Local time"
                                        showUTCTime={true}
                                        showTime={true}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12}>
                                <Row>
                                    <Col xs={12} className="darkblue field-label mb-1">
                                        Estimated Arrival
                                    </Col>
                                </Row>
                                <Row>
                                    <DateTimePicker
                                        port={selectedDestinationPort}
                                        datetime={estimatedArrivalDateTime}
                                        isValid={isEstimatedArrivalDateTimeValid}
                                        errorMessage={estimatedArrivalErrorMessages.length > 0 ? estimatedArrivalErrorMessages.join("\n") : ""}
                                        onChange={(date) => {
                                            setEstimatedArrivalDateTime(date);
                                        }}
                                        showHeadLabel={true}
                                        endLabel="Local time"
                                        showUTCTime={true}
                                        showTime={true}
                                    />
                                </Row>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                Vessel Name
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Select
                                    onChange={(e) => {
                                        setVesselID(e.target.value);
                                    }}
                                    value={vesselID}
                                >
                                    {vessels && vessels.length > 0 && vessels.map(v => {
                                        return (
                                            <option key={`vessel_${v.vesselName}`} value={v.id}>{v.vesselName}</option>
                                        )
                                    })

                                    }

                                </Form.Select>
                            </Col>
                        </Row>
                        {userCtx.user?.userTypeID !== UserType.REGULATORY &&
                            <>
                                <Row className="mt-4">
                                    <Col xs={12}>
                                        <div className="heading-text darkblue">Feed plan</div>
                                        <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Row className='mb-1'>
                                            <Col xs={6}></Col>
                                            <Col xs={2} className="text-center field-label">Pellets</Col>
                                            <Col xs={2} className="text-center field-label">Grain</Col>
                                            <Col xs={2} className="text-center field-label">Roughage</Col>
                                        </Row>
                                        <Row className="mb-2 pb-2 cc-port-loading-feed">
                                            <Col xs={6} className="cc-feed-plan-row-label">ROB from previous voyage</Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtPelletsROB'
                                                            isInvalid={!robPelletsValid}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setROBPellets(e.currentTarget.value);
                                                                setROBPelletsValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(robPellets) === 0) {
                                                                    setROBPellets("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (robPellets === "") {
                                                                    setROBPellets(0);
                                                                }
                                                            }}
                                                            value={robPellets}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!robPelletsValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robPelletsValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtPelletsROB`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid pellets ROB amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtGrainROB'
                                                            isInvalid={!robGrainValid}
                                                            onChange={(e) => {
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setROBGrain(e.currentTarget.value);
                                                                setROBGrainValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(robGrain) === 0) {
                                                                    setROBGrain("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (robGrain === "") {
                                                                    setROBGrain(0);
                                                                }
                                                            }}
                                                            value={robGrain}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!robGrainValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robGrainValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtGrainROB`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid grain ROB amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group></Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtRoughageROB'
                                                            isInvalid={!robRoughageValid}
                                                            onChange={(e) => {
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setROBRoughage(e.currentTarget.value);
                                                                setROBRoughageValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(robRoughage) === 0) {
                                                                    setROBRoughage("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (robRoughage === "") {
                                                                    setROBRoughage(0);
                                                                }
                                                            }}
                                                            value={robRoughage}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!robRoughageValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robGrainValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtRoughageROB`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid roughage ROB amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={6} className="cc-feed-plan-row-label">Loading in {consignmentPorts[0]?.portName} (Departure)</Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtPelletsLoading'
                                                            isInvalid={!loadedPelletsValid}
                                                            onChange={(e) => {
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setDepartureLoadingPellets(e.currentTarget.value);
                                                                setLoadedPelletsValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(departureLoadingPellets) === 0) {
                                                                    setDepartureLoadingPellets("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (departureLoadingPellets === "") {
                                                                    setDepartureLoadingPellets(0);
                                                                }
                                                            }}
                                                            value={departureLoadingPellets}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!loadedPelletsValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robGrainValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtPelletsLoading`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid pellets loading amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtGrainLoading'
                                                            isInvalid={!loadedGrainValid}
                                                            onChange={(e) => {
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setDepartureLoadingGrain(e.currentTarget.value);
                                                                setLoadedGrainValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(departureLoadingGrain) === 0) {
                                                                    setDepartureLoadingGrain("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (departureLoadingGrain === "") {
                                                                    setDepartureLoadingGrain(0);
                                                                }
                                                            }}
                                                            value={departureLoadingGrain}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!loadedGrainValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robGrainValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtGrainLoading`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid grain loading amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={2}>
                                                <Form.Group>
                                                    <InputGroup>
                                                        <Form.Control
                                                            id='txtRoughageLoading'
                                                            isInvalid={!loadedRoughageValid}
                                                            onChange={(e) => {
                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                setDepartureLoadingRoughage(e.currentTarget.value);
                                                                setLoadedRoughageValid(true);
                                                            }}
                                                            onFocus={() => {
                                                                if (Number(departureLoadingRoughage) === 0) {
                                                                    setDepartureLoadingRoughage("");
                                                                }
                                                            }}
                                                            onBlur={() => {
                                                                if (departureLoadingRoughage === "") {
                                                                    setDepartureLoadingRoughage(0);
                                                                }
                                                            }}
                                                            value={departureLoadingRoughage}
                                                            className="inputGroupWithAppend errorLeft"
                                                        />
                                                        <InputGroup.Text className={`inputGroupAppend ${!loadedRoughageValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                        {
                                                            !robGrainValid ? (
                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip
                                                                        anchorSelect={`#txtRoughageLoading`}
                                                                        place="top"
                                                                        variant="error"
                                                                        content="A valid roughage loading amount is required."
                                                                    />
                                                                </div>
                                                            ) : null
                                                        }
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="bg-grey-transparent py-2">
                                    <Col xs={6} className="field-label darkblue" style={{ lineHeight: "27px" }}>Total all feed ({formatUpToSpecifiedPrecision(totalFeed, 2)}t)</Col>
                                    <Col xs={2} className="text-end pe-4" style={{ lineHeight: "27px" }}><span className="secondary-grey" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalPellets, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                    <Col xs={2} className="text-end pe-4" style={{ lineHeight: "27px" }}><span className="secondary-grey" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalGrain, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                    <Col xs={2} className="text-end pe-4" style={{ lineHeight: "27px" }}><span className="secondary-grey" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalRoughage, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                </Row>
                            </>
                        }
                        <Row className="mb-4 mt-5">
                            <Col xs={12} className='voluntary-data-bg p-2'>
                                <Form>
                                    <Form.Check
                                        type={`checkbox`}
                                        id='chkAlternatingPenSpacing'
                                        label={`This consignment uses alternate pen spacing`}
                                        checked={isUseAlternatePenSpacing === true}
                                        onChange={() => setIsUseAlternatePenSpacing(!isUseAlternatePenSpacing)}
                                        className="field-label secondary-grey"
                                    />
                                </Form>
                            </Col>
                        </Row>

                    </Container>
                </Row>
                <Container className="button-container">
                    <Row className="w-100 pt-4" style={{ position: "relative", top: "-300px" }}>
                        <Stack direction="horizontal" gap={2}>
                            <Button variant="primary" onClick={goToPersonnel} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Next</Button>
                            <Button className="ms-2 me-auto" variant="secondary" onClick={goToDashboard} size="lg" style={{ minWidth: "150px" }}>Previous</Button>
                        </Stack>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
};

export default Consignment_VoyageDetails;