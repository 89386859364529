import React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import SheepHotSpotsDeck from "./SheepHotSpotsDeck";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import ReadWriteFormRadio from "../ReadWriteElements/ReadWriteFormRadio";

const SheepHotSpots = () => {
    const { data, updateSheepHasHotSpots } = useContext(DeckRecordOtherContext);
    const [triggerClearMissingAffectedDecks, setTriggerClearMissingAffectedDecks] = useState(-1);
    const [allSheepDecksEmpty, setAllSheepDecksEmpty] = useState(false);

    const onTriggerClearMissingAffectedDecks = () => {
        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1)
    }

    useEffect(() => {
        let lAllEmpty = [...data.sheepDecks].every(sd =>sd.DeckRecord.isEmpty === true);
        setAllSheepDecksEmpty(lAllEmpty);

    }, [data.sheepDecks]);
    return (
        <div>
            <div className="pb-1  field-label">Are there any hot spots or pens?</div>
            <Form>
                <ReadWriteFormRadio id='rdoSheepHotSPots_No' type={`radio`} label={`No`} name="rdoSheepHotSpots" checked={!data.hasSheepHotSpots}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasHotSpots(false);
                    }}
                    disabled={allSheepDecksEmpty}
                />
                <ReadWriteFormRadio type={`radio`} id='rdoSheepHotSPots_Yes' label={`Yes`} name="rdoSheepHotSpots" checked={data.hasSheepHotSpots}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasHotSpots(true);
                    }}
                    disabled={allSheepDecksEmpty}
                />
            </Form>
            {
                data.hasSheepHotSpots && !allSheepDecksEmpty ?
                    <div>
                        <Row className="pt-2">
                            <Col xs={6} className="dr-column-heading">Deck Affected?</Col>
                            <Col xs={6} className="dr-column-heading">List hot spots or pens</Col>
                        </Row>
                        {
                            data.sheepDecks.map((rec, index) => (
                                <SheepHotSpotsDeck key={`sheepHotSpots${index}`} rec={rec} triggerClearMissingAffectedDeck={triggerClearMissingAffectedDecks} onTriggerClearMissingAffectedDecks={onTriggerClearMissingAffectedDecks}/>
                            ))
                        }
                    </div>
                    : null
            }
        </div>
    )
};

export default SheepHotSpots;