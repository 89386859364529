import React, { useEffect, useState} from "react";
import { Stack, Table } from "react-bootstrap";
import TreatmentOverview from "./TreatmentOverview";
import MortalitiesOverview from "./MortalitiesOverview";
import FeedRemainingOverview from "./FeedRemainingOverview";
import AvgFeedOverview from "./AvgFeedOverview";
import AvgWaterOverview from "./AvgWaterOverview";

const ConsignmentInfoTableMobile = ({consignment, idx, isStarted, componentId, outerBorder}) => {
    return (
        <>
            <Table key={`lnc_info_table_${componentId}_${idx}`} className="my-0"  style={{borderLeft: !outerBorder ? "none" : "", borderRight: !outerBorder ? "none" : ""}} bordered={outerBorder}>
                <thead>
                    <tr className="consignment-info-table-header-mobile">
                        <th className="text-center consignment-info-table-cell-border-right w-50">Treatments</th>
                        <th className="text-center">Mortalities</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="consignment-info-table-cell-mobile consignment-info-table-cell-border-right w-50">
                            <Stack direction="horizontal">
                                <TreatmentOverview consignment={consignment.Consignment} index={idx} isStarted={isStarted}/>
                            </Stack>
                        </td>
                        <td className="consignment-info-table-cell-mobile">
                            <Stack direction="horizontal">
                                <MortalitiesOverview consignment={consignment.Consignment} index={idx} isStarted={isStarted} />
                            </Stack>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Table className="my-0"  style={{borderLeft: !outerBorder ? "none" : "", borderRight: !outerBorder ? "none" : ""}}  bordered={outerBorder}>
                <thead>
                    <tr className="consignment-info-table-header-mobile">
                        <th className="text-center">Feed Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="consignment-info-table-cell-mobile">
                            <Stack direction="horizontal">
                                <div className="w-100">
                                    <Stack direction="vertical" className="w-100">
                                        <FeedRemainingOverview consignment={consignment.Consignment} index={idx} isStarted={isStarted}/>
                                    </Stack>
                                </div>
                            </Stack>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Table className="my-0"  style={{borderLeft: !outerBorder ? "none" : "", borderRight: !outerBorder ? "none" : ""}}  bordered={outerBorder}>
                <thead>
                    <tr className="consignment-info-table-header-mobile">
                        <th className="text-center consignment-info-table-cell-border-right w-50">Avg feed</th>
                        <th className="text-center">Avg water</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="consignment-info-table-cell-mobile consignment-info-table-cell-border-right w-50">
                            <AvgFeedOverview consignment={consignment.Consignment} index={idx} isStarted={isStarted} />
                        </td>
                        <td className="consignment-info-table-cell-mobile">
                            <AvgWaterOverview consignment={consignment.Consignment} index={idx} isStarted={isStarted} />
                        </td>                        
                    </tr>
                </tbody>
            </Table>
        </>
    )
}

export default ConsignmentInfoTableMobile;