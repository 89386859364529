export class FeedDetail {
    ID;
    Pellets;
    Grain;
    Roughage;

    constructor({id, pellets, grain, roughage}) {
        this.ID = id;
        this.Pellets = pellets;
        this.Grain = grain;
        this.Roughage = roughage;
    }
}