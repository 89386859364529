import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { AnimalNumberValidationRules, parseNumericInput } from "../../utils/InputValidator";

const ManagementPlanHeadCount = ({planData, onDelete, onChange, noCross}) => {
    const [headCount, setHeadCount] = useState(0);
    const [selectedSpecies, setSelectedSpecies] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedSpeciesAndClass, setSelectedSpeciesAndClass] = useState("");
    const [selectedManagementPlan, setSelectedManagementPlan] = useState("");
    const [speciesAndClassOptions, setSpeciesAndClassOptions] = useState([]);
    const [speciesOptions, setSpeciesOptions] = useState([{id: 'empty', speciesName: ''}]);
    const [classOptions, setClassOptions] = useState([{id: 'empty', speciesName: ''}]);
    const [managementPlanOptions, setManagementPlanOptions] = useState([{id: 'empty', managementPlanName: ''}]);
    const [combinedOptions, setCombinedOptions] = useState([]);

    useEffect(() => {
        setSpeciesOptions([...[{id: 'empty', speciesName: ''}], ...planData.speciesOptions]);
        setClassOptions([...[{id: 'empty', speciesName: ''}], ...planData.classOptions]);
        setSpeciesAndClassOptions([...[], ...planData.speciesAndClassOptions]);
        setManagementPlanOptions([...[{id: 'empty', managementPlanName: ''}], ...planData.managementPlanOptions]);
        if (planData.selectedSpecies) {
            setSelectedSpecies(planData.selectedSpecies);
        }
        if (planData.selectedClass) {
            setSelectedClass(planData.selectedClass);
        }
        if (planData.selectedSpeciesAndClass) {
            setSelectedSpeciesAndClass(planData.selectedSpeciesAndClass);
        }
        
    }, [planData.speciesOptions, planData.classOptions, planData.speciesAndClassOptions, planData.managementPlanOptions, planData.selectedSpecies, planData.selectedClass]);
    
    useEffect(() => {
        if (!isNaN(Number(planData.numberOfHead))) {
            setHeadCount(Number(planData.numberOfHead))
        }
    },[planData.numberOfHead])

    useEffect(() => {
        let speciesRelatedManagementPlans = planData.managementPlanOptions.filter(po => {
            if (Number(po?.managementPlanSpeciesID) === Number(planData.speciesOptions.find(so => Number(so?.id) === Number(selectedSpecies))?.id)) return true;

            if (po?.id === 'empty') return true;
            return false;
        });
        let allManagementPlans = [{id: 'empty', managementPlanName: ''}, ...speciesRelatedManagementPlans];
        setManagementPlanOptions(allManagementPlans);

    }, [selectedSpecies, selectedClass])

    useEffect(() => {
        if (speciesAndClassOptions.length === 0) return;
        let combined = buildCombinedOptions();
        let mapOfOptionNameSet = new Map([...combined.map(c => [c.optionName, c]).reverse()]);
        let iterableValuesSet = mapOfOptionNameSet.values(mapOfOptionNameSet);
        let uniqueValuesArray = Array.from(iterableValuesSet);
        setCombinedOptions(uniqueValuesArray);
    }, [speciesAndClassOptions])

    const buildCombinedOptions = () => {
        return speciesAndClassOptions.map(opt => {
            let components = opt.split(",");
            let speciesComponent = Number(components[0]);
            let classComponent = Number(components[1]);
            let optionName = `${speciesOptions.find(sp => Number(sp.id) === speciesComponent)?.speciesName} - ${classOptions.find(co => Number(co.id) === classComponent)?.className}`;
            return {
                option: opt,
                optionName: optionName
            }
        });
    }

    useEffect(() => {
        if (selectedManagementPlan === 'empty') return;
        if (selectedManagementPlan === "" && isNaN(Number(planData?.selectedManagementPlan))) {
            setSelectedManagementPlan(managementPlanOptions[0].id);
        }
        if (!isNaN(Number(planData?.selectedManagementPlan))) {
            setSelectedManagementPlan(Number(planData.selectedManagementPlan));
        }
    }, [managementPlanOptions, selectedManagementPlan, planData.selectedManagementPlan])

    useEffect(() => {
        onChange(planData.id, planData.selectedSpecies, planData.selectedClass, planData.selectedManagementPlan, planData.numberOfHead);
    }, [planData.selectedSpecies, planData.selectedClass, planData.selectedManagementPlan, planData.numberOfHead]);

    const updateSelectedSpeciesAndClass = (speciesAndClass) => {
        let components = speciesAndClass.split(",");
        if (components[0] === -1) return;
        let speciesComponent = Number(components[0]);
        let classCompoent = Number(components[1]);
        setSelectedSpecies(speciesComponent);
        setSelectedClass(classCompoent);
        setSelectedSpeciesAndClass(speciesAndClass);
        planData.selectedSpecies = speciesComponent;
        planData.selectedClass = classCompoent;
    }

    return (
        <Row className="mb-2">
            <Col xs={3}>
                <Form.Select
                    onChange={(e) => {
                        updateSelectedSpeciesAndClass(e.target.value);
                        planData.selectedSpeciesAndClass = e.target.value;
                        }
                    }
                    value={selectedSpeciesAndClass}
                >
                    <option key={`species_opt_${-1}`} value={'-1, -1'}></option>
                    { 
                        combinedOptions.map((opt, i) => {
                            return (<option key={`species_opt_${i}`} value={opt.option}>{opt.optionName}</option>);
                        })
                    }
                </Form.Select>
            </Col>
            
            <Col xs={6}>
                <Form.Select
                    onChange={(e) => {
                        setSelectedManagementPlan(e.target.value);
                        planData.selectedManagementPlan = e.target.value;
                    }}
                    value={selectedManagementPlan}
                >
                { 
                    managementPlanOptions.map((opt, i) => {
                        return (<option key={`plan_opt_${i}`} value={opt.id}>{opt.managementPlanName}</option>);
                    })
                }
                </Form.Select>
            </Col>
            <Col xs={2}>
                <Form.Control 
                    onChange={(e) => {
                        parseNumericInput(e, new AnimalNumberValidationRules());
                        setHeadCount(e.target.value);
                        planData.numberOfHead = Number(e.target.value);
                    }}
                    defaultValue={0}
                    value={headCount}
                />
            </Col>
            <Col>
                    {
                        !noCross ? 
                        (
                            <Button 
                            style={{border: "none"}}
                                variant="secondary" 
                                onClick={() => { 
                                    onDelete(planData.id) 
                                }}
                            >
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </Button>
                        ) : null
                        }
            </Col>
        </Row>
    )
}

export default ManagementPlanHeadCount;