export class RegisteredEstablishmentConsignmentMortality {
    ID;
    RegisteredEstablishmentConsignmentID;
    MortalityDateUTC;
    PenName;
    CommodityTypeID;
    IsEuthanased;
    IsPostMortemConducted;
    NoPostMortemConductedReason;
    ConditionLevelThreeID;
    TagTypeID;
    TagNumber;
    PICTransferredFrom;
    IsMortalityFactorAggression;
    IsMortalityFactorFeedIssues;
    IsMortalityFactorWaterIssues;
    IsMortalityFactorShyFeeder;
    IsMortalityFactorWeatherConditions;
    IsMortalityFactorVentilationIssues;
    FurtherInformation;
    IsMortalityFactorOther;
    ArrivalDateUTC;
    SpeciesID;
    ClassID;
    ApplicableManagementPlans;

    constructor({id, registeredEstablishmentConsignmentID, mortalityDateUTC, penName, commodityTypeID, isEuthanased, isPostMortemConducted, noPostMortemConductedReason,
        conditionLevelThreeID, tagTypeID, tagNumber, picTransferredFrom, isMortalityFactorAggression, isMortalityFactorFeedIssues, isMortalityFactorWaterIssues, isMortalityFactorShyFeeder,
        isMortalityFactorWeatherConditions, isMortalityFactorVentilationIssues, furtherInformation, isMortalityFactorOther, arrivalDateUTC, speciesID, classID, applicableManagementPlans}) {
            this.ID = id;
            this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
            this.MortalityDateUTC = mortalityDateUTC;
            this.PenName = penName;
            this.CommodityTypeID = commodityTypeID;
            this.IsEuthanased = isEuthanased;
            this.IsPostMortemConducted = isPostMortemConducted;
            this.NoPostMortemConductedReason = noPostMortemConductedReason;
            this.ConditionLevelThreeID = conditionLevelThreeID;
            this.TagTypeID = tagTypeID;
            this.TagNumber = tagNumber;
            this.PICTransferredFrom = picTransferredFrom;
            this.IsMortalityFactorAggression = isMortalityFactorAggression;
            this.IsMortalityFactorFeedIssues = isMortalityFactorFeedIssues;
            this.IsMortalityFactorWaterIssues = isMortalityFactorWaterIssues;
            this.IsMortalityFactorShyFeeder = isMortalityFactorShyFeeder;
            this.IsMortalityFactorWeatherConditions = isMortalityFactorWeatherConditions;
            this.IsMortalityFactorVentilationIssues = isMortalityFactorVentilationIssues;
            this.FurtherInformation = furtherInformation;
            this.IsMortalityFactorOther = isMortalityFactorOther;
            this.ArrivalDateUTC = arrivalDateUTC;
            this.SpeciesID = speciesID;
            this.ClassID = classID;
            this.ApplicableManagementPlans = applicableManagementPlans;
    }

}