import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Form, Stack, Alert } from "react-bootstrap";
import { StringIsNullUndefinedOrEmpty } from '../../utils/StringUtils';
import { Tooltip } from 'react-tooltip';
import { NumericValidationRules, parseNumericInput, isValidEmail, isValidMobile } from '../../utils/InputValidator';
import { getExporter, getLAESForRegisteredEstablishments, patchExporter, postExporter, getOrgTypes, postRegisteredEstablishment, patchRegisteredEstablishment, postRegisteredEstablishmentExporter, patchRegisteredEstablishmentExporter } from '../../api/apiAccess';
import DateTimePicker from '../DateTimePicker';
import { LAE } from '../../classes/LAE';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Organisation_Add_Edit_Modal = ({ organisation, showModal, setShowModal, onAdd }) => {
    const numericValidationRules = new NumericValidationRules({ allowLeadingZerosForWholeNumbers: true, allowNegative: false, allowDecimal: false });

    const [orgTypes, setOrgTypes] = useState([]);

    const [id, setID] = useState(null);

    const [orgName, setOrgName] = useState("");
    const [validOrgName, setValidOrgName] = useState(true);

    const [orgType, setOrgType] = useState(1);

    const [contactPhone, setContactPhone] = useState("");
    const [validContactPhone, setValidContactPhone] = useState(true);

    const [licenseNumber, setLicenseNumber] = useState("");
    const [validLicenseNumber, setValidLicenseNumber] = useState(true);

    const [licenseExpirationDate, setLicenseExpirationDate] = useState(null);
    const [validLicenseExpirationDate, setValidLicenseExpirationDate] = useState(true);

    const [isActive, setIsActive] = useState(true);

    const [authSigName, setAuthSigName] = useState("");
    const [validAuthSigName, setValidAuthSigName] = useState(true);

    const [authSigEmail, setAuthSigEmail] = useState("");
    const [validAuthSigEmail, setValidAuthSigEmail] = useState(true);

    const [authSigMobile, setAuthSigMobile] = useState("");
    const [validAuthSigMobile, setValidAuthSigMobile] = useState(true);

    const [isEditMode, setIsEditMode] = useState(false);
    const [headerText, setHeaderText] = useState("Add New Organisation");


    // Only applicable to RE or RE/Exporters
    const [laes, setLaes] = useState([]);
    const [laeCountValid, setLaeCountValid] = useState(true);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        clearCurrentErrors();

        const populatePage = (orgID) => {
            const getOrgAndPopulate = async (orgID) => {
                let lResponse = await getExporter(orgID);

                if (lResponse?.status === 200) {
                    let exporter = lResponse.data;

                    setID(exporter.id);
                    setOrgName(exporter.exporterName);
                    setContactPhone(exporter.phoneNumber);

                    // have to do this so we display exactly what the user enters otherwise automatic conversion takes place
                    if (exporter.organisationTypeID === 1 || exporter.organisationTypeID === 3) {
                        setLicenseNumber(exporter.licenceNumber);
                        let lDateTimeParts = exporter.licenceExpiryDateUTC.split("T");
                        let lDateParts = lDateTimeParts[0].split("-");
                        let lDate = new Date(lDateParts[0], Number(lDateParts[1]) - 1, lDateParts[2]);
                        setLicenseExpirationDate(lDate);
                    }

                    setIsActive(exporter.isActive);
                    setAuthSigName(exporter.authorisedSignatoryName);
                    setAuthSigEmail(exporter.authorisedSignatoryEmail);
                    setAuthSigMobile(exporter.authorisedSignatoryPhone);

                    // Populate LAE
                    if (exporter.organisationTypeID === 2 || exporter.organisationTypeID === 3) {
                        let lLAES = []
                        let lUniqueID = 1;

                        let lExistingLAES = await getLAESForRegisteredEstablishments(orgID);

                        if (lExistingLAES.status === 200) {
                            if (lExistingLAES.data && lExistingLAES.data.length > 0) {
                                lExistingLAES.data.forEach(lae => {
                                    lLAES.push(new LAE(lUniqueID, lae.id, lae.laeNumber, lae.laeName));
                                    lUniqueID += 1;
                                })
                            }
                        }

                        setLaes(lLAES);
                    }
                }
                else {
                    setErrorMessage("Unable to get exporter details.");
                    setShowErrorMessage(true);
                }
            }

            getOrgAndPopulate(orgID);
        }

        const fetchOrgTypes = async () => {
            let lResponse = await getOrgTypes();
            if (lResponse?.status === 200) {
                setOrgTypes(lResponse.data);
            }
            else {
                setOrgTypes([]);
            }
        }

        fetchOrgTypes();
        if (organisation) { // edit
            setIsEditMode(true);
            setHeaderText("View/Edit Organisation");
            setOrgType(organisation.orgTypeID);
            populatePage(organisation.id);
        }
        else { // add
            setIsEditMode(false);
            setHeaderText("Add New Organisation");
            setOrgType(1);
            clearInputs();
        }
    }, [organisation]);


    useEffect(() => {
        if (orgType === 1) {
            setLaes([]);
            setLaeCountValid(true);
        }
        else {
            if (laes.length === 0) {
                let lLaes = [];
                lLaes.push(new LAE(1, null, "", "", true, true));
                setLaes(lLaes);
            }
            if (orgType === 2) {
                setLicenseNumber("");
                setLicenseExpirationDate(null);
            }
        }
    }, [orgType])

    const addLAE = () => {
        setLaeCountValid(true);
        let lUpdated = [...laes];

        let lUniqueID = 1;
        if (lUpdated && lUpdated.length > 0) {
            let maxObj = lUpdated.reduce(function (prev, current) {
                return (prev.UniqueIDForUI > current.UniqueIDForUI) ? prev : current
            });

            lUniqueID = maxObj.UniqueIDForUI + 1;
        }

        lUpdated.push(new LAE(lUniqueID, null, "", ""));
        setLaes(lUpdated);
    }

    const setLAENumber = (number, uniqueID) => {
        let lUpdated = [...laes];
        let lMatch = lUpdated.find(lae => lae.UniqueIDForUI === uniqueID);

        if (lMatch) {
            lMatch.Number = number;
            lMatch.NumberValid = true;
        }

        setLaes(lUpdated);
    }

    const setLAEName = (name, uniqueID) => {
        let lUpdated = [...laes];
        let lMatch = lUpdated.find(lae => lae.UniqueIDForUI === uniqueID);

        if (lMatch) {
            lMatch.Name = name;
            lMatch.NameValid = true;
        }

        setLaes(lUpdated);
    }

    const deleteLAE = (uniqueID) => {
        let lUpdated = [...laes];
        let lMatch = lUpdated.find(lae => lae.UniqueIDForUI === uniqueID);

        if (lMatch) {
            if (lMatch.ID === null) {
                lUpdated = lUpdated.filter(lae => lae.ID !== lMatch.ID);
            }
            else {
                lMatch.ToBeDeleted = true;
            }
        }

        setLaes(lUpdated);
    }

    const clearCurrentErrors = () => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setValidOrgName(true);
        setValidContactPhone(true);
        setValidLicenseNumber(true);
        setValidLicenseExpirationDate(true);
        setValidAuthSigName(true);
        setValidAuthSigMobile(true);
        setValidAuthSigEmail(true);
        setLaeCountValid(true);
    }

    const clearInputs = () => {
        setID(null);
        setOrgName("");
        setContactPhone("");
        setLicenseNumber("");
        setLicenseExpirationDate(null);
        setIsActive(true);
        setAuthSigName("");
        setAuthSigEmail("");
        setAuthSigMobile("");
        setShowErrorMessage(false);
        setShowSuccessMessage(false);

        let lLAEs = [];
        lLAEs.push(new LAE(1, null, "", ""));
        setLaes(lLAEs);
    }

    const save = () => {
        setShowErrorMessage(false);

        const validateNameAndContactPhoneNumber = async () => {
            let lValidName = !StringIsNullUndefinedOrEmpty(orgName);
            setValidOrgName(lValidName);

            let lValidContactPhone = isValidMobile(contactPhone);
            setValidContactPhone(lValidContactPhone);

            let lValid = lValidName && lValidContactPhone;
            return lValid;
        }

        const validateLicense = async () => {
            let lValidLicence = !StringIsNullUndefinedOrEmpty(licenseNumber);
            setValidLicenseNumber(lValidLicence);

            let lValidLicenceExpiryDate = false;
            if (!StringIsNullUndefinedOrEmpty(licenseExpirationDate) && licenseExpirationDate instanceof Date && !isNaN(licenseExpirationDate)) {
                lValidLicenceExpiryDate = true;
            }
            setValidLicenseExpirationDate(lValidLicenceExpiryDate);

            let lValid = lValidLicence && lValidLicenceExpiryDate;
            return lValid;
        }

        const validateAuthSignatory = async () => {
            let lValidAuthSigName = !StringIsNullUndefinedOrEmpty(authSigName);
            setValidAuthSigName(lValidAuthSigName);

            let lValidAuthSigEmail = isValidEmail(authSigEmail);
            setValidAuthSigEmail(lValidAuthSigEmail);

            let lValidAuthSigPhone = isValidMobile(authSigMobile);
            setValidAuthSigMobile(lValidAuthSigPhone);

            let lValid = lValidAuthSigName && lValidAuthSigEmail && lValidAuthSigPhone;
            return lValid;
        }

        const validateLAEs = async () => {
            let lValid = true;

            let lUpdatedLaes = [...laes];

            let lActiveLaes = [...lUpdatedLaes].filter(lae => lae.ToBeDeleted === false);

            if (lActiveLaes.length > 0) {
                setLaeCountValid(true);
                lActiveLaes.forEach(lae => {
                    let lNumberValid = !StringIsNullUndefinedOrEmpty(lae.Number);
                    let lNameValid = !StringIsNullUndefinedOrEmpty(lae.Name);
                    lae.NumberValid = lNumberValid;
                    lae.NameValid = lNameValid;

                    if (lNumberValid === false || lNameValid === false) {
                        lValid = false;
                    }
                })
            }
            else {
                lValid = false;
                setLaeCountValid(false);
            }

            setLaes(lUpdatedLaes);

            return lValid;
        }

        // have to do this so we save exactly what the user enters
        const getLicenseExpiryDate = async () => {
            let lYear = licenseExpirationDate.getFullYear().toString();
            let lMonth = (licenseExpirationDate.getMonth() + 1).toString();
            let lDay = licenseExpirationDate.getDate().toString();

            if (lMonth.length === 1) {
                lMonth = "0" + lMonth;
            }
            if (lDay.length === 1) {
                lDay = "0" + lDay;
            }

            let lDate = lYear + "-" + lMonth + "-" + lDay + "T00:00:00Z";

            return lDate;
        }

        const validateAndSaveExporter = async () => {
            let lValidNameAndPhoneNumber = await validateNameAndContactPhoneNumber();
            let lValidAuthSig = await validateAuthSignatory();
            let lValidLicenceNumberAndDate = await validateLicense();

            if (lValidNameAndPhoneNumber && lValidLicenceNumberAndDate && lValidAuthSig) {
                let lDate = await getLicenseExpiryDate();

                let lResult;

                if (isEditMode === true) {
                    lResult = await patchExporter(id, orgName, contactPhone, licenseNumber, lDate, isActive, authSigName, authSigEmail, authSigMobile);
                }
                else {
                    lResult = await postExporter(orgName, contactPhone, licenseNumber, lDate, isActive, authSigName, authSigEmail, authSigMobile);
                }

                if (lResult.status == 200) {
                    if (isEditMode === false) {
                        setShowModal(false);
                        clearInputs();
                    }
                    else {
                        setShowSuccessMessage(true);

                        setTimeout(() => {
                            setShowSuccessMessage(false);
                        }, 15000);
                    }
                    onAdd();
                }
                else if (lResult.status == 400) {
                    setShowErrorMessage(true);
                    setErrorMessage(lResult.data);
                }
                else {
                    let lErrText = "Unable to add new exporter";
                    if (isEditMode === true) {
                        lErrText = "Unable to update exporter"
                    }

                    setErrorMessage(lErrText);
                    setShowErrorMessage(true);
                }
            }
        }

        const validateAndSaveRegisteredEstablishment = async () => {
            let lValidNameAndPhoneNumber = await validateNameAndContactPhoneNumber();
            let lValidAuthSig = await validateAuthSignatory();
            let lValidLAEs = await validateLAEs();

            if (lValidNameAndPhoneNumber && lValidLAEs && lValidAuthSig) {
                let lResult;

                if (isEditMode === true) {
                    lResult = await patchRegisteredEstablishment(id, orgName, contactPhone, isActive, laes, authSigName, authSigEmail, authSigMobile);
                }
                else {
                    lResult = await postRegisteredEstablishment(orgName, contactPhone, isActive, laes, authSigName, authSigEmail, authSigMobile);
                }

                if (lResult.status == 200) {
                    if (isEditMode === false) {
                        setShowModal(false);
                        clearInputs();
                    }
                    else {
                        setShowSuccessMessage(true);

                        setTimeout(() => {
                            setShowSuccessMessage(false);
                        }, 15000);
                    }
                    onAdd();
                }
                else if (lResult.status == 400) {
                    setShowErrorMessage(true);
                    setErrorMessage(lResult.data);
                }
                else {
                    let lErrText = "Unable to add new registered establishment";
                    if (isEditMode === true) {
                        lErrText = "Unable to update registered establishment"
                    }
                    setErrorMessage(lErrText);
                    setShowErrorMessage(true);
                }
            }
        }

        const validateAndSaveExporterRegisteredEstablishmentCombination = async () => {
            let lValidNameAndPhoneNumber = await validateNameAndContactPhoneNumber();
            let lValidAuthSig = await validateAuthSignatory();
            let lValidLicense = await validateLicense();
            let lValidLAEs = await validateLAEs();

            if (lValidNameAndPhoneNumber && lValidLicense && lValidLAEs && lValidAuthSig) {
                let lResult;
                let lDate = await getLicenseExpiryDate();

                if (isEditMode === true) {
                    lResult = await patchRegisteredEstablishmentExporter(id, orgName, contactPhone, isActive, licenseNumber, lDate, laes, authSigName, authSigEmail, authSigMobile);
                }
                else {
                    lResult = await postRegisteredEstablishmentExporter(orgName, contactPhone, isActive, licenseNumber, lDate, laes, authSigName, authSigEmail, authSigMobile);
                }

                if (lResult.status == 200) {
                    if (isEditMode === false) {
                        setShowModal(false);
                        clearInputs();
                    }
                    else {
                        setShowSuccessMessage(true);

                        setTimeout(() => {
                            setShowSuccessMessage(false);
                        }, 15000);
                    }
                    onAdd();
                }
                else if (lResult.status == 400) {
                    setShowErrorMessage(true);
                    setErrorMessage(lResult.data);
                }
                else {
                    let lErrText = "Unable to add new RE/Exporter";
                    if (isEditMode === true) {
                        lErrText = "Unable to update RE/Exporter"
                    }
                    setErrorMessage(lErrText);
                    setShowErrorMessage(true);
                }
            }
        }

        if (orgType === 1) {
            validateAndSaveExporter();
        }
        else if (orgType === 2) {
            validateAndSaveRegisteredEstablishment();
        } else if (orgType === 3) {
            validateAndSaveExporterRegisteredEstablishmentCombination();
        }
    }

    return (
        <Modal show={showModal} onHide={() => { setShowModal(false); clearCurrentErrors(); clearInputs(); }}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">Organisation Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtName`}
                                        onChange={(e) => {
                                            setOrgName(e.target.value);
                                            setValidOrgName(true);
                                        }}
                                        value={orgName}
                                        isInvalid={validOrgName === false}
                                    />
                                    {
                                        validOrgName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtName`}
                                                    place="top"
                                                    variant="error"
                                                    content="An organisation name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Organisation Type</div>
                                {
                                    isEditMode === true ?
                                        <div className='field-label'>{orgTypes.find(ot => ot.id === orgType)?.name}</div>
                                        :
                                        <Form.Group>
                                            <Form.Select value={orgType} onChange={(e) => setOrgType(Number(e.target.value))} disabled={isEditMode}>
                                                {
                                                    orgTypes.map(orgTypeOpt => {
                                                        return (
                                                            <option key={`orgType_option_${orgTypeOpt.id}`} value={orgTypeOpt.id}>{`${orgTypeOpt.name}`}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </Form.Group>
                                }
                            </Stack>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Contact Phone</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtPhone`}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            parseNumericInput(e, numericValidationRules);
                                            setContactPhone(e.target.value);
                                            setValidContactPhone(true);
                                        }}
                                        value={contactPhone}
                                        isInvalid={validContactPhone === false}
                                    />
                                    {
                                        validContactPhone === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtPhone`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid mobile phone number is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>

                    {
                        orgType === 1 || orgType === 3 ?
                            <Row className="mb-3">
                                <Col xs={6}>
                                    <Stack>
                                        <div className="darkblue field-label">Exporter License Number</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtExporterLicenseNumber`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    setLicenseNumber(e.target.value);
                                                    setValidLicenseNumber(true);
                                                }}
                                                value={licenseNumber}
                                                isInvalid={validLicenseNumber === false}
                                            />
                                            {
                                                validLicenseNumber === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtExporterLicenseNumber`}
                                                            place="top"
                                                            variant="error"
                                                            content="A license number is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col xs={6}>
                                    <Stack>
                                        <div className="darkblue field-label">Expiration Date</div>
                                        <DateTimePicker
                                            datetime={licenseExpirationDate}
                                            canwrite={true}
                                            isValid={validLicenseExpirationDate}
                                            errorMessage={"An expiration date is required."}
                                            showTime={false}
                                            showUTCTime={false}
                                            onChange={(date) => {
                                                setValidLicenseExpirationDate(true);
                                                setLicenseExpirationDate(date);
                                            }}
                                            showHeadLabel={false}
                                        />
                                    </Stack>
                                </Col>
                            </Row>
                            : null
                    }

                    {
                        orgType === 2 || orgType === 3 ?
                            <Row className="mb-3">
                                <Col>
                                    <Row>
                                        <Col>
                                            <Stack direction='horizontal' gap={2}>
                                                <div>LAEs</div>
                                                {
                                                    !laeCountValid ?
                                                        <>
                                                            <FontAwesomeIcon icon={faCircleExclamation} color="#dc3545" data-tooltip-id="laeTooltip" data-tooltip-content="One or more LAE's are required." />
                                                            <div className="validation-error-tooltip">
                                                                <Tooltip id="laeTooltip" variant="error" />
                                                            </div>
                                                        </>
                                                        : null
                                                }
                                            </Stack>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="darkblue field-label">LAE Number</div>
                                        </Col>
                                        <Col xs={5}>
                                            <div className="darkblue field-label">Name/nickname</div>
                                        </Col>
                                    </Row>
                                    {
                                        laes.filter(lae => lae.ToBeDeleted === false)?.map((lae, index) => {
                                            return (
                                                <Row key={`lae_${index}`} className='mb-2'>
                                                    <Col xs={5}>
                                                        <Form.Group>
                                                            <Form.Control
                                                                id={`txtLAENumber_${index}`}
                                                                onChange={(e) => {
                                                                    setLAENumber(e.target.value, lae.UniqueIDForUI);
                                                                }}
                                                                value={lae.Number}
                                                                isInvalid={lae.NumberValid === false}
                                                            />
                                                            {
                                                                lae.NumberValid === false ?
                                                                    (<div className="validation-error-tooltip">
                                                                        <Tooltip
                                                                            anchorSelect={`#txtLAENumber_${index}`}
                                                                            place="top"
                                                                            variant="error"
                                                                            content="A LAE number is required."
                                                                        />
                                                                    </div>
                                                                    ) : null
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={5}>
                                                        <Form.Group>
                                                            <Form.Control
                                                                id={`txtLAENName_${index}`}
                                                                onChange={(e) => {
                                                                    setLAEName(e.target.value, lae.UniqueIDForUI);
                                                                }}
                                                                value={lae.Name}
                                                                isInvalid={lae.NameValid === false}
                                                            />
                                                            {
                                                                lae.NameValid === false ?
                                                                    (<div className="validation-error-tooltip">
                                                                        <Tooltip
                                                                            anchorSelect={`#txtLAENName_${index}`}
                                                                            place="top"
                                                                            variant="error"
                                                                            content="A LAE name is required."
                                                                        />
                                                                    </div>
                                                                    ) : null
                                                            }
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Button
                                                            style={{ border: "none" }}
                                                            variant="secondary"
                                                            onClick={() => {
                                                                deleteLAE(lae.UniqueIDForUI)
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faTimesCircle} />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                    <Row>
                                        <Col>
                                            <Button variant='secondary' onClick={() => addLAE()}>Add Another</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            : null
                    }

                    {
                        isEditMode === true ?
                            <Row className="mb-3">
                                <Col>
                                    <Stack direction='horizontal' gap={2}>
                                        <div className="darkblue field-label">Is Active</div>
                                        <Form.Group>
                                            <Form.Check
                                                onChange={(e) => {
                                                    setIsActive(e.target.checked);
                                                }}
                                                checked={isActive}
                                            />
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row className='mb-2'>
                        <Col>Authorised Signatory</Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtAuthSigName`}
                                        onChange={(e) => {
                                            setAuthSigName(e.target.value);
                                            setValidAuthSigName(true);
                                        }}
                                        value={authSigName}
                                        isInvalid={validAuthSigName === false}
                                    />
                                    {
                                        validAuthSigName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtAuthSigName`}
                                                    place="top"
                                                    variant="error"
                                                    content="An authorised signatory name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">Email Address</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtAuthSigEmail`}
                                        onChange={(e) => {
                                            setAuthSigEmail(e.target.value);
                                            setValidAuthSigEmail(true);
                                        }}
                                        value={authSigEmail}
                                        isInvalid={validAuthSigEmail === false}
                                    />
                                    {
                                        validAuthSigEmail === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtAuthSigEmail`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid authorised signatory email address is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Mobile Phone</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtAuthSigPhone`}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            parseNumericInput(e, numericValidationRules);
                                            setAuthSigMobile(e.target.value);
                                            setValidAuthSigMobile(true);
                                        }}
                                        value={authSigMobile}
                                        isInvalid={validAuthSigMobile === false}
                                    />
                                    {
                                        validAuthSigMobile === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtAuthSigPhone`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid authorised signatory mobile phone number is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {
                        showErrorMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='danger'>{errorMessage}</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        showSuccessMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>The organisation has been {isEditMode === true ? "updated" : "added"} successfully.</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => save()}>Save</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}

export default Organisation_Add_Edit_Modal;