export class PlannedMortalitySubmission {
    MortalityDateUTC;
    PenName;
    CommodityTypeID;
    IsEuthanased;
    IsPostMortemConducted;
    NoPostMortemConductedReason;
    ConditionLevelThreeID;
    TagTypeID;
    TagNumber;
    PICTransferredFrom;
    IsMortalityFactorAggression;
    IsMortalityFactorFeedIssues;
    IsMortalityFactorWaterIssues;
    IsMortalityFactorShyFeeder;
    IsMortalityFactorWeatherConditions;
    IsMortalityFactorVentilationIssues;
    IsMortalityFactorOther;
    ArrivalDateUTC;
    SpeciesID;
    ClassID;  
    ApplicableManagementPlanIDs;
    FurtherInformation;
    constructor(mortalityDateUTC, penName, commodityTypeID, isEuthanased, isPostMortemConducted, noPostMortemConductedReason, levelThreeConditionID, tagTypeID, tagNumber, picTransferredFrom, mortalityFactors, arrivalDateUTC, speciesID, classID, applicableManagementPlanIDs, FurtherInformation) {
        this.MortalityDateUTC = mortalityDateUTC;
        this.PenName = penName;
        this.CommodityTypeID = commodityTypeID;
        this.IsEuthanased = isEuthanased;
        this.IsPostMortemConducted = isPostMortemConducted;
        this.NoPostMortemConductedReason = noPostMortemConductedReason;
        this.ConditionLevelThreeID = levelThreeConditionID;
        this.TagTypeID = tagTypeID;
        this.TagNumber = tagNumber;
        this.PicTransferredFrom = picTransferredFrom;
        this.IsMortalityFactorAggression = mortalityFactors && mortalityFactors.find(mf => mf.id === 1 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorFeedIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 2 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorWaterIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 3 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorShyFeeder = mortalityFactors && mortalityFactors.find(mf => mf.id === 6 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorWeatherConditions = mortalityFactors && mortalityFactors.find(mf => mf.id === 4 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorVentilationIssues = mortalityFactors && mortalityFactors.find(mf => mf.id === 5 && mf.isChecked === true) !== undefined;
        this.IsMortalityFactorOther = mortalityFactors && mortalityFactors.find(mf => mf.id === 7 && mf.isChecked === true) !== undefined;
        this.ArrivalDateUTC = arrivalDateUTC;
        this.SpeciesID = speciesID;
        this.ClassID = classID;
        this.ApplicableManagementPlanIDs = applicableManagementPlanIDs  && Array.isArray(applicableManagementPlanIDs) ? [...applicableManagementPlanIDs] : [];
        this.FurtherInformation = FurtherInformation;
    }
}