export const StringIsNullUndefinedOrEmpty = (input) => {
    return input === null || input === undefined || input.length === 0;
}

export const IsNotNullAndGreaterThanZero = (value) => {
    if (Number(value) <= 0 || value === null || value === undefined || value === "") {
        return false;
    }
    else {
        return true;
    }
}

export const StringIntegerRepresentationHasNonNumericCharacters = (value) => {
    let valueArr = Array.from(value);
    let hasNonNumericCharacters = false;
    valueArr.forEach(v => {
        if (isNaN(v)) {
            hasNonNumericCharacters = true;
        }
    });
    return hasNonNumericCharacters;
}

export const IsNullOrUndefined = (input) => {
    return input === null || input === undefined;
}
