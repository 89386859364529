import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Alert, Spinner } from "react-bootstrap";
import { getAllDetailedObservationsConsignmentID } from "../../api/apiAccess";
import JsonToHtmlTable from "../../components/JsonToHtmlTable";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { getPortLocalDatetimeNoLabel } from "../../utils/common";
import { PrevDailyReportData } from "../../classes/PrevDailyReportData";
import { format } from "date-fns";
import formatcoords from "formatcoords";
import { EditViewString } from "../../components/ReadWriteElements/EditViewString";
import { UserContext } from "../..";


const View_Observations = ({ consignmentID, selectedColumnIndex, allowEditing, onSelectedColumnIndexChanged }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [jsonDataDisplayed, setJsonDataDisplayed] = useState({ "Head": [], "Body": [] });
    const [hasData, setHasData] = useState(true);
    const [errorGettingData, setErrorGettingData] = useState(false);
    const userCtx = useContext(UserContext);

    // get data
    useEffect(() => {
        const fetchAllObservationsForConsignment = async () => {
            let lResponse = await getAllDetailedObservationsConsignmentID(consignmentID);

            if (lResponse?.status === 200) {
                if (lResponse.data.length > 0) {
                    let lData = getFormattedData(lResponse.data);

                    setJsonDataDisplayed(lData);
                    setHasData(true);
                }
                else {
                    setHasData(false);
                }
            }
            else {
                setErrorGettingData(true);
            }

            setIsLoading(false);
        }

        const getFormattedData = (observations) => {
            if (observations && observations.length > 0) {
                let lHead = [
                    [[""]]
                    , [[""]]
                ];

                let lBody = [];

                let lVesselPositionRow = { groupRowHeading: "Vessel Position", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lNextPortRow = { groupRowHeading: "Next Port", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lETARow = { groupRowHeading: "Est. ETA in Local", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lSailingConditionsRow = { groupRowHeading: "Sailing Conditions", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lVentilationIssuesRow = { groupRowHeading: "Ventilation Issues", groupRowsData: [{ rowHeading: "", data: [] }] };

                let lFeedROBHeadingRow = { groupRowHeading: "Feed ROB", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lFeedROBPelletsRow = { groupRowHeading: "Pellets", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };
                let lFeedROBGrainRow = { groupRowHeading: "Grain", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };
                let lFeedROBRoughageRow = { groupRowHeading: "Roughage", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };

                let lFeedQualityHeadingRow = { groupRowHeading: "Feed Quality", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lFeedQualityMeasuredRow = { groupRowHeading: "Measured by", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };
                let lFeedQualityQualityRow = { groupRowHeading: "Quality", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };
                let lFeedQualityCommentsRow = { groupRowHeading: "Comments", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };

                let lWaterHeadingRow = { groupRowHeading: "Water", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lWaterConsumptionRow = { groupRowHeading: "Consumption", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };
                let lWaterIsIssue = { groupRowHeading: "Has Water Issue", groupRowsData: [{ rowHeading: "", data: [] }] };
                let lWaterCommentsRow = { groupRowHeading: "Comments", groupRowsData: [{ rowHeading: "", data: [] }], isSubheading: true };

                let lGenCommentsRow = { groupRowHeading: "General Comments", groupRowsData: [{ rowHeading: "", data: [] }] };

                // sort in ascending day order
                observations.sort((a, b) => (a.day.dayNumber > b.day.dayNumber) ? 1 : -1);

                observations.forEach((obs) => {
                    let lObservation = obs.observation;
                    let lPort = obs.port;
                    let lFeed = obs.feedDetail;
                    let lConditions = obs.sailingCondition;
                    let lFeedQuality = obs.feedQualityType;
                    let lFeedMeasured = obs.feedMeasurementType;

                    let lDateHeader = [
                        ["Day " + obs.day.dayNumber],
                        createLink(obs.day, "/editobservation")
                    ];
                    lHead.push(lDateHeader);

                    lVesselPositionRow.groupRowsData[0].data.push([formatcoords(lObservation.latitude, lObservation.longitude).format("DD MM ss X", { latLonSeparator: '\n', decimalPlaces: 0 })]);
                    lNextPortRow.groupRowsData[0].data.push([lPort.portName]);
                    lETARow.groupRowsData[0].data.push([getPortLocalDatetimeNoLabel(lPort, lObservation.estimatedArrivalDateUTC)]);
                    lSailingConditionsRow.groupRowsData[0].data.push([lConditions.sailingConditionName]);
                    lVentilationIssuesRow.groupRowsData[0].data.push([obs.hasVentilationIssues ? "Yes" : "No"]);

                    lFeedROBHeadingRow.groupRowsData[0].data.push([""]);
                    lFeedROBPelletsRow.groupRowsData[0].data.push([parseFloat(lFeed.pellets).toFixed(2) + "t"]);
                    lFeedROBGrainRow.groupRowsData[0].data.push([parseFloat(lFeed.grain).toFixed(2) + "t"]);
                    lFeedROBRoughageRow.groupRowsData[0].data.push([parseFloat(lFeed.roughage).toFixed(2) + "t"]);

                    lFeedQualityHeadingRow.groupRowsData[0].data.push([""]);
                    lFeedQualityMeasuredRow.groupRowsData[0].data.push([lFeedMeasured.feedMeasurementType]);
                    lFeedQualityQualityRow.groupRowsData[0].data.push([lFeedQuality.feedQualityType]);

                    let lHasFeedQualityComments = !StringIsNullUndefinedOrEmpty(lObservation.feedQualityComments);
                    if (lHasFeedQualityComments) {
                        lFeedQualityCommentsRow.groupRowsData[0].data.push([
                            // for the selcted day we want to show the actual comments rather than Yes or No
                            [new PrevDailyReportData("Yes", lObservation.feedQualityComments)]
                        ]);
                    }
                    else {
                        lFeedQualityCommentsRow.groupRowsData[0].data.push([
                            [new PrevDailyReportData("No", "No")]
                        ]);
                    }

                    lWaterHeadingRow.groupRowsData[0].data.push([""]);
                    lWaterConsumptionRow.groupRowsData[0].data.push([lObservation.waterConsumptionAvg + "L/hd"]);
                    lWaterIsIssue.groupRowsData[0].data.push([lObservation.isWaterIssues ? "Yes" : "No"]);
                    let lHasWaterComments = !StringIsNullUndefinedOrEmpty(lObservation.waterComments);
                    if (lHasWaterComments) {
                        lWaterCommentsRow.groupRowsData[0].data.push(
                            // for the selcted day we want to show the actual comments rather than Yes or No
                            [new PrevDailyReportData("Yes", lObservation.waterComments)]
                        );
                    }
                    else {
                        lWaterCommentsRow.groupRowsData[0].data.push(
                            [new PrevDailyReportData("No", "No")]
                        );
                    }

                    let lHasGenComments = !StringIsNullUndefinedOrEmpty(lObservation.generalComments);
                    if (lHasGenComments) {
                        lGenCommentsRow.groupRowsData[0].data.push(
                            // for the selcted day we want to show the actual comments rather than Yes or No
                            [new PrevDailyReportData("Yes", lObservation.generalComments)]
                        );
                    }
                    else {
                        lGenCommentsRow.groupRowsData[0].data.push(
                            [new PrevDailyReportData("No", "No")]
                        );
                    }
                })

                lBody.push(lVesselPositionRow);
                lBody.push(lNextPortRow);
                lBody.push(lETARow);
                lBody.push(lSailingConditionsRow);
                lBody.push(lVentilationIssuesRow);
                lBody.push(lFeedROBHeadingRow);
                lBody.push(lFeedROBPelletsRow);
                lBody.push(lFeedROBGrainRow);
                lBody.push(lFeedROBRoughageRow);

                lBody.push(lFeedQualityHeadingRow);
                lBody.push(lFeedQualityMeasuredRow);
                lBody.push(lFeedQualityQualityRow);
                lBody.push(lFeedQualityCommentsRow);
                lBody.push(lWaterHeadingRow);
                lBody.push(lWaterConsumptionRow);
                lBody.push(lWaterCommentsRow);
                lBody.push(lGenCommentsRow);

                let lData = { "Head": lHead, "Body": lBody };
                return lData;
            }
            else {
                return { "Head": [], "Body": [] };
            }
        }

        fetchAllObservationsForConsignment();
    }, [consignmentID]);

    const createLink = (aConsignmentDay, aPath) => {
        // If a user clicks edit we should take them to the same tab that they are viewing the previous deck record for
        let lText = EditViewString(userCtx.user.writeAccess);
        return [{ link: { href: aPath, text: lText, consignmentDay: aConsignmentDay } }];
    }

    return (
        <>
            {
                isLoading ? (
                    <div className="text-center pt-5">
                        <Spinner className="mx-auto" />
                        <div className="mx-auto">Loading data...</div>
                    </div>
                ) : null
            }
            {
                isLoading === false && hasData === true ?
                    <div>
                        <Row>
                            <Col>
                                <JsonToHtmlTable jsonData={jsonDataDisplayed} filter={null} duplicateHeaders={false} initialSelectedColumnIndex={!isNaN(selectedColumnIndex) ? selectedColumnIndex : undefined} showEdit={allowEditing} onSelectedColumnChanged={onSelectedColumnIndexChanged} />
                            </Col>
                        </Row>
                    </div>
                    : null
            }
            {
                isLoading === false && hasData === false && errorGettingData === false ?
                    <Alert variant="info" className="field-label">There is currently no data for the selected consignment.</Alert>
                    : null
            }
            {
                isLoading === false && errorGettingData === true ?
                    <Alert variant="info" className="field-label">Something went wrong while fetching previous observations.</Alert>
                    : null
            }
        </>
    )
};

export default View_Observations;