import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Enumerable from "linq";
import {
  getConsignmentDaysForConsignmentID,
  getConsignmentDecksForConsignmentID,
  getConsignmentDecksWithoutBridgeForConsignmentID,
  getSpeciesClassForConsignmentID,
  getSpeciesForConsignmentID,
  getHealthConditionL1ForConsignmentID,
  getMortalityConditionL1ForConsignmentID,
} from "../api/apiAccess";
import { generateRandomString } from "../utils/common";

const CustomSelect = (props, getOption, find_args, option_callback) => {
  const [options, setOptions] = useState({});
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [keyLabel, setKeyLabel] = useState(generateRandomString(5));

  useEffect(() => {
    const fetchOptions = async () => {
      let result = await getOption(find_args);
      if (result?.data) {
        let _options = result?.data;
        //if (props.defaultOption) _options = [props.defaultOption, ..._options];
        //console.log("select options", find_args, props.selected, _options);
        setOptions(_options);
        //console.log("select options", dbClass, find_args, props.selected, _options);
        if (props.loadDefaultValue && _options[0]) props.loadDefaultValue(props.selected ? props.selected : _options[0].id, _options[0]);
      }
    };
    if (!optionsLoaded) {
      setOptionsLoaded(true);
      fetchOptions();
    }
  }, [props]);

  return (
    <Form.Select
      disabled={props.disabled !== undefined && props.disabled}
      className="field-label"
      onChange={(e) => {
        let id = parseInt(e.target.value);
        let op = Enumerable.from(options).where((_op) => _op.id === id).toArray();
        //console.log("select change", id, op, options);
        if (op.length > 0) op = op[0];
        else if (props.defaultOption) {
          op = props.defaultOption;
        }

        if (props.onChange && op) props.onChange(id, op);
      }}
      value={options.length > 0 ? props.selected : 0}
    >
      {props.defaultOption && (
        <option value={props.defaultOption.id} key={keyLabel + "-def-" + props.defaultOption.id} className="field-label">
          {props.defaultOption.name}
        </option>
      )}
      {options &&
        options.length > 0 &&
        options.map((option) => {
          return (
            <option value={option.id} key={keyLabel + option.id} className="field-label">
              {option_callback ? option_callback(option) : option.name}
            </option>
          );
        })}
    </Form.Select>
  );
};

const ConsignmentDaySelect = (props) =>
  CustomSelect(props, getConsignmentDaysForConsignmentID, props.consignmentID, (option) => "Day " + option.dayNumber);

const ConsignmentDeckSelect = (props) =>
  CustomSelect(props, getConsignmentDecksWithoutBridgeForConsignmentID, props.consignmentID, (option) => option.deckName);

const ConsignmentAnimalSpecieSelet = (props) => CustomSelect(props, getSpeciesForConsignmentID, props.consignmentID, (option) => option.speciesName);

const ConsignmentAnimalClassSelet = (props) =>
  CustomSelect(props, getSpeciesClassForConsignmentID, props.consignmentID, (option) => option.className);

const ConsignmentHealthConditionL1Select = (props) =>
  CustomSelect(props, getHealthConditionL1ForConsignmentID, props.consignmentID, (option) => option.conditionName);
const ConsignmentMortalityConditionL1Select = (props) =>
  CustomSelect(props, getMortalityConditionL1ForConsignmentID, props.consignmentID, (option) => option.conditionName);

export default {
  ConsignmentDaySelect,
  ConsignmentDeckSelect,
  ConsignmentAnimalSpecieSelet,
  ConsignmentAnimalClassSelet,
  ConsignmentHealthConditionL1Select,
  ConsignmentMortalityConditionL1Select,
};
