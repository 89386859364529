import React from "react";
import { Row, Col } from "react-bootstrap";

const REConsignmentAnimalOverviewRow = ({speciesName, speciesClassName, animalCount, managementPlanNames, identifier}) => {
    return (
        <tr>
            <td className="text-center">
                <div>{speciesName}</div>
            </td>
            <td className="text-center">
                <div>{speciesClassName}</div>
            </td>
            <td className="text-center">
                <div>{animalCount}</div>
            </td>
            <td>
                <div>
                    {
                        managementPlanNames.length === 0 ?
                        (
                            <div>
                                <em>There are no applicable management plans for this species and class.</em>
                            </div>
                        ) : 
                        (
                            <ul style={{marginBottom: "0px"}}>
                            {
                                managementPlanNames.map((name, i) => {
                                    return <li key={`species_class_management_plan_${identifier}_${i}`}>{name}</li>;
                                })
                            }
                            
                            </ul>
                        )
                    }
                    
                </div>
            </td>
        </tr>
        
    )
}

export default REConsignmentAnimalOverviewRow;