import "../css/viewhistorydata.css";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import LoggedInContainer from "../layout/LoggedInContainer";
import View_Observations from "./Observations/View_Observations";
import View_Deck_Temperatures from "./DeckTemperatures/View_Deck_Temperatures";
import View_Deck_Record_General_Demeanour from "./DeckRecords/View_Deck_Record_General_Demeanour";
import View_Deck_Record_Feeding_Behaviour from "./DeckRecords/View_Deck_Record_Feeding_Behaviour";
import View_Deck_Record_Manure_Pad_Score from "./DeckRecords/View_Deck_Record_Manure_Pad_Score";
import View_Deck_Record_Other from "./DeckRecords/View_Deck_Record_Other";
import { getConsignmentDeckAnimals, setCompletedConsignmentDaysForConsignmentIDAsViewed } from "../api/apiAccess";
import { UserContext } from "..";

const tabs = [
  { name: "Observations", value: "1" },
  { name: "Temperatures", value: "2" },
  { name: "General Demeanour", value: "3" },
  { name: "Feeding Behaviour", value: "4" },
  { name: "Manure Pad Score", value: "5" },
  { name: "Other Deck Records", value: "6" },
];

const ViewHistoryData = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { consignmentID } = useParams();
  const userCtx = useContext(UserContext);

  const [focusedTab, setFocusedTab] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [selectedColumnIndex, setSelectedColumnIndex] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [hasSheep, setHasSheep] = useState(false);
  const [hasCattle, setHasCattle] = useState(false);

  useEffect(() => {
    // check if this consignment has cattle or sheep (if not the "Other" tab in not displayed)
    const setHasCattleOrSheep = async () => {
      let lResponse = await getConsignmentDeckAnimals(consignmentID);

      if (lResponse?.status === 200) {
        let lHasSheep = lResponse.data.some((dr) => dr.species.id === 1);
        let lHasCattle = lResponse.data.some((dr) => dr.species.id === 2);
        setHasSheep(lHasSheep);
        setHasCattle(lHasCattle);
      } else {
        //todo
        return null;
      }
    };

    setSelectedTab("1");
    setHasCattleOrSheep();
  }, []);

  useEffect(() => {
    if (!location || !location.state) {
      navigate("/Dashboard");
    }
    if (location?.state?.focusedTab) {
      let lSelectedTabID = tabs.find((tab) => tab.name.toLowerCase() === location.state.focusedTab).value;
      setFocusedTab(location.state.focusedTab);
      setSelectedTab(lSelectedTabID);
    }

    if (location?.state?.selectedColumnIndex) {
      setSelectedColumnIndex(location.state.selectedColumnIndex);
    }

    if (location?.state?.editable) {
      setIsEditable(location.state.editable);
    }
  }, [location]);

  useEffect(() => {
    const updateIsReviewedStatus = async () => {
      if (userCtx.user?.userTypeID === 1) {
        await setCompletedConsignmentDaysForConsignmentIDAsViewed(consignmentID);
      }
    }
    if (consignmentID) {
      updateIsReviewedStatus();
    }
  }, [consignmentID])

  const handleSelectedColumnIndexChanged = (index) => {
    setSelectedColumnIndex(index);
  }

  const goBack = () => {
    navigate(`/consignmentdashboard/${consignmentID}`, {state: { isHistorical: location?.state?.isHistorical }});
  };

  return (
    <LoggedInContainer>
      <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <Row className="upper-panel mx-0" style={{ position: "relative" }}>
          <Col>
            <Container>
              <Row>
                <Col xs={12} style={{ marginTop: "75px" }}>
                  <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                    <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                    Back
                  </Button>
                  <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                    <div style={{ lineHeight: "53px", fontSize: "35px" }}>View {focusedTab}</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col>
            <Container className="py-4 px-5 form-container-mid" style={{ top: "-350px" }}>
              <Row className="text-center mb-3">
                <Col>
                  <ButtonGroup style={{ width: "100%" }}>
                    {tabs.map((radio, idx) =>
                      radio.name === "Other Deck Records" && hasCattle === false && hasSheep === false ? null : (
                        <ToggleButton
                          className={selectedTab === radio.value ? "tab-bar-button-active" : "tab-bar-button"}
                          key={idx}
                          id={`radio-${idx}`}
                          type="radio"
                          name="radio"
                          value={radio.value}
                          checked={selectedTab === radio.value}
                          onChange={(e) => {
                            let selectedTitle = tabs.find((t) => t.value === e.currentTarget.value).name.toLowerCase();
                            setFocusedTab(selectedTitle);
                            setSelectedTab(e.currentTarget.value);
                          }}
                        >
                          {radio.name}
                        </ToggleButton>
                      )
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
              {focusedTab === "observations" ? <View_Observations consignmentID={consignmentID} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable} onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged} /> : null}

              {focusedTab === "temperatures" ? <View_Deck_Temperatures consignmentID={consignmentID} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable}  onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged}/> : null}

              {focusedTab === "general demeanour" ? <View_Deck_Record_General_Demeanour consignmentID={consignmentID} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable} onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged} /> : null}

              {focusedTab === "feeding behaviour" ? <View_Deck_Record_Feeding_Behaviour consignmentID={consignmentID} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable} onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged} /> : null}

              {focusedTab === "manure pad score" ? <View_Deck_Record_Manure_Pad_Score consignmentID={consignmentID} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable} onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged} /> : null}

              {focusedTab === "other deck records" ? (
                <View_Deck_Record_Other consignmentID={consignmentID} hasSheep={hasSheep} hasCattle={hasCattle} selectedColumnIndex={selectedColumnIndex} allowEditing={isEditable} onSelectedColumnIndexChanged={handleSelectedColumnIndexChanged} />
              ) : null}
            </Container>
          </Col>
        </Row>
      </Container>
    </LoggedInContainer>
  );
};

export default ViewHistoryData;
