import { MortalityConditionLevelOne } from "./MortalityConditionLevelOne";
import { MortalityConditionLevelTwo } from "./MortalityConditionLevelTwo";
import { MortalityConditionLevelThree } from "./MortalityConditionLevelThree";

export class DetailedMortalityCondition {
    LevelOne;
    LevelTwo;
    LevelThree;
    constructor({levelOne, levelTwo, levelThree}) {
        this.LevelOne = new MortalityConditionLevelOne(levelOne);
        this.LevelTwo = new MortalityConditionLevelTwo(levelTwo);
        this.LevelThree = new MortalityConditionLevelThree(levelThree);
    }
}