import '../../css/settings.css';
import React from "react";
import { Row, Col, Container, Button, Stack, Modal} from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faUsersGear, faGears, faFileArrowUp, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../..";
import { FileUploader } from "react-drag-drop-files";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { uploadDBfile } from "../../api/apiAccess"
import Swal from "../../utils/Swal";

const Admin_Tools = () => {
    const userCtx = useContext(UserContext);
    const [userTypeID, setUserTypeID] = useState(null);
    const [showOrgSettings, setShowOrgSettings] = useState(false); // only visible for exporter or RE/Exporter industry users

    const [isShowModalOfImportDBFile, setIsShowModalOfImportDBFile] = useState(false);
    const [file, setFile] = useState(null);
    const fileTypes = ["zip","db"];
    const [isSyncing, setIsSyncing] = useState(false);

    useEffect(() => {
        setUserTypeID(userCtx?.user?.userTypeID);
        if (userCtx?.associatedExporter && Object.keys(userCtx?.associatedExporter).length > 0) {
            if (userCtx?.user?.userTypeID === 1 && (userCtx.user?.exporterDetails?.organisationTypeID === 1 ||userCtx.user?.exporterDetails?.organisationTypeID === 3)) {
                setShowOrgSettings(true);
            }
            else {
                setShowOrgSettings(false);
            }
        }
    }, [userCtx?.user?.userTypeID, userCtx.user?.exporterDetails?.organisationTypeID]);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    const goUserManagement = () => {
        if (userTypeID === 3) {
            navigate("/livecorpusermanagement");
        }
        else {
            navigate("/usermanagement");
        }
    }

    const handleChange = (file) => {
        setFile(file);
    }

    const openModalImportDBfile = () => {
        setIsShowModalOfImportDBFile(true);
    }

    const onCancelImportDBfile = () => {
        setIsShowModalOfImportDBFile(false);
        setFile(null);
    }

    const onConfirmImportDBfile = async ()=>{
        if(file === null){
            Swal.Toast("Please select a file to import.","error");
            return;
        }

        if(isSyncing) {
            Swal.Toast("Please wait until the current sync is complete.","error");
            return;
        }

        setIsSyncing(true);

        try{
            let result = await uploadDBfile(file);
            if (result.status === 200) {
                setIsShowModalOfImportDBFile(false);
                setFile(null);
                Swal.Toast("Import successfully");
            }else{
                Swal.Toast("Import failed","error");
            }
        }
        catch(err){
            Swal.Warning("Import failed");
            setIsSyncing(false);
        }

        setIsSyncing(false);
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5", minHeight: "calc(100vh - 75px)" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Admin Tools</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-5 px-4 form-container-full">

                    {
                        userTypeID !== null ?
                            <Stack direction="horizontal">
                                <div onClick={() => goUserManagement()} className="admin-tool-block text-center">
                                    <div className="mx-auto p-3 admin-tool-icon-frame">
                                        <FontAwesomeIcon icon={faUsersGear} />
                                        <div className="field-label mt-2">User Management</div>
                                    </div>
                                </div>

                                {
                                    showOrgSettings === true ?

                                        <div gap={2} className="admin-tool-block text-center" onClick={() => navigate("/organisationsettings")}>
                                            <div className="mx-auto p-3 admin-tool-icon-frame">
                                                <FontAwesomeIcon icon={faGears} />
                                                <div className="field-label mt-2">Organisation Settings</div>
                                            </div>
                                        </div>

                                        : null
                                }

                                {
                                    userTypeID === 3 ?

                                        <div gap={2} className="admin-tool-block text-center" onClick={() => navigate("/livecorporgmanagement")}>
                                            <div className="mx-auto p-3 admin-tool-icon-frame">
                                                <FontAwesomeIcon icon={faGears} />
                                                <div className="field-label mt-2">Organisation Management</div>
                                            </div>
                                        </div>

                                        : null
                                }

                                {userTypeID === 3 &&
                                    <div onClick={() => openModalImportDBfile()} className="admin-tool-block text-center">
                                        <div className="mx-auto p-3 admin-tool-icon-frame">
                                            <FontAwesomeIcon icon={faFileArrowUp} />
                                            <div className="field-label mt-2">Import DB file</div>
                                        </div>
                                    </div>
                                }
                            </Stack>

                            : null
                    }

                </Container>
            </Container>

            <Modal show={isShowModalOfImportDBFile} onHide={onCancelImportDBfile} size="lg" backdrop={false}>
                <Modal.Header closeButton>
                <Modal.Title>Import DB file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FileUploader
                        handleChange={handleChange}
                        name="file"
                        types={fileTypes}
                        classes="drop_zone"
                    >
                        <Container className="px-3 text-center py-5" style={{ minHeight: "120px", minWidth: "100%" }} >
                            {!file ? (
                                <><FontAwesomeIcon icon={faCirclePlus} size="lg" /><span>&nbsp;Drag and drop a file.db or file.zip here, or &nbsp;</span><Button className="btn-primary" >SELECT FILE</Button></>
                            ) :
                                (
                                    <>
                                        <Row className="text-center py-3">
                                            <div><b>Filename:</b> {file.name}</div>
                                            <div><b>Last Modified:</b> {format(new Date(file.lastModified), "dd/MM/yyyy HH:mm")}</div>
                                            <div><b>Size:</b> {Math.round(file.size / 1000)}kb</div>

                                        </Row>
                                    </>
                                )
                            }
                        </Container>
                    </FileUploader>
                </Modal.Body>
                <Modal.Footer>

                <Button variant="secondary" onClick={onCancelImportDBfile} disabled={isSyncing}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirmImportDBfile}>
                    {isSyncing && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                    Confirm
                </Button>
                </Modal.Footer>
            </Modal>

        </LoggedInContainer>
    )
};

export default Admin_Tools;