import "../css/dashboard.css";
import React, { useState, useEffect, useContext, useId } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { Alert, Container, Stack, Row, Col, Button, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTrash } from "@fortawesome/free-solid-svg-icons";
import LoggedInContainer from "../layout/LoggedInContainer";
import { getDetailedConsignmentWithObsLocations, submitToDept } from "../api/apiAccess";
import { DetailedConsignment } from "../classes/DetailedConsignment";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import ConsignmentReportingStatus from "../components/ConsignmentDashboard/ConsignmentReportingStatus";
import ConsignmentLoadingAndDischargingPorts from "../components/ConsignmentDashboard/ConsignmentLoadingAndDischargingPorts";
import ConsignmentVoyageSummary from "../components/ConsignmentDashboard/ConsignmentVoyageSummary";
import TreatmentOverview from "../components/ConsignmentOverview/TreatmentOverview";
import MortalitiesOverview from "../components/ConsignmentOverview/MortalitiesOverview";
import FeedRemainingOverview from "../components/ConsignmentOverview/FeedRemainingOverview";
import AvgFeedOverview from "../components/ConsignmentOverview/AvgFeedOverview";
import AvgWaterOverview from "../components/ConsignmentOverview/AvgWaterOverview";
import ConsignmentEditHistory from "../components/ConsignmentDashboard/ConsignmentEditHistory";
import ConsignmentMap from "../components/ConsignmentDashboard/ConsignmentMap";
import { MediaQueryContext, UserContext } from "..";
import { getCurrentDayAndEndDayForConsignment } from "../utils/common";
import ConsignmentInfoMobile from "../components/ConsignmentOverview/ConsignmentInfoMobile";
import ConsignmentInfoTableMobile from "../components/ConsignmentOverview/ConsignmentInfoTableMobile";
import {isReportEditable} from "../utils/common";


const mapRender = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

/// You can only get to this page if the consignment is started
const ConsignmentDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const componentId = useId();
  const userCtx = useContext(UserContext);
  const mediaQueryCtx = useContext(MediaQueryContext);

  const { consignmentID } = useParams();
  const [consignmentDetails, setConsignmentDetails] = useState({});
  const [CurrentAndExpectedNumberOfDays, setCurrentAndExpectedNumberOfDays] = useState({});
  const [mobileShowMap, setMobileShowMap] = useState(false);

  useEffect(() => {
    if (consignmentID === undefined) return;
    console.log("userCtx:", userCtx);

    const fetchDetailedConsignment = async () => {
      let results = await getDetailedConsignmentWithObsLocations(consignmentID);
      if (results?.status === 200) {
        let consignment = new DetailedConsignment(results.data);
        setConsignmentDetails(consignment);
        let lCurrentAndEndDayResults = await getCurrentDayAndEndDayForConsignment(userCtx.user?.userTypeID, consignment);
        setCurrentAndExpectedNumberOfDays(lCurrentAndEndDayResults);
      }
    };

    const fetchAll = () => {
      fetchDetailedConsignment();
    };

    fetchAll();
  }, [consignmentID]);

  const goToDailyReporting = () => {
    navigate(`/viewhistorydata/${consignmentID}`, { state: { focusedTab: "observations", editable: isReportEditable(userCtx, consignmentDetails.IsEditable()), isHistorical: location?.state?.isHistorical } });
  };

  const goToHealthAndMortalityReports = () => {
    navigate(`/healthandmortalityreports/${consignmentID}`, {state: { isHistorical: location?.state?.isHistorical }});
  };

  return (
    <LoggedInContainer>
      <Container fluid className="px-5 py-4">
        <Row className="border-bottom-separator pb-4">
          <Col xs={mediaQueryCtx.mobile === true ? 12 : 4}>
            <Row>
              <Col xs={mediaQueryCtx.mobile === true ? 12 : 10}>
                  <div className={`subtitle-text-heavy darkblue ${mediaQueryCtx.mobile === true ? "text-center" : ""}`}> LNC-{consignmentDetails?.Consignment?.lncNumber} </div>
              </Col>
            </Row>
            <Row>
              <div className={`field-label darkblue ${mediaQueryCtx.mobile === true ? "text-center" : ""}`}>Vessel {consignmentDetails?.Vessel?.VesselName}</div>
            </Row>
          </Col>
          <Col>
          {
            mediaQueryCtx.mobile === true ? (
                    <Stack className="pt-4 pb-0" gap={2} style={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
                    {/* if you go to the consignment review page from the cosnignment dashboard you should not be able to edit or delete, since the consignment has started */}
                    <Button className="btn-primary-outline primary-green py-0 px-2 ms-auto btn btn-primary"
                      href={`/viewinactiveconsignment/${consignmentID}`}>
                      View Consignment Setup
                    </Button>
                    {CurrentAndExpectedNumberOfDays?.CurrentDayNumber ? (
                      <div className="d-inline-block w-100 text-center field-label" style={{ fontSize: "24px" }}>
                        Day <span className="primary-green">{CurrentAndExpectedNumberOfDays?.CurrentDayNumber}</span>
                        {/* {CurrentAndExpectedNumberOfDays?.ExpectedNumberOfDays ? (
                          <span> of {CurrentAndExpectedNumberOfDays?.ExpectedNumberOfDays}</span>
                        ) : null} */}
                      </div>
                    ) : null}
                  </Stack>
            ) : (
                  <Stack className="pb-2" gap={2} style={{ alignItems: "flex-end", justifyContent: "flex-end", height: "100%" }}>
                    {/* if you go to the consignment review page from the cosnignment dashboard you should not be able to edit or delete, since the consignment has started */}
                    <Button className="btn-primary-outline primary-green py-0 px-2 ms-auto btn btn-primary"
                      href={`/viewinactiveconsignment/${consignmentID}`}>
                      View Consignment Setup
                    </Button>
                    {CurrentAndExpectedNumberOfDays?.CurrentDayNumber ? (
                      <div className="d-inline-block w-50 text-end field-label" style={{ fontSize: "24px" }}>
                        Day <span className="primary-green">{CurrentAndExpectedNumberOfDays?.CurrentDayNumber}</span>
                        {/* {CurrentAndExpectedNumberOfDays?.ExpectedNumberOfDays ? (
                          <span> of {CurrentAndExpectedNumberOfDays?.ExpectedNumberOfDays}</span>
                        ) : null} */}
                      </div>
                    ) : null}
                  </Stack>
            )
          }
          </Col>
        </Row>
        
        { 
          !location?.state?.isHistorical ?
          (
            <Row>
              <ConsignmentReportingStatus consignment={consignmentDetails} isMobile={mediaQueryCtx.mobile === true} />
            </Row>
          ) : null
        }

        {
          mediaQueryCtx.mobile === true ? 
          (
            <Row>
              <Col xs={12} className="px-3">
                <Button
                  className="btn-secondary mt-3 w-100"
                  onClick={() => {
                    setMobileShowMap(!mobileShowMap);
                  }}
                >
                  
                  {mobileShowMap ? "Hide Map" : "Show Map"}
                </Button>
              </Col>
              {
                mobileShowMap ? (
                  <Col xs={12} className="pt-4">
                    <Row style={{ height: "300px", maxHeight: "600px", marginTop: "38x", paddingRight: "12px" }}>
                      <div className="mx-auto">
                        <Wrapper apiKey="AIzaSyD-7x9cjHGwxQH-JLXAlBRQ6ArVtL3ME3A" render={mapRender}>
                          <ConsignmentMap consignmentID={consignmentDetails?.Consignment?.id} />
                        </Wrapper>
                      </div>
                    </Row>
                  </Col>
                ) : null
              }
            </Row>
          ) : null
        }
        <Row>
          <Col xs={mediaQueryCtx.mobile === true ? 12 : 9}>
            <Row>
              <ConsignmentLoadingAndDischargingPorts consignmentID={consignmentDetails?.Consignment?.id} isMobile={mediaQueryCtx.mobile === true} isHistorical={location?.state?.isHistorical === true} endOfVoyageSubmittedDate={consignmentDetails?.EndOfVoyage?.EndOfVoyageCompletedDateUTC}/>
            </Row>
            {consignmentDetails?.ConsignmentDays?.length > 0 ? (
              <Row className="pt-4">
                <Col xs={mediaQueryCtx.mobile === true ? 12 : 4}>
                  <div className={`heading-text darkblue`} style={{ fontSize: "16px" }}>
                    Voyage summary
                  </div>
                </Col>
                <Col xs={mediaQueryCtx.mobile === true ? 12 : 8}>
                  <Stack direction="horizontal" gap={3} style={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
                    <Button className="btn-secondary w-50" onClick={goToDailyReporting}>
                      <div>Daily Reporting</div>
                    </Button>
                    <Button className="btn-secondary w-50" onClick={goToHealthAndMortalityReports}>
                      <div>Health & Mortality Report</div>
                    </Button>
                  </Stack>
                </Col>
              </Row>
            ) : null}

            <Row className="pt-2">
              <ConsignmentVoyageSummary consignmentID={consignmentDetails?.Consignment?.id} isConsignmentEditable={Object.keys(consignmentDetails).length > 0 ? isReportEditable(userCtx,consignmentDetails?.IsEditable()) : false} isMobile={mediaQueryCtx.mobile === true} isHistorical={location?.state?.isHistorical === true}/>
            </Row>
            <Row className="pt-4">
              <Col xs={12}>
                <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                  Voyage statistics
                </div>
              </Col>
            </Row>
            <Row>
              {mediaQueryCtx.mobile === true ? (
                <ConsignmentInfoTableMobile consignment={consignmentDetails} idx={0} componentId={componentId} isStarted={true} outerBorder={true}/>
              ) : (              
                <Container className="px-4">
                  <Row className="dashboard-consignment-header-row" style={{ borderRadius: "4px 4px 0 0" }}>
                    <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue py-2">
                      <div className="text-center">Treatments</div>
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start py-2">
                      <div className="text-center">Mortalities</div>
                    </Col>
                    <Col xs={4} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start py-2">
                      <div className="text-center">Feed remaining</div>
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start py-2">
                      <div className="text-center">Avg feed</div>
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start py-2">
                      <div className="text-center">Avg water</div>
                    </Col>
                  </Row>
                  <Row className="dashboard-consignment-cell-row" style={{ borderRadius: "0 0 4px 4px" }}>
                    <Col xs={2} className="py-2" style={{ display: "flex", alignItems: "center" }}>
                      <TreatmentOverview consignment={consignmentDetails?.Consignment} isStarted={true} />
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{ display: "flex", alignItems: "center" }}>
                      <MortalitiesOverview consignment={consignmentDetails?.Consignment} isStarted={true} />
                    </Col>
                    <Col xs={4} className="dashboard-consignment-header-cell-border-start py-2" style={{ display: "flex", alignItems: "center" }}>
                      <FeedRemainingOverview consignment={consignmentDetails?.Consignment} isStarted={true} />
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{ display: "flex", alignItems: "center" }}>
                      <AvgFeedOverview consignment={consignmentDetails?.Consignment} isStarted={true} />
                    </Col>
                    <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{ display: "flex", alignItems: "center" }}>
                      <AvgWaterOverview consignment={consignmentDetails?.Consignment} isStarted={true} />
                    </Col>
                  </Row>
                </Container>
              )}

              <Row className="pt-5">
                <Col xs={mediaQueryCtx.mobile === true ? 12 : 8}>
                  <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                    Consignment edit history
                  </div>
                </Col>
              </Row>
              <Row className="mx-0">  
                <ConsignmentEditHistory consignmentID={consignmentID} isMobile={mediaQueryCtx.mobile === true}/>
              </Row>
            </Row>
          </Col>
          {mediaQueryCtx.mobile !== true ?
            (
              <Col xs={3} className="pt-4">
                <Row style={{ height: "700px", maxHeight: "1000px", marginTop: "38x", paddingRight: "12px" }}>
                  <Wrapper apiKey="AIzaSyD-7x9cjHGwxQH-JLXAlBRQ6ArVtL3ME3A" render={mapRender}>
                    <ConsignmentMap consignmentID={consignmentDetails?.Consignment?.id} />
                  </Wrapper>
                </Row>
              </Col>
            ) : null
          }
          
        </Row>
      </Container>
    </LoggedInContainer>
  );
};

export default ConsignmentDashboard;
