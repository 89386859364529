import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Stack } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { IsNotNullAndGreaterThanZero } from "../../utils/StringUtils";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import { InputGroup } from "react-bootstrap/esm";
import { PercentageValidationRules, parseNumericInput } from "../../utils/InputValidator";
import ReadWriteFormCheck from "../ReadWriteElements/ReadWriteFormCheck";
import ReadWriteInputGroup from "../ReadWriteElements/ReadWriteInputGroup";

const SheepScabbyMouthDeck = ({ rec, triggerClearMissingAffectedDeck, onTriggerClearMissingAffectedDecks }) => {
    const { updateSheepScabbyMouthDeck, triggerShowInvalid, data } = useContext(DeckRecordOtherContext);
    const [isInvalidPercentage, setIsInvalidPercentage] = useState(false);
    const [isInvalidAffectedDecks, setIsInvalidAffectedDecks] = useState(false);

    const updateData = (isAffected, scabbyMouthPercentage) => {
        if (rec.DeckRecord.isEmpty === false) {
            let lIsValid = isValid(isAffected, scabbyMouthPercentage);
            updateSheepScabbyMouthDeck(rec.DeckRecord.consignmentDeckID, isAffected, scabbyMouthPercentage, lIsValid);
        }
    }

    const isValid = (isAffected, scabbyMouthPercentage) => {
        let lIsValid = true;
        if (isAffected === true && IsNotNullAndGreaterThanZero(scabbyMouthPercentage) === false) {
            lIsValid = false;
        }
        return lIsValid;
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerShowInvalid >= 0) {
            setIsInvalidPercentage(!rec.isValidScabbyMouth);
            setIsInvalidAffectedDecks(!data.sheepScabbyMouthValid);
        }
    }, [triggerShowInvalid]);

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerClearMissingAffectedDeck >= 0) {
            setIsInvalidAffectedDecks(false);
        }
    }, [triggerClearMissingAffectedDeck]);

    return (
        <Row key={`sheepScabbyMouthDeck_${rec.DeckRecord.consignmentDeckID}`} className="pb-3 pt-3 gray-border-top">
            <Col xs={6}>
                <Stack direction="horizontal" gap={4}>
                    <Form>
                        <Form.Group>
                            <ReadWriteFormCheck labelstacked={false} id={`scabbyMouthCheck_${rec.DeckRecord.consignmentDeckID}`} checked={rec.hasSheepScabbyMouth}
                                onChange={(e) => {
                                    onTriggerClearMissingAffectedDecks()
                                    setIsInvalidPercentage(false);
                                    setIsInvalidAffectedDecks(false);
                                    updateData(e.target.checked, rec.DeckRecord.scabbyMouthPercent);
                                }}
                                isInvalid={isInvalidAffectedDecks && !rec.DeckRecord.isEmpty}
                                label={rec.ConsignmentDeck.deckName}
                                disabled={rec.DeckRecord.isEmpty}
                            />
                        </Form.Group>
                        {
                            isInvalidAffectedDecks && !rec.DeckRecord.isEmpty ? (
                                <div className="validation-error-tooltip">
                                    <Tooltip
                                        anchorSelect={`#scabbyMouthCheck_${rec.DeckRecord.consignmentDeckID}`}
                                        place="top"
                                        variant="error"
                                        content="One ore more decks must be selected, or select no."
                                    />
                                </div>
                            ) : null
                        }
                    </Form>
                </Stack>
            </Col>
            <Col xs={6}>
                {
                    rec.hasSheepScabbyMouth && !rec.DeckRecord.isEmpty?
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                <Form.Control id={`scabbyMouthPercent_${rec.DeckRecord.consignmentDeckID}`} value={rec.DeckRecord.scabbyMouthPercent}
                                    onChange={(e) => {
                                        parseNumericInput(e, new PercentageValidationRules(false, false));
                                        setIsInvalidPercentage(false);
                                        updateData(rec.hasSheepScabbyMouth, e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={isInvalidPercentage && !rec.DeckRecord.isEmpty}
                                    disabled={rec.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${isInvalidPercentage && !rec.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    isInvalidPercentage && !rec.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#scabbyMouthPercent_${rec.DeckRecord.consignmentDeckID}`}
                                                place="top"
                                                variant="error"
                                                content="A scabby mouth percentage greater than 1 is required."
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                        : null
                }
            </Col>
        </Row>
    )
};

export default SheepScabbyMouthDeck;