import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";

export class ReadWriteProperties {
    writeonly = false;
    readonlytext = "";
    readonlyclickevent = null;
    readonlyclasses = "";
    writeonlyclasses = "";
    labelstacked = true;
}

export const updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject = (obj, hasWriteAccess = false) => {
    let lModifiedObj= {};
    Object.assign(lModifiedObj, obj);

    // Update props based on write access
    if (hasWriteAccess === false) {
        if(StringIsNullUndefinedOrEmpty(lModifiedObj.readonlyclasses) === false){
            lModifiedObj.className += " " + lModifiedObj.readonlyclasses;
        }
        if (StringIsNullUndefinedOrEmpty(lModifiedObj.readonlytext) === false) {
            lModifiedObj.children = lModifiedObj.readonlytext;
        }
        if (lModifiedObj.readonlyclickevent) {
            lModifiedObj.onClick = lModifiedObj.readonlyclickevent;
        }
    }
    else {
        if(StringIsNullUndefinedOrEmpty(lModifiedObj.writeonlyclasses) === false){
            lModifiedObj.className += " " + lModifiedObj.writeonlyclasses;
        }
    }

    // Remove custom properties
    let lCustomProperties = Object.getOwnPropertyNames(new ReadWriteProperties);

    Object.keys(lModifiedObj).forEach(key => {
        if (lCustomProperties.includes(key)) {
            delete lModifiedObj[key];
        }
    });

    return lModifiedObj;
}