import React, { useState, useEffect, forwardRef, useContext } from "react";
import { Form, Stack, FormLabel, InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { parseNumericInput, HourValidationRules, MinuteValidationRules } from "../utils/InputValidator";
import { faCircleExclamation, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { format, addMinutes } from "date-fns";
import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";
import { UserContext } from "..";
import { getPortTimezone, isValidTZLocation, isValidTZLocationOfPort } from "../utils/common";
import { StringIsNullUndefinedOrEmpty } from "../utils/StringUtils";
import moment from "moment-timezone";
import tzlookup from "tz-lookup";

/*date passed in should be local time - js seesm to autimatically convert utc saved dates into local time*/
const DateTimePicker = (props) => {
  const [hour, setHour] = useState("");
  const [minute, setMinute] = useState("");
  const [date, setDate] = useState("");
  const [utcDate, setUtcDate] = useState("");

  const [inited, setInited] = useState(false);

  const userCtx = useContext(UserContext);
  const [writeAccess, setWriteAccess] = useState(false);

  useEffect(() => {
    let lWriteAccess = userCtx.user.writeAccess || props.canwrite === true;
    setWriteAccess(lWriteAccess);
  }, [userCtx.user]);

  const CustomInput = forwardRef(function CustomInput(props, ref) {
    return writeAccess === true ? (
      <div>
        <InputGroup style={{ borderRadius: ".375rem", backgroundColor: "#FFFFFF" }}>
          <Form.Control
            type="text"
            className={`datepicker-input ${props.className} text-start inputGroupWithAppend`}
            onClick={props.onClick}
            value={props.value}
            onChange={props.onChange}
          />
          <InputGroup.Text
            onClick={props.onClick}
            style={{ marginLeft: "0px" }}
            className={`inputGroupAppend datepicker-input-nested-label ${props.className}`}
          >
            <FontAwesomeIcon icon={faCalendarAlt} />
          </InputGroup.Text>
        </InputGroup>
      </div>
    ) : (
      <div className="text-13-400 secondary-grey">{props.value}</div>
    );
  });

  useEffect(() => {
    //console.log("DateTimePicker onChange", date, hour, minute, props.port);
    // update utc date
    if (!StringIsNullUndefinedOrEmpty(date)) {
      let lDate = new Date(new Date(date).getTime());

      if ((props.showTime || !props.isValid) && !StringIsNullUndefinedOrEmpty(hour) && !StringIsNullUndefinedOrEmpty(minute)) {
        lDate.setHours(hour);
        lDate.setMinutes(minute);
        let dataStr = format(lDate, "yyyy-MM-dd HH:mm");
      

        if (props.onChange)
          isValidTZLocationOfPort(props.port)
            ? props.onChange(zonedTimeToUtc(lDate, tzlookup(props.port.latitude, props.port.longitude)))
            : props.onChange(lDate);

        let utcDate = isValidTZLocationOfPort(props.port) ? moment.tz(dataStr, tzlookup(props.port.latitude, props.port.longitude)) : moment(lDate);
        //console.log("DateTimePicker onChange dataStr", dataStr,utcDate);
        setUtcDate(utcDate.utc().format("DD/MM/YYYY HH:mm"));
      } else {
        if (props.onChange) {
          if (isValidTZLocationOfPort(props.port)) {
            props.onChange(zonedTimeToUtc(lDate, tzlookup(props.port.latitude, props.port.longitude)))
          } else {
            props.onChange(lDate);
          }
        }
      }
    } else if (inited) {
      //console.log("DateTimePicker onChange setDate empty!!!!!!!");
      setUtcDate("");
      props.onChange(null);
    }
  }, [hour, minute, date, props.port]);

  useEffect(() => {
   //console.log("DateTimePicker setDate", inited, props.datetime, props.port);
    if (!inited && props.datetime != null) {
      setInited(true);
      if (props.datetime) {
        let localDatetime = isValidTZLocationOfPort(props.port)
          ? moment(props.datetime).tz(tzlookup(props.port.latitude, props.port.longitude))
          : moment(props.datetime);

          //console.log("DateTimePicker localDatetime:", localDatetime.format("DD/MM/YYYY HH:mm"),localDatetime.toDate(),isValidTZLocationOfPort(props.port), typeof localDatetime.toDate() === "string" ) ;
        setDate(Date.parse(localDatetime.format('YYYY-MM-DD HH:mm')));
        setHour(localDatetime.format("HH"));
        setMinute(localDatetime.format("mm"));
      } else {
        //console.log("DateTimePicker setDate empty");
        setDate("");
        setHour("");
        setMinute("");
      }
    }
    else if (props.datetime === null) {
      //console.log("DateTimePicker setDate empty since null");
      setDate("");
      setHour("");
      setMinute("");
    }
  }, [props.datetime]);

  return (
    <Stack>
      <div>
        <Stack style={{ display: "inline-block", marginRight: `${props.showTime !== false ? "30px" : "0px"}` }}>
          <div className="darkblue field-label mb-1">{props.showHeadLabel && <FormLabel>Date</FormLabel>}</div>
          <DatePicker
            disabled={!writeAccess}
            dateFormat="dd/MM/yyyy"
            selected={typeof date === "string" ? null : date}
            todayButton="Today"
            onChange={(_date) => {
              setDate(_date);
            }}
            customInput={<CustomInput />}
            className={"isValid" in props && props.isValid === false ? "redBorder" : ""}
          />
        </Stack>

        {props.showTime !== false || props.isValid === false ? (
          <Stack style={{ display: "inline-block" }}>
            <div className="darkblue field-label mb-1">{props.showHeadLabel && <FormLabel>Local Time</FormLabel>}</div>
            <Stack direction="horizontal" gap={3}>
              {props.showTime !== false && (
                <>
                  <Form.Control
                    disabled={!writeAccess}
                    style={{ textAlign: "center" }}
                    className={
                      "isValid" in props && props.isValid === false
                        ? "redBorder input-hour form-control input secondary-grey"
                        : "input-hour form-control input secondary-grey"
                    }
                    value={hour}
                    onBlur={() =>
                      hour?.length === 1 ? setHour("0" + hour) : hour?.length > 2 ? setHour(hour.substring(hour.length - 2, hour.length)) : undefined
                    }
                    onChange={(e) => {
                      parseNumericInput(e, new HourValidationRules());
                      setHour(e.target.value);
                    }}
                  />

                  <span className="time-sparater input">:</span>
                  <Form.Control
                    disabled={!writeAccess}
                    style={{ textAlign: "center" }}
                    className={
                      "isValid" in props && props.isValid === false
                        ? "redBorder input-min form-control input secondary-grey"
                        : "input-min form-control input secondary-grey"
                    }
                    value={minute}
                    onBlur={() =>
                      minute?.length === 1
                        ? setMinute("0" + minute)
                        : minute?.length > 2
                          ? setMinute(minute.substring(minute.length - 2, minute.length))
                          : undefined
                    }
                    onChange={(e) => {
                      parseNumericInput(e, new MinuteValidationRules());
                      setMinute(e.target.value);
                    }}
                  />
                  {props.endLabel && <span className="field-label">{props.endLabel}</span>}
                </>
              )}

              {props.isValid === false && props.showInvalidIcon !== false ? (
                <span class="ms-1">
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    color="#dc3545"
                    data-tooltip-id="dateTooltip"
                    data-tooltip-content={props.errorMessage ? props.errorMessage : ""}
                  />
                </span>
              ) : null}
            </Stack>
          </Stack>
        ) : null}
      </div>

      {!StringIsNullUndefinedOrEmpty(utcDate) && props.showUTCTime !== false ? <span className="mt-1 darkblue field-label">({utcDate} UTC) </span> : null}

      {props.errorMessage ? (
        <div className="validation-error-tooltip">
          <Tooltip id="dateTooltip" variant="error" />
        </div>
      ) : null}
    </Stack>
  );
};

export default DateTimePicker;
