import React, { useEffect, useState, useId } from "react";
import { Container, Stack } from "react-bootstrap";
import { getAvgWaterConsumptionForConsignmentID } from "../../api/apiAccess";
import { formatUpToSpecifiedPrecision } from "../../utils/common";

const AvgWaterOverview = ({consignment, index, isStarted}) => {
    const componentId = useId();
    const [avgWaterConsumption, setAvgWaterConsumption] = useState(0);

    useEffect(() => {
        if (consignment === undefined || isStarted === false) return;
        
        const fetchAvgWaterConsumption = async () => {
            let results = await getAvgWaterConsumptionForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setAvgWaterConsumption(results.data);
            } 
        }
        fetchAvgWaterConsumption();
    }, [consignment, isStarted])

    return (
        <Container fluid key={`avg_water_overview_${componentId}_${index}`} className="my-auto mx-0 px-auto">
            <Stack direction="vertical">
                <Stack direction="horizontal">
                    <Container className="text-center">
                        { isStarted ? (
                            <>
                                <div style={{display: 'inline-block'}}>{formatUpToSpecifiedPrecision(avgWaterConsumption, 2)}</div><span className="field-label">L/hd</span>
                            </>
                        ) : (
                            <div style={{display: 'inline-block'}}>-</div>
                        )}
                        
                    </Container>
                </Stack>
            </Stack>
        </Container>
    )
};

export default AvgWaterOverview;