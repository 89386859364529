export class LAE {
    UniqueIDForUI;
    ID; // maybe null f we add a new one
    Number;
    Name;
    NumberValid;
    NameValid;
    ToBeDeleted;
    constructor(uniqueIDForUI, id, number, name, numberValid = true, nameValid = true) {
        this.UniqueIDForUI = uniqueIDForUI;
        this.ID = id;
        this.Number = number;
        this.Name = name;
        this.NumberValid = numberValid;
        this.NameValid = nameValid;
        this.ToBeDeleted = false;
    }
}