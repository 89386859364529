import React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import SheepScabbyMouthDeck from "./SheepScabbyMouthDeck";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import ReadWriteFormRadio from "../ReadWriteElements/ReadWriteFormRadio";

const SheepScabbyMouth = () => {
    const { data, updateSheepHasScabbyMouth, clearScabbyMouthMissingAffectedDecksValidity } = useContext(DeckRecordOtherContext);
    const [triggerClearMissingAffectedDecks, setTriggerClearMissingAffectedDecks] = useState(-1);
    const [allSheepDecksEmpty, setAllSheepDecksEmpty] = useState(false);

    const onTriggerClearMissingAffectedDecks = () => {
        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
        clearScabbyMouthMissingAffectedDecksValidity();
    }

    useEffect(() => {
        let lAllEmpty = [...data.sheepDecks].every(sd => sd.DeckRecord.isEmpty === true);
        setAllSheepDecksEmpty(lAllEmpty);

    }, [data.sheepDecks]);

    return (
        <div>
            <div className="pb-1  field-label">Has Scabby Mouth identified in &gt;1% of sheep on a deck?</div>
            <Form>
                <ReadWriteFormRadio id='rdoSheepScabbyMouth_No' type={`radio`} label={`No`} name="rdoSheepScabbyMouth" checked={!data.hasSheepScabbyMouth}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasScabbyMouth(false);
                    }}
                    disabled={allSheepDecksEmpty}
                />
                <ReadWriteFormRadio id='rdoSheepScabbyMouth_Yes' type={`radio`} label={`Yes`} name="rdoSheepScabbyMouth" checked={data.hasSheepScabbyMouth}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasScabbyMouth(true);
                    }}
                    disabled={allSheepDecksEmpty}
                />
            </Form>
            {
                data.hasSheepScabbyMouth && !allSheepDecksEmpty ?
                    <div>
                        <Row className="pt-2">
                            <Col xs={6} className="dr-column-heading">Deck Affected?</Col>
                            <Col xs={6} className="dr-column-heading">
                                <div>Scabby Mouth-affected</div>
                                <div>% animals per deck (if&gt;1%)</div>
                            </Col>
                        </Row>
                        {
                            data.sheepDecks.map((rec, index) => (
                                <SheepScabbyMouthDeck key={`sheepScabbyMouth${index}`} rec={rec} triggerClearMissingAffectedDeck={triggerClearMissingAffectedDecks} onTriggerClearMissingAffectedDecks={onTriggerClearMissingAffectedDecks} />
                            ))
                        }
                    </div>
                    : null
            }
        </div>
    )
};

export default SheepScabbyMouth;