import { useNavigate, useLocation, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Stack, Alert } from "react-bootstrap";
import LivestockDetail from "../../components/ConsignmentCreation/LivestockDetail";
import ConsignmentProgression from "../../components/ConsignmentCreation/ConsignmentProgression";
import LoggedInContainer from "../../layout/LoggedInContainer";
import LivestockDragNDropUploader from "../../components/ConsignmentCreation/LivestockDragNDropUploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { getManagementPlans, getSpecies, getSpeciesClasses } from "../../api/apiAccess";
import { addToOrUpdateSessionConsignmentBeingCreated, getSessionConsignmentBeingCreated } from "../../helpers/SessionHelper";

const livestockSubcategoriesOptions = [
    {
        "id": 1,
        "speciesId": 1, // Sheep old
        "speciesName": "sheep",
        "isYoung": false,
        "AselCategoryDescription": [
            {
                "description": "More than four permanent incisors"
            }
        ]
    },
    {
        "id": 2,
        "speciesId": 1, // Sheep young
        "speciesName": "sheep",
        "isYoung": true,
        "AselCategoryDescription": [
            {
                "description": "Young - up to four permanent incisors"
            }
        ]
    },
    {
        "id": 3,
        "speciesId": 2,
        "dailyFeedReqPercentage": 2,
        "speciesName": "cattle",
        "isYoung": false,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
    },
    {
        "id": 4,
        "speciesId": 2,
        "speciesName": "cattle",
        "dailyFeedReqPercentage": 2.5,
        "isYoung": true,
        "AselCategoryDescription": [
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
        ]
    },
    {
        "id": 5,
        "speciesId": 3,
        "speciesName": "buffalo",
        "dailyFeedReqPercentage": 2,
        "isYoung": false,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
    },
    {
        "id": 6,
        "speciesId": 3,
        "speciesName": "buffalo",
        "dailyFeedReqPercentage": 2.5,
        "isYoung": true,
        "AselCategoryDescription": [
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
        ]
    },
    {
        "id": 7,
        "speciesId": 4, //Alpacas
        "speciesName": "alpacas",
        "dailyFeedReqPercentage": 0,
        "isYoung": false,
        "AselCategoryDescription": []
    },
    {
        "id": 8,
        "speciesId": 5, //Camels
        "speciesName": "camels",
        "dailyFeedReqPercentage": 0,
        "isYoung": false,
        "AselCategoryDescription": []
    },
    {
        "id": 9,
        "speciesId": 6, //Deer
        "speciesName": "deer",
        "dailyFeedReqPercentage": 0,
        "isYoung": false,
        "AselCategoryDescription": []
    },
    {
        "id": 10,
        "speciesId": 7, // Goats old
        "speciesName": "goats",
        "isYoung": false,
        "AselCategoryDescription": [
            {
                "description": "More than four permanent incisors"
            }
        ]
    },
    {
        "id": 11,
        "speciesId": 7, // Goats young
        "speciesName": "goats",
        "isYoung": true,
        "AselCategoryDescription": [
            {
                "description": "Young - up to four permanent incisors"
            }
        ]
    },
    {
        "id": 12,
        "speciesId": 8, // Llamas
        "speciesName": "llamas",
        "isYoung": false,
        "AselCategoryDescription": []
    }
]

const validateConsignmentRowData = (data) => {
    if (!data.isValid) return false;
    if (!data.numHead) return false;
    if (data.numHead.length <= 0) return false;
    if (!data.speciesId) return false;
    if (data.speciesId.length <= 0) return false;
    if (!data.classId) return false;
    if (data.classId.length <= 0) return false;
    if (!data.aselCategories) return false;
    let isAselCategoriesValid = true;
    data.aselCategories.every(cat => {
        if (isNaN(cat.category)) {
            isAselCategoriesValid = false;
            return false;
        }

        if (isNaN(cat.dailyFeedReqPercentage)) {
            isAselCategoriesValid = false;
            return false;
        }

        if (!cat.hasOwnProperty("numHeadForCategory") || isNaN(cat.numHeadForCategory)) {
            isAselCategoriesValid = false;
            return false;
        }

        if (!cat.hasOwnProperty("avgWeight") || isNaN(cat.avgWeight)) {
            isAselCategoriesValid = false;
            return false;
        }

        if (Number(cat.avgWeight) > 0 && Number(cat.numHeadForCategory) === 0) {
            isAselCategoriesValid = false;
            return false;
        }
    })
    if (!isAselCategoriesValid) return false;
    return true;
}

const Consignment_Livestock = () => {
    const navigate = useNavigate();
    const [consignment, setConsignment] = useState(null);
    const [speciesOptions, setSpeciesOptions] = useState([]);
    const [livestockClassesOptions, setLivestockClassesOptions] = useState([]);
    const [managementPlans, setManagementPlans] = useState([]);
    const [livestockRows, setLivestockRows] = useState([]);
    const [triggerRowValidation, setTriggerRowValidation] = useState(0);
    const [enterDataManually, setEnterDataManually] = useState(false);
    const [pageError, setPageError] = useState("");
    const [isDataUploadedFromTemplate, setIsDataUploadedFromTemplate] = useState(false);
    const [importedDataSet, setImportedDataSet] = useState([]);
    const [importedDataSetEvaluation, setImportedDataSetEvaluation] = useState({});

    const [ consignmentBeingCreated, setConsignmentBeingCreated ] = useState({});
    const [ initialConsignmentBeingCreatedState, setInitialConsignmentBeingCreatedState ] = useState("");
    const [ showConfirmModificationsPanel, setShowConfirmModificationsPanel ] = useState(false);
    const [ isSpeciesClassRemovedInModification, setIsSpeciesClassRemovedInModification ] = useState(false);
    const [ isSpeciesClassAddedInModification, setIsSpeciesClassAddedInModification ] = useState(false);
    const [ confirmModificationNavigationPath, setConfirmModificationNavigationPath ] = useState("");

    useEffect(() => {
        let fetchSpecies = async () => {
            let results = await getSpecies();
            if (results?.status === 200) {
                setSpeciesOptions(results.data);
            }
        }

        let fetchSpeciesClasses = async () => {
            let results = await getSpeciesClasses();
            if (results?.status === 200) {
                setLivestockClassesOptions(results.data);
            }
        }

        let fetchManagementPlans = async () => {
            let results = await getManagementPlans();
            if (results?.status === 200) {
                setManagementPlans(results.data);
            }
        }

        let fetchAll = async () => {
            await fetchSpecies();
            await fetchSpeciesClasses();
            await fetchManagementPlans();
        }

        fetchAll();

        let sessionConsignmentBeingCreated = getSessionConsignmentBeingCreated();
        
        if (sessionConsignmentBeingCreated) {
            setConsignmentBeingCreated(sessionConsignmentBeingCreated);
            if (sessionConsignmentBeingCreated.livestock?.length > 0 && sessionConsignmentBeingCreated.livestockAddedManually) {
                let consignmentLivestockRows = sessionConsignmentBeingCreated.livestock.map((row, index) => {
                    return {
                        bgColor: index % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
                        key: `lr_${index}`,
                        data: {
                            rowId: index,
                            speciesId: row.speciesId,
                            classId: row.classId,
                            aselCategories: [
                                {
                                    numHeadForCategory: row.old.numHead,
                                    avgWeight: row.old.avgWeight
                                },
                                {
                                    numHeadForCategory: row.young.numHead,
                                    avgWeight: row.young.avgWeight
                                }
                            ]
                        }
                    }
                })
                setLivestockRows(consignmentLivestockRows);
            } else if (sessionConsignmentBeingCreated.livestockWithVoluntaryData?.length > 0 && !sessionConsignmentBeingCreated.livestockAddedManually) {
                addToOrUpdateSessionConsignmentBeingCreated({
                    livestock: []
                })
                dragAndDropDataImported(sessionConsignmentBeingCreated.livestockWithVoluntaryData);
            }

            if (sessionConsignmentBeingCreated.isModifiedAfterStarting === true) {
                setInitialConsignmentBeingCreatedState(sessionConsignmentBeingCreated);
            }

            setEnterDataManually(sessionConsignmentBeingCreated.livestockAddedManually);
        }
    }, []);

    const goBack = () => {
        navigate("/createconsignmentpersonnel")
    }

    const goToManagementPlans = () => {
        
        let modificationCheckResults = undefined;
        if (consignmentBeingCreated.isModifiedAfterStarting === true) {
            // Make sure the livestock changes aren't going to break things
            modificationCheckResults = checkLivestockModifications();
        } 

        if (modificationCheckResults && modificationCheckResults.canProceed === false) {
            setIsSpeciesClassAddedInModification(modificationCheckResults.errors.addingNewSpeciesClassAfterLoading === true);
            setIsSpeciesClassRemovedInModification(modificationCheckResults.errors.removingAllSpeciesClassAnimalsAfterLoading === true)
            setConfirmModificationNavigationPath("/createconsignmentmanagementplans");
            setShowConfirmModificationsPanel(true);
            return;
        } else {

            if (validateInputData()) {
                if (updateConsignmentBeingCreated()) {
                    navigate('/createconsignmentmanagementplans')
                } else {
                    setPageError("An error occurred while attempting to update livestock details. Please try again.")
                }
            }
        }
    }

    const goToReview = async () => {
        let modificationCheckResults = undefined;
        if (consignmentBeingCreated.isModifiedAfterStarting === true) {
            // Make sure the livestock changes aren't going to break things
            modificationCheckResults = checkLivestockModifications();
        }
        
        if (modificationCheckResults && modificationCheckResults.canProceed === false) {
            setIsSpeciesClassAddedInModification(modificationCheckResults.errors.addingNewSpeciesClassAfterLoading === true);
            setIsSpeciesClassRemovedInModification(modificationCheckResults.errors.removingAllSpeciesClassAnimalsAfterLoading === true)
            setConfirmModificationNavigationPath("/createconsignmentreview");
            setShowConfirmModificationsPanel(true);
            return;
        } else {
            if (validateInputData()) {
                if (updateConsignmentBeingCreated()) {
                    navigate('/createconsignmentreview')
                } else {
                    setPageError("An error occurred while attempting to update livestock details. Please try again.")
                }
            }
        }
    }

    const checkLivestockModifications = () => {
        return enterDataManually ? checkManuallyEnteredLivestockModifications() : checkUploadedLivestockModifications();
    }

    const checkManuallyEnteredLivestockModifications = () => {
        // Are the livestock rows valid at all
        if (livestockRows.length === 0) return false;
        if (livestockRows.find(r => !r.isValid) !== undefined) return false;

        // Does the entered data differ from the original data in significant ways?
        let previousLivestockData = [];
        if (initialConsignmentBeingCreatedState.livestockAddedManually === true) {
            previousLivestockData = initialConsignmentBeingCreatedState.livestock;
        } else {
            previousLivestockData = initialConsignmentBeingCreatedState.livestockWithVoluntaryData.map(lwvd => { return {numHead: lwvd.noHead, speciesId: speciesOptions.find(sp => sp.speciesName === lwvd.species)?.id, classId: livestockClassesOptions.find(lsc => lsc.className === lwvd.class)?.id}});
        }
        
        let currentLivestockData = livestockRows.map(lsr => lsr.data); // Map the manually entered data

        let isInvalid = false;
        let errors = {};

        currentLivestockData.forEach(curr => {
            // If the current species/class was not in the previous data, you can not add it now (since animals have been loaded)
            if (previousLivestockData.find(prev => Number(curr.speciesId) === Number(prev.speciesId) && Number(curr.classId) === Number(prev.classId)) === undefined) {
                isInvalid = true;
                errors.addingNewSpeciesClassAfterLoading = true;
            }
        });

        previousLivestockData.forEach(prev => {
            if (currentLivestockData.find(curr => Number(curr.speciesId) === Number(prev.speciesId) && Number(curr.classId) === Number(prev.classId)) === undefined) {
                isInvalid = true;
                errors.removingAllSpeciesClassAnimalsAfterLoading = true;
            }
        })


        return {
            canProceed: !isInvalid,
            errors: errors
        }

    }

    const checkUploadedLivestockModifications = () => {
        // Has data been uploaded?
        if (!isDataUploadedFromTemplate) return false;
        // Is the uploaded data valid at all?
        if (!validateImportedData()) return false;
        if (livestockRows.find(r => !r.isValid) !== undefined) return false;

        // Does the uploaded data differ from the original data in significant ways?
        // Does the entered data differ from the original data in significant ways?
        let previousLivestockData = [];
        if (initialConsignmentBeingCreatedState.livestockAddedManually === true) {
            previousLivestockData = initialConsignmentBeingCreatedState.livestock;
        } else {
            previousLivestockData = initialConsignmentBeingCreatedState.livestockWithVoluntaryData
            //.map(lwvd => { return {numHead: lwvd.noHead, speciesId: speciesOptions.find(sp => sp.speciesName === lwvd.species)?.id, classId: livestockClassesOptions.find(lsc => lsc.className === lwvd.class)?.id}});
        }
        //let previousLivestockData = initialConsignmentBeingCreatedState.livestockWithVoluntaryData;
        let currentLivestockData = importedDataSet;

        let isInvalid = false;
        let errors = {};
        currentLivestockData.forEach(curr => {
            // If the current species/class was not in the previous data, you can not add it now (since animals have been loaded)
            if (previousLivestockData.find(prev => curr.species === prev.species && curr.class === prev.class) === undefined) {
                isInvalid = true;
                errors.addingNewSpeciesClassAfterLoading = true;
            }
        });

        previousLivestockData.forEach(prev => {
            if (currentLivestockData.find(curr => curr.species === prev.species && curr.class === prev.class) === undefined) {
                isInvalid = true;
                errors.removingAllSpeciesClassAnimalsAfterLoading = true;
            }
        })


        return {
            canProceed: !isInvalid,
            errors: errors
        }
    }

    const onConfirmModifications = () => {
        setShowConfirmModificationsPanel(false);
        setIsSpeciesClassAddedInModification(false);
        setIsSpeciesClassRemovedInModification(false);
        if (validateInputData()) {
            if (updateConsignmentBeingCreated()) {
                navigate(confirmModificationNavigationPath, { state: { isLivestockChanged: true }})
            } else {
                setPageError("An error occurred while attempting to update livestock details. Please try again.")
            }
        }
    }

    const addLivestockRow = () => {
        let tempRows = [...livestockRows];
        let currentLastRowData = tempRows[tempRows.length - 1]?.data;
        tempRows.push({
            bgColor: tempRows.length % 2 === 0 ? "#F5F5F5" : "#FFFFFF",
            key: currentLastRowData ? currentLastRowData.rowId + 1 : livestockRows.length,
            data: { rowId: currentLastRowData ? currentLastRowData.rowId + 1 : livestockRows.length }
        });
        setLivestockRows(tempRows);
    }

    const removeLivestockRow = (rowId) => {
        let tempRows = [...livestockRows];
        tempRows = tempRows.filter(r => Number(r.data.rowId) !== Number(rowId));
        tempRows.forEach((item, index) => {
            item.bgColor = index % 2 === 0 ? "#F5F5F5" : "#FFFFFF"
        });
        setLivestockRows(tempRows);
    }

    const updateLivestockData = (data) => {
        let tempLivestockRows = [...livestockRows];
        let rowToUpdate = tempLivestockRows.find(row => row.data.rowId === data.rowId);
        if (rowToUpdate) {
            rowToUpdate.data = data;
        };
        setLivestockRows(tempLivestockRows);
    }

    const onEnterDataManually = () => {
        setLivestockRows([]);
        setPageError("");
        
        addToOrUpdateSessionConsignmentBeingCreated({
            livestock: [],
            managementPlans: [],
            preloadedManagementPlans: [],
            livestockAddedManually: false
        })
        if (livestockRows.length === 0) {
            addLivestockRow();
        }
        setEnterDataManually(true);
        let consignmentBeingCreated = getSessionConsignmentBeingCreated();
        setConsignmentBeingCreated(consignmentBeingCreated);
    }

    const clearManuallyEnteredData = () => {
        setLivestockRows([]);
        addToOrUpdateSessionConsignmentBeingCreated({
            livestock: [],
            managementPlans: [],
            preloadedManagementPlans: [],
            livestockAddedManually: false
        })
        setEnterDataManually(false);
        let consignmentBeingCreated = getSessionConsignmentBeingCreated();
        setConsignmentBeingCreated(consignmentBeingCreated);
    }

    const validateInputData = () => {
        setPageError("")
        setTriggerRowValidation(triggerRowValidation + 1);
        if (enterDataManually && livestockRows.length === 0) {
            setPageError("At least one valid row of livestock is required.")
            return false;
        }
        if ((!enterDataManually && !isDataUploadedFromTemplate) || (!enterDataManually && !validateImportedData())) {
            setPageError("Livestock data has not been imported or the imported livestock data is not valid.")
            return false;
        }
        return livestockRows.find(r => !r.isValid) === undefined;
    }

    const validateImportedData = () => {
        if (importedDataSet.length === 0) return false;
        // More conditions might go here - i.e. ensuring species and management plans match actual species and management plans
        return true;
    }

    const updateConsignmentBeingCreated = () => {
        let livestockAdded = [];
        if (enterDataManually) {
            if (livestockRows.length === 0) return false;

            livestockAdded = livestockRows.map(r => {
                if (!r.isValid) return;
                return {
                    speciesId: Number(r.data.speciesId),
                    classId: r.data.classId,
                    old: {
                        numHead: r.data.aselCategories[1].numHeadForCategory,
                        avgWeight: r.data.aselCategories[1].avgWeight
                    },
                    young: {
                        numHead: r.data.aselCategories[0].numHeadForCategory,
                        avgWeight: r.data.aselCategories[0].avgWeight
                    }
                }
            });
        } else {
            if (!validateImportedData()) return false;
            livestockAdded = importedDataSet.map(ids => {
                let matchingSpeciesId = speciesOptions.find(so => so.speciesName.toLowerCase() === ids.species.toLowerCase())?.id;
                if (matchingSpeciesId === undefined) return false;
                let matchingClassId = livestockClassesOptions.find(lsc => lsc.className.toLowerCase() === ids.class.toLowerCase())?.id;
                if (matchingClassId === undefined) return false;
                return {
                    speciesId: matchingSpeciesId,
                    classId: matchingClassId,
                    old: {
                        numHead: ids.isYoung ? 0 : Number(ids.noHead),
                        avgWeight: ids.isYoung ? 0 : Number(ids.weight)
                    },
                    young: {
                        numHead: ids.isYoung ? Number(ids.noHead) : 0,
                        avgWeight: ids.isYoung ? Number(ids.weight) : 0
                    }
                }
            });
        }
        if (livestockAdded.length === 0) return false;

        let importedManagementPlans = importedDataSet.length === 0 ? [] : buildPreloadedManagementPlans();
        let preloadedManagementPlans = consignmentBeingCreated.isEditMode === true && enterDataManually ? consignmentBeingCreated.preloadedManagementPlans : importedManagementPlans; // If the consignment is being edited, preloaded plans will already exist and should only be overridden by newly imported animals

        let lManagementPlans = [];
        if (preloadedManagementPlans && preloadedManagementPlans.length > 0) {
            // if we upload data via template we need to update the management plans property at this point,
            // since that is what is used when saving. We also need to keep prelaoded management plans so that
            // if a user presses edit we know if they uploaded from a file or entrered data manually
            preloadedManagementPlans.forEach(mp => {
                let speciesId = speciesOptions.find(so => so.speciesName.toLowerCase() === mp.species.toLowerCase())?.id;
                if (isNaN(Number(speciesId))) return;
                let classId = livestockClassesOptions.find(co => co.className.toLowerCase() === mp.class.toLowerCase())?.id;
                if (isNaN(Number(classId))) return;
                let managementPlanId = managementPlans.find(mpo => Number(mpo.managementPlanSpeciesID) === Number(speciesId) && mpo.managementPlanName.toLowerCase() === mp.managementPlanName.toLowerCase())?.id;
                let noHead = Number(mp.numberOfHead);

                lManagementPlans.push({
                    speciesId: speciesId,
                    classId: classId,
                    managementPlanId: managementPlanId,
                    numberOfHead: noHead
                });
            })
        }
        else if (consignmentBeingCreated.managementPlans) {
            lManagementPlans = consignmentBeingCreated.managementPlans;
        }

        addToOrUpdateSessionConsignmentBeingCreated({
            livestock: livestockAdded,
            livestockAddedManually: enterDataManually,
            importType: importedDataSetEvaluation?.dataSetType,
            preloadedManagementPlans: preloadedManagementPlans,
            livestockWithVoluntaryData: [...importedDataSet],
            managementPlans: lManagementPlans
        })

        return true;
    }

    const buildPreloadedManagementPlans = () => {
        let importedAnimals = [...importedDataSet];
        let uniqueManagementPlans = getImportedUniqueManagementPlans(importedAnimals);
        let preloadedManagementPlans = [];
        let uniqueSpeciesClasses = [...new Set(importedAnimals.map(ia => `${ia.species},${ia.class}`))];
        uniqueSpeciesClasses.forEach(speciesClassStr => {
            let components = speciesClassStr.split(",");
            let speciesComponent = components[0];
            let classComponent = components[1];
            uniqueManagementPlans.forEach(ump => {
                let listOfHead = importedAnimals.filter(ia => ia.species === speciesComponent && ia.class === classComponent && (ia.managementPlan1 === ump || ia.managementPlan2 === ump || ia.managementPlan3 === ump || ia.managementPlan4 === ump)).map(filteredAnimal => filteredAnimal.noHead);
                let noHead = listOfHead.length > 0 ? listOfHead.reduce((a, b) => a + b) : 0;
                if (noHead > 0) {
                    preloadedManagementPlans.push({
                        species: speciesComponent,
                        class: classComponent,
                        managementPlanName: ump,
                        numberOfHead: noHead
                    });
                }
            });
        });

        preloadedManagementPlans = preloadedManagementPlans.filter(pmp => pmp.numberOfHead !== undefined || pmp.numberOfHead > 0);
        return preloadedManagementPlans;
    }

    const dragAndDropDataImported = (data) => {
        let dataSetEvaluation = evaluateImportedDataSet(data);
        setImportedDataSetEvaluation(dataSetEvaluation);
        setImportedDataSet(data);
        setEnterDataManually(false);
        setIsDataUploadedFromTemplate(true);
    }

    const evaluateImportedDataSet = (data) => {
        return {
            dataSetType: data.find(d => Number(d.noHead) > 1) === undefined ? "Individual" : "Aggregate",
            totalAnimals: data.map(d => d.noHead).filter(r => !isNaN(Number(r))).reduce((a, b) => Number(a) + Number(b))
        }
    }

    const buildGroupedImportedAnimals = () => {
        let importedAnimals = [...importedDataSet];
        let groups = [];
        // Create one group for each distinct species/class/exporterLine combination
        importedAnimals.forEach((animal, i) => {
            let existingGroup = groups.find(g => g.species === animal.species && g.class === animal.class && g.exporterLine === animal.exporterLine);
            if (!existingGroup) {
                groups.push({
                    count: animal.noHead,
                    species: animal.species,
                    class: animal.class,
                    exporterLine: animal.exporterLine,
                    avgWeight: animal.weight
                })
            }
        });
        let talliedGroups = [];
        // Iterate over each distinct group and sum the total animal count and total weight (temporary)
        groups.forEach((g, i) => {
            let targetImportedAnimalList = importedAnimals.filter(ia => ia.species === g.species && ia.class === g.class && ia.exporterLine === g.exporterLine);
            if (targetImportedAnimalList.length === 1) {
                // It is a group of one row
                let targetAnimal = targetImportedAnimalList[0];
                talliedGroups.push({
                    count: Number(targetAnimal.noHead),
                    species: targetAnimal.species,
                    class: targetAnimal.class,
                    exporterLine: targetAnimal.exporterLine,
                    totalWeight: targetAnimal.weight,
                    summed: false
                });

            } else {
                let animalsWeightHeadMapping = [];

                targetImportedAnimalList.forEach(animalRow => {
                    animalsWeightHeadMapping.push({ noHead: animalRow.noHead, weight: Number(animalRow.weight), summedWeight: Number(animalRow.noHead) * Number(animalRow.weight)});
                })
                let totalAnimals = animalsWeightHeadMapping.map(animals => Number(animals.noHead)).reduce((a, b) => a + b);
                let talliedGroup = {
                    count: totalAnimals,
                    species: targetImportedAnimalList[0].species,
                    class: targetImportedAnimalList[0].class,
                    exporterLine: targetImportedAnimalList[0].exporterLine,
                    totalWeight: animalsWeightHeadMapping.map(awhm => awhm.summedWeight).reduce((a, b) => a + b) / Number(totalAnimals), // This is actually the avgWeight despite the property name
                    summed: false
                }

                talliedGroups.push(talliedGroup);
            }
            
        })
        // Now that we have the total count and total weight, set the average weight and remove the total weight
        talliedGroups.forEach(tg => {
            tg.avgWeight = tg.summed ? (tg.totalWeight / tg.count) : tg.totalWeight;
            delete tg.totalWeight;
            delete tg.summed;
        })
        return talliedGroups.map((tg, i) => {
            return (
                <Row key={`tallied_group_${i}`}>
                    <Row>
                        <Col xs={2}>{tg.count}</Col>
                        <Col xs={4}>{`${tg.species} - ${tg.class}`}</Col>
                        <Col xs={4}>{tg.exporterLine}</Col>
                        <Col xs={2}>{Math.round(Number(tg.avgWeight)).toFixed(0)}</Col>
                    </Row>
                    <Row className="my-1 bg-grey" style={{ height: '1px' }}></Row>
                </Row>
            )
        });
    }

    const getImportedUniqueManagementPlans = (importedAnimals) => {
        let uniqueManagementPlanOnes = [...new Set([...importedAnimals].filter(an => an.managementPlan1 && an.managementPlan1 !== null).map(ia => ia.managementPlan1))];
        let uniqueManagementPlanTwos = [...new Set([...importedAnimals].filter(an => an.managementPlan1 && an.managementPlan1 !== null).map(ia => ia.managementPlan2))];
        let uniqueManagementPlanThrees = [...new Set([...importedAnimals].filter(an => an.managementPlan1 && an.managementPlan1 !== null).map(ia => ia.managementPlan3))];
        let uniqueManagementPlanFours = [...new Set([...importedAnimals].filter(an => an.managementPlan1 && an.managementPlan1 !== null).map(ia => ia.managementPlan4))];
        let allUniqueManagementPlans = [...new Set([].concat.apply([], [uniqueManagementPlanOnes, uniqueManagementPlanTwos, uniqueManagementPlanThrees, uniqueManagementPlanFours]))].filter(ump => ump !== undefined);
        return allUniqueManagementPlans;
    }

    const buildManagementPlanGroups = () => {
        let importedAnimals = [...importedDataSet];
        
        let allUniqueManagementPlans = getImportedUniqueManagementPlans(importedAnimals);
        return allUniqueManagementPlans.map((ump, i) => {
            return (
                <Row key={`ump_${i}`}>
                    <Row>
                        <Col xs={6}>{ump}</Col>
                        <Col xs={6}><div style={{paddingLeft: "12px"}}>{importedAnimals.filter(ia => ia.managementPlan1 === ump || ia.managementPlan2 === ump || ia.managementPlan3 === ump || ia.managementPlan4 === ump).map(filteredAnimal => filteredAnimal.noHead).reduce((a, b) => a + b)}</div></Col>
                    </Row>
                    <Row className="my-1 bg-grey" style={{ height: '1px' }}></Row>
                </Row>
            )
        })
    }

    const buildRegulatoryInformation = () => {
        let importedData = [...importedDataSet];
        let speciesNames = [...new Set(importedData.map(id => id.species))];
        let speciesDataObjects = [];
        speciesNames.forEach(speciesName => {
            let correspondingAnimals = importedData.filter(id => id.species === speciesName);
            let correspondingYoungAnimals = correspondingAnimals.filter(a => a.isYoung);
            let correspondingOldAnimals = correspondingAnimals.filter(a => !a.isYoung);
            let numberOfOld = correspondingOldAnimals.length > 1 ? [0, ...correspondingOldAnimals.map(y => y.noHead)].reduce((a, b) => a + b) : (correspondingOldAnimals[0]?.noHead ?? 0);
            let avgWeightOfOld = correspondingOldAnimals.length > 1 ? [0, ...correspondingOldAnimals.map(y => y.noHead * y.weight)].reduce((a, b) => a + b) / numberOfOld : (correspondingOldAnimals[0]?.weight ?? 0)
            let numberOfYoung = correspondingYoungAnimals.length > 1 ? [0, ...correspondingYoungAnimals.map(y => y.noHead)].reduce((a, b) => a + b) : (correspondingYoungAnimals[0]?.noHead ?? 0);
            let avgWeightOfYoung = correspondingYoungAnimals.length > 1 ? [0, ...correspondingYoungAnimals.map(y => y.noHead * y.weight)].reduce((a, b) => a + b) / numberOfYoung : (correspondingYoungAnimals[0]?.weight ?? 0);
            speciesDataObjects.push({
                speciesName: speciesName,
                old: {
                    noHead: numberOfOld,
                    avgWeight: avgWeightOfOld,
                },
                young: {
                    noHead: numberOfYoung,
                    avgWeight: avgWeightOfYoung,
                }
            })
        })
        return speciesDataObjects;
    }

    const importedFileReset = () => {
        setLivestockRows([]);
        setPageError("");
        setImportedDataSetEvaluation({});
        setImportedDataSet([]);
        setIsDataUploadedFromTemplate(false);
        addToOrUpdateSessionConsignmentBeingCreated({
            livestock: [],
            managementPlans: [],
            preloadedManagementPlans: [],
            livestockAddedManually: false
        })
        let consignmentBeingCreated = getSessionConsignmentBeingCreated();
        setConsignmentBeingCreated(consignmentBeingCreated);
    }

    const handleImportError = (msg) => {
        setPageError(msg);
    }

    const getOldYoungHeading = (speciesName, isYoung) => {
        let categoryDescriptions = livestockSubcategoriesOptions.find(lsc => lsc.speciesName.toLowerCase() === speciesName.toLowerCase() && lsc.isYoung === isYoung).AselCategoryDescription;
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', height: "100%" }}>

                {categoryDescriptions.map((desc, idx) => <div key={`cat_desc_${idx}`} className="mb-2" style={{ textAlign: "center", fontWeight: 500, borderBottom: "1px solid grey" }}>{desc.description}</div>)}

            </div>
        )
    }

    const hasOldYoungDifferentiation = (speciesName) => {
        let hasDifferentiation = ["sheep", "cattle", "buffalo", "goats"]
        return hasDifferentiation.includes(speciesName.toLowerCase());
    }

    const progressStages = [
        { title: "Voyage Details", completed: true },
        { title: "Personnel & Reporting", completed: true },
        { title: "Livestock", completed: false },
        { title: "Approved Management Plans", completed: false }
    ];

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>{consignmentBeingCreated.isModifiedAfterStarting === true ? "Change consignment livestock" : "Create new consignment"}</div>
                                <ConsignmentProgression stages={progressStages} currentStage={progressStages[2]} key="consignment_progress" />
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Container className="py-4 px-5 form-container">
                    <Row className="mb-3">
                        <Col xs={12}>
                            <div className="heading-text darkblue">Livestock details</div>
                            <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                        </Col>
                    </Row>
                    {
                        !enterDataManually ? (
                            <>
                                <Row className="mb-3">
                                    <Col xs={12} className="field-label secondary-grey">
                                        <p>There are multiple options to enter the livestock details required for a consignment.</p>
                                        <p>Import templates that allow you to input additional information which is not provided to the department but allows for greater accuracy for the stockperson/AAV and more in-depth analysis for your business and the industry.</p>
                                        <ul>
                                            <li>Import template for individual tag list or aggregate animals <Link to="/files/import_template_v2_0.xlsx" target="_blank" download={true}>[DOWNLOAD HERE]</Link></li>
                                        </ul>
                                        {/*
                                            ---HIDE UNTIL WE KNOW WHERE TO LINK TO---
                                            <p>CLICK HERE TO LEARN MORE ABOUT VOLUNTARY DATA IN LIVEXCollect</p>
                                        */}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <LivestockDragNDropUploader onImported={dragAndDropDataImported} onReset={importedFileReset} species={speciesOptions} classes={livestockClassesOptions} managementPlans={managementPlans} onRaiseError={handleImportError} hasData={importedDataSet?.length > 0} />
                                    </Col>
                                </Row>
                            </>
                        ) : null
                    }
                    {!isDataUploadedFromTemplate ? (
                        <Row className="mt-4">
                            {
                                !enterDataManually ? (
                                    <Col xs={12}>
                                        <span className="field-label secondary-grey">Or you can choose to <strong>manually</strong> enter the livestock details: &nbsp;</span>
                                        <Button
                                            className="btn-secondary"
                                            onClick={onEnterDataManually}
                                        >
                                            ENTER DATA MANUALLY
                                        </Button>
                                    </Col>) : null
                            }

                            {
                                enterDataManually ? (
                                    <Col xs={12}>
                                        <Button
                                            className="btn-secondary"
                                            onClick={() => clearManuallyEnteredData()}
                                        >
                                            CLEAR MANUALLY ENTERED DATA
                                        </Button>
                                    </Col>
                                ) : null
                            }
                        </Row>
                    ) : null}
                    {
                        enterDataManually ?
                            (
                                <>
                                    <Row className="mt-4 pe-2">
                                        <Col xs={1} className="align-bottom field-label darkblue">
                                            No. of head
                                        </Col>
                                        <Col xs={2} className="align-bottom ps-3 field-label darkblue">
                                            Species
                                        </Col>
                                        <Col xs={2} className="align-bottom ps-3 field-label darkblue">
                                            Class
                                        </Col>
                                        <Col xs={6}>
                                            <Row>
                                                <Col xs={4}></Col>
                                                <Col xs={4} className="align-bottom ps-3 field-label darkblue">
                                                    No. of head per category
                                                </Col>
                                                <Col xs={4} className="align-bottom ps-3 field-label darkblue">
                                                    Avg Weight
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                    {
                                        livestockRows.map(lr => {
                                            return <LivestockDetail
                                                key={`lsrow_${lr.key}`}
                                                livestockDetail={lr}
                                                speciesOptions={speciesOptions}
                                                livestockClassesOptions={livestockClassesOptions}
                                                triggerValidation={triggerRowValidation}
                                                onChange={updateLivestockData}
                                                onDelete={removeLivestockRow}
                                            />
                                        })
                                    }
                                    <Row>
                                        <Col>
                                            <div className="cc-add-port-button px-2 py-1" onClick={addLivestockRow}><FontAwesomeIcon className="primary-green" icon={faCirclePlus} /> Add row</div>
                                        </Col>
                                    </Row>
                                </>
                            )
                            : null
                    }
                    {
                        isDataUploadedFromTemplate ? (
                            <Container className="pt-3">
                                <Row>
                                    <Alert variant="info" className="text-center">
                                        <div className="pb-2">{importedDataSetEvaluation?.dataSetType} animal data template detected</div>
                                        <FontAwesomeIcon icon={faCheckCircle} style={{ height: '20px', width: '20px' }} /> {importedDataSetEvaluation?.totalAnimals} animals recorded in spreadsheet
                                    </Alert>
                                </Row>
                                <Row className="pe-2">
                                    <div className="heading-text darkblue">Aggregated Information</div>
                                    <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    <div><em>This information has been calculated from your spreadsheet upload and shows the aggregated data that has been identified.  This will <b>NOT</b> be sent to the Department.</em></div>
                                </Row>
                                <Row className="pt-3">
                                    <div className="heading-text darkblue" style={{ fontSize: "18px" }}>Imported livestock</div>
                                    <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Row>
                                            <Row>
                                                <Col xs={2} className="field-label">No. of Head</Col>
                                                <Col xs={4} className="field-label">Species / Class</Col>
                                                <Col xs={4} className="field-label">Exporter Line</Col>
                                                <Col xs={2} className="field-label">Avg Weight</Col>
                                            </Row>
                                        </Row>
                                        {buildGroupedImportedAnimals()}
                                    </Col>
                                </Row>

                                <Row className="pt-4">
                                    <Col xs={8}>
                                        <Row className="pe-2">
                                            <div className="heading-text darkblue" style={{ fontSize: "18px" }}>Management Plans</div>
                                            <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className="field-label">Management Plan</Col>
                                            <Col xs={6} className="field-label">No. head against this plan</Col>
                                        </Row>
                                        {buildManagementPlanGroups()}
                                    </Col>
                                    <Col xs={4}>
                                        <Row className="pe-2">
                                            <div className="heading-text darkblue" style={{ fontSize: "18px" }}>Livestock totals</div>
                                            <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                        </Row>
                                        <Row className="pe-2">
                                            <Col xs={12} className="bg-grey px-3 py-1">
                                                {
                                                    importedDataSet && importedDataSet.map(ids => {
                                                        return {
                                                            species: ids.species,
                                                            noHead: ids.noHead
                                                        }
                                                    }).map((animal, index, arr) => {
                                                        let indexOfFirstSpeciesEntry = arr.indexOf(arr.find(a => a.species === animal.species));
                                                        if (index !== indexOfFirstSpeciesEntry) return;

                                                        return (
                                                            <div key={`ls_count_${index}`}>
                                                                <Row >
                                                                    <Col xs={5}>
                                                                        {animal.species}
                                                                    </Col>
                                                                    <Col xs={7} className="text-end">
                                                                        {arr.filter(a => a.species === animal.species).map(a => a.noHead).reduce((a, b) => a + b)}
                                                                    </Col>
                                                                </Row>
                                                                {index !== arr.length - 1 ?
                                                                    (
                                                                        <Row key={`ls_count_line_${index}`} className="my-1 bg-override-primary" style={{ height: "1px", marginRight: "1px" }} />
                                                                    ) : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="pt-5 pe-2 mb-3">
                                    <div className="heading-text darkblue">Regulatory Information</div>
                                    <div className="bg-override-primary mt-1 mb-3" style={{ height: "2px" }}></div>
                                    <div><em>This information has been calculated from your spreadsheet upload and shows the data that will be sent to the Department.</em></div>
                                </Row>
                                {
                                    buildRegulatoryInformation().map((ri, i) => {
                                        let bgColor = i % 2 === 0 ? "#F5F5F5" : "#FFFFFF"
                                        return (
                                            <Row key={`reg_inf_${i}`} className="pb-3 pt-1 px-2" style={{ backgroundColor: bgColor }}>
                                                <Col xs={12}>
                                                    <Row>
                                                        <div className="heading-text darkblue" style={{ fontSize: "18px" }}>{ri.speciesName}</div>
                                                        <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}></Col>
                                                        {hasOldYoungDifferentiation(ri.speciesName) ?
                                                            (
                                                                <>
                                                                    <Col xs={5} className="text-center">{getOldYoungHeading(ri.speciesName, false)}</Col>
                                                                    <Col xs={5} className="text-center">{getOldYoungHeading(ri.speciesName, true)}</Col>
                                                                </>
                                                            ) :
                                                            (
                                                                <Col xs={10} className="text-center" style={{ textAlign: "center", fontWeight: 500, borderBottom: "1px solid grey" }}>{`All ${ri.speciesName}`}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}>No. of head</Col>
                                                        {hasOldYoungDifferentiation(ri.speciesName) ?
                                                            (
                                                                <>
                                                                    <Col xs={5} className="text-center">{ri.old.noHead}</Col>
                                                                    <Col xs={5} className="text-center">{ri.young.noHead}</Col>
                                                                </>
                                                            ) : (
                                                                <Col xs={10} className="text-center">{ri.old.noHead}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Col xs={2}>Avg. weight</Col>
                                                        {hasOldYoungDifferentiation(ri.speciesName) ?
                                                            (
                                                                <>
                                                                    <Col xs={5} className="text-center">{ri.old.noHead <= 0 ? "N/A" : `${(Number(ri.old.avgWeight)).toFixed(0)}kg`}</Col>
                                                                    <Col xs={5} className="text-center">{ri.young.noHead <= 0 ? "N/A" : `${(Number(ri.young.avgWeight)).toFixed(0)}kg`}</Col>
                                                                </>
                                                            ) : (
                                                                <Col xs={10} className="text-center">{ri.old.noHead <= 0 ? "N/A" : `${(Number(ri.old.avgWeight)).toFixed(0)}kg`}</Col>
                                                            )
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )
                                    })

                                }
                                <Row className="pt-3">
                                    <Col xs={8}>
                                        <Row className="pe-2">
                                            <div className="heading-text darkblue" style={{ fontSize: "18px" }}>Management Plans</div>
                                            <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                        </Row>
                                        <Row>
                                            <Col xs={6} className="field-label">Management Plan</Col>
                                            <Col xs={6} className="field-label">No. head against this plan</Col>
                                        </Row>
                                        {buildManagementPlanGroups()}
                                    </Col>
                                    <Col xs={4}>
                                        <Row className="pe-2">
                                            <div className="heading-text darkblue" style={{ fontSize: "18px" }}>Livestock totals</div>
                                            <div className="bg-override-primary mb-3" style={{ height: "1px" }}></div>
                                        </Row>
                                        <Row className="pe-2">
                                            <Col xs={12} className="bg-grey px-3 py-1">
                                                {
                                                    importedDataSet && importedDataSet.map(ids => {
                                                        return {
                                                            species: ids.species,
                                                            noHead: ids.noHead
                                                        }
                                                    }).map((animal, index, arr) => {
                                                        let indexOfFirstSpeciesEntry = arr.indexOf(arr.find(a => a.species === animal.species));
                                                        if (index !== indexOfFirstSpeciesEntry) return;

                                                        return (
                                                            <div key={`ls_reg_count_${index}`}>
                                                                <Row >
                                                                    <Col xs={5}>
                                                                        {animal.species}
                                                                    </Col>
                                                                    <Col xs={7} className="text-end">
                                                                        {arr.filter(a => a.species === animal.species).map(a => a.noHead).reduce((a, b) => a + b)}
                                                                    </Col>
                                                                </Row>
                                                                {index !== arr.length - 1 ?
                                                                    (
                                                                        <Row key={`ls_reg_count_line_${index}`} className="my-1 bg-override-primary" style={{ height: "1px", marginRight: "1px" }} />
                                                                    ) : null}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        ) : null
                    }
                    {
                        pageError.length > 0 ?
                            (
                                <Row className="mt-4">
                                    <Alert variant="danger">{pageError}</Alert>
                                </Row>
                            ) : null
                    } 
                    {
                        showConfirmModificationsPanel ? (
                            <Row className="mt-3">
                                <Alert variant="warning">
                                    <Container>
                                        <Row className="mb-2">
                                            <Col>
                                                You have made the following modifications to a consignment that is already underway aboard a ship:
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <ul>
                                                    {isSpeciesClassAddedInModification && <li><em>Added a new species/class that was not previously associated with the consignment</em></li>}
                                                    {isSpeciesClassRemovedInModification && <li><em>Entirely removed a species/class that was previously associated with the consignment.</em></li>}
                                                </ul>
                                            </Col>
                                        </Row>
                                        
                                        <Row className="mt-1">
                                            <Col>
                                                Are you sure you wish to proceed?
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">

                                            <Col style={{textAlign: 'end'}}>
                                                <Button
                                                    className="btn-secondary me-3"
                                                    style={{width: '150px'}}
                                                    onClick={() => navigate("/dashboard")}
                                                >
                                                    No
                                                </Button>

                                                <Button
                                                    className="btn-primary"
                                                    style={{width: '150px'}}
                                                    onClick={onConfirmModifications}
                                                >
                                                    Yes, proceed
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Alert>
                            </Row>
                        ) : null
                    }
                </Container>
                <Container className="button-container">
                    <Row className='mt-5' style={{ position: "relative", top: "-300px" }}>
                        <Stack direction="horizontal" gap={2}>
                            {
                                enterDataManually === true ?
                                    <Button variant="primary" onClick={goToManagementPlans} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Next</Button>
                                    :
                                    <Button variant="primary" onClick={goToReview} size="lg" style={{ marginLeft: "-10px", minWidth: "150px" }}>Complete</Button>
                            }
                            {!getSessionConsignmentBeingCreated().isModifiedAfterStarting &&
                                <Button className="me-2 me-auto" variant="secondary" onClick={goBack} size="lg" style={{ minWidth: "150px" }}>Previous</Button>
                            }
                        </Stack>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
};

export default Consignment_Livestock;