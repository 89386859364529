import "bootstrap/dist/css/bootstrap.min.css";
import "../css/app.css";
import "../css/login.css";
import CattleBG from "../assets/cattle.jfif";
import Logo from "../assets/LiveCorp_Logo.svg";
import React, { useState } from "react";
import LoggedOutContainer from "../layout/LoggedOutContainer";
import { Container, Stack, Form, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { getDownloadAccess } from "../api/apiAccess";

const DownloadApp = () => {
  const [password, setPassword] = useState("");
  const [isShowErrorMessage, setIsShowErrorMessage] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [downloadLink, setDownloadLink] = useState("");

  const handleDownloadAccess = async () => {
    try {
      await getDownloadAccess(password, (result) => {
        if (result?.status === 200) {
          setDownloadLink(result.data);
          setIsShowErrorMessage(false);
          setIsValidPassword(true);
        } else {
          setIsShowErrorMessage(true);
          setIsValidPassword(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LoggedOutContainer>
      <Row className="m-0 p-0">
        <Col xs={6} className="m-0 p-0 login-left-panel">
          <Stack className="text-center vh-100 m-0">
            <img alt="" className="login-left-panel-bg-image" src={CattleBG} />
            <div className="login-left-panel-text-container">
              <div className="login-left-panel-text login-left-panel-title">LIVEXCollect</div>
              <p className="mt-3 mb-0 login-left-panel-text login-left-panel-subtitle">Industry data</p>
              <p className="mt-0 login-left-panel-text login-left-panel-subtitle" style={{ color: "white" }}>
                in industry's hands
              </p>
            </div>
          </Stack>
        </Col>
        <Col xs={6} className="m-0 p-0">
          <div style={{ verticalAlign: "middle", height: "100vh", paddingTop: "15vh" }}>
            <Container className="mx-auto mb-3" style={{ width: "700px" }}>
              <Row className="mt-5 mb-2 text-center">
                <div className="mb-4">
                  <img src={Logo} alt="LIVEXCollect logo" style={{ width: "143px" }} />
                </div>
                <div className="subtitle-text-heavy darkblue">Download LIVEXCollect Ship App</div>
              </Row>

              {isShowErrorMessage && (
                <Row>
                  <Alert variant="danger">Invalid password. Please check and try again.</Alert>
                </Row>
              )}
            </Container>
            <Container className="px-auto" style={{ width: "400px" }}>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                {isValidPassword ? (
                  <>
                    <Container className="text-center mt-5">
                      <Button
                        href={downloadLink}
                        className="btn-lg btn-primary"
                        style={{ minWidth: "200px" }}
                        onClick={(e) => {
                          // onRequestAuth();
                        }}
                      >
                        <svg className="me-3" height="1.4em" viewBox="0 0 448 512">
                          <path
                            style={{ fill: "white" }}
                            d="M0 93.7l183.6-25.3v177.4H0V93.7zm0 324.6l183.6 25.3V268.4H0v149.9zm203.8 28L448 480V268.4H203.8v177.9zm0-380.6v180.1H448V32L203.8 65.7z"
                          />
                        </svg>
                        Download for Windows
                      </Button>
                    </Container>
                  </>
                ) : (
                  <>
                    <Form.Group className="mt-5 mb-3" controlId="loginForm.password">
                      <Form.Label className="field-label">Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => {
                          e.preventDefault();
                          setPassword(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Container className="text-center mt-5">
                      <Button
                        className="btn-lg btn-primary"
                        style={{ minWidth: "200px" }}
                        onClick={(e) => {
                          handleDownloadAccess();
                        }}
                      >
                        NEXT
                      </Button>
                    </Container>
                  </>
                )}
              </Form>
            </Container>
          </div>
        </Col>
      </Row>
    </LoggedOutContainer>
  );
};

export default DownloadApp;
