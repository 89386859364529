export class SimpleHistoricalREConsignment {
    RegisteredEstablishmentConsignmentID;
    LastAnimalDepartureDate;
    LAENumber;
    PremisesName;
    ExporterName;
    LNCNumber;
    
    constructor({registeredEstablishmentConsignmentID, lastAnimalDepartureDate, premisesName, laeNumber, exporterName, lncNumber}) {
        this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
        this.LastAnimalDepartureDate = lastAnimalDepartureDate;
        this.LAENumber = laeNumber;
        this.PremisesName = premisesName;
        this.ExporterName = exporterName;
        this.LNCNumber = lncNumber;
    }
}