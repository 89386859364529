import React, { useEffect, useState, useContext } from "react";
import { Container, Stack, Row, Col, Button } from "react-bootstrap";
import { faCheck, faEye, faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    getConsignmentDeparturePortsForConsignment, getConsignmentDestinationPortsForConsignment, getDeparturePorts,
    getDestinationPorts, getConsignmentDaysForConsignmentID
} from "../../api/apiAccess"
import { useNavigate } from "react-router-dom";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox } from "../Status/ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox";
import { UserContext } from "../..";
import { parseISO } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";

const ConsignmentLoadingAndDischargingPorts = ({ consignmentID, isMobile, isHistorical, endOfVoyageSubmittedDate}) => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    const [departurePorts, setDeparturePorts] = useState([]);
    const [destinationPorts, setDestinationPorts] = useState([]);
    const [loadingPorts, setLoadingPorts] = useState([]);
    const [dischargePorts, setDischargePorts] = useState([]);
    const [consignmentDays, setConsignmentDays] = useState([]);
    

    useEffect(() => {
        const fetchDeparturePorts = async () => {
            let results = await getDeparturePorts();
            if (results?.status === 200) {
                setDeparturePorts(results.data);
            }
        }

        const fetchDestinationPorts = async () => {
            let results = await getDestinationPorts();
            if (results?.status === 200) {
                setDestinationPorts(results.data);
            }
        }

        const fetchAll = async () => {
            await fetchDeparturePorts();
            await fetchDestinationPorts();
        }
        fetchAll();
    }, []);

    useEffect(() => {
        const fetchConsignmentDays = async () => {
            let results = await getConsignmentDaysForConsignmentID(consignmentID);
            if (results?.status === 200) {
                setConsignmentDays(results.data);
            }
        }
        if (consignmentID) {
            fetchConsignmentDays();
        }
    }, [consignmentID])

    const formatInTimeZone = (date, fmt, tz) => {
        let utcToZoned = utcToZonedTime(date, tz);
        return format(utcToZoned, fmt, { timeZone: tz });
    }
    
    useEffect(() => {
        if (consignmentID === undefined || !departurePorts || departurePorts.length === 0 || !consignmentDays) return;

        const fetchLoadingPorts = async () => {
            let results = await getConsignmentDeparturePortsForConsignment(consignmentID);
            if (results?.status === 200) {
                let lSubmittedConsignmentDayIDs = consignmentDays.map(cd => cd.id);

                let mappedLoadingPorts = results.data.map(result => {
                    let lPort = departurePorts.find(port => port.id === result.portID);
                    lPort.consignmentDeparturePortID = result.id;

                    let lHasReport = false;
                    let lIsViewed = false;
                    // regulatory users can only view reports that were completed on a consignment dya the has been submitted,
                    // Ideally we would have handles this on the database siade, nut it would have required significant changes
                    if(userCtx.user?.userTypeID === 2){
                        lHasReport = !StringIsNullUndefinedOrEmpty(result.loadingCompletedDateUTC) && lSubmittedConsignmentDayIDs.includes(result.consignmentDayID);
                        lIsViewed = lHasReport;
                    }
                    else{
                        lHasReport = !StringIsNullUndefinedOrEmpty(result.loadingCompletedDateUTC);
                        lIsViewed = result.isLoadingAndDepartureReviewed;
                    }

                    lPort.hasReport = lHasReport;
                    lPort.isViewed = lIsViewed;
                    return lPort;
                })
                setLoadingPorts(mappedLoadingPorts);
            }
        }

        fetchLoadingPorts();

    }, [departurePorts, consignmentID, consignmentDays]);

    useEffect(() => {
        if (consignmentID === undefined || !destinationPorts || destinationPorts.length === 0) return;
        const fetchDischargePorts = async () => {
            let results = await getConsignmentDestinationPortsForConsignment(consignmentID);
            if (results?.status === 200) {
                let lSubmittedConsignmentDayIDs = consignmentDays.map(cd => cd.id);

                let mappedDischargePorts = results.data.map((result) => {
                    if (destinationPorts.length === 0) return;
                    let lPort = destinationPorts.find(port => port.id === result.portID);
                    
                    let lHasReport = false;
                    let lIsViewed = false;
                    // regulatory users can only view reports that were completed on a consignment dya the has been submitted,
                    // Ideally we would have handles this on the database siade, nut it would have required significant changes
                    if(userCtx.user?.userTypeID === 2){
                        lHasReport = !StringIsNullUndefinedOrEmpty(result.dischargeCompletedDateUTC) && lSubmittedConsignmentDayIDs.includes(result.consignmentDayID);
                        lIsViewed = lHasReport;
                    }
                    else{
                        lHasReport = !StringIsNullUndefinedOrEmpty(result.dischargeCompletedDateUTC);
                        lIsViewed = result.isDischargeReviewed;
                    }
                    
                    lPort.consignmentDestinationPortID = result.id;
                    lPort.hasReport = lHasReport;
                    lPort.isViewed = lIsViewed;
                    if (lPort) return lPort;
                })
                setDischargePorts(mappedDischargePorts);
            }
        }

        fetchDischargePorts();
    }, [destinationPorts, consignmentID, consignmentDays])

    return (
        <Container>
            {
                isMobile ? 
                (
                    <Row className="pt-4">
                        <Col xs={12}>
                            <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                            Loading ports
                            </div>
                        </Col>
                    </Row>
                ) : 
                (
                    <Row className="pt-4">
                        <Col xs={12}>
                            <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                            Loading/Discharging ports
                            </div>
                        </Col>
                    </Row>
                )
            }
            <Row>
                <Col xs={isMobile ? 12 : (isHistorical ? 4 : 6)}>
                    <Container>
                        <Row className="bg-grey py-2" style={{ borderRadius: '4px 4px 0 0', border: '1px solid #DFE0E4' }}>
                            <Col>
                                <div className="field-label">Loading in</div>
                            </Col>
                            <Col>
                                <div className="field-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Loading report</div>
                            </Col>
                        </Row>
                        {
                            loadingPorts && loadingPorts.map((port, i) => {
                                let hasBottomRoundedBorder = i === loadingPorts.length - 1;
                                return (
                                    <Row key={`loading_port_${i}`} className="py-1" style={{ borderRadius: hasBottomRoundedBorder ? '0 0 4px 4px' : '0', borderLeft: '1px solid #DFE0E4', borderRight: '1px solid #DFE0E4', borderBottom: '1px solid #DFE0E4', alignItems: "center" }}>
                                        <Col>
                                            <Stack direction="horizontal" gap={2}>
                                                <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={port.isViewed} hasReport={port.hasReport} />
                                                <span className="field-label">{port?.portName} </span>
                                            </Stack>

                                        </Col>
                                        <Col>
                                            {
                                                port.hasReport === true ?
                                                    <div className="field-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button
                                                            className="btn-primary-outline primary-green py-0 px-2"
                                                            onClick={() => {
                                                                navigate(`/voyagedepartureandvesselloading/${port.consignmentDeparturePortID}`);
                                                            }}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </div>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                </Col>
                {
                    isMobile ? 
                    (
                        <Col xs={12} className="mt-3">
                            <div className="heading-text darkblue" style={{ fontSize: "16px" }}>
                            Discharging ports
                            </div>
                        </Col>
                    ) : null
                }
                <Col xs={isMobile ? 12 : (isHistorical ? 4 : 6)}>
                    <Container>
                        <Row className="bg-grey py-2" style={{ borderRadius: '4px 4px 0 0', border: '1px solid #DFE0E4' }}>
                            <Col>
                                <div className="field-label">Discharging in</div>
                            </Col>
                            <Col>
                                <div className="field-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>Discharge report</div>
                            </Col>
                        </Row>
                        {
                            dischargePorts && dischargePorts.map((port, i) => {
                                let hasBottomRoundedBorder = i === dischargePorts.length - 1;
                                return (
                                    <Row key={`discharging_port_${i}`} className="py-1" style={{ borderRadius: hasBottomRoundedBorder ? '0 0 4px 4px' : '0', borderLeft: '1px solid #DFE0E4', borderRight: '1px solid #DFE0E4', borderBottom: '1px solid #DFE0E4', alignItems: "center" }}>
                                        <Col>
                                            <Stack direction="horizontal" gap={2}>
                                                <ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox isViewed={port.isViewed} hasReport={port.hasReport} />
                                                <span className="field-label">{port?.portName} </span>
                                            </Stack>

                                        </Col>
                                        <Col>
                                            {
                                                port.hasReport === true ?
                                                    <div className="field-label" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button
                                                            className="btn-primary-outline primary-green py-0 px-2"
                                                            onClick={() => {
                                                                navigate(`/dischargereport/${consignmentID}/${port.consignmentDestinationPortID}`);
                                                            }}>
                                                            <FontAwesomeIcon icon={faEye} />
                                                        </Button>
                                                    </div>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                </Col>
                {
                    isHistorical ? (
                        <Col xs={4}>
                            <Container>
                                <Row className="bg-grey py-2" style={{ borderRadius: '4px 4px 0 0', border: '1px solid #DFE0E4' }}>
                                    <Col>
                                        <div className="field-label">End of Voyage reporting submitted</div>
                                    </Col>
                                </Row>
                                <Row className="py-1" style={{ borderRadius: '0 0 4px 4px', borderLeft: '1px solid #DFE0E4', borderRight: '1px solid #DFE0E4', borderBottom: '1px solid #DFE0E4', alignItems: "center" }}>
                                    <Col>
                                        <span className="field-label">{endOfVoyageSubmittedDate ? formatInTimeZone(parseISO(endOfVoyageSubmittedDate), "dd/MM/yyyy hh:mm a", "UTC") : ""} (UTC) </span>
                                    </Col>
                                    
                                </Row>
                            </Container>
                        </Col>
                    ) : null
                }
            </Row>
        </Container>
    )
}

export default ConsignmentLoadingAndDischargingPorts;