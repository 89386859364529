import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Stack } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import ReadWriteFormControl from "../ReadWriteElements/ReadWriteFormControl";
import ReadWriteFormCheck from "../ReadWriteElements/ReadWriteFormCheck";

const SheepHotSpotsDeck = ({ rec, triggerClearMissingAffectedDeck, onTriggerClearMissingAffectedDecks }) => {
    const { updateSheepHotSpotDeck, triggerShowInvalid, data } = useContext(DeckRecordOtherContext);
    const [isInvalidHotSpotDescription, setIsInvalidHotSpotDescription] = useState(false);
    const [isInvalidHotSpot, setIsInvalidHotSpot] = useState(false);

    const updateData = (isAffected, description) => {
        if (rec.DeckRecord.isEmpty === false) {
            let lIsValid = isValid(isAffected, description);
            updateSheepHotSpotDeck(rec.DeckRecord.consignmentDeckID, isAffected, description, lIsValid);
        }
    }

    const isValid = (isAffected, description) => {
        let lIsValid = true;
        if (isAffected === true && StringIsNullUndefinedOrEmpty(description) === true) {
            lIsValid = false;
        }
        return lIsValid;
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerShowInvalid >= 0) {
            setIsInvalidHotSpotDescription(!rec.isValidHotSpots);
            setIsInvalidHotSpot(!data.sheepHotSpotsValid);
        }
    }, [triggerShowInvalid]);

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerClearMissingAffectedDeck >= 0) {
            setIsInvalidHotSpot(false);
        }
    }, [triggerClearMissingAffectedDeck]);

    return (
        <Row key={`sheepHotSpotDeck_${rec.DeckRecord.consignmentDeckID}`} className="pb-3 pt-3 gray-border-top">
            <Col xs={6}>
                <Stack direction="horizontal" gap={4}>
                    <Form>
                        <Form.Group>
                            <ReadWriteFormCheck labelstacked={false} id={`hotSpotCheck_${rec.DeckRecord.consignmentDeckID}`} checked={rec.hasSheepHotSpots}
                                onChange={(e) => {
                                    onTriggerClearMissingAffectedDecks()
                                    setIsInvalidHotSpot(false);
                                    setIsInvalidHotSpotDescription(false);
                                    updateData(e.target.checked, rec.DeckRecord.hotSpotDescription);
                                }}
                                isInvalid={isInvalidHotSpot && !rec.DeckRecord.isEmpty}
                                label={rec.ConsignmentDeck.deckName}
                                disabled={rec.DeckRecord.isEmpty}
                            />
                        </Form.Group>
                        {
                            isInvalidHotSpot && !rec.DeckRecord.isEmpty ? (
                                <div className="validation-error-tooltip">
                                    <Tooltip
                                        anchorSelect={`#hotSpotCheck_${rec.DeckRecord.consignmentDeckID}`}
                                        place="top"
                                        variant="error"
                                        content="One ore more decks must be selected, or select no."
                                    />
                                </div>
                            ) : null
                        }
                    </Form>
                </Stack>
            </Col>
            <Col xs={6}>
                {
                    rec.hasSheepHotSpots && !rec.DeckRecord.isEmpty ?
                        <Form.Group>
                            <ReadWriteFormControl readonlyclasses='text-13-400 secondary-grey' id={`hotSpotDescription_${rec.DeckRecord.consignmentDeckID}`} value={rec.DeckRecord.hotSpotDescription}
                                onChange={(e) => {
                                    setIsInvalidHotSpotDescription(false);
                                    updateData(rec.hasSheepHotSpots, e.target.value);
                                }}
                                isInvalid={isInvalidHotSpotDescription && !rec.DeckRecord.isEmpty}
                                disabled={rec.DeckRecord.isEmpty}
                            />
                            {
                                isInvalidHotSpotDescription && !rec.DeckRecord.isEmpty ? (
                                    <div className="validation-error-tooltip">
                                        <Tooltip
                                            anchorSelect={`#hotSpotDescription_${rec.DeckRecord.consignmentDeckID}`}
                                            place="top"
                                            variant="error"
                                            content="A hot spot or pen is required."
                                        />
                                    </div>
                                ) : null
                            }
                        </Form.Group>
                        : null
                }
            </Col>
        </Row>
    )
};

export default SheepHotSpotsDeck;