export class ManagementPlan {
    ID;
    ManagementPlanSpeciesID;
    ManagementPlanName;
    IsActive;
    constructor({id, managementPlanSpeciesID, managementPlanName, isActive}) {
        this.ID = id;
        this.ManagementPlanSpeciesID = managementPlanSpeciesID;
        this.ManagementPlanName = managementPlanName;
        this.IsActive = isActive;
    }
}