import React from "react";
import { useState } from "react";
import { Row, Col, Container, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import AAV_Management from "../../components/LiveCorpAdminTools/AAV_Management";
import Accredited_Stockpersons_Management from "../../components/LiveCorpAdminTools/Accredited_Stockpersons_Management";
import LIVEXCollect_Management from "../../components/LiveCorpAdminTools/LIVEXCollect_Management";

const LiveCorp_User_Management = () => {
    const C_AAVs = "aavs"
    const C_AccreditedStockPersons = "stockpersons"
    const C_LIVEXCollect = "livexcollect"

    const [selectedTab, setSelectedTab] = useState(C_LIVEXCollect);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>User Management</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row className="mb-4">
                        <Col>
                            <ButtonGroup>
                                <ToggleButton className={selectedTab === C_LIVEXCollect ? "tab-bar-button-active" : "tab-bar-button"} type="radio" name="userType"
                                    value={C_LIVEXCollect} checked={selectedTab === C_LIVEXCollect}
                                    onClick={(e) => setSelectedTab(C_LIVEXCollect)}
                                >
                                    LIVEXCollect Users
                                </ToggleButton>
                                <ToggleButton className={selectedTab === C_AccreditedStockPersons ? "tab-bar-button-active" : "tab-bar-button"} type="radio" name="userType"
                                    value={C_AccreditedStockPersons} checked={selectedTab === C_AccreditedStockPersons}
                                    onClick={(e) => setSelectedTab(C_AccreditedStockPersons)}
                                >
                                    Accredited Stockpersons
                                </ToggleButton>
                                <ToggleButton className={selectedTab === C_AAVs ? "tab-bar-button-active" : "tab-bar-button"} type="radio" name="userType"
                                    value={C_AAVs} checked={selectedTab === C_AAVs}
                                    onClick={(e) => { setSelectedTab(C_AAVs); }}
                                >
                                    AAVs
                                </ToggleButton>
                            </ButtonGroup>
                        </Col>
                    </Row>
                    {
                        selectedTab === C_LIVEXCollect ? <LIVEXCollect_Management /> : null
                    }
                    {
                        selectedTab === C_AccreditedStockPersons ? <Accredited_Stockpersons_Management /> : null
                    }
                    {
                        selectedTab === C_AAVs ? <AAV_Management /> : null
                    }
                </Container>
            </Container>
        </LoggedInContainer >
    )
};

export default LiveCorp_User_Management;