import React, { useState, useEffect, useContext } from "react";
import { InputGroup } from "react-bootstrap";
import { UserContext } from "../..";
import { updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject } from "./ReadWriteProperties";

/*
    Can have reagular element properties, or any custom properties that are part of ReadWriteProperties
*/
const ReadWriteInputGroup = (props) => {
    const userCtx = useContext(UserContext);
    const [writeAccess, setWriteAccess] = useState(false);
    const [modifiedProps, setModifiedProps] = useState({});
    const [writeOnly, setWriteOnly] = useState(false);

    useEffect(() => {
        let lCanWrite = userCtx.user.writeAccess;
        setWriteAccess(lCanWrite);
    }, [userCtx.user]);

    useEffect(() => {
        setWriteOnly(props.writeonly === true);
        let lModifiedProps = updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject(props, writeAccess);
        setModifiedProps(lModifiedProps);
    }, [props, writeAccess]);

    return (
        writeOnly === false ?
            writeAccess === true ?
                <InputGroup {...modifiedProps} />
                :
                <div className={modifiedProps.className}>
                    {props.children[0]?.props.value}{props.children[1]?.props.children}
                </div>
            : null
    );
};

export default ReadWriteInputGroup;
