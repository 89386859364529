import '../css/jsontohtmltable.css';
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Container, Row, Col, Button, Table } from "react-bootstrap";
import { faCaretLeft, faCaretRight, faEye, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { StringIsNullUndefinedOrEmpty } from "../utils/StringUtils";
import { PrevDailyReportData } from "../classes/PrevDailyReportData";

// jsonData: json to convert into the html table

// filterIndex (int?): If there is a dropdown on the page that is used to specify which column is always visible, pass in the selected index here
// note the dropdown options must be in the same order as the data

// duplicateHeaders (bool): indicates if the headers should bne duplicated on both sides of the table
// initialSelectedColumnIndex (int): the index of the column to expand when first loaded
const JsonToHtmlTable = ({ jsonData, filter, duplicateHeaders, initialSelectedColumnIndex, showEdit, onSelectedColumnChanged }) => {
    const navigate = useNavigate();
    const [selectedColumnIndex, setSelectedColumnIndex] = useState(initialSelectedColumnIndex);
    const [headerRows, setHeaderRows] = useState([]);
    const [maxHeaderRowSpan, setMaxHeaderRowSpan] = useState(1);
    const [maxColumns, setMaxColumns] = useState(1);
    const [lastRowHeadingIndex, setLastRowHeadingIndex] = useState(1);
    const [lastFixedColumnIndex] = useState(1);
    const [jsonDataFiltered, setJsonDataFiltered] = useState( // sorted data
        { "Head": [], "Body": [] }
    );

    const [firstColWidth, setFirstColWidth] = useState(0);

    useEffect(() => {
        if (jsonData) {
            let lHeaderRows = [];
            let lMaxHeaderRowSpan = 1;
            let lMinColHeaderRows = 1;
            let lMaxColHeaderRows = 1;
            let lMaxColumns = 1;
            let lLastRowColumnHeaderIndex = null;

            jsonData["Head"].forEach((column) => {
                if (column.length > lMaxColHeaderRows) {
                    lMaxColHeaderRows = column.length;
                }
            })

            for (let i = 0; i < lMaxColHeaderRows; i++) {
                lHeaderRows.push(i);
            }

            lMaxHeaderRowSpan = lMaxColHeaderRows - lMinColHeaderRows + 1;

            let lFirstGroup = jsonData["Body"][0];
            if (lFirstGroup && lFirstGroup.groupRowsData[0].data[0]) {

                lMaxColumns = lFirstGroup.groupRowsData[0]?.data[0].length
                if ('groupRowHeading' in lFirstGroup) {
                    lLastRowColumnHeaderIndex = 0;
                }

                if (lFirstGroup.groupRowsData[0] && 'rowHeading' in lFirstGroup.groupRowsData[0]) {
                    lLastRowColumnHeaderIndex = 1;
                }
            }

            setLastRowHeadingIndex(lLastRowColumnHeaderIndex);
            setMaxColumns(lMaxColumns);
            setHeaderRows(lHeaderRows);
            setMaxHeaderRowSpan(lMaxHeaderRowSpan);
            setJsonDataFiltered(jsonData);
            if (initialSelectedColumnIndex && initialSelectedColumnIndex !== '' && !isNaN(Number(initialSelectedColumnIndex))) {
                setSelectedColumnIndex(initialSelectedColumnIndex);  
            } else {
                setSelectedColumnIndex(1 + lLastRowColumnHeaderIndex);
            }
            
        }
    }, [jsonData])

    // Update which column is displayed for non selected columns
    useEffect(() => {
        let updateData = {};
        Object.assign(updateData, jsonData);
        if (!StringIsNullUndefinedOrEmpty(filter)) { //if no filter index is passed through don't do anything
            let filterIndex = updateData["Head"][2] ? updateData["Head"][2][updateData["Head"][2]?.length - 1]?.indexOf(filter) : -1;
            if (filterIndex && filterIndex > -1) {
                updateData["Head"].forEach((column) => {
                    // Get the column header array
                    let last = column[maxHeaderRowSpan - 1];
                    // If it found column headers (i.e. not on deck or range column)
                    if (last) {
                        // Select the item at filter and remove it
                        let itemToBeShifted = last.splice(filterIndex, 1)[0];
                        // Insert item at start of the array (i.e. make it the first header in the column array)
                        last.unshift(itemToBeShifted)
                    }
                })

                updateData["Body"].forEach((group) => {
                    group.groupRowsData.forEach((row) => {
                        row.data.forEach((values) => {
                            values.unshift(values.splice(filterIndex, 1)[0])
                        })
                    })
                })
            }
            setJsonDataFiltered(updateData);
        }
    }, [filter]);

    useEffect(() => {
        onSelectedColumnChanged(selectedColumnIndex);
    }, [selectedColumnIndex])

    // Determine the width of the first td in the table. We need this so that the second td can be set to sticky and be offset by this value so that position sticky works without overlap
    const firstColWidthRef = useRef();
    useLayoutEffect(() => {
        setFirstColWidth(firstColWidthRef?.current?.clientWidth);
    });

    //#region Horizontal Scroll
    const step = 10;
    const scrollRef = useRef();
    const isScrollRef = useRef();

    const setMove = (state) => isScrollRef.current = state;

    const move = (isLeft) => {
        if (isScrollRef.current) {
            if (isLeft) {
                scrollRef.current.scrollLeft -= step;
            }
            else {
                scrollRef.current.scrollLeft += step;
            }
            requestAnimationFrame(function () {
                move(isLeft);
            });
        }
    };
    //#endregion

    const getTableHeadElement = (i, columnData, columnindex, rowindex, data, isRightSide) => {

        let borderClass = "";
        let hasBorderClass = false;
        if (columnindex <= lastRowHeadingIndex) {
            hasBorderClass = true;
        }

        if (columnindex === selectedColumnIndex && (columnData[rowindex].length === 1 || (columnData[rowindex].length === maxColumns && i === maxColumns - 1))) {
            hasBorderClass = true;
        }
        
        if (columnindex !== selectedColumnIndex) {
            hasBorderClass = true;
        }
                            
        if (hasBorderClass) {
            borderClass = isRightSide === false ? "tblPrevHistoryBorderRight" : "tblPrevHistoryBorderLeft"
        }

        let backgroundClass = columnindex % 2 === 1 || columnindex === 0 ? "jtht-column-background":"jtht-column-background-alternate";

        return (
            data !== null ?
                <th key={i} rowSpan={maxHeaderRowSpan - columnData.length + 1}
                    colSpan={columnindex === selectedColumnIndex ? maxColumns - columnData[rowindex].length + 1 : 1}
                    ref={rowindex === 0 ? (isRightSide === false && columnindex === 0) ? firstColWidthRef : (isRightSide === true && columnindex === lastRowHeadingIndex) ? firstColWidthRef : null : null}
                    style={{
                        // Only show the td if is the first item or if is part of the selected column
                        display: i === 0 || columnindex === selectedColumnIndex ? "" : "none",
                        // Row headers should be sticky and always remain visible even afetr scrolling
                        position: columnindex <= lastFixedColumnIndex ? "sticky" : "",
                        // If there are multiple row headers for a row, we need to offset the second header by the width of the first header
                        left: (isRightSide === false && columnindex <= lastFixedColumnIndex) ? columnindex === 0 ? "0px" : `${firstColWidth}px` : "",
                        right: isRightSide == true && columnindex <= lastFixedColumnIndex ? columnindex === 0 ? `${firstColWidth}px` : "0px" : "",
                        backgroundColor: i === 0 ? "white" : "unset",
                        maxWidth: columnindex <= lastFixedColumnIndex ? "100px" : "",
                        width: columnindex <= lastFixedColumnIndex ? "10px" : "",
                        minWidth: "10px",
                        // force row headers to be above the data when scrolling
                        zIndex: columnindex <= lastFixedColumnIndex ? "10" : ""
                    }}
                    className={`${columnindex} ${borderClass} ${backgroundClass} ${columnindex <= lastFixedColumnIndex ? "ps-2 pe-3": "text-center px-3"} jtht-row `}
                    // On click expand the selected column so that you can see all of the data
                    onClick={() => columnindex > lastFixedColumnIndex ? setSelectedColumnIndex(columnindex) : null}
                >
                    {
                        typeof data == 'object' && 'link' in data ?
                            data.link.consignmentDay.id ?
                                <>
                                    <Button
                                        variant="btn-outline-secondary"
                                        onClick={() => {
                                            navigate(data.link.href, {state: {consignmentDay: data.link.consignmentDay, selectedTab: data.link.selectedTab, selectedColumnIndex: selectedColumnIndex,allowEditing:showEdit }})
                                        }}
                                        className="btn-outline-secondary px-4"
                                    >
                                        <FontAwesomeIcon icon={showEdit? faPencil:faEye} />
                                    </Button>
                                </>
                                : null
                            : <span className='jtht-header-label'><strong>{data}</strong></span>
                    }
                </th> : null
        );
    }

    const getTableDataElementGroupRowHeading = (rowIndex, group, isRight) => {
        let borderClass = isRight === true ? "tblPrevHistoryBorderLeft" : "tblPrevHistoryBorderRight";
        return (
            rowIndex === 0 && 'groupRowHeading' in group ?
                <td rowSpan={group.groupRowsData.length} 
                    className={`${borderClass} jtht-header-label jtht-row`}
                    style={{
                        position: "sticky",
                        paddingLeft: group.isSubheading === true ? "50px" : "20px",
                        paddingRight: "20px",
                        left: isRight === false ? "0px" : "",
                        right: isRight === true ? "0px" : "",
                        maxWidth:  "10px",
                        width: "10px",
                        minWidth: "150px",
                        zIndex: "10",
                        backgroundColor:"white",
                    }}
                >
                    {group.groupRowHeading}
                </td> : null
        )
    }

    const getTableDataElementRowHeading = (heading, isRight) => {
        let borderClass = isRight === true ? "tblPrevHistoryBorderLeft" : "tblPrevHistoryBorderRight";
        return (
            heading !== null ?
                <td className={`${borderClass} jtht-header-label jtht-row ps-2 pe-4`}
                    style={{
                        position: "sticky",
                        left: isRight === false ? `${firstColWidth}px` : "",
                        right: isRight === true ? `${firstColWidth}px` : "",
                        width: heading?.length > 0 ? "10px" : "0px",
                        minWidth: "10px",
                        zIndex: "10",
                        backgroundColor: "white"
                    }}
                >{heading}</td> : null
        )
    }

    const getTableDataElement = (valueIndex, dataColumnIndex, group, value, rowIndex, rows) => {
        let lFirstMatchIndex = -1;

        let lIsArray = Array.isArray(value);
        let rowSpan = 1;

        let lCombine = false;

        let hasBorderClass = false;
        if (dataColumnIndex + ('groupRowHeading' in group ? 2 : 1) === selectedColumnIndex && valueIndex === maxColumns - 1) {
            hasBorderClass = true;
        }

        if ((dataColumnIndex + ('groupRowHeading' in group ? 2 : 1) !== selectedColumnIndex && valueIndex === 0)) {
            hasBorderClass = true;
        }

        let borderClass = hasBorderClass ? "tblPrevHistoryBorderRight" : "";
        let backgroundClass = dataColumnIndex % 2 === 1 ? "jtht-column-background":"jtht-column-background-alternate";

        if (jsonData["Formatting"]) {
            if (jsonData["Formatting"][valueIndex]?.combine === true) {
                rows.forEach((data, index) => {

                    let lMatchingData = false;
                    if (lIsArray) {
                        if (Array.isArray(data.data[dataColumnIndex][valueIndex])) {
                            lMatchingData = arraysMatch(value, data.data[dataColumnIndex][valueIndex]);
                        }
                    }
                    else {
                        lMatchingData = data.data[dataColumnIndex][valueIndex] === value;
                    }

                    if (lMatchingData) {
                        if (lFirstMatchIndex === -1) {
                            lFirstMatchIndex = index;
                        }
                        rowSpan = rowSpan + 1;
                    }
                })

                if (rowSpan > 1) {
                    rowSpan = rowSpan - 1; // subtract 1 to account for the iniitial rowspan of 1
                    lCombine = true;
                }
            }
        }

        if ((lCombine === true && rowIndex === lFirstMatchIndex) || lCombine === false) {

            return (
                <td key={valueIndex} rowSpan={rowSpan}
                    style={{
                        display: valueIndex == 0 ||
                            (dataColumnIndex + ('groupRowHeading' in group ? 2 : 1) == selectedColumnIndex) ?
                            "" : "none",
                        whiteSpace: "pre-line",
                        minWidth: 'groupRowHeading' in group ? "100px" : "220px"
                        
                    }}
                    className={`${borderClass} ${backgroundClass} text-center jtht-cell-text jtht-column jtht-row`}
                    onClick={() => {
                        setSelectedColumnIndex(dataColumnIndex + ('groupRowHeading' in group ? 2 : 1))
                    }}
                >
                    {
                        lIsArray ? value.map((value) => (
                            <div key={value}>
                                {
                                    // sometimes isnstead of showing additonal columns for the selected day we just want to chnage the data dispaleyd in the existing column
                                    // if the value is of type PrevDailyReportData and this is the selcted day show detail else show summary
                                    typeof value == 'object' && value instanceof PrevDailyReportData ?
                                        (dataColumnIndex + ('groupRowHeading' in group ? 2 : 1) == selectedColumnIndex) ?
                                            value.Detail : value.Summary
                                        :
                                        value


                                }
                            </div>
                        )) :
                            typeof value == 'object' && value instanceof PrevDailyReportData ?
                                (dataColumnIndex + ('groupRowHeading' in group ? 2 : 1) == selectedColumnIndex) ?
                                    value.Detail : value.Summary
                                :
                                value
                    }
                </td>
            )
        }
        else {
            return null
        }


    }

    const arraysMatch = (a, b) => {
        for (var i = 0; i < a.length; ++i) {
            if (a[i] !== b[i]) {
                return false;
            }
        }
        return true
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Button
                        variant="outline-dark"
                        style={{ width: "40px" }}
                        onMouseDown={() => { setMove(true); move(true); }}
                        onMouseUp={() => setMove(false)}
                    >
                        <FontAwesomeIcon icon={faCaretLeft} />
                    </Button>
                    <Button
                        variant="outline-dark"
                        style={{ width: "40px", float: "right" }}
                        onMouseDown={() => { setMove(true); move(false); }}
                        onMouseUp={() => setMove(false)}
                    >
                        <FontAwesomeIcon icon={faCaretRight} />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col style={{ marginTop: "20px" }}>
                    <div ref={scrollRef} className="tblPrevHistoryContainer" style={{ width: "100%", overflow: "hidden" }}>
                        <Table className="tblPrevHistory">
                            <thead>
                                {
                                    headerRows.map((option, rowindex) => (
                                        <tr key={rowindex}> 
                                            {
                                                jsonDataFiltered["Head"].map((columnData, columnindex) => (
                                                    columnData[rowindex]?.map((data, i) => (
                                                        getTableHeadElement(i, columnData, columnindex, rowindex, data, false)
                                                    ))
                                                ))
                                            }
                                            {
                                                // If duplicate headers we need to get all of the header then reverse the order
                                                duplicateHeaders === true ?
                                                    jsonDataFiltered["Head"].slice(0, lastRowHeadingIndex + 1).reverse().map((columnData, columnindex) => (
                                                        columnData[rowindex]?.map((data, i) => (
                                                            getTableHeadElement(i, columnData, columnindex, rowindex, data, true)
                                                        ))
                                                    )) : null
                                            }
                                        </tr>
                                    ))
                                }
                            </thead>
                            {
                                jsonDataFiltered["Body"]?.map((group, groupIndex) => (
                                    <tbody key={groupIndex}>
                                        {
                                            group.groupRowsData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {getTableDataElementGroupRowHeading(rowIndex, group, false)}
                                                    {getTableDataElementRowHeading(row.rowHeading, false)}
                                                    {
                                                        row.data.map((data, dataIndex) => (
                                                            data.map((value, valueIndex) => (
                                                                getTableDataElement(valueIndex, dataIndex, group, value, rowIndex, group.groupRowsData)
                                                            ))
                                                        ))
                                                    }
                                                    {
                                                        duplicateHeaders === true ?
                                                            <>
                                                                {getTableDataElementRowHeading(row.rowHeading, true)}
                                                                {getTableDataElementGroupRowHeading(rowIndex, group, true)}
                                                            </> : null
                                                    }
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                ))
                            }
                        </Table>
                    </div>
                </Col>
            </Row>
        </Container >
    )
}

export default JsonToHtmlTable;