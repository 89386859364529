export class DischargeReportSave {
    VesselArrivalDateUTC; // UTC
    VesselDischargeCommencedDateUTC; // UTC
    VesselDischargeCompletedDateUTC; // UTC
    LivestockDsicharged; // array

    constructor(vesselArrivalDate, vesselDischargeCommencedDate, vesselDischargeCompletedDate, livestockDischarged) {
        this.VesselArrivalDateUTC = vesselArrivalDate;
        this.DischargeCommencedDateUTC = vesselDischargeCommencedDate;
        this.DischargeCompletedDateUTC = vesselDischargeCompletedDate;
        this.LivestockDischarged = livestockDischarged;
    }
}

export class DischargeReportLivestockDischarged{
    ID;
    NumberDischarged;

    constructor(id, numberDischarged) {
        this.ID = id;
        this.NumberDischarged = numberDischarged;
    }
}