import React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Col, Form, Stack } from "react-bootstrap";
import CattlePantingDeck from "./CattlePantingDeck";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import ReadWriteFormRadio from "../ReadWriteElements/ReadWriteFormRadio";

const CattlePanting = ({ showCattlePantingQuestion }) => {
    const { data, updateCattleHasPanting } = useContext(DeckRecordOtherContext);
    const [triggerClearMissingAffectedDecks, setTriggerClearMissingAffectedDecks] = useState(-1);
    const [allCattleDecksEmpty, setAllCattleDecksEmpty] = useState(false);
    
    const onTriggerClearMissingAffectedDecks = () => {
        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1)
    }

    useEffect(() => {
        let lAllEmpty = [...data.cattleDecks].every(sd =>sd.DeckRecord.isEmpty === true);
        setAllCattleDecksEmpty(lAllEmpty);
    }, [data.cattleDecks]);

    return (
        showCattlePantingQuestion === true ?
            <div>
                <Stack gap={1} className="pb-1  field-label">
                    <div>A wet bulb temperature of 25°C or above has been reported today</div>
                    <div>Are any cattle panting at a score of 2 or above?</div>
                </Stack>
                <Form>
                    <ReadWriteFormRadio id='rdoCattlePanting_No' type={`radio`} label={`No`} name="rdoCattlePanting" checked={!data.hasCattlePanting}
                        onChange={() => {
                            setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                            updateCattleHasPanting(false);
                        }}
                        disabled={allCattleDecksEmpty}
                    />
                    <ReadWriteFormRadio id='rdoCattlePanting_Yes' type={`radio`} label={`Yes`} name="rdoCattlePanting" checked={data.hasCattlePanting}
                        onChange={() => {
                            setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                            updateCattleHasPanting(true);
                        }}
                        disabled={allCattleDecksEmpty}
                    />
                </Form>
                {
                    data.hasCattlePanting && !allCattleDecksEmpty ?
                        <div>
                            <Row className="pt-2">
                                <Col xs={3} className="dr-column-heading">Deck Affected?</Col>
                                <Col xs={9} className="dr-column-heading">
                                    <Row>
                                        <Col xs={12}>
                                            % of animals per deck
                                        </Col>
                                        <Col xs={4}>
                                            Score 4-4.5
                                        </Col>
                                        <Col xs={4}>
                                            Score 3-3.5
                                        </Col>
                                        <Col xs={4}>
                                            Score 2-2.5
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {
                                data.cattleDecks.map((rec, index) => (
                                    <CattlePantingDeck key={`cattlePanting${index}`} rec={rec} triggerClearMissingAffectedDecks={triggerClearMissingAffectedDecks} onTriggerClearMissingAffectedDecks={onTriggerClearMissingAffectedDecks} />
                                ))
                            }
                        </div>
                        : null
                }
            </div>
            :
            <div>No wet bulb temperatures of 25°C or above has been reported today.</div>
    )
};

export default CattlePanting;