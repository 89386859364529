import React, { useEffect } from "react";
import '../../css/draganddrop.css';
import { useState } from "react";
import { faCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { format } from "date-fns";
import { read, utils } from "xlsx";

const fileTypes = ["XLSX"];

const dataColumns = {
    tagType: "A",
    tagNumber: "B",
    noHead: "C",
    species: "D",
    class: "E",
    isYoung: "F",
    weight: "G",
    managementPlan1: "H",
    managementPlan2: "I",
    managementPlan3: "J",
    managementPlan4: "K",
    exporterLine: "L",
    livestockCategory: "M",
    age: "N",
    reLAENumber: "O",
    reName: "P",
    PIC: "Q"
}

const validTagTypes = ["NLIS", "VID", "POO"];
const isYoungDescriptors = ["<250kg / Breeding & ≤6 incisors / Pregant Cows", "≤4 incisor teeth"];

const LivestockDragNDropUploader = ({onImported, onReset, species, classes, managementPlans, onRaiseError, hasData}) => {
    const [file, setFile] = useState(null);
 
    useEffect(() => {
        if (!file) return;
        let reader = new FileReader();
        reader.onload = async (e) => {
            let errorRaised = false;
            const data = e.target.result;
            const wb = read(data);

            const targetSheetName = "Template";
            let targetSheet = wb.Sheets[targetSheetName];
            let livestockData = parseImportDataFromTargetSheet(targetSheet);
            if (livestockData.errorResult?.error) {
                onRaiseError(livestockData.errorResult.errorMsg);
                errorRaised = true;
            }


            if (!errorRaised) {
                let totalData = livestockData.data;
                onImported(totalData);
            }
        }
        reader.readAsArrayBuffer(file);

    }, [file])

    const parseImportDataFromTargetSheet = (targetSheet) => {
        const cellLimits = targetSheet["!ref"].split(":");
        const startRow = 3;
        const lastRow = Number(cellLimits[1].substring(1, cellLimits[1].length));
        let importedData = [];
        let errorResult = undefined;
        
        for (let i = startRow; i <= lastRow; i++) {
            let importedDataObject = {
                tagType: targetSheet[(dataColumns.tagType + i).toString()]?.v.trim(),
                tagNumber: targetSheet[(dataColumns.tagNumber + i).toString()]?.v.toString().trim(),
                noHead: Number(targetSheet[(dataColumns.noHead + i).toString()]?.v.toString().trim()),
                species: targetSheet[(dataColumns.species + i).toString()]?.v.trim(),
                class: targetSheet[(dataColumns.class + i).toString()]?.v.trim(),
                isYoung: targetSheet[(dataColumns.isYoung + i).toString()]?.v.trim() && isYoungDescriptors.map(iyd => iyd.toLowerCase()).includes(targetSheet[(dataColumns.isYoung + i).toString()]?.v.trim().toLowerCase()),
                weight: Number(targetSheet[(dataColumns.weight + i).toString()]?.v.toString().trim()).toFixed(0),
                managementPlan1: targetSheet[(dataColumns.managementPlan1 + i).toString()]?.v.trim(),
                managementPlan2: targetSheet[(dataColumns.managementPlan2 + i).toString()]?.v.trim(),
                managementPlan3: targetSheet[(dataColumns.managementPlan3 + i).toString()]?.v.trim(),
                managementPlan4: targetSheet[(dataColumns.managementPlan4 + i).toString()]?.v.trim(),
                exporterLine: targetSheet[(dataColumns.exporterLine + i).toString()]?.v.trim(),
                livestockCategory: targetSheet[(dataColumns.livestockCategory + i).toString()]?.v.trim(),
                age: Number(targetSheet[(dataColumns.age + i).toString()]?.v.toString().trim()),
                reLAENumber: targetSheet[(dataColumns.reLAENumber + i).toString()]?.v.toString().trim(),
                reName: targetSheet[(dataColumns.reName + i).toString()]?.v.trim(),
                PIC: targetSheet[(dataColumns.PIC + i).toString()]?.v.toString().trim()
            }

            // Ignore empty rows
            if (!allColumnsEmpty(importedDataObject)) {
                errorResult = validateImportedDataObject(importedDataObject, i);
                if (errorResult.error) {
                    break;
                }
                importedData.push(importedDataObject)
            }
        }
        return {data: importedData, errorResult: errorResult}
    }

    // Only returns true if all of the columns are undefined.
    // Returns false if any column has data (i.e. is not undefined)
    const allColumnsEmpty = (row) => {
        let allColumnsEmpty = true;
        Object.keys(row).forEach(k => {
                if (row[k] !== undefined && !isNaN(Number(row[k]))) {
                    allColumnsEmpty = false;
                }
            }
        )
        return allColumnsEmpty;
    }

    const buildError = (msg, rowNumber) => {
        return {
            error: true,
            errorMsg: `Import error at row ${rowNumber}. ${msg}`
        }
    } 

    const validateImportedDataObject = (data, rowNumber) => {
        if (data.noHead.length === 0 || isNaN(Number(data.noHead))) {
            return buildError("One or more rows have an invalid number of head supplied.", rowNumber);
        }

        if (data.tagNumber && data.tagNumber.length > 0 && Number(data.noHead) > 1) {
            return buildError("One or more rows have tag numbers but also list more than one number of head.  When a row has a tag number, then number of head must be 1.", rowNumber)
        }

        if (data.tagNumber && data.tagNumber.length > 0 && (!data.tagType || !validTagTypes.includes(data.tagType.toUpperCase()))) {
            return buildError("One or more rows have tag numbers but do not specify a valid tag type.", rowNumber);
        }

        if (data.tagType && validTagTypes.includes(data.tagType.toUpperCase()) && (!data.tagNumber || data.tagNumber.length === 0)) {
            return buildError("One or more rows have a tag type specified but do not provide a valid tag number.", rowNumber);
        }

        let validSpecies = species.find(sp => sp.speciesName.toLowerCase() === data.species.toLowerCase());
        if (data.species.length === 0 || !validSpecies) {
            return buildError("One or more rows has an invalid species name.", rowNumber);
        }

        if (data.class.length === 0 || !classes.find(c => c.className.toLowerCase() === data.class.toLowerCase())) {
            return buildError("One or more rows has an invalid livestock class name.", rowNumber);
        }

        if (data.weight.length === 0 || isNaN(Number(data.weight))) {
            return buildError("One or more rows has an invalid weight supplied.", rowNumber);
        }

        if (data.managementPlan1 && data.managementPlan1.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) &&  mp.managementPlanName.trim().toLowerCase() === data.managementPlan1.trim().toLowerCase())) {
            return buildError("Invalid management plan 1.", rowNumber);
        }

        if (data.managementPlan2 && data.managementPlan2.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan2.trim().toLowerCase())) {
            return buildError("Invalid management plan 2.", rowNumber);
        }

        if (data.managementPlan3 && data.managementPlan3.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan3.trim().toLowerCase())) {
            return buildError("Invalid management plan 3.", rowNumber);
        }

        if (data.managementPlan4 && data.managementPlan4.length > 0 && !managementPlans.find(mp => Number(mp.managementPlanSpeciesID) === Number(validSpecies.id) && mp.managementPlanName.trim().toLowerCase() === data.managementPlan4.trim().toLowerCase())) {
            return buildError("Invalid management plan 4.", rowNumber);
        }

        return {error: false, errorMsg: ""};
    }

    const handleChange = (file) => {
        setFile(file);
    }
    
    const clearFile = () => {
        onReset();
        setFile(null);
    }

    return (
        <Container >
            
            <Container className="d-flex justify-content-center align-items-center h-100">
                <FileUploader
                    handleChange={handleChange}
                    name="file"
                    types={fileTypes}
                    classes="drop_zone"
                >
                    <Container className="px-3 text-center py-5" style={{minHeight: "120px", minWidth: "100%"}} >
                        { !file ? (
                            <><FontAwesomeIcon icon={faCirclePlus} size="lg" /><span>&nbsp;Drag and drop your completed template here, or &nbsp;</span><Button className="btn-primary" >SELECT FILE</Button></>
                        ) :
                        (
                            <>
                                <Row className="text-center py-3">
                                    <div><b>Filename:</b> {file.name}</div>
                                    <div><b>Last Modified:</b> {format(new Date(file.lastModified), "dd/MM/yyyy HH:mm")}</div>
                                    <div><b>Size:</b> {Math.round(file.size / 1000)}kb</div>
                                    
                                </Row>
                            </>
                        )
                        }
                    </Container>
                </FileUploader>
            </Container>
            { 
                file || hasData === true ? (
                    <Row>
                        <Col xs={12} className="text-center mt-2"><Button variant="secondary" onClick={() => clearFile()}>Reset Imported Data</Button></Col>
                    </Row>
                ) : null
            }
        </Container>
    )
}

export default LivestockDragNDropUploader;