export class PlannedRegisteredEstablishmentConsignmentWithID {
    LAENumberID;
    LNCNumber;
    ExporterID;
    Livestock;
    ReportCreatedDate;
    ID;
    constructor() {
        this.Livestock = [];
    }

    IsValid = () => {
        if (this.ID === undefined) return false;
        if (this.LAENumberID === undefined) return false;
        if (this.LNCNumber === undefined || this.LNCNumber.length !== 6) return false;
        if (this.ExporterID === undefined) return false;
        if (this.Livestock === undefined || this.Livestock.length === 0) return false;
        if (this.ReportCreatedDate === undefined || isNaN(new Date(this.ReportCreatedDate))) return false;
        return true;
    }
}