import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { parseNumericInput, PercentageValidationRules } from "../../utils/InputValidator";
import { Tooltip } from "react-tooltip";
import ReadWriteInputGroup from "../ReadWriteElements/ReadWriteInputGroup";

const GeneralDemeanour = ({ deckRecord, onUpdate, hasPadding, onTriggerValidation }) => {
    const perCentageValidationRules = new PercentageValidationRules(false, false);
    const [isInvalidPercentUnhappy, setIsInvalidPercentUnhappy] = useState(false);

    const updateValues = (percentUnhappy) => {
        if (deckRecord.DeckRecord.isEmpty === false) { // you should not be able to update an empty deck
            if (percentUnhappy !== "") {
                let lPercentUnhappy = Number(percentUnhappy);
                let lPercentContent = 100 - lPercentUnhappy;
                onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id, lPercentUnhappy, lPercentContent, true);
            }
            else {
                onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id, percentUnhappy, null, false);
            }
        }
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (onTriggerValidation >= 0) {
            setIsInvalidPercentUnhappy(!deckRecord.DeckRecord.isValid);
        }
    }, [onTriggerValidation]);

    return (
        <Row className={hasPadding === true ? "pb-2" : ""}>
            {hasPadding}
            <Col xs={3} className="dr-text">
                {deckRecord.Species.speciesName}
            </Col>
            <Col xs={9}>
                <Row>
                    <Col xs={7}>
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='dr-text'>
                                <Form.Control
                                    id={`percentUnhappy_${deckRecord.DeckRecord.id}`}
                                    value={deckRecord.DeckRecord.isEmpty ? "-" : deckRecord.DeckRecord.generalDemeanourUnhappyPercent}
                                    onBlur={(e) =>
                                        e.target.value === "" ?
                                            updateValues(Number(e.target.value))
                                            : null
                                    }
                                    onChange={(e) => {
                                        setIsInvalidPercentUnhappy(false);
                                        parseNumericInput(e, perCentageValidationRules);
                                        updateValues(e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={isInvalidPercentUnhappy && !deckRecord.DeckRecord.isEmpty}
                                    disabled={deckRecord.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${isInvalidPercentUnhappy ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    isInvalidPercentUnhappy && !deckRecord.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#percentUnhappy_${deckRecord.DeckRecord.id}`}
                                                place="top"
                                                variant="error"
                                                content="A percentage unhappy is required."
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={5} className="dr-text align-self-center text-end">
                        {
                            deckRecord.DeckRecord.isEmpty === true ? "-%" :
                                deckRecord.DeckRecord.generalDemeanourContentPercent !== null ?
                                    <div>{deckRecord.DeckRecord.generalDemeanourContentPercent}%</div>
                                    : null
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default GeneralDemeanour;