import { RegisteredEstablishmentConsignmentAnimalModel } from "./RegisteredEstablishmentConsignmentAnimal";
import { RegisteredEstablishmentConsignmentManagementPlan } from "./RegisteredEstablishmentConsignmentManagementPlanModel";
import { RegisteredEstablishmentConsignmentMortality } from "./RegisteredEstablishmentConsignmentMortality";
import { RegisteredEstablishmentConsignmentRejection } from "./RegisteredEstablishmentConsignmentRejection";
import { SimpleRegisteredEstablishmentExporterInfo } from "./SimpleRegisteredEstablishmentExporterInfo";
import { SimpleRegisteredEstablishmentInfo } from "./SimpleRegisteredEstablishmentInfo";

export class RegisteredEstablishmentConsignment {
    ID;
    RegisteredEstablishmentLAENumberID;
    LNCNumber;
    ExporterID;
    ReportCreatedDate;
    IsSubmitted;
    DateSubmitted;
    LastAnimalDepartureDate;
    RegisteredEstablishments;
    Exporter;
    ConsignmentRejections;
    ConsignmentMortalities;
    ConsignmentManagementPlans;
    ConsignmentAnimals;

    constructor({id, registeredEstablishmentLAENumberID, lncNumber, exporterID, reportCreatedDate, isSubmitted, dateSubmitted, lastAnimalDepartureDate, registeredEstablishments, exporter, consignmentRejections, consignmentMortalities, consignmentManagementPlans, consignmentAnimals}) {
        this.ID = id;
        this.RegisteredEstablishmentLAENumberID = registeredEstablishmentLAENumberID;
        this.LNCNumber = lncNumber;
        this.ExporterID = exporterID;
        this.ReportCreatedDate = reportCreatedDate;
        this.IsSubmitted = isSubmitted;
        this.DateSubmitted = dateSubmitted;
        this.LastAnimalDepartureDate = lastAnimalDepartureDate;
        this.RegisteredEstablishments = registeredEstablishments?.map(re => {
            return new SimpleRegisteredEstablishmentInfo(re);
        });
        this.Exporter = new SimpleRegisteredEstablishmentExporterInfo(exporter);
        this.ConsignmentRejections = consignmentRejections && consignmentRejections.length > 0 ? consignmentRejections.map(cr => new RegisteredEstablishmentConsignmentRejection(cr)) : [];
        this.ConsignmentMortalities = consignmentMortalities && consignmentMortalities.length > 0 ? consignmentMortalities.map(cm => new RegisteredEstablishmentConsignmentMortality(cm)) : [];
        this.ConsignmentManagementPlans = consignmentManagementPlans && consignmentManagementPlans.length > 0 ? consignmentManagementPlans.map(mp => new RegisteredEstablishmentConsignmentManagementPlan(mp)) : [];
        this.ConsignmentAnimals = consignmentAnimals && consignmentAnimals.length > 0 ? consignmentAnimals.map(ca => new RegisteredEstablishmentConsignmentAnimalModel(ca)) : [];
    }
    
}