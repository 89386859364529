import React from "react";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";


const SubmissionStatusMobile = ({status}) => {
    return (
        <>
            { 
            status.submitted ? 
                (
                    <Stack direction="horizontal" gap={10}>
                        <button style={{ width:'100%', color:'var(--primary-colour)', padding:'10px', backgroundColor: '#44CB6F26', border: '0px', borderRadius: '5px'  }}>
                            <FontAwesomeIcon icon={faCheck} style={{fontSize: "16px", marginRight: '10px'}} />
                            Submitted
                        </button>
                    </Stack>
                )
                :
                
                (
                    <Stack direction="horizontal" gap={1}>
                        <button style={{ width:'100%', color: '#de5656', padding:'10px', backgroundColor: '#f7e4e4', border: '0px', borderRadius: '5px'  }}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: "16px", marginRight: '10px'}} />
                            Not submitted
                        </button>
                    </Stack>
                )
            }
        </>
    )
};

export default SubmissionStatusMobile;