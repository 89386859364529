export const determineDailyReportingStatus = (localVesselHours, hoursSinceLastReport) => {
    let isLastReportFromBefore12AM = (Number(hoursSinceLastReport) -  Number(localVesselHours)) > 0;
    if (Number(localVesselHours) !== NaN && Number(hoursSinceLastReport) !== NaN && Number(localVesselHours) > 11 && isLastReportFromBefore12AM) {
        return true;
    } else {
        return false;
    }
}

export const getDifferenceInWholeHours = (dateTime2, dateTime1) => {
    let differenceInMilliseconds =(dateTime2.getTime() - dateTime1.getTime());
    let differenceInHours = differenceInMilliseconds / 1000 / (60 * 60);
    return Math.abs(Math.round(differenceInHours));
}