// USE THIS FILE TO MEDIATE ANY SESSION STORAGE
// Please note, session storage can not exceed 5 MiB (1048kb) so avoid
// storing large files in session and be sure to explicitly clear 
// any data stored in session when it is no longer required.



// Add session keys here.  If they aren't in here, they will not
// be removed when clearSession is called.
const SessionKeys = Object.freeze({
    LOGGED_IN_USER: "logged-in-user",
    CONSIGNMENT_BEING_CREATED: "consignment-being-created"
});

//#region Session Management
export const clearSession = () => {
    Object.keys(SessionKeys).forEach(k => {
        sessionStorage.removeItem(SessionKeys[k]);
    });
    sessionStorage.clear();
}
//#endregion

//#region User Session Storage

// Only call this when a user initially logs in.
// To update the currently logged in user, use updateLoggedInUser
export const setSessionLoggedInUser = (loggedInUser, initialLogin = true) => {
    if (initialLogin) clearSession();
    sessionStorage.setItem(SessionKeys.LOGGED_IN_USER, JSON.stringify(loggedInUser));
}

export const getSessionLoggedInUser = () => {
    let loggedInUser = sessionStorage.getItem(SessionKeys.LOGGED_IN_USER);
    if (loggedInUser) {
        return JSON.parse(loggedInUser);
    }
    return undefined;
}

export const updateLoggedInUser = (updateObject) => {
    if (updateObject === undefined || Object.keys(updateObject).length === 0) return; // Require a valid object
    let loggedInUser = getSessionLoggedInUser(); 
    if (loggedInUser === undefined) return; // Don't try to update a null/undefined user object

    if (loggedInUser) {
        Object.keys(updateObject).forEach(k => {
            loggedInUser[k] = updateObject[k];
        });
    }
    setSessionLoggedInUser(loggedInUser, false);
}

export const clearLoggedInUser = () => {
    sessionStorage.removeItem(SessionKeys.LOGGED_IN_USER);
    clearSession();
}
//#endregion

//#region Consignment Creation Session Storage
export const getSessionConsignmentBeingCreated = () => {
    let consignmentBeingCreated = sessionStorage.getItem(SessionKeys.CONSIGNMENT_BEING_CREATED);
    if (consignmentBeingCreated) {
        return JSON.parse(consignmentBeingCreated);
    }
    return consignmentBeingCreated;
}

export const setSessionConsignmentBeingCreated = (consignmentBeingCreated) => {
    sessionStorage.setItem(SessionKeys.CONSIGNMENT_BEING_CREATED, JSON.stringify(consignmentBeingCreated));
}

export const addToOrUpdateSessionConsignmentBeingCreated = (additionsOrUpdates) => {
    let consignmentBeingCreated = getSessionConsignmentBeingCreated();
    if (consignmentBeingCreated) {
        Object.keys(additionsOrUpdates).forEach(key => {
            consignmentBeingCreated[key] = additionsOrUpdates[key];
        });
        setSessionConsignmentBeingCreated(consignmentBeingCreated);
    } else {
        setSessionConsignmentBeingCreated(additionsOrUpdates);
    }
}

export const clearSessionConsignmentBeingCreated = () => {
    sessionStorage.removeItem(SessionKeys.CONSIGNMENT_BEING_CREATED);
}
//#endregion