import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { parseNumericInput, PercentageValidationRules } from "../../utils/InputValidator";
import { Tooltip } from "react-tooltip";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import ReadWriteInputGroup from "../ReadWriteElements/ReadWriteInputGroup";
import ReadWriteFormSelect from "../ReadWriteElements/ReadWriteFormSelect";

const ManurePadScores = ({ deckRecord, onUpdate, hasPadding, onTriggerValidation, padDepthOptions }) => {

    const perCentageValidationRules = new PercentageValidationRules(false, false);
    const [isInvalidPadAbsentPercent, setIsInvalidPadAbsentPercent] = useState(false);
    const [isInvalidPadDryPercent, setIsInvalidPadDryPercent] = useState(false);
    const [isInvalidCombinedPercent, setIsInvalidCombinedPercent] = useState(false);
    const [isInvalidPadDepth, setIsInvalidPadDepth] = useState(false);

    const [padAbsentError, setPadAbsentError] = useState([]);
    const [padDryError, setPadDryError] = useState([]);

    const updatePercentPadAbsent = (percentAbsent) => {
        if (deckRecord.DeckRecord.isEmpty === false) {
            let lPercentDry = deckRecord.DeckRecord.manurePadDryPercent;
            let lAvgPadDepthID = deckRecord.DeckRecord.manurePadAverageDepthID;
            let lPercentSloppy = null;

            let lIsValid = false;

            if (isNotNullOrEmpty(lPercentDry) && isNotNullOrEmpty(percentAbsent)) {
                lPercentSloppy = 100 - Number(percentAbsent) - Number(lPercentDry);
                lIsValid = isValid(percentAbsent, lPercentDry, lPercentSloppy, lAvgPadDepthID);
            }

            if (lPercentSloppy <= 0) {
                lAvgPadDepthID = "";
            }

            onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id,
                percentAbsent, lPercentDry, lPercentSloppy, lAvgPadDepthID, lIsValid);
        }
    }

    const updatePercentPadDry = (percentDry) => {
        if (deckRecord.DeckRecord.isEmpty === false) {
            let lPercentAbsent = deckRecord.DeckRecord.manurePadAbsentPercent;
            let lAvgPadDepthID = deckRecord.DeckRecord.manurePadAverageDepthID;
            let lPercentSloppy = null;

            let lIsValid = false;

            if (isNotNullOrEmpty(percentDry) && isNotNullOrEmpty(lPercentAbsent)) {
                lPercentSloppy = 100 - Number(lPercentAbsent) - Number(percentDry);
                lIsValid = isValid(lPercentAbsent, percentDry, lPercentSloppy, lAvgPadDepthID);
            }

            if (lPercentSloppy <= 0) {
                lAvgPadDepthID = "";
            }

            onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id,
                lPercentAbsent, percentDry, lPercentSloppy, lAvgPadDepthID, lIsValid);
        }
    }

    const updateAvgPadDepth = (avgPadDepthID) => {
        if (deckRecord.DeckRecord.isEmpty === false) {
            let lPercentAbsent = deckRecord.DeckRecord.manurePadAbsentPercent;
            let lPercentDry = deckRecord.DeckRecord.manurePadDryPercent;
            let lPercentSloppy = deckRecord.DeckRecord.manurePadSloppyPercent;

            let lIsValid = isValid(lPercentAbsent, lPercentDry, lPercentSloppy, avgPadDepthID);

            onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id,
                lPercentAbsent, lPercentDry, lPercentSloppy, avgPadDepthID, lIsValid);
        }
    }

    const isValid = (percentAbsent, percentDry, percentSloppy, avgPadDepthID) => {
        let lPadDepthValid = isPadDepthValid(percentSloppy, avgPadDepthID);
        let lIsValidCombinePercentage = isValidCombinedPercentage(percentAbsent, percentDry);
        let lIsPadAbsentValid = isNotNullOrEmpty(percentAbsent);
        let lIsPadDryValid = isNotNullOrEmpty(percentDry);

        return lPadDepthValid && lIsValidCombinePercentage && lIsPadAbsentValid && lIsPadDryValid;
    }

    const isPadDepthValid = (percentSloppy, padDepthID) => {
        if (percentSloppy > 0 && padDepthID === "") {
            return false;
        }
        else {
            return true;
        }
    }

    const isValidCombinedPercentage = (valueOne, valueTwo) => {
        if (isNotNullOrEmpty(valueOne) && isNotNullOrEmpty(valueTwo)) {
            return Number(valueOne) + Number(valueTwo) <= 100;
        }
        else {
            // return true here becuase they will be marked as invalid as they are empty
            return true;
        }
    }

    const isNotNullOrEmpty = (value) => {
        return !StringIsNullUndefinedOrEmpty(value);
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (onTriggerValidation >= 0) {
            setIsInvalidPadAbsentPercent(false);
            setIsInvalidPadDryPercent(false);
            setIsInvalidCombinedPercent(false);
            setIsInvalidPadDepth(false);
            setPadAbsentError([]);
            setPadDryError([]);

            if (deckRecord.DeckRecord.isValid === false) {

                let lPadAbsentPercent = deckRecord.DeckRecord.manurePadAbsentPercent;
                let lPadDryPercent = deckRecord.DeckRecord.manurePadDryPercent;
                let lPadSloppyPercent = deckRecord.DeckRecord.manurePadSloppyPercent;
                let lPadDepthID = deckRecord.DeckRecord.manurePadAverageDepthID;

                let lMissingPadAbsent = !isNotNullOrEmpty(lPadAbsentPercent);
                let lMissingPadDry = !isNotNullOrEmpty(lPadDryPercent);

                let lPadAbsentError = [];
                let lPadDryError = [];

                if (lMissingPadAbsent) {
                    lPadAbsentError.push("A pad absent percentage is required.");
                }

                if (lMissingPadAbsent) {
                    lPadDryError.push("A pad presnt and dry, firm, tacky percentage is required.");
                }

                setIsInvalidPadAbsentPercent(lMissingPadAbsent);
                setIsInvalidPadDryPercent(lMissingPadDry);

                let lIsValidCombinedPercentage = isValidCombinedPercentage(lPadAbsentPercent, lPadDryPercent);
                setIsInvalidCombinedPercent(!lIsValidCombinedPercentage);

                if (!lIsValidCombinedPercentage) {
                    lPadAbsentError.push("The combined percentage can not be greater than 100.");
                    lPadDryError.push("The combined percentage can not be greater than 100.");
                }

                setPadAbsentError(lPadAbsentError);
                setPadDryError(lPadDryError);

                if (lPadSloppyPercent > 0 && lPadDepthID === "") {
                    setIsInvalidPadDepth(true);
                }
            }
        }
    }, [onTriggerValidation]);

    return (
        <Row className={hasPadding === true ? "pb-2" : ""}>
            {hasPadding}
            <Col xs={3} className="dr-text">
                {deckRecord.Species.speciesName}
            </Col>
            <Col xs={9}>
                <Row>
                    <Col xs={3}>
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='dr-text'>
                                <Form.Control
                                    id={`padAbsent${deckRecord.DeckRecord.id}`}
                                    value={deckRecord.DeckRecord.isEmpty === true ? "-" : deckRecord.DeckRecord.manurePadAbsentPercent}
                                    onBlur={(e) =>
                                        e.target.value === "" ?
                                            updatePercentPadAbsent(Number(e.target.value))
                                            : null
                                    }
                                    onChange={(e) => {
                                        setIsInvalidPadAbsentPercent(false);
                                        setIsInvalidCombinedPercent(false);
                                        parseNumericInput(e, perCentageValidationRules);
                                        updatePercentPadAbsent(e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isInvalidPadAbsentPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty}
                                    disabled={deckRecord.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isInvalidPadAbsentPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isInvalidPadAbsentPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#padAbsent${deckRecord.DeckRecord.id}`}
                                                place="top"
                                                variant="error"
                                                content={
                                                    padAbsentError.map((err, index) => (
                                                        <div key={`absentErr_${index}`}>{err}</div>
                                                    ))
                                                }
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='dr-text'>
                                <Form.Control
                                    id={`padDry${deckRecord.DeckRecord.id}`}
                                    value={deckRecord.DeckRecord.isEmpty === true ? "-" : deckRecord.DeckRecord.manurePadDryPercent}
                                    onBlur={(e) =>
                                        e.target.value === "" ?
                                            updatePercentPadDry(Number(e.target.value))
                                            : null
                                    }
                                    onChange={(e) => {
                                        setIsInvalidPadDryPercent(false);
                                        setIsInvalidCombinedPercent(false);
                                        parseNumericInput(e, perCentageValidationRules);
                                        updatePercentPadDry(e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isInvalidPadDryPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty}
                                    disabled={deckRecord.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isInvalidPadDryPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isInvalidPadDryPercent || isInvalidCombinedPercent) && !deckRecord.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#padDry${deckRecord.DeckRecord.id}`}
                                                place="top"
                                                variant="error"
                                                content={
                                                    padDryError.map((err, index) => (
                                                        <div key={`dryErr_${index}`}>{err}</div>
                                                    ))
                                                }
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={3} className="dr-text text-end">
                        {
                            deckRecord.DeckRecord.isEmpty === true ? "-%" :
                                deckRecord.DeckRecord.manurePadSloppyPercent !== null ?
                                    <div>{deckRecord.DeckRecord.manurePadSloppyPercent}%</div>
                                    : null
                        }
                    </Col>
                    <Col xs={3} className="dr-text">
                        {
                            deckRecord.DeckRecord.isEmpty === true ? "-" :
                                deckRecord.DeckRecord.manurePadSloppyPercent > 0 ?
                                    <Form.Group id={`padDepth${deckRecord.DeckRecord.id}`}>
                                        <ReadWriteFormSelect
                                            value={deckRecord.DeckRecord.manurePadAverageDepthID}
                                            isInvalid={isInvalidPadDepth}
                                            onChange={(e) => {
                                                updateAvgPadDepth(e.target.value);
                                                setIsInvalidPadDepth(false);
                                            }
                                            }>
                                            {
                                                padDepthOptions.map((option, index) => (
                                                    <option key={index} value={option.id}>{option.manurePadDepthType}</option>
                                                ))
                                            }

                                        </ReadWriteFormSelect>
                                        {
                                            isInvalidPadDepth ?
                                                <Tooltip
                                                    anchorSelect={`#padDepth${deckRecord.DeckRecord.id}`}
                                                    place="top"
                                                    variant="error"
                                                    content="A pad avg depth selection is required."
                                                    style={{ zIndex: 100, borderRadius: '8px', opacity: 1 }}
                                                />
                                                : null

                                        }
                                    </Form.Group>
                                    : null
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default ManurePadScores;