export class ConsignmentDeparturePort {
      ID;
      ConsignmentID;
      PortID;
      Order;
      FeedPlannedID;
      LoadingCommencedDateUTC;
      LoadingCompletedDateUTC;
      VesselDepartedDateUTC;
      FeedLoadedID;
      LoadingAnimals;
      IsLoadingAndDepartureReviewed;
      ConsignmentDayID

      constructor({id, consignmentID, portID, order, feedPlannedID, loadingCommencedDateUTC, loadingCompletedDateUTC, vesselDepartedDateUTC, feedLoadedID, loadingAnimals, isLoadingAndDepartureReviewed, consignmentDayID}) {
        this.ID = id;
        this.ConsignmentID = consignmentID;
        this.PortID = portID;
        this.Order = order;
        this.FeedPlannedID = feedPlannedID;
        this.LoadingCommencedDateUTC = loadingCommencedDateUTC;
        this.LoadingCompletedDateUTC = loadingCompletedDateUTC;
        this.VesselDepartedDateUTC = vesselDepartedDateUTC;
        this.FeedLoadedID = feedLoadedID;
        this.LoadingAnimals = loadingAnimals;
        this.IsLoadingAndDepartureReviewed = isLoadingAndDepartureReviewed;
        this.ConsignmentDayID = consignmentDayID;
      }
}