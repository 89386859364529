import '../../css/dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useState, useEffect, useContext, useTransition } from "react";
import { Container, Row, Col, Stack, ButtonGroup, Button } from "react-bootstrap";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import LoggedInContainer from "../../layout/LoggedInContainer";
import REConsignmentOverview from "../../components/RegisteredEstablishments/REConsignmentOverview";
import { getRegisteredEstablishmentConsignmentsForREs, getRegisteredEstablishmentConsignmentsForAllREs } from '../../api/apiAccess';
import { useNavigate } from 'react-router-dom';
import { RegisteredEstablishmentConsignment } from '../../classes/RE/RegisteredEstablishmentConsignment';
import { UserContext } from '../..';
import { UserType } from '../../api/enums/EnumUserType';

const REDashboard = () => {
    const usrCtx = useContext(UserContext);
    const navigate = useNavigate();
    const userCtx = useContext(UserContext)
    const [consignments, setConsignments] = useState([]);

    useEffect(() => {
        const fetchREConsignments = async () => {
            let results;
            if (userCtx.user?.userTypeID === UserType.LIVE_CORP) {
                results = await getRegisteredEstablishmentConsignmentsForAllREs();
            } else {
                results = await getRegisteredEstablishmentConsignmentsForREs(userCtx.associatedExporter.exporter.registeredEstablishmentIDs);
            }
            
            if (results?.status === 200) {
                let reConsignments = results.data.map(r => new RegisteredEstablishmentConsignment(r));
                setConsignments(reConsignments);
            }
        }

        fetchREConsignments();

    }, [])

    const onViewPastConsignments = () => {
        navigate("/re/historicalconsignments/");
    }

    const onCreateNewConsignment = () => {
        navigate("/re/createconsignment");
    }

    const goToExporterDashboard = () => {
        navigate("/dashboard");
    }

    const onReturnToLiveCorpDashboardClicked = () => {
        navigate("/dashboard");
    }

    return (
        <LoggedInContainer>
            <Container fluid>
                <Row className="dashboard-consignment-group-header">
                    <Col xs={7}>
                        <Stack direction="horizontal">
                            <div className="heading-text text-white" style={{lineHeight: "50px"}}>Consignments</div>
                            { userCtx.associatedExporter?.exporter?.organisationTypeID === 3 ? 
                                (
                                    <div className="ms-3">
                                        <ButtonGroup>
                                            <Button
                                                onClick={goToExporterDashboard}
                                                className="btn-secondary text-white"
                                                style={{height: "50px", width: "247px", fontSize: "13px", fontWeight: "400"}}
                                                >
                                                    
                                                    Voyages
                                            </Button>
                                            <Button
                                                style={{height: "50px", width: "247px", fontSize: "13px", fontWeight: "400"}}
                                                >
                                                    RE Consignments
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                ) : null
                            }
                            </Stack>
                    </Col>
                    <Col xs={5}>
                        <Stack className="justify-content-end" direction='horizontal' gap={2}>
                            {
                                userCtx.user?.userTypeID === UserType.LIVE_CORP ? (
                                    <Button
                                        onClick={onReturnToLiveCorpDashboardClicked}
                                        className="btn-secondary text-white"
                                        style={{height: "50px", width: "247px", fontSize: "13px", fontWeight: "400"}}
                                    >
                                        View LiveCorp Dashboard
                                    </Button>
                                ) : null
                            }
                            <Button
                                onClick={onViewPastConsignments}
                                className="btn-secondary text-white"
                                style={{height: "50px", width: "247px", fontSize: "13px", fontWeight: "400"}}
                            >
                                View past consignments
                            </Button>
                        
                            { userCtx.user?.userTypeID !== UserType.LIVE_CORP ? 
                                (
                                    <ReadWriteButton
                                        writeonly={true}
                                        onClick={onCreateNewConsignment}
                                        className="btn-primary"
                                        style={{height: "50px", width: "247px", fontSize: "13px", fontWeight: "400"}}
                                    >
                                        Create new consignment
                                    </ReadWriteButton>
                                ) : null
                            }
                        </Stack>
                    </Col>
                </Row>
                <Row className="mb-5 px-5 pt-5" style={{justifyContent: "center"}}>
                {
                    consignments.length > 0 && consignments.map((c, index) => {
                        if (c.IsSubmitted) return;
                        return (
                            <REConsignmentOverview key={`consignments_voyage_${index}`} consignment={c}/>
                        )
                    })
                }
                {
                    consignments?.filter(c => !c.IsSubmitted)?.length === 0 ? (
                        <div className='text-center'><em>There are currently no in progress consignment reports for this Registered Establishment</em></div>
                    )
                    : null
                }
                </Row>

            </Container>
        </LoggedInContainer>
    );
}

export default REDashboard;