import React from "react";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck, faHourglass1 } from "@fortawesome/free-solid-svg-icons";

const ReviewStatus = ({ status }) => {
    // if status recevied
        // if reviewed
            // RECEVIED / VIEWED
        // else (not reviewed)
            // AWAITING REVIEW
    // else 
        // NOT RECIEVED
    return (
        <>
            {
                status ?
                    status.received === true ?
                        status.reviewed === true ?
                            <Stack direction="horizontal" gap={1}>
                                <div className="bg-faint-success dashboard-reporting-status-icon-container">
                                    <FontAwesomeIcon icon={faCircleCheck} className="primary-green" />
                                </div>
                                <span style={{ fontSize: "13px" }}>{!status.isReviewRequiredPriorToDeptSubmission ? "Received" : "Viewed"}</span>
                            </Stack>
                            :
                            <Stack direction="horizontal" gap={1}>
                                <div className="dashboard-reporting-status-icon-container" style={{ backgroundColor: "#fcf3e6" }}>
                                    <FontAwesomeIcon icon={faHourglass1} color="#f79f1b" style={{ fontSize: "13px" }} />
                                </div>
                                <span style={{ fontSize: "13px", lineHeight: 1 }}>Awaiting review</span>
                            </Stack>
                        :
                        <Stack direction="horizontal" gap={1} >
                            <div className="dashboard-reporting-status-icon-container" style={{ backgroundColor: "#f7e4e4" }}>
                                <FontAwesomeIcon icon={faCircleXmark} className="error-red" />
                            </div>
                            <span style={{ fontSize: "13px", lineHeight: 1 }}>Not received</span>
                        </Stack>
                    : null
            }
        </>
    )
};

export default ReviewStatus;