import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Stack, Form, InputGroup, Modal, CloseButton, Alert, Spinner } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import ConsignmentMap from "../../components/ConsignmentDashboard/ConsignmentMap";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import {
  getFeedQualityOptions,
  getFeedMeasuredByOptions,
  getSailingOptions,
  getDetailedObservationForConsignmentDay,
  getConsignmentDestinationPortsForConsignment,
  getDestinationPorts,
  getConsignmentDecksForConsignmentID,
  getVentilationIssueOptions,
  patchObservation,
} from "../../api/apiAccess";
import { getPortLocalDatetime, getPortDateTimeUTC,isReportEditable } from "../../utils/common";
import DateTimePicker from "../../components/DateTimePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import formatcoords from "formatcoords";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { NumericValidationRules, parseNumericInput, FeedNumberValidationRules } from "../../utils/InputValidator";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { Tooltip } from "react-tooltip";
import dms2dec from "dms2dec";
import { getEditPageReasons } from "../../api/apiAccess";
import EditLogModal from "../../components/EditLogModal";
import { UserContext } from "../..";
import ReadWriteFormSelect from "../../components/ReadWriteElements/ReadWriteFormSelect";
import ReadWriteFormCheck from "../../components/ReadWriteElements/ReadWriteFormCheck";
import ReadWriteFormRadio from "../../components/ReadWriteElements/ReadWriteFormRadio";
import ReadWriteInputGroup from "../../components/ReadWriteElements/ReadWriteInputGroup";
import ReadWriteFormControl from "../../components/ReadWriteElements/ReadWriteFormControl";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import moment from "moment-timezone";
import tzlookup from "tz-lookup";

const Edit_Observation = () => {
  const { state } = useLocation();
  const userCtx = useContext(UserContext);

  const [showEditPositionModal, setShowEditPositionModal] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [editPageReasons, setEditPageReasons] = useState([]);

  // Modal Validation
  const degreeLatitudeValidationRules = new NumericValidationRules({
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
    minRange: -90,
    maxRange: 90,
  });

  const degreeLongitudeValidationRules = new NumericValidationRules({
    allowDecimal: false,
    allowNegative: true,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
    minRange: -180,
    maxRange: 180,
  });

  const minuteValidationRules = new NumericValidationRules({
    allowDecimal: false,
    allowNegative: false,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
    minRange: 0,
    maxRange: 60,
  });

  const secondValidationRules = new NumericValidationRules({
    allowDecimal: true,
    allowNegative: false,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
    minRange: 0,
    maxRange: 60,
  });

  const [modalVesselLocationValid, setModalVesselLocationValid] = useState(true);
  const [modalVesselLocationValidError, setModalVesselLocationValidError] = useState("");

  const [modalLatitudeDegreesValid, setModalLatitudeDegreesValid] = useState(true);
  const [modalLatitudeMinutesValid, setModalLatitudeMinutesValid] = useState(true);
  const [modalLatitudeSecondsValid, setModalLatitudeSecondsValid] = useState(true);

  const [modalLongitudeDegreesValid, setModalLongitudDegreesValid] = useState(true);
  const [modalLongitudMinutesValid, setModalLongitudMinutesValid] = useState(true);
  const [modalLongitudSecondsValid, setModalLongitudSecondsValid] = useState(true);

  const [modalRecordedDateValid, setModalRecordedDateValid] = useState(true);

  // Modal data
  const [modalRecordDateTime, setModalRecordDateTime] = useState(null);
  const [modalLatLong, setModalLatLong] = useState(null);
  const [decimalLatLong, setDecimalLatLong] = useState(null)// Current position index 0 = Lat, index 1 = Long

  // if latitude is a negate select south of equator otherwise select north of equator
  const updateLatitudeDegrees = (aLatitude) => {
    let lIsNorth = true;

    if (!StringIsNullUndefinedOrEmpty(aLatitude)) {
      if (Number(aLatitude) < 0) {
        lIsNorth = false;
      }
    }

    setModalLatLong({
      ...modalLatLong,
      latitude: {
        ...modalLatLong.latitude,
        degree: aLatitude,
      },
      isNW: lIsNorth,
    });
  };

  // if north is checked convert latitude to a positive, if south is selcted convert latitude to a negative
  const updateEquatorPosition = (aIsNorth) => {
    let lLatitudeDegree = modalLatLong.latitude.degree;

    if (!StringIsNullUndefinedOrEmpty(lLatitudeDegree)) {
      lLatitudeDegree = Math.abs(Number(lLatitudeDegree));

      if (aIsNorth == false) {
        lLatitudeDegree = lLatitudeDegree * -1;
      }
    }

    setModalLatLong({
      ...modalLatLong,
      latitude: {
        ...modalLatLong.latitude,
        degree: lLatitudeDegree,
      },
      isNW: aIsNorth,
    });
  };

  // Set the ships current location in decimal degrees
  // This is needed when converting the position recorded date/time from local time (relative to the ship) to UTC
  useEffect(() => {
    let lLatDegree = modalLatLong?.latitude.degree;
    let lLatMinute = modalLatLong?.latitude.minute;
    let lLatSecond = modalLatLong?.latitude.second;

    let lLonDegree = modalLatLong?.longitude.degree;
    let lLonMinute = modalLatLong?.longitude.minute;
    let lLonSecond = modalLatLong?.longitude.second;

    // Must be valid in order to convert from degrees, minutes, seconds to decimal degrees
    let lIsLatitudeDegreeValid = !StringIsNullUndefinedOrEmpty(lLatDegree) && isNaN(lLatDegree) === false;
    let lIsLatitudeMinuteValid = !StringIsNullUndefinedOrEmpty(lLatMinute);
    let lIsLatitudeSecondValid = !StringIsNullUndefinedOrEmpty(lLatSecond);
    let lIsLongitudeDegreeValid = !StringIsNullUndefinedOrEmpty(lLonDegree);
    let lIsLongitudeMinuteValid = !StringIsNullUndefinedOrEmpty(lLonMinute);
    let lIsLongitudeSecondValid = !StringIsNullUndefinedOrEmpty(lLonSecond);

    let lCoords = []
    if (lIsLatitudeDegreeValid && lIsLatitudeMinuteValid && lIsLatitudeSecondValid && lIsLongitudeDegreeValid && lIsLongitudeMinuteValid && lIsLongitudeSecondValid) {
      lCoords = dms2dec(
        [Math.abs(Number(lLatDegree)), Number(lLatMinute), Number(lLatSecond)],
        modalLatLong.isNW ? "N" : "S",
        [Math.abs(Number(lLonDegree)), Number(lLonMinute), Number(lLonSecond)],
        lLonDegree > 0 ? "E" : "W"
      );

      // Check if cooridnates are valid
      let lValid = true;

      // latitide must be between -90 and 90 (no decimals)
      if (lCoords[0] > degreeLatitudeValidationRules.maxRange) {
        lCoords[0] = degreeLatitudeValidationRules.maxRange;
        // Set latitude to max valid latitude
        lLatDegree = degreeLatitudeValidationRules.maxRange;
        lLatMinute = 0;
        lLatSecond = 0;
        lValid = false;
      }
      else if (lCoords[0] < degreeLatitudeValidationRules.minRange) {
        lCoords[0] = degreeLatitudeValidationRules.minRange;
        // Set latitude to min valid latitude
        lLatDegree = degreeLatitudeValidationRules.minRange;
        lLatMinute = 0;
        lLatSecond = 0;
        lValid = false;
      }

      // longitude must be between -180 and 180 (no decimals)
      if (lCoords[1] > degreeLongitudeValidationRules.maxRange) {
        lCoords[1] = degreeLongitudeValidationRules.maxRange;
        // Set longitude to max valid longitude
        lLonDegree = degreeLongitudeValidationRules.maxRange;
        lLonMinute = 0;
        lLonSecond = 0;
        lValid = false;
      }
      else if (lCoords[1] < degreeLongitudeValidationRules.minRange) {
        lCoords[1] = degreeLongitudeValidationRules.minRange;
        // Set longitude to min valid longitude
        lLonDegree = degreeLongitudeValidationRules.minRange;
        lLonMinute = 0;
        lLonSecond = 0;
        lValid = false;
      }

      // update latlong to valid values
      if (lValid === false) {
        setModalLatLong({
          ...modalLatLong,
          latitude: {
            degree: lLatDegree,
            minute: lLatMinute,
            second: lLatSecond
          },
          longitude: {
            degree: lLonDegree,
            minute: lLonMinute,
            second: lLonSecond
          }
        });
      }

      setDecimalLatLong({ latitude: lCoords[0], longitude: lCoords[1] });
    }
    else {
      setDecimalLatLong(null);
    }


  }, [modalLatLong])

  // Page Data
  const [hasData, setHasData] = useState(false);

  const [ID, setID] = useState(-1);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locationRecordedDateTime, setLocationRecordedDateTime] = useState(null);
  const locationRecordedLocalDateTimeStrFormat = "[Local:] dddd D/MM/YYYY HH:mm ([UTC]Z)";
  const [locationRecordedLocalDateTimeStr, setLocationRecordedLocalDateTimeStr] = useState("");

  const [isCurrentlyAtPort, setIsCurrentlyAtPort] = useState(false);
  const [nextPort, setNextPort] = useState(undefined);

  const [estArrivalDateTime, setEstArrivalDateTime] = useState(null);
  const [sailingConditionID, setSailingConditionID] = useState(1);

  const [hasVentilationIssue, setHasVentilationIssue] = useState(false);
  const [ventilationDecks, setVentilationDecks] = useState([]);

  const [ventilationIssue, setVentilationIssue] = useState(1);
  const [ventilationIssueLastHours, setVentilationIssueLastHours] = useState("");
  const [ventilationIssueComment, setVentilationIssueComment] = useState("");
  const [ventilationIssueAnimalImpactComment, setVentilationIssueAnimalImpactComment] = useState("");

  const [feedROBID, setFeedROBID] = useState(-1);
  const [feedROBPellets, setFeedROBPellets] = useState("");
  const [feedROBGrain, setFeedROBGrain] = useState("");
  const [feedROBRoughage, setFeedROBRoughage] = useState("");

  const [feedMeasuredID, setFeedMeasuredID] = useState(1);
  const [feedQualityID, setFeedQualityID] = useState(1);
  const [feedQualityComment, setFeedQualityComment] = useState("");

  const [hasWaterIssue, setHasWaterIssue] = useState(false);
  const [waterConsumption, setWaterConsumption] = useState("");
  const [waterQualityComment, setWaterQualityComment] = useState("");

  const [generalComment, setGeneralComment] = useState("");

  // Drodown options
  const [feedMeasuredByOptions, setFeedMeauredByOptions] = useState([]);
  const [feedQualityOptions, setFeedQualityOptions] = useState([]);
  const [sailingOptions, setSailingOptions] = useState([]);
  const [portOptions, setPortOptions] = useState([]);
  const [ventilationIssuesOptions, setVentilationIssuesOptions] = useState([]);

  // validation state
  const [estimatedArrivalDateValid, setEstimatedArrivalDateValid] = useState(true);
  const [ventilationIssueLengthValid, setVentilationIssueLengthValid] = useState(true);
  const [ventilationIssueDecksValid, setVentilationIssueDecksValid] = useState(true);
  const [robPelletsValid, setRobPelletsValid] = useState(true);
  const [robGrainValid, setRobGrainValid] = useState(true);
  const [robRoughageValid, setRobRoughageValid] = useState(true);
  const [waterConsumptionValid, setWaterConsumptionValid] = useState(true);
  const [waterommentsValid, setWaterCommentsValid] = useState(true);

  const [loading, setIsLoading] = useState(true);
  const [loadError, setHasLoadError] = useState(false);
  const [saveError, setHasSaveError] = useState(false);
  const [saveErrorMessage, setHasSaveErrorMessage] = useState(false);
  const [saveSuccess, setHasSaveSuccess] = useState(false);

  const IntegerValidationRules = new NumericValidationRules({
    allowDecimal: false,
    allowNegative: false,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
  });

  const decimalValidationRules = new NumericValidationRules({
    allowDecimal: true,
    decimalPrecision: 2,
    allowNegative: false,
    allowLeadingZerosForWholeNumbers: false,
    allowSingleZero: true,
  });

  const navigate = useNavigate();

  const goToBack = () => goToPreviousObservations();

  const goToPreviousObservations = () => {
    navigate(`/viewhistorydata/${state.consignmentDay.consignmentID}`, { state: { focusedTab: "observations", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx,state.allowEditing) } });
  };

  const fetchPageData = async () => {
    let lPromises = [
      getFeedMeasuredByOptions(),
      getFeedQualityOptions(),
      getSailingOptions(),
      getDetailedObservationForConsignmentDay(state.consignmentDay.id),
      getConsignmentDestinationPortsForConsignment(state.consignmentDay.consignmentID),
      getDestinationPorts(),
      getConsignmentDecksForConsignmentID(state.consignmentDay.consignmentID),
      getVentilationIssueOptions(),
    ];

    Promise.all(lPromises)
      .then((response) => {
        if (response && response.every((resp) => resp.status === 200)) {
          setFeedMeauredByOptions(response[0].data);
          setFeedQualityOptions(response[1].data);
          setSailingOptions(response[2].data);
          setVentilationIssuesOptions(response[7].data);

          let lObservationData = response[3].data;
          let lObservation = lObservationData.observation.observation;
          let lFeedDetail = lObservationData.observation.feedDetail;

          setID(lObservation.id);
          setLatitude(lObservation.latitude);
          setLongitude(lObservation.longitude);
          setLocationRecordedDateTime(new Date(lObservation.locationDateUTC));
          setLocationRecordedLocalDateTimeStr(
            moment(lObservation.locationDateUTC).tz(tzlookup(lObservation.latitude, lObservation.longitude)).format(locationRecordedLocalDateTimeStrFormat)
          );

          setIsCurrentlyAtPort(lObservation.isCurrentlyAtPort);
          updateModal(lObservation.latitude, lObservation.longitude, new Date(lObservation.locationDateUTC));

          setEstArrivalDateTime(new Date(lObservation.estimatedArrivalDateUTC));
          //console.log("setEstArrivalDateTime:",lObservation.estimatedArrivalDateUTC,new Date(lObservation.estimatedArrivalDateUTC));
          setSailingConditionID(lObservation.sailingConditionID);

          setFeedROBID(lFeedDetail.id);
          setFeedROBPellets(lFeedDetail.pellets);
          setFeedROBGrain(lFeedDetail.grain);
          setFeedROBRoughage(lFeedDetail.roughage);

          setFeedMeasuredID(lObservation.feedMeasurementTypeID);
          setFeedQualityID(lObservation.feedQualityTypeID);
          setFeedQualityComment(lObservation.feedQualityComments ? lObservation.feedQualityComments : "");

          setWaterConsumption(lObservation.waterConsumptionAvg);
          setWaterQualityComment(lObservation.waterComments ? lObservation.waterComments : "");

          let lHasWaterIssue = false;
          if (!StringIsNullUndefinedOrEmpty(lObservation.waterComments)) {
            lHasWaterIssue = true;
          }
          setHasWaterIssue(lHasWaterIssue);

          setGeneralComment(lObservation.generalComments ? lObservation.generalComments : "");

          let lPorts = [];

          let lConsignmentDestinationPorts = response[4].data;
          let lAllDestinationPorts = response[5].data;

          if (lConsignmentDestinationPorts && lAllDestinationPorts) {
            lConsignmentDestinationPorts.forEach((port) => {
              let lPort = lAllDestinationPorts.find((dp) => dp.id === port.portID);
              if (lPort) {
                lPorts.push(lPort);
              }
            });
          }
          setPortOptions(lPorts);

          //console.log("getNextPort:",lPorts, lPorts.find(dp=>dp.id === lObservation.nextPortID));
          setNextPort(lPorts.find((dp) => dp.id === lObservation.nextPortID));

          setVentilationIssueComment(lObservation.ventilationComment ? lObservation.ventilationComment : "");
          setVentilationIssueAnimalImpactComment(lObservation.ventilationAnimalImpactComment ? lObservation.ventilationAnimalImpactComment : "");
          setVentilationIssueLastHours(lObservation.ventilationIssueLength);

          // setup ventilation decks
          if (lObservationData.ventilationIssues !== null && lObservationData.ventilationIssues.length > 0) {
            setHasVentilationIssue(true);
          } else {
            setHasVentilationIssue(false);
          }

          let lDecks = response[6].data;
          lDecks.forEach((deck) => {
            if (lObservationData.ventilationIssues.some((vi) => vi.consignmentDeckID === deck.id)) {
              let lDeckVentilationIssue = lObservationData.ventilationIssues.find((vi) => vi.consignmentDeckID === deck.id);
              deck.ventilationDeckIssueID = lDeckVentilationIssue.id;
              deck.hasVentilationIssue = true;
            } else {
              deck.ventilationDeckIssueID = null;
              deck.hasVentilationIssue = false;
            }
          });

          setVentilationDecks(lDecks);

          return true;
        } else {
          return false;
        }
      })
      .then((result) => {
        setHasData(result);
        setHasLoadError(!result);
        setIsLoading(false);
      })
      .catch((error) => {
        setHasLoadError(true);
        setIsLoading(false);
      });
  };

  const fetchEditPageReasons = async () => {
    let results = await getEditPageReasons();
    if (results.status === 200) {
      let reasons = results.data;
      setEditPageReasons(reasons);
    }
  };

  useEffect(() => {
    fetchPageData();
    fetchEditPageReasons();
  }, []);

  // Populate modal
  const updateModal = (latitude, longitude, locationRecordedDateTime) => {
    // clear any validation
    setModalVesselLocationValid(true);
    setModalLatitudeDegreesValid(true);
    setModalLatitudeMinutesValid(true);
    setModalLatitudeSecondsValid(true);
    setModalLongitudDegreesValid(true);
    setModalLongitudMinutesValid(true);
    setModalLongitudSecondsValid(true);
    setModalRecordedDateValid(true);

    const defaultLatLong = () => {
      const coords = formatcoords(latitude, longitude);
      return {
        latitude: {
          degree: Math.round(coords.latValues.degreesInt, 0) * (coords.north === true ? 1 : -1),
          minute: Math.round(coords.latValues.minutesInt, 0),
          second: Math.round(coords.latValues.seconds, 0),
        },
        longitude: {
          degree: Math.round(coords.lonValues.degreesInt, 0) * (coords.east === true ? 1 : -1),
          minute: Math.round(coords.lonValues.minutesInt, 0),
          second: Math.round(coords.lonValues.seconds, 0),
        },
        isNW: latitude > 0,
      };
    };

    setModalRecordDateTime(locationRecordedDateTime);
    //console.log("setModalRecordDateTime:",locationRecordedDateTime);
    let lCoords = defaultLatLong();
    setModalLatLong(lCoords);
  };

  // Populate modal on open
  useEffect(() => {
    if (showEditPositionModal === true) {
      updateModal(latitude, longitude, locationRecordedDateTime);
    }
  }, [showEditPositionModal]);

  const mapRender = (status) => {
    if (status === Status.LOADING) return <h3>{status} ..</h3>;
    if (status === Status.FAILURE) return <h3>{status} ...</h3>;
    return null;
  };

  // clear ventilation inputs when no is selcted
  useEffect(() => {
    if (hasVentilationIssue === false) {
      setVentilationIssueComment("");
      setVentilationIssueAnimalImpactComment("");
      setVentilationIssueLastHours("");
      setVentilationIssue(1);

      let lDecks = [...ventilationDecks];
      ventilationDecks.forEach((deck) => {
        deck.hasVentilationIssue = false;
      });
      setVentilationDecks(lDecks);
    }
  }, [hasVentilationIssue]);

  // ventilation only applicable if other is selected from the dropdown
  useEffect(() => {
    if (ventilationIssue !== 3) {
      setVentilationIssueComment("");
    }
  }, [ventilationIssue]);

  useEffect(() => {
    if (hasWaterIssue === false) {
      setWaterQualityComment("");
    }
  }, [hasWaterIssue]);

  const updateVentilationAffectedDeck = (deckID, isAffected) => {
    let lDecks = [...ventilationDecks];
    let lMatchingDeck = lDecks.find((deck) => deck.id === deckID);
    if (lMatchingDeck) {
      lMatchingDeck.hasVentilationIssue = isAffected;
    }
    setVentilationDecks(lDecks);
    setVentilationIssueDecksValid(true);
  };

  const onEditModalClosed = () => {
    setEditModalShow(false);
  };

  const onEditModalSaveSuccess = () => {
    save();
  };

  const onEditModalSaveFailed = () => {
    // do nothing generic error message is displayed inside the modal
  };

  const validateModal = () => {
    let lIsLatitudeDegreeValid = !StringIsNullUndefinedOrEmpty(modalLatLong.latitude.degree);
    setModalLatitudeDegreesValid(lIsLatitudeDegreeValid);

    let lIsLatitudeMinuteValid = !StringIsNullUndefinedOrEmpty(modalLatLong.latitude.minute);
    setModalLatitudeMinutesValid(lIsLatitudeMinuteValid);

    let lIsLatitudeSecondValid = !StringIsNullUndefinedOrEmpty(modalLatLong.latitude.second);
    setModalLatitudeSecondsValid(lIsLatitudeSecondValid);

    let lIsLongitudeDegreeValid = !StringIsNullUndefinedOrEmpty(modalLatLong.longitude.degree);
    setModalLongitudDegreesValid(lIsLongitudeDegreeValid);

    let lIsLongitudeMinuteValid = !StringIsNullUndefinedOrEmpty(modalLatLong.longitude.minute);
    setModalLongitudMinutesValid(lIsLongitudeMinuteValid);

    let lIsLongitudeSecondValid = !StringIsNullUndefinedOrEmpty(modalLatLong.longitude.second);
    setModalLongitudSecondsValid(lIsLongitudeSecondValid);

    // can't have a negative latitude degree and south checked OR a positive latitude degree and north checked
    let lLocationValid = true;
    if (modalLatLong.latitude.degree > 0 && modalLatLong.isNW === false) {
      lLocationValid = false;
      setModalVesselLocationValidError("Invalid position. You can not have a positive latitude and be south of the equator.");
    } else if (modalLatLong.longitude.degree < 0 && modalLatLong.isNW === true) {
      lLocationValid = false;
      setModalVesselLocationValidError("Invalid position. You can not have a negative latitude and be north of the equator.");
    }
    setModalVesselLocationValid(lLocationValid);

    let lIsRecordedDateValid = !StringIsNullUndefinedOrEmpty(modalRecordDateTime);
    setModalRecordedDateValid(lIsRecordedDateValid);

    if (
      lIsLatitudeDegreeValid &&
      lIsLatitudeMinuteValid &&
      lIsLatitudeSecondValid &&
      lIsLongitudeDegreeValid &&
      lIsLongitudeMinuteValid &&
      lIsLongitudeSecondValid &&
      lLocationValid &&
      lIsRecordedDateValid
    ) {
      return true;
    } else {
      return false;
    }
  };

  const setPositionSave = () => {
    let lModalValid = validateModal();

    // if valid update state values the close modal
    if (lModalValid) {
      let ll = dms2dec(
        [Math.abs(Number(modalLatLong.latitude.degree)), Number(modalLatLong.latitude.minute), Number(modalLatLong.latitude.second)],
        modalLatLong.isNW ? "N" : "S",
        [Math.abs(Number(modalLatLong.longitude.degree)), Number(modalLatLong.longitude.minute), Number(modalLatLong.longitude.second)],
        modalLatLong.longitude.degree > 0 ? "E" : "W"
      );
      setLatitude(ll[0]);
      setLongitude(ll[1]);
      setLocationRecordedDateTime(new Date(modalRecordDateTime));
      setShowEditPositionModal(false);
      setLocationRecordedLocalDateTimeStr(new moment(modalRecordDateTime).tz(tzlookup(ll[0], ll[1])).format(locationRecordedLocalDateTimeStrFormat));
    }
  };

  const goToOk = (e) => {
    const validatePage = () => {
      let lEstimatedArrivalDateValid = !StringIsNullUndefinedOrEmpty(estArrivalDateTime);
      setEstimatedArrivalDateValid(lEstimatedArrivalDateValid);

      let lFeedRobPelletsValid = !StringIsNullUndefinedOrEmpty(feedROBPellets) && !isNaN(feedROBPellets);
      setRobPelletsValid(lFeedRobPelletsValid);

      let lFeedRobGrainValid = !StringIsNullUndefinedOrEmpty(feedROBGrain) && !isNaN(feedROBGrain);
      setRobGrainValid(lFeedRobGrainValid);

      let lFeedRobRoughageValid = !StringIsNullUndefinedOrEmpty(feedROBRoughage) && !isNaN(feedROBRoughage);
      setRobRoughageValid(lFeedRobRoughageValid);

      let lWaterConsumptionValid = !StringIsNullUndefinedOrEmpty(waterConsumption) && !isNaN(waterConsumption);
      setWaterConsumptionValid(lWaterConsumptionValid);

      let lWaterCommentsValid = true;
      if (hasWaterIssue === true && StringIsNullUndefinedOrEmpty(waterQualityComment)) {
        lWaterCommentsValid = false;
      }
      setWaterCommentsValid(lWaterCommentsValid);

      // If yes, is checked for ventilation issue, at least 1 deck must be checked
      let lVentilationDecksValid = true;
      if (hasVentilationIssue === true && ventilationDecks.every((deck) => deck.hasVentilationIssue === false)) {
        lVentilationDecksValid = false;
      }
      setVentilationIssueDecksValid(lVentilationDecksValid);

      let lVentilationHoursValid = true;
      if (hasVentilationIssue === true && (StringIsNullUndefinedOrEmpty(ventilationIssueLastHours) || isNaN(ventilationIssueLastHours))) {
        lVentilationHoursValid = false;
      }
      setVentilationIssueLengthValid(lVentilationHoursValid);

      if (
        lEstimatedArrivalDateValid &&
        lFeedRobPelletsValid &&
        lFeedRobGrainValid &&
        lFeedRobRoughageValid &&
        lWaterConsumptionValid &&
        lVentilationDecksValid &&
        lVentilationHoursValid &&
        lWaterCommentsValid
      ) {
        return true;
      } else {
        return false;
      }
    };

    e.preventDefault();
    setHasSaveError(false);
    setHasSaveSuccess(false);

    let lModalValid = validateModal();
    let lPageValid = validatePage();

    if (lModalValid && lPageValid) {
      setEditModalShow(true);
    } else {
      setEditModalShow(false);
      setHasSaveError(true);
      setHasSaveErrorMessage("Unable to save observation. Please address any validation issues.");
    }
  };

  // at this point the page should have already been validated
  const save = (editLogs) => {
    const writeToDB = async () => {

      if (editLogs === undefined || editLogs.length === 0) {
        setTimeout(() => {
          setHasSaveError(true);
          setHasSaveErrorMessage("Unable to save observation. Please address the reason for editing.");
        }, 5000);
        return;
      }

      // Create object to save
      let lFeedObj = {
        ID: feedROBID,
        Pellets: parseFloat(feedROBPellets),
        Grain: parseFloat(feedROBGrain),
        Roughage: parseFloat(feedROBRoughage),
      };

      let lVentilationIssuesObj = [];
      ventilationDecks.forEach((deck) => {
        lVentilationIssuesObj.push({
          ID: deck.ventilationDeckIssueID,
          ConsignmentDeckID: deck.id,
          ObservationID: ID,
          Affected: deck.hasVentilationIssue,
        });
      });

      let lObservation = {
        ID: ID,
        Latitude: latitude,
        Longitude: longitude,
        LocationDateUTC: locationRecordedDateTime.toISOString(),
        IsCurrentlyAtPort: isCurrentlyAtPort,
        NextPortID: nextPort?.id,
        EstimatedArrivalDateUTC: estArrivalDateTime.toISOString(),
        SailingConditionID: parseInt(sailingConditionID),
        FeedMeasurementTypeID: parseInt(feedMeasuredID),
        FeedQualityTypeID: parseInt(feedQualityID),
        FeedQualityComments: StringIsNullUndefinedOrEmpty(feedQualityComment) ? null : feedQualityComment,
        WaterConsumptionAvg: parseFloat(waterConsumption),
        IsWaterIssue: hasWaterIssue,
        WaterComments: StringIsNullUndefinedOrEmpty(waterQualityComment) ? null : waterQualityComment,
        GeneralComments: StringIsNullUndefinedOrEmpty(generalComment) ? null : generalComment,
        VentilationIssueComment: StringIsNullUndefinedOrEmpty(ventilationIssueComment) ? null : ventilationIssueComment,
        VentilationIssueLength: StringIsNullUndefinedOrEmpty(ventilationIssueLastHours) ? null : parseFloat(ventilationIssueLastHours),
        VentilationAnimalImpactComment: StringIsNullUndefinedOrEmpty(ventilationIssueAnimalImpactComment)
          ? null
          : ventilationIssueAnimalImpactComment,
        VentilationlssueTypeID: hasVentilationIssue ? parseInt(ventilationIssue) : null,
        FeedDetail: lFeedObj,
        VentilationIssues: lVentilationIssuesObj,
        EditLog: editLogs[0],
      };

      let lResult = await patchObservation(ID, lObservation);
      setEditModalShow(false);
      if (lResult.status === 200) {
        setHasSaveSuccess(true);

        setTimeout(() => {
          setHasSaveSuccess(false);
        }, 15000);
      } else {
        setHasSaveError(true);
        setHasSaveErrorMessage("Something went wrong while trying to save.");
      }
    };

    writeToDB();
  };

  return (
    <LoggedInContainer>
      <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <Row className="upper-panel mx-0">
          <Col>
            <Container>
              <Row>
                <Col xs={12}>
                  <div className="text-center heading-text mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>
                    {isReportEditable(userCtx, state.allowEditing) ? "Edit Observation" : "View Observation"}
                  </div>
                  <div className="text-center heading-text mb-2" style={{ color: "#FFF", lineHeight: "53px", fontSize: "24px" }}>
                    Day {state.consignmentDay.dayNumber}
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col>
            <Container className="py-4 px-5 form-container-mid">
              {loading === true ? (
                <Row>
                  <Col>
                    <div className="text-center pt-5">
                      <Spinner className="mx-auto" />
                      <div className="mx-auto">Loading data...</div>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {hasData && loading === false ? (
                <Row>
                  <Col xs={12} md={8}>
                    <Row>
                      <Col>
                        <div className="green-underline-heading">Location Details</div>
                        <Row>
                          <Col>
                            <div className="field-label">Current Location</div>
                            <Stack direction="horizontal" gap={3}>
                              <FontAwesomeIcon icon={faLocationDot} className="location-point-icon" />
                              <Stack>
                                <div className="text-13-400 secondary-grey">
                                  {formatcoords(latitude, longitude).format("DD MM ss X", { latLonSeparator: ", ", decimalPlaces: 0 })}
                                </div>
                                <ReadWriteButton
                                  writeonly={true}
                                  variant="link"
                                  style={{ textAlign: "left", paddingLeft: "0px" }}
                                  className="text-decoration-none field-label"
                                  onClick={() => setShowEditPositionModal(true)}
                                >
                                  Edit Position
                                </ReadWriteButton>
                              </Stack>
                            </Stack>
                          </Col>
                          <Col className="text-13-400 secondary-grey">
                            <div className="field-label">Date/Time position was recorded</div>
                            <Row>
                              <Col xs={2}>Local:</Col>
                              <Col>{locationRecordedLocalDateTimeStr}</Col>
                            </Row>
                            <Row>
                              <Col xs={2}>UTC:</Col>
                              <Col>{getPortDateTimeUTC(nextPort, locationRecordedDateTime) + " UTC"}</Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <ReadWriteFormCheck
                              id="chkCurrentlyAtPort"
                              label="Currently at Port?"
                              onChange={(e) => {
                                setIsCurrentlyAtPort(e.target.checked);
                              }}
                              checked={isCurrentlyAtPort}
                              className="field-label"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={4}>
                            <div className="field-label">Next Port of Arrival</div>
                            <Form.Group>
                              <ReadWriteFormSelect
                                className="text-13-400 secondary-grey"
                                value={nextPort?.id}
                                onChange={(e) => setNextPort(portOptions.find((dp) => dp.id === parseInt(e.target.value)))}
                              >
                                {portOptions.map((opt, i) => {
                                  return (
                                    <option key={`port_opt_${i}`} value={opt.id}>
                                      {opt.portName}
                                    </option>
                                  );
                                })}
                              </ReadWriteFormSelect>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={12}>
                            <div className="field-label">Estimated Date and Time of Arrival</div>
                            <DateTimePicker
                              datetime={estArrivalDateTime}
                              isValid={estimatedArrivalDateValid}
                              errorMessage={"An estimated date and time of arrival is required."}
                              showTime={true}
                              showUTCTime={true}
                              onChange={(date) => {
                                setEstimatedArrivalDateValid(true);
                                setEstArrivalDateTime(date);
                              }}
                              port={nextPort}
                              showHeadLabel={false}
                              endLabel="Local Time"
                            />
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col xs={12} md={4}>
                            <div className="field-label">Sailing Conditions</div>
                            <Form.Group>
                              <ReadWriteFormSelect
                                value={sailingConditionID}
                                onChange={(e) => setSailingConditionID(e.target.value)}
                                className="text-13-400 secondary-grey"
                              >
                                {sailingOptions.map((opt, i) => {
                                  return (
                                    <option key={`sailing_condition_opt_${i}`} value={opt.id}>
                                      {opt.sailingConditionName}
                                    </option>
                                  );
                                })}
                              </ReadWriteFormSelect>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="green-underline-heading">Ventilation</div>
                        <Row>
                          <Col>
                            <div className="medium-heading">Was there an issue with ventilation, including any period not operational?</div>
                            <ReadWriteFormRadio
                              type={`radio`}
                              label={`No`}
                              name="rdoVentilation_Yes"
                              checked={hasVentilationIssue === false}
                              className="text-13-400 secondary-grey"
                              id="rdoVentilationIssue_No"
                              onChange={() => {
                                setVentilationIssueDecksValid(true);
                                setHasVentilationIssue(false);
                              }}
                            />
                            <ReadWriteFormRadio
                              type={`radio`}
                              label={`Yes`}
                              name="rdoVentilation_No"
                              checked={hasVentilationIssue === true}
                              className="text-13-400 secondary-grey"
                              id="rdoVentilationIssue_Yes"
                              onChange={() => {
                                setVentilationIssueDecksValid(true);
                                setHasVentilationIssue(true);
                              }}
                            />
                          </Col>
                        </Row>
                        {hasVentilationIssue === true ? (
                          <>
                            <Row className="mt-3">
                              <Col xs={12} md={4}>
                                <div className="field-label">Ventilation Issue</div>
                                <Form.Group>
                                  <ReadWriteFormSelect
                                    value={ventilationIssue}
                                    onChange={(e) => setVentilationIssue(Number(e.target.value))}
                                    className="text-13-400 secondary-grey"
                                  >
                                    {ventilationIssuesOptions.map((opt, i) => {
                                      return (
                                        <option key={`ventilation_issue_opt_${i}`} value={opt.id}>
                                          {opt.ventilationIssueType}
                                        </option>
                                      );
                                    })}
                                  </ReadWriteFormSelect>
                                </Form.Group>
                              </Col>
                            </Row>
                            {ventilationIssue === 3 ? (
                              <Row className="mt-3">
                                <Col>
                                  <div className="field-label">Describe the ventilation issue</div>
                                  <ReadWriteFormControl
                                    as="textarea"
                                    rows={3}
                                    value={ventilationIssueComment}
                                    onChange={(e) => setVentilationIssueComment(e.target.value)}
                                    className="text-13-400 secondary-grey"
                                  />
                                </Col>
                              </Row>
                            ) : null}
                            <Row className="mt-3">
                              <Col xs={12}>
                                <div className="field-label">How long did the ventilation issue exits?</div>
                              </Col>
                              <Col xs={12} md={4}>
                                <Form.Group id="ventilationHours">
                                  <ReadWriteInputGroup readonlyclasses="text-13-400 secondary-grey">
                                    <Form.Control
                                      value={ventilationIssueLastHours}
                                      onChange={(e) => {
                                        e.preventDefault();
                                        parseNumericInput(e, decimalValidationRules);
                                        setVentilationIssueLastHours(e.target.value);
                                        setVentilationIssueLengthValid(true);
                                      }}
                                      className="inputGroupWithAppend errorLeft text-13-400 secondary-grey"
                                      isInvalid={!ventilationIssueLengthValid}
                                    />
                                    <InputGroup.Text className={`inputGroupAppend ${!ventilationIssueLengthValid ? "invalidAppend" : ""}`}>
                                      hours
                                    </InputGroup.Text>
                                    {!ventilationIssueLengthValid ? (
                                      <div className="validation-error-tooltip">
                                        <Tooltip
                                          anchorSelect={`#ventilationHours`}
                                          place="top"
                                          variant="error"
                                          content="A valid ventilation issue length in hours is required."
                                        />
                                      </div>
                                    ) : null}
                                  </ReadWriteInputGroup>
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col xs={12}>
                                <Stack direction="horizontal" gap={3}>
                                  <div>Decks Affected</div>
                                  {!ventilationIssueDecksValid ? (
                                    <>
                                      <FontAwesomeIcon
                                        icon={faCircleExclamation}
                                        color="#dc3545"
                                        data-tooltip-id="ventilationDecksTooltip"
                                        data-tooltip-content="One or more decks must be checked."
                                      />
                                      <div className="validation-error-tooltip">
                                        <Tooltip id="ventilationDecksTooltip" variant="error" />
                                      </div>
                                    </>
                                  ) : null}
                                </Stack>
                              </Col>
                              <Col xs={12} md={6}>
                                <Row>
                                  {ventilationDecks.map((deck) => (
                                    <Col key={`ventilationIssueDeck_${deck.id}`} xs={6}>
                                      <ReadWriteFormCheck
                                        label={deck.deckName}
                                        onChange={(e) => {
                                          updateVentilationAffectedDeck(deck.id, e.target.checked);
                                        }}
                                        checked={deck.hasVentilationIssue}
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <div className="field-label">Comment on impact on animals</div>
                                <ReadWriteFormControl
                                  as="textarea"
                                  rows={3}
                                  className="text-13-400 secondary-grey"
                                  value={ventilationIssueAnimalImpactComment}
                                  onChange={(e) => setVentilationIssueAnimalImpactComment(e.target.value)}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="green-underline-heading">Feed</div>
                        <div className="medium-heading">Feed Rob</div>
                        <Row className="mt-1">
                          <Col xs={12} md={4}>
                            <div className="field-label">Pellets</div>
                            <Form.Group id="pelletsROB">
                              <ReadWriteInputGroup readonlyclasses="text-13-400 secondary-grey">
                                <Form.Control
                                  value={feedROBPellets}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    parseNumericInput(e, new FeedNumberValidationRules());
                                    setRobPelletsValid(true);
                                    setFeedROBPellets(e.target.value);
                                  }}
                                  className="inputGroupWithAppend errorLeft"
                                  isInvalid={!robPelletsValid}
                                />
                                <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!robPelletsValid ? "invalidAppend" : ""}`}>
                                  t
                                </InputGroup.Text>
                                {robPelletsValid === false ? (
                                  <div className="validation-error-tooltip">
                                    <Tooltip anchorSelect={`#pelletsROB`} place="top" variant="error" content="A valid  pellets ROB is requried." />
                                  </div>
                                ) : null}
                              </ReadWriteInputGroup>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <div className="field-label">Grain</div>
                            <Form.Group id="grainROB">
                              <ReadWriteInputGroup readonlyclasses="text-13-400 secondary-grey">
                                <Form.Control
                                  value={feedROBGrain}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    parseNumericInput(e, new FeedNumberValidationRules());
                                    setRobGrainValid(true);
                                    setFeedROBGrain(e.target.value);
                                  }}
                                  className="inputGroupWithAppend errorLeft"
                                  isInvalid={!robGrainValid}
                                />
                                <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!robGrainValid ? "invalidAppend" : ""}`}>
                                  t
                                </InputGroup.Text>
                                {!robGrainValid ? (
                                  <div className="validation-error-tooltip">
                                    <Tooltip anchorSelect={`#grainROB`} place="top" variant="error" content="A valid grain ROB is required." />
                                  </div>
                                ) : null}
                              </ReadWriteInputGroup>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={4}>
                            <div className="field-label">Roughage</div>
                            <Form.Group id="roughageROB">
                              <ReadWriteInputGroup readonlyclasses="text-13-400 secondary-grey">
                                <Form.Control
                                  value={feedROBRoughage}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    parseNumericInput(e, new FeedNumberValidationRules());
                                    setRobRoughageValid(true);
                                    setFeedROBRoughage(e.target.value);
                                  }}
                                  className="inputGroupWithAppend errorLeft"
                                  isInvalid={!robRoughageValid}
                                />
                                <InputGroup.Text
                                  className={`inputGroupAppend text-13-400 secondary-grey ${!robRoughageValid ? "invalidAppend" : ""}`}
                                >
                                  t
                                </InputGroup.Text>
                                {!robRoughageValid ? (
                                  <div className="validation-error-tooltip">
                                    <Tooltip anchorSelect={`#roughageROB`} place="top" variant="error" content="A valid roughage ROB is required." />
                                  </div>
                                ) : null}
                              </ReadWriteInputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <div className="field-label">Feed Measured By</div>
                            <Form.Group>
                              <ReadWriteFormSelect
                                value={feedMeasuredID}
                                onChange={(e) => setFeedMeasuredID(e.target.value)}
                                className="text-13-400 secondary-grey"
                              >
                                {feedMeasuredByOptions.map((opt, i) => {
                                  return (
                                    <option key={`feed_measured_by_opt_${i}`} value={opt.id}>
                                      {opt.feedMeasurementType}
                                    </option>
                                  );
                                })}
                              </ReadWriteFormSelect>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <div className="field-label">Feed Quality</div>
                            <Form.Group>
                              <ReadWriteFormSelect
                                value={feedQualityID}
                                onChange={(e) => setFeedQualityID(e.target.value)}
                                className="text-13-400 secondary-grey"
                              >
                                {feedQualityOptions.map((opt, i) => {
                                  return (
                                    <option key={`feed_quality_opt_${i}`} value={opt.id}>
                                      {opt.feedQualityType}
                                    </option>
                                  );
                                })}
                              </ReadWriteFormSelect>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <div className="field-label">Additional Comments on Feed Quality</div>
                            <ReadWriteFormControl
                              as="textarea"
                              className="text-13-400 secondary-grey"
                              rows={3}
                              value={feedQualityComment}
                              onChange={(e) => setFeedQualityComment(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="green-underline-heading">Water</div>
                        <Row>
                          <Col xs={12} md={4}>
                            <div className="field-label">Water consumption</div>
                            <Form.Group id="waterConsumption">
                              <ReadWriteInputGroup readonlyclasses="text-13-400 secondary-grey">
                                <Form.Control
                                  value={waterConsumption}
                                  onChange={(e) => {
                                    e.preventDefault();
                                    parseNumericInput(e, decimalValidationRules);
                                    setWaterConsumption(e.target.value);
                                    setWaterConsumptionValid(true);
                                  }}
                                  className="inputGroupWithAppend errorLeft text-13-400 secondary-grey"
                                  isInvalid={!waterConsumptionValid}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${!waterConsumptionValid ? "invalidAppend" : ""}`}>
                                  avg. l/hd
                                </InputGroup.Text>
                                {!waterConsumptionValid ? (
                                  <div className="validation-error-tooltip">
                                    <Tooltip
                                      anchorSelect={`#waterConsumption`}
                                      place="top"
                                      variant="error"
                                      content="A valid water consumption is required."
                                    />
                                  </div>
                                ) : null}
                              </ReadWriteInputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col>
                            <div className="medium-heading">Were there any issues with water quality or supply?</div>
                            <ReadWriteFormRadio
                              type={`radio`}
                              label={`Yes`}
                              checked={hasWaterIssue === true}
                              className="text-13-400 secondary-grey"
                              id="rdoWaterIssue_Yes"
                              onChange={() => {
                                setWaterCommentsValid(true);
                                setHasWaterIssue(true);
                              }}
                            />

                            <ReadWriteFormRadio
                              type={`radio`}
                              label={`No`}
                              checked={hasWaterIssue === false}
                              className="text-13-400 secondary-grey"
                              id="rdoWaterIssue_No"
                              onChange={() => {
                                setWaterCommentsValid(true);
                                setHasWaterIssue(false);
                              }}
                            />
                          </Col>
                        </Row>
                        {hasWaterIssue ? (
                          <Row className="mt-3">
                            <Col>
                              <div className="field-label">Additional comments on Water Quality and any supply issues</div>
                              <ReadWriteFormControl
                                id="txtWaterComments"
                                as="textarea"
                                rows={3}
                                value={waterQualityComment}
                                onChange={(e) => {
                                  setWaterCommentsValid(true);
                                  setWaterQualityComment(e.target.value);
                                }}
                                className="text-13-400 secondary-grey"
                                isInvalid={!waterommentsValid}
                              />
                              {!waterommentsValid ? (
                                <div className="validation-error-tooltip">
                                  <Tooltip
                                    anchorSelect={`#txtWaterComments`}
                                    place="top"
                                    variant="error"
                                    content="A comment on the water quality and any supply issues is required."
                                  />
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        ) : null}
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <div className="green-underline-heading">General Comments</div>
                        <div className="field-label">
                          Please provide general comments - eg exceptions, further comments on deck or any other conditions
                        </div>
                        <ReadWriteFormControl
                          className="text-13-400 secondary-grey"
                          as="textarea"
                          rows={3}
                          value={generalComment}
                          onChange={(e) => setGeneralComment(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={4}>
                    <Wrapper apiKey="AIzaSyD-7x9cjHGwxQH-JLXAlBRQ6ArVtL3ME3A" render={mapRender}>
                      <ConsignmentMap consignmentID={state.consignmentDay.consignmentID} />
                    </Wrapper>
                  </Col>
                </Row>
              ) : null}
            </Container>
          </Col>
        </Row>
        {loading === false && hasData === true ? (
          <Row className="mt-5 mx-0" style={{ position: "relative", top: "-300px" }}>
            {saveSuccess === true ? (
              <Col xs={12}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Alert variant="success">Observation updated successfully.</Alert>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ) : null}

            {saveError === true ? (
              <Col xs={12}>
                <Container>
                  <Row>
                    <Col xs={12}>
                      <Alert variant="danger">{saveErrorMessage}</Alert>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ) : null}

            <Col xs={12}>
              <Container>
                <Row>
                  <Col>
                    <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                      <ReadWriteButton
                        writeonly={false}
                        readonlytext="Back"
                        variant="secondary"
                        onClick={() => {
                          goToBack();
                        }}
                        size="lg"
                        style={{ minWidth: "150px" }}
                      >
                        Cancel
                      </ReadWriteButton>
                      {isReportEditable(userCtx, state.allowEditing) &&
                        <ReadWriteButton
                          writeonly={true}
                          variant="primary"
                          onClick={(e) => {
                            goToOk(e);
                          }}
                          size="lg"
                          style={{ minWidth: "150px" }}
                        >
                          Update
                        </ReadWriteButton>
                      }
                    </Stack>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        ) : null}

        {loadError === true && loading === false ? (
          <Container>
            <Row>
              <Col xs={12}>
                <Alert variant="danger">Unable to load observation.</Alert>
              </Col>
              <Col xs={12}>
                <Button
                  className="me-2 ms-auto"
                  variant="primary"
                  onClick={() => {
                    goToBack();
                  }}
                  size="lg"
                  style={{ minWidth: "150px" }}
                >
                  Back
                </Button>
              </Col>
            </Row>
          </Container>
        ) : null}
      </Container>

      <Modal centered show={showEditPositionModal} onHide={() => setShowEditPositionModal(false)} size="lg">
        <Modal.Header className="modal-heading">
          <Modal.Title>Set Position Manually </Modal.Title>
          <CloseButton variant="white" onClick={() => setShowEditPositionModal(false)}></CloseButton>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className="mb-4">
              <Col>
                <Stack>
                  <div className="field-label">Latitude</div>
                  <Stack direction="horizontal" gap={1}>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLatitudeDegree`}
                          value={modalLatLong?.latitude.degree}
                          onChange={(e) => {
                            e.preventDefault();
                            parseNumericInput(e, degreeLatitudeValidationRules);
                            setModalLatitudeDegreesValid(true);
                            setModalVesselLocationValid(true);
                            updateLatitudeDegrees(e.target.value);
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLatitudeDegreesValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLatitudeDegreesValid ? "invalidAppend" : ""}`}>°</InputGroup.Text>
                        {!modalLatitudeDegreesValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLatitudeDegree`}
                              place="top"
                              variant="error"
                              content="Latitude degreed are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLatitudeMinute`}
                          value={modalLatLong?.latitude.minute}
                          onChange={(e) => {
                            e.preventDefault();
                            setModalLatitudeMinutesValid(true);
                            parseNumericInput(e, minuteValidationRules);
                            setModalLatLong({
                              ...modalLatLong,
                              latitude: {
                                ...modalLatLong.latitude,
                                minute: e.target.value,
                              },
                            });
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLatitudeMinutesValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLatitudeMinutesValid ? "invalidAppend" : ""}`}>'</InputGroup.Text>
                        {!modalLatitudeMinutesValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLatitudeMinute`}
                              place="top"
                              variant="error"
                              content="Latitude minutes are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLatitudeSecond`}
                          value={modalLatLong?.latitude.second}
                          onChange={(e) => {
                            e.preventDefault();
                            setModalLatitudeSecondsValid(true);
                            parseNumericInput(e, secondValidationRules);
                            setModalLatLong({
                              ...modalLatLong,
                              latitude: {
                                ...modalLatLong.latitude,
                                second: e.target.value,
                              },
                            });
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLatitudeSecondsValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLatitudeSecondsValid ? "invalidAppend" : ""}`}>"</InputGroup.Text>
                        {!modalLatitudeSecondsValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLatitudeSecond`}
                              place="top"
                              variant="error"
                              content="Latitude seconds are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Stack>
                </Stack>
              </Col>
              <Col>
                <Stack>
                  <div className="field-label">Longitude</div>
                  <Stack direction="horizontal" gap={1}>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLongitudeDegree`}
                          value={modalLatLong?.longitude.degree}
                          onChange={(e) => {
                            e.preventDefault();
                            setModalLongitudDegreesValid(true);
                            setModalVesselLocationValid(true);
                            parseNumericInput(e, degreeLongitudeValidationRules);
                            setModalLatLong({
                              ...modalLatLong,
                              longitude: {
                                ...modalLatLong.longitude,
                                degree: e.target.value,
                              },
                            });
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLongitudeDegreesValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLongitudeDegreesValid ? "invalidAppend" : ""}`}>°</InputGroup.Text>
                        {!modalLongitudeDegreesValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLongitudeDegree`}
                              place="top"
                              variant="error"
                              content="Longitude degrees are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLongitudeMinute`}
                          value={modalLatLong?.longitude.minute}
                          onChange={(e) => {
                            e.preventDefault();
                            setModalLongitudMinutesValid(true);
                            parseNumericInput(e, minuteValidationRules);
                            setModalLatLong({
                              ...modalLatLong,
                              longitude: {
                                ...modalLatLong.longitude,
                                minute: e.target.value,
                              },
                            });
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLongitudMinutesValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLongitudMinutesValid ? "invalidAppend" : ""}`}>'</InputGroup.Text>
                        {!modalLongitudMinutesValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLongitudeMinute`}
                              place="top"
                              variant="error"
                              content="Longitude minutes are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <InputGroup>
                        <Form.Control
                          id={`txtSetPositionLongitudeSecond`}
                          value={modalLatLong?.longitude.second}
                          onChange={(e) => {
                            e.preventDefault();
                            setModalLongitudSecondsValid(true);
                            parseNumericInput(e, secondValidationRules);
                            setModalLatLong({
                              ...modalLatLong,
                              longitude: {
                                ...modalLatLong.longitude,
                                second: e.target.value,
                              },
                            });
                          }}
                          className="inputGroupWithAppend errorLeft"
                          isInvalid={!modalLongitudSecondsValid}
                        />
                        <InputGroup.Text className={`inputGroupAppend ${!modalLongitudSecondsValid ? "invalidAppend" : ""}`}>"</InputGroup.Text>
                        {!modalLongitudSecondsValid ? (
                          <div className="validation-error-tooltip">
                            <Tooltip
                              anchorSelect={`#txtSetPositionLongitudeSecond`}
                              place="top"
                              variant="error"
                              content="Longitude seconds are required."
                            />
                          </div>
                        ) : null}
                      </InputGroup>
                    </Form.Group>
                  </Stack>
                </Stack>
              </Col>
            </Row>
            <Row className="mb-4">
              <Stack direction="horizontal" gap={2}>
                <span className="field-label mb-1">Current vessel location</span>
                {!modalVesselLocationValid ? (
                  <>
                    <FontAwesomeIcon
                      id="invalidDate"
                      icon={faCircleExclamation}
                      data-tooltip-id="invalidLocation"
                      data-tooltip-content={modalVesselLocationValidError}
                      data-tooltip-variant="error"
                      style={{ fontSize: "18px", color: "#dc3545" }}
                    />
                    <div className="validation-error-tooltip">
                      <Tooltip id="invalidLocation" place="top" variant="error" />
                    </div>
                  </>
                ) : null}
              </Stack>

              <Stack>
                <Form.Check
                  id="rdoNorthOfEquator"
                  className="text-13-400 secondary-grey"
                  type="radio"
                  label="North of Equator"
                  checked={modalLatLong?.isNW}
                  onChange={(e) => {
                    setModalVesselLocationValid(true);
                    updateEquatorPosition(true);
                  }}
                />
                <Form.Check
                  id="rdoSouthOfEquator"
                  className="text-13-400 secondary-grey"
                  type="radio"
                  label="South of Equator"
                  checked={!modalLatLong?.isNW}
                  onChange={(e) => {
                    setModalVesselLocationValid(true);
                    updateEquatorPosition(false);
                  }}
                />
              </Stack>
            </Row>
            <Row>
              <span className="field-label">Date/time position was recorded</span>
              <DateTimePicker
                datetime={modalRecordDateTime}
                port={decimalLatLong}
                onChange={(date) => {
                  setModalRecordedDateValid(true);
                  setModalRecordDateTime(date);
                }}
                endLabel="Local time"
                showTime={true}
                showUTCTime={true}
                isValid={modalRecordedDateValid}
                errorMessage="A position recorded date is required."
              />
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="me-0" variant="primary" onClick={() => setPositionSave()}>
            OK
          </Button>
          <Button
            className="me-auto"
            variant="secondary"
            onClick={() => {
              setShowEditPositionModal(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <EditLogModal
        title="Edit Observation Data"
        page="Observation"
        userID={userCtx.user.id}
        consignmentIDs={[state.consignmentDay.consignmentID]}
        showModal={editModalShow}
        reasonOptions={editPageReasons}
        onClose={onEditModalClosed}
        onSaveSuccess={onEditModalSaveSuccess}
        onSaveFail={onEditModalSaveFailed}
        onOk={(logs) => {
          save(logs);
        }}
      />
    </LoggedInContainer>
  );
};

export default Edit_Observation;
