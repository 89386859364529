import React, { useState, useEffect } from "react";
import { Form, Stack, Row, Col, Button, Table } from "react-bootstrap";
import { getLIVEXCollectUsers, getUserTypes } from "../../api/apiAccess";
import { textIncludesSearchTerm } from "../../utils/SearchUtils";
import LIVEXCollect_Add_Edit_Modal from "./LIVEXCollect_Add_Edit_Modal";

const LIVEXCollect_Management = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [accountTypeFilter, setAccountTypeFilter] = useState("all");
    const [companyFilter, setCompanyFilter] = useState("all");
    const [permissionsFilter, setPermissionsFilter] = useState("all");
    const [exporterOptions, setExporterOptions] = useState([]);
    const [accountTypeOptions, setAccountTypeOptions] = useState([]);

    const [showNoMatchingUsers, setShowNoMatchingUsers] = useState(false);

    const fetchAllUsers = async () => {
        let lResponse = await getLIVEXCollectUsers();

        if (lResponse?.status === 200) {
            setUsers(lResponse.data);
        }
        else {
            setUsers([]);
        }
    }

    useEffect(() => {
        const fetchAllAccountTypes = async () => {
            let lResponse = await getUserTypes();

            if (lResponse?.status === 200) {
                setAccountTypeOptions(lResponse.data);
            }
            else {
                setAccountTypeOptions([]);
            }
        }

        fetchAllAccountTypes();
        fetchAllUsers();
    }, []);

    useEffect(() => {
        if (!filteredUsers || filteredUsers.length === 0) {
            setShowNoMatchingUsers(true);
        }
        else {
            setShowNoMatchingUsers(false);
        }
    }, [filteredUsers])

    // Clear selected user or modal close
    useEffect(() => {
        if (showModal === false) {
            setSelectedUser(null);
        }
    }, [showModal]);


    useEffect(() => {
        let lFilteredUsers = [...users].filter(user =>
            (accountTypeFilter === "all" || user.userType === accountTypeFilter) &&
            (companyFilter === "all" || user.exporterID === Number(companyFilter)) &&
            (permissionsFilter === "all" || (permissionsFilter === "admin" && user.isOrgAdmin) || (permissionsFilter === "standard" && user.isOrgAdmin === false)) &&
            (
                searchTerm === "" || textIncludesSearchTerm(user.firstName, searchTerm) || textIncludesSearchTerm(user.lastName, searchTerm) || textIncludesSearchTerm(user.emailAddress, searchTerm)
                || textIncludesSearchTerm(user.phoneNumber, searchTerm)
            )
        );
        setFilteredUsers(lFilteredUsers);
    }, [users, searchTerm, accountTypeFilter, companyFilter, permissionsFilter]);

    // Create exporter options
    useEffect(() => {
        let lExporterOptions = [];

        if (users && users.length > 0) {
            users.forEach(user => {
                if (user.exporterID && !lExporterOptions.find(opt => opt.ID === user.exporterID)) {
                    lExporterOptions.push({ ID: user.exporterID, Name: user.exporterName });
                }
            })
        }
        setExporterOptions(lExporterOptions);
    }, [users]);

    return (
        <>
            <Row className="bg-grey p-3 mb-4 align-items-end">
                <Col xs={12} md={4}>
                    <Stack>
                        <div className="darkblue field-label">Search</div>
                        <Form.Group>
                            <Form.Control
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                                placeholder="Type a word to search, ie name, email, phone..."
                            />
                        </Form.Group>
                    </Stack>
                </Col>
                <Col md={2}>
                    <Stack>
                        <div className="darkblue field-label">Filter By:</div>
                        <Form.Group>
                            <Form.Select value={accountTypeFilter} onChange={(e) => setAccountTypeFilter(e.target.value)}>
                                <option value="all">All Account Types</option>
                                {
                                    accountTypeOptions.map((opt, i) => {
                                        return (<option key={`type_opt_${i}`} value={opt.ID}>{opt.userType}</option>);
                                    })
                                }
                            </Form.Select>
                        </Form.Group>
                    </Stack>
                </Col>
                <Col md={2}>
                    <Form.Group>
                        <Form.Select value={companyFilter} onChange={(e) => setCompanyFilter(e.target.value)}>
                            <option value="all">All Companies</option>
                            {
                                exporterOptions.map((opt, i) => {
                                    return (<option key={`exporter_opt_${i}`} value={opt.ID}>{opt.Name}</option>);
                                })
                            }
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Form.Group>
                        <Form.Select value={permissionsFilter} onChange={(e) => setPermissionsFilter(e.target.value)}>
                            <option value="all">All Permissions</option>
                            <option value="admin">Administrator</option>
                            <option value="standard">Standard</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col md={2} className="text-end align-self-end">
                    <Button variant="primary" onClick={() => setShowModal(true)} style={{ minWidth: "150px" }}>Add New</Button>
                </Col>
            </Row>

            {
                showNoMatchingUsers === false ?
                    <Row>
                        <Col>
                            <Table className="table-grey-border-bottom">
                                <thead>
                                    <tr>
                                        <th className="table-column-heading">Name</th>
                                        <th className="table-column-heading">Account Type</th>
                                        <th className="table-column-heading">Company</th>
                                        <th className="table-column-heading">Permissions</th>
                                        <th className="table-column-heading">Access Mode</th>
                                        <th className="table-column-heading">Account Status</th>
                                        <th className="table-column-heading"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredUsers.map((user) => {
                                            return (
                                                <tr key={`user_${user.id}`}>
                                                    <td className="table-text verticle-table-cell">{`${user.firstName} ${user.lastName}`}</td>
                                                    <td className="table-text verticle-table-cell">{user.userType}</td>
                                                    <td className="table-text verticle-table-cell">{user.exporterName ? user.exporterName : ""}</td>
                                                    <td className="table-text verticle-table-cell">{user.isOrgAdmin ? "Administrator" : "Standard"}</td>
                                                    <td className="table-text verticle-table-cell">{user.canWrite ? "Full Access" : "Read-Only"}</td>
                                                    <td className="table-text verticle-table-cell">{user.isActive ? "Active" : "Inactive"}</td>
                                                    <td className="verticle-table-cell">
                                                        <Button className="btn-secondary" onClick={() => { setSelectedUser(user); setShowModal(true); }}>View</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col>
                            <div className="italic">There are no users matching the the specified criteria.</div>
                        </Col>
                    </Row>
            }

            <LIVEXCollect_Add_Edit_Modal managementUser={selectedUser} showModal={showModal} setShowModal={setShowModal} onAdd={fetchAllUsers} accountTypes={accountTypeOptions} />
        </>
    )
}

export default LIVEXCollect_Management;