export class AccreditedStockperson {
    ID;
    FirstName;
    LastName;
    EmailAddress;
    PhoneNumber;
    Password;
    PasswordSalt;
    RefreshToken;
    AccreditationNumber;
    AccreditationDateUTC;
    IsAccreditationActive;
    IsAAV;
    IsEULAAgreedTo;
    IsActive;

    constructor({id, firstName, lastName, emailAddress, phoneNumber, password, passwordSalt, refreshToken, accreditationNumber, accreditationDateUTC, isAccreditationActive, isAAV, isEULAAgreedTo, isActive}) {
        this.ID = id;
        this.FirstName = firstName;
        this.LastName = lastName;
        this.EmailAddress = emailAddress;
        this.PhoneNumber = phoneNumber;
        this.Password = password;
        this.PasswordSalt = passwordSalt;
        this.RefreshToken = refreshToken;
        this.AccreditationNumber = accreditationNumber;
        this.AccreditationDateUTC = accreditationDateUTC;
        this.IsAccreditationActive = isAccreditationActive;
        this.IsAAV = isAAV;
        this.IsEULAAgreedTo = isEULAAgreedTo;
        this.IsActive = isActive;
    }
}