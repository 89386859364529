import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../..";

export const ViewedOrAwaitingReviewIcon = ({ isViewed }) => {
    const userCtx = useContext(UserContext);
    const [visibleForUserType, setvisibleForUserType] = useState(false);

    // Only industry users can view status
    useEffect(() => {
        let lCanViewStatus = userCtx.user?.userTypeID === 1 || userCtx.user?.userTypeID === 3;
        setvisibleForUserType(lCanViewStatus);
    }, [userCtx.user?.userTypeID])

    return (
        visibleForUserType === true ?
            isViewed === true ?
                <FontAwesomeIcon icon={faCheck} className="primary-green" /> :
                <FontAwesomeIcon icon={faHourglass1} className="warning-yelllow" />
            : null
    )
};

