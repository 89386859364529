import { useEffect, useState, useRef, createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Button, Stack, Alert, Spinner } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { getDeckRecordsForConsignmentDay, getConsignmentDeckAnimals, setDeckRecordsDB, getDeckTemperatureForConsignmentDayBasic } from "../../api/apiAccess";
import { DetailedDeckRecord } from "../../classes/DetailedDeckRecord";
import { sortArrayInStandardDeckOrder } from "../../utils/ListUtils";
import { DeckRecordsAdditionalQuestions, AdditionalQuestionsSheepDeck, AdditionalQuestionsCattleDeck } from "../../classes/SheepHotSpotAdditionalQuestion";
import SheepHotSpots from "../../components/DeckRecords/SheepHotSpots";
import SheepPanting from "../../components/DeckRecords/SheepPanting";
import SheepScabbyMouth from "../../components/DeckRecords/SheepScabbyMouth";
import CattlePanting from "../../components/DeckRecords/CattlePanting";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import '../../css/deckrecords.css';
import { getEditPageReasons } from "../../api/apiAccess";
import EditLogModal from "../../components/EditLogModal";
import { UserContext } from "../..";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import { isReportEditable } from "../../utils/common";

// Create a context that cab be accessed by any child component of this page
// Allows you to access data and function that exist on this page elswhere, without having to pass it using props (which is annoying if you have nested components)
export const DeckRecordOtherContext = createContext();

// See comment in DeckRecord.js in the classes folder in regards to empty decks
const Edit_Deck_Record_Other = () => {
    const { state } = useLocation();
    const inputFormContainer = useRef();
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasData, setHasData] = useState(false);
    const [hasLoadError, setHasLoadError] = useState(false);
    const [showSaveSuccess, setShowSaveSuccess] = useState(false);
    const [hasSheep, setHasSheep] = useState(false);
    const [hasCattle, setHasCattle] = useState(false);
    const [showCattlePantingQuestion, setShowHasCattlePantingQuestion] = useState(false);
    const [data, setData] = useState(new DeckRecordsAdditionalQuestions(false, false, false, false, [], []));

    const [hasSaveError, setHasSaveError] = useState(false);
    const [triggerShowInvalid, setTriggerShowInvalid] = useState(-1);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editPageReasons, setEditPageReasons] = useState([]);

    const getFormattedData = (deckAnimals, detailedDeckRecords, deckTemperatures) => {
        if (deckAnimals && detailedDeckRecords) {
            // get only sheep and cattle decks
            let lFilteredRecords = detailedDeckRecords.filter(dr => dr.DeckRecord.speciesID === 1 || dr.DeckRecord.speciesID === 2);
            let lFilteredDeckAnimals = deckAnimals.filter(da => da.species.id === 1 || da.species.id === 2);

            let lSheepDecks = []
            let lCattleDecks = [];

            let lHasSheepPanting = false;
            let lHasSheepHotSpots = false;
            let lHasSheepScabbyMouth = false;
            let lHasCattlePanting = false;

            let lHasCattleDeckTemperatureAtOrABoveTwentyFive = false;

            lFilteredDeckAnimals.forEach(da => {
                //skip if duplicated
                if(lSheepDecks.find(d => d.ConsignmentDeck.id === da.consignmentDeck.id) !== undefined) return;

                // should always have a deck record
                let lDeckRecord = lFilteredRecords.find(dr => dr.DeckRecord.speciesID === da.species.id && dr.ConsignmentDeck.id === da.consignmentDeck.id)

                let lConverted = DetailedDeckRecord.convertDetailedDeckRecord(lDeckRecord);

                if (da.species.id === 1) { // sheep
                    let lDeckHasSheepPanting = lDeckRecord.DeckRecord.pantingScoreFourPercent !== null || lDeckRecord.DeckRecord.pantingScoreThreePercent !== null;
                    let lDeckHasSheepHotSpots = !StringIsNullUndefinedOrEmpty(lDeckRecord.DeckRecord.hotSpotDescription);
                    let lDeckHasSheepScabbyMouth = lDeckRecord.DeckRecord.scabbyMouthPercent !== null;

                    if (lDeckHasSheepPanting === true) {
                        lHasSheepPanting = true;
                    }
                    if (lDeckHasSheepHotSpots === true) {
                        lHasSheepHotSpots = true;
                    }
                    if (lDeckHasSheepScabbyMouth === true) {
                        lHasSheepScabbyMouth = true;
                    }

                    // Set nulls to empty string to avoid uncontrolled to controlled js error
                    // Note need to set back to null on save if empty string
                    if (!lConverted.deckRecord.hotSpotDescription) {
                        lConverted.deckRecord.hotSpotDescription = "";
                    }
                    if (!lConverted.deckRecord.scabbyMouthPercent) {
                        lConverted.deckRecord.scabbyMouthPercent = "";
                    }
                    if (!lConverted.deckRecord.pantingScoreTwoPercent) {
                        lConverted.deckRecord.pantingScoreTwoPercent = "";
                    }
                    if (!lConverted.deckRecord.pantingScoreFourPercent) {
                        lConverted.deckRecord.pantingScoreFourPercent = "";
                    }

                    lSheepDecks.push(new AdditionalQuestionsSheepDeck(lConverted, lDeckHasSheepHotSpots, lDeckHasSheepPanting, lDeckHasSheepScabbyMouth));
                }
                else { // cattle
                    // get deck temperature for deck
                    if (deckTemperatures !== null && deckTemperatures.length > 0) {
                        let lDeckTemp = deckTemperatures.find(dt => dt.consignmentDeckID === da.consignmentDeck.id);

                        if (lDeckTemp) {
                            if (lDeckTemp.wetBulbTemperatureMax > 24) {
                                lHasCattleDeckTemperatureAtOrABoveTwentyFive = true;
                            }
                        }
                    }

                    let lDeckHasCattlePanting = lDeckRecord.DeckRecord.pantingScoreTwoPercent !== null || lDeckRecord.DeckRecord.pantingScoreThreePercent !== null || lDeckRecord.DeckRecord.pantingScoreFourPercent !== null;

                    if (lDeckHasCattlePanting === true) {
                        lHasCattlePanting = true;
                    }

                    // Set nulls to empty string to avoid uncontrolled to controlled js error
                    // Note need to set back to null on save if empty string
                    if (!lConverted.deckRecord.pantingScoreTwoPercent) {
                        lConverted.deckRecord.pantingScoreTwoPercent = "";
                    }
                    if (!lConverted.deckRecord.pantingScoreThreePercent) {
                        lConverted.deckRecord.pantingScoreThreePercent = "";
                    }
                    if (!lConverted.deckRecord.pantingScoreFourPercent) {
                        lConverted.deckRecord.pantingScoreFourPercent = "";
                    }

                    lCattleDecks.push(new AdditionalQuestionsCattleDeck(lConverted, lDeckHasCattlePanting));
                }
            })
            // sort into standard deck order
            lSheepDecks = sortArrayInStandardDeckOrder(lSheepDecks, "ConsignmentDeck.deckName", "ConsignmentDeck.isBridge");
            lCattleDecks = sortArrayInStandardDeckOrder(lCattleDecks, "ConsignmentDeck.deckName", "ConsignmentDeck.isBridge");

            let lData = new DeckRecordsAdditionalQuestions(lHasSheepHotSpots, lHasSheepPanting, lHasSheepScabbyMouth, lHasCattlePanting, lSheepDecks, lCattleDecks);
            setData(lData);

            setHasCattle(lCattleDecks.length > 0);
            setHasSheep(lSheepDecks.length > 0);
            setShowHasCattlePantingQuestion(lHasCattlePanting === true || lHasCattleDeckTemperatureAtOrABoveTwentyFive === true);
        }
    };

    //#region Sheep Hot Spots
    const updateSheepHasHotSpots = (aHasHotSpots) => {
        let lUpdated = { ...data };

        lUpdated.sheepHotSpotsValid = true;

        if (aHasHotSpots === true) {
            lUpdated.hasSheepHotSpots = true;

            if (lUpdated.sheepDecks.some(sd => sd.hasSheepHotSpots === true) === false) {
                lUpdated.sheepHotSpotsValid = false;
            }
        }
        else {
            // If false we need to uncheck all affected decks and clear their hot spot description
            lUpdated.hasSheepHotSpots = false;
            lUpdated.sheepDecks.forEach(deck => {
                deck.hasSheepHotSpots = false;
                deck.DeckRecord.hotSpotDescription = null;
            });
        }
        setData(lUpdated);
    }

    // we need to update the underlying deck records we use to save, and the display data
    const updateSheepHotSpotDeck = (aDeckID, aIsAffected, aHotSpots, isValid) => {
        let lUpdated = { ...data };
        lUpdated.sheepHotSpotsValid = true;
        let lDeckRecord = lUpdated.sheepDecks.find(deck => deck.DeckRecord.consignmentDeckID === aDeckID);

        if (lDeckRecord) {
            if (lDeckRecord.DeckRecord.isEmpty === false) {
                lDeckRecord.hasSheepHotSpots = aIsAffected;
                lDeckRecord.isValidHotSpots = isValid;
                if (aIsAffected === false) {
                    lDeckRecord.DeckRecord.hotSpotDescription = null;
                }
                else {
                    lDeckRecord.DeckRecord.hotSpotDescription = aHotSpots;
                }
                // check if ant decks are affected
                if (lUpdated.hasSheepHotSpots === true) {
                    if (lUpdated.sheepDecks.some(sd => sd.hasSheepHotSpots === true) === false) {
                        lUpdated.sheepHotSpotsValid = false;
                    }
                }

                setData(lUpdated);
            }
        }
    }
    //#endregion

    //#region Sheep Panting
    const updateSheepHasPanting = (aHasPanting) => {
        let lUpdated = { ...data };

        lUpdated.sheepPantingValid = true;

        if (aHasPanting === true) {
            lUpdated.hasSheepPanting = true;

            if (lUpdated.sheepDecks.some(sd => sd.hasSheepPanting === true) === false) {
                lUpdated.sheepPantingValid = false;
            }
        }
        else {
            // If false we need to uncheck all affected decks and clear their panting percentages
            lUpdated.hasSheepPanting = false;
            lUpdated.sheepDecks.forEach(deck => {
                deck.hasSheepPanting = false;
                deck.DeckRecord.pantingScoreThreePercent = null;
                deck.DeckRecord.pantingScoreFourPercent = null;
            });
        }
        setData(lUpdated);
    }

    const updateSheepPantingDeck = (aDeckID, aIsAffected, aPantingScoreThreePercent, aPantingScoreFourPercent, isValid) => {
        let lUpdated = { ...data };

        lUpdated.sheepPantingValid = true;

        let lDeckRecord = lUpdated.sheepDecks.find(deck => deck.DeckRecord.consignmentDeckID === aDeckID);

        if (lDeckRecord) {
            if (lDeckRecord.DeckRecord.isEmpty === false) {
                lDeckRecord.hasSheepPanting = aIsAffected;
                lDeckRecord.isValidPanting = isValid;
                // If deck is not affected clear percentages
                if (aIsAffected === false) {
                    lDeckRecord.DeckRecord.pantingScoreThreePercent = null;
                    lDeckRecord.DeckRecord.pantingScoreFourPercent = null;
                }
                else {
                    lDeckRecord.DeckRecord.pantingScoreThreePercent = aPantingScoreThreePercent;
                    lDeckRecord.DeckRecord.pantingScoreFourPercent = aPantingScoreFourPercent;
                }

                // check if ant decks are affected
                if (lUpdated.hasSheepPanting === true) {
                    if (lUpdated.sheepDecks.some(sd => sd.hasSheepPanting === true) === false) {
                        lUpdated.sheepPantingValid = false;
                    }
                }

                setData(lUpdated);
            }
        }
    }
    //#endregion

    //#region Sheep Scabby Mouth
    const updateSheepHasScabbyMouth = (aHasScabbyMouth) => {
        let lUpdated = { ...data };

        lUpdated.sheepScabbyMouthValid = true;

        if (aHasScabbyMouth === true) {
            lUpdated.hasSheepScabbyMouth = true;

            if (lUpdated.sheepDecks.some(sd => sd.hasSheepScabbyMouth === true) === false) {
                lUpdated.sheepScabbyMouthValid = false;
            }
        }
        else {
            // If false we need to uncheck all affected decks and clear their scabby mouth percentage
            lUpdated.hasSheepScabbyMouth = false;
            lUpdated.sheepDecks.forEach(deck => {
                deck.hasSheepScabbyMouth = false;
                deck.DeckRecord.scabbyMouthPercent = null;
            });
        }
        setData(lUpdated);
    }

    const updateSheepScabbyMouthDeck = (aDeckID, aIsAffected, aScabbyMouthPercentage, isValid) => {
        let lUpdated = { ...data };

        lUpdated.sheepScabbyMouthValid = true;

        let lDeckRecord = lUpdated.sheepDecks.find(deck => deck.DeckRecord.consignmentDeckID === aDeckID);

        if (lDeckRecord) {
            if (lDeckRecord.DeckRecord.isEmpty === false) {
                lDeckRecord.hasSheepScabbyMouth = aIsAffected;
                lDeckRecord.isValidScabbyMouth = isValid;
                // If deck is not affected clear percentages
                if (aIsAffected === false) {
                    lDeckRecord.DeckRecord.scabbyMouthPercent = null;
                }
                else {
                    lDeckRecord.DeckRecord.scabbyMouthPercent = aScabbyMouthPercentage;
                }

                // check if ant decks are affected
                if (lUpdated.hasSheepScabbyMouth === true) {
                    if (lUpdated.sheepDecks.some(sd => sd.hasSheepScabbyMouth === true) === false) {
                        lUpdated.sheepScabbyMouthValid = false;
                    }
                }

                setData(lUpdated);
            }
        }
    }

    const clearScabbyMouthMissingAffectedDecksValidity = () => {
        let lUpdated = { ...data };
        lUpdated.sheepScabbyMouthValid = true;
        setData(lUpdated);
    }
    //#endregion

    //#region Cattle Panting
    const updateCattleHasPanting = (aHasPanting) => {
        let lUpdated = { ...data };

        lUpdated.cattlePantingValid = true;

        if (aHasPanting === true) {
            lUpdated.hasCattlePanting = true;

            if (lUpdated.cattleDecks.some(sd => sd.hasCattlePanting === true) === false) {
                lUpdated.cattlePantingValid = false;
            }
        }
        else {
            // If false we need to uncheck all affected decks and clear their panting percentages
            lUpdated.hasCattlePanting = false;
            lUpdated.cattleDecks.forEach(deck => {
                deck.hasCattlePanting = false;
                deck.DeckRecord.pantingScoreTwoPercent = null;
                deck.DeckRecord.pantingScoreThreePercent = null;
                deck.DeckRecord.pantingScoreFourPercent = null;
            });
        }
        setData(lUpdated);
    }

    const updateCattlePantingDeck = (aDeckID, aIsAffected, aPantingScoreTwoPercent, aPantingScoreThreePercent, aPantingScoreFourPercent, isValid) => {
        let lUpdated = { ...data };

        lUpdated.cattlePantingValid = true;

        let lDeckRecord = lUpdated.cattleDecks.find(deck => deck.DeckRecord.consignmentDeckID === aDeckID);

        if (lDeckRecord) {
            if (lDeckRecord.DeckRecord.isEmpty === false) {
                lDeckRecord.hasCattlePanting = aIsAffected;
                lDeckRecord.isValidPanting = isValid;
                // If deck is not affected clear percentages
                if (aIsAffected === false) {
                    lDeckRecord.DeckRecord.pantingScoreTwoPercent = null;
                    lDeckRecord.DeckRecord.pantingScoreThreePercent = null;
                    lDeckRecord.DeckRecord.pantingScoreFourPercent = null;
                }
                else {
                    lDeckRecord.DeckRecord.pantingScoreTwoPercent = aPantingScoreTwoPercent;
                    lDeckRecord.DeckRecord.pantingScoreThreePercent = aPantingScoreThreePercent;
                    lDeckRecord.DeckRecord.pantingScoreFourPercent = aPantingScoreFourPercent;
                }

                // check if ant decks are affected
                if (lUpdated.hasCattlePanting === true) {
                    if (lUpdated.cattleDecks.some(sd => sd.hasCattlePanting === true) === false) {
                        lUpdated.cattlePantingValid = false;
                    }
                }

                setData(lUpdated);
            }
        }
    }
    //#endregion

    useEffect(() => {
        const getData = async () => {
            let lPromises = [
                getEditPageReasons(),
                getConsignmentDeckAnimals(state.consignmentDay.consignmentId),
                getDeckRecordsForConsignmentDay(state.consignmentDay.id),
                getDeckTemperatureForConsignmentDayBasic(state.consignmentDay.id)
            ];

            Promise.all(lPromises)
                .then(response => {
                    if (response && response.every(resp => resp.status === 200)) {
                        let reasons = response[0].data;
                        setEditPageReasons(reasons);

                        let lDeckAnimals = response[1].data;
                        let lDeckRecords = response[2].data;

                        let detailedDeckRecords = [];
                        lDeckRecords.forEach(d => {
                            detailedDeckRecords.push(new DetailedDeckRecord(d));
                        })

                        detailedDeckRecords = sortArrayInStandardDeckOrder(detailedDeckRecords, "ConsignmentDeck.deckName", "ConsignmentDeck.isBridge");
                        
                        let lTemperatureRecords = response[3].data;
                        getFormattedData(lDeckAnimals, detailedDeckRecords, lTemperatureRecords);

                        return true;
                    }
                    else {
                        return false;
                    }
                }).then(result => {
                    setHasData(result);
                    setHasLoadError(!result)
                    setIsLoading(false);
                })
                .catch(error => {
                    setHasLoadError(true);
                    setIsLoading(false);
                })
        }
        getData();
    }, []);

    const goBack = () => {
        navigate(`/viewhistorydata/${state.consignmentDay.consignmentId}`, { state: { focusedTab: "other deck records", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx, state.allowEditing) } })
    }

    const validateAndShowEditModal = () => {
        // Validate
        let lIsValid = false;

        if (data.cattlePantingValid === true && data.sheepHotSpotsValid && data.sheepPantingValid === true && data.sheepScabbyMouthValid === true) {
            let lSheepDecksValid = data.sheepDecks.length === 0 || data.sheepDecks.every(sd => sd.isValidHotSpots === true && sd.isValidPanting === true && sd.isValidScabbyMouth === true);
            let lCattleDecksValid = data.cattleDecks.length === 0 || data.cattleDecks.every(cd => cd.isValidPanting === true);
            lIsValid = lSheepDecksValid && lCattleDecksValid;
        }

        if (lIsValid === true) {
            setEditModalShow(true);
        }
        else {
            // add invalid style to input controls
            setTriggerShowInvalid(triggerShowInvalid + 1);
            window.scrollTo(0, inputFormContainer.current.offsetTop);
        }
    }

    // should already be valdiated at this point
    const writeToDB = async () => {
        let lRecordsToSave = [];

        // Convert empty strings back to null
        // had to set nulls to empty string on load to avoid uncontrolled to controlled js error
        for (const sd of data.sheepDecks) {
            if (sd.DeckRecord.hotSpotDescription === "") {
                sd.DeckRecord.hotSpotDescription = null;
            }
            if (sd.DeckRecord.scabbyMouthPercent === "") {
                sd.DeckRecord.scabbyMouthPercent = null;
            }
            if (sd.DeckRecord.pantingScoreTwoPercent === "") {
                sd.DeckRecord.pantingScoreTwoPercent = null;
            }
            if (sd.DeckRecord.pantingScoreThreePercent === "") {
                sd.DeckRecord.pantingScoreThreePercent = null;
            }
            if (sd.DeckRecord.pantingScoreFourPercent === "") {
                sd.DeckRecord.pantingScoreFourPercent = null;
            }

            lRecordsToSave.push(sd.DeckRecord);
        }

        for (const sd of data.cattleDecks) {
            if (sd.DeckRecord.hotSpotDescription === "") {
                sd.DeckRecord.hotSpotDescription = null;
            }
            if (sd.DeckRecord.scabbyMouthPercent === "") {
                sd.DeckRecord.scabbyMouthPercent = null;
            }
            if (sd.DeckRecord.pantingScoreTwoPercent === "") {
                sd.DeckRecord.pantingScoreTwoPercent = null;
            }
            if (sd.DeckRecord.pantingScoreThreePercent === "") {
                sd.DeckRecord.pantingScoreThreePercent = null;
            }
            if (sd.DeckRecord.pantingScoreFourPercent === "") {
                sd.DeckRecord.pantingScoreFourPercent = null;
            }

            lRecordsToSave.push(sd.DeckRecord);
        }

        let result = await setDeckRecordsDB(lRecordsToSave);
        if (result.status === 200) {
            setShowSaveSuccess(true);
            setHasSaveError(false);
            setTimeout(() => {
                setShowSaveSuccess(false);
            }, 15000);
        }
        else {
            setShowSaveSuccess(false);
            setHasSaveError(true);
        }
    }

    const onEditModalClosed = () => {
        setEditModalShow(false);
    }

    const onEditModalSaveSuccess = async () => {
        setEditModalShow(false);
        await writeToDB();
    }

    const onEditModalSaveFailed = () => {

    }

    return (
        <LoggedInContainer>
            <DeckRecordOtherContext.Provider value={{
                data, updateSheepHasHotSpots, updateSheepHotSpotDeck,
                updateSheepHasPanting, updateSheepPantingDeck,
                updateSheepHasScabbyMouth, updateSheepScabbyMouthDeck, clearScabbyMouthMissingAffectedDecksValidity,
                updateCattleHasPanting, updateCattlePantingDeck,
                triggerShowInvalid
            }}>
                <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                    <Row className="upper-panel mx-0">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", paddingTop: "56px" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Deck Records - Other</div>
                                        <div><em>Day {state.consignmentDay.dayNumber}</em></div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Row>

                    <Container ref={inputFormContainer} className="py-4 px-5 form-container-full">
                        {
                            isLoading ?
                                <Row>
                                    <Col>
                                        <div className="text-center pt-5">
                                            <Spinner className="mx-auto" />
                                            <div className="mx-auto">Loading data...</div>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            hasData && isLoading === false ?
                                <>
                                    <Row>
                                        {
                                            hasSheep ?
                                                <Col xs={6}>
                                                    <div className="green-underline-heading">Sheep</div>
                                                    <Stack gap={4}>
                                                        <SheepHotSpots />
                                                        <SheepPanting />
                                                        <SheepScabbyMouth />
                                                    </Stack>
                                                </Col>
                                                : null
                                        }
                                        {
                                            hasCattle ?
                                                <Col xs={6}>
                                                    <div className="green-underline-heading">Cattle</div>
                                                    <Stack>
                                                        <CattlePanting showCattlePantingQuestion={showCattlePantingQuestion} />
                                                    </Stack>
                                                </Col>
                                                : null
                                        }
                                    </Row>
                                </>
                                : null
                        }
                    </Container>

                    {
                        isLoading === false && hasData === true ?
                            <Row className="w-100 pt-4" style={{ position: "relative", top: "-300px" }}>
                                {
                                    showSaveSuccess === true ?
                                        <Col xs={12}>
                                            <Container style={{ width: "874px" }}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Alert variant='success'>Deck record updated successfully.</Alert>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                        : null
                                }
                                {
                                    hasSaveError === true ?
                                        <Col xs={12}>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Alert variant='danger'>Unable to save. Please wait a few minutes an try again. If the problem persists contact administration.</Alert>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                        : null
                                }
                                <EditLogModal
                                    title="Edit other data"
                                    page="Daily Record Other"
                                    userID={userCtx.user.id}
                                    consignmentIDs={[state.consignmentDay.consignmentId]}
                                    showModal={editModalShow}
                                    reasonOptions={editPageReasons}
                                    onClose={onEditModalClosed}
                                    onSaveSuccess={onEditModalSaveSuccess}
                                    onSaveFail={onEditModalSaveFailed}
                                />
                                <Col xs={12}>
                                    <Container>
                                        <Row>
                                            <Col>
                                                <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                                                    <Button variant="secondary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>
                                                        {hasSheep || (hasCattle && showCattlePantingQuestion) ? "Cancel" : "Back"}
                                                    </Button>
                                                    {
                                                        ((hasSheep || (hasCattle && showCattlePantingQuestion)) && isReportEditable(userCtx, state.allowEditing)) ?
                                                            <ReadWriteButton writeonly={true} variant="primary" onClick={() => { validateAndShowEditModal() }} size="lg" style={{ minWidth: "150px" }}>Update</ReadWriteButton>
                                                            : null
                                                    }
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                            </Row>
                            : null
                    }

                    {
                        (hasLoadError === true || hasData === false) && isLoading === false ?
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <Alert variant='danger'>Unable to load deck record.</Alert>
                                    </Col>
                                    <Col xs={12}>
                                        <Button className="me-2 ms-auto" variant="primary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                                    </Col>
                                </Row>
                            </Container>
                            : null
                    }
                </Container>
            </DeckRecordOtherContext.Provider>
        </LoggedInContainer >
    )
};

export default Edit_Deck_Record_Other;