import { Consignment } from './Consignment';
import { Exporter } from './Exporter';
import { Vessel } from './Vessel';
import { FeedDetail } from './FeedDetail'
import { AccreditedStockperson } from './AccreditedStockperson';
import { ConsignmentDay } from './ConsignmentDay';
import { ConsignmentDestinationPort } from './ConsignmentDestinationPort';
import { ConsignmentDeparturePort } from './ConsignmentDeparturePort';
import { ConsignmentEndOfVoyageReport } from './ConsignmentEndOfVoyageReport';
import { isBefore, addDays } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export class DetailedConsignment {
    Consignment;
    Exporter;
    Vessel;
    FeedROB;
    AssignedAAV;
    AccreditedStockpeople;
    ConsignmentDays;
    DeparturePorts;
    DestinationPorts;
    EndOfVoyage;
    _isCompleted;

    constructor({consignment, exporter, vessel, feedROB, assignedAAV, accreditedStockpeople, consignmentDays, departurePorts, destinationPorts, endOfVoyage}) {
        this.Consignment = new Consignment(consignment);
        this.Exporter = new Exporter(exporter);
        this.Vessel = new Vessel(vessel);
        this.FeedROB = new FeedDetail(feedROB);
        this.AssignedAAV = assignedAAV ? new AccreditedStockperson(assignedAAV) : null;
        this.AccreditedStockpeople = accreditedStockpeople?.map(as => new AccreditedStockperson(as)) ?? [];
        this.ConsignmentDays = consignmentDays?.map(cd => new ConsignmentDay(cd)) ?? [];
        this.DeparturePorts = departurePorts?.map(dp => new ConsignmentDeparturePort(dp)) ?? [];
        this.DestinationPorts = destinationPorts?.map(dp => new ConsignmentDestinationPort(dp)) ?? []
        this.EndOfVoyage = endOfVoyage ? new ConsignmentEndOfVoyageReport(endOfVoyage) : null;
        this._isCompleted = this.EndOfVoyage && this.EndOfVoyage.IsEOVReviewed === true && 
                            this.DeparturePorts && this.DeparturePorts.filter(depport => depport.IsLoadingAndDepartureReviewed === false)?.length === 0 && 
                            this.DestinationPorts && this.DestinationPorts.filter(destport => destport.IsDischargeReviewed === false)?.length === 0;
                            
    }

    IsCompleted() {
        return this._isCompleted === true;
    }

    IsEditable() {
        // A consignment can be edited at any time until 14 days after the EndOfVoyage is completed
        // Note: If you change the number of days that a consignment is editable, you should
        // update all points in the code where IsCompletedInLastNumDays is called to use the same number
        // unless you have a good reason not to do so.
        let numDaysEditable = 14;
        if (!this.EndOfVoyage) return true;
        if (!this.EndOfVoyage.EndOfVoyageCompletedDateUTC) return true;
        let numDaysAfterEndOfVoyageCompleted = addDays(utcToZonedTime(this.EndOfVoyage.EndOfVoyageCompletedDateUTC, Intl.DateTimeFormat().resolvedOptions().timeZone), numDaysEditable)
        let beforeExpiryDate = isBefore(new Date(), numDaysAfterEndOfVoyageCompleted)
        return beforeExpiryDate;
    }


    IsCompletedInLastNumDays(numDays = 7) {
        if (!this.IsCompleted()) return false;
        let currentDate = new Date();
        let localDateCompleted = new Date(this.EndOfVoyage.EndOfVoyageCompletedDateUTC);
        localDateCompleted.setHours(0, 0, 0, 0);
        let timeDifference = currentDate.getTime() - localDateCompleted.getTime();
        if (timeDifference < 0) return false;
        let maxTimeSpanInMs = numDays * 24 * 3600 * 1000;
        let completedInTimeSpan = timeDifference < maxTimeSpanInMs;
        return completedInTimeSpan;
    }
}