import React, { useContext, useEffect, useState } from "react";
import { Container, Row, Col, Stack, Button, Modal, Alert } from "react-bootstrap";
import { parseISO, format, isDate } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { RegisteredEstablishmentConsignment } from "../../classes/RE/RegisteredEstablishmentConsignment";
import { submitREConsignmentToDepartment } from "../../api/apiAccess";
import DateTimePicker from "../DateTimePicker";
import { PlannedRegisteredConsignmentSubmission } from "../../classes/RE/PlannedRegisteredConsignmentSubmission";
import { UserContext } from "../..";
import { UserType } from "../../api/enums/EnumUserType";
import REConsignmentAnimalOverviewRow from "./REConsignmentAnimalOverviewRow";


const REConsignmentDetails = ({ consignment, consignmentExporter, species, speciesClasses, managementPlans, onConsignmentSubmitted }) => {
    const userCtx = useContext(UserContext);
    const [loadedConsignment, setLoadedConsignment] = useState({});
    const [loadedConsignmentExporter, setLoadedConsignmentExporter] = useState({});
    const [showSubmitReportModal, setShowSubmitReportModal] = useState(false);
    const [hasConsignmentSubmissionError, setHasConsignmentSubmissionError] = useState(false);
    const [consignmentSubmissionErrorMessage, setConsignmentSubmissionErrorMessage] = useState("");
    const [isLastAnimalDateValid, setIsLastAnimalDateValid] = useState(true);
    const [lastAnimalDate, setLastAnimalDate] = useState(null);
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
    const [consignmentAnimalOverviewRows, setConsignmentAnimalOverviewRows] = useState([]);

    useEffect(() => {
        if (Object.keys(consignment).length > 0 && consignment instanceof RegisteredEstablishmentConsignment) {
            setLoadedConsignment(consignment);
            setLoadedConsignmentExporter(consignmentExporter);
            setShowLoadingSpinner(false);
        }
    }, [consignment])

    useEffect(() => {
        setLoadedConsignmentExporter(consignmentExporter);
    }, [consignmentExporter])

    useEffect(() => {
        if (!loadedConsignment || Object.keys(loadedConsignment).length === 0) return;
        if (!species || !Array.isArray(species) || species?.length === 0) return;
        if (!speciesClasses || !Array.isArray(speciesClasses) || speciesClasses?.length === 0) return;
        if (!managementPlans || !Array.isArray(managementPlans) || managementPlans?.length === 0) return;

        buildAnimalOverviewRows();

    }, [loadedConsignment, species, speciesClasses, managementPlans])

    const buildAnimalOverviewRows = () => {
        let rows = [];
        loadedConsignment.ConsignmentAnimals.forEach((animal, animalIdx) => {
            let speciesName = species.find(sp => sp.id === animal.SpeciesID).speciesName;
            let className = speciesClasses.find(cl => cl.id === animal.ClassID).className;
            let animalCount = animal.NumberOfStock;
            let managementPlanNames = loadedConsignment.ConsignmentManagementPlans.filter(cmp => cmp.RegisteredEstablishmentConsignmentAnimalID === animal.ID).map(smp => managementPlans.find(mp => mp.id === smp.ManagementPlanID)?.managementPlanName);
            let key = `animal_overview_row_${animalIdx}`
            rows.push(<REConsignmentAnimalOverviewRow speciesName={speciesName} speciesClassName={className} animalCount={animalCount} managementPlanNames={managementPlanNames} key={key} identifier={key} />)
        })
        setConsignmentAnimalOverviewRows(rows);
    }

    const showDepartmentSubmissionModal = () => {
        setHasConsignmentSubmissionError(false);
        setShowSubmitReportModal(true)
    }

    const hideDepartmentSubmissionModal = () => {
        setShowSubmitReportModal(false);
    }

    const submitConsignmentToDepartment = async () => {
        setHasConsignmentSubmissionError(false);
        if (validateLastAnimalDate()) {
            let submission = new PlannedRegisteredConsignmentSubmission(loadedConsignment.ID, new Date(), lastAnimalDate);
            let results = await submitREConsignmentToDepartment(submission);
            if (results.status === 200) {
                let returnedConsignment = new RegisteredEstablishmentConsignment(results.data);
                setLoadedConsignment(returnedConsignment);
                hideDepartmentSubmissionModal();
                onConsignmentSubmitted(true);
            } else {
                setConsignmentSubmissionErrorMessage("An error occurred during consignment submission.  Your consignment has not been submitted.  Please try again later.")
                setHasConsignmentSubmissionError(true);
            }
        }
    }

    const validateLastAnimalDate = () => {
        if (!isDate(lastAnimalDate)) {
            setIsLastAnimalDateValid(false);
            return false;
        }
        return true;
    }

    return (
        <>
            <Container fluid className="px-0 mx-0">
                <Row>
                    <Col xs={12}>
                        <table className="table consignment-table">
                            <tr style={{ height: "1px" }} className="dashboard-consignment-header-row">
                                <td className="text-center" style={{ width: "20%" }}>
                                    <div>
                                        Exporter
                                    </div>
                                </td>
                                {
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ? (
                                        <td className="text-center" style={{ width: "12%" }}>
                                            <div>
                                                Last departed date
                                            </div>
                                        </td>
                                    ) : null
                                }
                                <td className="text-center" style={{ width: "14%" }}>
                                    <div className="m-0 p-0">
                                        Totals listed
                                    </div>
                                </td>
                                {
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ? null: (
                                        <td className="text-center" style={{ width: "13%" }}>
                                            <div className="m-0 p-0">
                                                Last departed date
                                            </div>
                                        </td>
                                    )
                                }
                                <td className="text-center" style={{ width: "14%" }}>
                                    <div className="m-0 p-0">
                                        Reporting status
                                    </div>
                                </td>
                                {!loadedConsignment.IsSubmitted && userCtx.user?.userTypeID === UserType.INDUSTRY ? (
                                    <td className="text-center" style={{ width: "25%" }}>
                                        <div className="m-0 p-0">
                                            Action
                                        </div>
                                    </td>
                                ) : null}

                            </tr>
                            <tr>
                                <td className="text-center">
                                    <div>{loadedConsignmentExporter?.ExporterName}</div>
                                </td>
                                {
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ? (
                                        <td className="text-center">
                                            <div>{loadedConsignment?.LastAnimalDepartureDate ? format(parseISO(loadedConsignment.LastAnimalDepartureDate), "do MMMM yyyy") : ""}</div>
                                        </td>
                                    ) : null
                                }
                                <td className="text-center">
                                    <div>
                                        <Container fluid>
                                            <Row>
                                                <Col xs={12}>
                                                    <Stack direction="vertical">
                                                        <div>{loadedConsignment?.ConsignmentMortalities?.filter(m => m && m.ID !== 0)?.length}</div>
                                                        <div>{loadedConsignment?.ConsignmentMortalities?.filter(m => m && m.ID !== 0)?.length === 1 ? "Mortality" : "Mortalities"}</div>
                                                    </Stack>
                                                </Col>
                                                { /*
                                                    TEMPORARY - HIDE THE REJECTIONS COUNT
                                                    THIS WILL BE REINTRODUCED LATER SO DON'T DELETE --- 25/09/2023 (Mark) SEE LX-806

                                                    <Col xs={6}>
                                                        <Stack direction="vertical">
                                                            <div>{loadedConsignment?.ConsignmentRejections?.reduce((a,b) =>  a + Number(b.NumberOfStock), 0)}</div>
                                                            <div>{loadedConsignment?.ConsignmentRejections?.reduce((a,b) =>  a + Number(b.NumberOfStock), 0) === 1 ? "Rejection" : "Rejections"}</div>
                                                        </Stack>
                                                    </Col>
                                                */
                                                }
                                            </Row>
                                        </Container>
                                    </div>
                                </td>
                                {
                                    userCtx.user?.userTypeID !== UserType.REGULATORY ? null: (
                                        <td className="text-center">
                                            <div>{loadedConsignment?.LastAnimalDepartureDate ? format(parseISO(loadedConsignment.LastAnimalDepartureDate), "do MMMM yyyy") : ""}</div>
                                        </td>
                                    )
                                }
                                <td>
                                    {loadedConsignment.IsSubmitted ?
                                        (
                                            <Stack direction="vertical">
                                                <Stack direction="horizontal" gap={1} style={{ display: "flex", justifyContent: "center" }}>
                                                    <FontAwesomeIcon icon={faCheckCircle} color="green" />
                                                    <div>Submitted</div>
                                                </Stack>
                                                {
                                                    loadedConsignment.DateSubmitted ? (
                                                        <div className="text-center" style={{ fontSize: "12px" }}>{format(parseISO(loadedConsignment.DateSubmitted), "dd/MM/yyyy hh:mmaa")}</div>
                                                    ) : null
                                                }
                                            </Stack>
                                        ) :
                                        (
                                            <Stack direction="horizontal" gap={1} style={{ display: "flex", justifyContent: "center" }}>
                                                <FontAwesomeIcon icon={faXmarkCircle} color="red" />
                                                <div>Not submitted</div>
                                            </Stack>

                                        )
                                    }
                                </td>
                                {
                                    !loadedConsignment.IsSubmitted && userCtx.user?.userTypeID === UserType.INDUSTRY ? (
                                        <td className="text-center">
                                            <Button
                                                className="btn btn-sm btn-primary my-3"
                                                onClick={showDepartmentSubmissionModal}
                                            >
                                                Submit to Department
                                            </Button>
                                        </td>
                                    ) : null
                                }
                            </tr>
                        </table>
                    </Col>
                </Row>
                <Row>
                    {
                        consignmentAnimalOverviewRows.length > 0 ? (

                            <table className="table consignment-table">
                                <tr style={{ height: "1px" }} className="dashboard-consignment-header-row">
                                    <td className="text-center" style={{ width: "20%" }}>
                                        <div>
                                            Species
                                        </div>
                                    </td>
                                    <td className="text-center" style={{ width: "20%" }}>
                                        <div>
                                            Class
                                        </div>
                                    </td>
                                    <td className="text-center" style={{ width: "20%" }}>
                                        <div className="m-0 p-0">
                                            No. Livestock
                                        </div>
                                    </td>
                                    <td className="text-center" style={{ width: "40%" }}>
                                        <div className="m-0 p-0">
                                            Applicable Management Plans
                                        </div>
                                    </td>
                                </tr>
                                {
                                    consignmentAnimalOverviewRows.map(row => row)
                                }
                            </table>

                        ) : null

                    }
                </Row>

                {
                    showSubmitReportModal ?
                        (
                            <Modal
                                show={true}
                                animation={false}
                                centered={true}
                            >
                                <Modal.Header className="heading-text">Submit Report</Modal.Header>
                                <Modal.Body>
                                    <Container>
                                        <Row>
                                            <Col xs={12} className="field-label">
                                                Consignment
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col xs={12}>
                                                LNC-{loadedConsignment?.LNCNumber}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="field-label">
                                                Date of departure of the last animal in the consignment
                                            </Col>
                                        </Row>
                                        <Row className="mb-4">
                                            <Col xs={12}>
                                                <DateTimePicker
                                                    datetime={lastAnimalDate}
                                                    isValid={isLastAnimalDateValid}
                                                    errorMessage={"The date field must be filled out with a valid date"}
                                                    showHeadLabel={false}
                                                    showTime={false}
                                                    showUTCTime={false}
                                                    onChange={(date) => {
                                                        setIsLastAnimalDateValid(true);
                                                        setLastAnimalDate(date);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="field-label mb-1">
                                                The following will be reported:
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <Stack direction="horizontal" style={{ border: "1px solid #EAEAEA", borderRadius: "5px" }}>
                                                    <Stack directon="vertical" className="py-3" style={{ borderRight: "1px solid #EAEAEA" }}>
                                                        <div className="text-center heading-text">{loadedConsignment?.ConsignmentMortalities.length}</div>
                                                        <div className="text-center field-label">Mortalities</div>
                                                    </Stack>
                                                    { /*
                                                        <Stack directon="vertical"  className="py-3" >
                                                            <div className="text-center heading-text">{loadedConsignment?.ConsignmentRejections.length}</div>
                                                            <div className="text-center field-label">Rejections</div>
                                                        </Stack>
                                                    */
                                                    }
                                                </Stack>
                                            </Col>
                                        </Row>
                                        {
                                            hasConsignmentSubmissionError ? (
                                                <Alert variant="danger" className="mt-3">
                                                    {consignmentSubmissionErrorMessage}
                                                </Alert>
                                            ) : null
                                        }
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer style={{ justifyContent: "start" }}>
                                    <Stack direction="horizontal" gap={3}>
                                        <Button className="btn btn-primary" onClick={submitConsignmentToDepartment}>Submit</Button>
                                        <Button className="btn btn-secondary" onClick={hideDepartmentSubmissionModal}>Close</Button>
                                    </Stack>

                                </Modal.Footer>

                            </Modal>
                        ) : null
                }
            </Container>
        </>
    )
}

export default REConsignmentDetails;