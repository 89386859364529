export class UserCredentials {
    emailAddress = "";
    password = "";
    authenticationMethod = ""
    constructor({emailAddress, password, authenticationMethod} = {}) {
        this.emailAddress = emailAddress;
        this.password = password;
        this.authenticationMethod = authenticationMethod;
    }

    IsValid = () => {
        return this.emailAddress.length > 0 && this.password.length > 0 && this.AcceptableAuthMethods().includes(this.authenticationMethod);
    }

    AcceptableAuthMethods = () => {
        return ["SMSMe", "EmailMe"];
    }
}