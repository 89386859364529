import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/app.css';
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import LoggedOutContainer from '../layout/LoggedOutContainer';
import { Container, Row, Col, Stack, Form, Button, Alert } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import { isValidEmail } from '../utils/InputValidator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { sendPasswordResetEmail } from '../api/apiAccess';

const Request_New_Password = () => {
    const navigate = useNavigate();
    const [emailAddress, setEmailAddress] = useState("");
    const [validEmail, setValidEmail] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);

    const reset = () => {
        setShowSuccess(false);

        const sendEmail = async () => {
            let result = await sendPasswordResetEmail(emailAddress);
            setShowSuccess(true);
        }

        if (isValidEmail(emailAddress) === true) {
            setValidEmail(true);
            sendEmail();
        }
        else {
            setValidEmail(false);
        }
    }

    return (
        <LoggedOutContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => navigate("/")} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Request New Password</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row className="mb-4">
                        <Col>
                            Enter your email address and if we can find it in the system we will send you an email with a link to reset your password.
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col xs={12}>
                            <Stack>
                                <div className="darkblue field-label">Email Address</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtEmail`}
                                        onChange={(e) => {
                                            setEmailAddress(e.target.value);
                                            setValidEmail(true);
                                        }}
                                        value={emailAddress}
                                        isInvalid={validEmail === false}
                                    />
                                    {
                                        validEmail === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtEmail`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid email address is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    {
                        showSuccess ?
                            <Row>
                                <Col>
                                    <Alert variant="success">If the email address exists in the system you will receive a password reset email.</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant='primary' onClick={() => reset()}>Reset</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </LoggedOutContainer>
    )
};

export default Request_New_Password;