export class PlannedRegisteredEstablishmentLivestock {
    SpeciesID;
    SpeciesClassID;
    NumberOfHead;
    ManagementPlanIDs;
    constructor(speciesID, speciesClassID, numberOfHead, managementPlanIDs) {
        this.SpeciesID = speciesID;
        this.SpeciesClassID = speciesClassID;
        this.NumberOfHead = numberOfHead;
        this.ManagementPlanIDs = managementPlanIDs ? managementPlanIDs : [];
    }
}