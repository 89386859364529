import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useRef } from "react";
import {  Row, Col, InputGroup, Form, Button, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { NumericValidationRules, parseNumericInput } from "../../utils/InputValidator";
import { Tooltip } from "react-tooltip";
import ReadWriteInputGroup from '../ReadWriteElements/ReadWriteInputGroup';


const DeckTemperatureRecord = ({deck, temperatures, requestValidation, onChangeData, valid}) => {
    // Refs
    const maxDryBulbField = useRef(null);
    const minDryBulbField = useRef(null);
    const maxWetBulbField = useRef(null);
    const minWetBulbField = useRef(null);
    const maxRelHumField = useRef(null);
    const minRelHumField = useRef(null);

    // UI State
    const [warningDryBulb, setWarningDryBulb] = useState(false);
    const [warningWetBulb, setWarningWetBulb] = useState(false);
    const [isMaxDryBulbValid, setIsMaxDryBulbValid] = useState(true);
    const [isMinDryBulbValid, setIsMinDryBulbValid] = useState(true);
    const [isMaxWetBulbValid, setIsMaxWetBulbValid] = useState(true);
    const [isMinWetBulbValid, setIsMinWetBulbValid] = useState(true);
    const [isMaxRelHumValid, setIsMaxRelHumValid] = useState(true);
    const [isMinRelHumValid, setIsMinRelHumValid] = useState(true);
    const [minDryBulbErrors, setMinDryBulbErrors] = useState([]);
    const [maxDryBulbErrors, setMaxDryBulbErrors] = useState([]);
    const [minWetBulbErrors, setMinWetBulbErrors] = useState([]);
    const [maxWetBulbErrors, setMaxWetBulbErrors] = useState([]);
    const [minRelHumErrors, setMinRelHumErrors] = useState([]);
    const [maxRelHumErrors, setMaxRelHumErrors] = useState([]);
        
    // Data state
    const [maxDryBulbValue, setMaxDryBulbValue] = useState("");
    const [minDryBulbValue, setMinDryBulbValue] = useState("");
    const [maxWetBulbValue, setMaxWetBulbValue] = useState("");
    const [minWetBulbValue, setMinWetBulbValue] = useState("");
    const [maxRelHumValue, setMaxRelHumValue] = useState("");
    const [minRelHumValue, setMinRelHumValue] = useState("");

    const [isEmptyDeck, setIsEmptyDeck] = useState(false);
    const [emptyDeckTrigger, setEmptyDeckTrigger] = useState(0);

    // Constants and rules
    const numericValidationRules = new NumericValidationRules({
        allowDecimal: true,
        allowNegative: true,
        allowLeadingZerosForWholeNumbers: false,
        allowSingleZero: true,
        minRange: -99,
        maxRange: 99
    });

    // Effects
    useEffect(() => {
        if (temperatures) {
            setMaxDryBulbValue(`${temperatures.dryBulbTemperatureMax}`);
            setMinDryBulbValue(`${temperatures.dryBulbTemperatureMin}`);
            setMaxWetBulbValue(`${temperatures.wetBulbTemperatureMax}`);
            setMinWetBulbValue(`${temperatures.wetBulbTemperatureMin}`);
            setMaxRelHumValue(`${temperatures.relativeHumidityMax}`);
            setMinRelHumValue(`${temperatures.relativeHumidityMin}`);
            let emptyDeck = deckIsEmpty(temperatures); 
            setIsEmptyDeck(emptyDeck);
        }
    }, [temperatures]);

    useEffect(() => {
        const highlightInvalidFields = () => {
            if (isEmptyDeck === true) return true;

            let tempMaxDryBulbErrors = [];
            let tempMinDryBulbErrors = [];
            let tempMaxWetBulbErrors = [];
            let tempMinWetBulbErrors = [];
            let tempMaxRelHumErrors = [];
            let tempMinRelHumErrors = [];

            if (!maxDryBulbValue || maxDryBulbValue.length === 0) {
                let error = "A temperature value is required.";
                tempMaxDryBulbErrors.push(error);
            }

            if (Number(maxWetBulbValue) > Number(maxDryBulbValue)) {
                let error = "Max wet bulb temperature can not be higher than max dry bulb temperature.";
                tempMaxDryBulbErrors.push(error);
                tempMaxWetBulbErrors.push(error);
            }

            if (Number(minWetBulbValue) > Number(maxDryBulbValue)) {
                let error = "Min wet bulb temperature can not be higher than max dry bulb temperature."
                tempMaxDryBulbErrors.push(error);
                tempMinWetBulbErrors.push(error);
            }

            if (!minDryBulbValue || minDryBulbValue.length === 0) {
                let error = "A temperature value is required.";
                tempMinDryBulbErrors.push(error);
            }

            if (Number(minWetBulbValue) > Number(minDryBulbValue)) {
                let error = "Min wet bulb temperature can not be higher than min dry bulb temperature."
                tempMinDryBulbErrors.push(error);
                tempMinWetBulbErrors.push(error);
            }

            if (!maxRelHumValue || maxRelHumValue.length === 0) {
                let error = "A relative humidity value is required.";
                tempMaxRelHumErrors.push(error);
            }

            if (!minRelHumValue || minRelHumValue.length === 0) {
                let error = "A relative humidity value is required.";
                tempMinRelHumErrors.push(error);
            }

            let maxDryBulbValid = tempMaxDryBulbErrors.length === 0;
            let minDryBulbValid = tempMinDryBulbErrors.length === 0;
            let maxWetBulbValid = tempMaxWetBulbErrors.length === 0;
            let minWetBulbValid = tempMinWetBulbErrors.length === 0;
            let maxRelHumValid = tempMaxRelHumErrors.length === 0;
            let minRelHumValid = tempMinRelHumErrors.length === 0;
            setMaxDryBulbErrors(tempMaxDryBulbErrors);
            setMinDryBulbErrors(tempMinDryBulbErrors);
            setMaxWetBulbErrors(tempMaxWetBulbErrors);
            setMinWetBulbErrors(tempMinWetBulbErrors);
            setMaxRelHumErrors(tempMaxRelHumErrors);
            setMinRelHumErrors(tempMinRelHumErrors);
            setIsMaxDryBulbValid(maxDryBulbValid);
            setIsMinDryBulbValid(minDryBulbValid);
            setIsMaxWetBulbValid(maxWetBulbValid);
            setIsMinWetBulbValid(minWetBulbValid);
            setIsMaxRelHumValid(maxRelHumValid);
            setIsMinRelHumValid(minRelHumValid);
            return maxDryBulbValid && minDryBulbValid && maxWetBulbValid && minWetBulbValid && maxRelHumValid && minRelHumValid;
        }
        
        if (requestValidation) {
            let valid = highlightInvalidFields();
            deck.isValid = valid;
        }
    }, [requestValidation]);

    useEffect(() => {
        const calculateRelativeHumidity = (Tdb, Twb) => {
            if (Tdb === undefined || Tdb === null || Twb === undefined || Twb === null || Tdb.toString().length === 0 || Twb.toString().length === 0) return "";
            const wT = Number(Twb);
            const dT = Number(Tdb);
            const e = 2.71828;
            const N = .66875;
            const eD = 6.112 * Math.pow(e, ((17.502 * dT) / (240.97 + dT)));
            const eW = 6.112 * Math.pow(e, ((17.502 * wT)/ (240.97 + wT)));
            let rH = ((eW - N * (1 + (0.00115 * wT)) * (dT - wT)) / eD) * 100;
            if (rH < 0) rH = 0;
            if (rH > 100) rH = 100;
            return rH.toFixed(1);
          } 
        setMaxRelHumValue(calculateRelativeHumidity(maxDryBulbValue, maxWetBulbValue));
        setMinRelHumValue(calculateRelativeHumidity(minDryBulbValue, minWetBulbValue));
    }, [maxDryBulbValue, minDryBulbValue, maxWetBulbValue, minWetBulbValue]);

    useEffect(() => {
        notifyDataChange();
    }, [maxDryBulbValue, minDryBulbValue, maxWetBulbValue, minWetBulbValue, maxRelHumValue, minRelHumValue]);

    useEffect(() => {
        if (emptyDeckTrigger === 0) return;
        if (isEmptyDeck === true) {
            setMaxDryBulbValue('null');
            setMinDryBulbValue('null');
            setMaxWetBulbValue('null');
            setMinWetBulbValue('null');    
        } else {
            setMaxDryBulbValue("0");
            setMinDryBulbValue("0");
            setMaxWetBulbValue("0");
            setMinWetBulbValue("0");
        }
    }, [emptyDeckTrigger])

    const deckIsEmpty = (temps) => {
        const HasNoValue = (val) => { return val === undefined || val === 'undefined' || val === null || val === 'null' || isNaN(val) || val === 'NaN';}
        return  HasNoValue(temps?.dryBulbTemperatureMax) && 
                HasNoValue(temps?.dryBulbTemperatureMin) && 
                HasNoValue(temps?.wetBulbTemperatureMax)  && 
                HasNoValue(temps?.wetBulbTemperatureMin) &&
                HasNoValue(temps?.relativeHumidityMax) &&
                HasNoValue(temps?.relativeHumidityMin);
    }

    // Non-effect functions
    const generateWarnings = (mustBeLowerRef, mustBeHigherRef, updateWarningStateFn) => {
        if (mustBeLowerRef.current?.value === "" || mustBeHigherRef.current?.value === "") {
            updateWarningStateFn(false);
            return;
        }
        updateWarningStateFn(Number(mustBeLowerRef.current?.value) > Number(mustBeHigherRef.current?.value));
    }

    const notifyDataChange = () => {
        temperatures.dryBulbTemperatureMax = maxDryBulbValue;
        temperatures.dryBulbTemperatureMin = minDryBulbValue;
        temperatures.wetBulbTemperatureMax = maxWetBulbValue;
        temperatures.wetBulbTemperatureMin = minWetBulbValue;
        temperatures.relativeHumidityMax = maxRelHumValue;
        temperatures.relativeHumidityMin = minRelHumValue;
        onChangeData(temperatures);
    }

    const formatErrors = (key, errorList) => {
        
        return (
            <div>
            {  
                errorList && errorList.map((error, index) => (<div key={`${key}_${index}`}>{error}</div>))
            }
            </div>
        )
    }

    const makeAttributeSafe = (text) => {
        return text.replaceAll(/\s/g,'');
    }

    // Render
    return (
        <Form as={Row} className="border-bottom-separator" style={{backgroundColor: deck.shading}} noValidate autoComplete="off">
            <Col xs={3}>
                <div className="d-flex h-100 align-items-left">
                    <Stack direction="vertical">
                        <div className="heading-text">{deck.name}</div>
                        { /*
                        <div 
                            className="green-link"
                            onClick={() => {
                                setIsEmptyDeck(!isEmptyDeck);
                                setEmptyDeckTrigger(emptyDeckTrigger + 1);
                            }}
                        >
                            {isEmptyDeck ? "Restore deck" : "Empty deck" }
                        </div>
                        */}
                    </Stack>                    
                </div>
            </Col>
            <Col xs={3}>
                <Row className="py-2">
                    <Col xs={10}>    
                        <Row className="py-1">
                            <Col xs={2}>    
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Max</div>
                                </div>
                            </Col>

                            <Form.Group as={Col} xs={10} id={`max-dry-bulb-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={maxDryBulbValue === "null"}
                                        isInvalid={!isMaxDryBulbValid}
                                        ref={maxDryBulbField}
                                        aria-label="MaxTempDryBulb"
                                        aria-describedby="max-temp-dry-bulb"
                                        onFocus={() => {
                                            if (Number(maxDryBulbValue) === 0) {
                                                setMaxDryBulbValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (maxDryBulbValue == "") {
                                                setMaxDryBulbValue("0");
                                            }
                                        }}
                                        onChange={(e) => {
                                            parseNumericInput(e, numericValidationRules)
                                            if (e.target.value.length > 0) {
                                                setIsMaxDryBulbValid(true);
                                            }
                                            setMaxDryBulbValue(e.target.value);
                                            generateWarnings(minDryBulbField, maxDryBulbField, setWarningDryBulb);
                                        }}
                                        value={maxDryBulbValue !== "null" ? maxDryBulbValue : "-"}                                        
                                    />
                                    <InputGroup.Text id="max-temp-dry-bulb">&#176;C</InputGroup.Text>
                                    {!isMaxDryBulbValid ? (
                                            <Tooltip
                                                anchorSelect={`#max-dry-bulb-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`maxDryBulbErrors_${makeAttributeSafe(deck.name)}`, maxDryBulbErrors)}
                                                style={{zIndex: 100, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>

                        </Row>
                        <Row className="py-1">
                            <Col xs={2}>    
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Min</div>
                                </div>
                            </Col>
                            <Form.Group as={Col} xs={10} id={`min-dry-bulb-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={minDryBulbValue === "null"}
                                        isInvalid={!isMinDryBulbValid}
                                        ref={minDryBulbField}
                                        aria-label="MinTempDryBulb"
                                        aria-describedby="min-temp-dry-bulb"
                                        onFocus={() => {
                                            if (Number(minDryBulbValue) === 0) {
                                                setMinDryBulbValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (minDryBulbValue === "") {
                                                setMinDryBulbValue("0");
                                            }
                                        }}
                                        onChange={(e) => {
                                            parseNumericInput(e);
                                            if (e.target.value.length > 0) {
                                                setIsMinDryBulbValid(true);
                                            }
                                            setMinDryBulbValue(e.target.value);
                                            generateWarnings(minDryBulbField, maxDryBulbField, setWarningDryBulb);
                                        }}
                                        value={minDryBulbValue !== "null" ? minDryBulbValue : "-"}
                                    />
                                    <InputGroup.Text id="min-temp-dry-bulb">&#176;C</InputGroup.Text>
                                    {!isMinDryBulbValid ? (
                                            <Tooltip
                                                anchorSelect={`#min-dry-bulb-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`minDryBulbErrors_${makeAttributeSafe(deck.name)}`, minDryBulbErrors)}
                                                style={{zIndex: 10, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>
                        </Row>
                    </Col>
                    <Col xs={2} style={{verticalAlign: 'middle', lineHeight: '6'}}>
                        {warningDryBulb ? (
                            <>
                                <FontAwesomeIcon id={`warning_dry_bulb_${makeAttributeSafe(deck.name)}`} color="#FF9900" style={{fontSize: '30px'}} icon={faCircleExclamation} />
                                <Tooltip
                                    anchorSelect={`#warning_dry_bulb_${makeAttributeSafe(deck.name)}`}
                                    place="top"
                                    variant="warning"
                                    content="Min temperatures should not be higher than max temperatures."
                                    style={{zIndex: 10, borderRadius: '8px', opacity: 1, width: '150px', lineHeight: '1.5'}}
                                />
                            </>
                        ):null
                        }
                    </Col>
                </Row>
            </Col>
            <Col xs={3}>
                <Row className="py-2">
                    <Col xs={10}>
                        <Row className="py-1">
                            <Col xs={2}>    
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Max</div>
                                </div>
                            </Col>
                            <Form.Group as={Col} xs={10} id={`max-wet-bulb-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={maxWetBulbValue === "null"}
                                        isInvalid={!isMaxWetBulbValid}
                                        ref={maxWetBulbField}
                                        aria-label="MaxTempWetBulb"
                                        aria-describedby="max-temp-wet-bulb"
                                        onFocus={() => {
                                            if (Number(maxWetBulbValue) === 0) {
                                                setMaxWetBulbValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (maxWetBulbValue === "") {
                                                setMaxWetBulbValue("0");
                                            }
                                        }}
                                        onChange={(e) =>  {
                                            parseNumericInput(e);
                                            if (e.target.value.length > 0) {
                                                setIsMaxWetBulbValid(true);
                                            }
                                            setMaxWetBulbValue(e.target.value);
                                            generateWarnings(minWetBulbField, maxWetBulbField, setWarningWetBulb);
                                        }}
                                        value={maxWetBulbValue !== "null" ? maxWetBulbValue : "-"}
                                    />
                                    <InputGroup.Text id="max-temp-wet-bulb">&#176;C</InputGroup.Text>
                                    {!isMaxWetBulbValid ? (
                                            <Tooltip
                                                anchorSelect={`#max-wet-bulb-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`maxWetBulbErrors_${makeAttributeSafe(deck.name)}`, maxWetBulbErrors)}
                                                style={{zIndex: 10, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="py-1">
                            <Col xs={2}>    
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Min</div>
                                </div>
                            </Col>
                            <Form.Group as={Col} xs={10} id={`min-wet-bulb-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={minWetBulbValue === "null"}
                                        isInvalid={!isMinWetBulbValid}
                                        ref={minWetBulbField}
                                        aria-label="MinTempWetBulb"
                                        aria-describedby="min-temp-wet-bulb"
                                        onFocus={() => {
                                            if (Number(minWetBulbValue) === 0) {
                                                setMinWetBulbValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (minWetBulbValue === "") {
                                                setMinWetBulbValue("0");
                                            }
                                        }}
                                        onChange={(e) => {
                                            parseNumericInput(e);
                                            if (e.target.value.length > 0) {
                                                setIsMinWetBulbValid(true);
                                            }
                                            setMinWetBulbValue(e.target.value);
                                            generateWarnings(minWetBulbField, maxWetBulbField, setWarningWetBulb);
                                        }}
                                        value={minWetBulbValue !== "null" ? minWetBulbValue : "-"}
                                    />
                                    <InputGroup.Text id="min-temp-wet-bulb">&#176;C</InputGroup.Text>
                                    {!isMinWetBulbValid ? (
                                            <Tooltip
                                                anchorSelect={`#min-wet-bulb-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`minWetBulbErrors_${makeAttributeSafe(deck.name)}`, minWetBulbErrors)}
                                                style={{zIndex: 10, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>
                        </Row>    
                    </Col>
                    <Col xs={2} style={{verticalAlign: 'middle', lineHeight: '6'}}>
                        {warningWetBulb ? (
                            <>
                                <FontAwesomeIcon id={`warning_wet_bulb_${makeAttributeSafe(deck.name)}`} color="#FF9900" style={{fontSize: '30px'}} icon={faCircleExclamation} />
                                <Tooltip
                                    anchorSelect={`#warning_wet_bulb_${makeAttributeSafe(deck.name)}`}
                                    place="top"
                                    variant="warning"
                                    content="Min temperatures should not be higher than max temperatures."
                                    style={{zIndex: 10, borderRadius: '8px', opacity: 1, width: '150px', lineHeight: '1.5'}}
                                />
                            </>
                        ):null
                        }
                    </Col>
                </Row>
            </Col>
            <Col xs={3}>
                <Row className="py-2">
                    <Col xs={10}>
                        <Row className="py-1">
                            <Col xs={2}>    
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Max</div>
                                </div>
                            </Col>
                            <Form.Group as={Col} xs={10} id={`max-rel-hum-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={maxRelHumValue === "NaN"}
                                        isInvalid={!isMaxRelHumValid}
                                        ref={maxRelHumField}
                                        aria-label="MaxRelativeHumidity"
                                        aria-describedby="max-rel-humidity"
                                        onFocus={() => {
                                            if (Number(maxRelHumValue) === 0) {
                                                setMaxRelHumValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (maxRelHumValue === "") {
                                                setMaxRelHumValue("0");
                                            }
                                        }}
                                        onChange={(e) => {
                                            parseNumericInput(e);
                                            if (e.target.value.length > 0) {
                                                setIsMaxRelHumValid(true);
                                            }
                                            setMaxRelHumValue(e.target.value);
                                        }}
                                        value={maxRelHumValue !== "NaN" ? maxRelHumValue : "-"}
                                    />
                                    <InputGroup.Text id="max-rel-humidity">%</InputGroup.Text>
                                    {!isMaxRelHumValid ? (
                                            <Tooltip
                                                anchorSelect={`#max-rel-hum-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`maxRelHumErrors_${makeAttributeSafe(deck.name)}`, maxRelHumErrors)}
                                                style={{zIndex: 10, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>
                        </Row>
                        <Row className="py-1">
                            <Col xs={2}>
                                <div className="d-flex h-100 align-items-center">
                                    <div className='field-label'>Min</div>
                                </div>
                            </Col>
                            <Form.Group as={Col} xs={10} id={`min-rel-hum-form-group_${makeAttributeSafe(deck.name)}`}>
                                <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                    <Form.Control
                                        disabled={minRelHumValue === "NaN"}
                                        isInvalid={!isMinRelHumValid}
                                        ref={minRelHumField}
                                        aria-label="MinRelativeHumidity"
                                        aria-describedby="min-rel-humiditiy"
                                        onFocus={() => {
                                            if (Number(minRelHumValue) === 0) {
                                                setMinRelHumValue("");
                                            }
                                        }}
                                        onBlur={() => {
                                            if (minRelHumValue === "") {
                                                setMinRelHumValue("0");
                                            }
                                        }}
                                        onChange={(e) => {
                                            parseNumericInput(e);
                                            if (e.target.value.length > 0) {
                                                setIsMinRelHumValid(true);
                                            }
                                            setMinRelHumValue(e.target.value);
                                        }}
                                        value={minRelHumValue !== "NaN" ? minRelHumValue : "-"}
                                    />
                                    <InputGroup.Text id="min-rel-humidity">%</InputGroup.Text>
                                    {!isMinRelHumValid ? (
                                            <Tooltip
                                                anchorSelect={`#min-rel-hum-form-group_${makeAttributeSafe(deck.name)}`}
                                                place="top"
                                                variant="error"
                                                content={formatErrors(`minRelHumErrors_${makeAttributeSafe(deck.name)}`, minRelHumErrors)}
                                                style={{zIndex: 10, borderRadius: '8px', opacity: 1}}
                                            />
                                        ) :null
                                    }
                                </ReadWriteInputGroup>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Form>

    )
}

export default DeckTemperatureRecord;