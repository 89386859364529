import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useContext, useEffect, useState } from "react";
import { UserContext } from '..';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Set_Password from '../pages/SettingsAndAdminTools/Set_Password';
import ConsignmentDashboard from '../pages/ConsignmentDashboard';
import Request_New_Password from '../pages/Request_New_Password';
import Exporter_Organisation_Settings from '../pages/SettingsAndAdminTools/Exporter_Organisation_Settings';
import LiveCorp_User_Management from '../pages/SettingsAndAdminTools/LiveCorp_User_Management';
import LiveCorp_Org_Management from '../pages/SettingsAndAdminTools/LiveCorp_Org_Management';
import Voyage_Departure_And_Vessel_Loading from '../pages/Voyage_Departure_And_Vessel_Loading';
import HistoricalConsignments from '../pages/HistoricalConsignments';
import HealthAndMortalityReport from '../pages/HealthReports/HealthAndMortalityReport';
import ViewReport from '../pages/HealthReports/ViewReport';
import Settings from '../pages/SettingsAndAdminTools/Settings';
import Edit_Observation from '../pages/Observations/Edit_Observation';
import Discharge_Report from '../pages/Discharge_Report';
import End_Of_Voyage_Report from '../pages/End_Of_Voyage_Report';
import Edit_Deck_Record_Gen_Demeanour from '../pages/DeckRecords/Edit_Deck_Record_Gen_Demeanour';
import Edit_Deck_Record_Feeding_Behaviour from '../pages/DeckRecords/Edit_Deck_Record_Feeding_Behaviour';
import Edit_Deck_Record_Manure_Pad_Scores from '../pages/DeckRecords/Edit_Deck_Record_Manure_Pad_Scores';
import Edit_Deck_Temperatures from '../pages/DeckTemperatures/Edit_Deck_Temperatures';
import Edit_Deck_Record_Other from '../pages/DeckRecords/Edit_Deck_Record_Other';
import ViewHistoryData from '../pages/ViewHistoryData';
import Admin_Tools from '../pages/SettingsAndAdminTools/Admin_Tools';
import Exporter_And_Regulatory_User_Management from '../pages/SettingsAndAdminTools/Exporter_And_Regulatory_User_Management';
import Login from '../pages/Login'
import TermsAndConditions from '../pages/TermsAndConditions';
import Consignment_VoyageDetails from '../pages/ConsignmentCreation/Consignment_VoyageDetails';
import Consignment_Personnel from '../pages/ConsignmentCreation/Consignment_Personnel';
import Consignment_Livestock from '../pages/ConsignmentCreation/Consignment_Livestock';
import Consignment_ManagementPlans from '../pages/ConsignmentCreation/Consignment_ManagementPlans';
import Consignment_Review from '../pages/ConsignmentCreation/Consignment_Review';
import Dashboard from '../pages/Dashboard';
import { getSessionLoggedInUser } from '../helpers/SessionHelper';
import CreateREConsignment from '../pages/RegisteredEstablishments/Create_RE_Consignment';
import EditREConsignment from '../pages/RegisteredEstablishments/Edit_RE_Consignment';
import REDashboard from '../pages/RegisteredEstablishments/RE_Dashboard';
import HistoricalREConsignmentsDashboard from '../pages/RegisteredEstablishments/Historical_RE_Consignments_Dashboard';
import REConsignmentDashboard from '../pages/RegisteredEstablishments/RE_Consignment_Dashboard';
import AddREMortality from '../pages/RegisteredEstablishments/Add_RE_Mortality';
import LiveCorpAndRegulatoryDashboard from '../pages/LiveCorpAndRegulatoryDashboard';
import LiveCorpAndRegulatoryCompletedConsignments from '../pages/LiveCorpAndRegulatoryCompletedConsignments';
import ViewREMortalityReport from '../pages/RegisteredEstablishments/View_RE_Mortality_Report';
import { UserType } from '../api/enums/EnumUserType';
import { OrganisationType } from '../api/enums/EnumOrganisationType';
import DownloadApp from '../pages/DownloadApp';

const PageRoutes = () => {
    const userCtx = useContext(UserContext);
    const [hasWriteAccess, setHasWriteAccess] = useState(false);
    const [userTypeID, setUserTypeID] = useState(null);
    const [organisationTypeID, setOrganisationTypeID] = useState(-1);

    useEffect(() => {
        setHasWriteAccess(userCtx.user?.writeAccess === true);
        setUserTypeID(userCtx.user?.userTypeID);
        if (userCtx.associatedExporter?.exporter?.organisationTypeID !== undefined) {
            setOrganisationTypeID(userCtx.associatedExporter.exporter.organisationTypeID);
        }
    }, [userCtx.user, userCtx.associatedExporter]);

    const ProtectedRoute = ({ user, children }) => {
        if ((!user || Object.keys(user).length === 0) && !getSessionLoggedInUser()) {
            return <Navigate to="/" replace />
        }
        return children;
    }

    // Any logged in admin of any type
    const AdminRoute = ({ user, children }) => {
        let lSessionUser = getSessionLoggedInUser();

        if ((!user || Object.keys(user).length === 0) && !lSessionUser) {
            return <Navigate to="/" replace />
        }
        else if (user && Object.keys(user).length !== 0) {
            if (user?.isOrgAdmin === false) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
        else {
            if (lSessionUser?.isOrgAdmin === false) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
    }

    const ExporterAdminRoute = ({ user, children }) => {
        let lSessionUser = getSessionLoggedInUser();

        if ((!user || Object.keys(user).length === 0) && !lSessionUser) {
            return <Navigate to="/" replace />
        }
        else if (user && Object.keys(user).length !== 0) {
            if (user?.isOrgAdmin === false) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
        else {
            if (lSessionUser?.isOrgAdmin === false || lSessionUser?.userTypeID !== 1) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
    }

    const ExporterUserAndExporterOrgTypeAdminRoute = ({ user, children }) => {
        let lSessionUser = getSessionLoggedInUser();

        if ((!user || Object.keys(user).length === 0) && !lSessionUser) {
            return <Navigate to="/" replace />
        }
        else if (user && Object.keys(user).length !== 0) {
            if (user?.isOrgAdmin === false || user.exporterDetails?.organisationTypeID === 2) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
        else {
            if (lSessionUser?.isOrgAdmin === false || lSessionUser?.userTypeID !== 1 || lSessionUser.exporterDetails?.organisationTypeID === 2) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
    }

    const LiveCorpAdminRoute = ({ user, children }) => {
        let lSessionUser = getSessionLoggedInUser();

        if ((!user || Object.keys(user).length === 0) && !lSessionUser) {
            return <Navigate to="/" replace />
        }
        else if (user && Object.keys(user).length !== 0) {
            if (user?.isOrgAdmin === false) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
        else {
            if (lSessionUser?.isOrgAdmin === false || lSessionUser?.userTypeID !== 3) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
    }

    const ExporterOrRegulatoryAdminRoute = ({ user, children }) => {
        let lSessionUser = getSessionLoggedInUser();

        if ((!user || Object.keys(user).length === 0) && !lSessionUser) {
            return <Navigate to="/" replace />
        }
        else if (user && Object.keys(user).length !== 0) {
            if (user?.isOrgAdmin === false || (user?.userTypeID !== 1 && user?.userTypeID !== 2)) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
        else {
            if (lSessionUser?.isOrgAdmin === false || (lSessionUser?.userTypeID !== 1 && lSessionUser?.userTypeID !== 2)) {
                return <Navigate to="/dashboard" replace />
            }
            else {
                return children;
            }
        }
    }

    const getDefaultDashboardPathForUserTypeID = (userTypeID) => {
        if (isNaN(userTypeID)) return "/";
        switch (userTypeID) {
            case UserType.INDUSTRY:
                return "/dashboard";
            case UserType.REGULATORY:
            case UserType.LIVE_CORP:
                return "/historicalconsignments";
            default:
                return "/";
        }
    }

    const getDashboardRouteForUserTypeID = (userTypeID, organisationTypeID) => {
        switch (userTypeID) {
            case UserType.INDUSTRY:
                if (organisationTypeID === OrganisationType.REGISTERED_ESTABLISHMENT) {
                    return <ProtectedRoute user={userCtx.user}>
                        <REDashboard />
                    </ProtectedRoute>
                } else {
                    return <ProtectedRoute user={userCtx.user}>
                        <Dashboard />
                    </ProtectedRoute>
                }


            case UserType.REGULATORY:
            case UserType.LIVE_CORP:
                return <ProtectedRoute user={userCtx.user}>
                    <LiveCorpAndRegulatoryDashboard />
                </ProtectedRoute>
        }
    }

    const getHistoricalConsignmentRouteForUserTypeID = (userTypeID) => {
        switch (userTypeID) {
            case 1:
                return <ProtectedRoute user={userCtx.user}>
                    <HistoricalConsignments />
                </ProtectedRoute>
            case 2:
            case 3:
                return <ProtectedRoute user={userCtx.user}>
                    <LiveCorpAndRegulatoryCompletedConsignments />
                </ProtectedRoute>
        }
    }

    return (
        <Routes>
            <Route path="/download" element={<DownloadApp />} />
            <Route index element={<Login />} />
            {
                hasWriteAccess === true ?
                    <>
                        <Route
                            path="/createconsignment"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <Consignment_VoyageDetails />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/createconsignmentpersonnel"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <Consignment_Personnel />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/createconsignmentlivestock"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <Consignment_Livestock />
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="/createconsignmentmanagementplans"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <Consignment_ManagementPlans />
                                </ProtectedRoute>
                            }
                        />
                    </>
                    : null
            }

            {
                userTypeID === UserType.LIVE_CORP || userTypeID === UserType.REGULATORY || organisationTypeID === OrganisationType.REGISTERED_ESTABLISHMENT || organisationTypeID === OrganisationType.RE_EXPORTER ?
                    <>
                        <Route path="/re/dashboard"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <REDashboard />
                                </ProtectedRoute>
                            }
                        />

                        <Route path="/re/consignmentdashboard/:consignmentID"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <REConsignmentDashboard />
                                </ProtectedRoute>
                            }
                        />

                        <Route path="/re/historicalconsignments"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <HistoricalREConsignmentsDashboard />
                                </ProtectedRoute>
                            }
                        />

                        <Route path="/re/mortality/:mortalityID"
                            element={
                                <ProtectedRoute user={userCtx.user}>
                                    <ViewREMortalityReport />
                                </ProtectedRoute>
                            }
                        />



                        {
                            hasWriteAccess === true ? (
                                <>
                                    <Route path="/re/createconsignment"
                                        element={
                                            <ProtectedRoute user={userCtx.user}>
                                                <CreateREConsignment />
                                            </ProtectedRoute>
                                        }
                                    />

                                    <Route path="/re/editconsignment/:consignmentID"
                                        element={
                                            <ProtectedRoute user={userCtx.user}>
                                                <EditREConsignment />
                                            </ProtectedRoute>
                                        }
                                    />

                                    <Route path="/re/addmortality/:consignmentID"
                                        element={
                                            <ProtectedRoute user={userCtx.user}>
                                                <AddREMortality />
                                            </ProtectedRoute>
                                        }
                                    />
                                </>
                            ) : null
                        }
                    </>
                    : null
            }

            <Route
                path="/termsandconditions"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <TermsAndConditions />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/dashboard"
                element={getDashboardRouteForUserTypeID(userTypeID, organisationTypeID)}
            />
            <Route
                path="/createconsignmentreview"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Consignment_Review />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/viewinactiveconsignment/:consignmentID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Consignment_Review />
                    </ProtectedRoute>
                }
            />

            <Route path="/editdeckrecordgendemeanour"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Deck_Record_Gen_Demeanour />
                    </ProtectedRoute>
                }
            />

            <Route path="/editdeckrecordfeedingbehaviour"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Deck_Record_Feeding_Behaviour />
                    </ProtectedRoute>
                }
            />

            <Route path="/editdeckrecordmanurepadscores"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Deck_Record_Manure_Pad_Scores />
                    </ProtectedRoute>
                }
            />

            <Route path="/editdecktemperatures"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Deck_Temperatures />
                    </ProtectedRoute>
                }
            />

            <Route path="/editdeckrecordother"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Deck_Record_Other />
                    </ProtectedRoute>
                }
            />

            <Route path="/viewhistorydata/:consignmentID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <ViewHistoryData />
                    </ProtectedRoute>
                }
            />

            <Route path="/admintools"
                element={
                    <AdminRoute user={userCtx.user}>
                        <Admin_Tools />
                    </AdminRoute>
                }
            />

            <Route path="/usermanagement"
                element={
                    <ExporterOrRegulatoryAdminRoute user={userCtx.user}>
                        <Exporter_And_Regulatory_User_Management />
                    </ExporterOrRegulatoryAdminRoute>
                }
            />

            <Route path="/consignmentdashboard/:consignmentID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <ConsignmentDashboard />
                    </ProtectedRoute>
                }
            />

            <Route path="/setpassword"
                element={
                    <Set_Password />
                }
            />

            <Route path="/requestnewpassword"
                element={
                    <Request_New_Password />
                }
            />

            <Route path="/organisationsettings"
                element={
                    <ExporterUserAndExporterOrgTypeAdminRoute user={userCtx.user} exporter={userCtx.associatedExporter}>
                        <Exporter_Organisation_Settings />
                    </ExporterUserAndExporterOrgTypeAdminRoute>
                }
            />

            <Route path="/livecorpusermanagement"
                element={
                    <LiveCorpAdminRoute user={userCtx.user}>
                        <LiveCorp_User_Management />
                    </LiveCorpAdminRoute>
                }
            />

            <Route path="/livecorporgmanagement"
                element={
                    <LiveCorpAdminRoute user={userCtx.user}>
                        <LiveCorp_Org_Management />
                    </LiveCorpAdminRoute>
                }
            />

            <Route path="/voyagedepartureandvesselloading/:consignmentDeparturePortID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Voyage_Departure_And_Vessel_Loading />
                    </ProtectedRoute>
                }
            />

            <Route path="/historicalconsignments"
                element={getHistoricalConsignmentRouteForUserTypeID(userTypeID)}
            />

            <Route path="/healthandmortalityreports/:consignmentID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <HealthAndMortalityReport />
                    </ProtectedRoute>
                }
            />

            <Route path="/healthreport"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <ViewReport />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/settings"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Settings />
                    </ProtectedRoute>
                }
            />
            <Route path="/editobservation"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Edit_Observation />
                    </ProtectedRoute>
                }
            />

            <Route path="/dischargereport/:consignmentID/:consignmentDestinationPortID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <Discharge_Report />
                    </ProtectedRoute>
                }
            />

            <Route path="/endofvoyagereport/:consignmentID"
                element={
                    <ProtectedRoute user={userCtx.user}>
                        <End_Of_Voyage_Report />
                    </ProtectedRoute>
                }
            />





            {/* THIS HAS TO BE THE LAST ROUTE: If a route does not exist redirect to a specific page based on the user type and if they are currently logged in */}
            <Route path='*'
                element={
                    <Navigate repalce to={
                        userCtx.user && Object.keys(userCtx.user).length > 0 ? getDefaultDashboardPathForUserTypeID(userCtx.user.userTypeID) : "/"
                    } />
                }
            />
        </Routes>
    )
}

export default PageRoutes;