import React, { useState, useEffect } from "react";
import { Stack, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare } from "@fortawesome/free-solid-svg-icons";
import { createUniqueObjectArrayByProperty } from '../../utils/ListUtils';

const PortList = ({ voyage, deptPorts, destPorts }) => {

    const [loadPorts, setLoadPorts] = useState([]);
    const [discPorts, setDiscPorts] = useState([]);

    useEffect(() => {
        if (!voyage || voyage.detailedConsignments?.length === 0) return;
        let loadPortArr = voyage.detailedConsignments.map(dc => dc.DeparturePorts.map(dp => dp)).flat();
        setLoadPorts(createUniqueObjectArrayByProperty(loadPortArr, "PortID").sort((a,b) => a.order > b.order ? 1 : -1));
        let destPortArr = voyage.detailedConsignments.map(dc => dc.DestinationPorts.map(dp => dp)).flat();
        setDiscPorts(createUniqueObjectArrayByProperty(destPortArr, "PortID").sort((a,b) => a.order > b.order ? 1 : -1));
        
    }, [voyage, deptPorts, destPorts])
    
    return (
        <Row className="mt-3">
            <Col xs={6}>
                <div className="field-label darkblue mb-1">Loading in</div>
                {
                    loadPorts?.map((p, i) => {
                        let portName = deptPorts.find(dp => dp.id === p.PortID)?.portName;
                        let isLoaded = p.LoadingCompletedDateUTC?.length > 0;
                        return (
                            <Stack key={`departure_port_${i}`} direction="horizontal" className="mb-1">
                                {
                                    isLoaded === true ?
                                        <div className="bg-faint-success dashboard-reporting-status-icon-container">
                                            <FontAwesomeIcon icon={faCheck} className="vessel-is-loading" />
                                        </div>
                                        :
                                        <div className="bg-faint-grey dashboard-reporting-status-icon-container">
                                            <FontAwesomeIcon icon={faSquare} className="vessel-default-state" />
                                        </div>

                                }
                                <div className="ms-1" style={{ fontSize: "13px" }} key={`departure_port_${portName}`}>
                                    {portName}
                                </div>
                            </Stack>
                        )
                    })
                }
            </Col>
            <Col xs={6}>
                <div className="field-label darkblue mb-1">Discharging in</div>
                {
                    discPorts?.map((p, i) => {
                        let portName = destPorts.find(dp => dp.id === p.PortID)?.portName;
                        let countryName = destPorts.find(dp => dp.id === p.PortID)?.countryName;
                        let isDischarged = p.DischargeCompletedDateUTC?.length > 0;
                        return (
                            <Stack key={`destination_port_${i}`} direction="horizontal" className="mb-1">
                                {
                                    isDischarged === true ?
                                        <div className="bg-faint-success dashboard-reporting-status-icon-container">
                                            <FontAwesomeIcon icon={faCheck} className="vessel-is-loading" />
                                        </div>
                                        :
                                        <div className="bg-faint-grey dashboard-reporting-status-icon-container">
                                            <FontAwesomeIcon icon={faSquare} className="vessel-default-state" />
                                        </div>

                                }
                                <div className="ms-1" style={{ fontSize: "13px" }}>
                                    {portName} - {countryName}
                                </div>
                            </Stack>
                        )
                    })
                }
            </Col>
        </Row>
    )
};

export default PortList;