export class EditLog {
    consignmentID = -1;
    userID = -1;
    pageEdited = "";
    pageEditReasonID = -1;
    details = "";

    constructor(_consignmentID, _userID, _pageEdited, _pageEditReasonID, _details) {
        this.consignmentID = _consignmentID;
        this.userID = _userID;
        this.pageEdited = _pageEdited;
        this.pageEditReasonID = _pageEditReasonID;
        this.details = _details;
    }
}