import React, { useEffect, useId, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { getHealthTreatmentsForConsignmentID } from "../../api/apiAccess";

const TreatmentOverview = ({consignment, index, isStarted}) => {
    const componentId = useId()
    const [treatments, setTreatments] = useState("");
    const [treatmentsYesterday, setTreatmentsYesterday] = useState("-");
    const [treatmentsTotal, setTreatmentsTotal] = useState("-");

    useEffect(() => {
        if (consignment === undefined || isStarted === false) return;
        
        const fetchTreatments = async () => {
            let results = await getHealthTreatmentsForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setTreatments(results.data);
            }
        }

        fetchTreatments();
    }, [consignment, isStarted]);

    useEffect(() => {
        if (!isStarted) return;
        if (treatments === "") return;
        populateTreatmentData();
    }, [treatments]);

    const populateTreatmentData = () => {
        // Each treated animal has a single health report id for a given day in which it was treated
        // A single health report can have multiple treatments so tally up the number of unique health report ids
        setTreatmentsTotal([...new Set([...treatments.map(t => t.healthReportID)])].length); // The total number of treated animals in the voyage so far
        let dayNumbers = treatments.map(t => t.dayNumber);
        let highestDayNumber = Math.max(...dayNumbers);
        // Select the treatments belonging to the highest day number and get the number of unique health report ids
        setTreatmentsYesterday([...new Set([...treatments.filter(t => t.dayNumber === highestDayNumber).map(t => t.healthReportID)])].length); // The number of treated animals yesterday
    }

    return (
        <Container fluid key={`treatements_overview_${componentId}_${index}`} className="my-auto mx-0 px-auto">
            <Stack direction="vertical">
                <Stack direction="horizontal">
                    <Stack className="text-center" direction="vertical">
                        <div>{treatmentsYesterday}</div>
                        <div className="field-label">Yesterday</div>
                    </Stack>
                    <Stack className="text-center" direction="vertical">
                        <div>{treatmentsTotal}</div>
                        <div className="field-label">Voyage</div>
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    )
};

export default TreatmentOverview;