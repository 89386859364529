import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faHourglass1, faCheck } from "@fortawesome/free-solid-svg-icons";
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
import tz_lookup from 'tz-lookup';
import { getMostRecentObservationForConsignmentID } from "../../api/apiAccess";
import { getDifferenceInWholeHours, determineDailyReportingStatus } from "../../utils/StatusUtils";
import { UserContext } from '../../';
import { getCurrentDayAndEndDayForConsignment } from "../../utils/common";
import Swal from "../../utils/Swal";
import { submitToDept } from "../../api/apiAccess";
import { Link } from "react-router-dom";
import { ViewedOrAwaitingReviewIcon } from "../Status/ViewedOrAwaitingReviewIcon";
import { isReportEditable } from "../../utils/common";

const ConsignmentReportingStatus = ({ consignment, isMobile }) => {
    const userCtx = useContext(UserContext);
    const [dailyReportingRecieved, setDailyReportingRecieved] = useState(false);
    const [dailyReportingReviewed, setDailyReportingReviewed] = useState(false);
    const [deptReportStatus, setDeptReportStatus] = useState(false);
    const [currentVesselTime, setCurrentVesselTime] = useState("");
    const [lastReportTimeInHours, setLastReportTimeInHours] = useState("");
    const [hasReceivedDailyReport, setHasReceivedDailyReport] = useState(true);

    const [currentAndNumberOfDays, setCurrentAndNumberOfDays] = useState(null);
    const [currentConsignmentDay, setCurrentConsignmentDay] = useState(null);
    const [dayLoadingReport, setDayLoadingReport] = useState(null);
    const [dayDischargeReports, setDayDischargeReports] = useState([]);
    const [dayEndOfVoyageReport, setDayEndOfVoyageReport] = useState(null);

    useEffect(() => {

        const fetchMostRecentObservation = async () => {
            let results = await getMostRecentObservationForConsignmentID(consignment.Consignment.id);

            if (results?.status === 200) {
                let lastKnownTimezone = tz_lookup(results.data.latitude, results.data.longitude);
                let currentLocalTimeOnVesselBasedOnLastKnownTimezone = formatInTimeZone(Date.now(), lastKnownTimezone, "dd/MM/yyyy HH:mm OOOO");
                setCurrentVesselTime(currentLocalTimeOnVesselBasedOnLastKnownTimezone);
            }
        }

        const updateTodaysReportingStatus = async () => {
            let lDayNumbers = await getCurrentDayAndEndDayForConsignment(userCtx.user?.userTypeID, consignment);
            setCurrentAndNumberOfDays(lDayNumbers);

            if (lDayNumbers?.CurrentDayNumber) {
                let lCurrentConsignmentDay = consignment?.ConsignmentDays?.find(conDay => conDay.dayNumber === lDayNumbers.CurrentDayNumber);
                setCurrentConsignmentDay(lCurrentConsignmentDay);

                if (lCurrentConsignmentDay !== null && lCurrentConsignmentDay !== undefined) {
                    setDailyReportingRecieved(true);
                    let lIsAwaitingReview = isAwaitingReview(consignment, lCurrentConsignmentDay);
                    setDailyReportingReviewed(!lIsAwaitingReview);
                    setDeptReportStatus(lCurrentConsignmentDay.isSubmitted === true);
                }
                else {
                    setDailyReportingRecieved(false);
                    setDeptReportStatus(false);
                }
            }
        }

        if (Object.keys(consignment).length > 0) {
            // update last report time
            let mostRecentConsignmentDay = consignment?.ConsignmentDays?.reduce((previous, current) => !previous || current.dayNumber > previous.dayNumber ? current : previous, undefined);

            if (consignment.Consignment.dateLastSynced) {
                let lLastSynced = new Date(consignment.Consignment.dateLastSynced);
                let hoursSinceLastReport = getDifferenceInWholeHours(new Date(Date.now()), lLastSynced);
                setLastReportTimeInHours(hoursSinceLastReport);
                setHasReceivedDailyReport(true);
            }
            else {
                setHasReceivedDailyReport(false);
                setLastReportTimeInHours("");
            }

            if (mostRecentConsignmentDay !== undefined && mostRecentConsignmentDay !== null) {
                // update today's reporting status 
                updateTodaysReportingStatus(mostRecentConsignmentDay);
            }
            fetchMostRecentObservation();
        }
    }, [consignment])


    const isAwaitingReview = (aConsignment, aCurrentConsignmentDay) => {
        // get all reports that occured for the current day
        let lCurrentLoadingReport = aConsignment.DeparturePorts.find((dp) => dp.ConsignmentDayID === aCurrentConsignmentDay.id);
        if (lCurrentLoadingReport) {
            setDayLoadingReport(lCurrentLoadingReport);
        }
        else {
            setDayLoadingReport(null);
        }

        let lCurrentDischargeReports = [...aConsignment.DestinationPorts].filter((dp) => dp.ConsignmentDayID === aCurrentConsignmentDay.id);
        if (lCurrentDischargeReports) {
            setDayDischargeReports(lCurrentDischargeReports);
        }
        else {
            setDayDischargeReports(null);
        }

        let lCurrentEndOfVoyageReport = null;
        if (aConsignment.EndOfVoyage && aConsignment.EndOfVoyage.ConsignmentDayID === aCurrentConsignmentDay.id === true) {
            lCurrentEndOfVoyageReport = aConsignment.EndOfVoyage;
        }

        setDayEndOfVoyageReport(lCurrentEndOfVoyageReport);

        let lDailyReportingReviewedOrNoDailyReporting = true;
        if (aCurrentConsignmentDay !== null && aCurrentConsignmentDay !== undefined) {
            lDailyReportingReviewedOrNoDailyReporting = aCurrentConsignmentDay.isViewed;
        }

        let lLoadingReviewedOrNoLoading = true;
        if (lCurrentLoadingReport !== null && lCurrentLoadingReport !== undefined) {
            lLoadingReviewedOrNoLoading = lCurrentLoadingReport.IsLoadingAndDepartureReviewed;
        }

        let lDischargeReviewedOrNoDischarge = true;
        if (lCurrentDischargeReports !== null && lCurrentDischargeReports !== undefined) {
            if (lCurrentDischargeReports.some(dp => dp.IsDischargeReviewed === false) === true) {
                lDischargeReviewedOrNoDischarge = false;
            }
        }

        let lEOViewedOrNoEOV = true;
        if (lCurrentEndOfVoyageReport !== null && lCurrentEndOfVoyageReport !== undefined) {
            lEOViewedOrNoEOV = lCurrentEndOfVoyageReport.IsEOVReviewed;
        }

        let lAwaitingReviewStatus = lDailyReportingReviewedOrNoDailyReporting === false || lLoadingReviewedOrNoLoading === false
            || lDischargeReviewedOrNoDischarge === false || lEOViewedOrNoEOV === false;

        return lAwaitingReviewStatus;
    }


    const isSubmissionPastDue = (dayEndTime) => {
        let dayEndTimeDate = new Date(dayEndTime);
        let now = new Date(Date.now());
        let currentDueDateTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 11, 0);;
        // If the end time is greater than 24 hours ago, true
        if ((currentDueDateTime.getTime() - dayEndTimeDate.getTime()) * 1000 * 60 * 60 > 24) {
            return true;
        }
        // If the end time is less than 24 hours ago and it is currently later than today's due time, true
        if ((currentDueDateTime.getTime() - dayEndTimeDate.getTime()) * 1000 * 60 * 60 < 24 && (new Date(Date.now()).getTime() - currentDueDateTime.getTime()) <= 0) {
            return true;
        }
        //else
        return false;
    }

    const submitToDepartment = async () => {
        try {
            let lResponse = await submitToDept(consignment.Consignment.id);
            if (lResponse?.status === 200) {
                Swal.Toast("Consignment submitted to department successfully");
                setDeptReportStatus(true);
            } else {
                Swal.Toast("Error submitting consignment to department", "error");
            }
        } catch {
            Swal.Toast("Error submitting consignment to department", "error");
        }
    };

    return (
        <Container>
            <Row className="pb-2 pt-3">
                <Col xs={isMobile ? 12 : 4} className={`${isMobile ? "text-center" : ""}`}>
                    <div className="heading-text darkblue" style={{ fontSize: '16px' }}>Voyage Reporting</div>
                </Col>
            </Row>

            <Container fluid className="bg-darkblue" style={{ borderRadius: '4px' }}>

                <Row className="py-4 px-3">
                    <Col xs={isMobile ? 12 : userCtx?.user?.userTypeID !== 2 ? 4 : 6} className={`${isMobile ? "pb-3" : ""}`}>
                        <Container className="p-3 bg-white" style={{ borderRadius: '4px', height: "100%", display: "flex", flexDirection: "column" }}>
                            <Row className="px-2 pb-3">
                                <div className="text-center field-label">
                                    {
                                        currentAndNumberOfDays?.CurrentDayNumber ? `Day ${currentAndNumberOfDays?.CurrentDayNumber} Daily Reporting` : "Daily Reporting"
                                    }
                                </div>
                            </Row>
                            <Row className="px-2" style={{ flexGrow: 1 }}>
                                {
                                    !dailyReportingRecieved ? (
                                        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                            <div className="error-red text-center bg-faint-error field-label p-2">
                                                <span><FontAwesomeIcon icon={faExclamationTriangle} /> Not received</span>
                                            </div>
                                        </div>
                                    ) :
                                        <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                                            <div className="text-center field-label" style={{ flexGrow: 1 }}>
                                                <ul style={{ display: "inline-block", textAlign: "left" }}>
                                                    {
                                                        currentConsignmentDay ?
                                                            <li>
                                                                <Stack direction='horizontal' gap={2}>
                                                                    <Link to={`/viewhistorydata/${consignment.Consignment.id}`}
                                                                        state={{ focusedTab: "observations", editable: isReportEditable(userCtx, consignment.IsEditable()) }}
                                                                        className="blue-link">
                                                                        Daily Reporting
                                                                    </Link>
                                                                    <ViewedOrAwaitingReviewIcon isViewed={currentConsignmentDay?.isViewed === true} />
                                                                </Stack>
                                                            </li> : null
                                                    }
                                                    {
                                                        dayLoadingReport ?
                                                            <li>
                                                                <Stack direction='horizontal' gap={2}>
                                                                    <Link to={`/voyagedepartureandvesselloading/${dayLoadingReport.ID}`}
                                                                        className="blue-link">
                                                                        Loading Report
                                                                    </Link>
                                                                    <ViewedOrAwaitingReviewIcon isViewed={dayLoadingReport.IsLoadingAndDepartureReviewed === true} />
                                                                </Stack>
                                                            </li> : null
                                                    }
                                                    {
                                                        dayDischargeReports ?
                                                            dayDischargeReports.map((dayDischargeReport, i) => {
                                                                return (
                                                                    <li key={`dischargeReport_${i}`}>
                                                                        <Stack direction='horizontal' gap={2}>
                                                                            <Link to={`/dischargereport/${dayDischargeReport.ConsignmentID}/${dayDischargeReport.ID}`}
                                                                                className="blue-link">
                                                                                Discharge Report
                                                                            </Link>
                                                                            <ViewedOrAwaitingReviewIcon isViewed={dayDischargeReport?.IsDischargeReviewed === true} />
                                                                        </Stack>
                                                                    </li>
                                                                )
                                                            })
                                                            : null
                                                    }
                                                    {
                                                        dayEndOfVoyageReport ?
                                                            <li>
                                                                <Stack direction='horizontal' gap={2}>
                                                                    <Link to={`/endofvoyagereport/${consignment.Consignment.id}`}
                                                                        state={{}}
                                                                        className="blue-link">
                                                                        End of Voyage Report
                                                                    </Link>
                                                                    <ViewedOrAwaitingReviewIcon isViewed={dayEndOfVoyageReport?.IsEOVReviewed} />
                                                                </Stack>
                                                            </li> : null
                                                    }
                                                </ul>
                                            </div>
                                            {
                                                userCtx?.user?.userTypeID !== 2 ?
                                                    dailyReportingReviewed ? (
                                                        <div className="primary-green text-center bg-faint-success field-label p-2">
                                                            <span><FontAwesomeIcon icon={faCheckCircle} /> Viewed</span>
                                                        </div>
                                                    ) :
                                                        (
                                                            <div className="warning-yelllow text-center bg-faint-warning field-label p-2">
                                                                <span><FontAwesomeIcon icon={faHourglass1} /> Awaiting Review</span>
                                                            </div>
                                                        )
                                                    : null
                                            }

                                        </div>
                                }
                            </Row>
                        </Container>
                    </Col>
                    {userCtx?.user?.userTypeID !== 2 &&
                        <Col xs={isMobile ? 12 : 4} className={`${isMobile ? "pb-3" : ""}`}>
                            <Container className="p-3 bg-white" style={{ borderRadius: '4px', height: "100%", display: "flex", flexDirection: "column" }}>
                                <Row className="px-2 pb-3">
                                    <div className="text-center field-label">
                                        {
                                            currentAndNumberOfDays?.CurrentDayNumber ? `Day ${currentAndNumberOfDays?.CurrentDayNumber}  Report to Department` : "Report to Department"
                                        }
                                    </div>
                                </Row>
                                <Row className="px-2" style={{ flexGrow: 1 }}>
                                    {
                                        !deptReportStatus ? (
                                            <div style={{ flexGrow: 1, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                                                {
                                                    dailyReportingRecieved &&
                                                    consignment?.Exporter?.ReviewRequiredPriorToDeptSubmission && userCtx.user.writeAccess && (
                                                        <div className="mb-2" style={{ flexGrow: 1 }}>
                                                            <Button style={{ width: "100%" }} className={dailyReportingReviewed === true ? `btn-primary` : 'btn-secondary'} onClick={submitToDepartment}>
                                                                Submit to Department
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                <div className="error-red text-center bg-faint-error field-label p-2">
                                                    <span><FontAwesomeIcon icon={faExclamationTriangle} /> Not submitted</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <div style={{ flexGrow: 1 }}></div>
                                                <div className="primary-green text-center bg-faint-success field-label p-2">
                                                    <span><FontAwesomeIcon icon={faCheckCircle} /> Submitted</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </Row>
                            </Container>
                        </Col>
                    }
                    <Col xs={isMobile ? 12 : userCtx?.user?.userTypeID !== 2 ? 4 : 6}>
                        <Container className="p-3 bg-white" style={{ borderRadius: '4px', height: "100%" }}>
                            <Row className="px-2 pb-3">
                                <div className="text-center field-label">
                                    Vessel Status
                                </div>
                            </Row>
                            <Row className="px-2 pb-3">
                                <Col xs={12} className="field-label mb-2 text-center">
                                    <div style={{ display: "inline-block", textAlign: "left" }}>Vessel Current Time: {currentVesselTime}</div>
                                </Col>
                                {
                                    userCtx?.user?.userTypeID !== 2 ?
                                        <Col xs={12} className="field-label text-center">
                                            <div style={{ display: "inline-block", textAlign: "left" }}>
                                                {
                                                    hasReceivedDailyReport === true ?
                                                        lastReportTimeInHours > 24 ? (
                                                            <span className="error-red">
                                                                <FontAwesomeIcon icon={faExclamationTriangle} className="error-red" />
                                                                Last daily report received {lastReportTimeInHours} hr ago
                                                            </span>
                                                        ) : (
                                                            <span className="success-green">
                                                                Last daily report received {lastReportTimeInHours} hr ago
                                                            </span>
                                                        )
                                                        :
                                                        <span className="error-red">
                                                            <FontAwesomeIcon icon={faExclamationTriangle} className="error-red" />
                                                            No daily reports received
                                                        </span>
                                                }
                                            </div>
                                        </Col>
                                        : null
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container >
    )
}

export default ConsignmentReportingStatus;