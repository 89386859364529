export class ConsignmentDay {
    id;
    consignmenetId;
    dayNumber;
    startDateUTC;
    endDateUTC;
    isViewed;
    isSubmitted;
    observation;
    constructor({ id, consignmentID, dayNumber, startDateUTC, endDateUTC, isViewed, isSubmitted, observation}) {
        this.id = id;
        this.consignmentId = consignmentID;
        this.dayNumber = dayNumber;
        this.startDateUTC = startDateUTC;
        this.endDateUTC = endDateUTC;
        this.isViewed = isViewed;
        this.isSubmitted = isSubmitted;
        this.observation = observation;
    }
}