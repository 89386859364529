import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, ButtonGroup, ButtonToolbar, Form, Stack, Table, Spinner } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import Selects from "../../components/Selects";
import {
  getHealthReportsForConsignmentID,
  getMortalitiesForConsignmentID,
  getBirthAbortionReportsForConsignmentID,
  getHealthReportByID,
  getMortalityReportByID,
} from "../../api/apiAccess";
import { useNavigate } from "react-router-dom";
import { toMultiLevelStringOfCondition, isConditionIncludes, canViewVoluntaryData } from "../../utils/common";
import { useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faRepeat } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../..";
import { sortByProperty } from "../../utils/ListUtils";

const HealthAndMortalityReport = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { consignmentID } = useParams();
  const { state } = useLocation();
  const userCtx = useContext(UserContext);
  //console.log("HealthAndMortalityReport of consignment:" + consignmentID + " state:", state);

  const [loading, setIsLoading] = useState(false);
  const [recordType, setRecordType] = useState(props.type ? props.type : "health");
  const [conditonLevelOneType, setConditionLevelOneType] = useState("Morbidity");
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [dayFilter, setDayFilter] = useState(0);
  const [deckFilter, setDeckFilter] = useState(0);
  const [speciesFilter, setSpeciesFilter] = useState(0);
  const [speciesClassFilter, setSpeciesClassFilter] = useState(0);
  const [conditionFilter, setConditionFilter] = useState(0);

  const [healthReports, setHealthReports] = useState(undefined);
  const [birth_abortionReports, setBirthAbortionReports] = useState(undefined);
  const [mortalityReports, setMortalityReports] = useState(undefined);

  const [showVoluntaryData, setShowVoluntaryData] = useState(false);

  const goBack = () => {
    navigate(-1, {state: {isHistorical: location?.state?.isHistorical}});
  };

  const gotoViewHealthReport = async (report) => {
    if (recordType === "health") {
      let response = await getHealthReportByID(report.id);
      //console.log("healthreport", report, response.data);
      navigate(`/healthreport`, { state: { report: response.data, consignmentID: consignmentID, type: recordType } });
    } else if (recordType === "mortality") {
      let response = await getMortalityReportByID(report.mortalityID);
      console.log("mortality", report, response.data);

      navigate(`/healthreport`, { state: { report: response.data, consignmentID: consignmentID, type: recordType } });
    } else{
      navigate(`/healthreport`, { state: { report: report, consignmentID: consignmentID, type: recordType } });
    }
  };

  useEffect(() => {
    const fetchreports = async () => {
      let reports = [];

      if (recordType === "health") {
        reports = healthReports ? healthReports : (await getHealthReportsForConsignmentID(consignmentID)).data;
        if (!healthReports) {
          reports = sortByProperty(reports, "dayNumber");
          setHealthReports(reports);
        }
        console.log("health reports:", reports);
        setConditionLevelOneType("Morbidity");
      } else if (recordType === "birth_abortion") {
        reports = birth_abortionReports ? birth_abortionReports : (await getBirthAbortionReportsForConsignmentID(consignmentID)).data;
        if (!birth_abortionReports) {
          reports = sortByProperty(reports, "dayNumber");
          setBirthAbortionReports(reports);
        }
        console.log("BirthAbortion reports:", reports);
      } else if (recordType === "mortality") {
        reports = mortalityReports ? mortalityReports : (await getMortalitiesForConsignmentID(consignmentID)).data;
        if (!mortalityReports) {
          reports = sortByProperty(reports, "dayNumber");
          setMortalityReports(reports);
        }

        setConditionLevelOneType("Mortality");
        console.log("Mortality reports:", reports);
      }
      setReports(reports);
      setIsLoading(false);
    };
    setIsLoading(true);
    setConditionFilter(0);
    fetchreports();
  }, [recordType]);

  // Filter reports when the reports or filter options change
  useEffect(() => {
    //console.log("start filtering reports");
    let lFilteredReports = [];
    if (reports) {
      lFilteredReports = [...reports]?.filter((aReport) => reportMatchesFilter(aReport));
    }
    //  console.log("filtered reports:", lFilteredReports);
    setFilteredReports(lFilteredReports);
  }, [reports, searchTerm, dayFilter, deckFilter, speciesFilter, speciesClassFilter, conditionFilter, conditonLevelOneType]);

  useEffect(() => {
    let lCanView = canViewVoluntaryData(userCtx.user);
    setShowVoluntaryData(lCanView);
  }, [userCtx.user]);

  const matchKeywordInReport = (report, keyword) => {
    console.log("matchKeywordInReport:", report, keyword, conditionFilter);

    if ((keyword === null || keyword === "") && conditionFilter === 0) {
      return true;
    } else {
      if (report.tagNumber && report.tagNumber.toLowerCase().includes(keyword)) return true;
      if (report.tagType && report.tagType.toLowerCase().includes(keyword)) return true;

      if (report.condition) {
        //console.log("report.condition.levelOne:", report.condition.levelOne, conditionFilter);
        if (report.condition.levelOne.id === conditionFilter) return true;
        if (isConditionIncludes(report.condition, keyword)) return true;
      } else if (report.mortalityReport) {
        if (report.mortalityReport.condition[0].id === conditionFilter) {
          return true;
        }
        for (let i = 0; i < report.mortalityReport.condition.length; i++) {
          let c = report.mortalityReport.condition[i];
          if (c.name.toLowerCase().includes(keyword)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const reportMatchesFilter = (report) => {
    let lSearchTerm = searchTerm ? searchTerm.toLowerCase() : null;
    let lMatch = false;
    //console.log("reportMatchesFilter: speciesFilter", report, speciesFilter);
    if (
      (deckFilter === 0 || report.consignmentDeckID === deckFilter) &&
      (speciesFilter === 0 || report.speciesID === speciesFilter) &&
      (speciesClassFilter === 0 || report.classID === speciesClassFilter) &&
      (dayFilter === 0 || report.consignmentDayID === dayFilter) &&
      (conditionFilter === 0 || matchKeywordInReport(report, lSearchTerm)) &&
      (lSearchTerm === null || lSearchTerm === "" || matchKeywordInReport(report, lSearchTerm))
    ) {
      lMatch = true;
    }

    return lMatch;
  };

  return (
    <LoggedInContainer>
      <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <Row className="upper-panel mx-0" style={{ position: "relative" }}>
          <Col>
            <Container>
              <Row>
                <Col xs={12} style={{ marginTop: "75px" }}>
                  <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                    <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                    Back
                  </Button>
                  <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                    <div style={{ lineHeight: "53px", fontSize: "35px" }}>Health & Mortality Reporting</div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Container className="py-4 px-5 form-container-full">
          <Row className="mb-3" xl={12}>
            <ButtonToolbar className="p-0">
              <ButtonGroup style={{ width: "inherit" }}>
                <Button
                  variant={recordType === "health" ? "outline-primary" : "outline-secondary"}
                  onClick={(e) => {
                    setRecordType("health");
                  }}
                  className="field-label"
                >
                  Health Reports
                </Button>
                <Button
                  variant={recordType === "birth_abortion" ? "outline-primary" : "outline-secondary"}
                  onClick={(e) => {
                    setRecordType("birth_abortion");
                  }}
                  className="field-label"
                >
                  Birth/Abortions
                </Button>
                <Button
                  variant={recordType === "mortality" ? "outline-primary" : "outline-secondary"}
                  onClick={(e) => {
                    setRecordType("mortality");
                  }}
                  className="field-label"
                >
                  Mortalities
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Row>
          <Row className="mb-3 gray-frame">
            <Col xs={3}>
              <Stack>
                <Form.Label style={{ fontSize: "15px" }} className="darkblue field-label mb-1">
                  Search
                </Form.Label>
                <Form.Control
                  className="field-label"
                  type="text"
                  placeholder="Type a word to search, ie tag number, issue... "
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Stack>
            </Col>
            <Col>
              <Stack>
                <Form.Label style={{ fontSize: "15px" }} className="darkblue field-label mb-1">
                  Filter health reports by
                </Form.Label>
                <Stack direction="horizontal" gap={2}>
                  <Selects.ConsignmentDaySelect
                    defaultOption={{ id: 0, name: "All Days" }}
                    consignmentID={consignmentID}
                    onChange={(e) => {
                      setDayFilter(Number(e));
                      //console.log("setDayFilter", e);
                    }}
                    selected={dayFilter}
                  />
                  <Selects.ConsignmentDeckSelect
                    defaultOption={{ id: 0, name: "All Decks" }}
                    onChange={(e) => {
                      //console.log("setDeckFilter", e);
                      setDeckFilter(Number(e));
                    }}
                    selected={deckFilter}
                    consignmentID={consignmentID}
                  />
                  <Selects.ConsignmentAnimalSpecieSelet
                    defaultOption={{ id: 0, name: "All Species" }}
                    onChange={(e) => {
                      //console.log("setSpeciesFilter", e);
                      setSpeciesFilter(Number(e));
                    }}
                    selected={speciesFilter}
                    consignmentID={consignmentID}
                  />
                  <Selects.ConsignmentAnimalClassSelet
                    defaultOption={{ id: 0, name: "All Classes" }}
                    onChange={(e) => setSpeciesClassFilter(Number(e))}
                    selected={speciesClassFilter}
                    consignmentID={consignmentID}
                  />
                  {recordType === "health" ? (
                    <Selects.ConsignmentHealthConditionL1Select
                      consignmentID={consignmentID}
                      defaultOption={{ id: 0, name: "All L1 Conditions" }}
                      onChange={(index, option) => setConditionFilter(Number(option.id))}
                      selected={conditionFilter}
                    />
                  ) : null}
                  {recordType === "mortality" ? (
                    <Selects.ConsignmentMortalityConditionL1Select
                      consignmentID={consignmentID}
                      defaultOption={{ id: 0, name: "All L1 Conditions" }}
                      onChange={(index, option) => setConditionFilter(Number(option.id))}
                      selected={conditionFilter}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <th className="darkblue field-label mb-1 text-center">Last treated</th>
                    <th className="darkblue field-label mb-1 text-center">Deck</th>
                    <th className="darkblue field-label mb-1 text-center">Pen</th>
                    <th className="darkblue field-label mb-1 text-center">Animal</th>
                    <th className="darkblue field-label mb-1 text-center">Tag</th>
                    <th className="darkblue field-label mb-1 text-center">Condition</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="field-label">
                  {filteredReports &&
                    filteredReports.map(
                      (record, index) =>
                        record && (
                          <tr key={"report-" + index}>
                            <td className="align-middle text-center lines" key={"health-record-date" + index}>
                              Day {record.dayNumber}
                              {record.previousHealthReportID && <FontAwesomeIcon icon={faRepeat} style={{ marginLeft: "5px" }} />}
                            </td>
                            <td className="align-middle text-center" key={"health-record-deck" + index}>
                              {record.deckName}
                            </td>
                            <td className="align-middle text-center" key={"health-record-pen" + index}>
                              {record.penName}
                            </td>
                            <td className="align-middle text-center" key={"health-record-animal" + index}>
                              {record.speciesName} - {record.className}
                            </td>
                            <td className="align-middle text-center" key={"health-record-tag" + index}>
                              {record.tagType} - {record.tagNumber}
                              {record.hasAnimalEarTags === true && (
                                <p>
                                  {record.AnimalReport.quantity + " " + record.AnimalReport.Specie.name.toLowerCase()}
                                  <br></br>Tags not visible
                                </p>
                              )}
                            </td>
                            <td className="align-middle lines" key={"health-record-condition" + index}>
                              {record.condition && toMultiLevelStringOfCondition(record.condition)}
                              {
                                false && record.mortalityReport && record.mortalityReport.diagnosis
                                //&&
                                //toMultiLevelString(record.mortalityReport.diagnosis)
                              }
                              {record.birthOutcomeTypeID && "Birth"}
                              {record.estimatedAgedMonth && "Abortion"}
                            </td>

                            <td className="align-middle text-center" key={"health-record-btns" + index}>
                              <Button className="field-label" variant="outline-secondary" onClick={(e) => gotoViewHealthReport(record)}>
                                View
                              </Button>
                            </td>
                          </tr>
                        )
                    )}
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
            <Col>
              {loading === true ? (
                <Row>
                  <Col>
                    <div className="text-center pt-5">
                      <Spinner className="mx-auto" />
                      <div className="mx-auto">Loading data...</div>
                    </div>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Container>
    </LoggedInContainer>
  );
};

export default HealthAndMortalityReport;
