import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Form, Stack, Alert, InputGroup } from "react-bootstrap";
import { patchAAVUser, postAAVUser } from '../../api/apiAccess';
import { StringIsNullUndefinedOrEmpty } from '../../utils/StringUtils';
import { Tooltip } from 'react-tooltip';
import { NumericValidationRules, parseNumericInput, isValidEmail, isValidMobile } from '../../utils/InputValidator';

const AAV_Add_Edit_Modal = ({ managementUser, showModal, setShowModal, onAdd }) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [headerText, setHeaderText] = useState("Add New AAV");
    const [successText, setSuccessText] = useState("");

    const [id, setID] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [accreditationNumber, setAccreditationNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validAccreditationNumber, setValidAccreditationNumber] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);

    const numericValidationRules = new NumericValidationRules({ allowLeadingZerosForWholeNumbers: true, allowNegative: false, allowDecimal: false });

    useEffect(() => {
        clearCurrentErrors();

        if (managementUser) { // edit
            setIsEditMode(true);
            setHeaderText("View/Edit AAV");
            setSuccessText("The AAV has been successfully updated.");

            setID(managementUser.id);
            setFirstName(managementUser.firstName);
            setLastName(managementUser.lastName);
            setAccreditationNumber(managementUser.accreditationNumber);
            setEmailAddress(managementUser.emailAddress);
            setMobilePhone(managementUser.phoneNumber);
            setIsActive(managementUser.isActive);
        }
        else { // add
            setIsEditMode(false);
            setHeaderText("Add New AAV");
            setSuccessText("The AAV has been added successfully.");

            setID(null);
            setFirstName("");
            setLastName("");
            setAccreditationNumber("");
            setEmailAddress("");
            setMobilePhone("");
            setIsActive(true);
        }
    }, [managementUser]);

    const clearCurrentErrors = () => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setValidFirstName(true);
        setValidLastName(true);
        setValidEmail(true);
        setValidPhone(true);
        setValidAccreditationNumber(true);
    }

    const save = () => {
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        setErrorMessage("");
        setValidFirstName(false);
        setValidLastName(false);
        setValidEmail(false);
        setValidPhone(false);

        const clearInputs = () => {
            setID(null);
            setFirstName("");
            setLastName("");
            setAccreditationNumber("");
            setEmailAddress("");
            setMobilePhone("");
            setIsActive(true);
        }

        const saveAndValidate = async () => {
            // validate
            let lValidFirstName = !StringIsNullUndefinedOrEmpty(firstName);
            let lValidLastName = !StringIsNullUndefinedOrEmpty(lastName);
            let lValidEmail = isValidEmail(emailAddress);
            let lValidPhoneNumber = isValidMobile(mobilePhone);
            let lValidAccreditationNumber = !StringIsNullUndefinedOrEmpty(accreditationNumber); //todo what is a valid value

            setValidFirstName(lValidFirstName);
            setValidLastName(lValidLastName);
            setValidEmail(lValidEmail);
            setValidPhone(lValidPhoneNumber);
            setValidAccreditationNumber(lValidAccreditationNumber);

            if (lValidFirstName && lValidLastName && lValidEmail && lValidPhoneNumber && lValidAccreditationNumber) {
                // save
                let result;

                // parse any boolean values from string to boolean, otherwise we get a bad request when calling the api
                let lIsActive = JSON.parse(isActive);

                let lMode = "add new";
                if (isEditMode) {
                    lMode = "edit";
                }

                if (isEditMode === true) {
                    result = await patchAAVUser(id, firstName, lastName, emailAddress, mobilePhone, accreditationNumber, lIsActive);
                }
                else {
                    result = await postAAVUser(firstName, lastName, emailAddress, mobilePhone, accreditationNumber, lIsActive);
                }

                if (result.status == 200) {
                    setShowSuccessMessage(true);
                    onAdd();

                    if (isEditMode === false) {
                        clearInputs();
                    }

                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 15000);
                }
                else if (result.status == 400) {
                    setShowErrorMessage(true);
                    if (result.data && typeof result.data === `string`) {
                        setErrorMessage(result.data);
                    }
                    else {
                        setErrorMessage(`Unable to ${lMode} aav.`);
                    }
                }
                else {
                    setShowErrorMessage(true);
                    setErrorMessage(`Unable to ${lMode} aav.`);
                }
            }
        }
        saveAndValidate();
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">First Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtFirstName`}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            setValidFirstName(true);
                                        }}
                                        value={firstName}
                                        isInvalid={validFirstName === false}
                                    />
                                    {
                                        validFirstName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtFirstName`}
                                                    place="top"
                                                    variant="error"
                                                    content="A first name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">Last Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtLastName`}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            setValidLastName(true);
                                        }}
                                        value={lastName}
                                        isInvalid={validLastName === false}
                                    />
                                    {
                                        validLastName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtLastName`}
                                                    place="top"
                                                    variant="error"
                                                    content="A last name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">AAV Accreditation Number</div>
                                <Form.Group>
                                    <InputGroup>
                                        <InputGroup.Text>AAV-</InputGroup.Text>
                                        <Form.Control
                                            id={`txtAccreditationNumber`}
                                            onChange={(e) => {
                                                setAccreditationNumber(e.target.value);
                                                setValidAccreditationNumber(true);
                                            }}
                                            value={accreditationNumber}
                                            isInvalid={validAccreditationNumber === false}
                                        />
                                        {
                                            validAccreditationNumber === false ?
                                                (<div className="validation-error-tooltip">
                                                    <Tooltip
                                                        anchorSelect={`#txtAccreditationNumber`}
                                                        place="top"
                                                        variant="error"
                                                        content="A valid accreditation number is required."
                                                    />
                                                </div>
                                                ) : null
                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Stack>
                                <div className="darkblue field-label">Email Address</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtEmail`}
                                        onChange={(e) => {
                                            setEmailAddress(e.target.value);
                                            setValidEmail(true);
                                        }}
                                        value={emailAddress}
                                        isInvalid={validEmail === false}
                                    />
                                    {
                                        validEmail === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtEmail`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid email address is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Mobile Phone</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtPhone`}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            parseNumericInput(e, numericValidationRules);
                                            setMobilePhone(e.target.value);
                                            setValidPhone(true);
                                        }}
                                        value={mobilePhone}
                                        isInvalid={validPhone === false}
                                    />
                                    {
                                        validPhone === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtPhone`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid mobile phone number is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    {
                        isEditMode === true ?
                            <Row>
                                <Col>
                                    <Stack direction='horizontal' gap={2}>
                                        <div className="darkblue field-label">Is Active</div>
                                        <Form.Group>
                                            <Form.Check
                                                onChange={(e) => {
                                                    setIsActive(e.target.checked);
                                                }}
                                                checked={isActive}
                                            />
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {
                        showErrorMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='danger'>{errorMessage}</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        showSuccessMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>{successText}</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => save()}>Save</Button>
                        </Col>
                    </Row>
                </Container>


            </Modal.Footer>
        </Modal>
    )
}

export default AAV_Add_Edit_Modal;