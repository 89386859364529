import React, { useRef, useEffect, useState } from "react";
import { getObservationLocationsForConsignmentID } from "../../api/apiAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircle } from "@fortawesome/free-solid-svg-icons";

const ConsignmentMap = ({ consignmentID }) => {
  const ref = useRef();
  const [googleMap, setGoogleMap] = useState("");
  const [observationLocations, setObservationLocations] = useState([]);

  useEffect(() => {
    let map = new window.google.maps.Map(ref.current, { streetViewControl: false, mapTypeControl: false, mapTypeId: window.google.maps.MapTypeId.HYBRID });
    map.setCenter(new window.google.maps.LatLng(-34, 114));
    setGoogleMap(map);
  }, []);

  useEffect(() => {
    if (consignmentID === undefined || googleMap === "") return;

    const fetchObservationLocations = async () => {
      let results = await getObservationLocationsForConsignmentID(consignmentID);
      if (results?.status === 200) {
        setObservationLocations(results.data);
      }
    }

    fetchObservationLocations();
  }, [consignmentID, googleMap])

  useEffect(() => {
    if (observationLocations.length === 0) return;

    const setUpMarkers = async () => {
      let bounds = new window.google.maps.LatLngBounds();
      
      // should be in chronological date order
      observationLocations.forEach((loc, index) => {
        let latLng = new window.google.maps.LatLng({ lat: loc.latitude, lng: loc.longitude });

        let lIcon = faCircle;
        // most recent position
        if (index === observationLocations.length - 1) {
          lIcon = faLocationDot;
        }

        new window.google.maps.Marker({
          map: googleMap,
          position: latLng,
          icon: {
            path: lIcon.icon[4],
            fillColor: "#32ac59",
            fillOpacity: 1,
            anchor: new window.google.maps.Point(
              faCircle.icon[0] / 2, // width
              faCircle.icon[1] // height
            ),
            strokeWeight: 1,
            strokeColor: "#ffffff",
            scale: 0.055
          },
          title: `${(new Date(loc.locationDateUTC)).toString("dd/MM/yyyy HH:mm")}`,
        });
        bounds.extend(latLng);
        googleMap.fitBounds(bounds);
      });

      let currentZoom = googleMap.getZoom();
      if (currentZoom > 6) {
        googleMap.setZoom(6);
      }
    }

    setUpMarkers();

  }, [observationLocations])

  return (
    <div ref={ref} id="map" style={{ height: '100%', borderRadius: '4px' }} />
  );
}

export default ConsignmentMap;