import { DetailedDeckRecord } from "./DetailedDeckRecord";

export class DeckRecordsAdditionalQuestions {
    hasSheepHotSpots = false;
    hasSheepPanting = false;
    hasSheepScabbyMouth = false;
    hasCattlePanting = false;

    sheepHotSpotsValid = true;
    sheepPantingValid = true;
    sheepScabbyMouthValid = true;
    cattlePantingValid = true;

    sheepDecks = []; // array of AdditionalQuestionsSheepDeck
    cattleDecks = []; // array of AdditionalQuestionsCattleDeck

    constructor(hasSheepHotSpots, hasSheepPanting, hasSheepScabbyMouth, hasCattlePanting, sheepDecks, cattleDecks) {
        this.hasSheepHotSpots = hasSheepHotSpots;
        this.hasSheepPanting = hasSheepPanting;
        this.hasSheepScabbyMouth = hasSheepScabbyMouth;
        this.hasCattlePanting = hasCattlePanting;
        this.sheepDecks = sheepDecks;
        this.cattleDecks = cattleDecks;
    }
}

export class AdditionalQuestionsSheepDeck extends DetailedDeckRecord {
    hasSheepHotSpots = false;
    hasSheepPanting = false;
    hasSheepScabbyMouth = false;
    
    isValidHotSpots = true;
    isValidPanting = true;
    isValidScabbyMouth = true;

    constructor(detailedDeckRecord, hasSheepHotSpots, hasSheepPanting, hasSheepScabbyMouth) {
        super(detailedDeckRecord);
        this.hasSheepHotSpots = hasSheepHotSpots;
        this.hasSheepPanting = hasSheepPanting;
        this.hasSheepScabbyMouth = hasSheepScabbyMouth;
    }
}

export class AdditionalQuestionsCattleDeck extends DetailedDeckRecord {
    hasCattlePanting = false;
    isValidPanting = true;
    
    constructor(detailedDeckRecord, hasCattlePanting) {
        super(detailedDeckRecord);
        this.hasCattlePanting = hasCattlePanting;
    }
}