import '../css/historicalconsignments.css';
import React, {useContext, useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Stack, FormControl, FormSelect, Button } from "react-bootstrap";
import { format } from 'date-fns';
import LoggedInContainer from "../layout/LoggedInContainer";
import { UserContext } from '..';
import { getAllHistoricalConsignmentsVisibleForLoggedInUserID, getConsignmentsIDsMatchingSearch } from '../api/apiAccess';
import { faBriefcaseClock, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateTimePicker from '../components/DateTimePicker';

let searchSequence = 0;

const HistoricalConsignments = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [searchTerm, setSearchTerm ] = useState("");
    const [filteredHistoricalConsignments, setFilteredHistoricalConsignments] = useState([]);
    const [searchedHistoricalConsignments, setSearchedHistoricalConsignments] = useState([]);
    const [visibleHistoricalConsignments, setVisibleHistoricalConsignments] = useState([]);
    const [historicalConsignments, setHistoricalConsignments] = useState([]);
    const [selectedVesselFilterOption, setSelectedVesselFilterOption] = useState("all");
    const [selectedTimePeriodFilterOption, setSelectedTimePeriodFilterOption] = useState("all")
    const [selectedDeparturePortFilterOption, setSelectedDeparturePortFilterOption] = useState("all");
    const [selectedFinalPortFilterOption, setSelectedFinalPortFilterOption] = useState("all");
    const [vesselOptions, setVesselOptions] = useState([]);
    const [departurePortOptions, setDeparturePortOptions] = useState([]);
    const [finalPortOptions, setFinalPortOptions] = useState([]);
    const [selectedCustomTimePeriodStartDate, setSelectedCustomTimePeriodStartDate] = useState(new Date());
    const [selectedCustomTimePeriodEndDate, setSelectedCustomTimePeriodEndDate] = useState(new Date());
    const [earliestDischargeDate, setEarliestDischargeDate] = useState(new Date());
    const [isCustomDateFiltering, setIsCustomDateFiltering] = useState(false);

    useEffect(() => {
        const fetchAllHistoricalConsignments = async() => {
            let results = await getAllHistoricalConsignmentsVisibleForLoggedInUserID(userCtx.associatedExporter.exporterID);
            if (results?.status === 200) {
                setHistoricalConsignments(results.data);
                setFilteredHistoricalConsignments(results.data);
                setSearchedHistoricalConsignments(results.data);
                let lVesselOptions = [...new Set(results.data.map(r => r.vesselName))];
                setVesselOptions(lVesselOptions);
                let lDeparturePortOptions = [...new Set(results.data.map(r => r.departurePortName))];
                setDeparturePortOptions(lDeparturePortOptions);
                let lFinalPortOptions = [...new Set(results.data.map(r => r.dischargePortName))];
                setFinalPortOptions(lFinalPortOptions);
            }
        }
        if (userCtx.associatedExporter && Object.keys(userCtx.associatedExporter).length > 0) {
            fetchAllHistoricalConsignments();
        }
    }, [userCtx.associatedExporter])

    useEffect(() => {
        let allDates = historicalConsignments?.map(hc => new Date(hc.dischargeCompletedDateUTC));
        if (allDates.length === 0) return;
        let orderedDates = allDates.sort((a,b) => Date.parse(a) > Date.parse(b));
        if (orderedDates[0] && orderedDates[0] instanceof Date) {
            setEarliestDischargeDate(orderedDates[0]);
        }
    }, [historicalConsignments])

    useEffect(() => {
        let visible = searchedHistoricalConsignments.filter(shc => filteredHistoricalConsignments.map(fhc => fhc.consignmentID).includes(shc.consignmentID));
        setVisibleHistoricalConsignments(visible);
    }, [filteredHistoricalConsignments, searchedHistoricalConsignments])

    useEffect(() => {
        searchSequence++;
        if (searchTerm.length > 2) {
            searchConsignments(searchTerm);
        } else {
            clearSearch();
        }
    }, [searchTerm])

    useEffect(() => {
        filterConsignments();
    }, [selectedVesselFilterOption, selectedDeparturePortFilterOption, selectedFinalPortFilterOption, selectedCustomTimePeriodEndDate, selectedCustomTimePeriodStartDate])

    useEffect(() => {
        if (selectedTimePeriodFilterOption === "all") {
            let startDate = earliestDischargeDate;
            let endDate = new Date();
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }

        if (selectedTimePeriodFilterOption === "thisyear") {
            // set the start period to this year and end period to now
            let startDate = new Date(new Date().getFullYear(), 0, 1);
            let endDate = new Date(new Date().getFullYear(), 11, 31);
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }

        if (selectedTimePeriodFilterOption === "lastyear") {
            // set the start period to 1/1 last year and the end period to 31/12
            let startDate = new Date(new Date().getFullYear() - 1, 0, 1);
            let endDate = new Date(new Date().getFullYear() - 1, 11, 31);
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }
    }, [selectedTimePeriodFilterOption])

    const goToViewHistoricalConsignment = (consignmentID) => {
        navigate(`/consignmentdashboard/${consignmentID}`, {state: {isHistorical: true}});
    }

    const searchConsignments = async (aSearchTerm) => {
        let results = await getConsignmentsIDsMatchingSearch(aSearchTerm, userCtx.associatedExporter.exporterID, searchSequence);
        if (results.status === 200) {
            if (results.data.searchSequence === searchSequence) {
                let searchResults = filteredHistoricalConsignments.filter(c => results.data.relevantConsignmentIDs.includes(c.consignmentID));
                setSearchedHistoricalConsignments(searchResults);
            }
        }
    }

    const clearSearch = () => {
        setSearchedHistoricalConsignments(filteredHistoricalConsignments);
    }

    const filterConsignments = () => {

        let filteredResults = [...historicalConsignments].filter(c => {
            return (
                (selectedVesselFilterOption === "all" || c.vesselName === selectedVesselFilterOption) &&
                (selectedDeparturePortFilterOption === "all" || c.departurePortName === selectedDeparturePortFilterOption) &&
                (selectedFinalPortFilterOption === "all" || c.dischargePortName === selectedFinalPortFilterOption) &&
                (selectedTimePeriodFilterOption === "all" || (selectedCustomTimePeriodStartDate <= new Date(c.dischargeCompletedDateUTC) && selectedCustomTimePeriodEndDate >= new Date(c.dischargeCompletedDateUTC)))
            )
        }
            
        );
        setFilteredHistoricalConsignments(filteredResults);
        
    }

    const clearConsignmentFilter = () => {
        setSelectedVesselFilterOption("all");
        setSelectedDeparturePortFilterOption("all");
        setSelectedFinalPortFilterOption("all");
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{backgroundColor: "#F5F5F5"}}>
                <Row className="upper-panel">
                    <Container className="mt-5">
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{color: "#FFF", lineHeight: "53px", fontSize: "35px"}}>View Consignments</div>                          </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid">
                        <Row>
                            <Col xs={5}>
                                <Stack direction="vertical">
                                <span className='field-label'>Search</span>
                                    <FormControl 
                                        placeholder="Type a word to search, ie tag number, issue..."
                                        onChange={(e) => {
                                            let text = e.target.value;
                                            setSearchTerm(text);
                                        }}
                                    />
                                </Stack>
                            </Col>
                            <Col xs={7}>
                                <Stack direction="vertical">
                                    <span className='field-label'>Filter consignments by</span>
                                    <Stack direction="horizontal" gap={2}>
                                        <FormSelect
                                            onChange={(e) => {
                                                if (e.target.value === "custom") {
                                                    setIsCustomDateFiltering(true);
                                                } else {
                                                    setIsCustomDateFiltering(false);
                                                }
                                                setSelectedTimePeriodFilterOption(e.target.value);
                                            }}
                                            value={selectedTimePeriodFilterOption}
                                        >
                                            <option value="all">All Time Periods</option>
                                            <option value="thisyear">This year</option>
                                            <option value="lastyear">Last year</option>
                                            <option value="custom">Custom dates</option>
                                        </FormSelect>
                                        <FormSelect
                                            onChange={(e) => {
                                                setSelectedVesselFilterOption(e.target.value);
                                            }}
                                            value={selectedVesselFilterOption}
                                        >
                                            <option value="all">All Vessels</option>
                                            {
                                                vesselOptions && vesselOptions.length > 0 && vesselOptions.map((vo, idx) => {
                                                    return (<option key={`vessel_opt_${idx}`} value={vo}>{vo}</option>)
                                                })
                                            }
                                        </FormSelect>
                                        <FormSelect
                                            onChange={(e) => {
                                                setSelectedDeparturePortFilterOption(e.target.value);
                                            }}
                                            value={selectedDeparturePortFilterOption}
                                        >
                                            <option value="all">All departure ports</option>
                                            {
                                                departurePortOptions && departurePortOptions.map((dp, idx) => {
                                                    return (<option key={`dep_port_opt_${idx}`} value={dp}>{dp}</option>)
                                                })
                                            }
                                        </FormSelect>

                                        <FormSelect
                                            onChange={(e) => {
                                                setSelectedFinalPortFilterOption(e.target.value);
                                            }}
                                            value={selectedFinalPortFilterOption}
                                        >
                                            <option value="all">All final ports</option>
                                            {
                                                finalPortOptions && finalPortOptions.map((fp, idx) => {
                                                    return (<option key={`final_port_opt_${idx}`} value={fp}>{fp}</option>)
                                                })
                                            }
                                        </FormSelect>
                                        <Button
                                            onClick={clearConsignmentFilter}
                                            className="btn-transparent"
                                            style={{lineHeight: "20px"}}
                                        >
                                            <FontAwesomeIcon icon={faXmarkCircle}/>
                                        </Button>
                                    </Stack>
                                    {
                                        isCustomDateFiltering ? (
                                            <Stack direction="horizontal" className="mt-2">
                                                
                                                
                                                <Stack direction="vertical">
                                                    <Row>
                                                        <Col xs={1}>
                                                            <div style={{lineHeight:"40px"}}>From </div> 
                                                        </Col>
                                                        <Col xs={11}>
                                                            <DateTimePicker
                                                                canwrite={true}
                                                                datetime={selectedCustomTimePeriodStartDate}
                                                                showTime={false}
                                                                showUTCTime={false}
                                                                onChange={(date) => {
                                                                    setSelectedCustomTimePeriodStartDate(date);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <div style={{lineHeight:"40px"}}>To</div> 
                                                        </Col>
                                                        <Col xs={11}>
                                                            <DateTimePicker
                                                                canwrite={true}
                                                                datetime={selectedCustomTimePeriodEndDate}
                                                                showTime={false}
                                                                showUTCTime={false}
                                                                onChange={(date) => {
                                                                    setSelectedCustomTimePeriodEndDate(date);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                
    
                                                </Stack>
                                            
                                            </Stack>
                                        ) : null
                                    }
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="pt-1">
                            <Container fluid>
                                <Row>
                                    <Col xs={1} className="hc-table-header"><div className="field-label hc-table-header-contents">Departure</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">Vessel</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">LNC</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">Departure port</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">Final port</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">Discharge date of final port</div></Col>
                                    <Col xs={1}></Col>
                                </Row>
                                {
                                    visibleHistoricalConsignments && visibleHistoricalConsignments.length > 0 && visibleHistoricalConsignments.map((hc, i) => {
                                        return (
                                            <Row key={`historical_consignment_${i}`}>
                                                <Col xs={1} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {format(new Date(hc.vesselDepartedDateUTC), "dd/MM/yyyy")}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {
                                                            hc.vesselName
                                                    
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        LNC-{hc.lncNumber}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {`${hc.departureCountryName} - ${hc.departurePortName}`}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                    {`${hc.dischargeCountryName} - ${hc.dischargePortName}`}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {format(new Date(hc.dischargeCompletedDateUTC), "dd/MM/yyyy")}
                                                    </div>
                                                </Col>
                                                <Col xs={1} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        <Button
                                                            onClick={() => {
                                                                goToViewHistoricalConsignment(hc.consignmentID);
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                                {
                                    visibleHistoricalConsignments && visibleHistoricalConsignments.length === 0 && searchTerm.length > 2 ? (
                                        <div className="text-center mt-5 pt-3 field-label">
                                            No historical consignments match your search criteria.
                                        </div>
                                    ) : null
                                }
                                {
                                    visibleHistoricalConsignments && visibleHistoricalConsignments.length === 0 && searchTerm.length < 2 ? (
                                        <div className="text-center mt-5 pt-3 field-label">
                                            There are no historical consignments.
                                        </div>
                                    ) : null
                                }
                                
                            </Container>
                        </Row>
                    </Container>
                </Row>
                
            </Container>
        </LoggedInContainer>
    )
}

export default HistoricalConsignments;