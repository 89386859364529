export const sortByProperty = (arr, propString) => {
    return arr.sort((a, b) => (a[propString] > b[propString]) ? 1 : -1);
}

export const sortByPropertyDesc = (arr, propString) => {
    return arr.sort((a, b) => (a[propString] > b[propString]) ? -1 : 1);
}

export const sortByPropertyAlphanumeric = (arr, propString, alphaFirst = true) => {
    let arrayOne = arr.filter(v => {
        return alphaFirst ? isNaN(Number(v[propString])) : !isNaN(Number(v[propString]));
    });
    let arrayTwo = arr.filter(v => {
        return alphaFirst ? !isNaN(Number(v[propString])) : isNaN(Number(v[propString]));
    });
    let arrayOneSorted = sortByProperty(arrayOne, propString);
    let arrayTwoSorted = sortByProperty(arrayTwo, propString);
    let fullySortedArray = arrayOneSorted.concat(arrayTwoSorted);
    return fullySortedArray;
}

export const sortByTwoProperties = (arr, propAString, propBString) => {
    return arr.sort((a, b) => (a[propAString] > b[propAString]) ? 1 : (a[propBString] > b[propBString] ? 1 : -1));
}

// This sorts an array based on an IsBridgeProperty first, then performs numeric descending sorting followed by alphanumeric sorting
// The deckNamePropPath argument is the path to where the deck name is within the object
// The isBridgePropPath argument is the path to where the deck name is within the object
// This can be in dot notation. e.g. If the property is nested you can do things like ConsignmentDeck.deckName
export const sortArrayInStandardDeckOrder = (array, deckNamePropPath, isBridgePropPath) => {
    const getNestedProperty = (obj, path) => {
        const keys = path.split('.');
        let currentObject = obj;

        for (const key of keys) {
            if (currentObject && currentObject.hasOwnProperty(key)) {
                currentObject = currentObject[key];
            } else {
                return undefined; // Property not found
            }
        }

        return currentObject;
    }

    const customSort = (a, b) => {
        // Get deck values
        let deckAIsBridge = getNestedProperty(a, isBridgePropPath);
        let deckAName = getNestedProperty(a, deckNamePropPath);

        let deckBIsBridge = getNestedProperty(b, isBridgePropPath);
        let deckBName = getNestedProperty(b, deckNamePropPath);

        let collator = new Intl.Collator('en', { numeric: true, usage: 'sort', sensitivity: 'base' });

        // Sort on isBridge - bridge should always be first
        if (deckAIsBridge !== undefined && deckBIsBridge !== undefined) {
            let booleanComparison = deckBIsBridge - deckAIsBridge;

            if (booleanComparison !== 0) {
                return booleanComparison;
            }
        }

        if (deckAName !== undefined && deckBName !== undefined) {
            // if boolean comparison is the same
            // Sort on deck name numeric descending - highest numbers should go first
            let numericComparison = parseInt(deckBName, 10) - parseInt(deckAName, 10);

            if (numericComparison !== 0) {
                return numericComparison;
            }

            // If numeric comparison ins the same
            // sort on deck name alphabet ascending
            let alphaComparison = collator.compare(deckAName, deckBName);
            return alphaComparison;
        }

        // If neither property exists keep decks in whatever order they were passed in
        return 0;

    };
    return array.sort(customSort);
}
export const createUniqueObjectArrayByProperty = (arr, property) => {
    const uniqueArray = [];
    return arr.reduce((acc, obj) => {
        if (!uniqueArray.find(o => o[property] === obj[property])) {
            uniqueArray.push(obj);
        }
        return uniqueArray;
    }, uniqueArray);
}