import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Stack, Spinner } from "react-bootstrap";
import DeckTemperatureRecord from "../../components/DeckTemperatures/DeckTemperatureRecord";
import { sortArrayInStandardDeckOrder, sortDecksInStandardDeckOrder } from "../../utils/ListUtils";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { getDeckTemperatureForConsignmentDay, setEditLog, updateDeckTemperature } from "../../api/apiAccess";
import EditLogModal from "../../components/EditLogModal";
import { EditLog } from "../../classes/EditLog";
import { UserContext } from "../..";
import { getEditPageReasons } from "../../api/apiAccess";
import ReadWriteButton from "../../components/ReadWriteElements/ReadWriteButton";
import { isReportEditable } from "../../utils/common";

const Edit_Deck_Temperatures = () => {
    const { state } = useLocation();
    const userCtx = useContext(UserContext);
    const [triggerValidation, setTriggerValidation] = useState(0);
    const [deckTemperatures, setDeckTemperatures] = useState([]);
    const [hasPrevDeckTemperatures, setHasPrevDeckTemperatures] = useState(false);
    const [triggerAwaitingSubmission, setTriggerAwaitingSubmission] = useState(0);
    const [editModalShow, setEditModalShow] = useState(false);
    const [editPageReasons, setEditPageReasons] = useState([]);
    const [isLoading, setISLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEditPageReasons = async () => {
            let results = await getEditPageReasons();
            if (results.status === 200) {
                let reasons = results.data;
                setEditPageReasons(reasons);
            }
        }

        fetchEditPageReasons();
    }, [])

    const goToBack = () => goToPreviousDeckTemperatures();
    const goToOK = (e) => {
        e.preventDefault();
        performComponentLevelInputValidation();
        setEditModalShow(true);
    }
    const goToPreviousDeckTemperatures = () => {
        navigate(`/viewhistorydata/${state.consignmentDay.consignmentID}`, { state: { focusedTab: "temperatures", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx,state.allowEditing) } })
    }

    const onEditModalClosed = () => {
        setEditModalShow(false);
    }

    const onEditModalSaveSuccess = async () => {
        setTriggerAwaitingSubmission(triggerAwaitingSubmission + 1);
    }

    const onEditModalSaveFailed = () => {
        // Show logging error
    }

    const deckColourGrey = 'rgba(245,245,245,0.45)';
    const deckColourWhite = '#FFFFFF';

    useEffect(() => {
        //todo this could be changed to make a call to get vouage decks and anfother to get back temperatures for the specified day rather than all temperatures
        const fetchVoyageDeckDetails = async () => {
            let results = await getDeckTemperatureForConsignmentDay(state.consignmentDay.id);
            if (results?.status === 200) {
                let mappedDeckTemperatures = results.data.deckTemperatures.map(rd => {
                    let tempRD = rd;
                    let lMatchingDeck = results.data.consignmentDecks.find(deck => Number(deck.id) === Number(rd.consignmentDeckID));
                    tempRD.name = lMatchingDeck.deckName;
                    tempRD.isBridge = lMatchingDeck.isBridge;
                    return tempRD;
                })
                
                let sortedVoyageDecks = sortArrayInStandardDeckOrder(mappedDeckTemperatures, "name", "isBridge");
                sortedVoyageDecks.forEach((vd, index) => {
                    vd.shading = (index % 2 === 0) ? deckColourWhite : deckColourGrey;
                    vd.isValid = false;
                })

                setDeckTemperatures(sortedVoyageDecks);
            }
        }

        const fetchAll = async () => {
            await fetchVoyageDeckDetails();
            setISLoading(false);
        }
        setISLoading(true);
        fetchAll();
    }, []);

    useEffect(() => {
        if (triggerAwaitingSubmission === 0) return;
        if (deckTemperatures.find(dt => dt.isValid === false)) return;

        const saveData = async () => {
            let success = true;
            deckTemperatures.forEach(async dt => {
                let parsedDeckTemperature = parseDeckTemperatureObjectForSaving(dt);
                let updatedObject = await updateDeckTemperature(parsedDeckTemperature);
                if (!updatedObject || updatedObject === false) {
                    success = false;
                }
            });
            return success;
        }

        const doSaveData = async () => {
            let dataSaved = await saveData();
            if (dataSaved) {
                navigate(`/viewhistorydata/${state.consignmentDay.consignmentID}`, { state: { focusedTab: "temperatures", selectedColumnIndex: state?.selectedColumnIndex, editable: isReportEditable(userCtx,state.allowEditing) } });
            } else {
                // Report error saving data
            }
        }

        doSaveData();
    }, [triggerAwaitingSubmission])

    const parseDeckTemperatureObjectForSaving = (dt) => {
        let parsedObj = {
            ID: Number(dt.id),
            ConsignmentDeckID: Number(dt.consignmentDeckID),
            ConsignmentDayID: Number(dt.consignmentDayID),
            DryBulbTemperatureMax: Number(dt.dryBulbTemperatureMax),
            DryBulbTemperatureMin: Number(dt.dryBulbTemperatureMin),
            WetBulbTemperatureMax: Number(dt.wetBulbTemperatureMax),
            WetBulbTemperatureMin: Number(dt.wetBulbTemperatureMin),
            RelativeHumidityMax: Number(dt.relativeHumidityMax),
            RelativeHumidityMin: Number(dt.relativeHumidityMin)
        };
        if (parsedObj.ID === undefined) {
            delete parsedObj.ID;
        }
        return parsedObj;
    }


    const onChangeData = (data) => {
        onUpdateData(data);
    }

    const onUpdateData = (submissionData) => {
        let tempDeckTemperatures = [...deckTemperatures];
        let existingTemp = tempDeckTemperatures.find(tdt => Number(tdt?.id) === Number(submissionData?.id) || Number(tdt?.voyageDeckID) === Number(submissionData?.voyageDeckID));
        if (existingTemp) {
            existingTemp = submissionData;
        } else {
            tempDeckTemperatures.push(submissionData);
        }
        setDeckTemperatures(tempDeckTemperatures);
    }

    const performComponentLevelInputValidation = () => {
        setTriggerValidation(triggerValidation + 1);
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>
                                    {isReportEditable(userCtx, state.allowEditing) ? "Edit Deck Temperatures" : "View Deck Temperatures"}
                                </div>
                                <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", lineHeight: "53px", fontSize: "24px" }}>Day {state.consignmentDay.dayNumber}</div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid">
                        {
                            isLoading ?
                                <Row>
                                    <Col>
                                        <div className="text-center pt-5">
                                            <Spinner className="mx-auto" />
                                            <div className="mx-auto">Loading data...</div>
                                        </div>
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            isLoading === false ?
                                <>
                                    <Row>
                                        <Col xs={4}>
                                            {hasPrevDeckTemperatures ? (
                                                <div onClick={goToPreviousDeckTemperatures} style={{ textDecoration: "underline", textAlign: 'right', cursor: 'pointer' }}>VIEW PREVIOUS TEMPERATURES</div>
                                            ) : null
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col xs={3}></Col>
                                        <Col xs={3}>
                                            <Row>
                                                <Col xs={10} style={{ fontSize: '15px' }} className="text-center field-label">Dry Bulb Temperature</Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3}>
                                            <Row>
                                                <Col xs={10} style={{ fontSize: '15px' }} className="text-center field-label">Wet Bulb Temperature</Col>
                                            </Row>
                                        </Col>
                                        <Col xs={3}>
                                            <Row>
                                                <Col xs={10} style={{ fontSize: '15px' }} className="text-center field-label">Relative Humidity</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        deckTemperatures ? (
                                            deckTemperatures.map(d => {
                                                let temperatureObj = d;

                                                return <DeckTemperatureRecord deck={d} temperatures={temperatureObj} key={d.name} requestValidation={triggerValidation} onChangeData={onChangeData} valid={d.isValid} />
                                            })
                                        ) : null
                                    }
                                </>
                                : null
                        }
                    </Container>
                </Row>
                {
                    isLoading === false ?
                        <Row className='mt-5' style={{ position: "relative", top: "-300px" }}>
                            <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                                <ReadWriteButton writeonly={false} readonlytext="Back" variant="secondary" onClick={goToBack} size="lg" style={{ minWidth: "150px" }}>Cancel</ReadWriteButton>
                                {isReportEditable(userCtx, state.allowEditing) &&
                                    <ReadWriteButton writeonly={true} variant="primary" onClick={goToOK} size="lg" style={{ minWidth: "150px" }}>Update</ReadWriteButton>
                                }
                            </Stack>
                        </Row>
                        : null
                }

                <EditLogModal
                    title="Edit deck temperature data"
                    page="Deck Temperature"
                    userID={userCtx.user.id}
                    consignmentIDs={[state.consignmentDay.consignmentID]}
                    showModal={editModalShow}
                    reasonOptions={editPageReasons}
                    onClose={onEditModalClosed}
                    onSaveSuccess={onEditModalSaveSuccess}
                    onSaveFail={onEditModalSaveFailed}
                />

            </Container>
        </LoggedInContainer>
    )
}

export default Edit_Deck_Temperatures;