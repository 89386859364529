import { faCircle as faCircleHollow } from "@fortawesome/free-regular-svg-icons";
import { faCircle as faCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
const ConsignmentProgression = ({stages, currentStage}) => {

    const getLabelLeftMargin = (index) => {
        if (index === 0) return "1px";
        if (index === 4) return "0px";
        return "0.5px";
    }
    return (

        <Container style={{width: "500px"}}>
            {
                stages ? (
                    <Row>
                        
                        {
                            stages.map((s, index) => {
                                return (

                                    <Col key={`consignment_progress_indicator_${s.title}`} xs={3} className="mt-1 text-center">
                                        { index !== stages.length - 1 ? 
                                            (
                                                    <div style={{position: "relative", width: "calc(500/3)", top: "14px", height: "2px", backgroundColor: "rgba(245,245,245,0.45)", marginLeft: "calc(50% + 14px)", marginRight: "calc(-50% - 11px)"}} />
                                            ) : null
                                        }
                                        <div className="w-100">

                                            {s.title === currentStage?.title ? 
                                                (
                                                    <>
                                                        <FontAwesomeIcon className="primary-green" icon={faCircleSolid} style={{width: "27px", height: "27px"}}/>
                                                        <div className="m-0 p-0" style={{position:"relative", top: "-27px", left: getLabelLeftMargin(index), height: "0px", fontSize: "12px", color: "#FFF"}}>{index + 1}</div>
                                                    </>
                                                ) : null
                                            }
                                            { s.title !== currentStage?.title && s.completed === true ? 
                                                (
                                                    <>
                                                        <FontAwesomeIcon className="primary-green" icon={faCircleCheck} style={{width: "27px", height: "27px"}}/>
                                                    </>
                                                ) : null
                                            }
                                            { s.title !== currentStage?.title && s.completed !== true ? 
                                                ( 
                                                    <>
                                                        <FontAwesomeIcon icon={faCircleHollow} style={{color: "rgba(245,245,245,0.45)", width: "27px", height: "27px"}}/>
                                                        <div className="m-0 p-0" style={{position:"relative", top: "-27px", left: getLabelLeftMargin(index), height: "0px", fontSize: "12px", color: "rgba(245,245,245,0.45)"}}>{index + 1}</div>
                                                    </>
                                                ):null
                                            }
                                        </div>
                                        <div className="w-100 white-text mt-1" style={{fontSize: "12px"}}>
                                            {s.title}
                                        </div>
                                    </Col>

                                    )
                                })
                            }
                            
                    </Row>
                ): null
            }
        </Container>

    )
}

export default ConsignmentProgression;