import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Stack, Form, Alert, InputGroup } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { setUserPassword } from "../../api/apiAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { isValidPassword } from "../../utils/InputValidator";

const Set_Password = () => {
    const [searchParams] = useSearchParams();
    const [type, setType] = useState("new");
    const [encodedEmail, setEncodedEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordError, setPasswordError] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [isStockPerson, setIsStockPerson] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (searchParams && searchParams.get('em') && searchParams.get('t') && (searchParams.get('t') === "new" || searchParams.get('t') === "reset")) {
            setEncodedEmail(searchParams.get('em'));
            setType(searchParams.get('t'));
            setIsStockPerson(searchParams.get('stkp').toLowerCase() === "true");
        }
        else {
            navigate("/");
        }
    }, []);

    const validateAndCreateAccount = () => {
        setShowErrorMessage(false);
        setShowSuccessMessage(false);

        const passwordsMatch = (password, passwordConfirmation) => {
            return password === passwordConfirmation;
        }

        const updatePassword = async () => {
            let result = await setUserPassword(encodedEmail, password);

            if (result.status === 200) {
                setShowSuccessMessage(true);
                setPassword("");
                setPasswordConfirmation("");
            }
            else if (result.status === 400) {
                setShowErrorMessage(true);
                setErrorMessage(result.data);
            }
            else {
                setShowErrorMessage(true);
                setErrorMessage("Unable to set password.");
            }
        }

        let lIsValid = false;
        if (isValidPassword(password) === true) {
            if (passwordsMatch(password, passwordConfirmation)) {
                lIsValid = true;
                setPasswordError("");
            }
            else {
                setPasswordError("Passwords do not match.");
            }
        }
        else {
            setPasswordError("Password must be at least 8 characters, have both uppercase and lowercase letters, have at least 1 number and 1 special character (@$!%*#?&).");
        }

        setPasswordValid(lIsValid);

        if (lIsValid === true) {
            updatePassword();
        }
    }

    const goToLogin = () => {
        navigate("/");
    }
    return (
        <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
            <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                <Col xs={12} style={{ paddingTop: "75px" }}>
                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>
                            {
                                type === "new" ?
                                    <span>Create your new LIVEXCollect account</span>
                                    : <span>Reset your LIVEXCollect account password</span>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Container className="py-4 px-5 form-container-full">
                {
                    type === "new" ?
                        <Row className="mb-3">
                            <Col xs={12}>
                                <div>You can finish the creation of your new LIVEXCollect account by settting up your password.</div>
                            </Col>
                        </Row>
                        : null
                }
                {
                    showSuccessMessage === false ?
                        <>
                            <Row className="mb-3">
                                <Col xs={12} md={4}>
                                    <Stack>
                                        <div className="darkblue field-label">Enter new password</div>
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control
                                                    type={showPassword ? "text" : "password"}
                                                    id={`txtPassword`}
                                                    onChange={(e) => {
                                                        setPasswordValid(true);
                                                        setPassword(e.target.value);
                                                    }}
                                                    className="inputGroupWithAppendLeft"
                                                    value={password}
                                                    isInvalid={passwordValid === false}
                                                />
                                                <InputGroup.Text className={`inputGroupAppend ${passwordValid === false ? "invalidAppend" : ""}`} onClick={() => setShowPassword(!showPassword)}>
                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                </InputGroup.Text>
                                                {
                                                    passwordValid === false ?
                                                        (<div className="validation-error-tooltip">
                                                            <Tooltip
                                                                anchorSelect={`#txtPassword`}
                                                                place="top"
                                                                variant="error"
                                                                content={passwordError}
                                                            />
                                                        </div>
                                                        ) : null
                                                }
                                            </InputGroup>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col xs={12} md={4}>
                                    <Stack>
                                        <div className="darkblue field-label">Re-enter new password</div>
                                        <Form.Group>
                                            <InputGroup>
                                                <Form.Control
                                                    type={showPassword ? "text" : "password"}
                                                    id="txtPasswordConfirmation"
                                                    onChange={(e) => {
                                                        setPasswordValid(true);
                                                        setPasswordConfirmation(e.target.value);
                                                    }}
                                                    value={passwordConfirmation}
                                                    isInvalid={passwordValid === false}
                                                    className="inputGroupWithAppendLeft"
                                                />
                                                <InputGroup.Text className={`inputGroupAppend ${passwordValid === false ? "invalidAppend" : ""}`} onClick={() => setShowPassword(!showPassword)}>
                                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                                </InputGroup.Text>
                                                {
                                                    passwordValid === false ?
                                                        (<div className="validation-error-tooltip">
                                                            <Tooltip
                                                                anchorSelect={`#txtPasswordConfirmation`}
                                                                place="top"
                                                                variant="error"
                                                                content={passwordError}
                                                            />
                                                        </div>
                                                        ) : null
                                                }
                                            </InputGroup>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                        </>
                        : null
                }

                {
                    showErrorMessage ?
                        <Row>
                            <Col>
                                <Alert variant='danger'>{errorMessage}</Alert>
                            </Col>
                        </Row>
                        : null
                }
                {
                    showSuccessMessage ?
                        <Row>
                            <Col>
                                {isStockPerson === true ? 
                                <Alert variant='success'>Your password has been set. Stockpeople and AAVs you will log into the LIVEXCollect Program installed on the vessel</Alert> :
                                <Alert variant='success'>Your password has been set.</Alert>}
                            </Col>
                        </Row>
                        : null
                }

                {
                    showSuccessMessage === false ?
                        <Row>
                            <Col>
                                <Button className="me-2 ms-auto" variant="primary" onClick={() => validateAndCreateAccount()} style={{ minWidth: "150px" }}>
                                    {
                                        type === "new" ?
                                            <span>Create Account</span>
                                            : <span>Update Password</span>
                                    }
                                </Button>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col>
                                {
                                isStockPerson !== true ?
                                    <Button className="me-2 ms-auto" variant="primary" onClick={() => goToLogin()} style={{ minWidth: "150px" }}>
                                        Login to LIVEXCollect
                                    </Button> : null
                                }
                            </Col>
                        </Row>
                }
            </Container>
        </Container>
    )
};

export default Set_Password;