import { parseISO, format } from "date-fns";
import React, { useContext } from "react";
import { Container, Row, Col, Stack, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../..";
import { isOver28Days} from "../../utils/common";
import { UserType } from "../../api/enums/EnumUserType";

const REConsignmentOverview = ({consignment}) => {
    const userCtx = useContext(UserContext);
    const navigate = useNavigate();

    const viewREConsignment = () => {
        navigate(`/re/consignmentdashboard/${consignment.ID}`);
    }
    
    return (
        <Container className="mb-4">
            <Row>
                <Col xs={12}>
                    <div className="green-underline-heading-light px-0">
                        <div className="d-inline-block w-50">{consignment.Exporter.ExporterName} - {consignment.LNCNumber}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <table className="table consignment-table"><tbody>
                        <tr style={{height: "1px"}} className="dashboard-consignment-header-row">
                                <td className="text-center" style={{width: "25%"}}>
                                    <div>
                                        Report created
                                    </div>
                                </td>
                                <td className="text-center" style={{width: "25%"}}>
                                    <div className="m-0 p-0">
                                        In this report
                                    </div>
                                </td>
                                <td className="text-center" style={{width: "25%"}}>
                                    <div className="m-0 p-0">
                                        Action
                                    </div>
                                </td>
                            </tr>
                        <tr>
                            <td className="text-center" style={{height: "1px"}}>
                                <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>{format(parseISO(consignment.ReportCreatedDate), "do MMMM yyyy hh:mmaa")}</div>
                            </td>
                            <td className="text-center">
                                <div>
                                    <Container fluid>
                                        <Row>
                                            <Col xs={12}>
                                                <Stack direction="vertical" >
                                                    <div>{consignment.ConsignmentMortalities.length}</div>
                                                    <div>{consignment.ConsignmentMortalities.length === 1 ? "Mortality" : "Mortalities"}</div>
                                                </Stack>
                                            </Col>
                                            {
                                                /*
                                                TEMPORARY - HIDE REJECTIONS

                                                <Col xs={6}>
                                                    <Stack direction="vertical">
                                                        <div>{consignment.ConsignmentRejections?.reduce((a,b) =>  a + Number(b.NumberOfStock), 0)}</div>
                                                        <div>{consignment.ConsignmentRejections?.reduce((a,b) =>  a + Number(b.NumberOfStock), 0) === 1 ? "Rejection" : "Rejections"}</div>
                                                    </Stack>
                                                </Col>
                                                */
                                            }   
                                        </Row>
                                    </Container>
                                </div>
                            </td>
                            <td className="text-center" style={{height: "1px"}}>
                                <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <Button 
                                        className="btn btn-secondary"
                                        onClick={viewREConsignment}
                                    >
                                        {
                                            (userCtx.user?.writeAccess !== true || userCtx.user?.userTypeID !== UserType.INDUSTRY ) ? "View" : "Edit"
                                        }
                                    </Button>
                                </div>
                            </td>
                        </tr>
                   </tbody> </table>
                </Col>
            </Row>
        
        </Container>
    )
}

export default REConsignmentOverview;