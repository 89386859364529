import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHourglass1 } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState, useContext } from "react";
import { UserContext } from "../..";

export const ViewedOrAwaitingReviewIconWithBackgroundOrGreyBox = ({ isViewed, hasReport }) => {
    const userCtx = useContext(UserContext);
    const [visibleForUserType, setvisibleForUserType] = useState(false);

    // Only industry users can view status
    useEffect(() => {
        let lCanViewStatus = userCtx.user?.userTypeID === 1 || userCtx.user?.userTypeID === 3;
        setvisibleForUserType(lCanViewStatus);
    }, [userCtx.user?.userTypeID])

    return (
        visibleForUserType === true ?
            hasReport === true ?
                isViewed === true ?
                    <div className="bg-faint-success" style={{ display: 'flex', minHeight: '24px', minWidth: '24px', justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faCheck} className="primary-green" />
                    </div>
                    :
                    <div className="bg-faint-warning" style={{ display: 'flex', minHeight: '24px', minWidth: '24px', justifyContent: 'center', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faHourglass1} className="warning-yelllow" />
                    </div>
                :
                <div className="bg-grey" style={{ display: 'flex', height: '24px', width: '24px', justifyContent: 'center', alignItems: 'center' }}></div>
            : null
    )
};

