import React, { useEffect, useId, useState } from "react";
import { Container, Stack } from "react-bootstrap";
import { getAvgFeedPerHeadForConsignmentID } from "../../api/apiAccess";

const AvgFeedOverview = ({consignment, index, isStarted}) => {
    const componentId = useId();
    const [avgFeed, setAvgFeed] = useState(0);

    useEffect(() => {
        if (consignment === undefined || isStarted === false) return;
        
        const fetchAvgFeed = async () => {
            let results = await getAvgFeedPerHeadForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setAvgFeed(results.data);
            }
        }

        fetchAvgFeed();
    }, [consignment, isStarted])

    return (
        <Container fluid key={`avg_feed_overview_${componentId}_${index}`} className="my-auto mx-0 px-auto">
            <Stack direction="vertical">
                <Stack direction="horizontal">
                    <Container fluid className="text-center">
                        { isStarted ? (
                            <>
                                <div style={{display: 'inline-block'}}>{(avgFeed).toFixed(2)}</div><span className="field-label">kg/hd</span>
                            </>
                        ) : (
                            <div style={{display: 'inline-block'}}>-</div>
                        )}
                        
                    </Container>
                </Stack>
            </Stack>
        </Container>
    )
};

export default AvgFeedOverview;