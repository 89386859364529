import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useContext, useEffect } from "react";
import { Row, Col, Container, Button, Modal, Form, Stack, Alert } from "react-bootstrap";
import { patchRegulatoryUser, postRegulatoryUser, patchExporterUser, postExporterUser } from '../../api/apiAccess';
import { StringIsNullUndefinedOrEmpty } from '../../utils/StringUtils';
import { Tooltip } from 'react-tooltip';
import { NumericValidationRules, parseNumericInput, isValidEmail, isValidMobile } from '../../utils/InputValidator';
import { UserContext } from '../..';
import { updateLoggedInUser } from '../../helpers/SessionHelper';

const Exporter_And_Regulatory_Add_Edit_Modal = ({ showModal, setShowModal, user, onUpdate }) => {
    const userCtx = useContext(UserContext);

    const [headerText, setHeaderText] = useState("");
    const [isEditMode, setIsEditMode] = useState(false);

    const [userID, setUserID] = useState(-1);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [permissionLevel, setPermissionLevel] = useState(false);
    const [accessMode, setAccessMode] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [emailAddress, setEmailAddress] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [userType, setUserType] = useState(null);

    const [viewingLoggedInUser, setViewingLoggedInUser] = useState(false);
    const [loggedInUserExporterID, setLoggedInUserExporterID] = useState(null);

    const [showAccessMode, setShowAccessMode] = useState(false);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);

    const numericValidationRules = new NumericValidationRules({ allowLeadingZerosForWholeNumbers: true, allowNegative: false, allowDecimal: false });

    useEffect(() => {
        setShowErrorMessage(false);
        setErrorMessage("");
        setShowSuccessMessage(false);
        setValidFirstName(true);
        setValidLastName(true);
        setValidEmail(true);
        setValidPhone(true);

        if (user) {
            setHeaderText("View/Edit User");
            setIsEditMode(true);
            setUserID(user.id);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmailAddress(user.emailAddress);
            setMobilePhone(user.phoneNumber);
            setIsActive(user.isActive);
            setPermissionLevel(user.isOrgAdmin);
            setAccessMode(user.canWrite);
            setUserType(user.userTypeID);
        }
        else {
            setHeaderText("Add New User");
            clearInputs();
        }

    }, [user]);

    const clearInputs = () => {
        setIsEditMode(false);
        setUserID(null);
        setFirstName("");
        setLastName("");
        setEmailAddress("");
        setMobilePhone("");
        setIsActive(true);
        setPermissionLevel(false);

        let lUserTypeID = userCtx?.user?.userTypeID;

        setAccessMode(false);
        setUserType(lUserTypeID);
    }

    // can't edit logged in users permission level or deactive
    useEffect(() => {
        if (userID === userCtx?.user?.id) {
            setViewingLoggedInUser(true);
        }
        else {
            setViewingLoggedInUser(false);
        }
    }, [userID, userCtx?.user?.id]);

    // Regulatory users are always read only
    useEffect(() => {
        let lUserTypeID = userCtx?.user?.userTypeID;

        if (lUserTypeID === 1) {
            setShowAccessMode(true);
            setAccessMode(true);
            setLoggedInUserExporterID(userCtx.associatedExporter.exporterID);
        }
        else {
            setAccessMode(false);
            setShowAccessMode(false);
            setLoggedInUserExporterID(null);
        }
    }, [userType]);

    const save = () => {
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        setErrorMessage("");
        setValidFirstName(false);
        setValidLastName(false);
        setValidEmail(false);
        setValidPhone(false);

        const saveAndValidate = async () => {
            // validate
            let lValidFirstName = !StringIsNullUndefinedOrEmpty(firstName);
            let lValidLastName = !StringIsNullUndefinedOrEmpty(lastName);

            let lValidEmail = isValidEmail(emailAddress);
            let lValidPhoneNumber = isValidMobile(mobilePhone);

            setValidFirstName(lValidFirstName);
            setValidLastName(lValidLastName);
            setValidEmail(lValidEmail);
            setValidPhone(lValidPhoneNumber);

            if (lValidFirstName && lValidLastName && lValidEmail && lValidPhoneNumber) {
                
                 // parse any boolean values from string to boolean, otherwise we get a bad request when calling the api
                 let lPermissionLevel = JSON.parse(permissionLevel);
                 let lAccessMode = JSON.parse(accessMode);
                 let lIsActive = JSON.parse(isActive);

                let lMode = "add new";
                if (isEditMode) {
                    lMode = "edit";
                }

                // save
                let result;

                if (userType === 1) { // add/edit exporter user
                    if (isEditMode === true) {
                        result = await patchExporterUser(userID, firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lAccessMode, lIsActive);
                    }
                    else {
                        result = await postExporterUser(firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lAccessMode, lIsActive, loggedInUserExporterID);
                    }
                }
                else { // add/edit regulatory user
                    if (isEditMode === true) {
                        result = await patchRegulatoryUser(userID, firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                    }
                    else {
                        result = await postRegulatoryUser(firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                    }
                }
                
                if (result.status == 200) {
                    setShowSuccessMessage(true);
                    onUpdate();

                    if (viewingLoggedInUser === true) {
                        let updatedUser = JSON.parse(JSON.stringify(result.data)); // need json parse and stringify so that the use effect is triggered on the logged in container
                        userCtx.setUser(updatedUser);
                        updateLoggedInUser(updatedUser);
                    }

                    if (isEditMode === false) {
                        clearInputs();
                    }

                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 15000);

                    setTimeout(()=>{
                        setShowModal(false);
                    },1500);
                }
                else if (result.status == 400) {
                    setShowErrorMessage(true);
                    if (result.data && typeof result.data === `string`) {
                        setErrorMessage(result.data);
                    }
                    else {
                        setErrorMessage(`Unable to ${lMode} user details.`);
                    }
                }
                else {
                    setShowErrorMessage(true);
                    setErrorMessage(`Unable to ${lMode} user details.`);
                }
            }
        }
        saveAndValidate();
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <Row className="mb-3">
                                <Col>
                                    <Stack>
                                        <div className="darkblue field-label">First Name</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtFirstName`}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    setValidFirstName(true);
                                                }}
                                                value={firstName}
                                                isInvalid={validFirstName === false}
                                            />
                                            {
                                                validFirstName === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtFirstName`}
                                                            place="top"
                                                            variant="error"
                                                            content="A first name is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col>
                                    <Stack>
                                        <div className="darkblue field-label">Last Name</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtLastName`}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    setValidLastName(true);
                                                }}
                                                value={lastName}
                                                isInvalid={validLastName === false}
                                            />
                                            {
                                                validLastName === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtLastName`}
                                                            place="top"
                                                            variant="error"
                                                            content="A last name is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12}>
                                    <Stack>
                                        <div className="darkblue field-label">Permission Level</div>
                                        <Form.Select
                                            onChange={(e) => {
                                                setPermissionLevel(e.target.value);
                                            }}
                                            disabled={viewingLoggedInUser === true} // can't edit logged in users permission
                                            value={permissionLevel}
                                        >
                                            <option value={false}>Standard</option>
                                            <option value={true}>Administrator</option>
                                        </Form.Select>
                                    </Stack>
                                </Col>
                            </Row>
                            {
                                showAccessMode ?
                                    <Row className="mb-3">
                                        <Col xs={12}>
                                            <Stack>
                                                <div className="darkblue field-label">Access Mode</div>
                                                <Form.Select
                                                    onChange={(e) => {
                                                        setAccessMode(e.target.value);
                                                    }}
                                                    value={accessMode}
                                                    disabled={viewingLoggedInUser === true} // can't edit logged in users access mode
                                                >
                                                    <option value={true}>Full Access</option>
                                                    <option value={false}>Read-Only</option>
                                                </Form.Select>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <Row className="mb-3">
                                <Col xs={12}>
                                    <Stack>
                                        <div className="darkblue field-label">Email Address</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtEmail`}
                                                onChange={(e) => {
                                                    setEmailAddress(e.target.value);
                                                    setValidEmail(true);
                                                }}
                                                value={emailAddress}
                                                isInvalid={validEmail === false}
                                            />
                                            {
                                                validEmail === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtEmail`}
                                                            place="top"
                                                            variant="error"
                                                            content="A valid email address is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={6}>
                                    <Stack>
                                        <div className="darkblue field-label">Mobile Phone</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtPhone`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setMobilePhone(e.target.value);
                                                    setValidPhone(true);
                                                }}
                                                value={mobilePhone}
                                                isInvalid={validPhone === false}
                                            />
                                            {
                                                validPhone === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtPhone`}
                                                            place="top"
                                                            variant="error"
                                                            content="A valid mobile phone number is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            {
                                isEditMode === true ?
                                    <Row className="mb-3">
                                        <Col>
                                            <Stack direction='horizontal' gap={2}>
                                                <div className="darkblue field-label">Is Active</div>
                                                <Form.Group>
                                                    <Form.Check
                                                        onChange={(e) => {
                                                            setIsActive(e.target.checked);
                                                        }}
                                                        disabled={viewingLoggedInUser === true} // can't edit logged in users access mode
                                                        checked={isActive}
                                                    />
                                                </Form.Group>
                                            </Stack>
                                        </Col>
                                    </Row>
                                    : null
                            }
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {
                        showErrorMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='danger'>{errorMessage}</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        showSuccessMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>
                                        {
                                            isEditMode === true ?
                                                " The user has been updated successfully."
                                                :
                                                " The user has been added successfully."
                                        }
                                    </Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => save()}>Save</Button>
                        </Col>
                    </Row>
                </Container>


            </Modal.Footer>
        </Modal>
    )
}

export default Exporter_And_Regulatory_Add_Edit_Modal;