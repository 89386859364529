import '../css/dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button, Stack, Alert, Spinner } from 'react-bootstrap';
import LoggedInContainer from '../layout/LoggedInContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
    getDetailedConsignmentDeparturePort, getGroupedConsignmenLivestockForDeparturePort, getGroupedConsignmenPlannedLivestock, getFeedDetail,
    getDeparturePorts, getConsignmentDeparturePortsForConsignment, getDestinationPorts, getConsignmentDestinationPortsForConsignment, setLoadingAndDepartureReportAsReviewed, getConsignmentDaysForConsignmentID
} from '../api/apiAccess';
import { differenceInHours } from 'date-fns';
import { MediaQueryContext, UserContext } from '..';
import { getPortLocalDatetime, getPortDateTimeUTC, formatUpToSpecifiedPrecision } from '../utils/common';

const Voyage_Departure_And_Vessel_Loading = () => {
    const mediaQueryCtx = useContext(MediaQueryContext);
    const location = useLocation();
    const { consignmentDeparturePortID } = useParams();
    const userCtx = useContext(UserContext);
    const mobileHeaderRef = useRef();

    const navigate = useNavigate();

    const [hasData, setHasData] = useState(false);
    const [loading, setIsLoading] = useState(false);
    const [loadError, setHasLoadError] = useState(false);

    const [lncNumber, setLNCNumber] = useState("");
    const [portName, setPortName] = useState("");
    const [portID, setPortID] = useState("");

    const [departurePorts, setDeparturePorts] = useState([]);
    const [departurePort, setDeparturePort] = useState(undefined);
    const [destinationPorts, setDestinationPorts] = useState([]);

    const [loadingCommenced, setLoadingCommenced] = useState("");
    const [loadingCompleted, setLoadingCompleted] = useState("");
    const [vesselDeparted, setVesselDeparted] = useState("");
    const [loadingForHours, setLoadingForHours] = useState("");

    const [pelletsLoaded, setPelletsLoaded] = useState("");
    const [grainLoaded, setGrainLoaded] = useState("");
    const [roughageLoaded, setRoughageLoaded] = useState("");

    const [robPellets, setRobPellets] = useState("");
    const [robGrain, setRobGrain] = useState("");
    const [robRoughage, setRobRoughage] = useState("");

    const [totalPellets, setTotalPellets] = useState("");
    const [totalGrain, setTotalGrain] = useState("");
    const [totalRoughage, setTotalRoughage] = useState("");

    const [livestockPlanned, setLivestockPlanned] = useState([]);
    const [livestockLoaded, setLivestockLoaded] = useState([]);
    const [displayLivestock, setDisplayLivestock] = useState([]);

    const [livestockSpeciesTotals, setLivestockSpeciesTotals] = useState([]);

    const [canViewEverything, setCanViewEverything] = useState(false);

    const goBack = () => {
        navigate(-1, {state: {isHistorical: location?.state?.isHistorical}})
    }

    useEffect(() => {
        const populatePage = async () => {
            const updateIsReviewedStatus = async (reportID, isReviewed) => {
                if (userCtx.user?.userTypeID === 1 && isReviewed === false) {
                    await setLoadingAndDepartureReportAsReviewed(reportID);
                }
            }

            let lUserTypeID = userCtx.user.userTypeID;

            let lDeparturePort = await getDetailedConsignmentDeparturePort(consignmentDeparturePortID);

            if (lDeparturePort?.status === 200) {
                // regulatory users can only view this page if the day the loading report was competed has been submitted
                // ideally we would handle this at the database level, but this is difficult as the regulatory user still needs to know
                // the port exists even if it has not been submitted
                let lConsignmentDays = await getConsignmentDaysForConsignmentID(lDeparturePort.data.consignmentDeparturePort.consignmentID);
                
                if (lConsignmentDays.status === 200) {
                    let lCanViewPage = true;
                    if (userCtx.user?.userTypeID === 2) {
                        if (lConsignmentDays.data.find(cd => cd.id === lDeparturePort.data.consignmentDeparturePort.consignmentDayID)) {
                            lCanViewPage = true;
                        }
                        else {
                            lCanViewPage = false;
                        }
                    }

                    if (lCanViewPage === true) {
                        setPortName(lDeparturePort.data.port.portName);
                        setPortID(lDeparturePort.data.port.id);
                        setLNCNumber(lDeparturePort.data.lncNumber);
                        setDeparturePort(lDeparturePort.data.port);

                        // set is reviewed to true
                        updateIsReviewedStatus(consignmentDeparturePortID, lDeparturePort.data.consignmentDeparturePort.isLoadingAndDepartureReviewed);

                        setLoadingCommenced(new Date(lDeparturePort.data.consignmentDeparturePort.loadingCommencedDateUTC));
                        setLoadingCompleted(new Date(lDeparturePort.data.consignmentDeparturePort.loadingCompletedDateUTC));
                        setVesselDeparted(new Date(lDeparturePort.data.consignmentDeparturePort.vesselDepartedDateUTC));

                        if (lDeparturePort.data.feedLoadedDetail) {
                            setPelletsLoaded(lDeparturePort.data.feedLoadedDetail.pellets);
                            setGrainLoaded(lDeparturePort.data.feedLoadedDetail.grain);
                            setRoughageLoaded(lDeparturePort.data.feedLoadedDetail.roughage);
                        }

                        if (lDeparturePort.data.feedROBDetail) {
                            setRobPellets(lDeparturePort.data.feedROBDetail.pellets);
                            setRobGrain(lDeparturePort.data.feedROBDetail.grain);
                            setRobRoughage(lDeparturePort.data.feedROBDetail.roughage);
                        }

                        // get other information
                        let lPromises = [
                            getDeparturePorts(),
                            getConsignmentDeparturePortsForConsignment(lDeparturePort.data.consignmentDeparturePort.consignmentID),
                            getDestinationPorts(),
                            getConsignmentDestinationPortsForConsignment(lDeparturePort.data.consignmentDeparturePort.consignmentID),
                            getGroupedConsignmenLivestockForDeparturePort(consignmentDeparturePortID),
                            getFeedDetail(lDeparturePort.data.consignmentDeparturePort.feedLoadedID),
                        ];

                        if (lUserTypeID === 1 || lUserTypeID === 3) {
                            setCanViewEverything(true);
                            lPromises.push(getGroupedConsignmenPlannedLivestock(lDeparturePort.data.consignmentDeparturePort.consignmentID));
                        }
                        else {
                            setCanViewEverything(false);
                        }

                        Promise.all(lPromises)
                            .then(response => {
                                if (response && response.every(resp => resp?.status === 200)) {
                                    let allDeparturePorts = response[0].data;
                                    let lConsignmentDeparturePorts = response[1].data;
                                    let lConsignmentDeparturePortsDetail = [];

                                    lConsignmentDeparturePorts.forEach(port => {
                                        let portDetails = allDeparturePorts.find(p => Number(p.id) === Number(port.portID));
                                        lConsignmentDeparturePortsDetail.push(portDetails);
                                    })
                                    setDeparturePorts(lConsignmentDeparturePortsDetail);

                                    let allDestinationPorts = response[2].data;
                                    let lConsignmentDestinationPorts = response[3].data;
                                    let lConsignmentDestinationPortsDetail = [];

                                    lConsignmentDestinationPorts.forEach(port => {
                                        let portDetails = allDestinationPorts.find(p => Number(p.id) === Number(port.portID));
                                        lConsignmentDestinationPortsDetail.push(portDetails);
                                    })
                                    setDestinationPorts(lConsignmentDestinationPortsDetail);

                                    let lLivestockLoaded = response[4].data;
                                    setLivestockLoaded(lLivestockLoaded);

                                    // Only certain users should see this information
                                    if (lUserTypeID === 1 || lUserTypeID === 3) {
                                        let lLivestockPlanned = response[6].data;
                                        setLivestockPlanned(lLivestockPlanned);
                                    }

                                    let lFeedLoaded = response[5].data;
                                    setGrainLoaded(lFeedLoaded.grain);
                                    setPelletsLoaded(lFeedLoaded.pellets);
                                    setRoughageLoaded(lFeedLoaded.roughage);

                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }).then(result => {
                                setHasData(result);
                                setHasLoadError(!result)
                                setIsLoading(false);
                            })
                            .catch(error => {
                                setHasLoadError(true)
                                setIsLoading(false);
                            })
                    }
                    else {
                        setHasData(false);
                        setHasLoadError(true)
                        setIsLoading(false);
                    }
                }
                else {
                    setHasData(false);
                    setHasLoadError(true)
                    setIsLoading(false);
                }
            }
            else {
                setHasLoadError(true)
                setIsLoading(false);
            }
        }
        if (consignmentDeparturePortID && userCtx.user) {
            setIsLoading(true)
            populatePage();
        }

    }, [consignmentDeparturePortID, userCtx.user])


    useEffect(() => {
        let lDisplayLivestock = [];
        livestockLoaded.forEach(livestock => {
            lDisplayLivestock.push({
                speciesID: livestock.speciesID,
                classID: livestock.classID,
                name: `${livestock.speciesName} - ${livestock.className}`,
                noLoaded: livestock.numberLoaded,
                noPlanned: 0
            });
        })

        livestockPlanned.forEach(plannedLivestock => {
            let lMatch = lDisplayLivestock.find(livestock => livestock.speciesID === plannedLivestock.speciesID && livestock.classID === plannedLivestock.classID);

            if (lMatch) {
                lMatch.noPlanned = plannedLivestock.numberLoaded;
            }
            else {
                lDisplayLivestock.push({
                    speciesID: plannedLivestock.speciesID,
                    classID: plannedLivestock.classID,
                    name: `${plannedLivestock.speciesName} - ${plannedLivestock.className}`,
                    noLoaded: 0,
                    noPlanned: plannedLivestock.numberLoaded
                });
            }
        })

        setDisplayLivestock(lDisplayLivestock);
    }, [livestockPlanned, livestockLoaded])

    useEffect(() => {
        let lSpeciesTotals = [];

        livestockLoaded.forEach(livestock => {
            let lMatch = lSpeciesTotals.find(species => species.id === livestock.speciesID);

            if (lMatch) {
                lMatch.count += livestock.numberLoaded
            }
            else {
                lSpeciesTotals.push({ id: livestock.speciesID, name: livestock.speciesName, count: livestock.numberLoaded });
            }
        })

        setLivestockSpeciesTotals(lSpeciesTotals);
    }, [livestockLoaded])


    useEffect(() => {
        if (loadingCommenced && loadingCompleted) {
            let lHours = differenceInHours(loadingCompleted, loadingCommenced);
            if (lHours === 1) {
                setLoadingForHours(lHours + " hour");
            }
            else {
                setLoadingForHours(lHours + " hours");
            }
        }
    }, [loadingCommenced, loadingCompleted])

    useEffect(() => {
        setTotalPellets(Number(pelletsLoaded) + Number(robPellets));
    }, [pelletsLoaded, robPellets])

    useEffect(() => {
        setTotalGrain(Number(grainLoaded) + Number(robGrain));
    }, [grainLoaded, robGrain])

    useEffect(() => {
        setTotalRoughage(Number(roughageLoaded) + Number(robRoughage));
    }, [roughageLoaded, robRoughage])

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                {
                                    mediaQueryCtx.mobile === true ? (
                                        <Container fluid ref={mobileHeaderRef}>
                                            <Row>
                                                <Col xs={12} style={{ marginTop: "20px" }}>
                                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF"}}>
                                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>View Voyage Departure and Vessel Loading</div>
                                                        <div>LNC- {lncNumber}</div>
                                                    </div>
                                                </Col>
                                                <Col xs={12} className="text-center">
                                                    <Button className="w-50 mt-3" variant="back" onClick={() => goBack()}>
                                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Container>
                                    ) : (
                                        <Col xs={12} style={{ marginTop: "75px" }}>
                                            <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                                <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                            </Button>
                                            <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                                <div style={{ lineHeight: "53px", fontSize: "35px" }}>View Voyage Departure and Vessel Loading</div>
                                                <div>LNC- {lncNumber}</div>
                                            </div>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full" style={{marginTop: mediaQueryCtx.mobile === true ? (mobileHeaderRef?.current?.offsetHeight ? `${mobileHeaderRef?.current?.offsetHeight - 130}px` : "120px") : "inherit"}}>
                    {
                        loading === true ? (
                            <Row>
                                <Col>
                                    <div className="text-center pt-5">
                                        <Spinner className="mx-auto" />
                                        <div className="mx-auto">Loading data...</div>
                                    </div>
                                </Col>
                            </Row>
                        ) : null
                    }
                    {
                        hasData === true ?
                            <>
                                <Row className='mb-4'>
                                    <Col>
                                        <Row className="mt-3">
                                            <Col xs={12}>
                                                <div className="green-underline-heading">Voyage Summary</div>
                                            </Col>
                                            <Row className='mb-3'>
                                                <Col xs={12} md={3}>
                                                    <div className="field-label">
                                                        Loading In
                                                    </div>
                                                    {
                                                        departurePorts.map((port, i) => {
                                                            return (
                                                                <div key={`departurePort_${port.id}`} className={port.id === portID ? "bold text-13-400 secondary-grey" : "text-13-400 secondary-grey"}>{port.portName}</div> ///todo bold port we are currently viewing
                                                            );
                                                        })
                                                    }
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="field-label">
                                                        Discharging In
                                                    </div>
                                                    {
                                                        destinationPorts.map((port, i) => {
                                                            return (
                                                                <div className='text-13-400 secondary-grey' key={`destinationPort_${port.id}`}>{port.portName}, {port.countryName}</div>
                                                            );
                                                        })
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <Stack>
                                                        <div className="field-label">Loading Commenced</div>
                                                        <div className='text-13-400 secondary-grey'>
                                                            {
                                                                loadingCommenced ?
                                                                    getPortDateTimeUTC(departurePorts.find(dp => dp.id === Number(portID)), loadingCommenced) + " UTC"
                                                                    : ""
                                                            }
                                                        </div>
                                                        <div className='text-13-400 secondary-grey'>
                                                            {
                                                                loadingCommenced ? getPortLocalDatetime(departurePort, loadingCommenced) : ""
                                                            }
                                                        </div>
                                                    </Stack>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Stack>
                                                        <div className="field-label">Loading Completed</div>
                                                        <div className='text-13-400 secondary-grey'>
                                                            {
                                                                loadingCompleted ?
                                                                    getPortDateTimeUTC(departurePorts.find(dp => dp.id === Number(portID)), loadingCompleted) + " UTC"
                                                                    : ""
                                                            }
                                                        </div>
                                                        <div className='text-13-400 secondary-grey'>
                                                            {
                                                                loadingCompleted ? getPortLocalDatetime(departurePort, loadingCompleted) : ""
                                                            }
                                                        </div>
                                                        {
                                                            loadingCommenced && loadingCompleted ?
                                                                <div className='italic text-13-400 secondary-grey'>Loading for {loadingForHours}</div>
                                                                : null
                                                        }
                                                    </Stack>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <Stack>
                                                        <div className="field-label">Vessel Departed</div>
                                                        <div className='text-13-400 secondary-grey'>
                                                            {
                                                                vesselDeparted ?
                                                                    getPortDateTimeUTC(departurePorts.find(dp => dp.id === Number(portID)), vesselDeparted) + " UTC"
                                                                    : ""
                                                            }
                                                            <div className='text-13-400 secondary-grey'>
                                                                {
                                                                    vesselDeparted ? getPortLocalDatetime(departurePort, vesselDeparted) : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className='mb-4'>
                                    <Col>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="green-underline-heading">Livestock Loaded</div>
                                            </Col>
                                        </Row>
                                        {
                                            canViewEverything && displayLivestock.some(liv => liv.noLoaded !== liv.noPlanned) ?
                                                <Row>
                                                    <Col>
                                                        <Alert variant='warning'>
                                                            <Stack direction='horizontal' gap={3}>
                                                                <FontAwesomeIcon icon={faExclamationCircle} />
                                                                <div className='text-13-400'>The final number of loaded livestock did not match the planned consignment details.  Please see final loaded values as entered by the stockperson or AAV.</div>
                                                            </Stack>
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                                : null
                                        }
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Row>
                                                    <Col xs={12} md={9}>
                                                        <Row className='pb-1'>
                                                            <Col className="field-label darkblue">Species / Class</Col>
                                                            {
                                                                canViewEverything ?
                                                                    <Col className="field-label darkblue">No. In Plan</Col>
                                                                    : null
                                                            }
                                                            <Col className="field-label darkblue">No. Loaded</Col>
                                                        </Row>
                                                        {
                                                            displayLivestock.map((livestock, i) => {
                                                                return (
                                                                    <Row key={`livestock_loading_${i}`} className='pb-2'>
                                                                        <Col className='text-13-400 secondary-grey'>{livestock.name}</Col>
                                                                        {
                                                                            canViewEverything ?
                                                                                <Col className='text-13-400 secondary-grey'>{livestock.noPlanned}</Col>
                                                                                : null
                                                                        }
                                                                        <Col>
                                                                            <Stack direction='horizontal' gap={2}>
                                                                                <div className='text-13-400 secondary-grey'>{livestock.noLoaded}</div>
                                                                                {
                                                                                    canViewEverything && livestock.noPlanned !== livestock.noLoaded ?
                                                                                        <FontAwesomeIcon icon={faExclamationCircle} style={{ color: "#664d03" }} />
                                                                                        : null
                                                                                }
                                                                            </Stack>
                                                                        </Col>
                                                                    </Row>
                                                                );
                                                            })
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <div className="bg-grey-transparent p-3">
                                                    <div className="field-label mb-2">Livestock Total Loaded</div>
                                                    {
                                                        livestockSpeciesTotals.map((livestock, index) => (
                                                            <Row key={"livestockLoaded" + index} className="mb-2">
                                                                <Col xs={8} className="field-label">{livestock.name}</Col>
                                                                <Col xs={4} className="field-label">{livestock.count}</Col>
                                                            </Row>
                                                        ))
                                                    }
                                                    <Row>
                                                        <Col xs={8} className="field-label">Total Head</Col>
                                                        <Col xs={4} className="field-label">
                                                            {
                                                                livestockSpeciesTotals.reduce((sum, livestock) => sum + livestock.count, 0)
                                                            }
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="green-underline-heading">Feed Loaded</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Row className='mb-1'>
                                                    <Col xs={6}></Col>
                                                    <Col xs={2} className="text-center field-label">Pellets</Col>
                                                    <Col xs={2} className="text-center field-label">Grain</Col>
                                                    <Col xs={2} className="text-center field-label">Roughage</Col>
                                                </Row>
                                                <Row className="mb-2 pb-2 cc-port-loading-feed">
                                                    <Col xs={6} className="cc-feed-plan-row-label">ROB from previous voyage</Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{robPellets}t</div>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{robGrain}t</div>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{robRoughage}t</div>
                                                    </Col>
                                                </Row>
                                                <Row className="mb-3">
                                                    <Col xs={6} className="cc-feed-plan-row-label">Loaded in {portName} (Departure)</Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{pelletsLoaded}t</div>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{grainLoaded}t</div>
                                                    </Col>
                                                    <Col xs={2}>
                                                        <div className='cc-feed-input p-0'>{roughageLoaded}t</div>
                                                    </Col>
                                                </Row>
                                                <Row className="bg-grey-transparent py-2">
                                                    <Col xs={6} className="field-label darkblue" style={{ lineHeight: "27px" }}>Total feed onboard</Col>
                                                    <Col xs={2} className="text-end darkblue" style={{ lineHeight: "27px" }}><span className="darkblue" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalPellets, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                                    <Col xs={2} className="text-end darkblue" style={{ lineHeight: "27px" }}><span className="darkblue" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalGrain, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                                    <Col xs={2} className="text-end darkblue" style={{ lineHeight: "27px" }}><span className="sdarkblue" style={{ fontSize: "13px", fontWeight: "400" }}>{formatUpToSpecifiedPrecision(totalRoughage, 2)}</span><span className="darkblue" style={{ marginLeft: "4px", fontSize: "12px", fontWeight: "500" }}>t</span></Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }
                    {
                        loadError === true && loading === false ?
                            <Row>
                                <Col xs={12}>
                                    <Alert variant='danger'>Unable to load voyage departure and vessel loading report.</Alert>
                                </Col>
                                <Col xs={12}>
                                    <Button
                                        className="me-2 ms-auto"
                                        variant="primary"
                                        onClick={() => {
                                            goBack();
                                        }}
                                        size="lg"
                                        style={{ minWidth: "150px" }}
                                    >
                                        Back
                                    </Button>
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
                {
                    loading === false && hasData === true ?
                        <Container className='mt-5' style={{ position: "relative", top: "-300px" }}>
                            <Row>
                                <Col className='text-center'>
                                    <Button variant='primary' onClick={() => goBack()}>Done</Button>
                                </Col>
                            </Row>
                        </Container>
                        : null
                }
            </Container>
        </LoggedInContainer>
    );
};

export default Voyage_Departure_And_Vessel_Loading;