export class RegisteredEstablishmentConsignmentRejection {
    ID;
    RegisteredEstablishmentConsignmentID;
    SpeciesID;
    ClassID;
    RejectionCriteriaID;
    NumberOfStock;
    constructor({id, registeredEstablishmentConsignmentID, speciesID, classID, rejectionCriteriaID, numberOfStock}) {
        this.ID = id;
        this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
        this.SpeciesID = speciesID;
        this.ClassID = classID;
        this.RejectionCriteriaID = rejectionCriteriaID;
        this.NumberOfStock = numberOfStock;
    }
}