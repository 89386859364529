import React, { useState } from "react";
import { Stack, Button } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const LNCNumberMobile = ({consignment, onViewConsignmentClicked, loadingReports, dischargeReports, eovReport, onLoadingAndDepartureViewed, onDischargeViewed, onEOVViewed}) => {

    return (
        <Stack direction="vertical">
            <Stack direction="horizontal" className="m-0 px-3 py-2 lnc-number-mobile">
                <div className="field-label">LNC-{consignment?.lncNumber}</div>
                <div className="ms-auto">
                    <Button variant="primary-outline" onClick={onViewConsignmentClicked}>
                        <FontAwesomeIcon icon={faEye} style={{color: "#44cb6f"}} />
                    </Button>
                </div>
            </Stack>
            { 
                loadingReports?.length > 0 || dischargeReports?.length > 0 || (eovReport && !eovReport.IsEOVReviewed) ?
                (
                    <Stack direction="vertical" className="mb-3">
                        { 
                            loadingReports?.length > 0 ? 
                            (
                                <>
                                    {
                                        loadingReports?.map((lr, i) => {
                                            if (lr.isReviewed) return;
                                            return (
                                            <Button 
                                                key={`lnc_${consignment.lncNumber}_loading_report_${i}`}
                                                className="mx-5 my-1"
                                                onClick={() => {
                                                    onLoadingAndDepartureViewed(lr.departurePortID);
                                                }}
                                            >
                                                Loading And Departure Report
                                            </Button> 
                                        )})
                                    }
                                </>
                            ): null 
                        }
                        { 
                            dischargeReports?.length > 0 ? 
                            (
                                <>
                                {
                                    dischargeReports?.map((dr, i) =>  {
                                        if (dr.isReviewed) return;
                                        return (
                                            <Button 
                                                key={`lnc_${consignment.lncNumber}_discharge_report_${i}`}
                                                className="mx-5 my-1"
                                                onClick={() => {
                                                    onDischargeViewed(dr.consignment.id, dr.destinationPortID);
                                                }}
                                            >
                                                Discharge Report
                                            </Button>
                                        )
                                    })
                                }
                                </>
                                
                            ) : null 
                        }
                        { 
                            eovReport && !eovReport.IsEOVReviewed ? 
                            (
                                <Button 
                                    className="mx-5 my-1" 
                                    onClick={() => {
                                        onEOVViewed(consignment?.id);
                                    }}
                                >
                                    End of Voyage Report
                                </Button>
                            ) : null 
                        }
                    </Stack>
                ) : null
            }
        </Stack>
    )
}

export default LNCNumberMobile;