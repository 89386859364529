import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { UserContext } from "../..";
import { updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject } from "./ReadWriteProperties";

/*
    Can have reagular element properties, or any custom properties that are part of ReadWriteProperties
*/
const ReadWriteFormSelect = (props) => {
    const userCtx = useContext(UserContext);
    const [writeAccess, setHasWriteAccess] = useState(false);
    const [selectedName, setSelectedName] = useState("");
    const [modifiedProps, setModifiedProps] = useState({});
    const [writeOnly, setWriteOnly] = useState(false);

    useEffect(() => {
        let lWriteAccess = userCtx.user.writeAccess;
        setHasWriteAccess(lWriteAccess);
    }, [userCtx.user]);

    useEffect(() => {
        let lSelected = props.children.find(opt => opt.props.value === props.value);
        if (lSelected) {
            setSelectedName(lSelected.props.children);
        }
        else {
            setSelectedName("-");
        }

        setWriteOnly(props.writeonly === true);
        let lModifiedProps = updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject(props, writeAccess);
        setModifiedProps(lModifiedProps);
    }, [props, writeAccess]);

    return (
        writeOnly === false ?
            writeAccess === true ?
                <Form.Select {...modifiedProps} />
                :
                <div className="text-13-400 secondary-grey">{selectedName}</div>
            : null
    );
};

export default ReadWriteFormSelect;
