import { faCircleDot } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { NumericValidationRules, parseNumericInput } from "../../utils/InputValidator";
import { Tooltip } from "react-tooltip";

const livestockSubcategoriesOptions = [
        {
        "id": 1,
        "speciesId": 1, // Sheep old
        "AselCategoryDescription": [
            {
                "description": "More than four permanent incisors"
            }
        ]
        },
        {
        "id": 2,
        "speciesId": 1, // Sheep young
        "AselCategoryDescription": [
            {
                "description": "Young - up to four permanent incisors"
            }
        ]
        },
        {
        "id": 3,
        "speciesId": 2,
        "dailyFeedReqPercentage": 2,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
        },
        {
        "id": 4,
        "speciesId": 2,
        "dailyFeedReqPercentage": 2.5,
        "AselCategoryDescription": [
            {
                "description": "Less than 250kg"
            },
            {
                "description": "Pregnant"
            },
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
        ]
        },
        {
        "id": 5, 
        "speciesId": 3,
        "dailyFeedReqPercentage": 2,
        "AselCategoryDescription": [
            {
                "description": "Over 250kg and not pregnant"
            }
        ]
        },
        {
        "id": 6,
        "speciesId": 3,
        "dailyFeedReqPercentage": 2.5,
        "AselCategoryDescription": [
            {
                "description": "Less than 250kg"
            },
            {
                "description": "Pregnant"
            },
            {
                "description": "Breeding heifers with six or fewer permanent teeth"
            }
            ]
        },
        {
            "id": 7,
            "speciesId": 4, //Alpacas
            "dailyFeedReqPercentage": 0,
            "AselCategoryDescription": []
        },
        {
            "id": 8,
            "speciesId": 5, //Camels
            "dailyFeedReqPercentage": 0,
            "AselCategoryDescription": []
        },
        {
            "id": 9,
            "speciesId": 6, //Deer
            "dailyFeedReqPercentage": 0,
            "AselCategoryDescription": []
        },
        {
            "id": 10,
            "speciesId": 7, // Goats old
            "AselCategoryDescription": [
                {
                    "description": "More than four permanent incisors"
                }
            ]
        },
        {
            "id": 11,
            "speciesId": 7, // Goats young
            "AselCategoryDescription": [
                {
                    "description": "Young - up to four permanent incisors"
                }
            ]
        }, 
        {
            "id": 12,
            "speciesId": 8, // Llamas
            "AselCategoryDescription": []
        }
    ]


const LivestockDetail = ({livestockDetail, speciesOptions, livestockClassesOptions, onChange, onDelete, triggerValidation}) => {
    const [rowId, setRowId] = useState('');
    const [noOfHead, setNoOfHead] = useState(0);
    const [speciesSubcategoryOldNoHead, setSpeciesSubcategoryOldNoHead] = useState(0);
    const [speciesSubcategoryYoungNoHead, setSpeciesSubcategoryYoungNoHead] = useState(0);
    const [speciesSubcategoryOldAvgWeight, setSpeciesSubcategoryOldAvgWeight] = useState(0);
    const [speciesSubcategoryYoungAvgWeight, setSpeciesSubcategoryYoungAvgWeight] = useState(0);
    const [selectedSpecies, setSelectedSpecies] = useState(1);
    const [selectedLivestockClass, setSelectedLivestockClass] = useState(1);

    const [isNoOfHeadValid, setIsNoOfHeadValid] = useState(true);
    const [isSubcategoryOldNoHeadValid, setIsSubcategoryOldNoHeadValid] = useState(true);
    const [isSubcategoryOldAvgWeightValid, setIsSubcategoryOldAvgWeightValid] = useState(true);
    const [isSubcategoryYoungNoHeadValid, setIsSubcategoryYoungNoHeadValid] = useState(true);
    const [isSubcategoryYoungAvgWeightValid, setIsSubcategoryYoungAvgWeightValid] = useState(true);
    const [isSpeciesValid, setIsSpeciesValid] = useState(true);
    const [isSelectedLivestockClassValid, setIsSelectedLivestockClassValid] = useState(true);
    
    const [initValidationTriggerValue, setInitValidationTriggerValue] = useState(triggerValidation);
    
    const numericValidationRules = new NumericValidationRules({allowLeadingZerosForWholeNumbers: false, allowNegative: false, allowDecimal: false});
    
    useEffect(() => {
        setRowId(livestockDetail.data.rowId);
        
        if (livestockDetail.data.aselCategories?.length > 0) 
        {
            let totalHead = livestockDetail.data.aselCategories.map(asc => asc.numHeadForCategory).reduce((a,b) => Number(a) + Number(b));
            setNoOfHead(totalHead);
        }
        
        if (livestockDetail.data.speciesId && (!isNaN(Number(livestockDetail.data.speciesId)) || livestockDetail.data.speciesId.length > 0) )
        {
            setSelectedSpecies(livestockDetail.data.speciesId);
        }

        if (livestockDetail.data.classId && (!isNaN(Number(livestockDetail.data.classId)) || livestockDetail.data.classId.length > 0)) 
        {
            setSelectedLivestockClass(livestockDetail.data.classId);
        }

        if (livestockDetail.data.aselCategories && livestockDetail.data.aselCategories.length > 0) 
        {
            livestockDetail.data.aselCategories.forEach(cat => {
                let catIndex = livestockDetail.data.aselCategories.indexOf(cat);
                if (cat.numHeadForCategory && cat.numHeadForCategory.toString().length > 0) 
                {
                    if (catIndex === 0) {
                        setSpeciesSubcategoryOldNoHead(cat.numHeadForCategory);
                    }

                    if (catIndex === 1) {
                        setSpeciesSubcategoryYoungNoHead(cat.numHeadForCategory);
                    }
                }

                if (cat.avgWeight && cat.avgWeight.toString().length > 0) 
                {
                    if (catIndex === 0) {
                        setSpeciesSubcategoryOldAvgWeight(cat.avgWeight);
                    }

                    if (catIndex === 1) {
                        setSpeciesSubcategoryYoungAvgWeight(cat.avgWeight);
                    }
                }
            })
            
        }

    }, [livestockDetail]);

    useEffect(() => {
        updateValidateAll();
        notifyUpdateData();
    }, [noOfHead, selectedSpecies, selectedLivestockClass, speciesSubcategoryOldNoHead,speciesSubcategoryYoungNoHead,speciesSubcategoryOldAvgWeight, speciesSubcategoryYoungAvgWeight])

    useEffect(() => {
        let oldNumHead = !isNaN(speciesSubcategoryOldNoHead) ? Number(speciesSubcategoryOldNoHead) : 0;
        let youngNumHead = !isNaN(speciesSubcategoryYoungNoHead) ? Number(speciesSubcategoryYoungNoHead) : 0;
        setNoOfHead(oldNumHead + youngNumHead);
    }, [speciesSubcategoryOldNoHead, speciesSubcategoryYoungNoHead])

    useEffect(() => {
        if (triggerValidation > initValidationTriggerValue) {
            if ((Number(speciesSubcategoryOldNoHead) + Number(speciesSubcategoryYoungNoHead)) !== Number(noOfHead) || (Number(speciesSubcategoryOldNoHead) + Number(speciesSubcategoryYoungNoHead)) === 0) {
                setIsSubcategoryOldNoHeadValid(false);
                setIsSubcategoryYoungNoHeadValid(false);
            }

            if (Number(speciesSubcategoryOldNoHead) > 0 && (Number(speciesSubcategoryOldAvgWeight) <= 0 || isNaN(speciesSubcategoryOldAvgWeight))) {
                setIsSubcategoryOldAvgWeightValid(false);
            } else if (Number(speciesSubcategoryOldNoHead) > 0) {
                
                setIsSubcategoryOldAvgWeightValid(true);

                // Since only one of these categories must be filled out, set the other category to valid if it's number of head is 0
                // If it's number of head is not 0, its validity will be checked later
                if (Number(speciesSubcategoryYoungNoHead) === 0) 
                {
                    setIsSubcategoryYoungAvgWeightValid(true);
                }
                
            }

            if (Number(speciesSubcategoryYoungNoHead) > 0 && (Number(speciesSubcategoryYoungAvgWeight) <= 0 || isNaN(speciesSubcategoryYoungAvgWeight))) {
                setIsSubcategoryYoungAvgWeightValid(false);
            } else if (Number(speciesSubcategoryYoungNoHead) > 0) {
                setIsSubcategoryYoungAvgWeightValid(true);

                // Since only one of these categories must be filled out, set the other category to valid if it's number of head is 0
                // If it's number of head is not 0, its validity will be checked later
                if (Number(speciesSubcategoryYoungNoHead) === 0) 
                {
                    setIsSubcategoryOldAvgWeightValid(true);
                }
            }

            if (Number(selectedSpecies) === 0) {
                setIsSpeciesValid(false);
            }

            if (Number(selectedLivestockClass) === 0) {
                setIsSelectedLivestockClassValid(false);
            }
        }
    }, [triggerValidation])

    useEffect(() => {
        notifyUpdateData();
    }, [isNoOfHeadValid, isSpeciesValid, isSelectedLivestockClassValid, isSubcategoryOldNoHeadValid, isSubcategoryYoungNoHeadValid, isSubcategoryOldAvgWeightValid, isSubcategoryYoungAvgWeightValid])

    const notifyUpdateData = () => {
        let catOne = -1;
        let catTwo = -1;
        let aselCategories = livestockSubcategoriesOptions.filter(cat => cat.speciesId === Number(selectedSpecies));
        if(aselCategories && aselCategories.length > 1){
            catOne = aselCategories[0].id;
            catTwo = aselCategories[1].id;
        }

        let data = {
            rowId: rowId,
            numHead: noOfHead,
            speciesId: selectedSpecies,
            classId: selectedLivestockClass,
            aselCategories: [
                {category: catOne, dailyFeedReqPercentage: 0, numHeadForCategory: speciesSubcategoryYoungNoHead, avgWeight: speciesSubcategoryYoungAvgWeight},
                {category: catTwo, dailyFeedReqPercentage: 0, numHeadForCategory: speciesSubcategoryOldNoHead, avgWeight: speciesSubcategoryOldAvgWeight}
            ],
            isValid: isNoOfHeadValid && isSpeciesValid && isSelectedLivestockClassValid && isSubcategoryOldNoHeadValid && isSubcategoryYoungNoHeadValid && isSubcategoryOldAvgWeightValid && isSubcategoryYoungAvgWeightValid
        }
        onChange(data);
    }

    const updateValidateAll = () => {
        let isValid = true;

        if (Number(noOfHead) === 0) 
        {
            isValid = false;
        }
        
        if (Number(speciesSubcategoryOldNoHead) + Number(speciesSubcategoryYoungNoHead) !== Number(noOfHead)) 
        {
            isValid = false;
        }

        if (Number(selectedSpecies) === 0) 
        {
            isValid = false;
        }

        if (Number(selectedLivestockClass) === 0) 
        {
            isValid = false;
        }

        if (Number(speciesSubcategoryOldNoHead) > 0 && Number(speciesSubcategoryOldAvgWeight) === 0) 
        {
            isValid = false;
        }

        if (Number(speciesSubcategoryYoungNoHead) > 0 && Number(speciesSubcategoryYoungAvgWeight) === 0) 
        {
            isValid = false;
        }

        livestockDetail.isValid = isValid;
    }
    
    return (
        <Row className="mb-2 py-3" style={{backgroundColor: livestockDetail.bgColor}}>
            <Col xs={1}>
                <div style={{paddingLeft: '12px', fontSize: "13px", marginTop: "2px"}}>{noOfHead}</div>
            </Col>
            <Col xs={2}>
                <Form.Select
                    id="species-select"
                    onChange={(e) => {
                        if (!isSpeciesValid) {
                            setIsSpeciesValid(true);
                        }
                        setSelectedSpecies(e.target.value);
                    }}
                    value={selectedSpecies}
                    isInvalid={!isSpeciesValid}
                    style={{fontSize: "13px"}}
                >
                    {speciesOptions && speciesOptions.length > 0 && speciesOptions.map(sp => {
                            return (
                                <option key={`species_option_${sp.speciesName}`} value={sp.id}>{`${sp.speciesName}`}</option>
                            )
                        })
                    }
                </Form.Select>
                {!isSpeciesValid ?
                    (
                        <Tooltip
                            anchorSelect="#species-select"
                            variant="error"
                            content="You must select a species"    
                        />
                    ):null
                }
            </Col>
            <Col xs={2}>

                <Form.Select
                    id="class-select"
                    onChange={(e) => {
                        if (!isSelectedLivestockClassValid) {
                            setIsSelectedLivestockClassValid(true);
                        }
                        setSelectedLivestockClass(e.target.value);
                        //updateValidateAll();
                    }}
                    value={selectedLivestockClass}
                    isInvalid={!isSelectedLivestockClassValid}
                    style={{fontSize: "13px"}}
                >
                    {
                        livestockClassesOptions && livestockClassesOptions.length > 0 && livestockClassesOptions.map(lsClass => {
                            return (
                                <option key={`class_option_${lsClass.className}`} value={lsClass.id}>{`${lsClass.className}`}</option>
                            )
                        })
                    }
                </Form.Select>
                {!isSelectedLivestockClassValid ?
                    (
                        <div className="validation-error-tooltip">
                            <Tooltip
                                anchorSelect="#class-select"
                                content="You must select a class"    
                            />
                        </div>
                    ):null
                }
            </Col>
            <Col xs={6}>
                {livestockSubcategoriesOptions?.filter(lsc => lsc.speciesId === speciesOptions?.find(so => Number(so.id) === Number(selectedSpecies))?.id)?.map((sc, i) => {
                    

                    return (
                        <Row key={`asel_cat_${i}_species_${sc.speciesId}`} className={livestockSubcategoriesOptions?.filter(lsc => lsc.speciesId === speciesOptions?.find(so => Number(so.id) === Number(selectedSpecies))?.id).length > 1 ? "mb-2 pb-2" : "mb-2"} style={{borderBottom: livestockSubcategoriesOptions?.filter(lsc => lsc.speciesId === speciesOptions?.find(so => Number(so.id) === Number(selectedSpecies))?.id).length > 1 ? '1px dotted #44CB6F' : 'unset'}}>
                            <Col xs={4} >
                            {sc.AselCategoryDescription.map((d, i) => {
                                return <p key={i} className="mb-0" style={{fontSize: "13px"}}><FontAwesomeIcon icon={faCircleDot} style={{height: '10px', marginBottom: '1px'}}/> {d.description}</p>
                            })}
                            </Col>
                            <Col xs={4}>
                                {i === 0 ? 
                                    (
                                        <>
                                            <Form.Control
                                                id={`old-numhead-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setIsSubcategoryOldNoHeadValid(true);
                                                    setSpeciesSubcategoryOldNoHead(e.target.value);
                                                    //updateValidateAll();
                                                }}
                                                onFocus={() => {
                                                    if (speciesSubcategoryOldNoHead === 0) {
                                                        setSpeciesSubcategoryOldNoHead("");
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (speciesSubcategoryOldNoHead === "") {
                                                        setSpeciesSubcategoryOldNoHead(0);
                                                    }
                                                }}
                                                value={speciesSubcategoryOldNoHead}
                                                isInvalid={!isSubcategoryOldNoHeadValid}
                                                style={{fontSize: "13px"}}

                                            />
                                            { !isSubcategoryOldNoHeadValid ? 
                                                (
                                                    <div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#old-numhead-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                            content="Please enter a valid number of head."
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    ) :
                                    (
                                        <>
                                            <Form.Control
                                                id={`young-numhead-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setIsSubcategoryYoungNoHeadValid(true);
                                                    setSpeciesSubcategoryYoungNoHead(e.target.value);
                                                    //updateValidateAll();
                                                }}
                                                onFocus={() => {
                                                    if (speciesSubcategoryYoungNoHead === 0) {
                                                        setSpeciesSubcategoryYoungNoHead("");
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (speciesSubcategoryYoungNoHead === "") {
                                                        setSpeciesSubcategoryYoungNoHead(0);
                                                    }
                                                }}
                                                value={speciesSubcategoryYoungNoHead}
                                                isInvalid={!isSubcategoryYoungNoHeadValid}
                                                style={{fontSize: "13px"}}
                                            />
                                            { !isSubcategoryYoungNoHeadValid ? 
                                                (
                                                    <div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#young-numhead-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                            content="Please enter a valid number of head."
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    )
                                }
                            </Col>
                            <Col xs={4}>
                                {i === 0 ? 
                                    (
                                        <>
                                            <Form.Control
                                                id={`old-avgweight-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setIsSubcategoryOldAvgWeightValid(true);
                                                    setSpeciesSubcategoryOldAvgWeight(e.target.value);
                                                    //updateValidateAll();
                                                }}
                                                onFocus={() => {
                                                    if (speciesSubcategoryOldAvgWeight === 0) {
                                                        setSpeciesSubcategoryOldAvgWeight("");
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (speciesSubcategoryOldAvgWeight === "") {
                                                        setSpeciesSubcategoryOldAvgWeight(0);
                                                    }
                                                }}
                                                value={speciesSubcategoryOldAvgWeight}
                                                isInvalid={!isSubcategoryOldAvgWeightValid}
                                                style={{fontSize: "13px"}}
                                            />
                                            { !isSubcategoryOldAvgWeightValid ? 
                                                (
                                                    <div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#old-avgweight-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                            content="Please enter a valid average weight."
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Form.Control
                                                id={`young-avgweight-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setIsSubcategoryYoungAvgWeightValid(true);
                                                    setSpeciesSubcategoryYoungAvgWeight(e.target.value);
                                                    //updateValidateAll();
                                                }}
                                                onFocus={() => {
                                                        if (speciesSubcategoryYoungAvgWeight === 0) {
                                                            setSpeciesSubcategoryYoungAvgWeight("");
                                                        }
                                                }}
                                                onBlur={() => {
                                                    if (speciesSubcategoryYoungAvgWeight === "") {
                                                        setSpeciesSubcategoryYoungAvgWeight(0);
                                                    }
                                                }}
                                                value={speciesSubcategoryYoungAvgWeight}
                                                isInvalid={!isSubcategoryYoungAvgWeightValid}
                                                style={{fontSize: "13px"}}
                                            />
                                            { !isSubcategoryYoungAvgWeightValid ? 
                                                (
                                                    <div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#young-avgweight-input-asel_cat_${i}_species_${sc.speciesId}`}
                                                            content="Please enter a valid average weight."
                                                        />
                                                    </div>
                                                ) : null
                                            }
                                        </>
                                    )
                                }

                            </Col>
                        </Row>
                        );
                })

                }
            </Col>
            <Col xs={1}>
                <Button 
                    variant="primary"
                    onClick={() => {
                        onDelete(livestockDetail.data.rowId);
                    }}
                    style={{display: Number(livestockDetail.data.rowId) === 0 ? "none": "block"}}
                >
                    <FontAwesomeIcon icon={faCircleXmark} />
                </Button>
            </Col>
            
        </Row>
    )
}

export default LivestockDetail;