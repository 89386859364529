import React from "react";
import { Row, Col, Container, Button, Stack, Form, Modal, InputGroup, Alert } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../..";
import { Tooltip } from 'react-tooltip';
import { NumericValidationRules, parseNumericInput, isValidEmail, isValidMobile, isValidPassword } from "../../utils/InputValidator";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import { getUser, patchUserSettingsPage, setPasswordSettingsPage } from "../../api/apiAccess";
import { updateLoggedInUser } from "../../helpers/SessionHelper";

const Settings = () => {
    const userCtx = useContext(UserContext);
    const numericValidationRules = new NumericValidationRules({ allowLeadingZerosForWholeNumbers: true, allowNegative: false, allowDecimal: false });

    const [id, setID] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");

    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");

    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordValid, setCurrentPasswordValid] = useState(true);

    const [passwordValid, setPasswordValid] = useState(true);
    const [passwordError, setPasswordError] = useState("");
    const [showPasswordSuccess, setShowPasswordSuccess] = useState(false);
    const [showPasswordError, setShowPasswordError] = useState(false);

    const [showUserDetailsSuccessMessage, setShowUserDetailsSuccessMessage] = useState(false);
    const [showUserDetailsErrorMessage, setShowUserDetailsErrorMessage] = useState(false);
    const [userDetailsErrorMessage, setUserDetailsErrorMessage] = useState("");

    useEffect(() => {
        const populatePage = async () => {
            let lUserID = userCtx?.user?.id;
            setID(lUserID);

            let result = await getUser(lUserID);

            if (result.status === 200) {
                setFirstName(result.data.firstName);
                setLastName(result.data.lastName);
                setEmailAddress(result.data.emailAddress);
                setMobilePhone(result.data.phoneNumber);
            }
            else {
                setShowUserDetailsErrorMessage(true);
                setUserDetailsErrorMessage("Unable to load user details.");
            }
        }

        setShowUserDetailsErrorMessage(false);
        setShowPasswordSuccess(false);
        setShowPasswordError(false);
        setPasswordError("");

        if (userCtx?.user?.id) {
            populatePage();
        }

    }, [userCtx?.user]);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    const resetPasswordModal = () => {
        setCurrentPassword("");
        setCurrentPasswordValid(true);
        setNewPassword("");
        setNewPasswordConfirmation("");
        setPasswordValid(true);
        setShowPasswordSuccess(false);
        setShowPasswordError(false);
        setPasswordError("");
    }

    const saveUserDetails = () => {
        setShowUserDetailsSuccessMessage(false);
        setShowUserDetailsErrorMessage(false);
        setUserDetailsErrorMessage("");

        const saveAndValidate = async () => {
            // validate
            let lValidFirstName = !StringIsNullUndefinedOrEmpty(firstName);
            let lValidLastName = !StringIsNullUndefinedOrEmpty(lastName);
            let lValidEmail = isValidEmail(emailAddress);
            let lValidPhoneNumber = isValidMobile(mobilePhone);

            setValidFirstName(lValidFirstName);
            setValidLastName(lValidLastName);
            setValidEmail(lValidEmail);
            setValidPhone(lValidPhoneNumber);

            if (lValidFirstName && lValidLastName && lValidEmail && lValidPhoneNumber) {
                // save
                let result = await patchUserSettingsPage(id, firstName, lastName, emailAddress, mobilePhone);

                if (result.status === 200) {
                    setShowUserDetailsSuccessMessage(true);
                    // update user context
                    let lUser = JSON.parse(JSON.stringify(userCtx.user))
                    lUser.firstName = result.data.firstName;
                    lUser.lastName = result.data.lastName;
                    lUser.emailAddress = result.data.emailAddress;
                    lUser.phoneNumber = result.data.phoneNumber;

                    userCtx.setUser(lUser);
                    updateLoggedInUser(lUser);

                    setTimeout(() => {
                        setShowUserDetailsSuccessMessage(false);
                    }, 15000);
                }
                else if (result.status === 400) {
                    setShowUserDetailsErrorMessage(true);
                    if (result.data && typeof result.data === `string`) {
                        setUserDetailsErrorMessage(result.data);
                    }
                    else {
                        setUserDetailsErrorMessage(`Unable update details.`);
                    }
                }
                else {
                    setShowUserDetailsErrorMessage(true);
                    setUserDetailsErrorMessage(`Unable to update details.`);
                }
            }
        }
        saveAndValidate();
    }

    const savePassword = () => {
        const passwordsMatch = (password, passwordConfirmation) => {
            return password === passwordConfirmation;
        }

        const updatePassword = async () => {
            let result = await setPasswordSettingsPage(id, newPassword, currentPassword);

            if (result.status === 200) {
                setShowPasswordSuccess(true);
                setNewPassword("");
                setNewPasswordConfirmation("");
                setCurrentPassword("");

                setTimeout(() => {
                    setShowPasswordSuccess(false);
                }, 15000);
            }

            else {
                setShowPasswordError(true);
                if (result.data) {
                    setPasswordError(result.data);
                }
                else {
                    setPasswordError("Unable to set password.");
                }
            }
        }

        setShowPasswordError(false);
        setShowPasswordSuccess(false);

        let lIsNewPasswordValid = false;

        let lCurrentPasswordValid = !StringIsNullUndefinedOrEmpty(currentPassword);
        setCurrentPasswordValid(lCurrentPasswordValid);

        if (isValidPassword(newPassword) === true) {
            if (passwordsMatch(newPassword, newPasswordConfirmation)) {
                lIsNewPasswordValid = true;
                setPasswordError("");
            }
            else {
                setPasswordError("Passwords do not match.");
            }
        }
        else {
            setPasswordError("Password must be at least 8 characters, have both uppercase and lowercase letters, have at least 1 number and 1 special character (@$!%*#?&).");
        }

        setPasswordValid(lIsNewPasswordValid);

        if (lIsNewPasswordValid === true && lCurrentPasswordValid === true) {
            updatePassword();
        }
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Settings</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row>
                        <Col xs={12}>
                            <div className="green-underline-heading">Update Profile Information</div>
                            <p>You can login to this system in a few different ways.  You'll always need to enter your email address and password.  As a second form of authentication, you'll need a unique code which can be sent to either the email address or phone number entered below.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col xs={12} md={6}>
                            <Row className="mb-3">
                                <Col>
                                    <Stack>
                                        <div className="darkblue field-label">First Name</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtFirstName`}
                                                onChange={(e) => {
                                                    setFirstName(e.target.value);
                                                    setValidFirstName(true);
                                                }}
                                                value={firstName}
                                                isInvalid={validFirstName === false}
                                            />
                                            {
                                                validFirstName === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtFirstName`}
                                                            place="top"
                                                            variant="error"
                                                            content="A first name is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                                <Col>
                                    <Stack>
                                        <div className="darkblue field-label">Last Name</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtLastName`}
                                                onChange={(e) => {
                                                    setLastName(e.target.value);
                                                    setValidLastName(true);
                                                }}
                                                value={lastName}
                                                isInvalid={validLastName === false}
                                            />
                                            {
                                                validLastName === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtLastName`}
                                                            place="top"
                                                            variant="error"
                                                            content="A last name is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12}>
                                    <Stack>
                                        <div className="darkblue field-label">Email Address</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtEmail`}
                                                onChange={(e) => {
                                                    setEmailAddress(e.target.value);
                                                    setValidEmail(true);
                                                }}
                                                value={emailAddress}
                                                isInvalid={validEmail === false}
                                            />
                                            {
                                                validEmail === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtEmail`}
                                                            place="top"
                                                            variant="error"
                                                            content="A valid email address is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={6}>
                                    <Stack>
                                        <div className="darkblue field-label">Mobile Phone</div>
                                        <Form.Group>
                                            <Form.Control
                                                id={`txtPhone`}
                                                onChange={(e) => {
                                                    e.preventDefault();
                                                    parseNumericInput(e, numericValidationRules);
                                                    setMobilePhone(e.target.value);
                                                    setValidPhone(true);
                                                }}
                                                value={mobilePhone}
                                                isInvalid={validPhone === false}
                                            />
                                            {
                                                validPhone === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtPhone`}
                                                            place="top"
                                                            variant="error"
                                                            content="A valid mobile phone number is required."
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={{ span: 5, offset: 1 }} style={{ marginTop: "20px" }}>
                            <Button variant="secondary" onClick={() => setShowModal(true)}>Reset Password</Button>
                        </Col>
                    </Row>
                </Container>
                <Row className='mt-5 mx-0' style={{ position: "relative", top: "-300px" }}>
                    <Col>
                        <Container style={{ textAlign: "center" }}>
                            {
                                showUserDetailsErrorMessage ?
                                    <Row>
                                        <Col>
                                            <Alert variant='danger'>{userDetailsErrorMessage}</Alert>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            {
                                showUserDetailsSuccessMessage ?
                                    <Row>
                                        <Col>
                                            <Alert variant='success'>Details updated successfully.</Alert>
                                        </Col>
                                    </Row>
                                    : null
                            }
                            <Row>
                                <Col>
                                    <Button className="me-2 me-auto" variant="primary" onClick={() => saveUserDetails()} size="lg" style={{ minWidth: "150px" }}>Ok</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal show={showModal} onHide={() => { setShowModal(false); resetPasswordModal(); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Reset Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Stack>
                                    <div className="darkblue field-label">Current Password</div>
                                    <Form.Group>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                id={`txtCurrentPassword`}
                                                onChange={(e) => {
                                                    setCurrentPasswordValid(true);
                                                    setCurrentPassword(e.target.value);
                                                }}
                                                className="inputGroupWithAppendLeft"
                                                value={currentPassword}
                                                isInvalid={currentPasswordValid === false}
                                            />
                                            <InputGroup.Text className={`inputGroupAppend ${currentPasswordValid === false ? "invalidAppend" : ""}`} onClick={() => setShowPassword(!showPassword)}>
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                            </InputGroup.Text>
                                            {
                                                currentPasswordValid === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtCurrentPassword`}
                                                            place="top"
                                                            variant="error"
                                                            content={`Your current password is required.`}
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </InputGroup>
                                    </Form.Group>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12}>
                                <Stack>
                                    <div className="darkblue field-label">Enter new password</div>
                                    <Form.Group>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                id={`txtPassword`}
                                                onChange={(e) => {
                                                    setPasswordValid(true);
                                                    setNewPassword(e.target.value);
                                                }}
                                                className="inputGroupWithAppendLeft"
                                                value={newPassword}
                                                isInvalid={passwordValid === false}
                                            />
                                            <InputGroup.Text className={`inputGroupAppend ${passwordValid === false ? "invalidAppend" : ""}`} onClick={() => setShowPassword(!showPassword)}>
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                            </InputGroup.Text>
                                            {
                                                passwordValid === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtPassword`}
                                                            place="top"
                                                            variant="error"
                                                            content={passwordError}
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </InputGroup>
                                    </Form.Group>
                                </Stack>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12}>
                                <Stack>
                                    <div className="darkblue field-label">Re-enter new password</div>
                                    <Form.Group>
                                        <InputGroup>
                                            <Form.Control
                                                type={showPassword ? "text" : "password"}
                                                id="txtPasswordConfirmation"
                                                onChange={(e) => {
                                                    setPasswordValid(true);
                                                    setNewPasswordConfirmation(e.target.value);
                                                }}
                                                value={newPasswordConfirmation}
                                                isInvalid={passwordValid === false}
                                                className="inputGroupWithAppendLeft"
                                            />
                                            <InputGroup.Text className={`inputGroupAppend ${passwordValid === false ? "invalidAppend" : ""}`} onClick={() => setShowPassword(!showPassword)}>
                                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                            </InputGroup.Text>
                                            {
                                                passwordValid === false ?
                                                    (<div className="validation-error-tooltip">
                                                        <Tooltip
                                                            anchorSelect={`#txtPasswordConfirmation`}
                                                            place="top"
                                                            variant="error"
                                                            content={passwordError}
                                                        />
                                                    </div>
                                                    ) : null
                                            }
                                        </InputGroup>
                                    </Form.Group>
                                </Stack>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        {
                            showPasswordError ?
                                <Row>
                                    <Col>
                                        <Alert variant='danger'>{passwordError}</Alert>
                                    </Col>
                                </Row>
                                : null
                        }
                        {
                            showPasswordSuccess ?
                                <Row>
                                    <Col>
                                        <Alert variant='success'>Password updated successfully.</Alert>
                                    </Col>
                                </Row>
                                : null
                        }
                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => savePassword()}>Save</Button>
                            </Col>
                        </Row>
                    </Container>


                </Modal.Footer>
            </Modal>
        </LoggedInContainer >
    )
};

export default Settings;