import React from "react";
import { Row, Col, Container, Button, Form, Alert } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useContext, useEffect } from "react";
import { UserContext } from "../..";
import { setExportersRequireReviewStatus } from "../../api/apiAccess";
import { updateLoggedInUser } from "../../helpers/SessionHelper";

const Exporter_Organisation_Settings = () => {
    const userCtx = useContext(UserContext);
    const [requireReview, setRequireReview] = useState(true);
    const [exporterID, setExporterID] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1)
    }

    useEffect(() => {
        if (userCtx.associatedExporter?.exporter) {
            setRequireReview(userCtx.associatedExporter.exporter.reviewRequiredPriorToDeptSubmission);
            setExporterID(userCtx.associatedExporter.exporter.id);
        }
    }, [userCtx.associatedExporter]);

    const save = () => {

        const update = async () => {
            // save
            let result = await setExportersRequireReviewStatus(exporterID, requireReview);

            if (result?.status === 200) {
                setShowSuccess(true);
                setTimeout(() => {
                    setShowSuccess(false);
                }, 15000);

                // Update user context
                let updatedExporter = JSON.parse(JSON.stringify(result.data)); // need json parse and stringify otheriwse use effects that are triggered by this may not fire
                userCtx.setAssociatedExporter(updatedExporter);

                let lUser = userCtx.user;
                lUser.associatedExporter = updatedExporter;
                updateLoggedInUser(lUser);
            }
            else {
                setShowError(true);
            }
        }

        setShowError(false);
        setShowSuccess(false);
        update();
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Organisation Settings</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row className="mb-3">
                        <Col>
                            <div>Any changes you make on this screen will apply to your entire organisation.</div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Form>
                                <Form.Check
                                    checked={requireReview}
                                    onChange={(e) => setRequireReview(e.target.checked)}
                                    type={`checkbox`}
                                    label={`Require review prior to submission to Department`}
                                    className="field-label secondary-grey"
                                />
                            </Form>
                        </Col>
                    </Row>
                    {
                        showError === true ?
                            <Row>
                                <Col>
                                    <Alert variant="danger">Unable to save.</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        showSuccess === true ?
                            <Row>
                                <Col>
                                    <Alert variant="success">Review status updated successfully.</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => save()}>Ok</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
};

export default Exporter_Organisation_Settings;