import '../css/historicalconsignments.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Stack, FormControl, FormSelect, Button, Spinner } from "react-bootstrap";
import { format } from 'date-fns';
import LoggedInContainer from "../layout/LoggedInContainer";
import { getAllCompletedConsignmentSummaries } from '../api/apiAccess';
import { faUsers, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { textIncludesSearchTerm } from '../utils/SearchUtils';
import DateTimePicker from '../components/DateTimePicker';

const LiveCorpAndRegulatoryCompletedConsignments = () => {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [consignments, setConsignments] = useState([]);
    const [filteredConsignments, setFilteredConsignments] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedVesselFilterOption, setSelectedVesselFilterOption] = useState("all");
    const [selectedExporterFilterOption, setSelectedExporterFilterOption] = useState("all");
    const [selectedDeparturePortFilterOption, setSelectedDeparturePortFilterOption] = useState("all");
    const [selectedDestinationPortFilterOption, setSelectedDestinationPortFilterOption] = useState("all");
    const [selectedDateFilterOption, setSelectedDateFilterOption] = useState("thisyear");

    const [customStartDate, setCustomStartDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [customEndDate, setCustomEndDate] = useState(new Date(new Date(new Date().getFullYear(), 11, 31)));

    const [vesselOptions, setVesselOptions] = useState([]);
    const [exporterOptions, setExporterOptions] = useState([]);
    const [departurePortOptions, setDeparturePortOptions] = useState([]);
    const [destinationPortOptions, setDestinationPortOptions] = useState([]);

    useEffect(() => {
        const PopulatePage = async () => {
            setIsLoading(true);
            let results = await getAllCompletedConsignmentSummaries();

            if (results?.status === 200) {
                let lCompletedConsignments = results.data;

                let lVesselOptions = [];
                let lExporterOptions = [];
                let lDeparturePortOptions = [];
                let lDestinationPortOptions = [];

                lCompletedConsignments.forEach(con => {
                    if (!lVesselOptions.find(vo => vo.ID === con.vesselID)) {
                        lVesselOptions.push({ ID: con.vesselID, Name: con.vesselName });
                    }

                    if (!lExporterOptions.find(eo => eo.ID === con.exporterID)) {
                        lExporterOptions.push({ ID: con.exporterID, Name: con.exporterName });
                    }

                    if (!lDeparturePortOptions.find(po => po.ID === con.portID)) {
                        lDeparturePortOptions.push({ ID: con.departurePortID, Name: con.departurePortName });
                    }

                    if (!lDestinationPortOptions.find(po => po.ID === con.portID)) {
                        lDestinationPortOptions.push({ ID: con.finalDestinationPortID, Name: con.finalDestinationPortName });
                    }
                });

                setConsignments(lCompletedConsignments);
                setVesselOptions(lVesselOptions);
                setExporterOptions(lExporterOptions);
                setDeparturePortOptions(lDeparturePortOptions);
                setDestinationPortOptions(lDestinationPortOptions);

                setLoadingError(false);
            }
            else {
                setLoadingError(true);
            }

            setIsLoading(false);
        }

        PopulatePage();
    }, [])

    // Filter consignments
    useEffect(() => {

        let lStartDate = null;
        let lEndDate = null;

        switch (selectedDateFilterOption) {
            case "thisyear":
                lStartDate = new Date(new Date().getFullYear(), 0, 1);
                lEndDate = new Date(new Date().getFullYear(), 11, 31);
                break;
            case "lastyear":
                lStartDate = new Date(new Date().getFullYear() - 1, 0, 1);
                lEndDate = new Date(new Date().getFullYear() - 1, 11, 31);
                break;
            case "customdates":
                lStartDate = customStartDate;
                lEndDate = customEndDate;
                break;
        }

        let lFilteredConsignments;

        if (selectedDateFilterOption === "customdates" && (!customStartDate || !customEndDate)) {
            lFilteredConsignments = [];
        }
        else {
            lFilteredConsignments = [...consignments].filter(con =>
                (
                    selectedVesselFilterOption === "all"
                    || con.vesselID === Number(selectedVesselFilterOption)
                )
                && (
                    selectedExporterFilterOption === "all"
                    || con.exporterID === Number(selectedExporterFilterOption)
                )
                && (
                    selectedDeparturePortFilterOption === "all"
                    || con.departurePortID === Number(selectedDeparturePortFilterOption)
                )
                && (
                    selectedDestinationPortFilterOption === "all"
                    || con.finalDestinationPortID === Number(selectedDestinationPortFilterOption)
                )
                && (
                    selectedDateFilterOption === "all"
                    || new Date(con.vesselDepartedDate) >= lStartDate && new Date(con.vesselDepartedDate) <= lEndDate
                )
                && (
                    searchTerm === ""
                    || textIncludesSearchTerm(con.vesselName, searchTerm)
                    || textIncludesSearchTerm(con.exporterName, searchTerm)
                    || textIncludesSearchTerm(con.lncNumber, searchTerm)
                    || textIncludesSearchTerm(con.departurePortName, searchTerm)
                    || textIncludesSearchTerm(con.finalDestinationPortName, searchTerm)
                )
            );
        }

        setFilteredConsignments(lFilteredConsignments);
    }, [
        consignments, searchTerm, selectedVesselFilterOption, selectedExporterFilterOption,
        selectedDeparturePortFilterOption, selectedDestinationPortFilterOption, selectedDateFilterOption, customStartDate, customEndDate
    ])

    const goToConsignmentDashboard = (consignmentID) => {
        navigate(`/consignmentdashboard/${consignmentID}`);
    }

    const goBack = () => {
        navigate(-1)
    }

    const intlCollator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'accent'});

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0">
                    <Col>
                        <Container>
                            <Row style={{ marginTop: "65px" }}>
                                <Col xs={2} className='my-auto'>
                                    <Button variant="back" onClick={() => goBack()}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                </Col>
                                <Col>
                                    <div className="text-center heading-text" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>View Past Consignments</div>
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Container className="form-container-mid py-4 px-5">
                        {
                            isLoading === true ?
                                <Row>
                                    <Col>
                                        <div className="text-center pt-5">
                                            <Spinner className="mx-auto" />
                                            <div className="mx-auto">Loading data...</div>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                loadingError === true ?
                                    <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>Unable to load completed consignments.</div>
                                    :
                                    consignments && consignments.length > 0 ?
                                        <>
                                            <Row className='bg-grey p-3 mb-4 darkblue field-label'>
                                                <Col xs={5}>
                                                    <Stack direction="vertical" gap={1}>
                                                    <span className='field-label'>Search</span>
                                                        <FormControl
                                                            placeholder="Type a word to search, ie vessel, LNC..."
                                                            onChange={(e) => {
                                                                let text = e.target.value;
                                                                setSearchTerm(text);
                                                            }}
                                                        />
                                                    </Stack>
                                                </Col>
                                                <Col xs={7}>
                                                    <Stack direction="vertical" gap={1}>
                                                    <span className='field-label'>Filter consignments by</span>
                                                        <Stack direction="horizontal" gap={2}>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedDateFilterOption(e.target.value);
                                                                }}
                                                                value={selectedDateFilterOption}
                                                            >
                                                                <option value="all">All Time Periods</option>
                                                                <option value="thisyear">This Year</option>
                                                                <option value="lastyear">Last Year</option>
                                                                <option value="customdates">Custom Dates</option>

                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedVesselFilterOption(e.target.value);
                                                                }}
                                                                value={selectedVesselFilterOption}
                                                            >
                                                                <option value="all">All Vessels</option>
                                                                {
                                                                    vesselOptions && vesselOptions.length > 0 ?
                                                                        vesselOptions.map(vo => {
                                                                            return (<option key={`vesselOption_${vo.ID}`} value={vo.ID}>{vo.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedExporterFilterOption(e.target.value);
                                                                }}
                                                                value={selectedExporterFilterOption}
                                                            >
                                                                <option value="all">All Exporters</option>
                                                                {
                                                                    exporterOptions && exporterOptions.length > 0 ?
                                                                        exporterOptions.map(eo => {
                                                                            return (<option key={`exporterOption_${eo.ID}`} value={eo.ID}>{eo.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedDeparturePortFilterOption(e.target.value);
                                                                }}
                                                                value={selectedDeparturePortFilterOption}
                                                            >
                                                                <option value="all">All Departure Ports</option>
                                                                {
                                                                    departurePortOptions && departurePortOptions.length > 0 ?
                                                                        departurePortOptions
                                                                            .filter((val, index, array) => array.findIndex(v => v.Name === val.Name) === index)
                                                                            .sort((a,b) => intlCollator.compare(a.Name, b.Name))
                                                                            .map((dp, i) => {
                                                                                return (<option key={`departurePortOption_${i}_${dp.ID}`} value={dp.ID}>{dp.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedDestinationPortFilterOption(e.target.value);
                                                                }}
                                                                value={selectedDestinationPortFilterOption}
                                                            >
                                                                <option value="all">All Final Ports</option>
                                                                {
                                                                    destinationPortOptions && destinationPortOptions.length > 0 ?
                                                                        destinationPortOptions
                                                                            .filter((val, index, array) => array.findIndex(v => v.Name === val.Name) === index)
                                                                            .sort((a,b) => intlCollator.compare(a.Name, b.Name))
                                                                            .map((dp, index) => {
                                                                                return (<option key={`destinationPortOption_${dp.ID}_${index}`} value={dp.ID}>{dp.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                        </Stack>
                                                        {
                                                            selectedDateFilterOption === "customdates" ?
                                                                <div className='darkblue field-label whiteInputGroupContainer' style={{ marginTop: '10px', display: `flex`, alignSelf: 'flex-start' }}>
                                                                    <Stack>
                                                                        <div>Start Date</div>
                                                                        <DateTimePicker
                                                                            datetime={customStartDate}
                                                                            canwrite={true}
                                                                            showTime={false}
                                                                            showUTCTime={false}
                                                                            onChange={(date) => {
                                                                                setCustomStartDate(date);
                                                                            }}
                                                                            showHeadLabel={false}
                                                                        />
                                                                    </Stack>

                                                                    <Stack>
                                                                        <div>End Date</div>
                                                                        <DateTimePicker
                                                                            datetime={customEndDate}
                                                                            canwrite={true}
                                                                            showTime={false}
                                                                            showUTCTime={false}
                                                                            onChange={(date) => {
                                                                                setCustomEndDate(date);
                                                                            }}
                                                                            showHeadLabel={false}
                                                                        />
                                                                    </Stack>
                                                                </div>
                                                                : null
                                                        }

                                                    </Stack>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <table className='consignmentsTable'>
                                                        <thead>
                                                            <tr>
                                                                <th><div>Departure</div></th>
                                                                <th><div>Vessel</div></th>
                                                                <th><div>Exporter</div></th>
                                                                <th><div>LNC</div></th>
                                                                <th><div>Departure Port</div></th>
                                                                <th><div>Final Port</div></th>
                                                                <th><div>Discharge Date of Final Port</div></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filteredConsignments && filteredConsignments.length > 0 ?
                                                                    filteredConsignments.map((con, i) => {
                                                                        return (
                                                                            <tr key={`filtered_consignment_${i}`}>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.vesselDepartedDate ? format(new Date(con.vesselDepartedDate), "dd/MM/yyyy HH:mm") : "-"}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.vesselName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.exporterName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Stack direction='horizontal' gap={2}>
                                                                                            <div>{`LNC-${con.lncNumber}`}</div>
                                                                                            {
                                                                                                con.isMultiConsignment === true ? (
                                                                                                    <span className="me-2">
                                                                                                        <div className="dashboard-tooltip">
                                                                                                            <FontAwesomeIcon icon={faUsers} />
                                                                                                            <div className="dashboard-tooltiptext p-2">This vessel is carrying multiple consignments.</div>
                                                                                                        </div>
                                                                                                    </span>
                                                                                                )
                                                                                                    : null
                                                                                            }
                                                                                        </Stack>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.departurePortName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.finalDestinationPortName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.finalDestinationPortDischargeCompletedDate ? format(new Date(con.finalDestinationPortDischargeCompletedDate), "dd/MM/yyyy HH:mm") : "-"}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                goToConsignmentDashboard(con.consignmentID);
                                                                                            }}
                                                                                        >
                                                                                            View
                                                                                        </Button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr style={{ backgroundColor: `#FFFFFF` }}>
                                                                        <td colSpan={8}>
                                                                            <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>No completed consignments match the specified filters.</div>
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>There are currently no completed consignments.</div>
                        }
                    </Container>
                </Row>
            </Container>
        </LoggedInContainer>
    )
}

export default LiveCorpAndRegulatoryCompletedConsignments;