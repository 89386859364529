import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Alert, Spinner } from "react-bootstrap";
import { getDeckRecordsForConsignment } from "../../api/apiAccess";
import { DetailedDeckRecord } from "../../classes/DetailedDeckRecord";
import { StringIsNullUndefinedOrEmpty, IsNotNullAndGreaterThanZero } from "../../utils/StringUtils";
import JsonToHtmlTable from "../../components/JsonToHtmlTable";
import { sortByProperty } from "../../utils/ListUtils";
import { EditViewString } from "../../components/ReadWriteElements/EditViewString";
import { UserContext } from "../..";

// See comment in DeckRecord.js in the classes folder in regards to empty decks
const View_Deck_Record_Other = ({ consignmentID, hasSheep, hasCattle, allowEditing, onSelectedColumnIndexChanged }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [jsonDataDisplayed, setJsonDataDisplayed] = useState({ "Head": [], "Body": [] });
    const [hasData, setHasData] = useState(true);
    const [errorGettingData, setErrorGettingData] = useState(false);
    const userCtx = useContext(UserContext);

    // get data
    useEffect(() => {
        const fetchDeckRecords = async () => {
            let lResponse = await getDeckRecordsForConsignment(consignmentID);

            if (lResponse?.status === 200) {
                if (lResponse.data.length > 0) {
                    let lDetailedDeckRecords = [];
                    lResponse.data.forEach(d => {
                        lDetailedDeckRecords.push(new DetailedDeckRecord(d));
                    })

                    let lDateDayCombinations = getUniqueVoyageDaysThatHaveADeckRecord(lDetailedDeckRecords);
                    let lData = getFormattedData(lDateDayCombinations, lDetailedDeckRecords);

                    setJsonDataDisplayed(lData);
                    setHasData(true);
                }
                else {
                    setHasData(true);
                }
            }
            else {
                setErrorGettingData(true);
            }
            setIsLoading(false);
        }

        const getUniqueVoyageDaysThatHaveADeckRecord = (detailedDeckRecords) => {
            let lDays = [];

            detailedDeckRecords.forEach((record) => {
                let lExisitngDay = lDays.find(item => item.id === record.ConsignmentDay.id);
                if (!lExisitngDay) {
                    lDays.push(record.ConsignmentDay);
                }
            })
            lDays = sortByProperty(lDays, "dayNumber");
            return lDays;
        }

        const getFormattedData = (dateDayCombinations, detailedDeckRecords) => {
            if (dateDayCombinations && dateDayCombinations.length > 0 && detailedDeckRecords && detailedDeckRecords.length > 0) {
                let lHead = [
                    [[""]],
                    [[null]]
                ];

                dateDayCombinations.forEach((aConsignmentDay) => {
                    let lDateHeader = [
                        ["Day " + aConsignmentDay.dayNumber],
                        createLink(aConsignmentDay, "/editdeckrecordother"),
                    ];
                    lHead.push(lDateHeader);
                })

                let lSheepHotSpots = null;
                let lSheepPanting = null;
                let lSheepScabbyMouth = null;
                let lCattlePanting = null;

                if (hasSheep) {
                    lSheepHotSpots = { groupRowHeading: "Sheep hot spots or pens?", groupRowsData: [] };
                    lSheepHotSpots.groupRowsData.push({ "rowHeading": null, data: [] });

                    lSheepPanting = { groupRowHeading: "Sheep panting at or above 3?", groupRowsData: [] };
                    lSheepPanting.groupRowsData.push({ "rowHeading": null, data: [] });

                    lSheepScabbyMouth = { groupRowHeading: "Sheep displaying scabby mouth", groupRowsData: [] };
                    lSheepScabbyMouth.groupRowsData.push({ "rowHeading": null, data: [] });
                }

                if (hasCattle) {
                    lCattlePanting = { groupRowHeading: "Cattle panting at or above 2?", groupRowsData: [] };
                    lCattlePanting.groupRowsData.push({ "rowHeading": null, data: [] });
                }


                dateDayCombinations.forEach((consignmentDay) => {
                    // get all deck records for x day
                    let lRecords = [...detailedDeckRecords].filter(dr => dr.ConsignmentDay.id === consignmentDay.id);

                    // Check if any cattle and sheep decks are not empty for the specified consignment day
                    // If all consignments species combination on the deck was marked as empty on the ship display a hyphen
                    let lIsSomeNonEmptySheepDeck = [...lRecords].some((rec) => rec.DeckRecord.speciesID === 1 && rec.DeckRecord.isEmpty === false);
                    let lIsSomeNonEmptyCattleDeck = [...lRecords].some((rec) => rec.DeckRecord.speciesID === 2 && rec.DeckRecord.isEmpty === false);

                    if (hasSheep) {
                        if (lIsSomeNonEmptySheepDeck === true) {
                            let lHasSheepHotSpotsForDate = lRecords.some(rec => rec.Species.id === 1 && StringIsNullUndefinedOrEmpty(rec.DeckRecord.hotSpotDescription) === false);
                            let lHasSheepPantingForDate = lRecords.some(rec => rec.Species.id === 1 && (IsNotNullAndGreaterThanZero(rec.DeckRecord.pantingScoreThreePercent) || IsNotNullAndGreaterThanZero(rec.DeckRecord.pantingScoreFourPercent)));

                            let lHasSheepScabbyMouthForDate = lRecords.some(rec => rec.Species.id === 1 && IsNotNullAndGreaterThanZero(rec.DeckRecord.scabbyMouthPercent) === true);


                            lSheepHotSpots.groupRowsData[0].data.push(lHasSheepHotSpotsForDate ? ["Yes"] : ["No"]);
                            lSheepPanting.groupRowsData[0].data.push(lHasSheepPantingForDate ? ["Yes"] : ["No"]);
                            lSheepScabbyMouth.groupRowsData[0].data.push(lHasSheepScabbyMouthForDate ? ["Yes"] : ["No"]);
                        }
                        else {
                            lSheepHotSpots.groupRowsData[0].data.push(["-"]);
                            lSheepPanting.groupRowsData[0].data.push(["-"]);
                            lSheepScabbyMouth.groupRowsData[0].data.push(["-"]);
                        }
                    }

                    if (hasCattle) {
                        if (lIsSomeNonEmptyCattleDeck === true) {
                            let lHasCattlePantingForDate = lRecords.some(rec => rec.Species.id === 2 && (IsNotNullAndGreaterThanZero(rec.DeckRecord.pantingScoreTwoPercent) || IsNotNullAndGreaterThanZero(rec.DeckRecord.pantingScoreThreePercent) || IsNotNullAndGreaterThanZero(rec.DeckRecord.pantingScoreFourPercent)));
                            lCattlePanting.groupRowsData[0].data.push(lHasCattlePantingForDate ? ["Yes"] : ["No"]);
                        }
                        else {
                            lCattlePanting.groupRowsData[0].data.push(["-"]);
                        }
                    }
                })

                let lBody = [];
                if (hasSheep) {
                    lBody.push(lSheepHotSpots);
                    lBody.push(lSheepPanting);
                    lBody.push(lSheepScabbyMouth);
                }
                if (hasCattle) {
                    lBody.push(lCattlePanting);
                }

                // set second combine to true if we only want to show the deck name once
                let lData = { "Formatting": [{ combine: true }, { combine: false }, { combine: false }], "Head": lHead, "Body": lBody };

                return lData;
            }
            else {
                return { "Head": [], "Body": [] };
            }
        }

        fetchDeckRecords();
    }, [consignmentID, hasSheep, hasCattle]);

    const createLink = (aConsignmentDay, aPath) => {
        // If a user clicks edit we should take them to the same tab that they are viewing the previous deck record for
        let lText = EditViewString(userCtx.user.writeAccess);
        return [{ link: { href: aPath, text: lText, consignmentDay: aConsignmentDay } }];
    }

    return (
        <>
            {
                isLoading ? (
                    <div className="text-center pt-5">
                        <Spinner className="mx-auto" />
                        <div className="mx-auto">Loading data...</div>
                    </div>
                ) : null
            }
            {
                isLoading === false && hasData === true ?
                    <div>
                        <Row>
                            <Col>
                                <JsonToHtmlTable jsonData={jsonDataDisplayed} filter={null} duplicateHeaders={false} showEdit={allowEditing} onSelectedColumnChanged={onSelectedColumnIndexChanged} />
                            </Col>
                        </Row>
                    </div> : null
            }
            {
                isLoading === false && hasData === false && errorGettingData === false ?
                    <Alert variant="info" className="field-label">There is currently no data for the selected consignment.</Alert>
                    : null
            }
            {
                isLoading === false && errorGettingData === true ?
                    <Alert variant="info" className="field-label">Something went wrong while fetching previous deck records.</Alert>
                    : null
            }
        </>
    )
};

export default View_Deck_Record_Other;