import { StringIsNullUndefinedOrEmpty } from "../utils/StringUtils";

export class EndOfVoyageReportSave {
    ID;
    ConsignmentID;
    FeedROBID;
    FeedAvailabilityComment;
    IsAnimalRestSpaceAdequate;
    AnimalRestSpaceComment;
    BeddingLoading;
    BeddingROB;
    IsBeddingIssue;
    BeddingIssueComment;
    IsBeddingUseLoadingSlipperyAreas;
    IsBeddingUseManagementPlans;
    IsBeddingUseMitigationHealthConcerns;
    IsBeddingUseAfterWashDown;
    IsBeddingUseHospitalPens;
    IsBeddingUseNotUsed;
    IsUnexpectedHealthIssues;
    UnexpectedHealthIssuesComment;
    IsOtherHealthIssues;
    OtherHealthIssuesComment;
    IsAggressiveAnimalIssues;
    AggressiveAnimalIssuesComment;
    IsDischargeConcerns;
    DischargeConcernsComment;
    IsAgreeWithDeclaration;
    AgreeWithDeclarationComment;
    IsEOVReviewed;
    PelletsROB;
    GrainROB;
    RoughageROB;

    constructor(aID, aConsignmentID, aFeedROBID, aFeedAvailabilityComment, aIsAnimalRestSpaceAdequate, aAnimalRestSpaceComment, aBeddingLoading, aBeddingROB,
        aIsBeddingIssue, aBeddingIssueComment, aIsBeddingUseLoadingSlipperyAreas, aIsBeddingUseManagementPlans, aIsBeddingUseMitigationHealthConcerns,
        aIsBeddingUseAfterWashDown, aIsBeddingUseHospitalPens, aIsBeddingUseNotUsed, aIsUnexpectedHealthIssues, aUnexpectedHealthIssuesComment,
        aIsOtherHealthIssues, aOtherHealthIssuesComment, aIsAggressiveAnimalIssues, aAggressiveAnimalIssuesComment, aIsDischargeConcerns,
        aDischargeConcernsComment, aIsAgreeWithDeclaration, aAgreeWithDeclarationComment, aPelletsROB, aGrainROB, aRoughageROB) {
        this.ID = Number(aID);
        this.ConsignmentID = Number(aConsignmentID);
        this.FeedROBID = Number(aFeedROBID);
        this.FeedAvailabilityComment = EmptyStringIfNull(aFeedAvailabilityComment);
        this.IsAnimalRestSpaceAdequate = aIsAnimalRestSpaceAdequate;
        this.AnimalRestSpaceComment = EmptyStringIfNull(aAnimalRestSpaceComment);
        this.BeddingLoading = Number(aBeddingLoading);
        this.BeddingROB = Number(aBeddingROB);
        this.IsBeddingIssue = FalseIfNull(aIsBeddingIssue);
        this.BeddingIssueComment = EmptyStringIfNull(aBeddingIssueComment);
        this.IsBeddingUseLoadingSlipperyAreas = FalseIfNull(aIsBeddingUseLoadingSlipperyAreas);
        this.IsBeddingUseManagementPlans = FalseIfNull(aIsBeddingUseManagementPlans);
        this.IsBeddingUseMitigationHealthConcerns = FalseIfNull(aIsBeddingUseMitigationHealthConcerns);
        this.IsBeddingUseAfterWashDown = FalseIfNull(aIsBeddingUseAfterWashDown);
        this.IsBeddingUseHospitalPens = FalseIfNull(aIsBeddingUseHospitalPens);
        this.IsBeddingUseNotUsed = FalseIfNull(aIsBeddingUseNotUsed);
        this.IsUnexpectedHealthIssues = FalseIfNull(aIsUnexpectedHealthIssues);
        this.UnexpectedHealthIssuesComment = EmptyStringIfNull(aUnexpectedHealthIssuesComment);
        this.IsOtherHealthIssues = FalseIfNull(aIsOtherHealthIssues);
        this.OtherHealthIssuesComment = EmptyStringIfNull(aOtherHealthIssuesComment);
        this.IsAggressiveAnimalIssues = FalseIfNull(aIsAggressiveAnimalIssues);
        this.AggressiveAnimalIssuesComment = EmptyStringIfNull(aAggressiveAnimalIssuesComment);
        this.IsDischargeConcerns = FalseIfNull(aIsDischargeConcerns);
        this.DischargeConcernsComment = EmptyStringIfNull(aDischargeConcernsComment);
        this.IsAgreeWithDeclaration = FalseIfNull(aIsAgreeWithDeclaration);
        this.AgreeWithDeclarationComment = EmptyStringIfNull(aAgreeWithDeclarationComment);
        this.IsEOVReviewed = true;
        this.PelletsROB = Number(aPelletsROB);
        this.GrainROB = Number(aGrainROB);
        this.RoughageROB = Number(aRoughageROB);
    }
}

const EmptyStringIfNull = (value) => {
    if (StringIsNullUndefinedOrEmpty(value)) {
        return "";
    }
    else {
        return value;
    }
}

const FalseIfNull = (value) => {
    if (StringIsNullUndefinedOrEmpty(value)) {
        return false;
    }
    else {
        return value;
    }
}