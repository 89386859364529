import React from "react";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck, faHourglass1, faCheck } from "@fortawesome/free-solid-svg-icons";

const ReviewStatusMobile = ({ status }) => {
    // if status recevied
        // if reviewed
            // RECEVIED / VIEWED
        // else (not reviewed)
            // AWAITING REVIEW
    // else 
        // NOT RECIEVED
    return (
        <>
            {
                status ?
                    status.received === true ?
                        status.reviewed === true ?
                            <Stack direction="horizontal" gap={1} className='mb-2' >
                                <button style={{ width:'100%', color:'var(--primary-colour)', padding:'10px', backgroundColor: '#44CB6F26', border: '0px', borderRadius: '5px'  }}>
                                    <FontAwesomeIcon icon={faCheck} style={{fontSize: "16px", marginRight: '10px'}} />  
                                   {!status.isReviewRequiredPriorToDeptSubmission ? "Received" : "Viewed"}
                                </button>
                            </Stack>
                            :
                            <Stack direction="horizontal" gap={1} className='mb-2'>
                                <button style={{ width:'100%', color:'#f79f1b', padding:'10px', backgroundColor: '#fcf3e6', border: '0px', borderRadius: '5px'  }}>
                                    <FontAwesomeIcon icon={faHourglass1} color="#f79f1b" style={{ fontSize: "13px", marginRight: '10px' }} />
                                    Awaiting review
                                </button>
                            </Stack>
                        :
                        <Stack direction="horizontal" gap={1} className='mb-2'>
                            <button style={{ width:'100%', color:'#de5656', padding:'10px', backgroundColor: '#f7e4e4', border: '0px', borderRadius: '5px'  }}>
                                <FontAwesomeIcon icon={faCircleXmark} style={{fontSize: "16px", marginRight: '10px'}} />  
                                Not received
                            </button>
                        </Stack>
                    : null
            }
        </>
    )
};

export default ReviewStatusMobile;