export class Exporter {
    ID;
    ExporterName;
    MailingAddress;
    PhoneNumber;
    LicenceNumber;
    LicenceExpiryDateUTC;
    IsActive;
    OrganisationTypeID;
    ReviewRequiredPriorToDeptSubmission;
    RegisteredEstablishmentIDs;

    constructor({id, exporterName, mailingAddress, phoneNumber, licenceNumber, licenceExpiryDateUTC, isActive, organisationTypeID, reviewRequiredPriorToDeptSubmission, registeredEstablishmentIDs}) {
        this.ID = id;
        this.ExporterName = exporterName;
        this.MailingAddress = mailingAddress;
        this.PhoneNumber = phoneNumber;
        this.LicenceNumber = licenceNumber;
        this.LicenceExpiryDateUTC = licenceExpiryDateUTC;
        this.IsActive = isActive;
        this.OrganisationTypeID = organisationTypeID;
        this.ReviewRequiredPriorToDeptSubmission = reviewRequiredPriorToDeptSubmission;
        this.registeredEstablishmentIDs = registeredEstablishmentIDs;
    }
}