import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Stack, Alert, Spinner } from "react-bootstrap";
import { getDeckRecordsForConsignment } from "../../api/apiAccess";
import { DetailedDeckRecord } from "../../classes/DetailedDeckRecord";
import JsonToHtmlTable from "../../components/JsonToHtmlTable";
import { sortByProperty, sortArrayInStandardDeckOrder } from "../../utils/ListUtils";
import { EditViewString } from "../../components/ReadWriteElements/EditViewString";
import { UserContext } from "../..";
import { IsNullOrUndefined } from "../../utils/StringUtils";

// See comment in DeckRecord.js in the classes folder in regards to empty decks
const View_Deck_Record_General_Demeanour = ({ consignmentID, selectedColumnIndex, allowEditing, onSelectedColumnIndexChanged }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [jsonDataDisplayed, setJsonDataDisplayed] = useState({ "Head": [], "Body": [] });
    const [selectedFilter, setSelectedFilter] = useState("Non-Positive");
    const [hasData, setHasData] = useState(true);
    const [errorGettingData, setErrorGettingData] = useState(false);
    const userCtx = useContext(UserContext);

    // get data
    useEffect(() => {
        const fetchDeckRecords = async () => {
            let lResponse = await getDeckRecordsForConsignment(consignmentID);

            if (lResponse?.status === 200) {
                if (lResponse.data.length > 0) {
                    let lDetailedDeckRecords = [];
                    lResponse.data.forEach(d => {
                        lDetailedDeckRecords.push(new DetailedDeckRecord(d));
                    })
                    let lDateDayCombinations = getUniqueVoyageDaysThatHaveADeckRecord(lDetailedDeckRecords);
                    let lData = getFormattedData(lDateDayCombinations, lDetailedDeckRecords);

                    setJsonDataDisplayed(lData);
                    setHasData(true);
                }
                else {
                    setHasData(false);
                }
            }
            else {
                setErrorGettingData(true);
            }
            setIsLoading(false);
        }

        const getUniqueVoyageDaysThatHaveADeckRecord = (detailedDeckRecords) => {
            let lDays = [];

            detailedDeckRecords.forEach((record) => {
                let lExisitngDay = lDays.find(item => item.id === record.ConsignmentDay.id);
                if (!lExisitngDay) {
                    lDays.push(record.ConsignmentDay);
                }
            })

            lDays = sortByProperty(lDays, "dayNumber");
            return lDays;
        }

        const getFormattedData = (dateDayCombinations, detailedDeckRecords) => {
            if (dateDayCombinations && dateDayCombinations.length > 0 && detailedDeckRecords && detailedDeckRecords.length > 0) {
                let lHead = [
                    [["Deck"]],
                    [["Species"]]
                ];

                let lBody = [];

                dateDayCombinations.forEach((aConsignmentDay) => {
                    let lDateHeader = [
                        ["Day " + aConsignmentDay.dayNumber],
                        createLink(aConsignmentDay, "/editdeckrecordgendemeanour"),
                        ["Non-Positive", "Positive/neutral"]
                    ];
                    lHead.push(lDateHeader);

                    // get all detailed deck records fo x day
                    let lRecords = [...detailedDeckRecords].filter(dr => dr.ConsignmentDay.id === aConsignmentDay.id);


                    // sort records in standard deck order
                    lRecords = sortArrayInStandardDeckOrder(lRecords, "ConsignmentDeck.deckName", "ConsignmentDeck.isBridge");

                    lRecords.forEach((record) => {
                        let lDeckName = record.ConsignmentDeck.deckName;
                        let lDeckID = record.ConsignmentDeck.id;

                        let lDeckRecordToUpdate = lBody.find(obj => obj.deckID === lDeckID);
                        if (!lDeckRecordToUpdate) {
                            lDeckRecordToUpdate = { groupRowHeading: lDeckName, groupRowsData: [], deckID: lDeckID };
                            lBody.push(lDeckRecordToUpdate);
                        }

                        let lSpeciesRecord = lDeckRecordToUpdate.groupRowsData.find(obj => obj.speciesID === record.Species.id);
                        // add new row for species if there is not one already
                        if (!lSpeciesRecord) {
                            let lHeading = record.Species.speciesName;
                            lSpeciesRecord = { rowHeading: lHeading, data: [], speciesID: record.Species.id };
                            lDeckRecordToUpdate.groupRowsData.push(lSpeciesRecord);
                        }

                        // If the deck was marked as empty on the ship display a hyphen
                        let lUnhappy = "-";
                        let lContent = "-";
                        if (record.DeckRecord.isEmpty === false) {
                            lUnhappy = !IsNullOrUndefined(record.DeckRecord.generalDemeanourUnhappyPercent) ? record.DeckRecord.generalDemeanourUnhappyPercent + "%" : "";
                            lContent = !IsNullOrUndefined(record.DeckRecord.generalDemeanourContentPercent) ? record.DeckRecord.generalDemeanourContentPercent + "%" : "";
                        }

                        let lDateData = [lUnhappy, lContent];
                        lSpeciesRecord.data.push(lDateData);
                    })
                })

                let lData = { "Head": lHead, "Body": lBody };
                return lData;
            }
            else {
                return { "Head": [], "Body": [] };
            }
        }

        fetchDeckRecords();
    }, [consignmentID]);

    const createLink = (aConsignmentDay, aPath) => {
        // If a user clicks edit we should take them to the same tab that they are viewing the previous deck record for
        let lText = EditViewString(userCtx.user.writeAccess);
        return [{ link: { href: aPath, text: lText, consignmentDay: aConsignmentDay } }];
    }

    return (
        <>
            {
                isLoading ? (
                    <div className="text-center pt-5">
                        <Spinner className="mx-auto" />
                        <div className="mx-auto">Loading data...</div>
                    </div>
                ) : null
            }
            {
                isLoading === false && hasData === true ?
                    <div>
                        <Row className="mb-3">
                            <Col>
                                <Stack gap={2} direction="horizontal" style={{ justifyContent: "end" }}>
                                    <div className="field-label">Compare to:</div>
                                    <Form.Select value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)} style={{ maxWidth: "200px" }}>
                                        <option value="Non-Positive">Non-Positive</option>
                                        <option value="Positive/neutral">Positive/neutral</option>
                                    </Form.Select>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <JsonToHtmlTable jsonData={jsonDataDisplayed} filter={selectedFilter} duplicateHeaders={false} initialSelectedColumnIndex={!isNaN(selectedColumnIndex) ? selectedColumnIndex : undefined} showEdit={allowEditing} onSelectedColumnChanged={onSelectedColumnIndexChanged} />
                            </Col>
                        </Row>
                    </div> : null
            }
            {
                isLoading === false && hasData === false && errorGettingData === false ?
                    <Alert variant="info" className="field-label">There is currently no data for the selected consignment.</Alert>
                    : null
            }
            {
                isLoading === false && errorGettingData === true ?
                    <Alert variant="info" className="field-label">Something went wrong while fetching previous deck records.</Alert>
                    : null
            }
        </>
    )
};

export default View_Deck_Record_General_Demeanour;