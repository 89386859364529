import React from "react";
import { useEffect, useState } from "react";
import { Container, Col, Row} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
import VoyageDetails from "./VoyageDetails";
import VoyageConsignmentDetails from "./VoyageConsignmentDetails";
import TreatmentOverview from "./TreatmentOverview";
import MortalitiesOverview from "./MortalitiesOverview";
import FeedRemainingOverview from "./FeedRemainingOverview";
import AvgFeedOverview from "./AvgFeedOverview";
import AvgWaterOverview from "./AvgWaterOverview";
import ReviewStatusMobile from "./ReviewStatusMobile";
import SubmissionStatusMobile from "./SubmissionStatusMobile";
import PortList from "./PortList";
import LNCNumberMobile from "./LNCNumberMobile";
import ConsignmentInfoMobile from "./ConsignmentInfoMobile";
import { useNavigate } from "react-router-dom";


const ConsignmentOverview = ({ voyage, isStarted, isMobile }) => {
  const navigate = useNavigate();
  const [departurePorts, setDeparturePorts] = useState([]);
  const [destinationPorts, setDestinationPorts] = useState([]);
  const [endOfVoyageReport, setEndOfVoyageReport] = useState(null);
  const [mobileViewExpanded, setMobileViewExpanded] = useState(false);
  const [isConsignmentViewed, setIsConsignmentViewed] = useState(false);
  const [isLoadingAndDepartureReviewed, setIsLoadingAndDepartureReviewed] = useState(false);
  const [isDischargeReviewed, setIsDischargeReviewed] = useState(false);
  const [isEndOfVoyageReviewed, setIsEndOfVoyageReviewed] = useState(false);

  useEffect(() => {
    setDeparturePorts(voyage.departurePorts);
    setDestinationPorts(voyage.destinationPorts);
  }, [voyage.departurePorts, voyage.destinationPorts]);

  useEffect(() => {
    setEndOfVoyageReport(voyage?.detailedConsignments[0]?.EndOfVoyage);
  }, voyage.consignments);

  useEffect(() => {
    if (endOfVoyageReport?.endOfVoyageReport?.isEOVReviewed) {
      setIsEndOfVoyageReviewed(true);
    }
  }, [endOfVoyageReport]);

  const handleViewConsignmentClicked = (consignmentID) => {
    if (consignmentID !== undefined) {
        let navPath = isStarted ? `/consignmentdashboard/${consignmentID}` : `/viewinactiveconsignment/${consignmentID}`;
        navigate(navPath);
    } 
    setIsConsignmentViewed(true);
  };

  const handleLoadingAndDepartureReportReviewed = (id) => {
    navigate(`/voyagedepartureandvesselloading/${id}`);
    setIsLoadingAndDepartureReviewed(true);
  };

  const handleDischargeReportReviewed = (consignmentID, destinationPortID) => {
    navigate(`/dischargereport/${consignmentID}/${destinationPortID}`);
    setIsDischargeReviewed(true);
  };

  const handleEOVReportReviewed = (consignmentID) => {
    navigate(`/endofvoyagereport/${consignmentID}`);
    setIsEndOfVoyageReviewed(true);
  };



  return (
    <Container className="mb-2" fluid>
      {
        isMobile ? 
          (
            <Row>
              <Col xs={12}>
                <Accordion flush style={{border: "none"}}>
                  <Accordion.Header 
                    onFocus={(e) => { e.target.blur()}}
                  >
                    <Row className="w-100">
                      <Col xs={12}>
                        <div className="green-underline-heading-light px-0">
                          <div className="d-inline-block">{voyage.vessel}</div>
                        </div>
                      </Col>
                    </Row>

                  </Accordion.Header>
                  <Accordion.Body className="mx-3" style={{ padding:'11px' }}>
                    <Row className="p-3" style={{ backgroundColor: 'var(--secondary-colour)', borderTopLeftRadius: '8px', borderTopRightRadius: '8px', color: 'white' }}>
                      <Col xs={6}>
                        Voyage Details                  
                      </Col>
                      <Col xs={6} style={{ textAlign: 'right '}} >
                        {voyage.isMultiConsignmentVoyage || voyage.consignments?.length > 1 ? (
                          <span className="me-2">
                            <div className="dashboard-tooltip">
                              <FontAwesomeIcon icon={faUsers} />
                              <div className="dashboard-tooltiptext p-2">This voyage has multiple consignments.</div>
                            </div>
                          </span>
                        ) : null}
                        {isStarted ? (
                          <>
                            Day <span className="primary-green">{voyage.days?.currentDayNum}</span> 
                            {/* of {voyage.days?.totalDayNum} */}
                          </>
                        ) : (
                          <div style={{ textAlign: "right" }}>
                            <span className="primary-green">ETD:</span> {voyage.days?.etd}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className="pt-3 px-0" style={{ borderLeft: '#DEDEDE 1px solid', borderRight: '#DEDEDE 1px solid', borderBottom: '#DEDEDE 1px solid'}}>
                      <Col className="px-0 mx-0" xs={12}>
                        <div className="mb-3 mx-3" style={{ fontWeight: '500', fontSize: '15px', lineHeight: '22.5px', color: 'var(--darkblue)'}}>Daily reporting</div>
                        
                        <div className="mx-3 mb-3">
                          <ReviewStatusMobile status={voyage.reviewStatus} />
                        
                          <SubmissionStatusMobile status={voyage.submissionStatus} />
                        
                          <PortList voyage={voyage} deptPorts={departurePorts} destPorts={destinationPorts}/>
                        </div>
                        
                        <ConsignmentInfoMobile 
                          voyage={voyage} 
                          isStarted={isStarted} 
                          onViewConsignmentClicked={handleViewConsignmentClicked} 
                          eovReport={endOfVoyageReport}
                          onLoadingAndDepartureViewed={handleLoadingAndDepartureReportReviewed}
                          onDischargeViewed={handleDischargeReportReviewed}
                          onEOVViewed={handleEOVReportReviewed}
                        />
                                          
                      </Col>
                    </Row>
                    
                  </Accordion.Body>
                </Accordion>
              </Col>
            </Row>
          ) :
          (
            <>
              <Row>
                <Col xs={12}>
                  <div className="green-underline-heading-light px-0">
                    <div className="d-inline-block w-50">{voyage.vessel}</div>
                    <div className="d-inline-block w-50 text-end field-label" style={{ fontSize: "15px" }}>
                      {voyage.isMultiConsignmentVoyage || voyage.consignments?.length > 1 ? (
                        <span className="me-2">
                          <div className="dashboard-tooltip">
                            <FontAwesomeIcon icon={faUsers} />
                            <div className="dashboard-tooltiptext p-2">This voyage has multiple consignments.</div>
                          </div>
                        </span>
                      ) : null}
                      {isStarted ? (
                        <>
                          Day <span className="primary-green">{voyage.days?.currentDayNum}</span> 
                          {/* of {voyage.days?.totalDayNum} */}
                        </>
                      ) : (
                        <div style={{ textAlign: "right" }}>
                          <span className="primary-green">ETD:</span> {voyage.days?.etd}
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <table className="w-100 table consignment-table">
                <tbody>
                  <tr style={{ height: "1px" }} className="dashboard-consignment-header-row">
                    <td className="text-center" style={{ width: "20%", height: "inherit" }}>
                      <div>Voyage details</div>
                    </td>
                    <td className="text-center" style={{ height: "inherit" }}>
                      <table className="w-100" style={{ height: "100%" }}>
                        <tbody>
                          <tr>
                            <td
                              className="text-center consignment-table-cell-no-border-top consignment-table-cell-no-border-bottom consignment-table-cell-no-border-left"
                              style={{ width: "15%" }}
                            >
                              <div>Consignment</div>
                            </td>
                            <td
                              className="text-center consignment-table-cell-no-border-top consignment-table-cell-no-border-bottom consignment-table-cell-no-border-left"
                              style={{ width: "25%" }}
                            >
                              <div className="m-0 p-0">Treatments</div>
                            </td>
                            <td
                              className="text-center consignment-table-cell-no-border-top consignment-table-cell-no-border-bottom consignment-table-cell-no-border-left"
                              style={{ width: "15%" }}
                            >
                              <div className="m-0 p-0">Mortalities</div>
                            </td>
                            <td
                              className="text-center consignment-table-cell-no-border-top consignment-table-cell-no-border-bottom consignment-table-cell-no-border-left"
                              style={{ width: "25%" }}
                            >
                              <div className="m-0 p-0">Feed remaining</div>
                            </td>
                            <td
                              className="text-center consignment-table-cell-no-border-top consignment-table-cell-no-border-bottom consignment-table-cell-no-border-left"
                              style={{ width: "10%" }}
                            >
                              <div className="m-0 p-0">Avg feed</div>
                            </td>
                            <td className="text-center consignment-table-cell-no-border" style={{ width: "10%" }}>
                              <div className="m-0 p-0">Avg water</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>

                  <tr style={{ height: "1px" }}>
                    <td style={{ width: "20%", height: "inherit", verticalAlign: "top" }}>
                      <VoyageDetails
                        voyage={voyage}
                        isMultiConsignmentVoyage={voyage.isMultiConsignmentVoyage}
                        departurePorts={departurePorts}
                        destinationPorts={destinationPorts}
                        reviewStatus={voyage.reviewStatus}
                        submissionStatus={voyage.submissionStatus}
                        consignments={voyage.consignments}
                        isStarted={isStarted}
                      />
                    </td>
                    <td style={{ height: "inherit" }}>
                      <table className="w-100 h-100">
                      <tbody>
                        {voyage.detailedConsignments?.map((c, i) => {
                          let isLastRow = i === voyage.consignments.length - 1;

                          return (
                            <tr key={`voyage_overview_${i}`}>
                              <td
                                className={
                                  isLastRow
                                    ? "consignment-table-cell-no-border-top consignment-table-cell-no-border-left consignment-table-cell-no-border-bottom"
                                    : "consignment-table-cell-no-border-top consignment-table-cell-no-border-left"
                                }
                                style={{ width: "15%" }}
                              >
                                <div className="m-0 p-0">
                                  <VoyageConsignmentDetails
                                    detailedConsignment={c}
                                    eovReport={endOfVoyageReport}
                                    isStarted={isStarted}
                                    onViewConsignmentClicked={handleViewConsignmentClicked}
                                    onLoadingAndDepartureViewed={handleLoadingAndDepartureReportReviewed}
                                    onDischargeViewed={handleDischargeReportReviewed}
                                    onEOVViewed={handleEOVReportReviewed}
                                  />
                                </div>
                              </td>
                              <td
                                className={
                                  isLastRow
                                    ? "consignment-table-cell-no-border-top consignment-table-cell-no-border-left consignment-table-cell-no-border-bottom"
                                    : "consignment-table-cell-no-border-top consignment-table-cell-no-border-left"
                                }
                                style={{ width: "25%" }}
                              >
                                <div className="m-0 p-0">
                                  <TreatmentOverview consignment={c.Consignment} index={i} isStarted={isStarted} />
                                </div>
                              </td>
                              <td
                                className={
                                  isLastRow
                                    ? "consignment-table-cell-no-border-top consignment-table-cell-no-border-left consignment-table-cell-no-border-bottom"
                                    : "consignment-table-cell-no-border-top consignment-table-cell-no-border-left"
                                }
                                style={{ width: "15%" }}
                              >
                                <div className="m-0 p-0">
                                  <MortalitiesOverview consignment={c.Consignment} index={i} isStarted={isStarted} />
                                </div>
                              </td>
                              <td
                                className={
                                  isLastRow
                                    ? "consignment-table-cell-no-border-top consignment-table-cell-no-border-left consignment-table-cell-no-border-bottom"
                                    : "consignment-table-cell-no-border-top consignment-table-cell-no-border-left"
                                }
                                style={{ width: "25%" }}
                              >
                                <div className="m-0 p-0">
                                  <FeedRemainingOverview consignment={c.Consignment} index={i} isStarted={isStarted}/>
                                </div>
                              </td>
                              <td
                                className={
                                  isLastRow
                                    ? "consignment-table-cell-no-border-top consignment-table-cell-no-border-left consignment-table-cell-no-border-bottom"
                                    : "consignment-table-cell-no-border-top consignment-table-cell-no-border-left"
                                }
                                style={{ width: "10%" }}
                              >
                                <div className="m-0 p-0">
                                  <AvgFeedOverview consignment={c.Consignment} index={i} isStarted={isStarted} />
                                </div>
                              </td>
                              {i == 0 ? (
                                <td className="consignment-table-cell-no-border" rowSpan={voyage.consignments.length} style={{ width: "10%" }}>
                                  <div className="m-0 p-0">
                                    <AvgWaterOverview consignment={c.Consignment} index={i} isStarted={isStarted} />
                                  </div>
                                </td>
                              ) : null}
                            </tr>
                          );
                        })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )
      }
    </Container>
  );
};

export default ConsignmentOverview;
