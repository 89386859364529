import '../../css/voyagedetails.css';
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Stack, Button, Row, Col } from "react-bootstrap";
import ReviewStatus from "./ReviewStatus";
import SubmissionStatus from "./SubmissionStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckCircle, faCheckSquare, faSquare, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheckSquare as faCheckSquareOutline } from '@fortawesome/free-regular-svg-icons';
import { createUniqueObjectArrayByProperty } from '../../utils/ListUtils';
import PortList from './PortList';

const VoyageDetails = ({ voyage, departurePorts, destinationPorts, reviewStatus, submissionStatus, isStarted }) => {
    return (
        <Container style={{ height: "100%" }}>
            <div className="pt-0 pb-3" style={{ height: "100%" }}>
                <PortList voyage={voyage} deptPorts={departurePorts} destPorts={destinationPorts} />
                {
                    isStarted ? (
                        <>
                            <Row className='pt-3'>
                                <Col xs={12}>
                                    <div className="field-label darkblue mb-2">Daily Reporting</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <ReviewStatus status={reviewStatus} />
                                </Col>
                                <Col xs={6}>
                                    <SubmissionStatus status={submissionStatus} />
                                </Col>
                            </Row>
                        </>
                    ) : null
                }
            </div>
        </Container >
    )
};

export default VoyageDetails;