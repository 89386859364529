import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import LoggedOutContainer from '../layout/LoggedOutContainer';
import { UserContext } from '..';
import { updateLoggedInUser } from '../helpers/SessionHelper';
import { setTermsAndConditionsAgreedTo } from '../api/apiAccess';

const TermsAndConditions = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext)
    const goBack = () => navigate('/');

    const onAcceptTermsAndConditions = async () => {
        if (userCtx?.user?.id) {
            await setTermsAndConditionsAgreedTo();
        }
        updateTermsAndConditionsAgreedTo();
        window.scrollTo(0,0);
        navigate("/Dashboard");
    }

    const updateTermsAndConditionsAgreedTo = () => {
        // Update terms and conditions state in db for user
        // TEMP DEV UPDATE
        let updatedUser = userCtx.user;
        updatedUser.isEULAAgreedTo = true;
        userCtx.setUser(updatedUser);
        updateLoggedInUser(updatedUser);
    }
     
    return (
        <LoggedOutContainer className="termsAndConditionPage">
            <Container>
            <Row className="mb-4 mt-4">
                <Col>
                    <Button variant="primary" size="lg" type="submit" onClick={goBack} >&lt; Back</Button>
                </Col>
            </Row>
            <Row className="my-5">
                   <h1>LIVEXCollect Terms and Conditions</h1>
                   <p>Australian Livestock Export Corporation Ltd ABN 88 082 408 740 (<strong>LiveCorp</strong>) administers LIVEXCollect. LiveCorp (<strong>LiveCorp, we, our, us</strong>) grants eligible users a licence to use LIVEXCollect on the terms and conditions of use set out below (<strong>Terms and Conditions</strong>).</p>
                   <p>Please read this document carefully. These Terms and Conditions regulate your access to, and use of, LIVEXCollect. Upon submitting an application under clause 3 of these Terms and Conditions, you agree that, if your application is successful, you will be deemed to be a User for the purposes of these Terms and Conditions and acknowledge that you have read and understood, and are deemed to have accepted, these Terms and Conditions..</p>
                    <ol className='manageLine px-5'>
                        <li><strong>Definitions</strong>    
                            <ol><li >In these Terms and Conditions:</li>   </ol>
                            <div className='Paragraphg'>
                                    <p><strong>Accredited Stockperson</strong> means a stockperson who is accredited by LiveCorp for the management of livestock on vessels.</p>
                                    <p><strong>Australian Accredited Veterinarian (AAV)</strong> means a veterinarian who is accredited under the Export Control (Animals) Rules 2021 to carry out export operations in approved export programs.</p>
                                    <p><strong>Company Level Data</strong> means an Exporter's Voluntary Data, Regulatory Data and data derived from that data using components of LIVEXCollect, but does not include Live Export Data or De-identified Company Level Data.</p>
                                    <p><strong>Dashboard</strong> means the dashboard functionality of LIVEXCollect which enables Users to view (depending on their level of access) the data comprised in LIVEXCollect (or a subset thereof).</p>
                                    <p><strong>Databases</strong> means the databases into which raw data supplied in the LIVEXCollect Data Collection Tools is input and in which data derived from those raw inputs and viewed via the Dashboard is stored.</p>
                                    <p><strong>Declared Industry Organisations</strong> means declared industry, and live-export industry, research and marketing body or bodies pursuant to s 60(1)-(3A) of the Australian Meat and Live-stock Industry Act 1997.</p>
                                    <p><strong>Declared Industry Users</strong> means an individual nominated by a Declared Industry Organisation to use LIVEXCollect on its behalf.</p>
                                    <p><strong>De-identified Company Level Data </strong>means Company Level Data in respect of which information expressly disclosing the identity of the relevant Exporter is removed, together with data derived from such data using components of LIVEXCollect.</p>
                                    <p><strong>Department</strong> means the Australian Government Department of Agriculture, Water and the Environment or its successors.</p>
                                    <p><strong>Department Requirements</strong> means any terms and conditions imposed by the Department on an Exporter's live-stock export licence and operations including, without limitation, the sending of daily and end-of-voyage reports for sea voyages, end-of-journey reports for air freight and registered premise mortality reports for consignments to the Department via email.</p>
                                    <p><strong>Exporter</strong> means a business or company whose activities involve the export of livestock from Australia.</p>
                                    <p><strong>Exporter Users</strong>  means an individual nominated by an Exporter to use LIVEXCollect on its behalf but does not include individual Accredited Stockpersons, Australian Accredited Veterinarians or Registered Premises Operators engaged by the Exporter and whom are provided only with limited access to the LIVEXCollect Data Collection Tools.</p>
                                    <p><strong>Harmful Code</strong> means any virus, disabling or malicious device or code, ‘worm’, ‘trojan’, ‘time bomb’ or other harmful or destructive code, but does not include any ‘software lock’ or other technical mechanism that is included to manage the proper use of any software.</p>
                                    <p><strong>Industry Organisations</strong> means the Declared Industry Organisations and any Other Industry Organisations.</p>
                                    <p><strong>LIVEXCollect</strong> is a data collection system comprised of the LIVEXCollect Data Collection Tools, Database(s) and Dashboard.</p>
                                    <p><strong>LIVEXCollect Data Collection Tools</strong> means the spreadsheets into which Users can input data for subsequent inclusion into LIVEXCollect.</p>
                                    <p><strong>Live Export Data</strong> means Company Level Data that is both de-identified and aggregated, and data derived from such data using components of LIVEXCollect.</p>
                                    <p><strong>Other Industry Organisations</strong> means any organisation that is not a Declared Industry Organisation considered by LiveCorp, in its sole and absolute discretion, to be an industry organisation.</p>
                                    <p><strong>Other Industry Users</strong> means an individual nominated by an Other Industry Organisation to use LIVEXCollect on its behalf.</p>
                                    <p><strong>Registered Establishment</strong> means a premises registered to prepare livestock for export under the Export Control Act 2020 and the Export Control (Animals) Rules 2021.</p>
                                    <p><strong>Regulatory Data</strong> means raw data in relation to livestock export that is collated by the Exporter using the LIVEXCollect Data Collection Tools and that the Exporter is required to provide to the Department, and to LiveCorp for inclusion in the Databases, in order to meet the Department Requirements.</p>
                                    <p><strong>Users (you, your)</strong> means any authorised user of LIVEXCollect including, without limitation, Declared Industry Organisations and Declared Industry Users, Other Industry Organisations and Other Industry Users, Exporters and Exporter Users, Accredited Stockpersons, Australian Accredited Veterinarians, Registered Establishments and Department officers.</p>
                                    <p><strong>Voluntary Data </strong>means raw data in relation to livestock export that is not Regulatory Data collated by the Exporter using the LIVEXCollect Data Collection Tools and that the Exporter provides to LiveCorp for inclusion in the Databases.</p>
                            </div>
                        </li>   
                        <li><strong>Terms and Conditions</strong> 
                            <ol><li>We may change these Terms and Conditions without your consent: 
                                    <ol> (a) to comply with any law or regulation;</ol>
                                    <ol> (b) to reflect any decision of a court, ombudsman or a regulator;</ol>
                                    <ol> (c) to correct an error or to simplify them;</ol>
                                    <ol> (d) for security reasons; or</ol>
                                    <ol> (e) for any other reason, including as a result of changed industry practice, circumstances or environment or changed features</ol>
                                </li>
                                <li>Unless these terms say otherwise, we will give you 20 days' written notice of any change. However, unless the law requires us to, we do not have to give advance notice if the change is required for the security of the system or individual accounts including the prevention of criminal activity or fraud.</li>
                                <li>An electronic version of these Terms and Conditions will be available on LiveCorp's website. In the event of any inconsistency between the Terms and Conditions as set out in this document and the Terms and Conditions available on LiveCorp's website, the latter will prevail.:</li>
                                <li> Use of LIVEXCollect may require that you have access to appropriate third party software and hardware, such as Microsoft Excel, Kizeo and Amazon Web Services. For certain functions, LIVEXCollect also requires that users have access to the Internet. LiveCorp is not responsible for providing any third party software or hardware or for providing you with access to the Internet. LiveCorp may change or substitute the third party software required for use with LIVEXCollect without notice in its sole and absolute discretion.</li>
                                <li>LiveCorp will use its best endeavours to make the LIVEXCollect Data Collection Tools available to Exporters, Exporter Users and all other Users. However, for the avoidance of doubt, these Terms and Conditions are not an expressed or implied service level agreement between you and LiveCorp and LiveCorp does not guarantee uninterrupted access to, or use of, LIVEXCollect in part or whole.</li>
                            </ol>
                        </li>
                        <li><strong>Access</strong>
                            <ol> <li>Prior to LiveCorp providing access to their nominated users with administration rights, each Exporter, Registered Establishment, Department and Industry Organisation must:
                                        <ol> (a) complete an organisation user access form, signed by a representative for and on behalf of the Exporter, Registered Establishment, Department or Industry Organisation (as the case may be) who has the authority to bind their organisation; and</ol>
                                        <ol> (b) send it to LiveCorp by way of email to the following address: livexcollect@livecorp.com.au.</ol>
                                    </li>
                                
                                <li>Each Accredited Stockperson or AAV requiring access to LIVEXCollect must apply to LiveCorp for access to LIVEXCollect by:
                                        <ol> (a) completing a user access form; and</ol>
                                        <ol> (b) sending it to LiveCorp by way of email to the following address: livexcollect@livecorp.com.au.</ol>
                                    </li>
                                
                                <li>Upon receipt of an email pursuant to clause 3.1 or 3.2, LiveCorp will access the application and may request or conduct any enquiries it considers necessary, in its sole and absolute discretion, to verify the identity of the applicant or the purposes for which access is sought by the applicant.
                                    </li>
                                
                                <li>Upon completion of its assessment under clause 3.3, LiveCorp will notify the applicant:
                                        <ol> (a) that they have been refused access; or</ol>
                                        <ol> (b) that their application has been successful, the level of access to LIVEXCollect that the applicant will receive and notice of any additional steps the applicant must take in order to obtain or make use of its access.</ol>
                                    </li>
                                
                                <li>Where an Exporter provides a completed organisation user access form under clause 3.1, LiveCorp will, as far as reasonably practicable, use its best endeavours to complete the assessment and notification under clauses 3.3 and 3.4 within 10 business days of receiving the application.</li>

                            </ol>
                        </li>
                        <li><strong>Purpose</strong>
                            <ol> 
                               <li>LIVEXCollect enables the collection of, and access to, data in relation to livestock export. A User's access to, and the functionality available to them when using, LIVEXCollect, depends on the category of user, as further set out in clauses 5 to 7 below.</li>
                            </ol>
                        </li>
                        <li><strong>Administration of LIVEXCollect</strong>
                            <ol> 
                                <li>LiveCorp is responsible for the administration of LIVEXCollect, including determining who is able to access LIVEXCollect and the functionalities to which that User has access (including those available via the Dashboard).</li>
                              <li>Without limiting its role as administrator of LIVEXCollect, LiveCorp may:
                                        <ol> (a) input data contained in the LIVEXCollect Data Collection Tools into the Databases;</ol>
                                        <ol> (b) export from LIVEXCollect, and provide to Industry Organisations, Live Export Data for the purposes of those organisations' respective research, policy and advisory functions (including by making Live Export Data available to Declared Industry Users or Other Industry Users, as the case may be);</ol>
                                        <ol> (c) export from LIVEXCollect, and provide to the Department, the Regulatory Data;</ol>
                                        <ol> (d) export from LIVEXCollect, and provide to an Exporter, that Exporter's Company Level Data upon receipt of a written request from the Exporter pursuant to clause 6.1 (e);</ol>
                                        <ol> (e) access Live Export Data, De-identified Company Level Data and, subject to the prior written consent of the relevant Exporter, Company Level Data, for the purposes of LiveCorp's research, services and advisory functions;</ol>
                                        <ol> (f) access Live Export Data and De-identified Company Level Data to improve LIVEXCollect and its related software and reporting tools, in order to assist in the provision of better information, support and services to Users;</ol>
                                        <ol> (g) access Company Level Data for the purposes of enabling the creation of De-identified Company Level Data and/or Live Export Data;</ol>
                                        <ol> (h) access Company Level Data where required to enable the effective administration and delivery of LIVEXCollect and to address ad-hoc queries or requests for support from the relevant Exporter; and</ol>
                                        <ol> (i) store data provided via the LIVEXCollect Data Collection Tools on its servers for the purposes of backing up that data; and</ol>
                                        <ol> (j) provide access to LIVEXCollect to those of its directors, employees, contractors, partners and agents involved on behalf of LiveCorp in the functions and activities described in this clause 5.2</ol>
                                    </li>
                            </ol>
                        </li>
                        <li><strong>Exporters</strong>
                            <ol> 
                              
                                <li> Exporters are entitled to:
                                        <ol> (a) use the LIVEXCollect Data Collection Tools for the purposes of reporting Regulatory Data directly to the Department;</ol>
                                        <ol> (b) provide Company Level Data to LiveCorp for inclusion into LIVEXCollect;</ol>
                                        <ol> (c) request access for Exporter Users;</ol>
                                        <ol> (d) view the Exporter's Company Level Data and/or De-identified Company Level Data on the LIVEXCollect Dashboard; and</ol>
                                        <ol> (e) request a copy of the Exporter's Company Level Data and/or De-identified Company Level Data from LiveCorp.</ol>
                                    </li>
                                
                                <li>Each Exporter may nominate one or more employees and contractors as Exporter Users, who will have access to LIVEXCollect for one or more of the purposes outlined in clause 6.1.
                                    </li>
                                
                                <li> Exporters may provide the LIVEXCollect Data Collection Tools to Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments engaged by them for the purpose of those persons inputting data into the LIVEXCollect Data Collection Tools on the Exporter's behalf and submitting them to LiveCorp for incorporation of that data into the Databases and to the Department for the purposes of satisfying the Department Requirements. Each Exporter:
                                        <ol> (a) Selects an Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments as registered by LiveCorp for the LIVEXCollect Data Collection Tools to whom the Exporter provides access to from time to time;</ol>
                                        <ol> (b) must provide to those Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments the privacy collection notice issued by LiveCorp in respect of LIVEXCollect, available from LiveCorp on request; and(b) provide Company Level Data to LiveCorp for inclusion into LIVEXCollect;</ol>
                                        <ol> (c) agrees that LiveCorp can treat data contained in the LIVEXCollect Data Collection Tools as submitted by a LIVEXCollect registered Accredited Stockperson, Australian Accredited Veterinarian and/or Registered Establishments under clause 6.3(a) as being the Company Level Data of the Exporter and will, to the extent necessary, procure the express agreement of the Accredited Stockperson, Australian Accredited Veterinarians and/or Registered Establishments for this purpose.</ol>
                                      
                                    </li>
                                
                                <li> Exporters acknowledge and agree that they are responsible for keeping LiveCorp informed about the changes in access of any Exporter User or changes to Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments under clause 6.3(a) and are liable for the actions of any of their Exporter Users and the Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments which they have selected. Without limiting the foregoing, Exporters must inform LiveCorp immediately if an Exporter User ceases to be entitled to use LIVEXCollect, if there is a change to Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments under clause 6.3(a), or if the Exporter wishes to cease use of LIVEXCollect. Where such a request relating to an Exporter or Exporter User is made, the Exporter's, or Exporter User's, access credentials will be deactivated.
                                    </li>
                                
                                <li> Exporters acknowledge that:
                                        <ol> (a) Regulatory Data submitted to the Department via the LIVEXCollect Data Collection Tools must be accurate and complete, and submitting false or misleading information to the Department is a criminal offence under applicable laws;</ol>
                                        <ol> (b) they are required to send the Regulatory Data to LiveCorp for incorporation into LIVEXCollect as and when they report that Regulatory Data to the Department;</ol>
                                        <ol> (c) any Regulatory Data received by the Department (whether directly from the Exporter or Exporter Users, from LiveCorp, or otherwise) can be used by the Department in accordance with applicable laws;</ol>
                                        <ol> (d) LiveCorp is entitled to export from LIVEXCollect, and provide to the Department, the Exporter's Regulatory Data from time to time;</ol>
                                        <ol> (e) LiveCorp may incorporate any Voluntary Data provided by the Exporter into LIVEXCollect;</ol>
                                        <ol> (f) their Regulatory Data and Voluntary Data constitutes Company Level Data for the purposes of these Terms and Conditions;</ol>
                                        <ol> (g) whilst LIVEXCollect may assist in the conduct of its business or activities, including monitoring and performing the activities in compliance with applicable laws, including the Department Requirements, LIVEXCollect is not designed or intended to ensure or guarantee that the Exporter's use of LIVEXCollect (including the LIVEXCollect Data Collection Tools) ensures compliance with those laws or requirements;</ol>
                                        <ol> (h) whilst LiveCorp will use its best endeavours to make the LIVEXCollect Data Collection Tools available to Exporters, Exporter Users and all other Users, LiveCorp does not guarantee uninterrupted access to, or use of, LIVEXCollect in part or in whole;</ol>
                                        <ol> (i) LiveCorp does not guarantee or warrant that LIVEXCollect is able to assist Exporters in performing or complying with all applicable laws and Department Requirements;</ol>
                                        <ol> (j) LiveCorp is not responsible for the accuracy, adequacy, suitability or completeness of any Live Export Data, Company Level Data or De-identified Company Level Data which is input or uploaded into, or obtained from, LIVEXCollect;</ol>
                                        <ol> (k) they are responsible for the reporting of any Regulatory Data directly to the Department and that LiveCorp is not responsible for the reporting of Regulatory Data to the Department on behalf of the Exporter; and</ol>
                                        <ol> (l) LiveCorp is not engaged in the business of rendering legal advice and the Exporter is solely responsible for ensuring that it complies with all applicable laws and Department Requirements.</ol>
                                    </li>
                                
                                <li>Each Exporter releases and indemnifies LiveCorp, its servants and agents against all actions, claims and demands (including the cost of defending or settling any action, claim or demand) which may be instituted against LiveCorp arising out of, or with respect to, any breach by the Exporter, its Exporter Users or any Accredited Stockpersons, Australian Accredited Veterinarians and/or Registered Establishments engaged by it of any applicable law or Department Requirement or as a result of any civil or regulatory enforcement proceedings arising out of the Exporter's, or its Exporter Users' or Accredited Stockpersons, Australian Accredited Veterinarians' and/or Registered Establishments', use or operation of LIVEXCollect (including the LIVEXCollect Data Collection Tools).
                                    </li>
                                
                            </ol>
                        </li>
                        <li><strong>Industry Organisations</strong>
                            <ol> <li>
                                    <ol> (a) Where approved by LiveCorp, Declared Industry Organisations are able to access Live Export Data, De-identified Company Level Data and/or, subject to the prior written consent of the relevant Exporter, Company Level Data, for the purposes of those organisations' respective research, policy and advisory functions. Declared Industry Organisations may nominate one or more employees as Declared Industry Users, who will have access to LIVEXCollect for this purpose.</ol>
                                    <ol> (b) Where approved by LiveCorp, Other Industry Organisations excluding Declared Industry Organisations are able to access Live Export Data and/or, subject to the prior written consent of the relevant Exporter, De-identified Company Level Data and/or Company Level Data, for the purposes of those organisations' respective research, policy and advisory functions. Other Industry Organisations may nominate one or more employees as Other Industry Users, who will have access to LIVEXCollect for this purpose.</ol>
                                    <ol> (c) Without prejudice to any other provision of these Terms and Conditions, each Industry Organisation acknowledges and agrees that:
                                        <ul> (i) any access granted pursuant to clauses 7(a) or (b) is granted on condition that it and its nominated users handle the data to which they obtain access in accordance with LiveCorp’s Data Governance Policy and any other requirements specified by LiveCorp, as varied from time to time;</ul>
                                        <ul> (ii) it is responsible for keeping LiveCorp informed about the changes in access of its nominated users and is liable for the actions of any of its nominated users. Without limiting the foregoing, each Industry Organisation must inform LiveCorp immediately if any of its nominated users ceases to be employed by it or ceases to be its nominee;</ul>
                                        <ul> (iii) LiveCorp is not responsible for the accuracy, adequacy, suitability or completeness of any Live Export Data, Company Level Data or De-identified Company Level Data that it, or its nominated users, obtains or accesses via LIVEXCollect; and</ul>
                                        <ul> (iv) LiveCorp does not guarantee uninterrupted access to, or use of, LIVEXCollect in part or whole.</ul> 
                                    </ol>
                                   
                                </li>
                            
                            </ol>
                        </li>
                        <li><strong>Ownership of LIVEXCollect</strong>
                            <ol><li>With the exception of Live Export Data, Company Level Data and De-identified Company Level Data, Users agree that all intellectual property rights in LIVEXCollect (and each component thereof) including (without limitation) the design, trade marks, logos and branding displayed on and associated with LIVEXCollect, copyright in the Databases, and any
                                    intellectual property rights in LIVEXCollect, the LIVEXCollect Data Collection Tools and the LIVEXCollect Dashboard), belongs to LiveCorp (Intellectual Property). The Intellectual Property is protected by applicable intellectual property and other laws, including copyright, and you must not use it except as strictly permitted by these Terms and Conditions. </li>
                                <li>Each User agrees that they will neither attempt to modify the content or appearance of LIVEXCollect, or otherwise interfere with the operation of LIVEXCollect, save to the extent expressly provided for in these Terms and Conditions. </li>
                                <li>LiveCorp represents and warrants for the benefit of Users that it is entitled to grant the licence to use LIVEXCollect provided for in these Terms and Conditions. </li>
                            </ol>
                        </li>
                        <li><strong>Ownership of Data</strong>
                            <ol><li>Company Level Data, together with Live Export Data and De-identified Company Level Data to the extent it incorporates that Exporter's Company Level Data, are owned by that Exporter. The Exporter is responsible for the accuracy, adequacy, suitability or completeness of its Company Level Data.</li>
                                <li>Each Exporter provides a permanent, irrevocable, non-exclusive, worldwide, transferable licence (and right of sublicense) for LiveCorp to reproduce, publish, adapt, communicate to the public and commercialise the Live Export Data. </li>
                                <li>Notwithstanding any other clause of these Terms and Conditions, LiveCorp does not represent or warrant that it will retain or back up Live Export Data, Company Level Data or De-identified Company Level Data for a specific period. To the extent that any User requires access to, or a copy of, any data within LIVEXCollect to which they have access and are entitled to retain, those Users are responsible for retaining a copy of that data outside of LIVEXCollect.</li>
                            </ol>
                        </li>
                        <li><strong>Changes to LIVEXCollect</strong>
                            <ol><li>LiveCorp may change or update LIVEXCollect in part or whole and its features and functionality at any time. You may be required to download and/or reinstall a new version of the LIVEXCollect Data Collection Tools or one or more of its components if changes and updates are made.</li>
                            </ol>
                        </li>
                        <li><strong>Security</strong>
                            <ol><li>Each User must take all reasonable steps to ensure that no other person uses its access to LIVEXCollect, including, without limitation, the LIVEXCollect Data Collection Tools, Dashboard or Databases or any other components thereof.</li>
                                <li>Each User is responsible for all use of LIVEXCollect made using that User's access to LIVEXCollect, regardless of whether that use was that of the User or another person, and whether or not such use of LIVEXCollect was with the User's knowledge or consent. Each User agrees that LiveCorp has no additional duties to make any enquiry as to the authority or identity of the person using LIVEXCollect by way of that User's access thereto.</li>  
                                <li> Each User must notify LiveCorp immediately if:
                                        <ol>  (a) they believe their access to LIVEXCollect or any component thereof (including any access code or password by which you access LIVEXCollect) has or may have been misused, or any device on which LIVEXCollect (or any component thereof) is installed or stored is lost or stolen; or</ol>
                                        <ol>  (b) they believe any access code or password by which they access LIVEXCollect (or a component thereof) has or may have become known to someone else.</ol>
                                    </li>
                                   
                            </ol>
                        </li>
                        <li><strong>Confidentiality</strong>
                            <ol>
                                    <li>Any data or other information which by its nature is confidential accessed via LIVEXCollect (Confidential Information) will be treated by Users as strictly confidential and will not be used or disclosed, either directly or indirectly, to any other person:
                                    <ol>  (a) for any purpose other than fulfilling the User's obligations or exercising the User's rights under these Terms and Conditions; or</ol>
                                    <ol>  (b) in any way which will, or is likely to, affect the registration of any Intellectual Property Rights associated with or in any part of the Confidential Information.</ol>
                                    <ol>This obligation of confidence survives the termination of these Terms and Conditions or the User's access to LIVEXCollect and will continue for as long as the information concerned retains the necessary quality of confidence.</ol>
                                </li>
                              
                                <li> The provisions of clause 12.1 do not apply if:
                                    <ol>  (a) the User has the written consent of LiveCorp to disclose the Confidential Information; or</ol>
                                    <ol>  (b) the User can demonstrate, to the reasonable satisfaction of LiveCorp, that the information was known to it before disclosure; or</ol>
                                    <ol>  (c) the Confidential Information is subsequently otherwise legally acquired by the User from a third party (unless the third party is breaching any obligation of confidence which that third party owed to the party which originally communicated the information); or</ol>
                                    <ol>  (d) the information is or comes into the public domain or is or becomes generally known in the industry otherwise than by breach of these Terms and Conditions; or</ol>
                                    <ol>  (e) it is necessary for the User to disclose the Confidential Information to its officers, directors and employees to enable those officers, directors and employees to perform the purpose for which the User obtained the Confidential Information in accordance with these Terms and Conditions and the User imposes undertakings of confidentiality on those officers, directors and employees to whom the Confidential Information has been disclosed; or</ol>
                                    <ol>  (b) the User can demonstrate, to the reasonable satisfaction of LiveCorp, that the information was known to it before disclosure; or</ol>
                                    <ol>  (f) it is necessary for the User to disclose the Confidential Information to those of its consultants, agents and advisors who are directly and necessarily concerned with the evaluation of the Confidential Information and who strictly need to know the Confidential Information to allow the proper use of LIVEXCollect in accordance with these Terms and Conditions, or to the extent necessary to enable the User to understand its rights and obligations under these Terms and Conditions, provided that the User imposes undertakings of confidentiality on those consultants, agents and advisors to whom the Confidential Information has been disclosed; or</ol>
                                    <ol>  (g) the Confidential Information is required to be disclosed to a third party by compulsion of law; or</ol>
                                    <ol>  (h) the Confidential Information is required by government instrumentalities.</ol>
                                </li>
                                
                                <li>Without limiting the generality of any other provision of these Terms and Conditions, and to secure the confidentiality of the Confidential Information, each User shall:
                                    <ol> (a) keep all Confidential Information accessed by the User (and all information generated by the User which is based upon that Confidential Information):
                                        <ul> (i) secure; and</ul>
                                        <ul> (ii) separated from all other documents and records;</ul>
                                    </ol>
                                    <ol> (b) make copies of the Confidential Information accessed by the User only so far as is required for the purposes of the User fulfilling its obligations or exercising its rights in accordance with these Terms and Conditions; and </ol>
                                    <ol> (c) not sell, offer for sale, advertise, invite offers for, commercialise, deal with or supply any part of the Confidential Information accessed by the User to any person.</ol>
                                </li>
                            </ol>
                        </li>

                        <li><strong>Privacy</strong>
                            <ol>
                                <li>Each User agrees to comply with all laws applicable to them in relation to the protection of personal information. Without limiting the foregoing, each User agrees:
                                    <ol>  (a) to collect, use or disclose personal information which is contributed to LIVEXCollect in compliance with the Privacy Act 1988 (Cth) (Privacy Act);</ol> 
                                    <ol>  (b) not to commit any act, omission or engage in any practice which is prohibited by the Privacy Act; and</ol> 
                                    <ol>  (c) not to do any act or engage in any practice which if done or engaged in by an agency, would be a breach of an Australian Privacy Principle.</ol> 
                                </li>
                                <li>In providing you access to LIVEXCollect, LiveCorp is bound by the Privacy Act and handles personal information you provide in accordance with the Privacy Act and LiveCorp’s Privacy Policy, which is available at https://livecorp.com.au/privacy-policy.</li>
                                <li> Individual Users will be provided with a privacy collection notice when first accessing LIVEXCollect. This privacy collection notice and the privacy policy of LiveCorp explain the way the personal information of individual Users may be collected, held, used and disclosed by LiveCorp.</li>
                            </ol>
                        </li>
                    
                        <li><strong>Warranties</strong>
                            <ol>
                                <li>While every effort is made to ensure that the information obtained from LIVEXCollect is free from error, no representation or warranty is made or given with respect to the accuracy, adequacy, suitability or completeness of any data accessed via LIVEXCollect, including any Live Export Data, Company Level Data or De-identified Company Level Data.</li>
                                <li> LiveCorp provides Users with access to LIVEXCollect and any data accessed through the use of LIVEXCollect on an ‘as is’ basis and use is at the User’s risk. To the maximum extent permitted by law, no representation or warranty is made or given by LiveCorp in respect of the availability, accuracy, currency, completeness, quality or reliability of LIVEXCollect or the data accessed through use of LIVEXCollect or that the data accessed through use of LIVEXCollect will be fit for any particular purpose or will not infringe any third party intellectual property rights.</li>
                                <li>Without limiting clause 14.2, LiveCorp does not guarantee:
                                    <ol>  (a) that LIVEXCollect or any third-party software or hardware will be free from any Harmful Code;</ol> 
                                    <ol>  (b) that LIVEXCollect will be available or available on all computer systems;</ol> 
                                    <ol>  (c) the time in which any Regulatory Data or Voluntary Data can be uploaded to LIVEXCollect;</ol> 
                                    <ol>  (d) that access to LIVEXCollect or any software or hardware will be uninterrupted; or</ol> 
                                    <ol>  (e) that it will be impossible to identify the De-identified Company Level Data of a particular Exporter.</ol> 
                                </li>
                                <li>Each User is responsible for taking appropriate precautions for the protection of its systems, software or hardware (or, where the User is a nominee, of the systems, software or hardware of the organisation that nominated them).</li>
                                <li>Each Exporter and Industry Organisation represents and warrants that it will not, nor will it suffer or permit its nominated Users or any other third party under its direction or control to,negligently or wilfully introduce any Harmful Code into LIVEXCollect or LiveCorp's systems, software or hardware. If any Exporter or Industry Organisation becomes aware that any Harmful Code has been introduced into LIVEXCollect or LiveCorp's systems, software or hardware, whether through a breach of this clause 14.5 or otherwise, they must promptly report that introduction to LiveCorp. If Harmful Code is introduced into LIVEXCollect or LiveCorp's systems, software or hardware in breach of this clause 14.5, the Exporter or Industry Organisation (as the case may be) will bear all reasonable and substantiated costs, expenses, liabilities and losses incurred by LiveCorp as a direct result of the Harmful Code, including the reasonable and substantiated cost of repair of any harm, damage or destruction caused by that Harmful Code.</li>                                
                            </ol>
                        </li>
                        <li><strong>Limitation of liability</strong>
                            <ol>
                                <li>Subject to any rights implied by law which cannot be excluded and these Terms and Conditions, LiveCorp is not liable to any User, whether in contract, tort (including negligence),statue or otherwise, for any losses, damages, liabilities, claims or expenses (including but not limited to legal costs and defence or settlement costs), direct or indirect, arising out of:
                                    <ol>  (a) the use of LIVEXCollect;</ol> 
                                    <ol>  (b) the collection, processing, access to, or reliance on, Live Export Data, Company Level Data or De-identified Company Level Data;</ol> 
                                    <ol>  (c) any variation, suspension or termination of use of LIVEXCollect;</ol> 
                                    <ol>  (d) any unauthorised use of LIVEXCollect;</ol> 
                                    <ol>  (e) any system or telecommunications failure which impacts your use of LIVEXCollect;</ol> 
                                    <ol>  (f) any fault, error or defect in the design of LIVEXCollect;</ol> 
                                    <ol>  (g) any fault or failure resulting in a loss of access to LIVEXCollect;</ol> 
                                    <ol>  (h) any event outside the reasonable control of LiveCorp;</ol> 
                                    <ol>  (i) any error or inaccuracy in any information or data accessed via LIVEXCollect;</ol> 
                                    <ol>  (j) the use of third party websites or third party software or hardware; or</ol> 
                                    <ol>  (k) third party data, unless the losses, damages, liabilities, claims or expenses arise from the fraud or wilful misconduct of LiveCorp.</ol> 
                                </li>
                                
                            </ol>
                        </li>
                        <li><strong>Governing Law and Jurisdiction</strong>
                            <ol>
                                These Terms and Conditions are governed by and subject to the laws of New South Wales. Each party submits to the non-exclusive jurisdiction of the courts of New South Wales, and the courts competent to determine appeals from those courts, with respect to any proceedings that may be brought at any time relating to these Terms and Conditions and waives any objection they may now or in the future have to the venue of any proceedings, and any claim they may now or in the future have that any proceedings have been brought in an inconvenient forum, if that venue falls within the foregoing.
                            </ol>
                        </li>
                        <li><strong>Relationship between the parties</strong>
                            <ol>
                                 A User's acceptance of these Terms and Conditions does not create any partnership, joint venture or agency relationship between the parties.
                            </ol>
                        </li>
                        <li><strong>Severability</strong>
                            <ol>
                                If any part of these Terms and Conditions is for any reason invalid or unenforceable, that part must be read down to the extent necessary to preserve its operation. If it cannot be read down, it must be severed.
                            </ol>
                        </li>
                       
                        <li><strong>Termination</strong>
                            <ol>
                                <li>Subject to the Terms and Conditions, each User's use of and access to LIVEXCollect may be terminated at any time by LiveCorp in its sole and absolute discretion:
                                    <ol>  (a) if LiveCorp decides to no longer continue to offer LIVEXCollect;</ol> 
                                    <ol>  (b) if the User ceases to be eligible to use LIVEXCollect under these Terms and Conditions;</ol> 
                                    <ol>  (c) if the User (or in the case of an Exporter or Industry Organisation, its nominated users or any other third party under its direction or control) breaches these Terms and Conditions or any requirements or conditions placed on the User's access by LiveCorp in accordance with these Terms and Conditions; or</ol> 
                                    <ol>  (d) where necessary to protect the security of LIVEXCollect.</ol> 
                                    All restrictions on use, licences granted by Users and all disclaimers and limitations of liability by LiveCorp will survive termination of the User's access to LIVEXCollect.
                                </li>
                                
                            </ol>
                        </li>
                    </ol>
                    


              
            
            </Row>

            <Row>
                <Col xs={4}></Col>
                <Col xs={4}>
                    <div className='d-grid mt-3 mb-5'>
                        <Button variant="primary" size='lg' onClick={onAcceptTermsAndConditions} >I Agree</Button>
                    </div>
                </Col>
                <Col xs={4}></Col>
            </Row>
            </Container>
        </LoggedOutContainer>
       
    )
};

export default TermsAndConditions;