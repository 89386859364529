import { DeckRecord } from "./DeckRecord";
import { Species } from "./Species";
import { ConsignmentDay } from "./ConsignmentDay";
import { ConsignmentDeck } from "./ConsignmentDeck";
import { ManurePadDepthType } from "./ManurePadDepthType";
import de from "date-fns/esm/locale/de/index.js";

export class DetailedDeckRecord {
    DeckRecord;
    Species;
    ConsignmentDay;
    ConsignmentDeck;
    ManurePadDepthType;

    constructor({ deckRecord, species, consignmentDay, consignmentDeck, manurePadDepthType }) {
        this.DeckRecord = new DeckRecord(deckRecord);
        this.Species = new Species(species);
        this.ConsignmentDay = new ConsignmentDay(consignmentDay);
        this.ConsignmentDeck = new ConsignmentDeck(consignmentDeck);
        this.ManurePadDepthType = new ManurePadDepthType(manurePadDepthType)
    }

    static convertDetailedDeckRecord(detailedDeckRecord) {
        return {
            deckRecord: detailedDeckRecord.DeckRecord, species: detailedDeckRecord.Species, consignmentDay: detailedDeckRecord.ConsignmentDay,
            consignmentDeck: detailedDeckRecord.ConsignmentDeck, manurePadDepthType: detailedDeckRecord.ManurePadDepthType
        };
    }
}