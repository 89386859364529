import _Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
let Swal = withReactContent(_Swal);

Swal.Toast = (title, icon = "success") => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });

  Toast.fire({
    icon: icon,
    title: title,
  });
};

Swal.Warning = (message, timeout = 3000) => {
  Swal.fire({
    title: <p>{message}</p>,
    icon: "warning",
    timer: timeout,
  });
};

Swal.ConfirmationDialog = (message, saveButtonText, onSave) => {
  Swal.fire({
    title: message,
    showCancelButton: true,
    confirmButtonText: saveButtonText,
    confirmButtonColor: "#FF0000",  
    customClass: {
      container: '...',
      popup: '...',
      header: '...',
      title: '...',
      closeButton: '...',
      icon: '...',
      image: '...',
      htmlContainer: '...',
      input: '...',
      inputLabel: '...',
      validationMessage: '...',
      actions: '...',
      confirmButton: 'btn btn-primary',
      denyButton: '...',
      cancelButton: 'btn btn-secondary',
      loader: '...',
      footer: '....',
      timerProgressBar: '....',
    }
  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      onSave();
    }
  })
}

export default Swal;
