import React from "react";
import { useContext, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import SheepPantingDeck from "./SheepPantingDeck";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import ReadWriteFormRadio from "../ReadWriteElements/ReadWriteFormRadio";

const SheepPanting = () => {
    const { data, updateSheepHasPanting } = useContext(DeckRecordOtherContext);
    const [triggerClearMissingAffectedDecks, setTriggerClearMissingAffectedDecks] = useState(-1);
    const [allSheepDecksEmpty, setAllSheepDecksEmpty] = useState(false);

    const onTriggerClearMissingAffectedDecks = () => {
        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1)
    }

    useEffect(() => {
        let lAllEmpty = [...data.sheepDecks].every(sd => sd.DeckRecord.isEmpty === true);
        setAllSheepDecksEmpty(lAllEmpty);

    }, [data.sheepDecks]);
    return (
        <div>
            <div className="pb-1 field-label">Are any sheep panting at a score of 3 or above?</div>
            <Form>
                <ReadWriteFormRadio id='rdoSheepPanting_No' type={`radio`} label={`No`} name="rdoSheepHotSpots" checked={!data.hasSheepPanting}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasPanting(false);
                    }}
                    disabled={allSheepDecksEmpty} 
                />
                <ReadWriteFormRadio id='rdoSheepPanting_Yes' type={`radio`} label={`Yes`} name="rdoSheepHotSpots" checked={data.hasSheepPanting}
                    onChange={() => {
                        setTriggerClearMissingAffectedDecks(triggerClearMissingAffectedDecks + 1);
                        updateSheepHasPanting(true);
                    }}
                    disabled={allSheepDecksEmpty}
                />
            </Form>
            {
                data.hasSheepPanting && !allSheepDecksEmpty ?
                    <div>
                        <Row className="pt-2">
                            <Col xs={4} className="dr-column-heading">Deck Affected?</Col>
                            <Col xs={8}>
                                <Row>
                                    <Col xs={12} className="dr-column-heading">
                                        % of animals per deck
                                    </Col>
                                    <Col xs={6} className="dr-column-heading">
                                        Score 4+
                                    </Col>
                                    <Col xs={6} className="dr-column-heading">
                                        Score 3+
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {
                            data.sheepDecks.map((rec, index) => (
                                <SheepPantingDeck key={`sheepPanting${index}`} rec={rec} triggerClearMissingAffectedDecks={triggerClearMissingAffectedDecks} onTriggerClearMissingAffectedDecks={onTriggerClearMissingAffectedDecks} />
                            ))
                        }
                    </div>
                    : null
            }
        </div>
    )
};

export default SheepPanting;