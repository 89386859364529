import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Stack, InputGroup } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { IsNotNullAndGreaterThanZero } from "../../utils/StringUtils";
import { DeckRecordOtherContext } from "../../pages/DeckRecords/Edit_Deck_Record_Other";
import { parseNumericInput, PercentageValidationRules } from "../../utils/InputValidator";
import ReadWriteFormCheck from "../ReadWriteElements/ReadWriteFormCheck";
import ReadWriteInputGroup from "../ReadWriteElements/ReadWriteInputGroup";

const CattlePantingDeck = ({ rec, triggerClearMissingAffectedDecks, onTriggerClearMissingAffectedDecks }) => {
    const percentageValidationRules = new PercentageValidationRules(false, false);

    const { updateCattlePantingDeck, triggerShowInvalid, data } = useContext(DeckRecordOtherContext);
    const [isMissingPantingPercent, setIsMissingPantingPercent] = useState(false);
    const [isInvalidCombinedPercent, setIsInvalidCombinedPercent] = useState(false);
    const [pantingError, setPantingError] = useState("");
    const [isInvalidPanting, setIsInvalidPanting] = useState(false);

    const updateData = (isAffected, pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent) => {
        if (rec.DeckRecord.isEmpty === false) {
            let lIsValid = isValid(isAffected, pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent);
            updateCattlePantingDeck(rec.DeckRecord.consignmentDeckID, isAffected, pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent, lIsValid);
        }
    }

    // If affected, panting score three percent or panting score four percent must have a value and their combined value can not be more than 100
    const isValid = (isAffected, pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent) => {
        let lIsValid = false;
        if (isAffected === true) {
            if (isMissingInputs(pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent) === true) {
                lIsValid = false;
            }
            else { // has a value greater than 0 for at least one input
                if (isValidCombinedPercentage(pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent) === true) {
                    lIsValid = true;
                }
                else {
                    lIsValid = false;
                }
            }
        }
        return lIsValid;
    }

    const isMissingInputs = (pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent) => {
        if (IsNotNullAndGreaterThanZero(pantingScoreTwoPercent) === false && IsNotNullAndGreaterThanZero(pantingScoreThreePercent) === false && IsNotNullAndGreaterThanZero(pantingScoreFourPercent) === false) {
            return true;
        }
        else {
            return false;
        }
    }

    const isValidCombinedPercentage = (valueOne, valueTwo, valueThree) => {
        if (Number(valueOne) + Number(valueTwo) + Number(valueThree) <= 100) {
            return true;
        }
        else {
            return false;
        }
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerShowInvalid >= 0) {
            setIsInvalidPanting(!data.cattlePantingValid);

            if (isMissingInputs(rec.DeckRecord.pantingScoreTwoPercent, rec.DeckRecord.pantingScoreThreePercent, rec.DeckRecord.pantingScoreFourPercent) === true) {
                let lMissingError = "A percentage greater than 0 is requried for panting score 4-4.5, 3-3.5 or 2-2.5, or uncheck this deck.";
                setPantingError(lMissingError);
                setIsMissingPantingPercent(true);
            }
            else if (isValidCombinedPercentage(rec.DeckRecord.pantingScoreTwoPercent, rec.DeckRecord.pantingScoreThreePercent, rec.DeckRecord.pantingScoreFourPercent) === false) {
                let lCombinedPercentageError = "The combined percentage can not be greather than 100.";
                setIsInvalidCombinedPercent(true);
                setPantingError(lCombinedPercentageError);
            }
            else { // No error
                setIsMissingPantingPercent(false);
                setIsInvalidCombinedPercent(false);
                setPantingError("");
            }
        }
    }, [triggerShowInvalid]);

    useEffect(() => {
        // we do not need to run this on first load
        if (triggerClearMissingAffectedDecks >= 0) {
            setIsInvalidPanting(false);
        }
    }, [triggerClearMissingAffectedDecks]);

    return (
        <Row key={`cattlePantingDeck_${rec.DeckRecord.consignmentDeckID}`} className="pb-3 pt-3 gray-border-top">
            <Col xs={3}>
                <Stack direction="horizontal" gap={4}>
                    <Form>
                        <Form.Group>
                            <ReadWriteFormCheck labelstacked={false} id={`cattlePantingCheck_${rec.DeckRecord.consignmentDeckID}`} checked={rec.hasCattlePanting}
                                onChange={(e) => {
                                    onTriggerClearMissingAffectedDecks();
                                    setIsMissingPantingPercent(false);
                                    setIsInvalidCombinedPercent(false);
                                    setIsInvalidPanting(false);
                                    updateData(e.target.checked, rec.DeckRecord.pantingScoreTwoPercent, rec.DeckRecord.pantingScoreThreePercent, rec.DeckRecord.pantingScoreFourPercent);
                                }}
                                isInvalid={isInvalidPanting && !rec.DeckRecord.isEmpty}
                                label={rec.ConsignmentDeck.deckName}
                                disabled={rec.DeckRecord.isEmpty}
                            />
                        </Form.Group>
                        {
                            isInvalidPanting && !rec.DeckRecord.isEmpty ? (
                                <div className="validation-error-tooltip">
                                    <Tooltip
                                        anchorSelect={`#cattlePantingCheck_${rec.DeckRecord.consignmentDeckID}`}
                                        place="top"
                                        variant="error"
                                        content="One ore more decks must be selected, or select no."
                                    />
                                </div>
                            ) : null
                        }
                    </Form>
                </Stack>
            </Col>
            <Col xs={3}>
                {
                    rec.hasCattlePanting && !rec.DeckRecord.isEmpty ?
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                <Form.Control
                                    id={`cattlePantingScoreFourPercent_${rec.DeckRecord.consignmentDeckID}`}
                                    value={rec.DeckRecord.pantingScoreFourPercent}
                                    onChange={(e) => {
                                        setIsMissingPantingPercent(false);
                                        setIsInvalidCombinedPercent(false);
                                        parseNumericInput(e, percentageValidationRules);
                                        updateData(rec.hasCattlePanting, rec.DeckRecord.pantingScoreTwoPercent, rec.DeckRecord.pantingScoreThreePercent, e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty}
                                    disabled={rec.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#cattlePantingScoreFourPercent_${rec.DeckRecord.consignmentDeckID}`}
                                                place="top"
                                                variant="error"
                                                content={pantingError}
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                        : null
                }
            </Col>
            <Col xs={3}>
                {
                    rec.hasCattlePanting && !rec.DeckRecord.isEmpty ?
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                <Form.Control
                                    id={`cattlePantingScoreThreePercent_${rec.DeckRecord.consignmentDeckID}`}
                                    value={rec.DeckRecord.pantingScoreThreePercent}
                                    onChange={(e) => {
                                        setIsMissingPantingPercent(false);
                                        setIsInvalidCombinedPercent(false);
                                        parseNumericInput(e, percentageValidationRules);
                                        updateData(rec.hasCattlePanting, rec.DeckRecord.pantingScoreTwoPercent, e.target.value, rec.DeckRecord.pantingScoreFourPercent);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty}
                                    disabled={rec.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#cattlePantingScoreThreePercent_${rec.DeckRecord.consignmentDeckID}`}
                                                place="top"
                                                variant="error"
                                                content={pantingError}
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                        : null
                }
            </Col>
            <Col xs={3}>
                {
                    rec.hasCattlePanting && !rec.DeckRecord.isEmpty ?
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='text-13-400 secondary-grey'>
                                <Form.Control
                                    id={`cattlePantingScoreTwoPercent_${rec.DeckRecord.consignmentDeckID}`}
                                    value={rec.DeckRecord.pantingScoreTwoPercent}
                                    onChange={(e) => {
                                        setIsMissingPantingPercent(false);
                                        setIsInvalidCombinedPercent(false);
                                        parseNumericInput(e, percentageValidationRules);
                                        updateData(rec.hasCattlePanting, e.target.value, rec.DeckRecord.pantingScoreThreePercent, rec.DeckRecord.pantingScoreFourPercent);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty}
                                    disabled={rec.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isMissingPantingPercent || isInvalidCombinedPercent) && !rec.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#cattlePantingScoreTwoPercent_${rec.DeckRecord.consignmentDeckID}`}
                                                place="top"
                                                variant="error"
                                                content={pantingError}
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                        : null
                }
            </Col>
        </Row>
    )
};

export default CattlePantingDeck;