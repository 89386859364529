import '../css/historicalconsignments.css';
import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Stack, FormControl, FormSelect, Button, Spinner } from "react-bootstrap";
import { format } from 'date-fns';
import LoggedInContainer from "../layout/LoggedInContainer";
import { getAllActiveConsignmentSummaries } from '../api/apiAccess';
import { faUsers, faXmarkCircle, faCheckCircle, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { textIncludesSearchTerm } from '../utils/SearchUtils';
import { getDifferenceInWholeHours, determineDailyReportingStatus } from '../utils/StatusUtils';
import { UserContext } from '..';
import { UserType } from '../api/enums/EnumUserType';

const LiveCorpAndRegulatoryDashboard = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);

    const [consignments, setConsignments] = useState([]);
    const [filteredConsignments, setFilteredConsignments] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedVesselFilterOption, setSelectedVesselFilterOption] = useState("all");
    const [selectedExporterFilterOption, setSelectedExporterFilterOption] = useState("all");
    const [selectedDeparturePortFilterOption, setSelectedDeparturePortFilterOption] = useState("all");

    const [vesselOptions, setVesselOptions] = useState([]);
    const [exporterOptions, setExporterOptions] = useState([]);
    const [departurePortOptions, setDeparturePortOptions] = useState([]);

    const goToCompletedConsignments = () => {
        navigate("/historicalconsignments")
    }

    useEffect(() => {
        const PopulatePage = async () => {
            setIsLoading(true);
            let results = await getAllActiveConsignmentSummaries();

            if (results?.status === 200) {
                let lActiveConsignments = results.data;

                let lVesselOptions = [];
                let lExporterOptions = [];
                let lPortOptions = [];

                // Determine if today's daily reporting has been completed
                lActiveConsignments.forEach(con => {
                    let lHoursSinceLastReport = getDifferenceInWholeHours(new Date(Date.now()), new Date(con.lastSync));
                    con.lastReportRecievedMoreThan48HoursAgo = lHoursSinceLastReport > 48;
                    
                    if (!lVesselOptions.find(vo => vo.ID === con.vesselID)) {
                        lVesselOptions.push({ ID: con.vesselID, Name: con.vesselName });
                    }

                    if (!lExporterOptions.find(eo => eo.ID === con.exporterID)) {
                        lExporterOptions.push({ ID: con.exporterID, Name: con.exporterName });
                    }

                    if (!lPortOptions.find(po => po.ID === con.portID)) {
                        lPortOptions.push({ ID: con.portID, Name: con.portName });
                    }
                });

                setConsignments(lActiveConsignments);
                setVesselOptions(lVesselOptions);
                setExporterOptions(lExporterOptions);
                setDeparturePortOptions(lPortOptions);

                setLoadingError(false);
            }
            else {
                setLoadingError(true);
            }

            setIsLoading(false);
        }

        PopulatePage();
    }, [])

    // Filter consignments
    useEffect(() => {
        let lFilteredConsignments = [...consignments].filter(con =>
            (
                selectedVesselFilterOption === "all"
                || con.vesselID === Number(selectedVesselFilterOption)
            )
            && (
                selectedExporterFilterOption === "all"
                || con.exporterID === Number(selectedExporterFilterOption)
            )
            && (
                selectedDeparturePortFilterOption === "all"
                || con.portID === Number(selectedDeparturePortFilterOption)
            )
            && (
                searchTerm === ""
                || textIncludesSearchTerm(con.vesselName, searchTerm)
                || textIncludesSearchTerm(con.exporterName, searchTerm)
                || textIncludesSearchTerm(con.lncNumber, searchTerm)
                || textIncludesSearchTerm(con.portName, searchTerm)
            )
        );

        lFilteredConsignments.sort((a, b) => {
            // Convert datetime strings to Date objects for comparison
            let dateA = new Date(b.vesselDepartedDate);
            let dateB = new Date(a.vesselDepartedDate);

            // Compare dates
            if (dateA < dateB) {
                return -1;
            }
            if (dateA > dateB) {
                return 1;
            }
            return 0;
        })

        setFilteredConsignments(lFilteredConsignments);
    }, [consignments, searchTerm, selectedVesselFilterOption, selectedExporterFilterOption, selectedDeparturePortFilterOption])

    const goToConsignmentDashboard = (consignmentID) => {
        navigate(`/consignmentdashboard/${consignmentID}`);
    }

    const goToREDashboard = () => {
        if (userCtx.user?.userTypeID === UserType.LIVE_CORP) {
            navigate(`/re/dashboard`);
        } else if (userCtx.user?.userTypeID === UserType.REGULATORY) {
            navigate(`/re/historicalconsignments`);
        }
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0">
                    <Col>
                        <Container>
                            <Row style={{ marginTop: "65px" }}>
                                <Col xs={2}></Col>
                                <Col>
                                    <div className="text-center heading-text" style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>View Active Consignments</div>
                                </Col>
                                <Col xs={2} className='my-auto'>
                                    <Button className="w-100" variant="back" onClick={() => goToCompletedConsignments()}>
                                        View Past Consignments
                                    </Button>
                                    <Button className="mt-2 w-100" variant="back" onClick={() => goToREDashboard()}>
                                        View RE Dashboard
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className='mx-0'>
                    <Container className="form-container-mid py-4 px-5">
                        {
                            isLoading === true ?
                                <Row>
                                    <Col>
                                        <div className="text-center pt-5">
                                            <Spinner className="mx-auto" />
                                            <div className="mx-auto">Loading data...</div>
                                        </div>
                                    </Col>
                                </Row>
                                :
                                loadingError === true ?
                                    <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>Unable to load active consignments.</div>
                                    :
                                    consignments && consignments.length > 0 ?
                                        <>
                                            <Row className='bg-grey p-3 mb-4 darkblue field-label'>
                                                <Col xs={5}>
                                                    <Stack direction="vertical" gap={1}>
                                                        <span className='field-label'>Search</span>
                                                        <FormControl
                                                            placeholder="Type a word to search, ie vessel, LNC..."
                                                            onChange={(e) => {
                                                                let text = e.target.value;
                                                                setSearchTerm(text);
                                                            }}
                                                        />
                                                    </Stack>
                                                </Col>
                                                <Col xs={7}>
                                                    <Stack direction="vertical" gap={1}>
                                                        <span className='field-label'>Filter consignments by</span>
                                                        <Stack direction="horizontal" gap={2}>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedVesselFilterOption(e.target.value);
                                                                }}
                                                                value={selectedVesselFilterOption}
                                                            >
                                                                <option value="all">All Vessels</option>
                                                                {
                                                                    vesselOptions && vesselOptions.length > 0 ?
                                                                        vesselOptions.map(vo => {
                                                                            return (<option key={`vesselOption_${vo.ID}`} value={vo.ID}>{vo.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedExporterFilterOption(e.target.value);
                                                                }}
                                                                value={selectedExporterFilterOption}
                                                            >
                                                                <option value="all">All Exporters</option>
                                                                {
                                                                    exporterOptions && exporterOptions.length > 0 ?
                                                                        exporterOptions.map(eo => {
                                                                            return (<option key={`exporterOption_${eo.ID}`} value={eo.ID}>{eo.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                            <FormSelect
                                                                onChange={(e) => {
                                                                    setSelectedDeparturePortFilterOption(e.target.value);
                                                                }}
                                                                value={selectedDeparturePortFilterOption}
                                                            >
                                                                <option value="all">All Departure Ports</option>
                                                                {
                                                                    departurePortOptions && departurePortOptions.length > 0 ?
                                                                        departurePortOptions.map(dp => {
                                                                            return (<option key={`portOption_${dp.ID}`} value={dp.ID}>{dp.Name}</option>)
                                                                        })
                                                                        : null
                                                                }
                                                            </FormSelect>
                                                        </Stack>
                                                    </Stack>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <table className='consignmentsTable'>
                                                        <thead>
                                                            <tr>
                                                                <th><div>Departure</div></th>
                                                                <th><div>Voyage Day</div></th>
                                                                <th><div>Vessel</div></th>
                                                                <th><div>Exporter</div></th>
                                                                <th><div>LNC</div></th>
                                                                <th><div>Departure Port</div></th>
                                                                <th><div>Report Last Submitted</div></th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                filteredConsignments && filteredConsignments.length > 0 ?
                                                                    filteredConsignments.map((con, i) => {
                                                                        return (
                                                                            <tr key={`filtered_consignment_${i}`}>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.vesselDepartedDate ? format(new Date(con.vesselDepartedDate), "dd/MM/yyyy HH:mm") : "-"}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.consignmentDay ? con.consignmentDay : "-"}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.vesselName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.exporterName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Stack direction='horizontal' gap={2}>
                                                                                            <div>{`LNC-${con.lncNumber}`}</div>
                                                                                            {
                                                                                                con.isMultiConsignment === true ? (
                                                                                                    <span className="me-2">
                                                                                                        <div className="dashboard-tooltip">
                                                                                                            <FontAwesomeIcon icon={faUsers} />
                                                                                                            <div className="dashboard-tooltiptext p-2">This vessel is carrying multiple consignments.</div>
                                                                                                        </div>
                                                                                                    </span>
                                                                                                )
                                                                                                    : null
                                                                                            }
                                                                                        </Stack>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        {con.portName}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div style={{ color: con.lastReportRecievedMoreThan48HoursAgo ? "#de5656" : null }}>
                                                                                        {con.lastSync ? format(new Date(con.lastSync), "dd/MM/yyyy HH:mm") : "-"}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <div>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                goToConsignmentDashboard(con.consignmentID);
                                                                                            }}
                                                                                        >
                                                                                            View
                                                                                        </Button>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    <tr style={{ backgroundColor: `#FFFFFF` }}>
                                                                        <td colSpan={8}>
                                                                            <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>No active consignments match the specified filters.</div>
                                                                        </td>
                                                                    </tr>
                                                            }
                                                        </tbody>
                                                    </table>
                                                </Col>
                                            </Row>
                                        </>
                                        :
                                        <div className='text-center' style={{ marginTop: "30px", fontStyle: "italic" }}>There are currently no active consignments.</div>
                        }
                    </Container>
                </Row>
            </Container>
        </LoggedInContainer>
    )
}

export default LiveCorpAndRegulatoryDashboard;