import React, {useState, useEffect} from "react";
import { Container, Stack, Row, Col } from "react-bootstrap";
import { getEditHistoryForConsignment, getEditHistoryForREConsignment } from "../../api/apiAccess";
import { format } from "date-fns";


const ConsignmentEditHistory = ({consignmentID, isMobile, isREConsignment = false}) => {
    const [editHistory, setEditHistory] = useState([]);
    
    useEffect(() => {
        const fetchEditHistory = async() => {
            let results = isREConsignment === false ? await getEditHistoryForConsignment(consignmentID) : await getEditHistoryForREConsignment(consignmentID);
            console.log("fetchEditHistory",results)
            if (results?.status === 200) {
                setEditHistory(results.data);
            }
        }
        fetchEditHistory();
    }, [consignmentID]);

    const formatAMPM = (date) => {
        let strDate = format(date, "dd/MM/yyyy");
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return `${strDate} ${strTime}`;
      }
      
    return (
        <Container className={`${isMobile ? "px-0" : "ps-4 pe-0"}`}>
            {
                !editHistory || editHistory.length === 0 ? (
                    <Row>
                        <Col xs={12} className="text-center">
                            <i>This consignment has not been edited.</i>
                        </Col>
                    </Row>
                ) : null
            }
            {
                !isMobile && editHistory && editHistory.length > 0 ? (
                    <>
                        <Row className="dashboard-consignment-header-row" style={{borderRadius: '4px 4px 0 0'}}>
                            <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue">Date</Col>
                            <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start">Exporter user</Col>
                            <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start">Page edited</Col>
                            <Col xs={2} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start">Selected reason for edit</Col>
                            <Col xs={4} className="dashboard-consignment-header-cell field-label darkblue dashboard-consignment-header-cell-border-start">Detail provided</Col>
                        </Row>
                        
                        {
                            
                            editHistory.map((edit, index) => {
                                let bottomBorderRadius = "0";
                                if (index === editHistory.length - 1) {
                                    bottomBorderRadius = "0 0 4px 4px";
                                }
                                return(
                                    <Row key={`edit_history_row_${index}`} className="dashboard-consignment-cell-row" style={{borderRadius: bottomBorderRadius}}>
                                        <Col xs={2} className="py-2" style={{display: 'flex', alignItems: 'center'}}>{formatAMPM(new Date(edit.date))}</Col>
                                        <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{display: 'flex', alignItems: 'center'}}>{edit.userFullName}</Col>
                                        <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{display: 'flex', alignItems: 'center'}}>{edit.editedPageName}</Col>
                                        <Col xs={2} className="dashboard-consignment-header-cell-border-start py-2" style={{display: 'flex', alignItems: 'center'}}>{edit.pageEditReason}</Col>
                                        <Col xs={4} className="dashboard-consignment-header-cell-border-start py-2" style={{display: 'flex', alignItems: 'center'}}>{edit.details}</Col>
                                    </Row>
                                )
                            })
                            
                        }

                    </>
                    ) : null
            }

            {
                isMobile && editHistory && editHistory.length > 0 ? 
                (
                    <>                       
                        {   
                            editHistory.map((edit, index) => {
                                return(
                                    <div key={`edit_history_row_${index}`} className="m-0 p-0 w-100 consignment-dashboard-edit-history-divider-borders">
                                        <Row className="px-0 py-2">
                                            <Col xs={6}>
                                                <Stack direciton="vertical">
                                                    <div className="field-label">Date</div>
                                                    {formatAMPM(new Date(edit.date))}
                                                </Stack>
                                                
                                            </Col>
                                            <Col xs={6}>
                                                <Stack direciton="vertical">
                                                    <div className="field-label">Exporter user</div>
                                                    {edit.userFullName}
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row className="py-2">
                                            <Col xs={6}>
                                                <Stack direciton="vertical">
                                                    <div className="field-label">Page edited</div>
                                                    {edit.editedPageName}
                                                </Stack>
                                            </Col>
                                            <Col xs={6}>
                                                <Stack direciton="vertical">
                                                    <div className="field-label">Selected reason for edit</div>
                                                    {edit.pageEditReason}
                                                </Stack>
                                            </Col>
                                        </Row>
                                        <Row className="py-2">
                                            <Col xs={12}>
                                                <Stack direciton="vertical">
                                                    <div className="field-label">Detail provided</div>
                                                    {edit.details}
                                                </Stack>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                        }
                    </>
                ) : null
            }
            
        </Container>
    )
}

export default ConsignmentEditHistory;