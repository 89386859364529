export class AAV {
    id = -1;
    firstName = "";
    lastName = "";
    accreditationNumber = "";

    key;
    isValid = true;

    constructor(key = null) {
        this.key = key;
    }
}