import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import DateTimePicker from "../../components/DateTimePicker";
import { Container, Row, Col, Button, Stack, FormControl, FormSelect, Spinner } from "react-bootstrap";
import { getAllHistoricalConsignmentsVisibleForRegisteredEstablishmentExporterID, getAllREHistoricalConsignmentsVisible, getREConsignmentsIDsMatchingSearch } from "../../api/apiAccess";
import { UserContext } from "../..";
import { SimpleHistoricalREConsignment } from "../../classes/RE/SimpleHistoricalREConsignment";
import { UserType } from "../../api/enums/EnumUserType";


let searchSequence = 0;

const HistoricalREConsignmentsDashboard = () => {
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [historicalREConsignments, setHistoricalREConsignments] = useState([]);
    const [visibleHistoricalREConsignments, setVisibleHistoricalREConsignments] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [isCustomDateFiltering, setIsCustomDateFiltering] = useState(false);
    const [filteredHistoricalREConsignments, setFilteredHistoricalREConsignments] = useState([]);
    const [searchedHistoricalREConsignments, setSearchedHistoricalREConsignments] = useState([]);
    const [selectedTimePeriodFilterOption, setSelectedTimePeriodFilterOption] = useState("all")
    const [selectedPremisesFilterOption, setSelectedPremisesFilterOption] = useState("all");
    const [selectedCustomTimePeriodStartDate, setSelectedCustomTimePeriodStartDate] = useState(new Date());
    const [selectedCustomTimePeriodEndDate, setSelectedCustomTimePeriodEndDate] = useState(new Date());
    const [earliestAnimalDepartureDate, setEarliestAnimalDepartureDate] = useState(new Date());
    const [premisesOptions, setPremisesOptions] = useState([]);
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        const fetchAllHistoricalConsignments = async () => {
            let results;
            
            if (userCtx.user?.userTypeID === UserType.LIVE_CORP || userCtx.user?.userTypeID === UserType.REGULATORY) {
                results = await getAllREHistoricalConsignmentsVisible();
            } else {
                results = await getAllHistoricalConsignmentsVisibleForRegisteredEstablishmentExporterID(userCtx.associatedExporter.exporterID);
            }

            if (results?.status === 200) {
                let consignmentResults = results.data.map(r => new SimpleHistoricalREConsignment(r));
                let orderedResults = consignmentResults.sort((a, b) => new Date(b.LastAnimalDepartureDate) - new Date(a.LastAnimalDepartureDate));
                setHistoricalREConsignments(orderedResults);
                setFilteredHistoricalREConsignments(orderedResults);
                setSearchedHistoricalREConsignments(orderedResults);
                setPremisesOptions([... new Set(consignmentResults.map(cr => cr.PremisesName))]);
            }
        }


        fetchAllHistoricalConsignments();

    }, [userCtx.associatedExporter])

    useEffect(() => {
        let allDates = historicalREConsignments?.map(hc => new Date(hc.LastAnimalDepartureDate));
        if (allDates.length === 0) return;
        let orderedDates = allDates.sort((a, b) => b - a);
        if (orderedDates[0] && orderedDates[0] instanceof Date) {
            setEarliestAnimalDepartureDate(orderedDates[0]);
        }
    }, [historicalREConsignments])

    useEffect(() => {
        //let visible = searchedHistoricalREConsignments.filter(shc => filteredHistoricalREConsignments.map(fhc => fhc.consignmentID).includes(shc.consignmentID));
        let visible = filteredHistoricalREConsignments.filter(fhc => searchedHistoricalREConsignments.map(shc => shc.RegisteredEstablishmentConsignmentID).includes(fhc.RegisteredEstablishmentConsignmentID));
        setVisibleHistoricalREConsignments(visible);
    }, [filteredHistoricalREConsignments, searchedHistoricalREConsignments])

    useEffect(() => {
        searchSequence++;
        if (searchTerm.length > 2) {
            searchConsignments(searchTerm);
        } else {
            clearSearch();
        }
    }, [searchTerm])

    useEffect(() => {
        filterConsignments();
    }, [selectedPremisesFilterOption, selectedCustomTimePeriodEndDate, selectedCustomTimePeriodStartDate])

    useEffect(() => {
        if (selectedTimePeriodFilterOption === "all") {
            let startDate = earliestAnimalDepartureDate;
            let endDate = new Date();
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }

        if (selectedTimePeriodFilterOption === "thisyear") {
            // set the start period to this year and end period to now
            let startDate = new Date(new Date().getFullYear(), 0, 1, 0, 0, 0);
            let endDate = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59);
            debugger
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }

        if (selectedTimePeriodFilterOption === "lastyear") {
            // set the start period to 1/1 last year and the end period to 31/12
            let startDate = new Date(new Date().getFullYear() - 1, 0, 1, 0, 0, 0);
            let endDate = new Date(new Date().getFullYear() - 1, 11, 31, 23, 59, 59);
            setSelectedCustomTimePeriodStartDate(startDate)
            setSelectedCustomTimePeriodEndDate(endDate);
        }
    }, [selectedTimePeriodFilterOption])

    useEffect(() => {
        filterConsignments();
    }, [selectedCustomTimePeriodStartDate, selectedCustomTimePeriodEndDate])

    const searchConsignments = async (aSearchTerm) => {
        setIsSearching(true);
        let results = await getREConsignmentsIDsMatchingSearch(aSearchTerm, userCtx.associatedExporter.exporterID, searchSequence);
        if (results.status === 200) {
            if (results.data.searchSequence === searchSequence) {
                let searchResults = filteredHistoricalREConsignments.filter(c => results.data.relevantConsignmentIDs.includes(c.RegisteredEstablishmentConsignmentID));
                setSearchedHistoricalREConsignments(searchResults);
                setIsSearching(false);
            }
        } else {
            setIsSearching(false);
        }
    }

    const clearSearch = () => {
        setIsSearching(false);
        setSearchedHistoricalREConsignments(filteredHistoricalREConsignments);
    }

    const filterConsignments = () => {
        let filteredResults = [...historicalREConsignments].filter(c => {
            return (
                (selectedPremisesFilterOption === "all" || c.PremisesName === selectedPremisesFilterOption) &&
                (selectedTimePeriodFilterOption === "all" || (new Date(c.LastAnimalDepartureDate) >= selectedCustomTimePeriodStartDate && new Date(c.LastAnimalDepartureDate) <= selectedCustomTimePeriodEndDate))
            )
        }

        );
        setFilteredHistoricalREConsignments(filteredResults);

    }

    const clearConsignmentFilter = () => {
        setIsCustomDateFiltering(false);
        setSelectedPremisesFilterOption("all");
        setSelectedTimePeriodFilterOption("all");
    }

    const goToViewHistoricalConsignment = (consignmentID) => {
        navigate(`/re/consignmentdashboard/${consignmentID}`);
    }

    const goToMainDashboard = () => {
        navigate("/dashboard");
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel">
                    <Col>
                        <Container>
                            <Row style={{ marginTop: "45px" }}>
                                <Col xs={2}></Col>
                                <Col>
                                    <div className={`text-center heading-text mb-2 ${userCtx.user?.userTypeID === UserType.REGULATORY ? "mt-4" : "mt-5"}`} style={{ color: "#FFF", lineHeight: "53px", fontSize: "35px" }}>View {userCtx.user?.userTypeID !== UserType.INDUSTRY ? "RE " : ""}Consignments</div>
                                </Col>
                                <Col xs={2} className='my-auto'>
                                    {
                                        userCtx.user?.userTypeID !== UserType.INDUSTRY ? (
                                            <Button className="w-100 mt-3" variant="back" onClick={() => goToMainDashboard()}>
                                                View Active Consignments
                                            </Button>
                                        ) : null
                                    }
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid">
                        <Row>
                            <Col xs={5}>
                                <Stack direction="vertical">
                                    <span className='field-label'>Search</span>
                                    <FormControl
                                        placeholder="Type a word to search, ie tag number, issue..."
                                        onChange={(e) => {
                                            let text = e.target.value;
                                            setSearchTerm(text);
                                        }}
                                    />
                                </Stack>
                            </Col>
                            <Col xs={7}>
                                <Stack direction="vertical">
                                    <span className='field-label'>Filter consignments by</span>
                                    <Stack direction="horizontal" gap={2}>
                                        <FormSelect
                                            onChange={(e) => {
                                                if (e.target.value === "custom") {
                                                    setIsCustomDateFiltering(true);
                                                } else {
                                                    setIsCustomDateFiltering(false);
                                                }
                                                setSelectedTimePeriodFilterOption(e.target.value);
                                            }}
                                            value={selectedTimePeriodFilterOption}
                                        >
                                            <option value="all">All Time Periods</option>
                                            <option value="thisyear">This year</option>
                                            <option value="lastyear">Last year</option>
                                            <option value="custom">Custom dates</option>
                                        </FormSelect>
                                        <FormSelect
                                            onChange={(e) => {
                                                setSelectedPremisesFilterOption(e.target.value);
                                            }}
                                            value={selectedPremisesFilterOption}
                                        >
                                            <option value="all">All Premises</option>
                                            {
                                                premisesOptions && premisesOptions.length > 0 && premisesOptions.map(po => {
                                                    return (<option key={`premises_opt_${po}`} value={po}>{po}</option>)
                                                })
                                            }
                                        </FormSelect>
                                        <Button
                                            onClick={clearConsignmentFilter}
                                            className="btn-transparent"
                                            style={{ lineHeight: "20px" }}
                                        >
                                            <FontAwesomeIcon icon={faXmarkCircle} />
                                        </Button>
                                    </Stack>
                                    {
                                        isCustomDateFiltering ? (
                                            <Stack direction="horizontal" className="mt-2">


                                                <Stack direction="vertical">
                                                    <Row>
                                                        <Col xs={1}>
                                                            <div style={{ lineHeight: "40px" }}>From </div>
                                                        </Col>
                                                        <Col xs={11}>
                                                            <DateTimePicker
                                                                canwrite={true}
                                                                datetime={selectedCustomTimePeriodStartDate}
                                                                showTime={false}
                                                                showUTCTime={false}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        date.setHours(0, 0, 0);
                                                                    }
                                                                    setSelectedCustomTimePeriodStartDate(date);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs={1}>
                                                            <div style={{ lineHeight: "40px" }}>To</div>
                                                        </Col>
                                                        <Col xs={11}>
                                                            <DateTimePicker
                                                                canwrite={true}
                                                                datetime={selectedCustomTimePeriodEndDate}
                                                                showTime={false}
                                                                showUTCTime={false}
                                                                onChange={(date) => {
                                                                    if (date) {
                                                                        date.setHours(23, 59, 59);
                                                                    }
                                                                    setSelectedCustomTimePeriodEndDate(date);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>


                                                </Stack>

                                            </Stack>
                                        ) : null
                                    }
                                </Stack>
                            </Col>
                        </Row>
                        {
                            isSearching ? (
                                <Row className="text-center mt-5 py-0 field-label">
                                    <Spinner className="mx-auto" />
                                    <div className="pt-1">Searching...</div>
                                </Row>
                            ) : null
                        }
                        <Row className="pt-1">
                            <Container fluid>
                                <Row>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">Last Departure</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">LAE No.</div></Col>
                                    <Col xs={4} className="hc-table-header"><div className="field-label hc-table-header-contents">Exporter</div></Col>
                                    <Col xs={2} className="hc-table-header"><div className="field-label hc-table-header-contents">LNC</div></Col>
                                    <Col xs={2}></Col>
                                </Row>
                                {
                                    visibleHistoricalREConsignments && visibleHistoricalREConsignments.length > 0 && visibleHistoricalREConsignments.map((hc, i) => {
                                        return (
                                            <Row key={`historical_consignment_${i}`}>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {format(new Date(hc.LastAnimalDepartureDate), "dd/MM/yyyy")}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {
                                                            hc.LAENumber
                                                        }
                                                    </div>
                                                </Col>
                                                <Col xs={4} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        {hc.ExporterName}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        LNC-{hc.LNCNumber}
                                                    </div>
                                                </Col>
                                                <Col xs={2} className='hc-table-row-cell'>
                                                    <div className='hc-table-row-cell-contents'>
                                                        <Button
                                                            onClick={() => {
                                                                goToViewHistoricalConsignment(hc.RegisteredEstablishmentConsignmentID);
                                                            }}
                                                        >
                                                            View
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }
                                {
                                    visibleHistoricalREConsignments && visibleHistoricalREConsignments.length === 0 && searchTerm.length > 2 ? (
                                        <div className="text-center mt-5 pt-3 field-label">
                                            No historical consignments match your search criteria.
                                        </div>
                                    ) : null
                                }
                                {
                                    visibleHistoricalREConsignments && visibleHistoricalREConsignments.length === 0 && searchTerm.length < 2 ? (
                                        <div className="text-center mt-5 pt-3 field-label">
                                            There are no historical consignments.
                                        </div>
                                    ) : null
                                }

                            </Container>
                        </Row>
                    </Container>
                </Row>

            </Container>
        </LoggedInContainer>
    );
}

export default HistoricalREConsignmentsDashboard;