import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import { parseNumericInput, PercentageValidationRules } from "../../utils/InputValidator";
import { Tooltip } from "react-tooltip";
import { StringIsNullUndefinedOrEmpty } from "../../utils/StringUtils";
import ReadWriteInputGroup from "../ReadWriteElements/ReadWriteInputGroup";

const FeedingBehaviour = ({ deckRecord, onUpdate, hasPadding, onTriggerValidation }) => {

    const perCentageValidationRules = new PercentageValidationRules(false, false);
    const [isMissingNoInterestPercent, setIsMissingNoInterestPercent] = useState(false);
    const [isMissingClimbingSmotheringPercent, setIsMissingClimbingSmotheringPercent] = useState(false);
    const [isInvalidCombinedPercentage, setIsInvalidCombinedPercentage] = useState(false);
    const [invalidNoInterestPercentageError, setInvalidNoInterestPercentageError] = useState(false);
    const [invalidClimbingSmotheringPercentageError, setInvalidClimbingSmotheringPercentageError] = useState(false);

    const updatePercentNoInterest = (percentNoInterest) => {
        if (deckRecord.DeckRecord.isEmpty === false) { // you should not be able to update an empty deck
            let lPercentClimbingSmothering = deckRecord.DeckRecord.feedingBehaviourClimbingSmotheringPercent;
            let lPercentInterested = null;

            let lIsValid = false;

            if (isNotNullOrEmpty(percentNoInterest) && isNotNullOrEmpty(lPercentClimbingSmothering)) {
                lPercentInterested = 100 - Number(percentNoInterest) - Number(lPercentClimbingSmothering);
                lIsValid = isValid(percentNoInterest, lPercentClimbingSmothering);
            }

            onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id,
                percentNoInterest, lPercentClimbingSmothering, lPercentInterested, lIsValid);
        }
    }

    const updatePercentClimbingSmothering = (percentClimbingSmothering) => {
        if (deckRecord.DeckRecord.isEmpty === false) { // you should not be able to update an empty deck
            let lPercentNoInterest = deckRecord.DeckRecord.feedingBehaviourNoInterestPercent;
            let lPercentInterested = null;

            let lIsValid = false;

            if (isNotNullOrEmpty(lPercentNoInterest) && isNotNullOrEmpty(percentClimbingSmothering)) {
                lPercentInterested = 100 - Number(lPercentNoInterest) - Number(percentClimbingSmothering);
                lIsValid = isValid(lPercentNoInterest, percentClimbingSmothering);
            }

            onUpdate(deckRecord.DeckRecord.consignmentDeckID, deckRecord.DeckRecord.id,
                lPercentNoInterest, percentClimbingSmothering, lPercentInterested, lIsValid);
        }
    }

    const isValid = (percentNoInterest, percentClimbingSmothering) => {
        let lIsValidCombinePercentage = isValidCombinedPercentage(percentNoInterest, percentClimbingSmothering);
        let lIsNoInterestValid = isNotNullOrEmpty(percentNoInterest);
        let lIsClimbingSmotheringValid = isNotNullOrEmpty(percentClimbingSmothering);

        return lIsNoInterestValid && lIsClimbingSmotheringValid && lIsValidCombinePercentage;
    }

    const isValidCombinedPercentage = (valueOne, valueTwo) => {
        if (isNotNullOrEmpty(valueOne) && isNotNullOrEmpty(valueTwo)) {
            return Number(valueOne) + Number(valueTwo) <= 100;
        }
        else {
            // return true here becuase they will be marked as invalid as they are empty
            return true;
        }
    }

    const isNotNullOrEmpty = (value) => {
        return !StringIsNullUndefinedOrEmpty(value);
    }

    useEffect(() => {
        // we do not need to run this on first load
        if (onTriggerValidation >= 0) {
            setIsMissingNoInterestPercent(false);
            setIsMissingClimbingSmotheringPercent(false);
            setIsInvalidCombinedPercentage(false);

            if (deckRecord.DeckRecord.isValid === false) { // empty decks should alwways be valid. On load they are set to valid and it should never change.
                let lNoInterestPercent = deckRecord.DeckRecord.feedingBehaviourNoInterestPercent;
                let lClimbingSmotheringPercent = deckRecord.DeckRecord.feedingBehaviourClimbingSmotheringPercent;

                let lMissingNoInterest = !isNotNullOrEmpty(lNoInterestPercent);
                let lMissingClimbingSmothering = !isNotNullOrEmpty(lClimbingSmotheringPercent);

                setIsMissingNoInterestPercent(lMissingNoInterest);
                setIsMissingClimbingSmotheringPercent(lMissingClimbingSmothering);

                if (!lMissingNoInterest && !lMissingClimbingSmothering) {
                    if (!isValidCombinedPercentage(lNoInterestPercent, lClimbingSmotheringPercent)) {
                        setIsInvalidCombinedPercentage(true);
                        let lCombinedError = "The combined percentage can not be greather than 100.";

                        setInvalidNoInterestPercentageError(lCombinedError);
                        setInvalidClimbingSmotheringPercentageError(lCombinedError);
                    }
                }
                else {
                    setInvalidNoInterestPercentageError("A percentage for no interest is required.");
                    setInvalidClimbingSmotheringPercentageError("A percentage for climbing, smothering is required.");
                }
            }
        }
    }, [onTriggerValidation]);

    return (
        <Row className={hasPadding === true ? "pb-2" : ""}>
            {hasPadding}
            <Col xs={3} className="dr-text">
                {deckRecord.Species.speciesName}
            </Col>
            <Col xs={9}>
                <Row>
                    <Col xs={4}>
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='dr-text'>
                                <Form.Control
                                    id={`percentNoInterest_${deckRecord.DeckRecord.id}`}
                                    value={deckRecord.DeckRecord.isEmpty ? "-" : deckRecord.DeckRecord.feedingBehaviourNoInterestPercent}
                                    onBlur={(e) =>
                                        e.target.value === "" ?
                                            updatePercentNoInterest(Number(e.target.value))
                                            : null
                                    }
                                    onChange={(e) => {
                                        setIsMissingNoInterestPercent(false);
                                        setIsInvalidCombinedPercentage(false);
                                        parseNumericInput(e, perCentageValidationRules);
                                        updatePercentNoInterest(e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isMissingNoInterestPercent || isInvalidCombinedPercentage) && !deckRecord.DeckRecord.isEmpty}
                                    disabled={deckRecord.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${isMissingNoInterestPercent || isInvalidCombinedPercentage ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    (isMissingNoInterestPercent || isInvalidCombinedPercentage) && !deckRecord.DeckRecord.isEmpty ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#percentNoInterest_${deckRecord.DeckRecord.id}`}
                                                place="top"
                                                variant="error"
                                                content={invalidNoInterestPercentageError}
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={4}>
                        <Form.Group>
                            <ReadWriteInputGroup readonlyclasses='dr-text'>
                                <Form.Control
                                    id={`percentClimbingSmothering_${deckRecord.DeckRecord.id}`}
                                    value={deckRecord.DeckRecord.feedingBehaviourClimbingSmotheringPercent}
                                    onBlur={(e) =>
                                        e.target.value === "" ?
                                            updatePercentClimbingSmothering(Number(e.target.value))
                                            : null
                                    }
                                    onChange={(e) => {
                                        setIsMissingClimbingSmotheringPercent(false);
                                        setIsInvalidCombinedPercentage(false);
                                        parseNumericInput(e, perCentageValidationRules);
                                        updatePercentClimbingSmothering(e.target.value);
                                    }}
                                    className="inputGroupWithAppend errorLeft"
                                    isInvalid={(isMissingClimbingSmotheringPercent || isInvalidCombinedPercentage) && !deckRecord.DeckRecord.isEmpty}
                                    disabled={deckRecord.DeckRecord.isEmpty}
                                />
                                <InputGroup.Text className={`inputGroupAppend ${(isMissingClimbingSmotheringPercent || isInvalidCombinedPercentage) && !deckRecord.DeckRecord.isEmpty ? "invalidAppend" : ""}`}>%</InputGroup.Text>
                                {
                                    isMissingClimbingSmotheringPercent || isInvalidCombinedPercentage ? (
                                        <div className="validation-error-tooltip">
                                            <Tooltip
                                                anchorSelect={`#percentClimbingSmothering_${deckRecord.DeckRecord.id}`}
                                                place="top"
                                                variant="error"
                                                content={invalidClimbingSmotheringPercentageError}
                                            />
                                        </div>
                                    ) : null
                                }
                            </ReadWriteInputGroup>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="dr-text text-end">
                        {
                            deckRecord.DeckRecord.isEmpty ? "-%" :
                                deckRecord.DeckRecord.feedingBehaviourInterestedPercent !== null ?
                                    <div>{deckRecord.DeckRecord.feedingBehaviourInterestedPercent}%</div>
                                    : null
                        }
                    </Col>
                </Row>
            </Col>
        </Row>
    )
};

export default FeedingBehaviour;