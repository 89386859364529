import Axios from "axios";
import { apiConfig } from "./apiConfig";
import { clearLoggedInUser, getSessionLoggedInUser, setSessionLoggedInUser, updateLoggedInUser } from "../helpers/SessionHelper";
import globalRouter from "../helpers/GlobalRouter";
import { setupCache } from "axios-cache-adapter";

// See https://github.com/lisaogren/axios-cache-adapter for configuration options
const cacheOptions = {
    maxAge: 15 * 60 * 1000, // 15 minutes
    limit: false, // No limit to cached requests
    exclude: {
        paths: [],
        query: true, // Doesn't cache requests that have a query string component
        filter: null,
        methods: ['post', 'patch', 'put', 'delete'] // Doesn't cache requests using these methods
    },
    clearOnStale: false, // Clear cached results as soon as they become stale
    clearOnError: true, // Reset the whole cache if there are any errors writing to the cache
    readOnError: false, 
    readHeaders: true, // Use the cache-control headers returned by the API to set the max-age of the cache
    ignoreCache: false, 
    debug: process.env.REACT_APP_ENV !== "PRODUCTION" &&  process.env.REACT_APP_ENV !== "TEST" ? false : false // Console log the caching functions in dev
}

// Create a cache and adapter that can be passed to the Axios instance
const cache = setupCache(cacheOptions);

export const clearAPICache = () => {
    cache.store.clear();
}

let refreshingFunc = undefined;

export const apiagent = (options, ttl = 1) => {
    let retry = true;

    // Define request success interceptor
    const prepareRequestHandler = (requestConfig) => {
        if (options.config && options.config.headers) {
            let lKeys = Object.keys(options.config.headers);
            for(const key of lKeys){
                requestConfig.headers[key] = options.config.headers[key];
            }
        }
        else if (hasAccessToken()) {
            let access_token = getAccessToken();
            requestConfig.headers["Authorization"] = "Bearer " + access_token;
        }
       
        requestConfig.headers["Content-Type"] = "application/json";
        requestConfig.headers["x-api-key"] = "sZksEIse1vfrTLc1QkDnRTTPOAYBomvY1ub/HIYgIkM=";
        return requestConfig;
    };
    
    // Define request error interceptor
    const requestErrorHandler = (requestError) => {
        //console.log("Request Error:", requestError);
        Promise.reject(requestError);
    }
    
    // Define response success interceptor
    const responseSuccessHandler = async (response) => {
        //console.log("Response success: ", response);
        const length = await cache.store.length()
        //console.log('Cache store length:', length)
        return response
    }

    // Define response error interceptor
    const responseErrorHandler = async (error) => {
        //console.log("Response Error: ", error);
        if (error?.response?.status === 401 && retry === true && ttl > 0) {
            // Try again once after the first failure
            retry = false;
            try {
                // If there is not already a refresh request underway, create one
                if (!refreshingFunc) refreshingFunc = requestNewAccessToken();

                // Await the results of the currently underway refresh request
                let results = await refreshingFunc;
                if (results?.status === 200) {
                    // The refresh token successfully acquired a new access token
                    // Store the new tokens
                    let isInitialLogin = false;
                    setSessionLoggedInUser(results.data, isInitialLogin);
                    // Run the original request again
                    return request(options).then(onRequestSuccess).catch(onRequestError);
                } else {
                    // The refresh token failed to acquire a new access token
                    clearAPICache();
                    // Log the user out
                    clearLoggedInUser();
                    // Navigate to the login page
                    globalRouter.navigate("/", {state: {sessionExpired: true}});
                }
            } catch (err) {
                // Some other error occurred, return to login
                clearAPICache();
                clearLoggedInUser();
                globalRouter.navigate("/", {state: {sessionExpired: true}});

                refreshingFunc = undefined;
            } finally {
                // Clear/reinitialize the refresh request function
                refreshingFunc = undefined;
            }
        }

        if (error?.response?.data === "Invalid refresh token provided") {
            clearAPICache();
            clearLoggedInUser();
            globalRouter.navigate("/", {state: {sessionExpired: true}});

            refreshingFunc = undefined;
        }

        refreshingFunc = undefined;
        
        return Promise.reject(error);
    }

    // Create an Axios instance that uses the cache adapter 
    const request = Axios.create({adapter: cache.adapter});

    // Attach our custom request and response interceptors
    request.interceptors.request.use(prepareRequestHandler,requestErrorHandler);
    request.interceptors.response.use(responseSuccessHandler, responseErrorHandler);    

    // Perform the request
    return request(options).then(onRequestSuccess).catch(onRequestError).finally(onRequestFinished(options));;
};

const onRequestSuccess = (response) => {
    return Promise.resolve(response);
};

const onRequestError = async (error) => {
    return Promise.reject(error);
};

const onRequestFinished = (options) => {
    if (process.env.REACT_APP_ENV === "PRODUCTION") return;
    //console.log("Request completed:", options); // Debug log
}



/**
 * Base HTTP Client
 */
export const requests = {
    get(url, params = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "get",
                params: params
            }
        );
    },

    delete(url) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "delete",
            }
        );
    },

    post(url, data = {}, config = {}, ttl = 1) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "post",
                data: data,
                config: config,
            }, 
            ttl
        );
    },

    put(url, data = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "put",
                data: data,
            }
        );
    },

    patch(url, data = {}) {
        return apiagent(
            {
                baseURL: apiConfig.baseURL,
                url: url,
                method: "patch",
                data: data,
            }
        );
    },
};

const hasAccessToken = () => {
    return getSessionLoggedInUser()?.authDetails?.accessToken?.length > 0;
}

const getAccessToken = () => {
    let loggedInUser = getSessionLoggedInUser();
    if (loggedInUser?.authDetails?.accessToken) {
        return loggedInUser.authDetails.accessToken;
    }
}

const requestNewAccessToken = async () => {
    const makeRequest = async () => {
        let loggedInUser = getSessionLoggedInUser();
        let body = { AccessToken: null, RefreshToken: loggedInUser?.authDetails?.refreshToken };
        let results = await requests.post("Authenticate/web/refreshaccesstoken", body, {}, 0)
            .then(res => {
                loggedInUser.authDetails = res.data.authDetails;
                updateLoggedInUser(loggedInUser);
                return res;
            })
            .catch(err => {
                return err;
            });
        return results;
    }

    return makeRequest();
}   
