export class ConsignmentEndOfVoyageReport {
    ID;
    ConsignmentID;
    FeedROBID;
    FeedAvailabilityComment;
    IsAnimalRestSpaceAdequate;
    AnimalRestSpaceComment;
    BeddingLoading;
    BeddingROB;
    IsBeddingIssue;
    BeddingIssueComment;
    IsBeddingUseLoadingSlipperyAreas;
    IsBeddingUseManagementPlans;
    IsBeddingUseMitigationHealthConcerns;
    IsBeddingUseAfterWashDown;
    IsBeddingUseHospitalPens;
    IsBeddingUseNotUsed;
    IsUnexpectedHealthIssues;
    UnexpectedHealthIssuesComment;
    IsOtherHealthIssues;
    OtherHealthIssuesComment;
    IsAggressiveAnimalIssues;
    AggressiveAnimalIssuesComment;
    IsDischargeConcerns;
    DischargeConcernsComment;
    IsAgreeWithDeclaration;
    AgreeWithDeclarationComment ;
    IsEOVReviewed;
    EndOfVoyageCompletedDateUTC;
    ConsignmentDayID;

    constructor({
        id,
        consignmentID,
        feedROBID,
        feedAvailabilityComment,
        isAnimalRestSpaceAdequate,
        animalRestSpaceComment,
        beddingLoading,
        beddingROB,
        isBeddingIssue,
        beddingIssueComment,
        isBeddingUseLoadingSlipperyAreas,
        isBeddingUseManagementPlans,
        isBeddingUseMitigationHealthConcerns,
        isBeddingUseAfterWashDown,
        isBeddingUseHospitalPens,
        isBeddingUseNotUsed,
        isUnexpectedHealthIssues,
        unexpectedHealthIssuesComment,
        isOtherHealthIssues,
        otherHealthIssuesComment,
        isAggressiveAnimalIssues,
        aggressiveAnimalIssuesComment,
        isDischargeConcerns,
        dischargeConcernsComment,
        isAgreeWithDeclaration,
        agreeWithDeclarationComment ,
        isEOVReviewed,
        endOfVoyageCompletedDateUTC,
        consignmentDayID
    }) {
        this.ID = id;
        this.ConsignmentID = consignmentID;
        this.FeedROBID = feedROBID;
        this.FeedAvailabilityComment = feedAvailabilityComment;
        this.IsAnimalRestSpaceAdequate = isAnimalRestSpaceAdequate;
        this.AnimalRestSpaceComment = animalRestSpaceComment;
        this.BeddingLoading = beddingLoading;
        this.BeddingROB = beddingROB;
        this.IsBeddingIssue = isBeddingIssue;
        this.BeddingIssueComment = beddingIssueComment;
        this.IsBeddingUseLoadingSlipperyAreas = isBeddingUseLoadingSlipperyAreas;
        this.IsBeddingUseManagementPlans = isBeddingUseManagementPlans;
        this.IsBeddingUseMitigationHealthConcerns = isBeddingUseMitigationHealthConcerns;
        this.IsBeddingUseAfterWashDown = isBeddingUseAfterWashDown;
        this.IsBeddingUseHospitalPens = isBeddingUseHospitalPens;
        this.IsBeddingUseNotUsed = isBeddingUseNotUsed;
        this.IsUnexpectedHealthIssues = isUnexpectedHealthIssues;
        this.UnexpectedHealthIssuesComment = unexpectedHealthIssuesComment;
        this.IsOtherHealthIssues = isOtherHealthIssues;
        this.OtherHealthIssuesComment = otherHealthIssuesComment;
        this.IsAggressiveAnimalIssues = isAggressiveAnimalIssues;
        this.AggressiveAnimalIssuesComment = aggressiveAnimalIssuesComment;
        this.IsDischargeConcerns = isDischargeConcerns;
        this.DischargeConcernsComment = dischargeConcernsComment;
        this.IsAgreeWithDeclaration = isAgreeWithDeclaration;
        this.AgreeWithDeclarationComment = agreeWithDeclarationComment;
        this.IsEOVReviewed = isEOVReviewed;
        this.EndOfVoyageCompletedDateUTC = endOfVoyageCompletedDateUTC;
        this.ConsignmentDayID = consignmentDayID;
    }
}