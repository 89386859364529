export class DeckRecord {
    id;
    consignmentDeckID;
    consignmentDayID;
    speciesID;
    generalDemeanourUnhappyPercent;
    generalDemeanourContentPercent;
    feedingBehaviourNoInterestPercent;
    feedingBehaviourClimbingSmotheringPercent;
    feedingBehaviourInterestedPercent;
    manurePadAbsentPercent;
    manurePadDryPercent;
    manurePadSloppyPercent;
    manurePadAverageDepthID;
    deckWashedDown;
    addedBedding;
    shovelledOut;
    hotSpotDescription;
    scabbyMouthPercent;
    pantingScoreTwoPercent;
    pantingScoreThreePercent;
    pantingScoreFourPercent;
    isEmpty; // One set during instantiation, this should not change
    // On the ship app, a user can mark a deck as being empty. That means in the database all the values are null. This is still considered to be valid.
    // If a deck is empty it should always pass validation and therefore never show an error message or invalid state
    // Set isEmpty based on what is passed in during instantiation, we do not want this property to update once it has been set
    // If a deck was marked as empty on the ship, website users can not restore decks or mark them as empty
    // Since we don not have an IsEmpty column in the database, we assume a deck was marked as empty if all of the inputs are null

    constructor({ id, consignmentDeckID, consignmentDayID, speciesID, generalDemeanourUnhappyPercent, generalDemeanourContentPercent,
        feedingBehaviourNoInterestPercent, feedingBehaviourClimbingSmotheringPercent, feedingBehaviourInterestedPercent,
        manurePadAbsentPercent, manurePadDryPercent, manurePadSloppyPercent, manurePadAverageDepthID, deckWashedDown, addedBedding, shovelledOut,
        hotSpotDescription, scabbyMouthPercent, pantingScoreTwoPercent, pantingScoreThreePercent, pantingScoreFourPercent }) {
        this.id = id;
        this.consignmentDeckID = consignmentDeckID;
        this.consignmentDayID = consignmentDayID
        this.speciesID = speciesID;
        this.generalDemeanourUnhappyPercent = generalDemeanourUnhappyPercent;
        this.generalDemeanourContentPercent = generalDemeanourContentPercent;
        this.feedingBehaviourNoInterestPercent = feedingBehaviourNoInterestPercent;
        this.feedingBehaviourClimbingSmotheringPercent = feedingBehaviourClimbingSmotheringPercent;
        this.feedingBehaviourInterestedPercent = feedingBehaviourInterestedPercent;
        this.manurePadAbsentPercent = manurePadAbsentPercent;
        this.manurePadDryPercent = manurePadDryPercent;
        this.manurePadSloppyPercent = manurePadSloppyPercent;
        this.manurePadAverageDepthID = manurePadAverageDepthID;
        this.deckWashedDown = deckWashedDown;
        this.addedBedding = addedBedding;
        this.shovelledOut = shovelledOut;
        this.hotSpotDescription = hotSpotDescription;
        this.scabbyMouthPercent = scabbyMouthPercent;
        this.pantingScoreTwoPercent = pantingScoreTwoPercent;
        this.pantingScoreThreePercent = pantingScoreThreePercent;
        this.pantingScoreFourPercent = pantingScoreFourPercent;

        this.isEmpty = !generalDemeanourUnhappyPercent && !generalDemeanourContentPercent && !feedingBehaviourNoInterestPercent &&
            !feedingBehaviourClimbingSmotheringPercent && !feedingBehaviourInterestedPercent && !manurePadAbsentPercent &&
            !manurePadDryPercent && !manurePadSloppyPercent && !manurePadAverageDepthID && !deckWashedDown &&
            !addedBedding && !shovelledOut && !hotSpotDescription && !scabbyMouthPercent && !pantingScoreTwoPercent &&
            !pantingScoreThreePercent && !pantingScoreFourPercent;
    }
}