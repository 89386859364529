import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { setEditLogs,addREConsignmentEditLogs } from "../api/apiAccess";
import { EditLog } from "../classes/EditLog";

// consignmentIDs should be an array if it is just one consignment still put the id in an array
const EditLogModal = ({ showModal, title, page, userID, consignmentIDs, reasonOptions, onOk, onClose, onSaveSuccess, onSaveFail ,isREConsignment }) => {
    const [show, setShow] = useState(false);
    const [selectedReason, setSelectedReason] = useState("none");
    const [error, setError] = useState("");
    const [details, setDetails] = useState("");

    useEffect(() => {
        setError("");
        setShow(showModal);
    }, [showModal])

    const handleClose = () => {
        setError("");
        onClose();
        setShow(false);
    }

    const handleSave = async () => {
        if (validateEditLogInputs()) {

            let lEditLogs = [];

            consignmentIDs.forEach(conID => {
                let editLog = new EditLog(conID, userID, page, selectedReason, details);
                lEditLogs.push(editLog);
            });

            if (onOk) {
                onOk(lEditLogs)
            }
            else {
                let success = isREConsignment === true ? await addREConsignmentEditLogs(lEditLogs) : await setEditLogs(lEditLogs);
                if (success.status === 200) {
                    onSaveSuccess();
                } else {
                    setError("Unable to save. Please wait a few minutes an try again. If the problem persists contact administration.");
                    onSaveFail();
                }
            }
        }
    }

    const validateEditLogInputs = () => {
        let errors = [];
        if (selectedReason === "none") {
            errors.push("A reason for editing must be selected.");
        }

        if (details.length === 0) {
            errors.push("You must provide explanatory comments for your edit.");
        }
        if (errors.length > 0) {
            setError(errors.join(" \n"));
            return false;
        }
        return true;
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Exporters must only edit consignment reporting under exceptional circumstances.</p>
                <p>Please select the reason for your edit from the list below</p>
                <Form.Select
                    onChange={(e) => setSelectedReason(e.target.value)}
                >
                    <option value="none"></option>
                    {reasonOptions?.map((ro, i) => {
                        return (
                            <option key={`reason_option_${i}`} value={ro.id}>{ro.reason}</option>
                        )
                    })}
                </Form.Select>
                <p className="mt-4">Please provide additional details</p>
                <Form.Control
                    as="textarea"
                    rows={3}
                    onChange={(e) => setDetails(e.target.value)}>
                </Form.Control>
                {
                    error.length > 0 ? (
                        <Alert
                            variant="error"
                        >
                            {error}
                        </Alert>
                    ) : null

                }
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="primary" onClick={handleSave}>Save changes</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditLogModal;