import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Stack, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const VoyageConsignmentDetails = ({ detailedConsignment, eovReport, isStarted, onViewConsignmentClicked,  onLoadingAndDepartureViewed, onDischargeViewed, onEOVViewed}) => {
    const [loadingAndDepartureReports, setLoadingAndDepartureReports] = useState([]);
    const [dischargeReports, setDischargeReports] = useState([]);

    useEffect(() => {
        if (detailedConsignment?.DeparturePorts?.length > 0 && isStarted) {
            let loadreports = [];
            detailedConsignment.DeparturePorts.forEach(dp => {
                if (!dp.LoadingAnimals || dp.LoadingAnimals.length === 0) return;
                let entry = {
                    consignment: detailedConsignment.Consignment,
                    departurePortID: dp.ID,
                    isReviewed: dp.IsLoadingAndDepartureReviewed
                }
                if (loadreports.find(existing => existing.consignment.id === entry.consignment.id && existing.departurePortID === entry.departurePortID)) return;
                loadreports.push(entry);
            });
            if (loadreports.filter(r => r !== undefined).length > 0) {
                setLoadingAndDepartureReports(loadreports);
            }
        }
    }, [detailedConsignment.DeparturePorts])

    useEffect(() => {
        if (detailedConsignment?.DestinationPorts?.length > 0 && isStarted) {
            let disports = [];
            detailedConsignment.DestinationPorts.forEach(dp => {
                if (!dp.DischargeCompletedDateUTC) return;
                let dischargeDate = new Date(dp.DischargeCompletedDateUTC);
                if (!(dischargeDate instanceof Date) || isNaN(dischargeDate)) return;
                let entry = {
                    consignment: detailedConsignment.Consignment,
                    destinationPortID: dp.ID,
                    isReviewed: dp.IsDischargeReviewed
                }
                if (disports.find(existing => existing.consignment.id === entry.consignment.id && existing.destinationPortID === entry.destinationPortID)) return;
                disports.push(entry);
            });
            if (disports.length > 0) {
                setDischargeReports(disports);
            }
        }
    }, [detailedConsignment.DestinationPorts.DischargeCompletedDateUTC])


    return (
        <Container className="my-auto mx-0 px-auto">
            <Stack direction="vertical" className="pb-2">
                <Stack className="py-2 mb-2" gap={1} direction="horizontal">
                    <div className="field-label">
                        LNC-{detailedConsignment?.Consignment?.lncNumber}
                    </div>

                    <Button
                        className="btn-primary-outline primary-green py-0 px-2 ms-auto"
                        onClick={() => {
                            onViewConsignmentClicked(detailedConsignment.Consignment.id);
                        }}>
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </Stack>
                <Stack direction="vertical" gap={2}>


                    {
                        loadingAndDepartureReports?.map((lr, i) => {
                            if (lr.isReviewed) return;
                            return (
                                <Button
                                    key={`loading_rep_link_${i}`}
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                        onLoadingAndDepartureViewed(lr.departurePortID);
                                    }}
                                > View Loading And Departure Report</Button>
                            )
                        })
                    }
                    {
                        dischargeReports?.map((dr, i) =>  {
                            if (dr.isReviewed) return;
                            return (
                                <Button
                                    key={`discharge_rep_link_${i}`}
                                    style={{ fontSize: "12px" }}
                                    onClick={() => {
                                        onDischargeViewed(dr.consignment.id, dr.destinationPortID);
                                    }}
                                > View Discharge Report</Button>
                            )
                        })
                    }
                    {
                        eovReport && eovReport.IsEOVReviewed !== true ? (
                            <Button
                                style={{ fontSize: "12px" }}
                                onClick={() => {
                                    onEOVViewed(detailedConsignment.Consignment.id);
                                }}
                            > View End of Voyage Report</Button>
                        ) : null
                    }
                </Stack>
            </Stack>
        </Container>
    )
}

export default VoyageConsignmentDetails;