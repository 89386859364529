import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Container } from "react-bootstrap";

const LoggedOutContainer = ({children}) => {
    return (
        <Container fluid="xs">
            {children}
        </Container>
    )
};

export default LoggedOutContainer;