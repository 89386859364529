import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Button, Stack, Form, InputGroup, Tooltip, Badge, Alert } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../..";
import { createRegisteredEstablishmentConsignment, getAllExporters, getLAENumberInfo, getManagementPlans, getSpecies, getSpeciesClasses } from "../../api/apiAccess";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faXmark, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { PlannedRegisteredEstablishmentConsignment } from "../../classes/RE/PlannedRegisteredEstablishmentConsignment";
import { PlannedRegisteredEstablishmentLivestock } from "../../classes/RE/PlannedRegisteredEstablishmentLivestock";
import { RegisteredEstablishmentConsignment } from "../../classes/RE/RegisteredEstablishmentConsignment";
import Swal from "../../utils/Swal";

const CreateREConsignment = () => {
    const userCtx = useContext(UserContext);
    const navigate = useNavigate();
    const [laeInfo, setLAEInfo] = useState([]);
    const [exporters, setExporters] = useState([]);
    const [managementPlans, setManagementPlans] = useState([]);
    const [speciesOptions, setSpeciesOptions] = useState([]);
    const [speciesClassesOptions, setSpeciesClassesOptions] = useState([]);
    const [selectedExporter, setSelectedExporter] = useState({});
    const [exporterIsInvalid, setExporterIsInvalid] = useState(false);
    const [lncNumber, setLNCNumber] = useState("");
    const [selectedRegisteredEstablishment, setSelectedRegisteredEstablishment] = useState({});
    const [livestock, setLivestock] = useState([]);
    const [livestockAggregates, setLivestockAggregates] = useState([]);
    const [isLNCNumberValid, setIsLNCNumberValid] = useState(true);
    const [hasConsignmentError, setHasConsignmentError] = useState(false);
    const [isSelectedRegisteredEstablishmentInvalid, setIsSelectedRegisteredEstablishmentInvalid] = useState(false);
    const [triggerAddManagementPlan, setTriggerAddManagementPlan] = useState({});
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const fetchLAENumberInfo = async () => {
            let results = await getLAENumberInfo(userCtx.user.id);
            if (results?.status === 200) {
                setLAEInfo(results.data);
            }
        }

        const fetchExporters = async () => {
            let results = await getAllExporters();
            if (results?.status === 200) {
                let exportersExcludingREOnly = results.data.filter(ex => ex.organisationTypeID !== 2);
                setExporters(exportersExcludingREOnly);
            }
        }

        const fetchSpecies = async () => {
            let results = await getSpecies();
            if (results?.status === 200) {
                setSpeciesOptions(results.data);
            }
        }

        const fetchSpeciesClasses = async () => {
            let results = await getSpeciesClasses();
            if (results?.status === 200) {
                setSpeciesClassesOptions(results.data);
            }
        }

        const fetchManagementPlans = async () => {
            let results = await getManagementPlans();
            if (results?.status === 200) {
                setManagementPlans(results.data);
            }
        }

        fetchLAENumberInfo();
        fetchExporters();
        fetchSpecies();
        fetchSpeciesClasses();
        fetchManagementPlans();
    }, []);

    useEffect(() => {
        if (laeInfo.length === 1) {
            setSelectedRegisteredEstablishment(laeInfo[0]);
        }
    }, [laeInfo])

    useEffect(() => {
        initializeLivestock();
    }, [speciesOptions, speciesClassesOptions, managementPlans])

    useEffect(() => {
        updateLivestockAggregates();
    }, [livestock]);

    useEffect(() => {
        // When the trigger is fired, add the currently selected plan for the provided row to it's applicable management plan list
        // We do this so that the user doesn't have to explicitly click an ADD button
        if (Object.keys(triggerAddManagementPlan).length === 0) return;
        if (triggerAddManagementPlan.livestockRowIndex === undefined) return;
        updateSelectedManagementPlans(triggerAddManagementPlan.livestockRowIndex);
        setTriggerAddManagementPlan({});
    }, [triggerAddManagementPlan])

    const initializeLivestock = () => {
        let tempLivestock = [];
        tempLivestock.push({ numberOfHead: "", speciesID: 1, classID: 1, selectedManagementPlans: [], currentlyFocusedManagementPlanSelection: managementPlans.find(p => Number(p.managementPlanSpeciesID) === 1), validation: {isNumberOfHeadValid: true, isSpeciesIDValid: true, isClassIDValid: true} });
        setLivestock(tempLivestock);
    }

    const onComplete = async () => {
        if(isSaving){
            Swal.Toast("Please wait for the current save to complete","error");
            return;
        }

        setIsSaving(true);
        setHasConsignmentError(false);
        if (validateInputData()) {
            let consignment = createAndPopulateREConsignment();
            if (consignment != null) {
                let results = await createRegisteredEstablishmentConsignment(consignment);
                if (results?.status === 200) {
                    let consignment = new RegisteredEstablishmentConsignment(results.data);
                    navigate(`/re/consignmentdashboard/${consignment.ID}`);
                } else {
                    setHasConsignmentError(true);
                }
            }
        }
        setIsSaving(false);
    }

    const goToDashboard = () => {
        navigate("/re/dashboard");
    }

    const updateSelectedRegisteredEstablishment = (selectedRegisteredEstablishmentID) => {
        let matchingSelection = laeInfo.find(info => Number(info.id) === Number(selectedRegisteredEstablishmentID));
        setSelectedRegisteredEstablishment(matchingSelection);
    }

    const updateCurrentlyFocusedManagementPlanSelection = (livestockIndex, plan) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock[livestockIndex].currentlyFocusedManagementPlanSelection = plan;
        setLivestock(tempLivestock);
        // Notify the trigger that the livestock row should have the currently seleected plan added to the list of applicable plans
        setTriggerAddManagementPlan({livestockRowIndex: livestockIndex});
    }

    const updateSelectedManagementPlans = (livestockRowIndex) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        let currentlyFocused = tempLivestock[livestockRowIndex].currentlyFocusedManagementPlanSelection;
        if (!currentlyFocused || currentlyFocused === "empty") return;

        if (tempLivestock[livestockRowIndex].selectedManagementPlans.find(mp => Number(mp.id) === Number(currentlyFocused.id)) === undefined) {
            tempLivestock[livestockRowIndex].selectedManagementPlans.push(currentlyFocused);
            setLivestock(tempLivestock);
        }
    }

    const updateLivestockRowNumberOfHead = (livestockRowIndex, numberOfHead) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock[livestockRowIndex].numberOfHead = numberOfHead;
        setLivestock(tempLivestock);
    }

    const validateInputData = () => {
        let isAllValid = true;

        if (!selectedRegisteredEstablishment || Object.keys(selectedRegisteredEstablishment).length === 0) {
            isAllValid = false;
            setIsSelectedRegisteredEstablishmentInvalid(true);
        }

        if (!lncNumber || lncNumber.length !== 6) 
        {
            isAllValid = false;
            setIsLNCNumberValid(false);
        }

        if (exporters.find(e => e.id === selectedExporter?.id) === undefined) {
            isAllValid = false;
            setExporterIsInvalid(true);
        }


        let tempLivestock = [...livestock]
        let allLivestockValid = true;
        tempLivestock.forEach(ls => {
            ls.validation.isNumberOfHeadValid = !isNaN(ls.numberOfHead) && Number(ls.numberOfHead) > 0;
            ls.validation.isSpeciesIDValid = !isNaN(ls.speciesID) && Number(ls.speciesID) > 0;
            ls.validation.isClassIDValid = !isNaN(ls.classID) && Number(ls.classID) > 0;
            if (Object.values(ls.validation).includes(false)) allLivestockValid = false;
        });
        if (!allLivestockValid) {
            isAllValid = false;
            setLivestock(tempLivestock)
        }

        return isAllValid;
    }

    const removeSelectedExporter = () => {
        setHasConsignmentError(false);
        setSelectedExporter({});
    }

    const addLivestockRow = () => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock.push({ numberOfHead: "", speciesID: 1, classID: 1, selectedManagementPlans: [], currentlyFocusedManagementPlanSelection: managementPlans.find(p => Number(p.managementPlanSpeciesID) === 1), validation: {isNumberOfHeadValid: true, isSpeciesIDValid: true, isClassIDValid: true} })
        setLivestock(tempLivestock);
    }

    const removeLivestockRow = (index) => {
        let tempLivestock = [...livestock];
        tempLivestock.splice(index, 1);
        setLivestock(tempLivestock);
    }

    const removeSelectedManagementPlan = (livestockIndex, planIndex) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock[livestockIndex]?.selectedManagementPlans.splice(planIndex, 1);
        setLivestock(tempLivestock);
    }

    const updateSelectedSpeciesForRow = (livestockRowIndex, speciesID) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock[livestockRowIndex].speciesID = Number(speciesID);
        tempLivestock[livestockRowIndex].currentlyFocusedManagementPlanSelection = managementPlans.filter(plan => Number(plan.managementPlanSpeciesID) === Number(speciesID))[0];
        setLivestock(tempLivestock);

    }

    const updateSelectedClassForRow = (livestockRowIndex, classID) => {
        setHasConsignmentError(false);
        let tempLivestock = [...livestock];
        tempLivestock[livestockRowIndex].classID = Number(classID);
        setLivestock(tempLivestock);
    }

    const updateLivestockAggregates = () => {
        let tempAggregates = [];
        livestock.forEach(ls => {
            let existingAggregateForSpeciesClass = tempAggregates.find(agg => Number(agg.speciesID) === Number(ls.speciesID));
            if (existingAggregateForSpeciesClass === undefined) {
                let speciesName = speciesOptions.find(so => Number(so.id) === Number(ls.speciesID))?.speciesName;
                tempAggregates.push({speciesID: ls.speciesID, speciesName: speciesName, numberOfHead: Number(ls.numberOfHead)})
            } else {
                let aggregateIndex = tempAggregates.indexOf(existingAggregateForSpeciesClass);
                existingAggregateForSpeciesClass.numberOfHead += Number(ls.numberOfHead);
                tempAggregates[aggregateIndex] = existingAggregateForSpeciesClass;
            }
        })
        setLivestockAggregates(tempAggregates);
    }

    const createAndPopulateREConsignment = () => {
        let consignment = new PlannedRegisteredEstablishmentConsignment();
        consignment.LAENumberID = selectedRegisteredEstablishment?.id;
        consignment.LNCNumber = lncNumber;
        consignment.ExporterID = selectedExporter?.id;
        consignment.ReportCreatedDate = new Date();
        consignment.Livestock = livestock.map(ls => 
            { 
                return new PlannedRegisteredEstablishmentLivestock(ls.speciesID, ls.classID, ls.numberOfHead, ls.selectedManagementPlans.map(mp => mp.id))
            });
        if (!consignment.IsValid()) {
            setHasConsignmentError(true);
            return null;
        }
        return consignment;
    }

    

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{backgroundColor: "#F5F5F5"}}>
                <Row className="upper-panel">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center heading-text mb-2 mt-3" style={{color: "#FFF", lineHeight: "53px", fontSize: "35px"}}>Create new RE report</div>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Container className="py-4 px-5 form-container-mid">
                        <Row>
                            <Col xs={12}>
                                <div className="heading-text darkblue">Consignment details</div>
                                <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                RE Number
                            </Col>
                            <Col xs={12}>
                                <Form.Select
                                    isInvalid={isSelectedRegisteredEstablishmentInvalid}
                                    aria-label="RE LAE Number"
                                    onChange={(e) => {
                                        setHasConsignmentError(false);
                                        setIsSelectedRegisteredEstablishmentInvalid(false);
                                        updateSelectedRegisteredEstablishment(e.target.value);
                                    }}
                                    value={selectedRegisteredEstablishment?.id}
                                >
                                    <option value={-1}>Select RE number...</option>
                                    {
                                        laeInfo.length > 1 ? <option value={-1}>Select RE number...</option> : null
                                    }
                                    {laeInfo && laeInfo.length > 0 && laeInfo.map((info, i) => {
                                        return (
                                            <option key={`lae_number_${i}_${info.laeNumber}`} value={info?.id}>{`${info.laeNumber} (${info.laeName})`}</option>
                                        )
                                    })
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <Col xs={12} className="darkblue field-label mb-1">
                                LNC Number
                            </Col>
                            <Col xs={12}>
                                <Form.Group id="lnc-number-form-group">
                                    <InputGroup className="pb-3">
                                        <InputGroup.Text id="lnc-number">LNC-</InputGroup.Text>
                                        <Form.Control
                                            autoComplete='off'
                                            isInvalid={!isLNCNumberValid}
                                            id="lnc-number-input"
                                            aria-label="LNCNumber"
                                            aria-describedby="lnc-number"
                                            onChange={(e) => {
                                                if (!isNaN(e.target.value) && e.target.value.length === 6) {
                                                    setHasConsignmentError(false);
                                                    setIsLNCNumberValid(true);
                                                }
                                                setLNCNumber(e.target.value);
                                            }}
                                            value={lncNumber}
                                        />
                                        {!isLNCNumberValid ?
                                            (
                                                <div className="validation-error-tooltip">
                                                    <Tooltip
                                                        anchorSelect="#lnc-number-form-group"
                                                        place="top"
                                                        content="LNC number must be numeric and six digits long."
                                                    />
                                                </div>
                                            ) : null    

                                        }
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="darkblue field-label mb-1">
                                Exporter
                            </Col>
                        </Row>
                            
                        { 
                            Object.keys(selectedExporter).length > 0 ? (
                                <Row className="mb-4">
                                    <Col xs={12}>
                                        <Badge className="p-2 secondary-badge" bg="secondary" style={{fontSize: "14px"}}>
                                            {selectedExporter.exporterName}
                                            <span
                                                className="ms-2"
                                                onClick={() => removeSelectedExporter()}
                                                >
                                                <FontAwesomeIcon icon={faXmark}/>
                                            </span>
                                        </Badge>
                                    </Col>
                                </Row>
                            ) : null
                        }

                        { 
                            Object.keys(selectedExporter).length === 0 ? (
                                <Row className="mb-4">
                                    <Col xs={12}>
                                        <Typeahead
                                            isInvalid={exporterIsInvalid}
                                            id="exporter-typeahead"
                                            onChange={(e) => {
                                                setHasConsignmentError(false);
                                                setExporterIsInvalid(false);
                                                setSelectedExporter(e[0])
                                            }}
                                            labelKey={(option) => `${option.exporterName}`}
                                            options={exporters}
                                            selected={[]}
                                            placeholder="Enter an exporter name..."
                                            minLength={3}
                                        />
                                    </Col>
                                </Row>
                            ):null
                        }
                        <Row>
                            <Col xs={10}>
                                <Row>
                                    <Col xs={12} style={{marginTop: "8px"}}>
                                        <div className="heading-text darkblue">Livestock details</div>
                                        <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                                    </Col>
                                </Row>
                            
                                <Row>
                                    <Col xs={12}>Please complete the table below to list livestock by species and class.</Col>
                                </Row>
                                <Row className="mt-2">
                                    <Col xs={12}>
                                        <Row>
                                            <Col xs={2} className="field-label">
                                                No. of head
                                            </Col>
                                            <Col xs={2} className="field-label">
                                                Species
                                            </Col>
                                            <Col xs={2} className="field-label">
                                                Class
                                            </Col>
                                            <Col xs={6} className="field-label">
                                                Applicable management plan/s
                                            </Col>
                                        </Row>
                                        { livestock && livestock.map((ls, lsIndex) => {
                                            let managementPlanOptions = managementPlans.filter(plan => Number(plan.managementPlanSpeciesID) === Number(ls.speciesID));
                                            
                                            return (
                                                <Row key={`livestock_row_${lsIndex}`} className="mb-2">
                                                    <Col xs={2}>
                                                        <Form.Control 
                                                            isInvalid={!ls.validation.isNumberOfHeadValid}
                                                            onChange={(e) => {
                                                                if (!isNaN(e.target.value)) {
                                                                    ls.validation.isNumberOfHeadValid = true;
                                                                    updateLivestockRowNumberOfHead(lsIndex, e.target.value);
                                                                }
                                                            }}
                                                            value={ls.numberOfHead}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Form.Select
                                                            defaultValue={ls.speciesID}
                                                            value={ls.speciesID}
                                                            onChange={(e) => updateSelectedSpeciesForRow(lsIndex, e.target.value)}
                                                            
                                                        >
                                                            {speciesOptions.map((opt, i) => <option key={`species_option_${i}`} value={opt.id}>{opt.speciesName}</option>)}
                                                        </Form.Select>
                                                                                                    
                                                    </Col>
                                                    <Col xs={2}>
                                                        <Form.Select
                                                            defaultValue={ls.classID}
                                                            value={ls.classID}
                                                            onChange={(e) => updateSelectedClassForRow(lsIndex, e.target.value)}
                                                        >
                                                            {speciesClassesOptions.map((opt, i) => <option key={`species_classes_option_${i}`} value={opt.id}>{opt.className}</option>)}
                                                        </Form.Select>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Stack direction="vertical" >
                                                            <Stack direction="horizontal" gap={1}>
                                                                <Form.Select
                                                                    onChange={(e) => {
                                                                        let plan = managementPlans.find(mp => Number(mp.id) === Number(e.target.value));
                                                                        updateCurrentlyFocusedManagementPlanSelection(lsIndex, plan);
                                                                    }}
                                                                >
                                                                    <option key={`empty_mp_opt_${lsIndex}`} value="empty"></option>
                                                                    {
                                                                        managementPlanOptions.map(mp => (
                                                                            <option key={`mp_${mp.id}`} value={mp.id}>{mp.managementPlanName}</option>
                                                                        ))
                                                                    }
                                                                </Form.Select>
                                                                {
                                                                    lsIndex !== 0 || livestock.length > 1? (
                                                                        <Button className="btn btn-secondary btn-sm" onClick={(e) => { e.preventDefault(); removeLivestockRow(lsIndex); }}><FontAwesomeIcon icon={faXmark} /></Button>
                                                                    ) : (
                                                                        <div style={{width: "31px"}}/>
                                                                    )
                                                                }
                                                            </Stack>
                                                            { 
                                                                ls.selectedManagementPlans && ls.selectedManagementPlans.length > 0 ? (
                                                                    <Row>
                                                                        <Col xs={12}>Added</Col>
                                                                        {
                                                                            ls.selectedManagementPlans.map((plan, planIndex) => 
                                                                                <div key={`added_mp_${plan.id}`}>
                                                                                    <Col xs={12}>
                                                                                        <Stack direction="horizontal" gap={1} className="px-2 py-1 mb-2 w-100" style={{backgroundColor: "#6c6c6c", borderRadius: "10px", fontSize: "12px", color: "white"}}>
                                                                                            <div style={{width: "calc(100% - 18px)"}}>{plan.managementPlanName}</div>
                                                                                            <div 
                                                                                                onClick={() => removeSelectedManagementPlan(lsIndex, planIndex)}
                                                                                                className="text-end clickable hoverable" 
                                                                                                style={{width: "18px", borderRadius: "18px", height: "18px"}}
                                                                                            >
                                                                                                <FontAwesomeIcon icon={faXmark}/>
                                                                                            </div>
                                                                                        </Stack>
                                                                                    </Col>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    </Row>
                                                                ) : null
                                                            }
                                                        </Stack>
                                                    </Col>
                                                </Row>
                                            )
                                        })

                                        }
                                        <Row>
                                            <Col xs={12}>
                                            <div className="cc-add-port-button px-2 py-1 mb-4" onClick={addLivestockRow}><FontAwesomeIcon className="primary-green" icon={faCirclePlus} /> Add another row</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={2}>
                                <Row>
                                    <Col xs={12} style={{backgroundColor: "rgba(245, 245, 245, 0.85)", minHeight: "200px", borderRadius: "3px", paddingTop: "8px"}}>
                                        <Row>
                                            <Col xs={12}>
                                                <div className="heading-text darkblue">Totals</div>
                                                <div className="bg-override-primary mt-1 mb-3" style={{height: "2px"}}></div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {
                                                livestockAggregates.map((lsa, lsaIndex) => {
                                                    return(
                                                        <Row key={`livestock_aggregates_${lsaIndex}`}>
                                                            <Col>
                                                                {lsa?.speciesName}
                                                            </Col>
                                                            <Col  style={{textAlign: 'right'}}>
                                                                {lsa.numberOfHead}
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Row>

                <Container className="button-container-mid">
                    <Row className="w-100 pt-4" style={{position: "relative", top:"-300px"}}>
                        <Stack direction="vertical">
                            {
                                hasConsignmentError ? (
                                    <div className="ps-4" style={{display: 'flex', justifyContent: 'center'}}>
                                        <Alert variant="danger">
                                            An error occurred while creating your consignment. Please check that the data you have entered is valid and try again.
                                        </Alert>
                                    </div>
                                ) : null 
                            }
                            <Stack direction="horizontal" gap={2}>
                                <Button variant="primary" disabled={isSaving} onClick={(e) => {e.preventDefault(); onComplete();}} size="lg" style={{marginLeft: "-10px", minWidth: "150px" }}>
                                    {isSaving && <FontAwesomeIcon icon={faSpinner} spin className="me-2" />}
                                    Complete</Button>
                                <Button className="ms-2 me-auto" variant="secondary" disabled={isSaving} onClick={goToDashboard} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                            </Stack>
                        </Stack>
                    </Row>
                </Container>
            </Container>
        </LoggedInContainer>
    )
}

export default CreateREConsignment;