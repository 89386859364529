export class ConsignmentDeck {
    id;
    consignmenetId;
    deckName;
    isBridge;
    
    constructor({ id, consignmentID, deckName,  isBridge}) {
        this.id = id;
        this.consignmentId = consignmentID;
        this.deckName = deckName;
        this.isBridge = isBridge;
    }
}