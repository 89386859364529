export class RegisteredEstablishmentManagementPlan {
    ID;
    ManagementPlanID;
    ManagementPlanName;
    RegisterestEstablishmentConsignmentMortalitiesID;
    constructor({id, managementPlanID, managementPlanName, registeredEstablishmentConsignmentMortalitiesID}) {
        this.ID = id;
        this.ManagementPlanID = managementPlanID;
        this.ManagementPlanName = managementPlanName;
        this.RegisterestEstablishmentConsignmentMortalitiesID = registeredEstablishmentConsignmentMortalitiesID;
    }
}