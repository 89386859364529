export class Consignment {
    id;
    lncNumber;
    exporterID;
    vesselID;
    estimatedDepartureDateUTC;
    estimatedArrivalDateUTC;
    feedROBID;
    requiresDailyReporting;
    isMultiConsignmentVoyage;
    assignedAAVID;
    consignmentCode;
    departurePorts;
    destinationPorts;
    dateLastSynced;

    constructor({id, lncNumber, exporterID, vesselID, estimatedDepartureDateUTC, estimatedArrivalDateUTC, feedROBID, requiresDailyReporting, isMultiConsignmentVoyage, assignedAAVID, consignmentCode, dateLastSynced}) {
        this.id = id;
        this.lncNumber = lncNumber;
        this.exporterID = exporterID;
        this.vesselID = vesselID;
        this.estimatedDepartureDateUTC = estimatedDepartureDateUTC;
        this.estimatedArrivalDateUTC = estimatedArrivalDateUTC;
        this.feedROBID = feedROBID;
        this.requiresDailyReporting = requiresDailyReporting;
        this.isMultiConsignmentVoyage = isMultiConsignmentVoyage;
        this.assignedAAVID = assignedAAVID;
        this.consignmentCode = consignmentCode;
        this.departurePorts = [];
        this.destinationPorts = [];
        this.dateLastSynced = dateLastSynced;
    }
}