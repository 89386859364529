import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './css/app.css';
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, createContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, useNavigate } from "react-router-dom";
import { getSessionLoggedInUser, updateLoggedInUser } from './helpers/SessionHelper';
import { getAssociatedExporter } from './api/apiAccess';
import PageRoutes from './components/PageRoutes';
import { GlobalDebug } from './utils/GlobalDebug';
import globalRouter from './helpers/GlobalRouter';

export const UserContext = createContext(undefined);

export const MediaQueryContext = createContext(undefined);

const UserManagedRouter = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [user, setUser] = useState(getSessionLoggedInUser() || {});
  const [associatedExporter, setAssociatedExporter] = useState({});
  const manageUser = { user, associatedExporter, setUser, setAssociatedExporter };
  const [mediaQuery, setMediaQuery] = useState("");
  // Expose the navigate functionality to non-react components by storing it
  // in the GlobalRouter helper
  const navigate = useNavigate();
  globalRouter.navigate = navigate;

  // if there is no user it is handled by PageRoutes.js
  useEffect(() => {
    // Check for a logged in user
    let loggedInUser = getSessionLoggedInUser();
    if (loggedInUser) {
      setUser(loggedInUser);
      manageUser.user = user;
    }
  }, [])

  useEffect(() => {
    updateMediaQueryState(true);
    window.addEventListener("resize", handleResize, false);

    return () => window.removeEventListener("resize", handleResize);
  }, [])

  const handleResize = () => {
    updateMediaQueryState();
  }

  const updateMediaQueryState = (initialCheck) => {
    if (!initialCheck) {
      if (windowWidth === window.innerWidth) return;
    }
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
    let isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (mediaQuery.mobile !== isMobile) {
      setMediaQuery({ mobile: isMobile});
    }
  }

  useEffect(() => {
    const fetchAssociatedExporter = async () => {
      if (user?.id) {
        let results = await getAssociatedExporter(user.id);
        if (results?.status === 200) {
          setAssociatedExporter(results.data);
        }
      }
    }
    if (user?.userTypeID && user?.userTypeID === 1) {
      fetchAssociatedExporter();
    }

  }, [user])

  useEffect(() => {
    manageUser.associatedExporter = associatedExporter;
    updateLoggedInUser({exporterDetails: associatedExporter.exporter});
  }, [associatedExporter])

  return (
    <MediaQueryContext.Provider value={mediaQuery}>
      <UserContext.Provider value={manageUser}>
        <PageRoutes />
      </UserContext.Provider>
    </MediaQueryContext.Provider>
  );
}

const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "PRODUCTION") {
      GlobalDebug(false, true);
    }
  }, [])

  return (
    <BrowserRouter>
      <UserManagedRouter />
    </BrowserRouter>
  )
};

export default App;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);