import React from "react";
import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Stack, Form, Table } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { getOrganisations, getOrgTypes } from "../../api/apiAccess";
import { textIncludesSearchTerm } from "../../utils/SearchUtils";
import Organisation_Add_Edit_Modal from "../../components/LiveCorpAdminTools/Organisation_Add_Edit_Modal";

const LiveCorp_Org_Management = () => {
    const navigate = useNavigate();

    const [orgTypes, setOrgTypes] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedOrg, setSelectedOrg] = useState(null);

    const [organisations, setOrganisations] = useState([]);
    const [filteredOrganisations, setFilteredOrganisations] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [orgTypeFilter, setOrgTypeFilter] = useState("all");

    const [showNoMatchingOrgs, setShowNoMatchingOrgs] = useState(false);

    const goBack = () => {
        navigate(-1)
    }

    const fetchAllOrganisations = async () => {
        let lResponse = await getOrganisations();

        if (lResponse?.status === 200) {
            setOrganisations(lResponse.data);
        }
        else {
            setOrganisations([]);
        }
    }

    const fetchOrgTypes = async () => {
        let lResponse = await getOrgTypes();
        if (lResponse?.status === 200) {
            setOrgTypes(lResponse.data);
        }
        else {
            setOrgTypes([]);
        }
    }

    useEffect(() => {
        fetchAllOrganisations();
        fetchOrgTypes();
    }, []);

    useEffect(() => {
        let lFilteredOrgs = [...organisations].filter(org =>
            (orgTypeFilter === "all" || org.orgTypeID === Number(orgTypeFilter)) &&
            (
                searchTerm === "" || textIncludesSearchTerm(org.name, searchTerm) || textIncludesSearchTerm(org.phoneNumber, searchTerm)
            )
        );
        setFilteredOrganisations(lFilteredOrgs);
    }, [organisations, searchTerm, orgTypeFilter]);

    useEffect(() => {
        if (!filteredOrganisations || filteredOrganisations.length === 0) {
            setShowNoMatchingOrgs(true);
        }
        else {
            setShowNoMatchingOrgs(false);
        }
    }, [filteredOrganisations]);

    // Clear selected user or modal close
    useEffect(() => {
        if (showModal === false) {
            setSelectedOrg(null);
        }
    }, [showModal]);

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>Organisation Management</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row className="bg-grey p-3 mb-4 align-items-end">
                        <Col xs={12} md={4}>
                            <Stack>
                                <div className="darkblue field-label">Search</div>
                                <Form.Group>
                                    <Form.Control
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                        value={searchTerm}
                                        placeholder="Type a word to search, ie name, email, phone..."
                                    />
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col md={3}>
                            <Stack>
                                <div className="darkblue field-label">Filter By:</div>
                                <Form.Group>
                                    <Form.Select value={orgTypeFilter} onChange={(e) => setOrgTypeFilter(e.target.value)}>
                                        <option value="all">All Organisation Types</option>
                                        {
                                            orgTypes.map(orgTypeOpt => {
                                                return (
                                                    <option key={`orgType_option_${orgTypeOpt.id}`} value={orgTypeOpt.id}>{`${orgTypeOpt.name}`}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col md={5} className="text-end align-self-end">
                            <Button variant="primary" onClick={() => setShowModal(true)} style={{ minWidth: "150px" }}>Add New</Button>
                        </Col>
                    </Row>
                    {
                        showNoMatchingOrgs === false ?
                            <Row>
                                <Col>
                                    <Table className="table-grey-border-bottom">
                                        <thead>
                                            <tr>
                                                <th className="table-column-heading">Organisation Name</th>
                                                <th className="table-column-heading">Organisation Type</th>
                                                <th className="table-column-heading">Phone Number</th>
                                                <th className="table-column-heading">Organisation Status</th>
                                                <th className="table-column-heading"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                filteredOrganisations.map((org, index) => {
                                                    return (
                                                        <tr key={`user_${index}`}>
                                                            <td className="table-text verticle-table-cell">{org.name}</td>
                                                            <td className="table-text verticle-table-cell">{org.type}</td>
                                                            <td className="table-text verticle-table-cell">{org.phoneNumber}</td>
                                                            <td className="table-text verticle-table-cell">{org.isActive ? "Active" : "Inactive"}</td>
                                                            <td className="verticle-table-cell">
                                                                <Button className="btn-secondary" onClick={() => { setSelectedOrg(org); setShowModal(true); }}>View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <div className="italic">There are no organisations matching the the specified criteria.</div>
                                </Col>
                            </Row>
                    }

                    <Organisation_Add_Edit_Modal showModal={showModal} organisation={selectedOrg} setShowModal={setShowModal} onAdd={fetchAllOrganisations} />
                </Container>
            </Container>
        </LoggedInContainer >
    )
};

export default LiveCorp_Org_Management;