import React from "react";
import { Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";


const SubmissionStatus = ({status}) => {
    return (
        <>
            { 
            status.submitted ? 
                (
                    <Stack direction="horizontal" gap={1}>
                        <div className="bg-faint-success dashboard-reporting-status-icon-container">
                            <FontAwesomeIcon icon={faCircleCheck} className="primary-green" style={{fontSize: "16px"}} />
                        </div>
                        <span style={{fontSize: "13px", lineHeight: 1}}>Submitted</span>
                    </Stack>
                )
                :
                
                (
                    <Stack direction="horizontal" gap={1}>
                        <div className="dashboard-reporting-status-icon-container" style={{backgroundColor: "#f7e4e4"}}>
                            <FontAwesomeIcon icon={faCircleXmark} className="error-red" />
                        </div>
                        <span style={{fontSize: "13px", lineHeight: 1}}>Not submitted</span>
                    </Stack>
                )
            }
        </>
    )
};

export default SubmissionStatus;