export class RegisteredEstablishmentConsignmentManagementPlan {
    ID;
    RegisteredEstablishmentConsignmentID;
    RegisteredEstablishmentConsignmentAnimalID;
    ManagementPlanID;
    constructor({id, registeredEstablishmentConsignmentID, registeredEstablishmentConsignmentAnimalID, managementPlanID}) {
        this.ID = id;
        this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
        this.RegisteredEstablishmentConsignmentAnimalID = registeredEstablishmentConsignmentAnimalID;
        this.ManagementPlanID = managementPlanID;
    }
}