import React, {useState, useEffect, useContext} from "react";
import JsonToHtmlTable from '../../components/JsonToHtmlTable';
import { Row, Col, Stack, Alert, Form, ProgressBar, Spinner } from "react-bootstrap";
import { getDeckTemperaturesForConsignmentID } from "../../api/apiAccess";
import { sortArrayInStandardDeckOrder } from "../../utils/ListUtils";
import { EditViewString } from "../../components/ReadWriteElements/EditViewString";
import { UserContext } from "../..";


const View_Deck_Temperatures = ({consignmentID, selectedColumnIndex, allowEditing, onSelectedColumnIndexChanged}) => {
    const [jsonDataDisplayed, setJsonDataDisplayed] = useState({ "Head": [], "Body": [] });
    const [selectedFilter, setSelectedFilter] = useState("Dry Bulb Temperature");
    const [hasData, setHasData] = useState(false);
    const [deckTemperaturesGroupedByDay, setDeckTemperaturesGroupedByDay] = useState([]);
    const [viewData, setViewData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFetched, setIsFetched] = useState(false);
    const userCtx = useContext(UserContext);

    useEffect(() => {
        const fetchDeckTemperatures = async () => {
            let results = await getDeckTemperaturesForConsignmentID(consignmentID);
            if (results?.status === 200) {
                let deckTemperatureDayGroups = [];
                results.data.forEach(rd => {
                    let existingGroup = deckTemperatureDayGroups.find(dtdg => dtdg.consignmentDay.id === rd.consignmentDay.id);
                    let existingGroupIndex = -1;
                    if (!existingGroup) {
                        deckTemperatureDayGroups.push(rd);
                    } else {
                        existingGroupIndex = deckTemperatureDayGroups.indexOf(existingGroup);
                        let missingDecks = rd.consignmentDecks.filter(cd => deckTemperatureDayGroups.find(grp => grp.consignmentDecks.find(d => d.id === cd.id) === undefined));
                        existingGroup.consignmentDecks = existingGroup.consignmentDecks.concat(missingDecks);
                        existingGroup.deckTemperatures = existingGroup.deckTemperatures.concat(rd.deckTemperatures);
                        deckTemperatureDayGroups[existingGroupIndex] = existingGroup;
                    }
                });
                // sort in ascending day order
                deckTemperatureDayGroups.sort((a, b) => (a.consignmentDay.dayNumber > b.consignmentDay.dayNumber) ? 1 : -1);
                setDeckTemperaturesGroupedByDay(deckTemperatureDayGroups);
            } else {
                setIsLoading(false);
            }
            setIsFetched(true);
        }

        fetchDeckTemperatures();
    }, []);

    useEffect(() => {
        let structuredJsonData =
        {
            "Head": [
                [["Deck"]],
                [["Range"]]
            ],
            "Body": []
        }

        buildTableColumnHeaders().forEach(i => {
            structuredJsonData["Head"].push(i);
        })

        structuredJsonData["Body"] = buildDataRows();
        setHasData(structuredJsonData["Body"].length > 0);

        setViewData(structuredJsonData);
        if (isFetched) {
            setIsLoading(false);
        }
    }, [deckTemperaturesGroupedByDay, isFetched])

    const buildTableColumnHeaders = () => {
        let uniqueDayArray = deckTemperaturesGroupedByDay.map(grp => grp.consignmentDay);
        
        let columnHeaderArr = [];
        uniqueDayArray.forEach(ud => {
            columnHeaderArr.push(
                [
                    [`Day ${ud.dayNumber}`],
                    createLink(ud),
                    ["DBT", "WBT", "RH"]
                ]
            );
        });

        return columnHeaderArr;
    }

    const buildDataRows = () => {
        let deckGroups = [];

        deckTemperaturesGroupedByDay.forEach(day => day.consignmentDecks.forEach(cd => {
            deckGroups.push({
                groupRowHeading: cd.deckName,
                isBridge: cd.isBridge,
                groupRowsData: [
                    { rowHeading: "Max.", data: [] },
                    { rowHeading: "Min.", data: [] }
                ] 
            }); 
        }));

        deckGroups = sortArrayInStandardDeckOrder(deckGroups, "groupRowHeading", "isBridge");

        let allTempDatas = [];
        deckTemperaturesGroupedByDay.forEach(grp => {
            grp.consignmentDecks.forEach(d => {
                if (allTempDatas.find(innerDeck => innerDeck.id === d.id) === undefined) {
                    allTempDatas.push({deckName: d, deckTempData: []});
                }
            });
            grp.deckTemperatures.forEach(dt => {
                let correspondingTempDataObject = allTempDatas.find(atd => atd.deckName.id === dt.consignmentDeckID);
                Object.keys(dt).forEach(k => {
                    if (dt[k] === null) {
                        dt[k] = "-";
                    }
                });
                correspondingTempDataObject.deckTempData.push(dt);
            });
        });

        allTempDatas.forEach(atd => atd.deckName = atd.deckName.deckName);
        

        allTempDatas.forEach(td => {

            td.deckTempData.forEach((temp) => {
                // Max vals
                deckGroups.find(dg => dg.groupRowHeading === td.deckName).groupRowsData[0].data.push([temp.dryBulbTemperatureMax, temp.wetBulbTemperatureMax, temp.relativeHumidityMax]);
                // Min vals
                deckGroups.find(dg => dg.groupRowHeading === td.deckName).groupRowsData[1].data.push([temp.dryBulbTemperatureMin, temp.wetBulbTemperatureMin, temp.relativeHumidityMin]);
            })
        });

        deckGroups = deckGroups.filter(dg => dg.groupRowsData[0].data.length > 0 && dg.groupRowsData[1].data.length > 0);

        return deckGroups;
    }

    const createLink = (consignmentDay) => {
        let lText = EditViewString(userCtx.user.writeAccess);
        return [{ link: { href: "/editdecktemperatures", text: lText, consignmentDay: consignmentDay } }];
    }

    return (
        <>
            {
                !isLoading && hasData === true ? (
                    <div>
                        <Row className="mb-3">
                            <Col>
                                <Stack gap={2} direction="horizontal" style={{ justifyContent: "end" }}>
                                    <div className="field-label">Compare to:</div>
                                    <Form.Select value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)} style={{ maxWidth: "200px" }}>
                                        <option value="DBT">Dry Bulb Temperature</option>
                                        <option value="WBT">Wet Bulb Temperature</option>
                                        <option value="RH">Relative Humidity</option>
                                    </Form.Select>
                                </Stack>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <JsonToHtmlTable jsonData={viewData} filter={selectedFilter} duplicateHeaders={false} initialSelectedColumnIndex={!isNaN(selectedColumnIndex) ? selectedColumnIndex : undefined} showEdit={allowEditing} onSelectedColumnChanged={onSelectedColumnIndexChanged}/>
                            </Col>
                        </Row>
                    </div>
                )
                : null
            }
            {
                !isLoading && hasData === false ? (     
                    <Alert variant="info" className="field-label">There is currently no data for the selected consignment.</Alert> 
                ): null
            }
            {
                isLoading ? ( 
                    <div className="text-center pt-5">
                        <Spinner className="mx-auto"/>
                        <div className="mx-auto">Loading data...</div>
                    </div>
                ) : null
            }
        </>
    )
}

export default View_Deck_Temperatures;