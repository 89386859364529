import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Button, FormLabel, Stack, Table } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import { toMultiLevelStringOfCondition, canViewVoluntaryData } from "../../utils/common";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../..";


const ViewReport = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userCtx = useContext(UserContext);

  const [showVoluntaryData, setShowVoluntaryData] = useState(false);

  console.log("ViewReport state:", state);

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let lCanView = canViewVoluntaryData(userCtx.user);
    setShowVoluntaryData(lCanView);
  }, [userCtx.user]);

  const getMedicationStrLine = (treatment) => {
    if (treatment) {
      let lStr = "";

      if (treatment.levelOne?.treatmentName) {
        lStr += "" + treatment.levelOne.treatmentName;
      }

      if (treatment.levelTwo?.treatmentName) {
        lStr += " > " + treatment.levelTwo.treatmentName;
      }

      if (treatment.levelThree?.treatmentName) {
        lStr += " > " + treatment.levelThree.treatmentName;
      }

      return lStr + (showVoluntaryData ? ` (${treatment.dosage}mL)` : "");
    }
    return "";
  };

  const displayTreatement = (report)=>{
    return (
      <>
      {report.treatments && report.treatments.map((treatment, index) => <p key={"medication" + "-" + index}>{getMedicationStrLine(treatment)}</p>)}
    
      {report.treatments && (<>
        {report.movedToHospitalPen ? <p>Moved to hospital pen</p> : <p>Treated in pen</p>}
        {report.fedAdditionalChaff && <p>Fed additional hay</p>}
        {report.addedSawdustToPen && <p>Added sawdust to pen</p>}
      </>)}
    </>
    )
  }

  const getHistoryReports = (report) => {
    let hreports = report.historyHealthReports;

    if (hreports && hreports.length === 1){
      hreports = hreports.concat(hreports[0].historyHealthReports);
      hreports.sort((a,b) => a.dayNumber - b.dayNumber); 
    }

    return hreports;
  }

  const getHistoryComments = (report) => {
    let hreports = report.historyHealthReports;
    let dayComments = [];

    if (hreports && hreports.length === 1){
      hreports = hreports.concat(hreports[0].historyHealthReports);

      hreports.sort((a,b) => b.dayNumber - a.dayNumber); 
      
      hreports.map((report) => {
        dayComments.push({dayNumber:report.dayNumber, comment:report.comment});
      });
    }

    return dayComments;
  }

  const displayAdditionalInfo = (report) => {
    return (
      <>
        {report.muscleInfo?.isFrontLeftLegEffected && <p>Front left leg effected</p>}
        {report.muscleInfo?.isFrontRightLegEffected && <p>Front right leg effected</p>}
        {report.muscleInfo?.isRearLeftLegEffected && <p>Rear left leg effected</p>}
        {report.muscleInfo?.isRearRightLegEffected && <p>Rear right leg effected</p>}
        {report.muscleInfo?.isLame && <p>Lame</p>}

        {report.eyeInfo?.isLeftEyeEffected && <p>Left eye effected</p>}
        {report.eyeInfo?.isRightEyeEffected && <p>Right eye effected</p>}
        {report.eyeInfo?.isBlind && <p>Blind</p>}
      </>
    );
  };
  

  return (
    <LoggedInContainer>
      <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
        <Row className="upper-panel mx-0" style={{ position: "relative" }}>
          <Col>
            <Container>
              <Row>
                <Col xs={12} style={{ marginTop: "75px" }}>
                  <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                    <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />
                    Back
                  </Button>
                  <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                    <div style={{ lineHeight: "53px", fontSize: "35px" }}>
                      {state?.type === "health" && "View Health Report"}
                      {state?.type === "birth_abortion" && state?.report.birthOutcomeTypeID && "View Birth Report"}
                      {state?.type === "birth_abortion" && state?.report.estimatedAgedMonth && "View Abortion Report"}
                      {state?.type === "mortality" && "View Mortality Report"}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>

        <Container className="py-4 px-5 form-container-full">
          <Row className="mb-1">
            <Col>
              <Stack>
                <FormLabel className="field-label"><strong>First Reporting Day</strong></FormLabel>
                <FormLabel className="field-label">
                  Day {state.report.historyHealthReports?.length > 0 ? state.report.historyHealthReports[0].dayNumber : state.report.dayNumber}
                </FormLabel>
              </Stack>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table striped hover>
                <thead>
                  <tr>
                    <th className="darkblue field-label mb-1">Deck</th>
                    <th className="darkblue field-label mb-1">Pen ID</th>
                    <th className="darkblue field-label mb-1">Species</th>
                    <th className="darkblue field-label mb-1">Ear Tag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="row-label">{state.report.deckName}</td>
                    <td className="row-label">{state.report.penName}</td>
                    <td className="row-label">{state.report.speciesName + " - " + state.report.className}</td>
                    <td className="row-label">
                      {state.report.tagType} - {state.report.tagNumber}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              {state?.report.managementPlans?.length === 0 && <FormLabel className="field-label">No management plan</FormLabel>}
              {state?.report.managementPlans?.length > 0 && (
                <div>
                  <FormLabel className="darkblue field-label mb-1"><strong>Applicable management plans:</strong></FormLabel>
                  {state?.report.managementPlans?.map((plan, index) => (
                    <p key={"plan" + "-" + index} className="field-label">{plan.managementPlanName}</p>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col>
              <FormLabel className="darkblue field-label mb-1">
                <strong>History</strong>
              </FormLabel>
            </Col>
          </Row>

          <Row>
            <Col>
              <Table>
                <thead>
                  <tr>
                    <td className="darkblue field-label text-center">Day</td>
                    <td className="darkblue field-label text-center">Report type</td>
                    <td className="darkblue field-label">Health condition / comments</td>
                    <td className="darkblue field-label">Medication/actions taken</td>
                  </tr>
                </thead>
                <tbody>
                  {getHistoryReports(state.report)?.length > 0 && (
                    <>
                      {getHistoryReports(state.report).map((report, index) => (
                        <tr key={"history" + "-" + index}>
                          <td className="row-label align-middle text-center">Day {report.dayNumber}</td>
                          <td className="row-label align-middle text-center">Health</td>
                          <td className="align-middle lines row-label">
                            {report.condition && toMultiLevelStringOfCondition(report.condition)}
                            {report.comment && state?.type === "mortality" && <p>{report.comment}</p>}
                           {displayAdditionalInfo(report)}
                          </td>
                          <td className="row-label align-middle lines">
                            {displayTreatement(report)}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}

                  <tr>
                    <td className="row-label align-middle text-center">Day {state.report.dayNumber}</td>
                    <td className="row-label align-middle text-center">
                      {state?.type === "health" && "Health"}
                      {state?.type === "birth_abortion" && state?.report.birthOutcomeTypeID && "Birth"}
                      {state?.type === "birth_abortion" && state?.report.estimatedAgedMonth && "Abortion"}
                      {state?.type === "mortality" && "Mortality"}
                    </td>
                    <td className="align-middle lines row-label">
                      {state.report.condition && toMultiLevelStringOfCondition(state.report.condition)}

                      {displayAdditionalInfo(state.report)}
                    </td>
                    <td className="row-label align-middle lines">
                      {displayTreatement(state.report)}
                    </td>
                  </tr>

                  {state?.report.subsequentHealthReports?.length > 0 && (
                    <>
                      {state.report.subsequentHealthReports.map((report, index) => (
                        <tr key={"history" + "-" + index}>
                          <td className="row-label align-middle text-center">Day {report.dayNumber}</td>
                          <td className="row-label align-middle text-center">Health</td>
                          <td className="align-middle lines row-label">
                            {report.condition && toMultiLevelStringOfCondition(report.condition)}

                            {displayAdditionalInfo(report)}
                          </td>
                          <td className="row-label align-middle lines">
                            {displayTreatement(report)}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
          {state?.type === "mortality" && (
            <>
              <Row className="mb-3">
                <Col>
                  <Stack>
                    <FormLabel className="darkblue field-label mb-1">
                      <strong>Is Euthanased? </strong>
                    </FormLabel>
                    <FormLabel className="field-label mb-1">{state?.report.isEuthanased ? "Yes" : "No"}</FormLabel>
                  </Stack>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Stack>
                    <FormLabel className="darkblue field-label mb-1">
                      <strong>Post-mortem conducted? </strong>
                    </FormLabel>
                    <FormLabel className="field-label mb-1">
                      {state?.report.isPostMortemConducted ? "Yes" : "No, "}
                      {state?.report.isPostMortemConducted ? "" : state?.report.noPostMortemConductedReason}
                    </FormLabel>
                  </Stack>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Stack>
                    <FormLabel className="darkblue field-label mb-1">
                      <strong>Other factors that may have lead to mortality </strong>
                    </FormLabel>
                    <FormLabel className="darkblue field-label mb-0">
                      {state?.report.isMortalityFactorAggression ? "Dominance / aggression" : ""}
                    </FormLabel>
                    <FormLabel className="field-label mb-0">{state?.report.isMortalityFactorFeedIssues ? "Feed issues" : ""}</FormLabel>
                    <FormLabel className="field-label mb-0">{state?.report.isMortalityFactorWaterIssues ? "Water issues" : ""}</FormLabel>
                    <FormLabel className="field-label mb-0">{state?.report.isMortalityFactorShyFeeder ? "Shy feeder" : ""}</FormLabel>
                    <FormLabel className="field-label mb-0">{state?.report.isMortalityFactorWeatherConditions ? "Weather conditions" : ""}</FormLabel>
                    <FormLabel className="field-label mb-0">{state?.report.isMortalityFactorVentilationIssues ? "Ventilation issues" : ""}</FormLabel>
                    <FormLabel className="field-label mb-0">
                      {!state?.report.isMortalityFactorAggression &&
                      !state?.report.isMortalityFactorFeedIssues &&
                      !state?.report.isMortalityFactorWaterIssues &&
                      !state?.report.isMortalityFactorShyFeeder &&
                      !state?.report.isMortalityFactorWeatherConditions &&
                      !state?.report.isMortalityFactorVentilationIssues
                        ? "None"
                        : ""}
                    </FormLabel>
                  </Stack>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <Stack>
                    <FormLabel className="darkblue field-label mb-1">
                      <strong>Further information or other diagnosis (if applicable)</strong>
                    </FormLabel>
                    <FormLabel className="field-label mb-1">
                      {state?.report.furtherInformation?.length > 0 ? state?.report.furtherInformation : "None"}
                    </FormLabel>
                  </Stack>
                </Col>
              </Row>
            </>
          )}

          {(state?.type === "health" || state?.type === "birth_abortion") && (
            <Row className="mb-3">
              <Col>
                <Stack>
                  <FormLabel className="darkblue field-label mb-1">
                    <strong> Additional comment </strong>
                  </FormLabel>
                  <FormLabel className="field-label mb-1"><strong> Day {state?.report.dayNumber} : </strong> {state?.report.comment ? state?.report.comment : "None"}</FormLabel>
                  {getHistoryComments(state.report)?.length > 0 && (
                    <>
                      {getHistoryComments(state.report).map((comment, index) => (
                        <FormLabel key={"history-comment" + "-" + index} className="field-label mb-1"><strong> Day {comment.dayNumber} : </strong> {comment.comment ? comment.comment : "None"}</FormLabel>
                      ))}
                    </>
                  )}
                </Stack>
              </Col>
            </Row>
          )}
        </Container>
      </Container>
    </LoggedInContainer>
  );
};

export default ViewReport;
