import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/app.css';
import '../css/login.css';
import CattleBG from '../assets/cattle.jfif';
import Logo from '../assets/LiveCorp_Logo.svg';
import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import LoggedOutContainer from '../layout/LoggedOutContainer';
import { Container, Stack, Form, Row, Col, Button, Alert, Spinner } from 'react-bootstrap';
import { requestAuth, submitAuth } from '../api/apiAccess';
import { UserCredentials } from '../classes/UserCredentials';
import { AuthCodeSubmissionModel } from '../classes/AuthCodeSubmissionModel';
import { UserContext } from '..';
import { setSessionLoggedInUser } from '../helpers/SessionHelper';
import { clearSession } from '../helpers/SessionHelper';


const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userCtx = useContext(UserContext);
    const [authMethod, setAuthMethod] = useState("SMSMe");
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [authCode, setAuthCode] = useState("");
    const [authCodeRequested, setAuthCodeRequested] = useState(false);
    const [resendCodeEnabled, setResendCodeEnabled] = useState(false);
    const [working, setWorking] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [sessionExpired, setSessionExpired] = useState(false);

    const [mfaCode, setMfaCode] = useState("");

    useEffect(() => {
        clearSession();
        setSessionExpired(location?.state?.sessionExpired === true);
    }, [])

    const goToDashboard = () => {
        navigate("/Dashboard");
    }

    const goToTermsAndConditions = () => {
        navigate("/TermsAndConditions");
    }

    const onRequestAuth = () => {
        if (emailAddress.length === 0) return;
        if (password.length === 0) return;
        if (authMethod.length === 0) return;
        setSessionExpired(false);

        doRequestAuth();
    }

    const doRequestAuth = async () => {
        setLoginError(false);
        setWorking(true);
        let credentials = new UserCredentials({ emailAddress: emailAddress, password: password, authenticationMethod: authMethod });
        if (!credentials) return false;
        if (!(credentials instanceof UserCredentials)) return false;
        if (!credentials.IsValid()) return false;
        let result = await requestAuth(credentials);

        if (result?.status === 200) {
            setWorking(false);
            setResendCodeEnabled(false);
            setAuthCodeRequested(true);
            setMfaCode(result.data.accessToken);
            setTimeout(() => {
                setResendCodeEnabled(true)
            }, 10000);
        } else {
            setWorking(false);
            setLoginError(true);
            setMfaCode("");
        }
    }

    const onSubmitAuth = async () => {
        if (authCode.length === 0) return;
        setWorking(true);

        let result = await submitAuth(authCode, mfaCode);
        if (result?.status === 200) {
            setWorking(false);
            userCtx.setUser(result.data);
            setSessionLoggedInUser(result.data);
            if (result.data.isEULAAgreedTo) {
                goToDashboard();
            } else {
                goToTermsAndConditions();
            }
            resetLoginProperties();
        } else {
            setInvalidCode(true);
            setWorking(false);
        }
    }

    const onGoBack = () => {
        resetLoginProperties();
    }

    const resetLoginProperties = () => {
        setEmailAddress("");
        setPassword("");
        setAuthCode("")
        setInvalidCode(false);
        setLoginError(false);
        setWorking(false);
        setAuthCodeRequested(false);
    }

    return (
        <LoggedOutContainer>
            <Row className="m-0 p-0">
                <Col md={6} className="d-none d-md-block m-0 p-0 login-left-panel">
                    <Stack className="text-center vh-100 m-0">
                        <img alt="" className="login-left-panel-bg-image" src={CattleBG} />
                        <div className="login-left-panel-text-container">
                            <div className="login-left-panel-text login-left-panel-title">LIVEXCollect</div>
                            <p className='mt-3 mb-0 login-left-panel-text login-left-panel-subtitle'>Industry data</p>
                            <p className='mt-0 login-left-panel-text login-left-panel-subtitle' style={{ color: "white" }}>in industry's hands</p>
                        </div>
                    </Stack>
                </Col>
                <Col md={6} xs={12} className="m-md-0 p-md-0 m-0 p-3">
                    <div className="login-vertical-offset">
                        <Row className="g-0">
                            <Col md={{ span: 6, offset: 3 }}>
                                <Container className="mx-auto mb-3" >
                                    <Row className="mt-5 mb-2 text-center">
                                        <div className='mb-4'>
                                            <img src={Logo} alt="LIVEXCollect logo" style={{ width: '143px' }} />
                                        </div>
                                        {
                                            process.env.REACT_APP_ENV === "TEST" ?
                                                <div style={{ color: "red", fontWeight: "bold" }}>TEST</div>
                                                : null
                                        }
                                        <div className="subtitle-text-heavy darkblue">LIVEXCollect Login</div>
                                    </Row>
                                    {working ? (
                                        <Row>
                                            <Spinner className="mx-auto" style={{ color: "#44CB6F" }} animation="grow" />
                                        </Row>
                                    ) : null

                                    }
                                    {loginError && !working ? (
                                        <Row>
                                            <Alert variant='danger'>Unable to login. Please check your credentials and try again.</Alert>
                                        </Row>
                                    ) : null
                                    }
                                    {invalidCode && !working ? (
                                        <Row>
                                            <Alert variant='danger'>Invalid code. Please check your {authMethod === "SMSMe" ? "mobile phone" : "email address"} and try again.</Alert>
                                        </Row>
                                    ) : null
                                    }
                                    {sessionExpired && !working ? (
                                        <Row>
                                            <Alert variant='danger'>Your session has expired. Please log in again.</Alert>
                                        </Row>
                                    ) : null
                                    }
                                </Container>
                            </Col>
                        </Row>
                        <Row className="g-0">
                            <Col md={{ span: 6, offset: 3 }}>
                                <Container className="px-auto">
                                    <Form onSubmit={(e) => { e.preventDefault(); }}>
                                        {authCodeRequested ?
                                            (
                                                <>
                                                    <Container>
                                                        <Row className="mb-3">
                                                            {!invalidCode ? (
                                                                <Alert variant="info">
                                                                    We have sent a one-time code to the {authMethod === "SMSMe" ? "mobile phone" : "email address"} you have registered with LIVEXCollect.
                                                                </Alert>
                                                            ) : null
                                                            }
                                                        </Row>
                                                    </Container>
                                                    <Form.Group className="mb-3" controlId="authForm.code">
                                                        <Form.Label className="field-label">Enter your authentication code</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={authCode}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setAuthCode(e.target.value.trim());
                                                            }}
                                                            onKeyUp={async (e) => {
                                                                if (authCode.length === 6 && e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    await onSubmitAuth();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>

                                                    <Container className="text-center mt-4">
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    className="btn-lg btn-primary mb-3"
                                                                    style={{ minWidth: '200px' }}
                                                                    onClick={async (e) => {
                                                                        await onSubmitAuth();
                                                                    }}
                                                                >
                                                                    LOGIN
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    className="btn-lg mb-3 btn-secondary"
                                                                    variant="outline-primary"
                                                                    style={{ minWidth: '200px' }}
                                                                    onClick={(e) => {
                                                                        onRequestAuth();
                                                                    }}
                                                                    disabled={!resendCodeEnabled}
                                                                >
                                                                    RESEND CODE
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Button
                                                                    className="btn-lg btn-secondary"
                                                                    style={{ minWidth: '200px' }}
                                                                    onClick={(e) => {
                                                                        onGoBack();
                                                                    }}
                                                                >
                                                                    BACK
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </>
                                            ) : (
                                                <>

                                                    <Form.Group className="mb-3" controlId="loginForm.email">
                                                        <Form.Label className="field-label">Email address</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="name@example.com"
                                                            value={emailAddress}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setEmailAddress(e.target.value);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="loginForm.password">
                                                        <Form.Label className="field-label">Password</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            value={password}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                setPassword(e.target.value);
                                                            }}
                                                            onKeyUp={(e) => {
                                                                if (password.length !== 0 && e.key === "Enter") {
                                                                    e.preventDefault();
                                                                    onRequestAuth();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label className="field-label">Authenticate by</Form.Label>
                                                        <Form.Select
                                                            className="override-select"
                                                            onChange={(e) => {
                                                                setAuthMethod(e.target.value)
                                                            }}
                                                        >
                                                            <option value="SMSMe">SMS</option>
                                                            <option value="EmailMe">Email</option>
                                                        </Form.Select>
                                                    </Form.Group>
                                                    <div className="field-label" style={{ textDecoration: "underline", cursor: "pointer", color:"gray" }} onClick={() => navigate("/requestnewpassword")}>Forgotten Password</div>
                                                    <Container className="text-center mt-2">
                                                        <Button
                                                            className="btn-lg btn-primary"
                                                            style={{ minWidth: '200px' }}
                                                            onClick={(e) => {
                                                                onRequestAuth();
                                                            }}
                                                        >
                                                            NEXT
                                                        </Button>
                                                    </Container>
                                                </>
                                            )}
                                    </Form>
                                </Container>
                            </Col>
                        </Row>
                        <Row className="login-footer">
                            <Col xs={12}>
                                <span style={{ fontSize: "13px", color: "gray", whiteSpace: "nowrap" }} >Built in partnership with the Australian Government through the Traceability Grants Program</span>
                            </Col>
                            <Col xs={12}>
                                <p style={{ fontSize: "12px", color: "gray", whiteSpace: "nowrap" }} >© Copyright 2024 LiveCorp ABN 88 082 408 740 All rights reserved.  • <a target="_blank" rel="noreferrer" href="https://livecorp.com.au/privacy-policy">Privacy policy </a> </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </LoggedOutContainer>
    )
};

export default Login;