import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { UserContext } from "../..";
import { updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject } from "./ReadWriteProperties";

/*
    Can have reagular element properties, or any custom properties that are part of ReadWriteProperties
*/
const ReadWriteFormRadio = (props) => {
    const userCtx = useContext(UserContext);
    const [writeAccess, setWriteAccess] = useState(false);
    const [modifiedProps, setModifiedProps] = useState({});
    const [writeOnly, setWriteOnly] = useState(false);

    useEffect(() => {
        let lWriteAccess = userCtx.user.writeAccess;
        setWriteAccess(lWriteAccess);
    }, [userCtx.user]);

    useEffect(() => {
        setWriteOnly(props.writeonly === true);
        let lModifiedProps = updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject(props, writeAccess);
        setModifiedProps(lModifiedProps);
    }, [props, writeAccess]);

    return (
        writeOnly === false ?
            writeAccess === true ?
                <Form.Check {...modifiedProps} />
                :
                modifiedProps.checked ?
                    <div className={modifiedProps.className}>{props.label}</div>
                    : null
            : null
    );
};

export default ReadWriteFormRadio;
