import React from "react";
import { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Button, Form, Stack, Table, Alert } from "react-bootstrap";
import LoggedInContainer from "../../layout/LoggedInContainer";
import { useNavigate } from "react-router-dom";
import Exporter_And_Regulatory_Add_Edit_Modal from "../../components/SettingsAndAdminTools/Exporter_And_Regulatory_Add_Edit_Modal";
import { UserContext } from "../..";
import { getAllRegulatoryUsers, getUsersForExporter } from "../../api/apiAccess";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { textIncludesSearchTerm } from "../../utils/SearchUtils";

const Exporter_And_Regulatory_User_Management = () => {
    const userCtx = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [permissionFilter, setPermissionFilter] = useState("all");
    const [selectedUser, setSelectedUser] = useState(null);
    const [showNoMatchingUsers, setShowNoMatchingUsers] = useState(false);

    const navigate = useNavigate();

    const fetchAllUsers = async () => {
        let lUserTypeID = userCtx?.user?.userTypeID;
        let lResponse = null;

        if (lUserTypeID) {
            switch (lUserTypeID) {
                case 1:
                    lResponse = await getUsersForExporter(userCtx.associatedExporter.exporterID);
                    break;
                case 2:
                    lResponse = await getAllRegulatoryUsers();
                    break;
                default: // invalid
            }
            if (lResponse?.status === 200) {
                setUsers(lResponse.data);
            }
            else {
                setUsers([]);
            }
        }
    }

    useEffect(() => {
        fetchAllUsers();
    }, [userCtx?.user?.userTypeID, userCtx?.associatedExporter?.exporterID]);

    useEffect(() => {
        if (!filteredUsers || filteredUsers.length === 0) {
            setShowNoMatchingUsers(true);
        }
        else {
            setShowNoMatchingUsers(false);
        }
    }, [filteredUsers]);

    const updateUsers = () => {
        fetchAllUsers();
    }

    useEffect(() => {
        let lFilteredUsers = [...users].filter(user =>
            (permissionFilter === "all" || (permissionFilter === "standard" && user.isOrgAdmin === false) || (permissionFilter === "admin" && user.isOrgAdmin === true))
            && (
                searchTerm === "" || textIncludesSearchTerm(user.firstName, searchTerm) || textIncludesSearchTerm(user.lastName, searchTerm) || textIncludesSearchTerm(user.emailAddress, searchTerm)
                || textIncludesSearchTerm(user.phoneNumber, searchTerm)
            )
        );
        setFilteredUsers(lFilteredUsers);
    }, [users, searchTerm, permissionFilter]);

    const goBack = () => {
        navigate(-1)
    }

    // Clear selected user on modal close
    useEffect(() => {
        if (showModal === false) {
            setSelectedUser(null);
        }
    }, [showModal]);

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col xs={12} style={{ marginTop: "75px" }}>
                                    <Button variant="back" onClick={() => goBack()} style={{ display: "inline", position: "absolute", top: "75px" }}>
                                        <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                    </Button>
                                    <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                        <div style={{ lineHeight: "53px", fontSize: "35px" }}>View / Invite Users</div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Container className="py-4 px-5 form-container-full">
                    <Row className="bg-grey p-3 mb-4">
                        <Col xs={4}>
                            <Stack>
                                <div className="darkblue field-label">Search</div>
                                <Form.Group>
                                    <Form.Control
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                        value={searchTerm}
                                        placeholder="Type a word to search, ie name, email, phone..."
                                    />
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col xs={2}>
                            <Stack>
                                <div className="darkblue field-label">Filter By:</div>
                                <Form.Group>
                                    <Form.Select value={permissionFilter} onChange={(e) => setPermissionFilter(e.target.value)}>
                                        <option value="all">All Permissions</option>
                                        <option value="admin">Administrator</option>
                                        <option value="standard">Standard</option>
                                    </Form.Select>
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col className="text-end align-self-end">
                            <Button variant="primary" onClick={() => setShowModal(true)} style={{ minWidth: "150px" }}>Add New</Button>
                        </Col>
                    </Row>
                    {
                        showNoMatchingUsers === false ?
                            <Row>
                                <Col>
                                    <Table className="table-grey-border-bottom">
                                        <thead>
                                            <tr>
                                                <th className="table-column-heading">Name</th>
                                                <th className="table-column-heading">Permission Level</th>
                                                <th className="table-column-heading">Access Mode</th>
                                                <th className="table-column-heading">Email Address</th>
                                                <th className="table-column-heading">Mobile Phone</th>
                                                <th className="table-column-heading">Account</th>
                                                <th className="table-column-heading"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                filteredUsers.map((user) => {
                                                    return (
                                                        <tr key={`user_${user.id}`}>
                                                            <td className="table-text verticle-table-cell">{`${user.firstName} ${user.lastName}`}</td>
                                                            <td className="table-text verticle-table-cell">{user.isOrgAdmin ? "Administrator" : "Standard"}</td>
                                                            <td className="table-text verticle-table-cell">{user.canWrite === true ? "Full Access" : "Read-Only"}</td>
                                                            <td className="table-text verticle-table-cell">{user.emailAddress}</td>
                                                            <td className="table-text verticle-table-cell">{user.phoneNumber}</td>
                                                            <td className="table-text verticle-table-cell">{user.isActive ? "Active" : "Inactive"}</td>
                                                            <td className="verticle-table-cell">
                                                                <Button className="btn-secondary" onClick={() => { setSelectedUser(user); setShowModal(true); }}>View</Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            :
                            <Row>
                                <Col>
                                    <div className="italic">There are no users matching the the specified criteria.</div>
                                </Col>
                            </Row>
                    }
                </Container>

                <Exporter_And_Regulatory_Add_Edit_Modal showModal={showModal} setShowModal={setShowModal} user={selectedUser} onUpdate={updateUsers} />

            </Container>
        </LoggedInContainer >
    )
};

export default Exporter_And_Regulatory_User_Management;