/// <summary>
/// Config format: {allowDecimal: bool, allowNegative: bool, allowLeadingZerosForWholeNumbers: bool, minRange: number, maxRange: number}
/// All or some of the config values can be passed in or the config argument can be omitted entirely.
/// </summary>
export class NumericValidationRules {

    constructor(config = null) {
        if (!config) {
            this.allowDecimal = true;
            this.decimalPrecision = null; // only works if allowDecimal is true
            this.allowNegative = true;
            this.allowLeadingZerosForWholeNumbers = false;
            this.allowSingleZero = true;
            this.minRange = null;
            this.maxRange = null;
        } else {
            this.allowDecimal = config.allowDecimal ?? true;
            this.decimalPrecision = config.decimalPrecision ?? null;
            this.allowNegative = config.allowNegative ?? true;
            this.allowLeadingZerosForWholeNumbers = config.allowLeadingZerosForWholeNumbers ?? false;
            this.allowSingleZero = config.allowSingleZero ?? true;
            this.minRange = config.minRange ?? null;
            this.maxRange = config.maxRange ?? null;
        }
    }
}

export class HourValidationRules extends NumericValidationRules {
    constructor(is24HourClock = true) {
        super({
            allowDecimal: false,
            allowNegative: false,
            allowLeadingZerosForWholeNumbers: true,
            minRange: is24HourClock ? 0 : 1,
            maxRange: is24HourClock ? 23 : 12
        });
    }
}

export class MinuteValidationRules extends NumericValidationRules {
    constructor() {
        super({
            allowDecimal: false,
            allowNegative: false,
            allowLeadingZerosForWholeNumbers: true,
            minRange: 0,
            maxRange: 59
        });
    }
}

// This is identical to the minute rules but added for completeness
export class SecondValidationRules extends MinuteValidationRules {}

export class PercentageValidationRules extends NumericValidationRules {
    constructor(allowDecimal = true, allowNegative = false) {
        super({
            allowDecimal: allowDecimal,
            decimalPrecision: null,
            allowNegative: allowNegative,
            allowLeadingZerosForWholeNumbers: true,
            minRange: 0,
            maxRange: 100
        });
    }
}

export class FeedNumberValidationRules extends NumericValidationRules {
    constructor() {
        super({
            allowDecimal: true,
            decimalPrecision: 2,
            allowNegative: false,
            allowLeadingZerosForWholeNumbers: false,
            allowSingleZero: true,
        });
    }
}

export class AnimalNumberValidationRules extends NumericValidationRules {
    constructor() {
        super({
            allowDecimal: false,
            allowNegative: false,
            allowLeadingZerosForWholeNumbers: false,
            allowSingleZero: true,
        });
    }
}

export const parseNumericInput = (event, numericValidationRules) => {
    if (!numericValidationRules) {
        numericValidationRules = new NumericValidationRules();
    }

     // do not allow more than the specified number of decimal places
     if (numericValidationRules.allowDecimal === true && numericValidationRules.decimalPrecision) {
        let lParts = event.nativeEvent.target.value.split('.');
        if(lParts.length === 2 && lParts[1].length > numericValidationRules.decimalPrecision){
            let lDiff = lParts[1].length - numericValidationRules.decimalPrecision;
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -lDiff);
        }
    }

    if (isNaN(Number(event.nativeEvent.data))) {
        let skip = false;
        if (numericValidationRules.allowDecimal && event.nativeEvent.data === ".") skip = true;
        if (numericValidationRules.allowNegative && event.nativeEvent.data === "-") skip = true;
        if (!skip) {
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
            event.preventDefault();
            return;
        }
    }

    if (event.nativeEvent.event === "+") {
        event.preventDefault();
        return;
    }

    if (event.nativeEvent.data === "-") {
        if (!numericValidationRules.allowNegative) {
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
            event.preventDefault();
            return;
        }
        // Only allow minus symbol if it is the first character in the field
        if (event.nativeEvent.target.value.length > 1) {
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
            event.preventDefault();
            return;
        }
    }

    if (event.nativeEvent.data === ".") {
        if (!numericValidationRules.allowDecimal) {
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
            event.preventDefault();
            return;
        }
        if (event.nativeEvent.target.value.length === 1) {
            event.nativeEvent.target.value = "0.";
        } else if (event.nativeEvent.target.value.slice(0, -1).includes(".")) {
            event.preventDefault();
            return;
        }
    }

    if (event.nativeEvent.data === "0") {
        if (numericValidationRules.allowSingleZero) {
            if (event.nativeEvent.target.value.length === 1) return;
            if (!numericValidationRules.allowLeadingZerosForWholeNumbers && event.nativeEvent.target.value.startsWith("0")) {
                event.nativeEvent.target.value = "0";
                event.preventDefault();
                return;
            }
        }

        if (!numericValidationRules.allowLeadingZerosForWholeNumbers) {
            if (event.nativeEvent.target.value.length === 1) {
                event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
                event.preventDefault();
                return;
            }

            let inputValue = event.nativeEvent.target.value;
            if (inputValue.slice(0, 1) === "-") {
                inputValue = inputValue.slice(1);
            }
            if (numericValidationRules.allowDecimal) {
                if (inputValue.slice(0, 1) === "0" && inputValue.slice(1, 2) !== ".") {
                    event.nativeEvent.target.value = event.nativeEvent.target.value.slice(0, -1);
                    event.preventDefault();
                    return;
                }
            }
        }
    } else {
        if (!numericValidationRules.allowLeadingZerosForWholeNumbers && event.nativeEvent.target.value.length > 1 && event.nativeEvent.target.value.startsWith("0")) {
            event.nativeEvent.target.value = event.nativeEvent.target.value.slice(1);
        }
    }

    if (numericValidationRules.maxRange !== null && event.target.value > numericValidationRules.maxRange) {
        event.target.value = numericValidationRules.maxRange;
        return;
    }

    if (numericValidationRules.minRange !== null && event.target.value < numericValidationRules.minRange) {
        event.target.value = numericValidationRules.minRange;
        return;
    }
}

export const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
}

export const isValidMobile = (phone) => {
    return /^\d{10}$/.test(phone);
}

// Minimum eight characters, at least one letter, one number and one special character:
export const isValidPassword = (password) => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).\S{8,}$/.test(password);
}

export const stripLeadingZeros = (value) => {
    if (value) {
        value = parseFloat(value)
    }
    else {
        value = 0;
    }
    return value;
}
