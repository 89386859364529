import React, { useState, useEffect, useContext } from "react";
import { Form } from "react-bootstrap";
import { UserContext } from "../..";
import { Stack } from "react-bootstrap";
import { updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject } from "./ReadWriteProperties";

/*
    Can have reagular element properties, or any custom properties that are part of ReadWriteProperties
*/
const ReadWriteFormCheck = (props) => {
    const userCtx = useContext(UserContext);
    const [writeAccess, setWriteAccess] = useState(false);
    const [label, setLabel] = useState("");
    const [value, setValue] = useState("");
    const [modifiedProps, setModifiedProps] = useState({});
    const [writeOnly, setWriteOnly] = useState(false);

    useEffect(() => {
        let lWriteAccess = userCtx.user.writeAccess;
        setWriteAccess(lWriteAccess);
    }, [userCtx.user]);

    useEffect(() => {
        setLabel(props.label);
        if (props.checked === true) {
            setValue("Yes");
        }
        else {
            setValue("No")
        }

        setWriteOnly(props.writeonly === true);
        let lModifiedProps = updatePropsbasedOnWriteAccessAndRemoveReadWritePropsFromObject(props, writeAccess);
        setModifiedProps(lModifiedProps);
    }, [props, writeAccess]);

    return (
        writeOnly === false ?
            writeAccess === true ?
                <Form.Check {...modifiedProps} />
                :
                <Stack className={modifiedProps.className} direction={props.labelstacked === false ? "horizontal" : "vertical"} gap={2}>
                    <div className="field-label">{label}</div>
                    <div className="text-13-400 secondary-grey">{value}</div>
                </Stack>
            : null
    );
};

export default ReadWriteFormCheck;
