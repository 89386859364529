export class ConsignmentDestinationPort {
    ID;
    ConsignmentID;
    PortID;
    Order;
    VesselArrivalDateUTC;
    DischargeCommencedDateUTC;
    DischargeCompletedDateUTC;
    DischargeAnimals;
    IsDischargeReviewed;
    ConsignmentDayID;

    constructor({id, consignmentID, portID, order, vesselArrivalDateUTC, dischargeCommencedDateUTC, dischargeCompletedDateUTC, dischargeAnimals, isDischargeReviewed, consignmentDayID}) {
      this.ID = id;
      this.ConsignmentID = consignmentID;
      this.PortID = portID;
      this.Order = order;
      this.VesselArrivalDateUTC = vesselArrivalDateUTC;
      this.DischargeCommencedDateUTC = dischargeCommencedDateUTC;
      this.DischargeCompletedDateUTC = dischargeCompletedDateUTC;
      this.DischargeAnimals = dischargeAnimals;
      this.IsDischargeReviewed = isDischargeReviewed;
      this.ConsignmentDayID = consignmentDayID;
    }
}