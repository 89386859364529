import React, { useState, useEffect } from "react";
import { Form, Stack, Row, Col, Button, Table } from "react-bootstrap";
import { getAccreditedStockpersons } from "../../api/apiAccess";
import { textIncludesSearchTerm } from "../../utils/SearchUtils";
import Accredited_Stockpersons_Add_Edit_Modal from "./Accredited_Stockpersons_Add_Edit_Modal";

const Accredited_Stockpersons_Management = () => {
    const [showModal, setShowModal] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [statusFilter, setStatusFilter] = useState("all");
    const [selectedUser, setSelectedUser] = useState(null);
    const [showNoMatchingUsers, setShowNoMatchingUsers] = useState(false);

    const fetchAllAccreditedStockPersons = async () => {
        let lResponse = await getAccreditedStockpersons();

        if (lResponse?.status === 200) {
            setUsers(lResponse.data);
        }
        else {
            setUsers([]);
        }
    }

    useEffect(() => {
        fetchAllAccreditedStockPersons();
    }, []);

    // Clear selected user or modal close
    useEffect(() => {
        if (showModal === false) {
            setSelectedUser(null);
        }
    }, [showModal]);

    useEffect(() => {
        let lFilteredUsers = [...users].filter(user =>
            (statusFilter === "all" || (statusFilter === "valid" && user.isAccreditationActive === true) || (statusFilter === "expired" && user.isAccreditationActive === false))
            && (
                searchTerm === "" || textIncludesSearchTerm(user.firstName, searchTerm) || textIncludesSearchTerm(user.lastName, searchTerm) || textIncludesSearchTerm(user.emailAddress, searchTerm)
                || textIncludesSearchTerm(user.phoneNumber, searchTerm)
            )
        );
        setFilteredUsers(lFilteredUsers);
    }, [users, searchTerm, statusFilter]);

    useEffect(() => {
        if (!filteredUsers || filteredUsers.length === 0) {
            setShowNoMatchingUsers(true);
        }
        else {
            setShowNoMatchingUsers(false);
        }
    }, [filteredUsers]);

    return (
        <>
            <Row className="bg-grey p-3 mb-4">
                <Col xs={4}>
                    <Stack>
                        <div className="darkblue field-label">Search</div>
                        <Form.Group>
                            <Form.Control
                                onChange={(e) => {
                                    setSearchTerm(e.target.value);
                                }}
                                value={searchTerm}
                                placeholder="Type a word to search, ie name, email, phone..."
                            />
                        </Form.Group>
                    </Stack>
                </Col>
                <Col xs={2}>
                    <Stack>
                        <div className="darkblue field-label">Filter By:</div>
                        <Form.Group>
                            <Form.Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                                <option value="all">All Statuses</option>
                                <option value="valid">Valid</option>
                                <option value="expired">Expired</option>
                            </Form.Select>
                        </Form.Group>
                    </Stack>
                </Col>
                <Col className="text-end align-self-end">
                    <Button variant="primary" onClick={() => setShowModal(true)} style={{ minWidth: "150px" }}>Add New</Button>
                </Col>
            </Row>
            {
                showNoMatchingUsers === false ?
                    <Row>
                        <Col>
                            <Table className="table-grey-border-bottom">
                                <thead>
                                    <tr>
                                        <th className="table-column-heading">Name</th>
                                        <th className="table-column-heading">AS ID</th>
                                        <th className="table-column-heading">Accreditation Status</th>
                                        <th className="table-column-heading">Email Address</th>
                                        <th className="table-column-heading">Mobile Phone</th>
                                        <th className="table-column-heading">Account Status</th>
                                        <th className="table-column-heading"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredUsers.map((user) => {
                                            return (
                                                <tr key={`user_${user.id}`}>
                                                    <td className="table-text verticle-table-cell">{`${user.firstName} ${user.lastName}`}</td>
                                                    <td className="table-text verticle-table-cell">{user.accreditationNumber}</td>
                                                    <td className="table-text verticle-table-cell">{user.isAccreditationActive ? "Valid" : "Expired"}</td>
                                                    <td className="table-text verticle-table-cell">{user.emailAddress}</td>
                                                    <td className="table-text verticle-table-cell">{user.phoneNumber}</td>
                                                    <td className="table-text verticle-table-cell">{user.isActive ? "Active" : "Inactive"}</td>
                                                    <td className="verticle-table-cell">
                                                        <Button className="btn-secondary" onClick={() => { setSelectedUser(user); setShowModal(true); }}>View</Button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col>
                            <div className="italic">There are no accredited stockpersons matching the the specified criteria.</div>
                        </Col>
                    </Row>
            }

            <Accredited_Stockpersons_Add_Edit_Modal managementUser={selectedUser} showModal={showModal} setShowModal={setShowModal} onAdd={fetchAllAccreditedStockPersons} />
        </>
    )
}

export default Accredited_Stockpersons_Management;