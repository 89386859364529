import React, {useEffect, useId, useState} from "react";
import { Container, Stack } from "react-bootstrap";
import { getMortalitiesForConsignmentID, getConsignmentAnimalCount } from "../../api/apiAccess";
import { formatUpToSpecifiedPrecision } from "../../utils/common";

const MortalitiesOverview = ({consignment, index, isStarted}) => {
    const componentId = useId();
    const [mortalities, setMortalities] = useState("");
    const [consignmentAnimalCount, setConsignmentAnimalCount] = useState(-1);
    const [mortalitiesYesterday, setMortalitiesYesterday] = useState("-");
    const [mortalitiesTotal, setMortalitiesTotal] = useState("-");
    const [mortalitiesTotalPercentage, setMortalitiesTotalPercentage] = useState("");

    useEffect(() => {
        if (consignment === undefined || isStarted === false) return;
        
        const fetchMortalities = async () => {
            let results = await getMortalitiesForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setMortalities(results.data);
            }
        }

        const fetchConsignmentAnimalCount = async () => {
            let results = await getConsignmentAnimalCount(consignment.id);
            if (results?.status === 200) {
                setConsignmentAnimalCount(results.data);
            }
        }

        const fetchAll = () => {
            fetchMortalities();
            fetchConsignmentAnimalCount();
        }

        fetchAll();
        
    }, [consignment, isStarted]);

    useEffect(() => {
        if (!isStarted) return;
        if (mortalities === "") return;
        calculateMortalitiesData();
    }, [mortalities, consignmentAnimalCount]);

    const calculateMortalitiesData = () => {
        setMortalitiesTotal(mortalities.length);
        let highestDayNumber = Math.max(...mortalities.map(m => m.dayNumber));
        setMortalitiesYesterday(mortalities.filter(m => m.dayNumber === highestDayNumber).length);
        
        if (consignmentAnimalCount !== -1 && consignmentAnimalCount >= mortalities.length && mortalities.length > 0) { 
            let mortalityPercentage = (mortalities.length / consignmentAnimalCount * 100);
            // round/format
            let lFormatted = formatUpToSpecifiedPrecision(mortalityPercentage, 2);
            setMortalitiesTotalPercentage(lFormatted);
        }   
    }

    return (
        <Container fluid key={`mortalities_overview_${componentId}_${index}`} className="my-auto mx-0 px-auto">
            <Stack direction="vertical">
                <Stack direction="horizontal">
                    <Stack className="text-center" direction="vertical">
                        <div>{mortalitiesYesterday}</div>
                        <div className="field-label">Yesterday</div>
                    </Stack>
                    { isStarted ? 
                        (
                            <Stack className="text-center" direction="vertical">
                                <div>{mortalitiesTotal}</div>
                                <div className="field-label">Voyage</div>
                                {
                                    mortalitiesTotal > 0 && consignmentAnimalCount !== -1 ? (
                                        <div className="field-label" style={{fontSize: "11px"}}>{mortalitiesTotalPercentage}%</div>
                                    ) : null
                                }
                            </Stack>
                        ) :null
                    }
                </Stack>
            </Stack>
        </Container>
    )
};

export default MortalitiesOverview;