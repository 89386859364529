import '../css/dashboard.css';
import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button, Stack, Alert, Spinner, Form, InputGroup } from 'react-bootstrap';
import LoggedInContainer from '../layout/LoggedInContainer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { MediaQueryContext, UserContext } from '..';
import EditLogModal from '../components/EditLogModal';
import { getEndOfVoyageReportForConsignment, patchEndOfVoyageReport, getEditPageReasons, setEOVReportAsReviewed } from '../api/apiAccess';
import { differenceInHours } from 'date-fns';
import { StringIsNullUndefinedOrEmpty } from '../utils/StringUtils';
import { Tooltip } from "react-tooltip";
import { parseNumericInput, NumericValidationRules, FeedNumberValidationRules } from '../utils/InputValidator';
import { EndOfVoyageReportSave } from '../classes/EndOfVoyageReportSave';
import ReadWriteButton from '../components/ReadWriteElements/ReadWriteButton';

const End_Of_Voyage_Report = () => {
    const mediaQueryCtx = useContext(MediaQueryContext);
    const location = useLocation();
    const { consignmentID } = useParams();
    const userCtx = useContext(UserContext);

    const navigate = useNavigate();

    const [hasData, setHasData] = useState(false);
    const [loading, setIsLoading] = useState(true);
    const [loadError, setHasLoadError] = useState(false);
    const [saveError, setHasSaveError] = useState(false);
    const [saveErrorMessage, setHasSaveErrorMessage] = useState(false);
    const [saveSuccess, setHasSaveSuccess] = useState(false);

    const [isEditMode, setIsEditMode] = useState(false);

    const [editModalShow, setEditModalShow] = useState(false);
    const [editPageReasons, setEditPageReasons] = useState([]);

    const [endOfVoyageReportID, setEndOfVoyageReportID] = useState(-1);
    const [voyageLength, setVoyageLength] = useState("");

    const [requiresDailyReporting, setRequiresDailyReporting] = useState(false);

    const [lncNumber, setLncNumber] = useState("");

    const [feedROBID, setFeedROBID] = useState(-1);
    const [pelletsROB, setPelletsROB] = useState("");
    const [grainROB, setGrainROB] = useState("");
    const [roughageROB, setRoughageROB] = useState("");
    const [feedAvailabilityComment, setFeedAvailabilityComment] = useState("");

    const [isAnimalRestSpaceAdequate, setIsAnimalRestSpaceAdequate] = useState(false);
    const [animalRestSpaceComment, setAnimalRestSpaceComment] = useState("");

    const [beddingLoaded, setBeddingLoaded] = useState("");
    const [beddingROB, setBeddingROB] = useState("");
    const [isBeddingIssues, setIsBeddingIssues] = useState(false);
    const [beddingIssuesComment, setBeddingIssuesComment] = useState("");
    const [beddingUsedLoadingAndSlipperyAreas, setBeddingUsedLoadingAndSlipperyAreas] = useState(false);
    const [beddingUsedManagaementPlans, setBeddingUsedManagaementPlans] = useState(false);
    const [beddingUsedMitigationHealthConcerns, setBeddingUsedMitigationHealthConcerns] = useState(false);
    const [beddingUsedAfterWashDown, setBeddingUsedAfterWashDown] = useState(false);
    const [beddingUsedHospitalPens, setBeddingUsedHospitalPens] = useState(false);
    const [beddingUsedNotUsed, setBeddingUsedNotUsed] = useState(false);

    const [isUnexpectedHealthIssues, setIsUnexpectedHealthIssues] = useState(false);
    const [unexpectedHealthIssuesComment, setUnexpectedHealthIssuesComment] = useState("");

    const [isOtherHealthIssues, setIsOtherHealthIssues] = useState(false);
    const [otherHealthIssuesComment, setOtherHealthIssuesComment] = useState("");

    const [isAggressiveAnimalIssues, setIsAggressiveAnimalIssues] = useState(false);
    const [aggressiveAnimalIssuesComment, setAggressiveAnimalIssuesComment] = useState("");

    const [isDischargeConcerns, setIsDischargeConcerns] = useState(false);
    const [dischargeConcernsComment, setDischargeConcernsComment] = useState("");

    const [isAgreeWithDeclaration, setIsAgreeWithDeclaration] = useState(false);
    const [declarationComment, setDeclarationComment] = useState("");

    const [pelletsROBValid, setPelletsROBValid] = useState(true);
    const [grainROBValid, setGrainROBValid] = useState(true);
    const [roughageROBValid, setRoughageROBValid] = useState(true);

    const [penSpaceAllowanceValid, setPenSpaceAllowanceValid] = useState(true);

    const [beddingLoadedValid, setBeddingLoadedValid] = useState(true);
    const [beddingROBValid, setBeddingROBValid] = useState(true);

    const [beddingIssuesValid, setBeddingIssuesValid] = useState(true);
    const [beddingUseValid, setBeddingUseValid] = useState(true);

    const [unexpectedHealthValid, setUnexpectedHealthValid] = useState(true);
    const [otherHealthValid, setOtherHealthValid] = useState(true);
    const [dominantAnimalsValid, setDominantAnimalsValid] = useState(true);
    const [dischargeConcernsValid, setDischargeConcernsValid] = useState(true);
    const [declarationStatementValid, setDeclarationStatementValid] = useState(true);

    const IntegerValidationRules = new NumericValidationRules({
        allowDecimal: false,
        allowNegative: false,
        allowLeadingZerosForWholeNumbers: false,
        allowSingleZero: true,
    });

    const decimalValidationRules = new NumericValidationRules({
        allowDecimal: true,
        decimalPrecision: 2,
        allowNegative: false,
        allowLeadingZerosForWholeNumbers: false,
        allowSingleZero: true,
    });

    const goBack = () => {
        navigate(-1, { state: { isHistorical: location?.state?.isHistorical }})
    }

    const populatePage = async () => {
        const updateIsReviewedStatus = async (eovReportID, isReviewed) => {
            if (userCtx.user?.userTypeID === 1 && isReviewed === false) {
                await setEOVReportAsReviewed(eovReportID);
            }
        }

        setIsLoading(true);
        setHasData(false);
        setHasLoadError(false);

        let lPromises = [
            getEndOfVoyageReportForConsignment(consignmentID),
            getEditPageReasons()
        ];

        Promise.all(lPromises)
            .then(response => {
                if (response && response.every(resp => resp?.status === 200)) {

                    let lEOVData = response[0].data;
                    let lEditPageReasons = response[1].data;
                    let lEOVReport = lEOVData.endOfVoyageReport;

                    updateIsReviewedStatus(lEOVReport.id, lEOVReport.isEOVReviewed);
                    setEndOfVoyageReportID(lEOVReport.id);
                    setRequiresDailyReporting(lEOVData.dailyReportingRequired);

                    let lDiffernceInHours = differenceInHours(new Date(lEOVData.lastHoofOff), new Date(lEOVData.firstHoofOn));
                    let lDiffInDays = Math.round(lDiffernceInHours / 24, 2);

                    setVoyageLength(lDiffInDays + " days");
                    setLncNumber(lEOVData.lncNumber);

                    setFeedROBID(lEOVData.feed.id);
                    setPelletsROB(lEOVData.feed.pellets);
                    setGrainROB(lEOVData.feed.grain);
                    setRoughageROB(lEOVData.feed.roughage);
                    setFeedAvailabilityComment(lEOVReport.feedAvailabilityComment);

                    setIsAnimalRestSpaceAdequate(lEOVReport.isAnimalRestSpaceAdequate);
                    setAnimalRestSpaceComment(lEOVReport.animalRestSpaceComment);

                    setBeddingLoaded(lEOVReport.beddingLoading);
                    setBeddingROB(lEOVReport.beddingROB);
                    setIsBeddingIssues(lEOVReport.isBeddingIssue);
                    setBeddingIssuesComment(lEOVReport.beddingIssueComment);
                    setBeddingUsedLoadingAndSlipperyAreas(lEOVReport.isBeddingUseLoadingSlipperyAreas);
                    setBeddingUsedManagaementPlans(lEOVReport.isBeddingUseManagementPlans);
                    setBeddingUsedMitigationHealthConcerns(lEOVReport.isBeddingUseMitigationHealthConcerns);
                    setBeddingUsedAfterWashDown(lEOVReport.isBeddingUseAfterWashDown);
                    setBeddingUsedHospitalPens(lEOVReport.isBeddingUseHospitalPens);
                    setBeddingUsedNotUsed(lEOVReport.isBeddingUseNotUsed);

                    setIsUnexpectedHealthIssues(lEOVReport.isUnexpectedHealthIssues);
                    setUnexpectedHealthIssuesComment(lEOVReport.unexpectedHealthIssuesComment);

                    setIsOtherHealthIssues(lEOVReport.isOtherHealthIssues);
                    setOtherHealthIssuesComment(lEOVReport.otherHealthIssuesComment);

                    setIsAggressiveAnimalIssues(lEOVReport.isAggressiveAnimalIssues);
                    setAggressiveAnimalIssuesComment(lEOVReport.aggressiveAnimalIssuesComment);

                    setIsDischargeConcerns(lEOVReport.isDischargeConcerns);
                    setDischargeConcernsComment(lEOVReport.dischargeConcernsComment);

                    setIsAgreeWithDeclaration(lEOVReport.isAgreeWithDeclaration);
                    setDeclarationComment(lEOVReport.agreeWithDeclarationComment);

                    let reasons = lEditPageReasons;
                    setEditPageReasons(reasons);

                    return true;
                }
                else {
                    return false;
                }
            }).then(result => {
                setHasData(result);
                setHasLoadError(!result)
                setIsLoading(false);
            })
            .catch(error => {
                setHasLoadError(true)
                setIsLoading(false);
            })
    }

    const cancelEdit = () => {
        setHasSaveError(false);
        setIsEditMode(false);
        populatePage();
    }

    useEffect(() => {
        if (consignmentID && userCtx.user) {
            populatePage();
        }
    }, [consignmentID, userCtx.user])

    const onEditModalClosed = () => {
        setEditModalShow(false);
    }

    const onEditModalSaveSuccess = () => {
        save();
    }

    const onEditModalSaveFailed = () => {
        // do nothing generic error message is displayed inside the modal
    }

    const validateAndShowEditModal = (e) => {
        const validate = () => {
            let lValid = true;

            // must have a value >= 0
            let lPelletsROBValid = !StringIsNullUndefinedOrEmpty(pelletsROB) && !isNaN(pelletsROB);
            let lGrainROBValid = !StringIsNullUndefinedOrEmpty(grainROB) && !isNaN(grainROB);
            let lRoughageROBValid = !StringIsNullUndefinedOrEmpty(roughageROB) && !isNaN(roughageROB);

            // if not suitable must have a comment
            let lPenSpaceValid = true;
            if (isAnimalRestSpaceAdequate === false && StringIsNullUndefinedOrEmpty(animalRestSpaceComment) === true) {
                lPenSpaceValid = false;
            }

            // must have a value >= 0
            let lBeddingLoadingValid = !StringIsNullUndefinedOrEmpty(beddingLoaded) && !isNaN(beddingLoaded);
            let lBeddingROBValid = !StringIsNullUndefinedOrEmpty(beddingROB) && !isNaN(beddingROB);

            // if not suitable must have a comment
            let lBeddingIssuesValid = true;
            if (isBeddingIssues === true && StringIsNullUndefinedOrEmpty(beddingIssuesComment) === true) {
                lBeddingIssuesValid = false;
            }

            // must have either "Bedding not used" or at least one of the other options checked
            let lBeddingUseageValid = true;
            // must have at least one option checked
            if (beddingUsedLoadingAndSlipperyAreas === false && beddingUsedManagaementPlans === false
                && beddingUsedMitigationHealthConcerns === false && beddingUsedAfterWashDown === false
                && beddingUsedHospitalPens === false && beddingUsedNotUsed === false) {
                lBeddingUseageValid = false;
            }
            // can't have "No useage" and another option checked
            else if (beddingUsedNotUsed === true && (
                beddingUsedLoadingAndSlipperyAreas === true || beddingUsedManagaementPlans === true
                || beddingUsedMitigationHealthConcerns === true || beddingUsedAfterWashDown === true
                || beddingUsedHospitalPens === true
            )
            ) {
                lBeddingUseageValid = false;
            }

            // if not suitable must have a comment
            let lHealthAndWelfareIssuesValid = true;
            if (isUnexpectedHealthIssues === true && StringIsNullUndefinedOrEmpty(unexpectedHealthIssuesComment) === true) {
                lHealthAndWelfareIssuesValid = false;
            }

            // if not suitable must have a comment
            let lOtherHealthAndWelfareIssuesValid = true;
            if (isOtherHealthIssues === true && StringIsNullUndefinedOrEmpty(otherHealthIssuesComment) === true) {
                lOtherHealthAndWelfareIssuesValid = false;
            }

            // aggressive animals only required if daily reporting is NOT required
            // if not suitable must have a comment
            let lAggressiveAnimalsValid = true;
            if (requiresDailyReporting === false) {
                if (isAggressiveAnimalIssues === true && StringIsNullUndefinedOrEmpty(aggressiveAnimalIssuesComment) === true) {
                    lAggressiveAnimalsValid = false;
                }
            }

            // if not suitable must have a comment
            let lDischargeConcernsValid = true;
            if (isDischargeConcerns === true && StringIsNullUndefinedOrEmpty(dischargeConcernsComment) === true) {
                lDischargeConcernsValid = false;
            }

            // if disagree must have a comment
            let lDeclarationValid = true;
            if (isAgreeWithDeclaration === false
                && (
                    StringIsNullUndefinedOrEmpty(declarationComment) === true
                    || declarationComment.length < 30
                )
            ) {
                lDeclarationValid = false;
            }

            if (!lPelletsROBValid || !lGrainROBValid || !lRoughageROBValid || !lPenSpaceValid || !lBeddingLoadingValid || !lBeddingROBValid
                || !lBeddingIssuesValid || !lBeddingUseageValid || !lHealthAndWelfareIssuesValid || !lOtherHealthAndWelfareIssuesValid || !lAggressiveAnimalsValid
                || !lDischargeConcernsValid || !lDeclarationValid) {
                lValid = false;
            }
            setPelletsROBValid(lPelletsROBValid);
            setGrainROBValid(lGrainROBValid);
            setRoughageROBValid(lRoughageROBValid);
            setPenSpaceAllowanceValid(lPenSpaceValid);
            setBeddingLoadedValid(lBeddingLoadingValid);
            setBeddingROBValid(lBeddingROBValid);
            setBeddingIssuesValid(lBeddingIssuesValid);
            setBeddingUseValid(lBeddingUseageValid);
            setUnexpectedHealthValid(lHealthAndWelfareIssuesValid);
            setOtherHealthValid(lOtherHealthAndWelfareIssuesValid);
            setDominantAnimalsValid(lAggressiveAnimalsValid);
            setDischargeConcernsValid(lDischargeConcernsValid);
            setDeclarationStatementValid(lDeclarationValid);

            return lValid;
        }

        e.preventDefault();
        setHasSaveError(false);
        setHasSaveSuccess(false);

        let lValid = validate();

        if (lValid === true) {
            setEditModalShow(true);
        }
        else {
            setEditModalShow(false);
            setHasSaveError(true);
            setHasSaveErrorMessage("Unable to save end of voyage report. Please address any validation issues.");
        }
    }

    // at this point the page should have already been validated
    const save = () => {
        const writeToDb = async () => {
            setEditModalShow(false);

            // create object to save
            let lObjToSave = new EndOfVoyageReportSave(endOfVoyageReportID, consignmentID, feedROBID, feedAvailabilityComment, isAnimalRestSpaceAdequate, animalRestSpaceComment,
                beddingLoaded, beddingROB, isBeddingIssues, beddingIssuesComment, beddingUsedLoadingAndSlipperyAreas, beddingUsedManagaementPlans,
                beddingUsedMitigationHealthConcerns, beddingUsedAfterWashDown, beddingUsedHospitalPens, beddingUsedNotUsed, isUnexpectedHealthIssues, unexpectedHealthIssuesComment,
                isOtherHealthIssues, otherHealthIssuesComment, isAggressiveAnimalIssues, aggressiveAnimalIssuesComment, isDischargeConcerns, dischargeConcernsComment,
                isAgreeWithDeclaration, declarationComment, pelletsROB, grainROB, roughageROB);

            let lResult = await patchEndOfVoyageReport(lObjToSave);
            if (lResult?.status === 200) {
                setHasSaveSuccess(true);
                setTimeout(() => {
                    setHasSaveSuccess(false);
                }, 15000);
                setIsEditMode(false);
            }
            else {
                setHasSaveError(true);
                setHasSaveErrorMessage("Something went wrong while trying to save.");
            }
        }
        writeToDb();
    }

    return (
        <LoggedInContainer>
            <Container fluid className="p-0" style={{ backgroundColor: "#F5F5F5" }}>
                <Row className="upper-panel mx-0" style={{ position: "relative" }}>
                    {
                        mediaQueryCtx.mobile === true ? (
                            <Col xs={12}>
                                <Container>
                                    <Row style={{ marginTop: "36px" }}>

                                        <Col xs={12}>
                                            <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                                <div style={{ lineHeight: "53px", fontSize: "35px" }}>End of Voyage Report</div>
                                                {
                                                    loading === false ?
                                                        <div>LNC- {lncNumber}</div>
                                                        : null
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={isEditMode === false && loading === false ? 6 : 12} className="mt-3">
                                            <Button className="w-100" variant="back" onClick={() => goBack()}>
                                                <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                            </Button>
                                        </Col>
                                        
                                        {
                                            isEditMode === false && loading === false ?
                                            (
                                                <Col xs={6} style={{ textAlign: "right" }} className="mt-3">
                                                    <ReadWriteButton  className="w-100" writeonly={true} variant="back" onClick={() => setIsEditMode(true)}>
                                                        Edit End of Voyage
                                                    </ReadWriteButton>
                                                </Col>
                                            )
                                            : null
                                        }
                                    </Row>
                                </Container>
                            </Col>
                        ) : (
                            <Col xs={12}>
                                <Container>
                                    <Row style={{ marginTop: "65px" }}>
                                        <Col xs={2}>
                                            <Button variant="back" onClick={() => goBack()}>
                                                <FontAwesomeIcon icon={faCaretLeft} style={{ marginRight: "5px" }} />Back
                                            </Button>
                                        </Col>
                                        <Col>
                                            <div className="text-center heading-text mb-2 mt-3" style={{ color: "#FFF", display: "inline" }}>
                                                <div style={{ lineHeight: "53px", fontSize: "35px" }}>End of Voyage Report</div>
                                                {
                                                    loading === false ?
                                                        <div>LNC- {lncNumber}</div>
                                                        : null
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={2} style={{ textAlign: "right" }}>
                                            {
                                                isEditMode === false && loading === false ?
                                                    <ReadWriteButton writeonly={true} variant="back" onClick={() => setIsEditMode(true)}>
                                                        Edit End of Voyage
                                                    </ReadWriteButton>
                                                    : null
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        )

                    }
                    
                </Row>
                <Container className="py-4 px-5 form-container-full" style={{marginTop: mediaQueryCtx.mobile === true ? "45px" : "inherit"}}>
                    {
                        loading === true ? (
                            <Row>
                                <Col>
                                    <div className="text-center pt-5">
                                        <Spinner className="mx-auto" />
                                        <div className="mx-auto">Loading data...</div>
                                    </div>
                                </Col>
                            </Row>
                        ) : null
                    }
                    {
                        hasData === true && loading === false ?
                            <>
                                <Row className='mb-4'>
                                    <Col xs={12}>
                                        <div className="green-underline-heading">Actual Voyage Length</div>
                                    </Col>
                                    <Col xs={12} className='text-13-400'>
                                        {voyageLength}
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col xs={12}>
                                        <Row>
                                            <Col>
                                                <div className="green-underline-heading">Feed</div>
                                            </Col>
                                        </Row>
                                        <Row className='pb-4'>
                                            <Col xs={12} lg={6}>
                                                <Row className='mb-2'>
                                                    <Col><div className="field-label">Feed ROB at end of voyage</div></Col>
                                                </Row>
                                                <Row className="field-label">
                                                    <Col xs={2}>Pellets</Col>
                                                    <Col xs={2}>Grain</Col>
                                                    <Col xs={2}>Roughage</Col>
                                                </Row>
                                                <Row className='text-13-400 secondary-grey pt-2 pb-2'>
                                                    <Col xs={2} className='text-end'>
                                                        {
                                                            isEditMode === true ?
                                                                <Form.Group id={`pelletsROB`}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            value={pelletsROB}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                                setPelletsROBValid(true);
                                                                                setPelletsROB(e.target.value);
                                                                            }}
                                                                            className="inputGroupWithAppend errorLeft"
                                                                            isInvalid={!pelletsROBValid}
                                                                        />
                                                                        <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!pelletsROBValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                                        {
                                                                            pelletsROBValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#pelletsROB`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A valid pellets ROB is requried."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                :
                                                                <div style={{ marginRight: "37px" }}>{pelletsROB}t</div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className='text-end'>
                                                        {
                                                            isEditMode === true ?
                                                                <Form.Group id={`grainROB`}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            value={grainROB}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                                setGrainROBValid(true);
                                                                                setGrainROB(e.target.value);
                                                                            }}
                                                                            className="inputGroupWithAppend errorLeft"
                                                                            isInvalid={!grainROBValid}
                                                                        />
                                                                        <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!grainROBValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                                        {
                                                                            grainROBValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#grainROB`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A valid grain ROB is requried."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                :
                                                                <div style={{ marginRight: "45px" }}>{grainROB}t</div>
                                                        }
                                                    </Col>
                                                    <Col xs={2} className='text-end'>
                                                        {
                                                            isEditMode === true ?
                                                                <Form.Group id={`roughageROB`}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            value={roughageROB}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                parseNumericInput(e, new FeedNumberValidationRules());
                                                                                setRoughageROBValid(true);
                                                                                setRoughageROB(e.target.value);
                                                                            }}
                                                                            className="inputGroupWithAppend errorLeft"
                                                                            isInvalid={roughageROBValid === false}
                                                                        />
                                                                        <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!roughageROBValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                                        {
                                                                            roughageROBValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#roughageROB`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A valid roughage ROB is requried."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                :
                                                                <div style={{ marginRight: "15px" }}>{roughageROB}t</div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Please comment on feed availability and trough space.</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12}>
                                                        {
                                                            isEditMode ?
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={3}
                                                                    value={feedAvailabilityComment}
                                                                    onChange={(e) => setFeedAvailabilityComment(e.target.value)}
                                                                />
                                                                :
                                                                <div>
                                                                    {
                                                                        !StringIsNullUndefinedOrEmpty(feedAvailabilityComment) ?
                                                                            <div>{feedAvailabilityComment}</div>
                                                                            : <div>No Comment</div>
                                                                    }
                                                                </div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col xs={12}>
                                        <Row>
                                            <Col><div className="green-underline-heading">Rest and Bedding</div></Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Was the pen space allowance suitable to allow animals to rest?</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isAnimalRestSpaceAdequate === true} className="text-13-400 secondary-grey"
                                                                        id="rdoSuitableRestSpace_Yes"
                                                                        onChange={() => {
                                                                            setPenSpaceAllowanceValid(true);
                                                                            setIsAnimalRestSpaceAdequate(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isAnimalRestSpaceAdequate === false} className="text-13-400 secondary-grey"
                                                                        id="rdoSuitableRestSpace_No"
                                                                        onChange={() => {
                                                                            setPenSpaceAllowanceValid(true);
                                                                            setIsAnimalRestSpaceAdequate(false);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div>{isAnimalRestSpaceAdequate === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isAnimalRestSpaceAdequate !== true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode ?
                                                                        <Form.Group id={`penSpaceAllowanceComment`}>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={animalRestSpaceComment}
                                                                                onChange={(e) => {
                                                                                    setPenSpaceAllowanceValid(true);
                                                                                    setAnimalRestSpaceComment(e.target.value);
                                                                                }}
                                                                                isInvalid={!penSpaceAllowanceValid}
                                                                            />
                                                                            {
                                                                                penSpaceAllowanceValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#penSpaceAllowanceComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is required."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>
                                                                        : <div>{animalRestSpaceComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col xs={12} lg={6}>
                                                <Row className="field-label">
                                                    <Col xs={3}>Bedding Loaded</Col>
                                                    <Col xs={3}>Bedding ROB</Col>
                                                </Row>

                                                <Row key={`beddingTable`} className='text-13-400 secondary-grey pt-2 pb-2'>
                                                    <Col xs={3} className='text-end'>
                                                        {
                                                            isEditMode === true ?
                                                                <Form.Group id={`beddingLoaded`}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            value={beddingLoaded}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                parseNumericInput(e, decimalValidationRules);
                                                                                setBeddingLoadedValid(true);
                                                                                setBeddingLoaded(e.target.value);
                                                                            }}
                                                                            className="inputGroupWithAppend errorLeft"
                                                                            isInvalid={!beddingLoadedValid}
                                                                        />
                                                                        <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!beddingLoadedValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                                        {
                                                                            beddingLoadedValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#beddingLoaded`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A valid bedding loaded is required."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                :
                                                                <div style={{ marginRight: "30px" }}>{beddingLoaded}t</div>
                                                        }
                                                    </Col>
                                                    <Col xs={3} className='text-end'>
                                                        {
                                                            isEditMode === true ?
                                                                <Form.Group id={`beddingROB`}>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            value={beddingROB}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                parseNumericInput(e, decimalValidationRules);
                                                                                setBeddingROBValid(true);
                                                                                setBeddingROB(e.target.value);
                                                                            }}
                                                                            className="inputGroupWithAppend errorLeft"
                                                                            isInvalid={!beddingROBValid}
                                                                        />
                                                                        <InputGroup.Text className={`inputGroupAppend text-13-400 secondary-grey ${!beddingROBValid ? "invalidAppend" : ""}`}>t</InputGroup.Text>
                                                                        {
                                                                            beddingROBValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#beddingROB`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A valid bedding ROB is requried."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </InputGroup>
                                                                </Form.Group>
                                                                :
                                                                <div style={{ marginRight: "50px" }}>{beddingROB}t</div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Was there any bedding issues?</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isBeddingIssues === true} className="text-13-400 secondary-grey"
                                                                        id="rdoBeddingIssues_Yes"
                                                                        onChange={() => {
                                                                            setIsBeddingIssues(true);
                                                                            setBeddingIssuesValid(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isBeddingIssues === false} className="text-13-400 secondary-grey"
                                                                        id="rdoBeddingIssuesNo_No"
                                                                        onChange={() => {
                                                                            setIsBeddingIssues(false);
                                                                            setBeddingIssuesValid(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div> {isBeddingIssues === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isBeddingIssues === true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode === true ?
                                                                        <Form.Group id={`beddingIssuesComment`}>

                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={beddingIssuesComment}
                                                                                onChange={(e) => {
                                                                                    setBeddingIssuesComment(e.target.value);
                                                                                    setBeddingIssuesValid(true);
                                                                                }}
                                                                                isInvalid={!beddingIssuesValid}
                                                                            />
                                                                            {
                                                                                beddingIssuesValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#beddingIssuesComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is requried."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>
                                                                        :
                                                                        <div>{beddingIssuesComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>How was bedding used for consignment?</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    {
                                                        beddingUseValid === false ?
                                                            <Col xs={12} className='pb-2'>
                                                                <Stack direction='horizontal' gap={2}>
                                                                    <FontAwesomeIcon icon={faCircleExclamation} color="#dc3545" data-tooltip-id="beddingUseageTooltip" data-tooltip-content={'You must select either "Bedding Not Used" or at least one of the other options'} />
                                                                </Stack>

                                                                <div className="validation-error-tooltip">
                                                                    <Tooltip id="beddingUseageTooltip" variant="error" />
                                                                </div>
                                                            </Col>
                                                            : null
                                                    }
                                                    <Col xs={12}>
                                                        {
                                                            isEditMode === true ?
                                                                <Stack>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_SlipperyAreas`}
                                                                                checked={beddingUsedLoadingAndSlipperyAreas === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedLoadingAndSlipperyAreas(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="Loading and slippery areas"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_ManagementPlans`}
                                                                                checked={beddingUsedManagaementPlans === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedManagaementPlans(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="Management plans or AEP"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_MitigationHealthConcerns`}
                                                                                checked={beddingUsedMitigationHealthConcerns === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedMitigationHealthConcerns(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="Mitigation of health concerns"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_AfterWashDown`}
                                                                                checked={beddingUsedAfterWashDown === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedAfterWashDown(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="After each wash down"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_HospitalPens`}
                                                                                checked={beddingUsedHospitalPens === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedHospitalPens(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="In hospital pens"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                    <div>
                                                                        <Form.Group>
                                                                            <Form.Check id={`bedding_NotUsed`}
                                                                                checked={beddingUsedNotUsed === true}
                                                                                onChange={(e) => {
                                                                                    setBeddingUsedNotUsed(e.target.checked);
                                                                                    setBeddingUseValid(true);
                                                                                }}
                                                                                label="Bedding not used"
                                                                            />
                                                                        </Form.Group>
                                                                    </div>
                                                                </Stack>
                                                                :
                                                                <ul>
                                                                    {
                                                                        beddingUsedLoadingAndSlipperyAreas === true ?
                                                                            <li>Loading and slippery areas</li> : null
                                                                    }
                                                                    {
                                                                        beddingUsedManagaementPlans === true ?
                                                                            <li>Management plans or AEP</li> : null
                                                                    }
                                                                    {
                                                                        beddingUsedMitigationHealthConcerns === true ?
                                                                            <li>Mitigation of health concerns</li> : null
                                                                    }
                                                                    {
                                                                        beddingUsedAfterWashDown === true ?
                                                                            <li>After each wash down</li> : null
                                                                    }
                                                                    {
                                                                        beddingUsedHospitalPens === true ?
                                                                            <li>In hospital pens</li> : null
                                                                    }
                                                                    {
                                                                        beddingUsedNotUsed === true ?
                                                                            <li>Bedding not used</li> : null
                                                                    }
                                                                </ul>
                                                        }
                                                    </Col>
                                                </Row>

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col xs={12}>
                                        <Row>
                                            <Col><div className="green-underline-heading">Health and Welfare</div></Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Were there any unexpected health or welfare issues?</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isUnexpectedHealthIssues === true} className="text-13-400 secondary-grey"
                                                                        id="rdoHealthIssue_Yes"
                                                                        onChange={() => {
                                                                            setIsUnexpectedHealthIssues(true);
                                                                            setUnexpectedHealthValid(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isUnexpectedHealthIssues === false} className="text-13-400 secondary-grey"
                                                                        id="rdoHealthIssue_No"
                                                                        onChange={() => {
                                                                            setIsUnexpectedHealthIssues(false);
                                                                            setUnexpectedHealthValid(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div>{isUnexpectedHealthIssues === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isUnexpectedHealthIssues === true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode === true ?
                                                                        <Form.Group id={`healthIssuesComment`}>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={unexpectedHealthIssuesComment}
                                                                                onChange={(e) => {
                                                                                    setUnexpectedHealthIssuesComment(e.target.value)
                                                                                    setUnexpectedHealthValid(true);
                                                                                }}
                                                                                isInvalid={!unexpectedHealthValid}
                                                                            />
                                                                            {
                                                                                unexpectedHealthValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#healthIssuesComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is requried."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>
                                                                        :
                                                                        <div>{unexpectedHealthIssuesComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-4'>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Were any other health or welfare issues identified?</div>
                                            </Col>
                                            <Col xs={12}>

                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isOtherHealthIssues === true} className="text-13-400 secondary-grey"
                                                                        id="rdoOtherHealthIssue_Yes"
                                                                        onChange={() => {
                                                                            setIsOtherHealthIssues(true);
                                                                            setOtherHealthValid(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isOtherHealthIssues === false} className="text-13-400 secondary-grey"
                                                                        id="rdoOtherHealthIssue_No"
                                                                        onChange={() => {
                                                                            setIsOtherHealthIssues(false);
                                                                            setOtherHealthValid(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div>{isOtherHealthIssues === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isOtherHealthIssues === true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode === true ?
                                                                        <Form.Group id={`otherHealthComment`}>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={otherHealthIssuesComment}
                                                                                onChange={(e) => {
                                                                                    setOtherHealthIssuesComment(e.target.value);
                                                                                    setOtherHealthValid(true);
                                                                                }}
                                                                                isInvalid={!otherHealthValid}
                                                                            />
                                                                            {
                                                                                otherHealthValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#otherHealthComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is requried."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>

                                                                        :
                                                                        <div>{otherHealthIssuesComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Were there any issues with dominant or aggressive animals?</div>
                                            </Col>
                                            <Col xs={12}>
                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isAggressiveAnimalIssues === true} className="text-13-400 secondary-grey"
                                                                        id="rdoAggressiveAnimals_Yes"
                                                                        onChange={() => {
                                                                            setIsAggressiveAnimalIssues(true);
                                                                            setDominantAnimalsValid(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isAggressiveAnimalIssues === false} className="text-13-400 secondary-grey"
                                                                        id="rdoAggressiveAnimals_No"
                                                                        onChange={() => {
                                                                            setIsAggressiveAnimalIssues(false);
                                                                            setDominantAnimalsValid(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div>{isAggressiveAnimalIssues === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isAggressiveAnimalIssues === true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode === true ?
                                                                        <Form.Group id={`aggressiveAnimalComment`}>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={aggressiveAnimalIssuesComment}
                                                                                onChange={(e) => {
                                                                                    setAggressiveAnimalIssuesComment(e.target.value)
                                                                                    setDominantAnimalsValid(true);
                                                                                }}
                                                                                isInvalid={!dominantAnimalsValid}
                                                                            />
                                                                            {
                                                                                dominantAnimalsValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#aggressiveAnimalComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is requried."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>
                                                                        :
                                                                        <div>{aggressiveAnimalIssuesComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className='pb-2'>
                                                <div className='field-label'>Do you have any concerns with discharge?</div>
                                            </Col>
                                            <Col xs={12} className='no-bottom-padding-last-child'>

                                                <Row className='text-13-400 secondary-grey pb-3'>
                                                    <Col xs={12} className='pb-2'>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <Form.Check type={`radio`} label={`Yes`} checked={isDischargeConcerns === true} className="text-13-400 secondary-grey"
                                                                        id="rdoDischargeConcerns_Yes"
                                                                        onChange={() => {
                                                                            setIsDischargeConcerns(true);
                                                                            setDischargeConcernsValid(true);
                                                                        }}
                                                                    />
                                                                    <Form.Check type={`radio`} label={`No`} checked={isDischargeConcerns === false} className="text-13-400 secondary-grey"
                                                                        id="rdoDischargeConcerns_No"
                                                                        onChange={() => {
                                                                            setIsDischargeConcerns(false);
                                                                            setDischargeConcernsValid(true);
                                                                        }}
                                                                    />
                                                                </div>
                                                                :
                                                                <div>{isDischargeConcerns === true ? "Yes" : "No"}</div>
                                                        }
                                                    </Col>
                                                    {
                                                        isDischargeConcerns === true ?
                                                            <Col xs={12}>
                                                                {
                                                                    isEditMode === true ?
                                                                        <Form.Group id={`dischargeConcernsComment`}>
                                                                            <Form.Control
                                                                                as="textarea"
                                                                                rows={3}
                                                                                value={dischargeConcernsComment}
                                                                                onChange={(e) => {
                                                                                    setDischargeConcernsComment(e.target.value)
                                                                                    setDischargeConcernsValid(true);
                                                                                }}
                                                                                isInvalid={!dischargeConcernsValid}
                                                                            />
                                                                            {
                                                                                dischargeConcernsValid === false ? (
                                                                                    <div className="validation-error-tooltip">
                                                                                        <Tooltip
                                                                                            anchorSelect={`#dischargeConcernsComment`}
                                                                                            place="top"
                                                                                            variant="error"
                                                                                            content="A comment is requried."
                                                                                        />
                                                                                    </div>
                                                                                ) : null
                                                                            }
                                                                        </Form.Group>
                                                                        :
                                                                        <div>{dischargeConcernsComment}</div>
                                                                }
                                                            </Col>
                                                            : null
                                                    }
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row className='mb-4'>
                                    <Col xs={12}>
                                        <Row>
                                            <Col><div className="green-underline-heading">Voyage summary statement</div></Col>
                                        </Row>
                                        <Row className='mb-3'>
                                            <Col>
                                                <div className='field-label'>There were no relationship issues with or between personnel impacting the management of animal health and welfare. There were no feed (quality/quantity), water (quality/supply) or ventilation issues that have not been reported on.</div>
                                            </Col>
                                        </Row>

                                        <Row className='text-13-400 secondary-grey pb-3'>
                                            <Col xs={12} className='pb-2'>
                                                {
                                                    isEditMode === true ?
                                                        <div>
                                                            <Form.Check type={`radio`} label={`I agree with the above`} checked={isAgreeWithDeclaration === true} className="text-13-400 secondary-grey"
                                                                id="rdoDeclaration_Yes"
                                                                onChange={() => {
                                                                    setIsAgreeWithDeclaration(true);
                                                                    setDeclarationStatementValid(true);
                                                                }}
                                                            />
                                                            <Form.Check type={`radio`} label={`I disagree with the above`} checked={isAgreeWithDeclaration === false} className="text-13-400 secondary-grey"
                                                                id="rdoDeclaration_No"
                                                                onChange={() => {
                                                                    setIsAgreeWithDeclaration(false);
                                                                    setDeclarationStatementValid(true);
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div> {isAgreeWithDeclaration === true ? "I agree with this statement" : "I disagree with this statement"}</div>
                                                }

                                            </Col>
                                            {
                                                isAgreeWithDeclaration !== true ?
                                                    <Col xs={12}>
                                                        {
                                                            isEditMode === true ?
                                                                <div>
                                                                    <div className='field-label pb-2'>Enter details of issues experienced (min 30 characters)</div>
                                                                    <Form.Group id={`declarationComment`}>
                                                                        <Form.Control
                                                                            as="textarea"
                                                                            rows={3}
                                                                            value={declarationComment}
                                                                            onChange={(e) => {
                                                                                setDeclarationStatementValid(true);
                                                                                setDeclarationComment(e.target.value);
                                                                            }}
                                                                            isInvalid={!declarationStatementValid}
                                                                        />
                                                                        {
                                                                            declarationStatementValid === false ? (
                                                                                <div className="validation-error-tooltip">
                                                                                    <Tooltip
                                                                                        anchorSelect={`#declarationComment`}
                                                                                        place="top"
                                                                                        variant="error"
                                                                                        content="A comment with at least 30 characters is required."
                                                                                    />
                                                                                </div>
                                                                            ) : null
                                                                        }
                                                                    </Form.Group>
                                                                </div>
                                                                :
                                                                <div>{declarationComment}</div>
                                                        }
                                                    </Col>
                                                    : null
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                            : null
                    }
                    {
                        loadError === true && loading === false ?
                            <Container>
                                <Row>
                                    <Col xs={12}>
                                        <Alert variant='danger'>Unable to load end of voyage report.</Alert>
                                    </Col>
                                    <Col xs={12}>
                                        <Button className="me-2 ms-auto" variant="primary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>Back</Button>
                                    </Col>
                                </Row>
                            </Container>
                            : null
                    }
                </Container>

                {
                    loading === false && hasData === true ?
                        <Row className='mt-5 mx-0' style={{ position: "relative", top: "-300px" }}>
                            {
                                saveSuccess === true ?
                                    <Col xs={12}>
                                        <Container>
                                            <Row>
                                                <Col xs={12}>
                                                    <Alert variant='success'>End of Voyage report updated successfully.</Alert>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    : null
                            }

                            {
                                saveError === true ?
                                    <Col xs={12}>
                                        <Container>
                                            <Row>
                                                <Col xs={12}>
                                                    <Alert variant='danger'>{saveErrorMessage}</Alert>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                    : null
                            }

                            {
                                isEditMode === true ?
                                    <Col xs={12}>
                                        <Stack direction="horizontal" gap={2} style={{ justifyContent: "center" }}>
                                            <Button variant="secondary" onClick={() => { cancelEdit() }} size="lg" style={{ minWidth: "150px" }}>Cancel</Button>
                                            <ReadWriteButton writeonly={true} variant="primary" onClick={(e) => { validateAndShowEditModal(e) }} size="lg" style={{ minWidth: "150px" }}>Update</ReadWriteButton>
                                        </Stack>
                                    </Col>
                                    :
                                    <Col xs={12}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Button variant="primary" onClick={() => { goBack() }} size="lg" style={{ minWidth: "150px" }}>Done</Button>
                                        </div>
                                    </Col>
                            }
                        </Row>
                        :
                        null
                }
            </Container>
            <EditLogModal
                title="Edit End of Voyage Report"
                page="End of Voyage Report"
                userID={userCtx.user.id}
                consignmentIDs={[consignmentID]}
                showModal={editModalShow}
                reasonOptions={editPageReasons}
                onClose={onEditModalClosed}
                onSaveSuccess={onEditModalSaveSuccess}
                onSaveFail={onEditModalSaveFailed}
            />
        </LoggedInContainer>
    );
};

export default End_Of_Voyage_Report;