import React, { useId } from "react";
import { useState, useEffect } from "react";
import { Container, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { getFeedRemainingDetailsForConsignmentID, getInitialFeedPlannedForConsignmentID } from "../../api/apiAccess";
import { formatUpToSpecifiedPrecision } from "../../utils/common";

const FeedRemainingOverview = ({ consignment, isStarted, index }) => {
    const componentId = useId();
    const [pellets, setPellets] = useState("0");
    const [grain, setGrain] = useState("0");
    const [roughage, setRoughage] = useState("0");
    const [hideDailyChange, setHideDailyChange] = useState(false);
    const [dailyChangePellets, setDailyChangePellets] = useState(0);
    const [dailyChangeGrain, setDailyChangeGrain] = useState(0);
    const [dailyChangeRoughage, setDailyChangeRoughage] = useState(0);

    useEffect(() => {
        if (consignment === undefined) return;

        const fetchInitialFeedPlanned = async () => {

            let results = await getInitialFeedPlannedForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setPellets(results.data.pellets);
                setGrain(results.data.grain);
                setRoughage(results.data.roughage);
                setHideDailyChange(true);
            }
        }

        const fetchFeedRemainingDetails = async () => {
            let results = await getFeedRemainingDetailsForConsignmentID(consignment.id);
            if (results?.status === 200) {
                setPellets(results.data.rob.pellets);
                setGrain(results.data.rob.grain);
                setRoughage(results.data.rob.roughage);
                setDailyChangePellets((results.data.rob.pellets - results.data.previous.pellets) ?? 0);
                setDailyChangeGrain((results.data.rob.grain - results.data.previous.grain) ?? 0);
                setDailyChangeRoughage((results.data.rob.roughage - results.data.previous.roughage) ?? 0);
            }
        }

        if (!isStarted) {
            fetchInitialFeedPlanned();
        } else {
            fetchFeedRemainingDetails();
        }
    }, [consignment]);

    return (
        <Container fluid key={`feed_remaining_overview_${componentId}_${index}`} className="my-auto mx-0 px-auto w-100">
            <Stack direction="vertical" className="w-100">
                <Stack direction="horizontal" className="w-100">
                    <Stack className="text-center w-33" direction="vertical" style={{width: "33%"}}>
                        <div>{formatUpToSpecifiedPrecision(pellets, 2)}t</div>
                        <div className="field-label">Pellets</div>
                        {
                            !hideDailyChange ? (
                                dailyChangePellets !== 0 ?
                                    (
                                        <div className="field-label" style={{ fontSize: "11px" }}><FontAwesomeIcon icon={dailyChangePellets < 0 ? faCaretDown : faCaretUp} className={dailyChangePellets < 0 ? "error-red" : "primary-green"} />{Math.abs(formatUpToSpecifiedPrecision(dailyChangePellets, 2))}t</div>
                                    ) :
                                    (
                                        <div className="field-label" style={{ fontSize: "11px" }}>{formatUpToSpecifiedPrecision(dailyChangePellets, 2)}t</div>
                                    )
                            ) : null
                        }
                    </Stack>
                    <Stack className="text-center mx-auto w-33" direction="vertical"  style={{width: "33%"}}>
                        <div>{formatUpToSpecifiedPrecision(grain, 2)}t</div>
                        <div className="field-label">Grain</div>
                        {
                            !hideDailyChange ? (
                                dailyChangeGrain !== 0 ?
                                    (
                                        <div className="field-label" style={{ fontSize: "11px" }}><FontAwesomeIcon icon={dailyChangeGrain < 0 ? faCaretDown : faCaretUp} className={dailyChangeGrain < 0 ? "error-red" : "primary-green"} />{Math.abs(formatUpToSpecifiedPrecision(dailyChangeGrain, 2))}t</div>
                                    ) :
                                    (
                                        <div className="field-label" style={{ fontSize: "11px" }}>{formatUpToSpecifiedPrecision(dailyChangeGrain, 2)}t</div>
                                    )
                            ) : null
                        }
                    </Stack>
                    <Stack className="text-center" direction="vertical" style={{width: "33%"}}>
                        <div>{formatUpToSpecifiedPrecision(roughage, 2)}t</div>
                        <div className="field-label">Roughage</div>
                        {
                            !hideDailyChange ? (
                                dailyChangeRoughage !== 0 ?
                                    (
                                        <div className="field-label" style={{ fontSize: "11px" }}><FontAwesomeIcon icon={dailyChangeRoughage < 0 ? faCaretDown : faCaretUp} className={dailyChangeRoughage < 0 ? "error-red" : "primary-green"} />{Math.abs(formatUpToSpecifiedPrecision(dailyChangeRoughage, 2))}t</div>
                                    ) : (
                                        <div className="field-label" style={{ fontSize: "11px" }}>{formatUpToSpecifiedPrecision(dailyChangeRoughage, 2)}t</div>
                                    )
                            ) : null
                        }
                    </Stack>
                </Stack>
            </Stack>
        </Container>
    )
};

export default FeedRemainingOverview;