import '../css/loggedincontainer.css';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Logo from "../assets/LiveCorp_Logo_Horizontal.svg";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Dropdown, Stack } from "react-bootstrap";
import { MediaQueryContext, UserContext } from '..';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearLoggedInUser, getSessionLoggedInUser } from '../helpers/SessionHelper';
import { clearAPICache } from '../api/apiagent';

const LoggedInContainer = ({ children }) => {
    const mediaQueryCtx = useContext(MediaQueryContext);
    const navigate = useNavigate();
    const userCtx = useContext(UserContext);
    const [user, setUser] = useState({});

    useEffect(() => {
        setUser( userCtx?.user);
    }, [userCtx.user]);
    
    const doLogout = () => {
        clearAPICache();
        clearLoggedInUser();
        let user = getSessionLoggedInUser();
        userCtx.setUser(user);
        navigate("/");
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Stack ref={ref} direction='horizontal' gap={2}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}>
            {children}
            <FontAwesomeIcon icon={faCaretDown} style={{ fontSize: mediaQueryCtx.mobile === true ? "18px" : "24px" }} />
        </Stack>
    ));

    return (
        <Container fluid className="p-0 m-0">
            <Row className="header-bar m-0">
                <Col>
                    <Row className='px-4'>
                        <Col>
                            <Row className="header-bar-content-container">
                                <Col xs={12}>
                                    <img
                                        style={{width: mediaQueryCtx.mobile === true ? "130px" : "200px"}}
                                        src={Logo} 
                                        className="header-logo" 
                                        onClick={() => navigate("/dashboard")}
                                    />
                                    {
                                        process.env.REACT_APP_ENV === "TEST" ?
                                        <span style={{color:"red", fontWeight:"bold"}}>TEST</span>
                                        : null
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className="px-4 py-0 m-0 header-logged-in-user-container float-end">
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle}>
                            <span style={{fontSize: mediaQueryCtx.mobile === true ? "13px" : "unset"}}>Logged in as: {`${user?.firstName} ${user?.lastName}`}</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="/settings">Settings</Dropdown.Item>
                            {
                                user?.isOrgAdmin ?
                                    <>
                                        <Dropdown.Item href="/admintools">Admin Tools</Dropdown.Item>
                                    </>
                                    : null
                            }
                            <Dropdown.Item onClick={doLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            {children}
        </Container>
    )
};

export default LoggedInContainer;