import 'react-tooltip/dist/react-tooltip.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Button, Modal, Form, Stack, Alert, ListGroup } from "react-bootstrap";
import { getAllExporters } from '../../api/apiAccess';
import { StringIsNullUndefinedOrEmpty } from '../../utils/StringUtils';
import { Tooltip } from 'react-tooltip';
import { NumericValidationRules, parseNumericInput, isValidEmail, isValidMobile } from '../../utils/InputValidator';
import { patchLiveCorpUser, postLiveCorpUser, patchRegulatoryUser, postRegulatoryUser, patchExporterUser, postExporterUser } from '../../api/apiAccess';
import { UserContext } from '../..';
import { updateLoggedInUser } from '../../helpers/SessionHelper';

const LIVEXCollect_Add_Edit_Modal = ({ managementUser, showModal, setShowModal, onAdd, accountTypes }) => {
    const userCtx = useContext(UserContext);

    const [isEditingLoggedInUser, setIsEditingLoggedInUser] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [headerText, setHeaderText] = useState("Add New User");

    const [companyOptions, setCompanyOptions] = useState([]);

    const [id, setID] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [accountTypeID, setAccountTypeID] = useState(1);
    const [companyID, setCompanyID] = useState("");
    const [permissionLevel, setPermissionLevel] = useState(false);
    const [canWrite, setCanWrite] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [mobilePhone, setMobilePhone] = useState("");
    const [isActive, setIsActive] = useState(true);

    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhone, setValidPhone] = useState(true);

    const [showCompanyInput, setShowCompanyInput] = useState(false);

    const numericValidationRules = new NumericValidationRules({ allowLeadingZerosForWholeNumbers: true, allowNegative: false, allowDecimal: false });

    useEffect(() => {
        clearCurrentErrors();
        if (managementUser) { // edit
            setIsEditMode(true);
            setHeaderText("View/Edit User");

            setID(managementUser.id);
            setFirstName(managementUser.firstName);
            setLastName(managementUser.lastName);
            setAccountTypeID(managementUser.userTypeID);
            setCompanyID(managementUser.exporterID);
            setPermissionLevel(managementUser.isOrgAdmin);
            setCanWrite(managementUser.canWrite);
            setEmailAddress(managementUser.emailAddress);
            setMobilePhone(managementUser.phoneNumber);
            setIsActive(managementUser.isActive);
        }
        else { // add
            setIsEditMode(false);
            setHeaderText("Add New User");

            setID(null);
            setFirstName("");
            setLastName("");
            setAccountTypeID(1);
            setCompanyID("");
            setPermissionLevel(false);
            setCanWrite(false);
            setEmailAddress("");
            setMobilePhone("");
            setIsActive(true);
        }
    }, [managementUser]);

    useEffect(() => {
        const fetchAllExporters = async () => {
            let lResponse = await getAllExporters();
            if (lResponse?.status === 200) {
                let lActive = lResponse.data.filter(e => e.isActive === true);
                setCompanyOptions(lActive);
                if (lActive !== null && lActive.length > 0) {
                    setCompanyID(lActive[0].id);
                }
            }
            else {
                setCompanyOptions([]);
            }
        }
        fetchAllExporters();
    }, []);

    useEffect(() => {
        if (Number(accountTypeID) === 1) { // exporter
            setShowCompanyInput(true);
        }
        else { // regulator or LiveCorp
            setShowCompanyInput(false);
            setCanWrite(false);
        }
    }, [accountTypeID]);

    useEffect(() => {
        if (id === userCtx?.user?.id) {
            setIsEditingLoggedInUser(true);
        }
        else {
            setIsEditingLoggedInUser(false);
        }
    }, [id, userCtx?.user?.id]);

    const clearCurrentErrors = () => {
        setShowErrorMessage(false);
        setValidFirstName(true);
        setValidLastName(true);
        setValidEmail(true);
        setValidPhone(true);
    }

    const save = () => {
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        setErrorMessage("");
        setValidFirstName(false);
        setValidLastName(false);
        setValidEmail(false);
        setValidPhone(false);

        const clearInputs = () => {
            setFirstName("");
            setLastName("");
            setPermissionLevel(false);
            setCanWrite(false);
            setEmailAddress("");
            setMobilePhone("");
            setIsActive(true);
        }

        const saveAndValidate = async () => {
            // validate
            let lValidFirstName = !StringIsNullUndefinedOrEmpty(firstName);
            let lValidLastName = !StringIsNullUndefinedOrEmpty(lastName);
            let lValidEmail = isValidEmail(emailAddress);
            let lValidPhoneNumber = isValidMobile(mobilePhone);

            setValidFirstName(lValidFirstName);
            setValidLastName(lValidLastName);
            setValidEmail(lValidEmail);
            setValidPhone(lValidPhoneNumber);
            if (lValidFirstName && lValidLastName && lValidEmail && lValidPhoneNumber) {
                let lMode = "add new";
                if(isEditMode){
                    lMode = "edit";
                }

                // parse any boolean values from string to boolean, otherwise we get a bad request when calling the api
                let lPermissionLevel = JSON.parse(permissionLevel);
                let lCanWrite = JSON.parse(canWrite);
                let lIsActive = JSON.parse(isActive);
                
                // save
                let result;
                switch (Number(accountTypeID)) {
                    case 1:
                        if (isEditMode === true) {
                            result = await patchExporterUser(id, firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lCanWrite, lIsActive);
                        }
                        else {
                            result = await postExporterUser(firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lCanWrite, lIsActive, companyID);
                        }
                        break;
                    case 2:
                        if (isEditMode === true) {
                            result = await patchRegulatoryUser(id, firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                        }
                        else {
                            result = await postRegulatoryUser(firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                        }
                        break;
                    case 3:
                        if (isEditMode === true) {
                            result = await patchLiveCorpUser(id, firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                        }
                        else {
                            result = await postLiveCorpUser(firstName, lastName, emailAddress, mobilePhone, lPermissionLevel, lIsActive);
                        }
                        break;
                }
                
                if (result.status == 200) {
                    setShowSuccessMessage(true);
                    onAdd();
                    if (isEditingLoggedInUser === true && isEditMode === true) { // update user context and session
                        let updatedUser = JSON.parse(JSON.stringify(result.data)); // need json parse and stringify so that the use effect is triggered on the logged in container
                        userCtx.setUser(updatedUser);
                        updateLoggedInUser(updatedUser);
                    }

                    if (isEditMode === false) {
                        clearInputs();
                    }

                    setTimeout(() => {
                        setShowSuccessMessage(false);
                    }, 15000);

                    setTimeout(()=>{
                        setShowModal(false);
                    },1500);
                }
                else if (result.status == 400) {
                    setShowErrorMessage(true);
                    if (typeof result.data === `string`) {
                        setErrorMessage(result.data);
                    }
                    else {
                        setErrorMessage(`Unable to ${lMode} user.`);
                    }
                }
                else {
                    setShowErrorMessage(true);
                    setErrorMessage(`Unable to ${lMode} user.`);
                }
            }
        }
        saveAndValidate();
    }

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{headerText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="mb-3">
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">First Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtFirstName`}
                                        onChange={(e) => {
                                            setFirstName(e.target.value);
                                            setValidFirstName(true);
                                        }}
                                        value={firstName}
                                        isInvalid={validFirstName === false}
                                    />
                                    {
                                        validFirstName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtFirstName`}
                                                    place="top"
                                                    variant="error"
                                                    content="A first name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                        <Col>
                            <Stack>
                                <div className="darkblue field-label">Last Name</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtLastName`}
                                        onChange={(e) => {
                                            setLastName(e.target.value);
                                            setValidLastName(true);
                                        }}
                                        value={lastName}
                                        isInvalid={validLastName === false}
                                    />
                                    {
                                        validLastName === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtLastName`}
                                                    place="top"
                                                    variant="error"
                                                    content="A last name is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>

                    <>
                        <Row className='mb-3'>
                            <Col xs={6}>
                                <Stack>
                                    <div className="darkblue field-label">Account Type</div>
                                    <Form.Group>
                                        <Form.Select value={accountTypeID} onChange={(e) => setAccountTypeID(e.target.value)} disabled={isEditMode}>
                                            {
                                                accountTypes.map((opt, i) => {
                                                    return (<option key={`type_opt_${i}`} value={opt.id}>{opt.userType}</option>);
                                                })
                                            }
                                        </Form.Select>
                                    </Form.Group>
                                </Stack>
                            </Col>
                        </Row>
                        {
                            showCompanyInput === true ?
                                <Row className='mb-3'>
                                    <Col>
                                        <Stack>
                                            <div className="darkblue field-label">Company</div>
                                            <Form.Group>
                                                <Form.Select value={companyID} onChange={(e) => setCompanyID(e.target.value)} disabled={isEditMode}>
                                                    {
                                                        companyOptions.map((opt, i) => {
                                                            return (<option key={`type_opt_${i}`} value={opt.id}>{opt.exporterName}</option>);
                                                        })
                                                    }
                                                </Form.Select>
                                            </Form.Group>
                                        </Stack>
                                    </Col>
                                </Row>
                                : null
                        }
                    </>

                    <Row className='mb-3'>
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Permission Level</div>
                                <Form.Group>
                                    <Form.Select value={permissionLevel} onChange={(e) => setPermissionLevel(e.target.value)} disabled={isEditingLoggedInUser}>
                                        <option value={false}>Standard</option>
                                        <option value={true}>Administrator</option>
                                    </Form.Select>
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    {
                        Number(accountTypeID) === 1 ? // industry
                            <Row className='mb-3'>
                                <Col xs={6}>
                                    <Stack>
                                        <div className="darkblue field-label">Access Mode</div>
                                        <Form.Group>
                                            <Form.Select value={canWrite} onChange={(e) => setCanWrite(e.target.value)} disabled={isEditingLoggedInUser}>
                                                <option value={true}>Full Access</option>
                                                <option value={false}>Read Only</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row className="mb-3">
                        <Col xs={12}>
                            <Stack>
                                <div className="darkblue field-label">Email Address</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtEmail`}
                                        onChange={(e) => {
                                            setEmailAddress(e.target.value);
                                            setValidEmail(true);
                                        }}
                                        value={emailAddress}
                                        isInvalid={validEmail === false}
                                    />
                                    {
                                        validEmail === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtEmail`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid email address is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col xs={6}>
                            <Stack>
                                <div className="darkblue field-label">Mobile Phone</div>
                                <Form.Group>
                                    <Form.Control
                                        id={`txtPhone`}
                                        onChange={(e) => {
                                            e.preventDefault();
                                            parseNumericInput(e, numericValidationRules);
                                            setMobilePhone(e.target.value);
                                            setValidPhone(true);
                                        }}
                                        value={mobilePhone}
                                        isInvalid={validPhone === false}
                                    />
                                    {
                                        validPhone === false ?
                                            (<div className="validation-error-tooltip">
                                                <Tooltip
                                                    anchorSelect={`#txtPhone`}
                                                    place="top"
                                                    variant="error"
                                                    content="A valid mobile phone number is required."
                                                />
                                            </div>
                                            ) : null
                                    }
                                </Form.Group>
                            </Stack>
                        </Col>
                    </Row>
                    {
                        isEditMode === true ?
                            <Row>
                                <Col>
                                    <Stack direction='horizontal' gap={2}>
                                        <div className="darkblue field-label">Is Active</div>
                                        <Form.Group>
                                            <Form.Check
                                                disabled={isEditingLoggedInUser}
                                                onChange={(e) => {
                                                    setIsActive(e.target.checked);
                                                }}
                                                checked={isActive}
                                            />
                                        </Form.Group>
                                    </Stack>
                                </Col>
                            </Row>
                            : null
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    {
                        showErrorMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='danger'>{errorMessage}</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    {
                        showSuccessMessage ?
                            <Row>
                                <Col>
                                    <Alert variant='success'>The user has been {isEditMode === true ? "updated" : "added"} successfully.</Alert>
                                </Col>
                            </Row>
                            : null
                    }
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => save()}>Save</Button>
                        </Col>
                    </Row>
                </Container>


            </Modal.Footer>
        </Modal>
    )
}

export default LIVEXCollect_Add_Edit_Modal;