export class RegisteredEstablishmentConsignmentAnimalModel {
    ID;
    RegisteredEstablishmentConsignmentID;
    NumberOfStock;
    SpeciesID;
    ClassID;

    constructor({id, registeredEstablishmentConsignmentID, numberOfStock, speciesID, classID}) {
        this.ID = id;
        this.RegisteredEstablishmentConsignmentID = registeredEstablishmentConsignmentID;
        this.NumberOfStock = numberOfStock;
        this.SpeciesID = speciesID;
        this.ClassID = classID;
    }
}